import {fetch,batchedFetch,post,put,postCloudinary} from '../api'
import axios from 'axios'
import {downloadMissingScreenRecordings} from '../utils/assets/downloadMissingScreenRecordings'
import * as Sentry from '@sentry/electron/renderer'

export function fetchScreenRecordings(){
	return (dispatch) => fetch(`/screen-recordings`)
	.then((response) => {
		dispatch({ type: 'FETCH_SCREEN_RECORDINGS_SUCCESS', response })
		downloadMissingScreenRecordings(response)
		return response
	})
	.catch((error) => {
		console.log(error)
		return error
	})
}

const files= [
	{ "name": "log.json", "type": "application/json" },
	{ "name": "recording.input-events.json", "type": "application/json" },
	{ "name": "metadata.json", "type": "application/json" },
]

export function syncNewScreenRecording(captureId) {
	const requestBody = {capture_id: captureId}
	return async (dispatch) => {
		let endpoint = '/screen-recordings'
		let basePath = `screenRecordings/${captureId}`
		let videoFileName = 'recording.mp4';
		let videoMimeType = 'video/mp4';
		try {
			console.log(`Starting sync for recording`, captureId);
			const metadataPath = `${basePath}/metadata.json`;
			const metadataContent = await ipcRenderer.invoke('read-file', metadataPath);
			const metadata = JSON.parse(metadataContent);
			requestBody.duration = metadata.durationSeconds
			requestBody.width = metadata.dimensions.width
			requestBody.height = metadata.dimensions.height
			requestBody.appName = metadata.appName 
			requestBody.displayName = metadata.displayName
			const response = await post(endpoint, requestBody);

			//upload video
			const videoPath = `${basePath}/${videoFileName}`;
			const videoBuffer = await ipcRenderer.invoke('read-file-buffer', videoPath);
			const videoUploadURL = response.uploadURLs.find(url => url.fileName.includes(videoFileName)).uploadURL;
			await axios.put(videoUploadURL, videoBuffer, { headers: { 'Content-Type': videoMimeType } });
			console.log('Upload successful for video', videoFileName);

			// Upload each JSON file (and any other files)
			for (const file of files) {
				const filePath = `${basePath}/${file.name}`;
				const fileContent = await ipcRenderer.invoke('read-file', filePath);
				const uploadURL = response.uploadURLs.find(url => url.fileName === file.name).uploadURL;
				await axios.put(uploadURL, fileContent, { headers: { 'Content-Type': file.type } });
				console.log('Upload successful for file:', file.name);
			}
			// Update the recording status to complete
			const updateResponse = await put(`/screen-recordings/${captureId}/complete`);
			console.log('Recording status updated to complete');
		} catch (error) {
			console.error('Error during screen recording sync', error);
			error.name = 'Sync Screen Recording Error'
			Sentry.withScope(scope => {
				scope.setExtra("RequestBody", requestBody);
				scope.setExtra("CaptureID", captureId);
				scope.setTag("error-type", "SyncNewRecordingError");
				scope.setLevel("error");
				// Capture specific file and step information if available in error
				if (error.filePath) {
					scope.setExtra("FailedFilePath", error.filePath);
					scope.setTag("Step", "FileUpload");
				} else if (error.response) {
					scope.setExtra("FailedResponse", error.response);
					scope.setTag("Step", "APIRequest");
			}
				Sentry.captureException(error);
			});
		}
	}
}
