// Voices may still be in original files but are now excluded

export const excludedVoiceIds = [
	'14',
	'1053406996',
	'3',
	'4',
	'11', // briana
	'10',
	'1050897321',
	'6',
	'1050897329',
];

