import React, {useState} from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import Icon from '../misc/Icon';
import { findBackgroundsForBrand } from '../../utils/brands/brandBackgrounds'

function SelectColorPopoverItem({ color, active, handleSelectColor, handleClosePopover }) {
  let itemStyle  
  if(color.type === 'solid'){    
    itemStyle = {
      background: color.rgba
    };    
  };

  const handleClick = () => {
    if(active){
      handleClosePopover()
    }
    handleSelectColor(color.id);
  };

  return (
    <button 
      data-state={active ? "active" : "inactive"} 
      className={'dropdownMenu--selectColor-grid-item ' + (color.type === 'image' ? ' dropdownMenu--selectColor-grid-item--image ' : ' dropdownMenu--selectColor-grid-item--solid ')}
      onClick={handleClick}
    >
      <div style={itemStyle} className='dropdownMenu--selectColor-grid-item-inner'>
        {color.type === 'image' && 
          <img className='dropdownMenu--selectColor-grid-item-inner-img' src={color.src} />
        }
      </div>

      <div className='dropdownMenu--selectColor-grid-item-border' />
      <div className='dropdownMenu--selectColor-grid-item-bg' />
    </button>
  );
}


function SelectColorDropdown({ children, activeColorId,colors,handleSelectColor,isTextColor,isAutoTextColor,isSlideBGColor,isAutoBGColor, colorCount,isNoneBGColor }) {
  
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const handleClosePopover = () => {
    setDropdownOpen(false); // Close the dropdown
  };

  const selectAutoColor = () => {
    const id = null 
    const isAutoTextColor=true
    handleSelectColor(id,isAutoTextColor)
    handleClosePopover()
  };

   const selectNoneColor = () => {
    const id = 'none' 
    handleSelectColor(id)
    handleClosePopover()
  };


  let showWide = false 
  if(colorCount && colorCount > 9){
    showWide = true
  }
  // console.log(`isNoneBGColor---- ${isNoneBGColor}`)


  return (
    <DropdownMenu.Root open={dropdownOpen} onOpenChange={setDropdownOpen}  modal={false}>
      <DropdownMenu.Trigger asChild>
        {children}
      </DropdownMenu.Trigger>
      <DropdownMenu.Content 
        sideOffset={1}
        // align="start"
        align="center"
        //alignOffset={-24}
        className={'dropdownMenu dropdownMenu--selectColor forceDarkTheme' + (showWide ? ' dropdownMenu--selectColor--wide ' : '  ')}
      >                
        <div className='dropdownMenu--selectColor-grid'>
           {isSlideBGColor &&
            <button 
              data-state={isNoneBGColor ? "active" : "inactive"} 
              className='dropdownMenu--selectColor-grid-item dropdownMenu--selectColor-grid-item--none '
              onClick={selectNoneColor}
            >
              <div className='dropdownMenu--selectColor-grid-item-inner'>
                None
              </div>
              <div className='dropdownMenu--selectColor-grid-item-border' />
              <div className='dropdownMenu--selectColor-grid-item-noneLine' />
              <div className='dropdownMenu--selectColor-grid-item-bg' />
            </button>        
          }

          {isTextColor &&
            <button 
              data-state={isAutoTextColor ? "active" : "inactive"} 
              className='dropdownMenu--selectColor-grid-item dropdownMenu--selectColor-grid-item--auto '
              onClick={selectAutoColor}
            >
              <div className='dropdownMenu--selectColor-grid-item-inner'>
                Auto
              </div>
              <div className='dropdownMenu--selectColor-grid-item-border' />
              <div className='dropdownMenu--selectColor-grid-item-bg' />
            </button>        
          }

          {isSlideBGColor &&
            <button 
              data-state={isAutoBGColor ? "active" : "inactive"} 
              className='dropdownMenu--selectColor-grid-item dropdownMenu--selectColor-grid-item--auto '
              onClick={selectAutoColor}
            >
              <div className='dropdownMenu--selectColor-grid-item-inner'>
                Auto
              </div>
              <div className='dropdownMenu--selectColor-grid-item-border' />
              <div className='dropdownMenu--selectColor-grid-item-bg' />
            </button>        
          }

         


          {colors.map(color => (
            <SelectColorPopoverItem 
              key={color.id}
              color={color}
              active={!isAutoTextColor && !isAutoBGColor && activeColorId === color.id}
              handleClosePopover={handleClosePopover}
              handleSelectColor={handleSelectColor}
            />
          ))}
        </div>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
}

export default SelectColorDropdown;