import React, { useRef, useEffect, useState, useMemo } from 'react';
import * as THREE from 'three';
import { useThree } from '@react-three/fiber';

const ExportMobileVideoMaterial = ({ codecVideoClip, opacity }) => {
  const { invalidate } = useThree();
  const materialRef = useRef();
  const textureRef = useRef();
  const [updateTrigger, setUpdateTrigger] = useState(0);


  useEffect(() => {
    if (!textureRef.current) {
      textureRef.current = new THREE.Texture();
      textureRef.current.minFilter = THREE.LinearFilter;
      textureRef.current.magFilter = THREE.LinearFilter;
      textureRef.current.format = THREE.RGBAFormat;
      textureRef.current.colorSpace = THREE.SRGBColorSpace;
    }

    if (codecVideoClip) {
      codecVideoClip.setExternalTexture(textureRef.current);
      codecVideoClip.setTextureUpdatedCallback(() => {
        setUpdateTrigger(prev => prev + 1);
        invalidate();
      });
    }

    return () => {
      if (codecVideoClip) {
        codecVideoClip.setExternalTexture(null);
        codecVideoClip.setTextureUpdatedCallback(null);
      }
    };
  }, [codecVideoClip, invalidate]);

  const material = useMemo(() => {
    return new THREE.MeshBasicMaterial({
      map: textureRef.current,
      transparent: true,
      opacity: opacity,
    });
  }, [opacity, updateTrigger]);

  useEffect(() => {
    if (materialRef.current) {
      materialRef.current.map = textureRef.current;
      materialRef.current.needsUpdate = true;
    }
  }, [material]);

  return <meshBasicMaterial ref={materialRef} map={textureRef.current} transparent opacity={opacity} attach="material" />;
};

export default ExportMobileVideoMaterial;