import store from '../store'


export function getOrgId() {	
	const state = store.getState()
	let id
	const organization=state.organization
	if(organization){
		id=organization.id
	}
	return id 
}
