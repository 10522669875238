import React, { useRef, useEffect, useMemo, useState } from 'react';
import * as THREE from 'three';
import { useFrame, useThree } from '@react-three/fiber';
import { useControls } from 'leva';
import { calculateWebcamCrop } from '../utils/calculateWebcamCrop';

const WebcamVideoVideoMaterial = ({ 
  videoElement, 
  opacity, 
  meshWidth, 
  meshHeight, 
  videoAssetWidth, 
  videoAssetHeight,
  faceBox,
  currentTime,
  transcript,
  renderOrder,
  colorAdjustments,
  startTime
}) => {
  const { invalidate } = useThree();
  const materialRef = useRef();

 
  const videoTexture = useMemo(() => {
    if (videoElement) {
      const texture = new THREE.VideoTexture(videoElement);
      texture.generateMipmaps = true;
      texture.minFilter = THREE.LinearMipmapLinearFilter;
      texture.colorSpace = THREE.SRGBColorSpace;      
      texture.magFilter = THREE.LinearFilter;
      texture.needsUpdate = true;
      return texture;
    }
  }, [videoElement]);

  const [lastUpdateTime, setLastUpdateTime] = useState(0);
  const [shouldZoomIn, setShouldZoomIn] = useState(false);

  useEffect(() => {
    if (currentTime - lastUpdateTime >= 0.1) {
      if (transcript && transcript.words) {
        const currentWord = transcript.words.find(word => 
          currentTime >= (word.start + startTime) && 
          currentTime <= (word.end + startTime)
        );

        // Check if we're in a gap between emphasized words
        const isInGapBetweenEmphasized = transcript.words.some(word => {
          const wordStart = word.start + startTime;
          const wordEnd = word.end + startTime;
          return word.emphasized && 
                 currentTime > wordEnd && 
                 currentTime < wordEnd + 0.5 && // 0.5s gap allowance
                 // Check if there's another emphasized word coming up
                 transcript.words.some(nextWord => 
                   nextWord.emphasized && 
                   (nextWord.start + startTime) > currentTime && 
                   (nextWord.start + startTime) < currentTime + 0.5
                 );
        });

        setShouldZoomIn(currentWord?.emphasized || isInGapBetweenEmphasized);
      }
      setLastUpdateTime(currentTime);
    }
  }, [transcript, currentTime, startTime, lastUpdateTime]);

  const { cropX, cropY, cropWidth, cropHeight } = useMemo(() => {
    return calculateWebcamCrop({
      originalWidth: videoAssetWidth,
      originalHeight: videoAssetHeight,
      targetWidth: meshWidth,
      targetHeight: meshHeight,
      faceBox: faceBox,
      zoomIn: shouldZoomIn
    });
  }, [
    videoAssetWidth,
    videoAssetHeight,
    faceBox,
    meshWidth,
    meshHeight,
    shouldZoomIn
  ]);


  const { exposure, highlights, shadows, brightness, contrast, saturation, whiteBalance } = colorAdjustments

  const shaderMaterial = useMemo(() => {
    return new THREE.ShaderMaterial({
      uniforms: {
        videoTexture: { value: videoTexture },
        opacity: { value: opacity },
        uvScale: { value: new THREE.Vector2(1, 1) },
        uvOffset: { value: new THREE.Vector2(0, 0) },
        exposure: { value: 0 },
        highlights: { value: 0 },
        shadows: { value: 0 },
        brightness: { value: 0 },
        contrast: { value: 0 },
        saturation: { value: 0 },
        whiteBalance: { value: 0 }
      },
      vertexShader: `
        uniform vec2 uvScale;
        uniform vec2 uvOffset;
        varying vec2 vUv;
        void main() {
          vUv = uv * uvScale + uvOffset;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        uniform sampler2D videoTexture;
        uniform float opacity;
        uniform float exposure;
        uniform float highlights;
        uniform float shadows;
        uniform float brightness;
        uniform float contrast;
        uniform float saturation;
        uniform float whiteBalance;
        varying vec2 vUv;

        vec3 adjustExposure(vec3 color, float exposure) {
          return color * pow(2.0, exposure * 0.75);
        }

        vec3 adjustHighlightsShadows(vec3 color, float highlights, float shadows) {
          float luminance = dot(color, vec3(0.299, 0.587, 0.114));
          float shadow = 1.0 - smoothstep(0.0, 0.5, luminance);
          float highlight = smoothstep(0.5, 1.0, luminance);
          return color * (1.0 + shadows * shadow - highlights * highlight);
        }

        vec3 adjustBrightnessContrast(vec3 color, float brightness, float contrast) {
          return (color - 0.5) * (1.0 + contrast) + 0.5 + brightness;
        }

        vec3 adjustSaturation(vec3 color, float saturation) {
          float luminance = dot(color, vec3(0.299, 0.587, 0.114));
          return mix(vec3(luminance), color, 1.0 + saturation);
        }
        
        vec3 srgbToLinear(vec3 color) {
    return vec3(
        color.r < 0.04045 ? color.r / 12.92 : pow((color.r + 0.055) / 1.055, 2.4),
        color.g < 0.04045 ? color.g / 12.92 : pow((color.g + 0.055) / 1.055, 2.4),
        color.b < 0.04045 ? color.b / 12.92 : pow((color.b + 0.055) / 1.055, 2.4)
    );
}

vec3 linearToSrgb(vec3 color) {
    return vec3(
        color.r < 0.0031308 ? 12.92 * color.r : 1.055 * pow(color.r, 1.0/2.4) - 0.055,
        color.g < 0.0031308 ? 12.92 * color.g : 1.055 * pow(color.g, 1.0/2.4) - 0.055,
        color.b < 0.0031308 ? 12.92 * color.b : 1.055 * pow(color.b, 1.0/2.4) - 0.055
    );
}

vec3 adjustWhiteBalance(vec3 color, float temperature) {
    // Convert sRGB to linear space
    vec3 linearColor = srgbToLinear(color);

    // Normalize temperature to [0,1] range
    float t = clamp(temperature * 0.5 + 0.5, 0.0, 1.0);

    // Calculate red and blue adjustments with slightly increased intensity
    float redAdjustment = 1.0 + (t - 0.5) * 0.3;  // Range: 0.85 to 1.15
    float blueAdjustment = 1.0 - (t - 0.5) * 0.3; // Range: 1.15 to 0.85

    // Apply white balance adjustment
    vec3 adjustedColor = linearColor * vec3(redAdjustment, 1.0, blueAdjustment);

    // Convert back to sRGB
    return linearToSrgb(adjustedColor);
}


        void main() {
          vec4 videoColor = texture2D(videoTexture, vUv);
          vec3 color = videoColor.rgb;
          
          color = adjustExposure(color, exposure);
          color = adjustHighlightsShadows(color, highlights, shadows);
          color = adjustBrightnessContrast(color, brightness, contrast);
          color = adjustSaturation(color, saturation);
          color = adjustWhiteBalance(color, whiteBalance);
          
          gl_FragColor = vec4(color, videoColor.a * opacity);
        }
      `,
      transparent: true
    });
  }, [videoTexture, opacity]);

  useFrame(() => {
    if (materialRef.current && videoTexture) {
      // The crop values are already normalized (0-1), so we can use them directly
      const finalScaleX = cropWidth;
      const finalScaleY = cropHeight;
      
      // UV offset starts from bottom-left in WebGL, so we need to flip the Y coordinate
      const offsetX = cropX;
      const offsetY = 1.0 - cropY - cropHeight;
      
      // Set the UV scale and offset uniforms
      materialRef.current.uniforms.uvScale.value.set(finalScaleX, finalScaleY);
      materialRef.current.uniforms.uvOffset.value.set(offsetX, offsetY);
      
      // Set other uniforms as before
      materialRef.current.uniforms.videoTexture.value = videoTexture;
      materialRef.current.uniforms.opacity.value = opacity;
      materialRef.current.uniforms.exposure.value = exposure;
      materialRef.current.uniforms.highlights.value = highlights;
      materialRef.current.uniforms.shadows.value = shadows;
      materialRef.current.uniforms.brightness.value = brightness;
      materialRef.current.uniforms.contrast.value = contrast;
      materialRef.current.uniforms.saturation.value = saturation;
      materialRef.current.uniforms.whiteBalance.value = whiteBalance;
  
      // Mark the material as needing an update
      materialRef.current.needsUpdate = true;
    }
  });
  

  useEffect(() => {
    if (materialRef.current && renderOrder !== undefined) {
      materialRef.current.renderOrder = renderOrder;
      invalidate();
    }
  }, [renderOrder, invalidate]);

  return <primitive object={shaderMaterial} ref={materialRef} attach="material" />;
};

export default WebcamVideoVideoMaterial;