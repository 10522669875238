import {Schema} from 'prosemirror-model'
import EditorNodes from './nodes/TextSlideNodes'
import EditorMarks from './marks/TextSlideMarks'


const textSlideSchema = new Schema({
  nodes:EditorNodes,
  marks: EditorMarks
})



export default textSlideSchema