import React from 'react';
import Icon from '../../../../misc/Icon'
import {calculateTimelineTimeFromVideoTime} from '../../../../../timeline/utils/calculateTimelineTimeFromVideoTime'

const EditorTimelineClipContentsWebcam = ({ clip,webcamChunks }) => {


  let chunks 
  if(clip.isPlaceholder && webcamChunks){ //work out the chunks from the audio clips
    const sortedChunks = webcamChunks.sort((a, b) => a.indexInParentClip - b.indexInParentClip);
    let placeholderChunks=[] 
    sortedChunks.forEach((chunk)=>{
      placeholderChunks.push({duration:chunk.duration,text:chunk.metadata.text,startTime:chunk._startTime - clip.startTime + clip.scene.startTime})
    })
    chunks=placeholderChunks
  }else{
    if(clip.metadata.transcript && clip.metadata.transcript.chunks){
      let transcriptChunks =[]
      clip.metadata.transcript.chunks.forEach((chunk)=>{
        const startTime = Math.max(chunk.start,clip.metadata.trimStart)
        const endTime = Math.min(chunk.end,clip.metadata.trimEnd)

        const duration = calculateTimelineTimeFromVideoTime(endTime,clip) -calculateTimelineTimeFromVideoTime(startTime,clip) 
        const relativeStartTime = calculateTimelineTimeFromVideoTime(startTime,clip) - clip.startTime
        
        // Filter words based on trim boundaries
        let filteredText = chunk.text;
        if (chunk.words && clip.metadata.trimStart !== undefined && clip.metadata.trimEnd !== undefined) {
          const filteredWords = chunk.words.filter(word => 
            !(word.end < clip.metadata.trimStart || word.start > clip.metadata.trimEnd)
          );
          filteredText = filteredWords.map(word => word.word).join(' ');
        }
        if(duration>0){
          transcriptChunks.push({
            duration: duration,
            text: filteredText,
            startTime: relativeStartTime
          })
        }
      })
      chunks=transcriptChunks
    }else{
      chunks=[{duration:clip.duration,text:'placeholder chunk'}]
    }
  }
  
  const segmentGap = 0.15
  return (
    <>
      <div className='editor-timeline-clip-inner'>
        {chunks && chunks.map((chunk, index) => (
          <div 
            key={index}
            className='editor-timeline-clip-inner-chunk'
            style={{ 
              left: `${((chunk.startTime) / clip.duration) * 100}%`,
              width: `${((chunk.duration) / clip.duration) * 100}%`
            }}
          >
            <div className="editor-timeline-clip-inner-chunk-line" />
            <div className="editor-timeline-clip-inner-chunk-text">
              {chunk.text}
            </div>            
            <div className="editor-timeline-clip-inner-chunk-bg" />
          </div>
        ))}
      </div>
    </>
  );
};

export default EditorTimelineClipContentsWebcam;