import React, {useState} from 'react'
import Icon from '../../../misc/Icon';
import SelectVoicePopover from './selectVoicePopover/SelectVoicePopover'
import SelectCaptionsPopover from './selectCaptionsPopover/SelectCaptionsPopover'
import * as Tooltip from '@radix-ui/react-tooltip';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import {getOrgDomain} from '../../../../utils/getOrgDomain'
//subtitlesType is null, standard or captions

function TranscriptPanelTopBar(props) {
  
  const {
    subtitlesType,
    handleChangeSubtitlesType,
    handleGenerateSRTFile,
    speakerName, 
    handleChangeActiveVoice,
    activeSpeakerVoice,
    handleChangeVoiceoverPlaybackRate,
    activeVoiceoverPlaybackRate,
    hideTranscriptPanel,
    showCaptions,
    toggleShowCaptions,
    generateSubtitles,
    regenerateAllSubtitles,
    // isWebcamMode,
    // toggleWebcamMode
  }=props

  const [voiceDropdownOpen, setVoiceDropdownOpen] = useState(false)
  const [captionsDropdownOpen, setCaptionsDropdownOpen] = useState(false)
 
  const handleCaptionStyleChange = (style, event) => {
    // Prevent the dropdown from closing
    event.preventDefault()
    
    let newSubtitlesType 
    if (style === 'Standard') {
      newSubtitlesType = 'standard'
    } else if (style === 'Pop') {
      newSubtitlesType = 'captions'
    }
    if (newSubtitlesType === subtitlesType) {
      newSubtitlesType = null
    }
    handleChangeSubtitlesType(newSubtitlesType);
  }

  let activeStyle 
  if(subtitlesType=='standard'){
    activeStyle='Standard'
  }
  if(subtitlesType=='captions'){
    activeStyle='Pop'
  }
 // console.log(`in top bar subtitlesType ${subtitlesType}`)

  let recordLabel = 'Record all'
  // could be just record  

  let showRecordButton = false

  if( getOrgDomain()=='yarn.so'){
    showRecordButton = true
  }


  return (
    <div className='editor-transcriptPanel-topBar'>          

    {showRecordButton &&
      <button onClick={() => window.ipcRenderer.send('open-webcam-window')}  className='editor-transcriptPanel-topBar-btn editor-transcriptPanel-topBar-btn--recordCamera'>
        <div className='editor-transcriptPanel-topBar-btn-iconContainer'>
          <Icon name='videoOutline' />
        </div>                
        <div className='editor-transcriptPanel-topBar-btn-label'>
          {recordLabel}
        </div>
      </button> 
    }




       <div className='editor-transcriptPanel-topBar-hSpacer' />
        
      
      
                    

      <SelectVoicePopover        
        activeSpeakerId={activeSpeakerVoice}
        handleChangeActiveVoice={handleChangeActiveVoice}
        //handleChangeActiveVoice={}
        handleClosePopover={() => setVoiceDropdownOpen(false)}   
        handleChangeVoiceoverPlaybackRate={handleChangeVoiceoverPlaybackRate}    
        activeVoiceoverPlaybackRate={activeVoiceoverPlaybackRate} 
      >
        <button onClick={() => setVoiceDropdownOpen(!voiceDropdownOpen)} className='editor-transcriptPanel-topBar-btn editor-transcriptPanel-topBar-btn--speakerDropdown'>
          <div className='editor-transcriptPanel-topBar-btn-iconContainer'>
            <Icon name='waveform' />
          </div>
          <div className='editor-transcriptPanel-topBar-btn-label'>
            {speakerName}            
          </div>     
          <div className='editor-transcriptPanel-topBar-btn-tooltipContainer'>
            <div className='tooltip tooltip--transcriptPanelTopBar'>
              Edit voice
            </div>
          </div>     
        </button>           
      </SelectVoicePopover>


      
      
      
      
      

      <DropdownMenu.Root modal={false}> 
        <DropdownMenu.Trigger asChild>
          <button className='editor-transcriptPanel-topBar-btn editor-transcriptPanel-topBar-btn--dropdown'>      
            <div className='editor-transcriptPanel-topBar-btn-iconContainer'>
              <Icon name='ellipses' />        
            </div>
          </button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal> 
          <DropdownMenu.Content             
            align="end"
            alignOffset={-2}
            className='dropdownMenu dropdownMenu--timelineSelect forceDarkTheme'
          >                         
                      
            <div className='dropdownMenu-subHeader'>
              Captions
            </div>

            
            <DropdownMenu.CheckboxItem 
              checked={subtitlesType === 'captions'}
              onSelect={(event) => handleCaptionStyleChange('Pop', event)}
            >            
              Pop
            </DropdownMenu.CheckboxItem>

            <DropdownMenu.CheckboxItem 
              checked={subtitlesType === 'standard'}
              onSelect={(event) => handleCaptionStyleChange('Standard', event)}
            >              
              Standard
            </DropdownMenu.CheckboxItem>            

            <DropdownMenu.Separator />      
            <DropdownMenu.Item onSelect={handleGenerateSRTFile}>              
              Download SRT...
            </DropdownMenu.Item>                                        
            
            <DropdownMenu.Item onSelect={hideTranscriptPanel}>              
              Hide Transcript Panel
            </DropdownMenu.Item>
          
          </DropdownMenu.Content>
        </DropdownMenu.Portal>          
      </DropdownMenu.Root>
            


      
      
    </div>
  );
}

export default TranscriptPanelTopBar;
