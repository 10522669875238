import React from 'react';
import { PortableText } from '@portabletext/react'
import moment from 'moment';
import VideoPlayer from '../uiKit/videoPlayer/VideoPlayer'

// Custom function to format dates
const formatDate = (date) => {
  const now = moment();
  const then = moment(date);
  const diffMinutes = now.diff(then, 'minutes');
  const diffHours = now.diff(then, 'hours');
  const diffDays = now.diff(then, 'days');
  const diffWeeks = now.diff(then, 'weeks');
  const diffMonths = now.diff(then, 'months');
  
  if (diffMinutes < 1) return 'just now';
  else if (diffMinutes === 1) return '1 min ago';
  else if (diffMinutes < 60) return `${diffMinutes} mins ago`;
  else if (diffHours === 1) return '1 hr ago';
  else if (diffHours < 24) return `${diffHours} hrs ago`;
  else if (diffDays === 1) return '1 day ago';
  else if (diffDays < 7) return `${diffDays} days ago`;
  else if (diffWeeks === 1) return '1 week ago';
  else if (diffWeeks < 4) return `${diffWeeks} weeks ago`;
  else if (diffMonths === 1) return '1 month ago';
  else if (diffMonths < 12) return `${diffMonths} months ago`;  
  else return then.fromNow(true); // For longer durations, use default formatting
};


const ptComponents = {
  block: {
    h1: ({children}) => <h1>{children}</h1>,
    h2: ({children}) => <h2>{children}</h2>,
    h3: ({children}) => <h3>{children}</h3>,
    h4: ({children}) => <h4>{children}</h4>,
    normal: ({children}) => {
      const text = children.join('');
      const imgRegex = /\[IMG:\s*(.+?)\]/;
      const videoRegex = /\[MUX:\s*(\w+)\]/;
      
      if (imgRegex.test(text)) {
        const imgMatch = text.match(imgRegex);
        let imgUrl = imgMatch[1];
        
        // Check if imgUrl is an object and try to extract the URL
        if (typeof imgUrl === 'object' && imgUrl !== null) {
          console.log('Image object:', imgUrl); // For debugging
          // Attempt to find a URL-like property in the object
          const urlProps = ['url', 'src', 'href', 'link'];
          for (let prop of urlProps) {
            if (imgUrl[prop] && typeof imgUrl[prop] === 'string') {
              imgUrl = imgUrl[prop];
              break;
            }
          }
        }
        
        console.log('Final Image URL:', imgUrl); // For debugging
        
        return (
          <div className='page-post-content-imageContainer'>
            <img
              alt="Post image"
              loading="lazy"
              src={String(imgUrl)}
              style={{maxWidth: '100%', height: 'auto'}}
            />
          </div>
        );
      }
      
      if (videoRegex.test(text)) {
        const videoMatch = text.match(videoRegex);
        const playbackId = videoMatch[1];
        return (
          <div className='updatesPage-post-videoPlayerOuterContainer'>
            <div className='updatesPage-post-videoPlayerMiddleContainer'>
              <div className='updatesPage-post-videoPlayerInnerContainer'>            
                <VideoPlayer 
                  playbackId={playbackId}
                  aspectRatio={16/9}
                />            
              </div>
            </div>
          </div>
        );
      }
      
      return <p>{text}</p>;
    }
  }
};

const UpdatesPagePost = ({post}) => {  
  const {title, publishedAt, body, slug} = post;
  return (
    <div id={slug.current} className='updatesPage-post'>      
      <a name={slug.current} className='updatesPage-post-date'>
        {formatDate(publishedAt)} 
      </a>      
      <div className='updatesPage-post-title'>
        {title}
      </div>      
      <PortableText
        value={body}
        components={ptComponents}
      />
      <div className='updatesPage-post-borderBottom' />
    </div>
  );
};

export default UpdatesPagePost;