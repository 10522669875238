const defaultValues = { // these are default values for animations
  positionX: 0,
  positionY: 0,
  positionZ: 0,
  scale: 1,
  rotationX: 0,
  rotationY: 0,
  rotationZ: 0,
  opacity: 1,
}

export default defaultValues;
