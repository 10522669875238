// recordingDimensions.js

// Exported fetchDeviceDimensions function

export const fetchRecordingDimensions = async (projectPath,fsProjectName) => {
  try {
    let data;

    if(window.isElectron){
 
      if (fsProjectName) {
      // If fsProjectName is provided, use IPC to read the file from the main process
        const relativePath = `recordings/${fsProjectName}/metadata.json`;
        //console.log(relativePath)
        const content = await ipcRenderer.invoke('read-file', relativePath);
        data = JSON.parse(content);
      } else {
        // Otherwise, use fetch as before
        const METADATA_SRC = `${projectPath}/recording/metadata.json`;
        const response = await fetch(METADATA_SRC);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        data = await response.json();
      }
    }
    else{ //Server side Export

      const s3BaseUrl = 'https://yarn-assets.s3.amazonaws.com/';
      const metadataUrl = `${s3BaseUrl}recordings/${fsProjectName}/metadata.json`;
      const response = await fetch(metadataUrl);
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
      }
      data = await response.json();
    }

    const displayRecorder = data.recorders.find(recorder => recorder.type === 'display');
    if (displayRecorder && displayRecorder.sessions && displayRecorder.sessions[0]) {
      const bounds = displayRecorder.sessions[0].bounds;
      return { recordingWidth: bounds.width, recordingHeight: bounds.height, recordingXOffset: bounds.x, recordingYOffset: bounds.y  };
    }
  } catch (error) {
    console.error('There has been a problem with your fetch operation:', error);
    return null;
  }
};





