import React, { useState, useEffect } from 'react';
import { findBackgroundsForBrand } from '../../utils/brands/brandBackgrounds';
import SlidePreviewBG from './SlidePreviewBG';
import SlidePreviewTextElement from './SlidePreviewTextElement';
import SlidePreviewImageElement from './SlidePreviewImageElement';

const SLIDE_WIDTH = 1920;
const SLIDE_HEIGHT = 1080;
const SLIDE_ASPECT = SLIDE_WIDTH / SLIDE_HEIGHT;

function SlidePreview({ displayWidth, slideData, activeBackgroundId }) {
  const [slideElements, setSlideElements] = useState([]);
  
  

  const displayHeight = displayWidth / SLIDE_ASPECT;
  const displayScalar = displayWidth / SLIDE_WIDTH;

  const extractElements = (layout) => {
    let elements = [];
    if (layout.children) {
      layout.children.forEach(child => {
        if (child.isLayoutGroup) {
          elements = elements.concat(extractElements(child));
        } else {
          elements.push(child);
        }
      });
    }
    return elements;
  };

  // console.log('slideData')
  // console.log(slideData)

  // console.log('slideElements')
  // console.log(slideElements)

  useEffect(() => {
    if (slideData && slideData.type === 'slide') {
      const elements = extractElements(slideData.layout);
      setSlideElements(elements);
    }
  }, [slideData]);

  // Background
  let slideBGId = activeBackgroundId;
  if (slideData.metadata && slideData.metadata.backgroundId !== 'none') {
    slideBGId = slideData.metadata.backgroundId;
  }

  // Text Elements
  const textElements = slideElements.filter(element => element.type === 'text');

  // Image Elements
  const imageElements = slideElements.filter(element => element.type === 'image');



  return (
    <div style={{height: `${displayHeight}px`, width: `${displayWidth}px`}} className='slidePreviewContainer'>
      <div style={{width: `${SLIDE_WIDTH}px`, height: `${SLIDE_HEIGHT}px`, transform: `scale(${displayScalar})`}} className='slidePreview'>
        
        {textElements.map((textElement, index) => (
          <SlidePreviewTextElement
            key={textElement.id}
            textElement={textElement}
            slideBGId={slideBGId}
          />
        ))}

        {imageElements.map((imageElement, index) => (
          <SlidePreviewImageElement
            key={imageElement.id}
            imageElement={imageElement}
          />
        ))}

        

        <SlidePreviewBG 
          slideBGId={slideBGId}
        />
        
      </div>
    </div>
  );
}

export default SlidePreview;