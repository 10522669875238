import { timeToPixels } from './timeToPixels'

const DECIMAL_PLACES = 3
const BUFFER = 0.00001

const DRAG_THRESHOLD = 10 //must drag more than this pixels to snap otherwise doesnt move



const MID_PROPORTION =0.5 //how far through the clip that we measure closest snap point to 
 

//Drop positions are in pixels

//deltaX is between drag events. trying this instead of relative to start position


export function calculateDropLines(draggedClip,dragClipNewStartTime,dx,pixelsPerSec,allClips, scenes, sceneGap,deltaX){
  
  //const clips = allClips.filter(clip => clip.type != 'audio' && clip.type!='zoom')

  const clips = allClips.filter(clip => {
    if (draggedClip.type === 'zoom') {
      return clip.type !== 'audio';
    } else {
      return clip.type !== 'audio' && clip.type !== 'zoom';
    }
  });

  let originalStartPx 
  let originalEndPx
  let originalMidPx
  let dragMidPx

//  console.log(`dx is----------------------------- ${dx}`)

  if(draggedClip){
   originalStartPx=timeToPixels(draggedClip.startTime, pixelsPerSec, scenes, sceneGap)
   originalEndPx=timeToPixels(draggedClip.endTime, pixelsPerSec, scenes, sceneGap)
   const midTime = draggedClip.startTime + 0.5 * draggedClip.duration
   originalMidPx=timeToPixels(midTime, pixelsPerSec, scenes, sceneGap)

   // let isDraggingLeft = true //relative to initial position
   // if(dx>originalStartPx){
   //  isDraggingLeft=false
   // }
   // console.log(`drag direction ${isDraggingLeft?'LEft':'Right'}`)

  //  const isDraggingLeft = deltaX<0
  // console.log(`drag direction ${isDraggingLeft?'LEft':'Right'}`)

  // if(isDraggingLeft){
  //   dragMidPx = dx + (draggedClip.duration * MID_PROPORTION * pixelsPerSec)
  // }else{
  //   dragMidPx = dx + (draggedClip.duration * (1-MID_PROPORTION) * pixelsPerSec)
  // }

  // console.log(dragMidPx)

   if(dx!==0){
      dragMidPx = dx + (draggedClip.duration * MID_PROPORTION * pixelsPerSec)
    }else{
      dragMidPx=originalMidPx
    }

  // dragMidPx = dx + 20
  }


  let scenePositions = new Set()

  scenes.forEach((scene) => {
    scenePositions.add(timeToPixels(Math.max(scene.startTime - BUFFER, 0), pixelsPerSec, scenes, sceneGap).toFixed(DECIMAL_PLACES));
    scenePositions.add(timeToPixels(scene.endTime + BUFFER, pixelsPerSec, scenes, sceneGap).toFixed(DECIMAL_PLACES));
  });

  let dropPositions = new Set(scenePositions);

  clips.forEach((clip) => {
    if (draggedClip && clip.id != draggedClip.id) {
      const clipStartPx = parseFloat(timeToPixels(clip.startTime, pixelsPerSec, scenes, sceneGap).toFixed(DECIMAL_PLACES));
      const clipEndPx = parseFloat(timeToPixels(clip.endTime, pixelsPerSec, scenes, sceneGap).toFixed(DECIMAL_PLACES));

      let isStartPxUnique = true;
      let isEndPxUnique = true;

      scenePositions.forEach((scenePx) => {
        const scenePxFloat = parseFloat(scenePx);
        if (Math.abs(scenePxFloat - clipStartPx) < 0.01) {
          isStartPxUnique = false;
        }
        if (Math.abs(scenePxFloat - clipEndPx) < 0.01) {
          isEndPxUnique = false;
        }
      });

      if (isStartPxUnique) {
        dropPositions.add(clipStartPx.toFixed(DECIMAL_PLACES));
      }
      if (isEndPxUnique) {
        dropPositions.add(clipEndPx.toFixed(DECIMAL_PLACES));
      }
    }
  });

  dropPositions = Array.from(dropPositions).sort((a, b) => parseFloat(a) - parseFloat(b));

    // Find the closest time to midTime
  // let closestTime = null;
  // let minDistance = Infinity;

  let closestPosition = null;
  let minDistance = Infinity;

  dropPositions.forEach((px) => {
    const pxFloat = parseFloat(px);
    const distance = Math.abs(dragMidPx - pxFloat);
    if (distance < minDistance) {
      minDistance = distance;
      closestPosition = pxFloat;
    }
  });

  let activeDropType = 'start'
  if(closestPosition - dragMidPx > 0){
    activeDropType = 'end'
  }



  const dragAmount = Math.abs(dragMidPx-originalMidPx)
// console.log(`drag amount is--------- ${dragAmount}`)
// console.log(`dragMidPx ${dragMidPx}`)
// console.log(`originalMidPx ${originalMidPx}`)

  if(dragAmount<DRAG_THRESHOLD){
    closestPosition=null
  }



// console.log(`closestPosition----- ${closestPosition}`)

  return {
    originalStartPx:originalStartPx,
    originalEndPx:originalEndPx,
    originalMidPx: originalMidPx ,
    dragMidPx:dragMidPx,
    dropPositions:dropPositions,
    closestPosition:closestPosition,
    activeDropType:activeDropType //start or end
  }

}




