const wordsPerMinute = 140 // I guess we might be able to come up with reasonable hueristics by using eleven labs IRL


// export function estimateAudioDuration(text) {	
//   const wordsCount = text.split(/\s+/).filter(Boolean).length;
//   let durationSeconds = (wordsCount / 140) * 60;
//   durationSeconds = Math.round(durationSeconds);
//   durationSeconds = Math.max(durationSeconds, 1);  
//   return durationSeconds *1000 //ms
// }


//const charsPerMinute = 800; 
//const charsPerMinute = 1150; ///// DEC 2nd it was this and now i change it
//const charsPerMinute = 1050; 
//const charsPerMinute = 1300; //Dec 10 lets underestimate and then enforce min gap


const charsPerMinute = 1300; //Dec 10 lets underestimate and then enforce min gap




// const charsPerMinute = 1050;

export function estimateAudioDuration(text) {	
  const charCount = text.length;
  let durationSeconds = (charCount / charsPerMinute) * 60;
 // durationSeconds = Math.round(durationSeconds);
 // durationSeconds = Math.max(durationSeconds, 1);  
 // return Math.max(durationSeconds * 1000,1); //ms
    return Math.max(durationSeconds * 1000,0); //ms
}

