const MIN_WIDTH = 20;
const MIN_HEIGHT = 20;
const MAX_WIDTH = 1920;
const MAX_HEIGHT = 1080;

export const updateDimensions = (
  prevDimensions,
  newDimensions,
  handleType,
  element,
  clipId,
  elementId,
  updateSlideElementField,
  fixedAspect,
  isInLayoutGroup
) => {
  const updatedDimensions = {
    ...prevDimensions,
    ...newDimensions
  };

  if (element && element.type === 'text') {// For text elements, update the slide element field directly
    Object.entries(updatedDimensions).forEach(([field, value]) => {
      if (value !== prevDimensions[field]) {            
        updateSlideElementField(clipId, elementId, field, value);
      }
    });
  } else {
    // For image elements
    let newWidth = updatedDimensions.width;
    let newHeight = updatedDimensions.height;
    let newX = updatedDimensions.x;
    let newY = updatedDimensions.y;

    if (fixedAspect) {
      const aspectRatio = prevDimensions.width / prevDimensions.height;
      const isCornerDrag = ['topLeft', 'topRight', 'bottomLeft', 'bottomRight'].includes(handleType);
      //const isCornerDrag = handleType === 'corner';
      const isLeft = newX !== prevDimensions.x;
      const isTop = newY !== prevDimensions.y;
      const isBottomRight = !isLeft && !isTop;

      // Calculate the change in position
      const deltaX = newX - prevDimensions.x;
      const deltaY = newY - prevDimensions.y;
      if (isCornerDrag) {
        // Calculate new dimensions based on the drag direction and distance
        let widthChange, heightChange;

        if (isBottomRight) {
          widthChange = newWidth - prevDimensions.width;
          heightChange = newHeight - prevDimensions.height;
        } else {
          if (isLeft) {
            widthChange = -deltaX;
          } else {
            widthChange = deltaX;
          }
          if (isTop) {
            heightChange = -deltaY;
          } else {
            heightChange = deltaY;
          }
        }
        // Determine which dimension change is larger and use that to calculate the new size
        if (Math.abs(widthChange / prevDimensions.width) > Math.abs(heightChange / prevDimensions.height)) {
          newWidth = prevDimensions.width + widthChange;
          newHeight = newWidth / aspectRatio;
        } else {
          newHeight = prevDimensions.height + heightChange;
          newWidth = newHeight * aspectRatio;
        }
        // Adjust position to maintain the correct corner
        if (isLeft) {
          newX = prevDimensions.x + prevDimensions.width - newWidth;
        }
        if (isTop) {
          newY = prevDimensions.y + prevDimensions.height - newHeight;
        }
      } else if (newWidth !== prevDimensions.width) {
        // Horizontal edge drag
        newHeight = newWidth / aspectRatio;
        const verticalCenter = prevDimensions.y + prevDimensions.height / 2;
        newY = verticalCenter - newHeight / 2;
      } else if (newHeight !== prevDimensions.height) {
        // Vertical edge drag
        newWidth = newHeight * aspectRatio;
        const horizontalCenter = prevDimensions.x + prevDimensions.width / 2;
        newX = horizontalCenter - newWidth / 2;
      }

      // Ensure dimensions are within constraints
      newWidth = Math.max(MIN_WIDTH, Math.min(MAX_WIDTH, newWidth));
      newHeight = Math.max(MIN_HEIGHT, Math.min(MAX_HEIGHT, newHeight));

      // Re-adjust position if dimensions were constrained
      if (isLeft || (handleType === 'left' && newX !== prevDimensions.x)) {
        newX = prevDimensions.x + prevDimensions.width - newWidth;
      }
      if (isTop || (handleType === 'top' && newY !== prevDimensions.y)) {
        newY = prevDimensions.y + prevDimensions.height - newHeight;
      }
    } else {
      // For non-fixed aspect ratio elements
      newWidth = Math.max(MIN_WIDTH, Math.min(MAX_WIDTH, newWidth));
      newHeight = Math.max(MIN_HEIGHT, Math.min(MAX_HEIGHT, newHeight));

      // Prevent movement if minimum size is reached
      if (newWidth === MIN_WIDTH && newX !== prevDimensions.x) {
        newX = prevDimensions.x + prevDimensions.width - newWidth;
      }
      if (newHeight === MIN_HEIGHT && newY !== prevDimensions.y) {
        newY = prevDimensions.y + prevDimensions.height - newHeight;
      }
    }

    // Update slide element fields
    updateSlideElementField(clipId, elementId, 'width', newWidth);
    updateSlideElementField(clipId, elementId, 'height', newHeight);
    if(!isInLayoutGroup){
      updateSlideElementField(clipId, elementId, 'x', newX);
      updateSlideElementField(clipId, elementId, 'y', newY);
    }
    updatedDimensions.width = newWidth;
    updatedDimensions.height = newHeight;
    if(!isInLayoutGroup){
      updatedDimensions.x = newX;
      updatedDimensions.y = newY;
     }
  }

  return updatedDimensions;
};