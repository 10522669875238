import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';
import { useTransition, animated, useSpring } from 'react-spring';
import Icon from './misc/Icon';
import ExportManager from '../ExportManager';

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const ExportProgressTray = () => {
  const projectExports = useSelector(state => state.projectExports || {});
  const projects = useSelector(state => state.projects || []);
  const [progress, setProgress] = useState(0);
  const [lastNonZeroProgress, setLastNonZeroProgress] = useState(0);
  const [isExiting, setIsExiting] = useState(false);
  const [localExportStatus, setLocalExportStatus] = useState('');

  const exportProjectId = Object.keys(projectExports)[0];
  const exportStatusObject = exportProjectId ? projectExports[exportProjectId] : null;
  const exportProject = exportProjectId ? find(projects, { id: exportProjectId }) : null;

  const title = exportProject ? exportProject.name : '';
  
  useEffect(() => {
    let status = exportStatusObject?.status || '';
    if (status === 'in_progress') {
      status = 'generating';
    }
    if (status === 'generation_completed') {
      status = 'completed';
    }
    setLocalExportStatus(status);
  }, [exportStatusObject?.status]);

  const hasActiveExport = localExportStatus !== '';

  const estimatedGenerationTime = exportStatusObject?.estimatedGenerationTime || 0;
  const inProgressTime = exportStatusObject?.inProgressTime || 0;

  // Animation transition
  const transitions = useTransition(hasActiveExport && !isExiting, {
    from: { opacity: 0, transform: 'scale(0.95) translateY(0px)' },
    enter: { opacity: 1, transform: 'scale(1) translateY(0px)' },
    leave: { opacity: 0, transform: 'scale(0.95) translateY(0px)' },
    config: { tension: 300, friction: 18 },    
  });

  useEffect(() => {
    let animationFrameId;
    const updateProgress = () => {
      if (localExportStatus === 'generating') {
        const elapsedTime = (Date.now() - inProgressTime) / 1000;
        const newProgress = Math.min(elapsedTime / estimatedGenerationTime, 1);
        setProgress(newProgress);
        if (newProgress > 0) {
          setLastNonZeroProgress(newProgress);
        }
        animationFrameId = requestAnimationFrame(updateProgress);
      } else if (localExportStatus === 'completed') {
        setProgress(1);
        setLastNonZeroProgress(1);
      }
    };

    if (localExportStatus === 'generating') {
      setProgress(lastNonZeroProgress);
      animationFrameId = requestAnimationFrame(updateProgress);
    } else if (localExportStatus === 'completed') {
      setProgress(1);
      setLastNonZeroProgress(1);
    } else if (localExportStatus === 'enqueued') {
      setProgress(0);
      setLastNonZeroProgress(0);
    }
    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [localExportStatus, inProgressTime, estimatedGenerationTime, lastNonZeroProgress]);

  const clearExportStatus = useCallback(() => {
    setIsExiting(true);
    setTimeout(() => {
      ExportManager.cancelExport(exportProjectId);
    }, 300);
  }, [exportProjectId]);


  useEffect(() => {
    if (!hasActiveExport) {
      setIsExiting(false);
    }
  }, [hasActiveExport]);

  const traySpring = useSpring({
    height: localExportStatus === 'completed' ? 90 : 55,
    config: { tension: 300, friction: 26 }
  });

  const progressSpring = useSpring({
    from: { transform: `translateX(-100%)` },
    to: { transform: `translateX(${(progress - 1) * 100}%)` },
    immediate: progress === 0 && localExportStatus === 'enqueued',
    config: { tension: 340, friction: 40 }
  });


  const handleDownload = useCallback(() => {
    if (!exportProject || !exportStatusObject?.resultUrl) return;
    const filename = `${exportProject.name}.mp4`;
    window.ipcRenderer.send('download-exported-video', {
      url: exportStatusObject.resultUrl,
      filename
    });
  }, [exportProject, exportStatusObject?.resultUrl]);

  const timeLeft = Math.max(0, estimatedGenerationTime - (Date.now() - inProgressTime) / 1000);
  const formattedEstimatedTimeLeft = formatTime(Math.ceil(timeLeft));
  const showTimestamp = (localExportStatus === 'generating' || localExportStatus === 'downloading' || localExportStatus === 'creating_blob') && timeLeft > 1;

  
  
 
  return transitions((style, item) =>
    item && (
      <animated.div style={style} data-active-export={hasActiveExport ? 'true' : 'false'}  className='exportProgressTrayContainer'>
          <animated.div style={traySpring} data-export-state={localExportStatus} className='exportProgressTray'>
          
          <div className='exportProgressTray-primaryRow'>
            <div data-opacity-state={localExportStatus === 'completed' ? 'light' : 'normal'} className='exportProgressTray-primaryRow-title'>
              <div className='exportProgressTray-primaryRow-title-label'>
                {localExportStatus === 'completed' ? 'Export Complete' : 'Exporting'}
              </div>
            </div>      
            {/*}        
            <div data-visible-state={showTimestamp ? 'visible' : 'hidden'} className='exportProgressTray-primaryRow-timestamp'>
              {formattedEstimatedTimeLeft}
            </div>
            */}              
          </div>          



          <div className='exportProgressTray-progressBarContainer'>
            <div className='exportProgressTray-progressBar'>
              <animated.div style={progressSpring} className='exportProgressTray-progressBar-bar' />
              <div className='exportProgressTray-progressBar-track' />
            </div>
          </div>

          {localExportStatus === 'completed' &&
            <button onClick={clearExportStatus} className='exportProgressTray-closeButton'>
              <div className='exportProgressTray-closeButton-inner'>
                <Icon name='crossSemibold' />
              </div>
            </button>
          }

          {localExportStatus !== 'completed' &&
            <button onClick={clearExportStatus} className='exportProgressTray-cancelButton'>
              Cancel
            </button>
          }


          <div data-visible-state={localExportStatus==='completed' ? 'visible' : 'hidden'} className='exportProgressTray-downloadBtnContainer'>
            <button onClick={handleDownload} className='exportProgressTray-downloadBtn'>
              Download
            </button>              
          </div>          

        </animated.div>
      </animated.div>
    )
  );
};

export default ExportProgressTray;