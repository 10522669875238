import React, { useRef, useEffect, useState } from 'react';
import * as THREE from 'three';
import { animated, useSpring } from '@react-spring/three';
import renderOrders from '../renderOrders';
import createRoundedRectShape from '../utils/createRoundedRectShape';
import {calulateVideoTimeFromTimelineTime} from '../../timeline/utils/calulateVideoTimeFromTimelineTime';
import WebcamVideoVideoMaterial from './WebcamVideoVideoMaterial';
import {getWebcamRectForTime} from '../../utils/webcam/getWebcamRectForTime'


const WebcamVideoPreview = (props) => {
  const {clip,time,projectBackground,showWebcamStatic,isPlaying,isDraggingWebcam,isResizingWebcam,slideClips,cameraPreviewRect} = props
  
  const isAnimated = !isDraggingWebcam && !isResizingWebcam;
  const notAnimated = !isAnimated;
  const prevNotAnimated = useRef(notAnimated);
  const isPlaceholder = clip.isPlaceholder;

  // Radius state and timeout ref
  const [currentRadius, setCurrentRadius] = useState(20);
  const radiusTimeoutRef = useRef(null);

  let startHiddenDuration = clip.metadata.startHiddenDuration || 0;
  let endHiddenDuration = clip.metadata.endHiddenDuration || 0;
  const relativeTime = time - clip.startTime;
  
  let localTime = clip.isPlaceholder ? relativeTime : calulateVideoTimeFromTimelineTime(time, clip);

  let visible = false;
  if (!clip.isPlaceholder) {
    if (localTime >= clip.metadata.trimStart && localTime < clip.metadata.trimEnd) {
      visible = true;
    }
    if (clip.isUploadingVideo) {
      if (relativeTime >= clip.startTime && relativeTime < clip.endTime) {
        visible = true;
      }
    }
    if (relativeTime < startHiddenDuration) {
      visible = false;
    }
    if (relativeTime > clip.duration - endHiddenDuration) {
      visible = false;
    }
  } else {
    if (time > clip.startTime && time < clip.endTime) {
      visible = true;
    }
  }

  const SCENE_WIDTH = 1920;
  const SCENE_HEIGHT = 1080;

  let rect = {}
  if(cameraPreviewRect){
    rect = cameraPreviewRect
  }

  const left = rect.x;
  const top = rect.y;
  const displayWidth = rect.width;
  const displayHeight = rect.height;

  // auto stuff
  let frameBorderWidth = 10
  if((displayWidth > 0.49 * SCENE_WIDTH) || (displayHeight > 0.95 * SCENE_HEIGHT)){    
    //webcamDisplayRadius = 0
  }  

  if((displayWidth > 680) || (displayHeight > 700)){
    frameBorderWidth = 0    
  }  

  if((displayWidth < 360) || (displayHeight < 360)){
    frameBorderWidth = 6    
    //webcamDisplayRadius = 16;
  }  

  

  const webcamFrameDisplayRadius = currentRadius + frameBorderWidth;
  const meshWidth = displayWidth - (frameBorderWidth * 2);
  const meshHeight = displayHeight - (frameBorderWidth * 2);

  // Handle radius changes with delay after resize
  useEffect(() => {
    if (isResizingWebcam) {
      // During resize, set to fixed radius
      setCurrentRadius(20);
      
      // Clear any pending radius changes
      if (radiusTimeoutRef.current) {
        clearTimeout(radiusTimeoutRef.current);
      }
    } else {
      // After resize ends, wait then set final radius
      radiusTimeoutRef.current = setTimeout(() => {
        let targetRadius = 20;
        if ((displayWidth > 0.49 * SCENE_WIDTH) || (displayHeight > 0.95 * SCENE_HEIGHT)) {    
          targetRadius = 0;
        } 
        if ((displayWidth < 360) || (displayHeight < 360)) {
          targetRadius = 16;
        }
        if(displayWidth == 1200){ // overhalf float
          targetRadius = 16          
        } 
        if(displayWidth == 1200){ // overhalf float
          targetRadius = 16          
        } 

        setCurrentRadius(targetRadius);
      }, 500);
    }

    return () => {
      if (radiusTimeoutRef.current) {
        clearTimeout(radiusTimeoutRef.current);
      }
    };
  }, [isResizingWebcam, displayWidth, displayHeight]);

  // Springs
  const [positionSprings, positionApi] = useSpring(() => ({
    position: [
      left + displayWidth / 2 - SCENE_WIDTH / 2,
      -(top + displayHeight / 2 - SCENE_HEIGHT / 2),
      0
    ],
    config: { tension: 260, friction: 24 },
    immediate: notAnimated,
  }));

  const [meshDimensionSprings, meshDimensionApi] = useSpring(() => ({
    meshWidth,
    meshHeight,
    config: { tension: 260, friction: 24 },
    immediate: notAnimated,
  }));

  const [frameDimensionSprings, frameDimensionApi] = useSpring(() => ({
    displayWidth,
    displayHeight,
    config: { tension: 260, friction: 24 },
    immediate: notAnimated,
  }));

  useEffect(() => {
    positionApi.start({
      position: [
        left + displayWidth / 2 - SCENE_WIDTH / 2,
        -(top + displayHeight / 2 - SCENE_HEIGHT / 2),
        0
      ],
      immediate: notAnimated,
    });
  }, [left, top, displayWidth, displayHeight, notAnimated]);

  useEffect(() => {
    meshDimensionApi.start({
      meshWidth,
      meshHeight,
      immediate: notAnimated,
    });
  }, [meshWidth, meshHeight, notAnimated]);

  useEffect(() => {
    frameDimensionApi.start({
      displayWidth,
      displayHeight,
      immediate: notAnimated,
    });
  }, [displayWidth, displayHeight, notAnimated]);

  const deviceRef = useRef();
  const frameRef = useRef();

  // Animated geometries using current radius
  const AnimatedMeshGeometry = animated(({ meshWidth, meshHeight }) => {
    const shape = createRoundedRectShape(meshWidth, meshHeight, currentRadius);
    const geometry = new THREE.ShapeGeometry(shape);
    const uvs = new Float32Array(geometry.attributes.position.count * 2);
    for (let i = 0; i < geometry.attributes.position.count; i++) {
      const vertex = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, i);
      uvs[i * 2] = (vertex.x + meshWidth / 2) / meshWidth;
      uvs[i * 2 + 1] = (vertex.y + meshHeight / 2) / meshHeight;
    }
    geometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));
    return <primitive object={geometry} />;
  });

  const AnimatedFrameGeometry = animated(({ displayWidth, displayHeight }) => {
    const shape = createRoundedRectShape(displayWidth, displayHeight, webcamFrameDisplayRadius);
    const geometry = new THREE.ShapeGeometry(shape);
    const uvs = new Float32Array(geometry.attributes.position.count * 2);
    for (let i = 0; i < geometry.attributes.position.count; i++) {
      const vertex = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, i);
      uvs[i * 2] = (vertex.x + displayWidth / 2) / displayWidth;
      uvs[i * 2 + 1] = (vertex.y + displayHeight / 2) / displayHeight;
    }
    geometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));
    return <primitive object={geometry} />;
  });

  let placeholderBG = "rgb(170,170,170)";
  let webcamRenderOrder = displayWidth === SCENE_WIDTH ? 
    renderOrders.webcamVideoFullScreen : 
    renderOrders.webcamVideo;

  return (
    <animated.group position={positionSprings.position} renderOrder={webcamRenderOrder}>
      <mesh
        ref={deviceRef}
        visible={visible}
        renderOrder={webcamRenderOrder}
      >
        <AnimatedMeshGeometry {...meshDimensionSprings} />
        {isPlaceholder ? (
          <meshBasicMaterial 
            color={placeholderBG}
            // color="red"
            transparent
            opacity={1}
          />
        ) : (
          <WebcamVideoVideoMaterial 
            videoElement={clip.video}
            meshWidth={meshWidth}
            meshHeight={meshHeight}
            zoom={clip.metadata.zoom}
            xOffset={clip.metadata.xOffset}
            yOffset={clip.metadata.yOffset}
            colorAdjustments={clip.metadata.colorAdjustments}
            renderOrder={webcamRenderOrder}
            videoAssetWidth={clip.metadata.originalWidth}
            videoAssetHeight={clip.metadata.originalHeight}             
            opacity={1}
            faceBox={clip.metadata.faceBox}
          />              
        )}
      </mesh>

      {frameBorderWidth > 0 && (
        <mesh
          ref={frameRef}
          visible={visible}
          renderOrder={renderOrders.webcamVideoFrame}
        >
          <AnimatedFrameGeometry {...frameDimensionSprings} />
          <meshBasicMaterial 
            color="rgb(248,248,252)"
            transparent
            opacity={1}
          />
        </mesh>
      )}
    </animated.group>
  );
};

export default WebcamVideoPreview;