function getInitialState() {
  return JSON.parse(localStorage.getItem('libraryPageSettings')) || {
    lastVisitedRoute: '/library',
    pageSettings: {}
  };
}

export default function(state = getInitialState(), action) {
  switch (action.type) {
    case 'UPDATE_LAST_VISITED_ROUTE':
      return updateAndSave({ ...state, lastVisitedRoute: action.payload });
    
    case 'UPDATE_PAGE_SETTING':
      const { route, setting, value } = action.payload;
      return updateAndSave({
        ...state,
        pageSettings: {
          ...state.pageSettings,
          [route]: {
            ...state.pageSettings[route],
            [setting]: value
          }
        }
      });
    
    default:
      return state;
  }
}

function updateAndSave(newState) {
  localStorage.setItem('libraryPageSettings', JSON.stringify(newState));
  return newState;
}