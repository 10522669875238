import {fetch,post} from '../api'
import { Mixpanel } from '../Mixpanel'



export function createPaymentLink(priceId) {
	const requestBody={
		priceId:priceId
	}
	console.log('create payment link')
	console.log(priceId)

	return (dispatch) => post(`/create-payment-link`,requestBody)
		.then((response) => {
			Mixpanel.track('create_payment_link')
			return response
		})
		.catch((error) => {
			console.log(error)
			return error
		})
}


export function cancelSubscription() {
	return (dispatch) => post(`/cancel-subscription`)
		.then((response) => {
			return response
		})
		.catch((error) => {
			return error
		})
}


