import React, { useState,useEffect } from 'react';
import Icon from '../../misc/Icon';
import DPToggleGrid from './DPToggleGrid';
import DPColorPicker from './DPColorPicker';
import DPNumberInput from './DPNumberInput';
import { getOrgBrand } from '../../../utils/brands/getOrgBrand'
import { getBackgroundForId } from '../../../utils/brands/getBackgroundForId'
import DPTextInput from './DPTextInput'
import DPCheckbox from './DPCheckbox'
import DPItemAnimationPanel from './DPItemAnimationPanel';
import {getOrgDomain} from '../../../utils/getOrgDomain'

const EditorDetailPanelScreen = (props) => {

  const { trimMode, toggleTrimMode,clip,updateClipMetadata,updateClipBackgroundColor} = props

  const isChromeRecording = true 
  const showScreenDeviceOptions = clip.metadata.screenVideoApp=='chrome'
  let activeScreenDevice = 'original' 
  if(clip.metadata.deviceFrame=='color' || clip.metadata.deviceFrame=='colorBB'){
    activeScreenDevice = 'colorFrame'
  }

  const setColorFrame = (value) => {   
    if(value=='original'){
      updateClipMetadata(clip.id,{deviceFrame:null})
    }else{
      updateClipMetadata(clip.id,{deviceFrame:'color'})
    }
  };

  const orgBrand=getOrgBrand()
  let activeBg = 'auto' //null
  if(clip.metadata.backgroundId=='none'){
    activeBg= 'none'
  }else if(clip.metadata.backgroundId){
    activeBg=clip.metadata.backgroundId
  }

  const setBgStyle = (bgId)=>{
    let value=bgId 
    if(bgId=='auto'){
      value=null
    }
    updateClipBackgroundColor(clip.id,value)
  }

  let bgStyles=[{ id:'none',value: 'none', label: 'None' }, {id:'auto', value: 'auto', label: 'Auto' }]
  
  orgBrand.backgrounds.forEach((bg)=>{
    if(bg.type=='image'){
      bgStyles.push({ value: bg.src, type: 'image',id:bg.id })
    }else{
      bgStyles.push({ value: bg.rgba, type: 'solid',id:bg.id  })
    }
  })


  const filterStyles = [
    { value: 'original', label: 'Original' },
    { value: 'colorFrame', label: 'Color Frame' },    
  ];

  const shadowStyles = [
    { value: 'none', label: 'None' },
    { value: 'default', label: 'Default' },    
    { value: 'soft', label: 'Soft' },
  ];

  const [playbackRate, setPlaybackRate] = useState(1);


  const handleUpdateClayOverlayTitle = (title) => {
    updateClipMetadata(clip.id,{clayTitle:title})
  } 

  //if there is no profile picture then don't show the placeholder
  const handleUpdateHidePlaceholderClayProfileImage = (isHidden) => {
    updateClipMetadata(clip.id,{hidePlaceholderClayProfileImage:isHidden})
  }  

  const orgDomain = getOrgDomain()
  const isClay = (orgDomain=='clay.com' || orgDomain=='clay.run' || orgDomain=='yarn.so') 

  const handleUpdateMetadata = (field,value) => { 
    updateClipMetadata(clip.id,{[field]:value})
  }  

  const [hideBookmarksBar, setHideBookmarksBar] = useState(clip.metadata.deviceFrame === 'colorBB');

  useEffect(() => {
    if (clip.metadata.deviceFrame === 'color' || clip.metadata.deviceFrame === 'colorBB') {
      updateClipMetadata(clip.id, { deviceFrame: hideBookmarksBar ? 'colorBB' : 'color' });
    }
  }, [hideBookmarksBar]);


  return (
    <>
      <div className='editor-detailPanel-header'>
        <div className='editor-detailPanel-header-label'>
          Screen Clip
        </div>
        <div className='editor-detailPanel-header-vSpacer' />        
      </div>

      
            

      <div className='editor-detailPanel-row'>
      <DPToggleGrid
        items={filterStyles}
        activeItem={activeScreenDevice}
        onItemClick={setColorFrame}      
      />
      </div>

      {(clip.metadata.deviceFrame === 'color' || clip.metadata.deviceFrame === 'colorBB') &&         
        <>
          <div className='editor-detailPanel-negativeTopMargin' />
          <DPCheckbox 
            label='Hide bookmarks bar'
            checked={hideBookmarksBar}
            setChecked={setHideBookmarksBar}
          />
        </>
      }

      <div className='editor-detailPanel-divider' />
      <div className='editor-detailPanel-row'>
        <button 
          onClick={toggleTrimMode}          
          className='dpButton dpButton--labelOnly dpButton--fullWidth dpButton--strong'
        >
          <div className='dpButton-label'>
            Edit Trim
          </div>
        </button>
      </div>
      <div className='editor-detailPanel-divider' />
      
      <div className='editor-detailPanel-row'>
        <div className='editor-detailPanel-row-label'>
          Background
        </div>
        <div className='editor-detailPanel-row-hSpacer' />            
        <DPColorPicker                              
          activeColorId={activeBg}
          onChange={setBgStyle}
          options={bgStyles}          
        />       
      </div>    

      <div className='editor-detailPanel-divider' />
      
      {isClay && 
        <>
        <div className='editor-detailPanel-row'>
          <div className='editor-detailPanel-row-label'>
            Clay Title
          </div>
          <div className='editor-detailPanel-row-hSpacer' />            
          <DPTextInput            
            value={clip.metadata.clayTitle}
            setValue={handleUpdateClayOverlayTitle}
            width={120}
          />
        </div>

          <div className='editor-detailPanel-negativeTopMargin' />
          <DPCheckbox 
            label='Hide placeholder Clay image'
            checked={clip.metadata.hidePlaceholderClayProfileImage}
            setChecked={handleUpdateHidePlaceholderClayProfileImage}
          />
        </>
      
      }

      <div className='editor-detailPanel-divider' />



      <DPItemAnimationPanel 
        item={clip}
        handleUpdateMetadata={handleUpdateMetadata}
        activeStartTransition={props.clip.metadata.startTransitionType}
        activeEndTransition={props.clip.metadata.endTransitionType}
        itemType='screenVideo'
      />






     {/* <div className='editor-detailPanel-row'>
        <div className='editor-detailPanel-row-label'>
          Playback rate
        </div>
        <div className='editor-detailPanel-row-hSpacer' />            
        <DPNumberInput 
          min={0.5}
          max={10}
          value={playbackRate}
          setValue={setPlaybackRate}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          iconName='cornerRadius'              
        />
      </div>*/}
      
    </>
  );
};

export default EditorDetailPanelScreen;