import axios from 'axios';



export function generateCaptions( chunk) {

  return (dispatch) => {
    return axios.post(`https://manifold-captions.onrender.com/captions`, { chunk })
      .then((response) => {
        // console.log('captions response')
        // console.log(response)
        return response.data;
      })
      .catch((error) => {
        console.error('Error fetching captions:', error);
      });
  };
}



export function generateSubtitles( chunk) {
  return (dispatch) => {
    return axios.post(`https://manifold-captions.onrender.com/subtitles`, { chunk })
    // return axios.post(`http://127.0.0.1:1234/subtitles`, { chunk })
      .then((response) => {
        // console.log('subtitles response')
        // console.log(response)
        return response.data;
      })
      .catch((error) => {
        console.error('Error fetching captions:', error);
      });
  };
}