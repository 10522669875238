import axios from 'axios';
import {postWithArrayBuffer,put,post} from '../api'
import {createFileNameForAudioClip} from '../utils/createFileNameForAudioClip'
import {getVoiceForId} from '../utils/voiceover/getVoiceForId'
import pronunciationDictionary from './pronunciationDictionary';

const API_KEY="ddc7b3744c96d6c666b6e2fc7d9a39ce";
const RETRY_LIMIT = 3; // Maximum number of retries
const RETRY_DELAY = 1500; // Delay between retries in milliseconds



// const pronunciation_dictionary=[
//  // {word:'replo',ph:"/ˈrɛploʊ/"}
//    {word:'replo',ph:"/'rɛpː.loʊ/",alt:"rep-low"},
//    {word:'claygent',alt:"clayjent"}
// ]



//phonemes work for
//"eleven_monolingual_v1" e.g. sarah
//eleven_turbo_v2 e.g. tom
//otherwise use the alt word e.g. francesca

function applyPronunciations(text, dictionary,model_id) {
  ///
  if(model_id=='eleven_monolingual_v1'){
  dictionary.forEach(entry => {
  const regex = new RegExp(`\\b${entry.word}\\b`, 'gi');
  if(entry.ph){
      const phonemeTag = `<phoneme alphabet="ipa" ph="${entry.ph}">${entry.word}</phoneme>`;
  text = text.replace(regex, phonemeTag);
}else{
  text = text.replace(regex, entry.alt);
}

  });
  return text;
  }else{
    dictionary.forEach(entry => {
      const regex = new RegExp(`\\b${entry.word}\\b`, 'gi');
      text = text.replace(regex, entry.alt);
    });
  return text;
  }
}

export function fetchSpeech(originalText, clipId, voiceId, projectId) {
 // console.log('fetch speech here------------')
  return async (dispatch) => {
    try {

      const voice = getVoiceForId(voiceId);
      let stability = 0.53;
      let similarityBoost = 0.65;
      let styleExaggeration = 0.5;
      let speakerBoost = true;
      let model_id = "eleven_monolingual_v1";

      if (voice) {
        if (voice.stability) stability = voice.stability;
        if (voice.similarityBoost) similarityBoost = voice.similarityBoost;
        if (voice.styleExaggeration) styleExaggeration = voice.styleExaggeration;
        if ('speakerBoost' in voice) speakerBoost = voice.speakerBoost;
        if (voice.modelId) model_id = voice.modelId;
      }

      // Apply pronunciations on the client side
      let text = applyPronunciations(originalText, pronunciationDictionary, model_id);
     // console.log('text is--------------------')
     // console.log(text)


      const voiceSettings = {
        providerId: voice.providerId,
        stability,
        similarityBoost,
        styleExaggeration,
        speakerBoost,
        model_id
      };

      // const response = await postWithArrayBuffer('/tts', {
      //   text,
      //   voiceSettings
      // }
      // );
       const response = await post('/tts', {
        text,
        voiceSettings
      }
      );

       const {audio_base64,alignment,normalized_alignment} = response

      const byteCharacters = atob(audio_base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const audioData = new Blob([byteArray], { type: 'audio/mpeg' });

      const audioUrl = window.URL.createObjectURL(audioData);
      const fileName = createFileNameForAudioClip(clipId, voiceId, originalText);

     // console.log(alignment)


      // // console.log('respponse is')
      // // console.log(response)

      // const audioData = new Blob([response], { type: 'audio/mpeg' });
      // const audioUrl = window.URL.createObjectURL(audioData);
      // const fileName = createFileNameForAudioClip(clipId, voiceId, originalText);
      
      await handleSaveAudio(audioData, fileName, projectId, voiceId, originalText,alignment,normalized_alignment);
     
      // dispatch({ type: 'FETCH_SPEECH_SUCCESS', payload: audioUrl, clipId: clipId, text: text });
      return {audioUrl:audioUrl,alignment:alignment,normalized_alignment:normalized_alignment}
    } catch (error) {
      console.error('Error fetching speech:', error);
      // dispatch({ type: 'FETCH_SPEECH_ERROR', error });
      throw error;
    }
  };
}


export async function handleSaveAudio(blob, fileName, projectId, voiceId, text) {
  if (window.ipcRenderer) {
    try {
    // Convert Blob to Base64 for ipcRenderer
    const base64data = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

    // Pass the base64 string to ipcRenderer
    await window.ipcRenderer.invoke('save-audio', base64data, fileName, projectId);
    // Create voiceover entry in the database
    const voiceoverData = {
      project: projectId,
      filename: fileName,
      voice_id: voiceId,
      voiceover_text: text
    };
    const response = await post('/voiceovers', voiceoverData);
    // console.log('create voiceover response is')
    // console.log(response)
    const uploadURL = response.uploadURL;
    const voiceoverId = response.voiceover.id
    // Convert Blob to ArrayBuffer for uploading to S3
    const arrayBuffer = await new Promise((resolve, reject) => {
    const reader = new FileReader();
      reader.readAsArrayBuffer(blob);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
    // Upload the audio file to S3
    await axios.put(uploadURL, arrayBuffer, { headers: { 'Content-Type': 'audio/mpeg' } })
    //console.log('Audio upload to S3 finished successfully.');

    await put(`/voiceovers/${voiceoverId}/complete`);
    //console.log('Voiceover status updated to complete.');

  } catch (error) {
    console.error('Error saving audio', error);
  }
  } else return;
}

