import * as THREE from 'three';

export const createDropShadow = (shadowWidth, shadowHeight, shadowOptions, shadowRadius) => {
  const {
    xOffset,
    yOffset,
    blur,
    spread,
    shadowColor,
    opacity,
  } = shadowOptions;

  // Use scene dimensions for the shadow canvas to ensure no clipping
  const canvasWidth = 1920;
  const canvasHeight = 1080;
  const canvas = document.createElement('canvas');
  canvas.width = canvasWidth;
  canvas.height = canvasHeight;
  const context = canvas.getContext('2d');

  // Set up shadow properties
  context.filter = `blur(${blur}px)`;
  context.fillStyle = shadowColor;
  context.globalAlpha = opacity;

  // Calculate the center position for the shadow
  const centerX = (canvasWidth - shadowWidth) / 2;
  const centerY = (canvasHeight - shadowHeight) / 2;

  // Calculate the actual dimensions including spread
  const finalWidth = shadowWidth + spread * 2 + blur * 2;
  const finalHeight = shadowHeight + spread * 2 + blur * 2;
  const finalX = centerX + xOffset - spread - blur;
  const finalY = centerY - yOffset - spread - blur;

  // Begin the path for rounded rectangle
  context.beginPath();
  
  // Draw rounded rectangle with the specified radius
  // Top left corner
  context.moveTo(finalX + shadowRadius, finalY);
  // Top right line and corner
  context.lineTo(finalX + finalWidth - shadowRadius, finalY);
  context.quadraticCurveTo(finalX + finalWidth, finalY, finalX + finalWidth, finalY + shadowRadius);
  // Right side line and bottom right corner
  context.lineTo(finalX + finalWidth, finalY + finalHeight - shadowRadius);
  context.quadraticCurveTo(finalX + finalWidth, finalY + finalHeight, finalX + finalWidth - shadowRadius, finalY + finalHeight);
  // Bottom line and bottom left corner
  context.lineTo(finalX + shadowRadius, finalY + finalHeight);
  context.quadraticCurveTo(finalX, finalY + finalHeight, finalX, finalY + finalHeight - shadowRadius);
  // Left side line and top left corner
  context.lineTo(finalX, finalY + shadowRadius);
  context.quadraticCurveTo(finalX, finalY, finalX + shadowRadius, finalY);
  
  context.closePath();
  context.fill();

  // Reset alpha to default
  context.globalAlpha = 1.0;

  // Create texture and material for the shadow
  const texture = new THREE.CanvasTexture(canvas);
  texture.needsUpdate = true;
  const material = new THREE.MeshBasicMaterial({
    map: texture,
    transparent: true,
  });

  // Create the shadow mesh
  const shadowMesh = new THREE.Mesh(
    new THREE.PlaneGeometry(canvasWidth, canvasHeight),
    material
  );

  return shadowMesh;
};