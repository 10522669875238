import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import { useLoader, useThree, useFrame } from '@react-three/fiber';
import { useControls, button } from 'leva';
import * as THREE from 'three';
import CanvasBackground from './CanvasBackground';
import CHNumber from '../playground/charts/numbers/CHNumber';
import CHDonut from '../playground/charts/donut/CHDonut';
import CHBarChart from '../playground/charts/CHBarChart';
import { getOrgBrand } from '../utils/brands/getOrgBrand'
import find from 'lodash/find'
import { getBackgroundForId } from '../utils/brands/getBackgroundForId'
import {getTextColorForBackgroundId} from '../utils/brands/getTextColorForBackgroundId'
import renderOrders from './renderOrders'


const ChartContainer = ({ time, clip, showChartStatic, showChartAnimated,projectBackground}) => {
	const orgBrand=getOrgBrand()
	//const brandKit = find(brands,{name:activeBrand})
	const showNumber = clip.metadata.activeChartType=='number' 
	const showDonut = clip.metadata.activeChartType=='donut' 
	const showBarChart = clip.metadata.activeChartType=='bar'
	let chartData 
	let animationType
	let motionStyle
	if(showNumber){
		chartData = clip.metadata.numberChartData
		animationType = clip.metadata.numberChartData.animationType
		motionStyle = clip.metadata.numberChartData.motionStyle
	}else if(showDonut){
		chartData=clip.metadata.donutChartData
		animationType = clip.metadata.donutChartData.animationType
		motionStyle = clip.metadata.donutChartData.motionStyle
	}else if(showBarChart){
		chartData=clip.metadata.barChartData
		animationType = clip.metadata.barChartData.animationType
		motionStyle = clip.metadata.barChartData.motionStyle
	}


	const outOfRange = time < clip.startTime || time > (clip.startTime + clip.duration)	
	const localTime = time - clip.startTime;
	const isAnimatedHidden = outOfRange || !showChartAnimated
	const isPreviewHidden = outOfRange || !showChartStatic
	

	const fontFamily = orgBrand.font.fontFamily
	const fontWeight = "Bold"
	const chartLabelFontWeight = "Medium"



	const backgroundId = clip.metadata.backgroundId

	let chartsBackground = projectBackground
	if(backgroundId && backgroundId!=='none'){
		chartsBackground=getBackgroundForId(backgroundId)
	}

  const isNoneBGColor = backgroundId =='none'


	//let chartsTextColor = orgBrand.textColors[0].rgba
	const chartsTextColor=getTextColorForBackgroundId(chartsBackground.id).rgba


	// more advanced handling in future for charts colors
	let numberValueTextColor = chartsTextColor
	let numberLabelTextColor = chartsTextColor
	let donutValueTextColor = chartsTextColor
	let donutLabelTextColor = chartsTextColor
	let donutFillColor = chartsTextColor
	let donutPartRingMaxOpacity = 0.5
	let donutFullRingMaxOpacity = 0.2

	let barChartBarFillColor = chartsTextColor
	let barChartBarBorderColor = chartsTextColor

	let barChartValueLabelColor = chartsTextColor
	let barChartAxisLabelLabelColor = chartsTextColor
	let barChartTitleColor = chartsTextColor
	let barChartTitleMaxOpacity = 0.8

	let barChartMaxOpacities = {
    border: {
        default: 0.6,
        lowlight: 0.4,
        highlight: 0.9
    },
    fill: {
        default: 0.18,
        lowlight: 0.12,
        highlight: 0.28
    },
    value: {
        default: 0.8,
        lowlight: 0.6,
        highlight: 1.0
    },
    label: {
        default: 0.8,
        lowlight: 0.6,
        highlight: 1.0
    }
	};


	//
	// Yarn White BG - Dark Text Color
	//if(activeBrand === 'Yarn' && chartsTextColor && chartsTextColor === 'rgba(80, 81, 85, 1)'){		
	if(chartsTextColor && chartsTextColor === 'rgba(80, 81, 85, 1)'){		
		const yarnDarkerGrey = 'rgba(40, 40, 55, 1)'
		const yarnBlue = 'rgba(105, 133, 255, 1)'

		//numberValueTextColor = 'rgba(105, 133, 255, 1)' // blue
		numberValueTextColor = yarnDarkerGrey // darker grey
		
		donutValueTextColor = yarnDarkerGrey
		donutFillColor = yarnBlue
		donutPartRingMaxOpacity = 1
		donutFullRingMaxOpacity = 0.2

		barChartTitleColor = yarnDarkerGrey
		barChartTitleMaxOpacity = 1

		//barChartValueLabelColor = 'rgba(40, 40, 55, 1)'
		barChartAxisLabelLabelColor = 'rgba(40, 40, 55, 1)'

		barChartBarFillColor= yarnBlue
		barChartBarBorderColor= yarnBlue

		barChartMaxOpacities.border.default = 0
		barChartMaxOpacities.border.lowlight = 0
		barChartMaxOpacities.border.highlight = 0

		barChartMaxOpacities.fill.default = 0.7
		barChartMaxOpacities.fill.lowlight = 0.4
		barChartMaxOpacities.fill.highlight = 1

		barChartMaxOpacities.value.default = 0.8
		barChartMaxOpacities.value.lowlight = 0.6
		barChartMaxOpacities.value.highlight = 1

		barChartMaxOpacities.label.default = 0.6
		barChartMaxOpacities.label.lowlight = 0.4
		barChartMaxOpacities.label.highlight = 1
	}




	return (
		<>
			{chartsBackground && !outOfRange && !isNoneBGColor &&
        <CanvasBackground background={chartsBackground} renderOrder={renderOrders.graphBG} />    
      }

			{showNumber && chartData && chartData.value &&
				<>
					<CHNumber 
						isHidden={isAnimatedHidden}
						targetValue={chartData.value.number}
	          prefix={chartData.value.prefix}
	          suffix={chartData.value.suffix?chartData.value.suffix:''}
	          preLabel={chartData.aboveLabel}
	          postLabel={chartData.belowLabel}
	          time={localTime}          
	          animationType={clip.metadata.numberChartData.animationType}
	          fontFamily={fontFamily}
	          fontWeight={fontWeight}
	          numberValueTextColor={numberValueTextColor}
	          numberLabelTextColor={numberLabelTextColor}
					/>
										
					<CHNumber 							
						isHidden={isPreviewHidden}
						targetValue={chartData.value.number}
	          prefix={chartData.value.prefix}
	          suffix={chartData.value.suffix?chartData.value.suffix:''}
	          preLabel={chartData.aboveLabel}
	          postLabel={chartData.belowLabel}
	          time={localTime}          
	          animationType={'none'}
	          fontFamily={fontFamily}
	          fontWeight={fontWeight}
	          numberValueTextColor={numberValueTextColor}
	          numberLabelTextColor={numberLabelTextColor}

					/>
								
				</>
			}

			{showDonut && chartData && chartData.value &&
				<>
					
					<CHDonut
						isHidden={isAnimatedHidden}						
						targetValue={chartData.value.number}	          
	          //suffix={chartData.value.suffix?chartData.value.suffix:null}
	          suffix='%' // for now, can handle fancy stuff later
	          preLabel={chartData.aboveLabel}
	          postLabel={chartData.belowLabel}
	          time={localTime}
	          animationType={clip.metadata.donutChartData.animationType}
	          fontFamily={fontFamily}
	          fontWeight={fontWeight}
	          donutValueTextColor={donutValueTextColor}
	          donutLabelTextColor={donutLabelTextColor}
	          donutFillColor={donutFillColor}
	          donutPartRingMaxOpacity={donutPartRingMaxOpacity}
	          donutFullRingMaxOpacity={donutFullRingMaxOpacity}
					/>
					
					<CHDonut
						isHidden={isPreviewHidden}						
						targetValue={chartData.value.number}	          
	          //suffix={chartData.value.suffix?chartData.value.suffix:null}
	          suffix='%' // for now, can handle fancy stuff later
	          preLabel={chartData.aboveLabel}
	          postLabel={chartData.belowLabel}
	          time={localTime}
	          animationType={'none'}
	          fontFamily={fontFamily}
	          fontWeight={fontWeight}
	          donutValueTextColor={donutValueTextColor}
	          donutLabelTextColor={donutLabelTextColor}
	          donutFillColor={donutFillColor}
	          donutPartRingMaxOpacity={donutPartRingMaxOpacity}
	          donutFullRingMaxOpacity={donutFullRingMaxOpacity}
					/>					

										
					
				</>
			}



			
			{showBarChart && chartData &&
				<>
					<CHBarChart           
	          isHidden={isAnimatedHidden}
	          title={chartData.title}
	          rowData={chartData.barItems}
	          time={localTime}    
	          animationType={chartData.animationType}      
	          fontFamily={fontFamily}
	          titleFontWeight={fontWeight}
	          chartLabelFontWeight={chartLabelFontWeight}

	          barChartBarFillColor={barChartBarFillColor}
	          barChartBarBorderColor={barChartBarBorderColor}
	          barChartTitleColor={barChartTitleColor}
	          barChartTitleMaxOpacity={barChartTitleMaxOpacity}

	          barChartValueLabelColor={barChartValueLabelColor}
	          barChartAxisLabelLabelColor={barChartAxisLabelLabelColor}
	          barChartTitleColor={barChartTitleColor}
	          barChartTitleMaxOpacity={barChartTitleMaxOpacity}

	          barChartMaxOpacities={barChartMaxOpacities}
	        />

	        
	        <CHBarChart
	          isHidden={isPreviewHidden}
	          title={chartData.title}
	          rowData={chartData.barItems}
	          time={localTime}    
	          animationType={'none'}
	          fontFamily={fontFamily}
	          titleFontWeight={fontWeight}
	          chartLabelFontWeight={chartLabelFontWeight}

	          barChartBarFillColor={barChartBarFillColor}
	          barChartBarBorderColor={barChartBarBorderColor}

	          barChartValueLabelColor={barChartValueLabelColor}
	          barChartAxisLabelLabelColor={barChartAxisLabelLabelColor}
	          barChartTitleColor={barChartTitleColor}
	          barChartTitleMaxOpacity={barChartTitleMaxOpacity}

	          barChartMaxOpacities={barChartMaxOpacities}
	          
	        />
	        
        </>
			}
		

			
			
			
  
		</>
	);
};

export default ChartContainer;