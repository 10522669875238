
export const getLegacyRecordingVideoTimes = async (projectPath,captureId) => {
  try {
    let data;
    if(window.isElectron){
      if (captureId) {
        // If fsProjectName is provided, use IPC to read the file from the main process
        const relativePath = `recordings/${captureId}/metadata.json`;
        const content = await ipcRenderer.invoke('read-file', relativePath);
        data = JSON.parse(content);
      } else {
        // Otherwise, use fetch as before
        const METADATA_SRC = `${projectPath}/recording/metadata.json`;
        const response = await fetch(METADATA_SRC);
        if (!response.ok) {
          throw new Error('Network response was not ok');
       }
        data = await response.json();
      }
    }else{
      if (!captureId) {
        throw new Error('captureId is required for server-side export');
      }
      const s3BaseUrl = 'https://yarn-assets.s3.amazonaws.com/';
      const metadataUrl = `${s3BaseUrl}recordings/${captureId}/metadata.json`;
      
      const response = await fetch(metadataUrl);
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
      }
      data = await response.json();
     // console.log('Video times data:', data);
    }

    const displayRecorder = data.recorders.find(recorder => recorder.type === 'display');
    if (displayRecorder && displayRecorder.sessions && displayRecorder.sessions[0]) {
      const session = displayRecorder.sessions[0];      

      return { 
        recordingVideoProcessStartTimeMs: session.processTimeStartMs,         
        recordingVideoProcessEndTimeMs: session.processTimeEndMs, 
        recordingVideoDuration: (session.processTimeEndMs - session.processTimeStartMs) / 1000
        // recordingVideoProcessStartTimeMs: session.unixStartMs,         
        // recordingVideoProcessEndTimeMs: session.unixEndMs, 
        // recordingVideoDuration: (session.unixEndMs - session.unixStartMs) / 1000
      };
      
    }
  } catch (error) {
    console.error('There has been a problem with your fetch operation:', error);
    return null;
  }
};

