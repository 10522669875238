import store from '../../store'


export function getUploadedBackgroundTracks() {
  const state = store.getState();

// console.log('music download status')
// console.log(state.musicDownloads)

   const tracks = state.uploadedMusic.map(track => ({ ...track,isUpload:true,title:track.original_filename})).filter(track => {
        const trackStatus = state.musicDownloads[track.id];
        return !trackStatus || trackStatus != 'downloading';
    });
   
    return tracks
}

