import store from '../store'


export function orgIsSSO() {	
	const state = store.getState()
	let isSSO
	const organization=state.organization
	if(organization){
		const domain=organization.domain
		if(domain=='example.com' || domain=='faire.com' || domain=='ext.faire.com'){
			isSSO=true
		}
	}
	return isSSO 
}
