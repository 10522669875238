import { closeProject } from '../utils/projectUtils/closeProject';
import {navigateToLibrary} from '../utils/library/navigateToLibrary'

export const addTab = (tab) => ({
  type: 'ADD_TAB',
  payload: tab,
});

export const setActiveTab = (tabId) => ({
  type: 'SET_ACTIVE_TAB',
  payload: tabId,
});

// Simple action creator for removing a tab
const removeTabSimple = (tabId) => ({
  type: 'REMOVE_TAB',
  payload: tabId,
});


export const removeTab = (tabId, history) => (dispatch, getState) => {
  try {
    const state = getState();
    const tabs = state.tabs;
    const isActiveTab = tabs.find(tab => tab.id === tabId)?.active;
    if (isActiveTab) {
      if (tabs.length > 1) {
        const nextTab = tabs.find(tab => tab.id !== tabId);
        if (nextTab) {
          history.push(nextTab.path);
          dispatch(setActiveTab(nextTab.id));
        }
      } else {
        navigateToLibrary(history);
      }
    }
    dispatch({
      type: 'REMOVE_TAB',
      payload: tabId
    });
    closeProject(tabId);
    return
  } catch (error) {
    console.error('Error in removeTab action creator:', error);
    return 'error';
  }
};
