import React, { useState, useEffect } from 'react';
import { Rnd } from 'react-rnd';
import Icon from '../../../../misc/Icon';
import * as ContextMenu from '@radix-ui/react-context-menu'
//Buttons belong to the scene BEFORE the gap

const GAP_BUFFER=0.0001

const EditorTimelineSceneGapButtons = ({ scene, mergeScene, gapWidth, left, setShowDraggingCursor,addSceneAfterScene,handleSeek }) => {
  const minWidth = 3;
  const initialX = gapWidth - minWidth;

  const [size, setSize] = useState({ width: minWidth, height: '100%' });
  const [position, setPosition] = useState({ x: initialX, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [iconStyle, setIconStyle] = useState({ opacity: 0, transform: 'scale(1)' });
  const [rndKey, setRndKey] = useState(0);

  const leftHandleStyle = {
    cursor: isDragging ? 'grabbing' : 'grab',
  };

  const threshold = gapWidth * 0.3; // 30% of gapWidth

  const updateStyle = (width) => {
    if (width <= threshold) {
      const ratio = (width + 0.1) / threshold;
      const opacity = 0.15 + 0.5 * ratio; // 0.15 to 0.65
      const scale = 1 + 0.3 * ratio; // 1 to 1.3
      setIconStyle({ opacity: (isDragging || isHovering) ? opacity : 0, transform: `scale(${scale})` });
    } else {
      setIconStyle({ opacity: (isDragging || isHovering) ? 0.7 : 0, transform: 'scale(1.3)' });
    }
  };

  const handleResizeStart = () => {
    setShowDraggingCursor(true);
    setIsDragging(true);
    updateStyle(minWidth);
  };

  const handleResize = (e, direction, ref, delta, position) => {
    const newWidth = gapWidth - ref.offsetLeft;
    setSize({
      width: newWidth,
      height: '100%',
    });
    setPosition({ x: ref.offsetLeft, y: 0 });
    updateStyle(Math.max(delta.width, minWidth));    
  };

  const handleResizeStop = (e, direction, ref, delta, position) => {
    setShowDraggingCursor(false);
    setIsDragging(false);
    if (ref.offsetWidth > threshold) {
      mergeScene(scene.id);
    }
    setSize({ width: minWidth, height: '100%' });
    setPosition({ x: initialX, y: 0 });
    updateStyle(minWidth);
    setRndKey((prevKey) => prevKey + 1); // Force re-mount
  };

  useEffect(() => {
    setPosition({ x: initialX, y: 0 });
  }, [initialX]);

  useEffect(() => {
    updateStyle(size.width);
  }, [isHovering, isDragging]);

  let showTooltip = false
  if(isHovering || isDragging){
    showTooltip = true
  }

  return (
    <ContextMenu.Root modal={false} key={scene.id}> 
    <ContextMenu.Trigger asChild>
      <div
        style={{ width: `${gapWidth}px`, left: `${left}px` }}
        className={"editor-timeline-sceneGapButtonsContainer "}
      >
      <div        
        className={"editor-timeline-sceneGapButtons " + (isDragging ? 'editor-timeline-sceneGapButtons--isDragging' : 'editor-timeline-sceneGapButtons--notDragging')}
      >
        
        <div className='editor-timeline-sceneGapButtons-skipBtnContainer'>
          <button onClick={()=> handleSeek(scene.endTime-GAP_BUFFER)} className='editor-timeline-sceneGapButtons-skipBtn' />
          <button onClick={()=> handleSeek(scene.endTime+GAP_BUFFER)} className='editor-timeline-sceneGapButtons-skipBtn' />
        </div>
        

        <button onClick={()=> addSceneAfterScene(scene.id)} className={'editor-timeline-sceneGapButtons-add ' + (isDragging || isHovering ? ' editor-timeline-sceneGapButtons-add--hide ' : ' editor-timeline-sceneGapButtons-add--show ')}>
          <Icon name='plusMedium' />
        </button>

        <Rnd
          key={rndKey}
          className="editor-timeline-sceneGapButtons-mergeHandle"
          size={{ width: size.width, height: size.height }} // Adjust handle size as needed
          position={{ x: position.x, y: position.y }}
          bounds="parent"
          minWidth={minWidth}
          enableResizing={{ left: true, right: false, top: false, bottom: false }}
          resizeHandleClasses={{ left: 'editor-timeline-sceneGapButtons-resizeHandle' }}
          onResizeStart={handleResizeStart}
          onResize={handleResize}
          onResizeStop={handleResizeStop}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          resizeHandleStyles={{
            left: leftHandleStyle,
          }}
        >
          <div
            className="editor-timeline-sceneGapButtons-mergeHandle-inner"          
          />

        </Rnd>

        <div className={"editor-timeline-sceneGapButtons-mergeHandleIconContainer"} style={iconStyle}>
          <Icon name="arrowLeftLine" />
        </div>


        {/*}
        <div className={'editor-timeline-sceneGapButtons-mergeHandleTooltipContainer ' + (showTooltip ? ' editor-timeline-sceneGapButtons-mergeHandleTooltipContainer--show ' : ' editor-timeline-sceneGapButtons-mergeHandleTooltipContainer--hide ')}>
          <div className='editor-timeline-sceneGapButtons-mergeHandleTooltip'>
            Drag left to <br/>merge scene
          </div>
        </div>
        */}
        
        </div>
        </div>
        </ContextMenu.Trigger>
        <ContextMenu.Content collisionPadding={10} className='contextMenu'>
          <ContextMenu.Item onSelect={()=> addSceneAfterScene(scene.id)}>                          
            <div className='dropdownMenu-item-iconContainer'>
              <Icon name='plusMedium' />
            </div>
            Add Scene
          </ContextMenu.Item>
          <ContextMenu.Item onSelect={() => mergeScene(scene.id)}>
            <div className='dropdownMenu-item-iconContainer'>
              <Icon name='arrowLeftLine' />
            </div>
            Merge Scenes
          </ContextMenu.Item>
        </ContextMenu.Content>

      
    </ContextMenu.Root>            


  );
};

export default EditorTimelineSceneGapButtons;
