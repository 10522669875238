import { fetchScreenRecordingMetadata } from './fetchScreenRecordingMetadata';
import { getMasterRecordingCursorData } from './getMasterRecordingCursorData';

const blobCache = {};


export const fetchScreenRecordingURL = async (captureId) => {
  let videoURL;
  if (captureId) {
    let videoPath 
    videoPath = `screenRecordings/${captureId}/recording.mp4`;
    if (blobCache[videoPath]) {
      return blobCache[videoPath];
    } else {
      if(window.isElectron){
        const videoBuffer = await ipcRenderer.invoke('read-file-buffer', videoPath);
        const videoBlob = new Blob([videoBuffer], { type: 'video/mp4' });
        videoURL = URL.createObjectURL(videoBlob);
        blobCache[videoPath] = videoURL;
      }else{ //for Server side export page
        const s3BaseUrl = 'https://yarn-assets.s3.amazonaws.com/';
        videoURL = s3BaseUrl + videoPath;
      }
      return videoURL;
    }
  }; 
}

export const fetchLegacyVideoURL = async (captureId) => {
  let videoURL
  const videoPath = `recordings/${captureId}/channel-1-display-0.mp4`;
  if (blobCache[videoPath]) {
    return blobCache[videoPath];
  } else {
    if(window.isElectron){
      const videoBuffer = await ipcRenderer.invoke('read-file-buffer', videoPath);
      const videoBlob = new Blob([videoBuffer], { type: 'video/mp4' });
      videoURL = URL.createObjectURL(videoBlob);
    }else{
      const s3BaseUrl = 'https://yarn-assets.s3.amazonaws.com/';
      videoURL = s3BaseUrl + videoPath;
    }
    blobCache[videoPath] = videoURL;
    return videoURL;
  }
};




export const getMasterRecordingData = async (captureId) => {
  try {
    let masterScreenRecordingData={}
    const videoURL = await fetchScreenRecordingURL(captureId);
    masterScreenRecordingData.videoURL = videoURL
    const metadata = await fetchScreenRecordingMetadata(captureId)
    if (!metadata) {
      throw new Error('Failed to fetch recording metadata');
    }
    masterScreenRecordingData.recordingWidth = metadata.dimensions.width 
    masterScreenRecordingData.recordingHeight = metadata.dimensions.height 
    masterScreenRecordingData.recordingVideoDuration = metadata.durationSeconds 
    const cursorData = await getMasterRecordingCursorData(captureId)
    masterScreenRecordingData.cursorData = cursorData
    return masterScreenRecordingData
  } catch (error) {
    console.error('Failed to get master recording data:', error);
    return []; // Return an empty array in case of error
  }
};




