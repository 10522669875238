import React, { useMemo, useEffect, useRef } from 'react';
import { useSpring, animated } from '@react-spring/three';
import * as THREE from 'three';

// Create an animated version of the group
const AnimatedGroup = animated('group');

const SlideEditorCanvasPreviewFillImage = ({
  position,
  width,
  height,
  devMode,
  isDndDraggingAnyElement,
  isDndDraggingThisElement,
  imgSrc,
  originalImageWidth,
  originalImageHeight
}) => {
  const [x, y, z] = position;
  const spring = useSpring({
    position: [x, y, z],
    config: { tension: 1000, friction: 60 },
    immediate: !isDndDraggingAnyElement && !isDndDraggingThisElement,
  });

  let opacity = 1;
  if (devMode) opacity = 0.75;
  if (isDndDraggingAnyElement) opacity = 0.5;
  if (isDndDraggingThisElement) opacity = 0;

  const textureRef = useRef();
  const materialRef = useRef();

  // Create texture and material only once
  useMemo(() => {
    const loader = new THREE.TextureLoader();
    textureRef.current = loader.load(imgSrc);
    textureRef.current.colorSpace = THREE.SRGBColorSpace;

    materialRef.current = new THREE.MeshBasicMaterial({
      map: textureRef.current,
      transparent: true,
      opacity: opacity,
    });
  }, [imgSrc]);

  // Update texture and material when properties change
  useEffect(() => {
    if (!textureRef.current || !materialRef.current) return;

    const meshAspectRatio = width / height;
    const imageAspectRatio = originalImageWidth / originalImageHeight;

    let scaleX, scaleY;

    if (imageAspectRatio > meshAspectRatio) {
      // Image is wider than the mesh
      scaleY = 1;
      scaleX = (height / originalImageHeight) * imageAspectRatio;
    } else {
      // Image is taller than or equal to the mesh
      scaleX = 1;
      scaleY = (width / originalImageWidth) / imageAspectRatio;
    }

    textureRef.current.repeat.set(scaleX, scaleY);
    textureRef.current.offset.set((1 - scaleX) / 2, (1 - scaleY) / 2);

    materialRef.current.opacity = opacity;
    materialRef.current.needsUpdate = true;

  }, [width, height, originalImageWidth, originalImageHeight, opacity]);

  return (
    <AnimatedGroup position={spring.position}>      
      <mesh>
        <planeGeometry args={[width, height]} />
        <primitive object={materialRef.current} attach="material" />
      </mesh>
    </AnimatedGroup>
  );
};

export default SlideEditorCanvasPreviewFillImage;