import React, { useRef, useEffect } from 'react';
import { Text } from '@react-three/drei';
import {getTextColorForId} from '../../utils/brands/getTextColorForId'



const SlideTextPreviewElementMeshes = ({ 
  textElementOpacity, 
  initialProperties,
  lettersArray,
  fontFamily,
  fontWeight, 
  fontSize,
  letterSpacing,
  textColor,
  zIndex,  
}) => {
  const groupRefs = useRef(lettersArray.map(() => React.createRef()));
  const textRefs = useRef(lettersArray.map(() => React.createRef()));


  // Update refs when lettersArray changes
  useEffect(() => {
    groupRefs.current = lettersArray.map(() => React.createRef());
    textRefs.current = lettersArray.map(() => React.createRef());
  }, [lettersArray]);

  useEffect(() => {    
    lettersArray.forEach((letterObj, index) => {
      const textRef = textRefs.current[index];
      if (textRef && textRef.material) {
        let opacity = textElementOpacity;
        if (textElementOpacity === 0) {
          opacity = 0;
        }        
        if (letterObj.opacity !== undefined) {
          opacity = letterObj.opacity;
        }        
        console.log(opacity)
        textRef.material.opacity = opacity;
        textRef.material.transparent = true;
        textRef.material.needsUpdate = true;
      }
    });
  }, [textElementOpacity, lettersArray]);



  return (
    <>
      {lettersArray.map((letterObj, index) => {        
        let color = null
        if(letterObj.colorId){
          const colorObj=getTextColorForId(letterObj.colorId)
          if(colorObj){
            color=colorObj.rgba
          }
        }  
        const opacity = letterObj.opacity || textElementOpacity
        
        return(
          <group 
            key={index}
            ref={groupRefs.current[index]}
            position={[initialProperties.positionX, initialProperties.positionY, 0]}
            scale={[initialProperties.scale, initialProperties.scale, initialProperties.scale]}
            renderOrder={zIndex}
          >
            
              <Text        
                ref={textRefs.current[index]}
                anchorX="left"
                anchorY="top"
                fontSize={fontSize}                
                textAlign="left"
                letterSpacing={letterSpacing}
                font={`../fonts/${fontFamily}/${fontFamily}-${fontWeight}.woff`}
                position={[letterObj.rect.normalLeft, letterObj.rect.normalTop, 0]}
                renderOrder={zIndex}
              >
                <meshBasicMaterial renderOrder={zIndex} transparent={true} opacity={opacity} color={color || textColor} />
                  {letterObj.letter}
              </Text>            
          </group>
        )
        }      
      )}
    </>
  );
};

export default SlideTextPreviewElementMeshes;
