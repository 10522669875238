

export const parseDataIntoNumberChart = (data) => {
  // The 'data' parameter is now expected to be a single string
  //console.log(data);

  // Directly extract data from the string
  const cellData = extractData(data);
  if (!cellData) return { compatible: false };

  // Determine if the data is compatible as a number chart
  const numberString = cellData.value.number.replace(/,/g, '');
  //const compatible = !isNaN(parseFloat(numberString));


  const rawNumber = parseFloat(numberString);
  const compatible = !isNaN(rawNumber);

  return {
    compatible,
    aboveLabel: compatible ? cellData.aboveLabel : undefined,
    //value: compatible ? cellData.value : undefined,
    // value: compatible ? cellData.value : undefined,
    value: compatible ? { ...cellData.value, number: rawNumber } : undefined,
    belowLabel: compatible ? cellData.belowLabel : undefined
  };
};

export default parseDataIntoNumberChart;


////// REGEX FOR NUMBER
// {
//   prefix: e.g.$
//   value: 3,
//   suffix: e.g. %, /month
//   preLabel:e.g. up to,
//   postLabel:e.f. faster
// }

// Extract preLabel, number (with prefix and suffix), and postLabel from the string
const extractData = (str) => {
  // Start of the string or a whitespace character
  const startOrWhitespace = "(?:^|\\s)";
  // Captures any non-digit, non-space characters (prefix)
  const prefix = "([^\\d\\s]*)";
  // Matches 1 to 3 digits
  const initialDigits = "\\d{1,3}";
  // Optionally matches groups of a comma followed by three digits
  const commaGroups = "(,\\d{3})*";
  // Optionally matches a decimal point followed by one or more digits
  const decimalPart = "(\\.\\d+)?";
  

 const numericValue = "(\\d+(?:,\\d+)*(?:\\.\\d+)?)";
 

  //const numericValue = "(\\d{1,3}(?:,\\d{3})*|\\d+)(\\.\\d+)?";
  // Optionally captures non-digit, non-space characters (suffix)
  const suffix = "([^\\d\\s]+)?";
  // Captures any text following the number (post-label), starting with a space or end of the string
  //const postLabel = "(\\s.*|$)";
  const postLabel = "(?:\\s+(.*))?$";
  // Combine all parts to form the final regex
  const regexPattern = `${startOrWhitespace}${prefix}${numericValue}${suffix}${postLabel}`;
  const regex = new RegExp(regexPattern);
  // Use the constructed regex to match the string
  const match = str.match(regex);
  // console.log(match);
  // Process the match result
  return match ? {
    aboveLabel: str.substring(0, match.index).trim(),
    value: {
      prefix: match[1].trim(),
      number: match[2],
      suffix: match[3] ? match[3].trim() : '',
    },
     belowLabel: match[4] ? match[4].trim() : ''
  } : null;
};


// const extractData = (str) => {
//   // Start of the string or a whitespace character
//   const startOrWhitespace = "(?:^|\\s)";
//   // Captures any non-digit, non-space characters (prefix)
//   const prefix = "([^\\d\\s]*)";
//   // Matches 1 to 3 digits
//   const initialDigits = "\\d{1,3}";
//   // Optionally matches groups of a comma followed by three digits
//   const commaGroups = "(,\\d{3})*";
//   // Optionally matches a decimal point followed by one or more digits
//   const decimalPart = "(\\.\\d+)?";
  

//   // // Combines the parts for capturing the numeric value
//   // const numericValue = `(${initialDigits}${commaGroups}${decimalPart})`;

//   //const numericValue = "(\\d{1,3}(?:,\\d{3})*|\\d+)"; //handle morethan 3 numbers
//  // const numericValue = `(${initialDigits}${commaGroups}${decimalPart}|\\d+(\\.\\d+)?)`;
 
//  //const numericValue = "(\\d+(?:,\\d{3})*(?:\\.\\d+)?)"; //this works but breaks while typing e.g 10,00
//  const numericValue = "(\\d+(?:,\\d+)*(?:\\.\\d+)?)";
 

//   //const numericValue = "(\\d{1,3}(?:,\\d{3})*|\\d+)(\\.\\d+)?";
//   // Optionally captures non-digit, non-space characters (suffix)
//   const suffix = "([^\\d\\s]+)?";
//   // Captures any text following the number (post-label), starting with a space or end of the string
//   //const postLabel = "(\\s.*|$)";
//   const postLabel = "(?:\\s+(.*))?$";
//   // Combine all parts to form the final regex
//   const regexPattern = `${startOrWhitespace}${prefix}${numericValue}${suffix}${postLabel}`;
//   const regex = new RegExp(regexPattern);
//   // Use the constructed regex to match the string
//   const match = str.match(regex);
//   // console.log(match);
//   // Process the match result
//   return match ? {
//     aboveLabel: str.substring(0, match.index).trim(),
//     value: {
//       prefix: match[1].trim(),
//       number: match[2],
//       suffix: match[3] ? match[3].trim() : '',
//     },
//      belowLabel: match[4] ? match[4].trim() : ''
//   } : null;
// };




// // Extract preLabel, number (with prefix and suffix), and postLabel from the string
//   const extractData = (str) => {
//    // const match = str.match(/(?:^|\s)([^\d\s]*)(\d{1,3}(,\d{3})*(\.\d+)?)([^\d\s]+)?(\s.*|$)/);
//      const match = str.match(/(?:^|\s)([^\d\s]*)(\d{1,3}(,\d{3})*(\.\d+)?)([^\d\s]+)?(\s.*|$)/);
//     console.log(match)
//     return match ? {
//       preLabel: str.substring(0, match.index).trim(),
//       value: {
//         prefix: match[1].trim(),
//         number: match[2],
//         suffix: match[5] ? match[5].trim() : '',
//       },
//       postLabel: match[6].trim()
//     } : null;
//   };