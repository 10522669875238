import { getLegacyRecordingVideoTimes } from './getLegacyRecordingVideoTimes';




async function getDurationForLegacyCaptureId(captureId,isDevice) {
  const recordingVideoTimes = await getLegacyRecordingVideoTimes(null,captureId);
  if (!recordingVideoTimes) {
    throw new Error('Failed to fetch video times');
  }
  else return recordingVideoTimes.recordingVideoDuration
}


export { getDurationForLegacyCaptureId }


