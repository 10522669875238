export const getLegacyKeystrokeData = async (captureId) => {
  try {
    let data;
    const relativePath = `recordings/${captureId}/keyStrokes-0.json`;
    const content = await ipcRenderer.invoke('read-file', relativePath);
    data = JSON.parse(content)
    return data
  } catch (error) {
    console.error('There has been a problem with your fetch operation:', error);
    return [];
  }
};
