
export const numberChart={
  rawData:"Up to 90% faster",
  animationType:'splitScale',//none,scaleUp,moveUp,splitScale
  isAutoMotionStyle:true,
	aboveLabel:'Up to', //all three can have
	belowLabel:'faster',
	value:{
		prefix:null,
		number:90,
		suffix:'%'
	}
}


export const donutChart={
	rawData:"85% Fortune 500",
	animationType:'countUp',//none,scaleUp,moveUp,countUp
	isAutoMotionStyle:true,
	aboveLabel:'', //all three can have
	belowLabel:'Fortune 500',
	value:{
		number:'85',
		suffix:'%'
	}
}


export const barChart={
  rawData:[
  	[{ value: "QWen" }, { value: "58.6" }],
    [{ value: "LLava" }, { value: "62.4" }],
    [{ value: "Fuyu-8B" }, { value: "84.8" }],
    [{ value: "" }, { value: "" }],
    [{ value: "" }, { value: "" }],    
    [{ value: "" }, { value: "" }],
  ],
  rowHighlightStates:['default','default','default','default','default','default'], //values default,highlight or lowlight
  categoryColorIds:["chartBlue", "chartGreen", "chartPurple", "chartOrange", "chartSky", "chartRed"],
		barType:'vertical',
		animationType:"countUp",
		isAutoMotionStyle:true,
		title:'OKVQA Benchmarks',
		barItems:[
			{
				label:'QWen',
				highlightState:'default', //default,highlight or lowlight
				value:{
					prefix:'',
					number:'58.6',
					suffix:''
				}
			},
			{
				label:'LLava',
				highlightState:'default',
				value:{
					prefix:'',
					number:'62.4',
					suffix:''
				}
			},
			{
				label:'Fuyu-8B',
				highlightState:'highlight',
				value:{
					prefix:'',
					number:'84.8',
					suffix:''
				}
			}
		]
	
}
