import React, { useState, useEffect } from 'react';
import Icon from '../../misc/Icon';
import DPToggleGrid from './DPToggleGrid';
import DPNumberInput from './DPNumberInput';
import DPTextInput from './DPTextInput'
import DPItemAnimationPanel from './DPItemAnimationPanel';
import DPSelectMenu from './DPSelectMenu';
import DPLayoutBox from './DPLayoutBox'

const layoutGroupTypeOptions = [
  { value: 'hstack', label: 'Horizontal', iconName: 'fillImage' },
  { value: 'vstack', label: 'Vertical', iconName: 'fitImage' },        
];


const hAlignTypes = [
  { value: 'left', label: 'Left' },
  { value: 'center', label: 'Center' },
  { value: 'right', label: 'Right' }
];

const vAlignTypes = [
  { value: 'top', label: 'Top' },
  { value: 'middle', label: 'Middle' },
  { value: 'bottom', label: 'Bottom' }
];

const alignmentTypes = [
  { value: 'vertical', label: 'Vertical', iconName: 'arrowDownMedium' },    
  { value: 'horizontal', label: 'Horizontal', iconName: 'arrowRightMedium' }
];

const lockTypes = [
  { value: true, label: 'Locked', iconName: 'arrowDownMedium' },    
  { value: false, label: 'Unlocked', iconName: 'arrowRightMedium' }
];



const EditorDetailPanelLayoutGroup = (props) => {
  
  const {slide,layoutGroupId,updateLayoutGroupField}=props

  const layoutGroup = slide.getLayoutGroupById(layoutGroupId)

  let type 
  if(layoutGroup){
    type = layoutGroup.type
  }


  const handleUpdateLayoutGroupField=(field,value)=>{
    updateLayoutGroupField(slide.id,layoutGroup.id,field,value)
  }


  let vAlign,hAlign 
  if(layoutGroup){
    vAlign=layoutGroup.vAlign
    hAlign=layoutGroup.hAlign
  }

  const [isDragging, setIsDragging] = useState(false);


  const toggleLock = () => {
    handleUpdateLayoutGroupField('isLocked', !layoutGroup.isLocked)
  }

  let isLocked = false
  if(layoutGroup && layoutGroup.isLocked){
    isLocked = true
  }

  return (
    <>      
      <div className='editor-detailPanel-header'>
        <div className='editor-detailPanel-header-label'>
          Layout Group
        </div>     
        <div className='editor-detailPanel-row-hSpacer' />
        <button onClick={toggleLock} className={'dpButton dpButton--mixed ' + (isLocked ? '' : 'dpButton--light')}> 
          <div className='dpButton-iconContainer'>
            <Icon name={isLocked ? 'lockedFill' : 'locked'} />
          </div>
          <div className='dpButton-label'>
            {isLocked ? "Locked" : "Lock"}
          </div>
        </button>         
      </div>
      
      <div className='editor-detailPanel-row'>
        <DPSelectMenu
          value={type}
          onValueChange={(value)=>{handleUpdateLayoutGroupField('type',value)}}
          options={layoutGroupTypeOptions}
          width={124}
        />        
        <div className='editor-detailPanel-row-hSpacer' />
        <button onClick={()=>{props.ungroupLayoutGroup(layoutGroupId)}} style={{width: '84px'}} className='dpButton dpButton--labelOnly dpButton--strong'>
          <div className='dpButton-label'>
            Ungroup
          </div>
        </button>
      </div>     

      <div className='editor-detailPanel-divider' />
      <div className='editor-detailPanel-row editor-detailPanel-row--layoutBox'>
        <div className='editor-detailPanel-row-label'>
          Align Items
        </div>
        <div className='editor-detailPanel-row-hSpacer' />  
        <DPLayoutBox 
          vAlign={vAlign}
          setVAlign={(value)=>{handleUpdateLayoutGroupField('vAlign',value)}}
          hAlign={hAlign}
          setHAlign={(value)=>{handleUpdateLayoutGroupField('hAlign',value)}}        
          isDragging={isDragging}
          setIsDragging={setIsDragging}        
        />
      </div>      

     
        
      


      {/*}
      <div className='editor-detailPanel-row'>
        <div className='editor-detailPanel-row-label'>
          Items
        </div>
        <div className='editor-detailPanel-row-hSpacer' />            
        {type ==='hstack' && 
          <DPToggleGrid          
            items={vAlignTypes}
            activeItem={vAlign}
            onItemClick={(value)=>{handleUpdateLayoutGroupField('vAlign',value)}}
            //onItemClick={setVAlign}
            fixedWidth={170}
          />
        }
        {type ==='vstack' && 
          <DPToggleGrid          
            items={hAlignTypes}
            activeItem={hAlign}
            //onItemClick={setHAlign}
            onItemClick={(value)=>{handleUpdateLayoutGroupField('hAlign',value)}}
            fixedWidth={170}
          />
        }
      </div>

      <div className='editor-detailPanel-divider' />

       <div className='editor-detailPanel-row'>
        <div className='editor-detailPanel-row-label'>
          Pin
        </div>
        <div className='editor-detailPanel-row-hSpacer' />            
        {type ==='hstack' && 
          <DPToggleGrid          
            items={hAlignTypes}
            activeItem={hAlign}
            onItemClick={(value)=>{handleUpdateLayoutGroupField('hAlign',value)}}            
            fixedWidth={170}
          />
        }
        {type ==='vstack' && 
          <DPToggleGrid          
            items={vAlignTypes}
            activeItem={vAlign}            
            onItemClick={(value)=>{handleUpdateLayoutGroupField('vAlign',value)}}
            fixedWidth={170}
          />
        }
      </div>
      */}

      
          
 

    </>
  );
};

export default EditorDetailPanelLayoutGroup;