import {TextSelection} from "prosemirror-state"

//if you press tab it should go to the start of the next node

export const tabKeyHandler = () => {
	return (state, dispatch, view) => {
		const { selection } = state;
		const { $from, $to } = selection;
		let currentNode = null;
		let nodePos
		let tr = state.tr
		state.doc.nodesBetween($from.pos, $to.pos, (node, pos) => {
			if (node.type.name === 'transcriptChunk' || node.type.name === 'sceneHeader') {
				currentNode = node;
				nodePos=pos
				return false
			}
			
		})
		if (currentNode) {
			const newCursorPos = nodePos +currentNode.nodeSize +2
			if (newCursorPos < tr.doc.content.size) {
				const newSelection = TextSelection.create(tr.doc, newCursorPos);
				tr.setSelection(newSelection);
				dispatch(tr);
			}
			return true
		} 
		return false
	} 
}

