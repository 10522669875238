const TranscriptGroupNodeSpec = {
  group: "block",
  content: "(transcriptChunk | webcamRecordingChunk)+",
  attrs: {
    groupId: { default: null },
    groupType:{default:'standard'}, //standard or webcam-placeholder or webcam-recording
    isProcessing:{default:false},
    skippedWords: { default: [] }  // Array of { text, startTime, endTime } objects
  },
  toDOM(node) { return ['transcriptGroup', 0]; },
  parseDOM: [{ tag: 'transcriptGroup' }],
};

export default TranscriptGroupNodeSpec;
