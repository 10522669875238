import React, {useState} from 'react'
import { getBackgroundForId } from '../../utils/brands/getBackgroundForId'
 
const SLIDE_WIDTH = 1920
const SLIDE_HEIGHT = 1080

function SlidePreviewBG({ slideBGId}) {
  
  const slideBG = getBackgroundForId(slideBGId)
  // console.log(slideBG)



  return (
    <div className='slidePreview-background'>

      {/* if has a small src, use that */}
      {slideBG && slideBG.type === 'image' && slideBG.smallSrc && 
        <img 
          className='slidePreview-background-image' 
          src={slideBG.smallSrc} 
        />
      }

      {slideBG && slideBG.type === 'image' && slideBG.src && !slideBG.smallSrc && 
        <img 
          className='slidePreview-background-image' 
          src={slideBG.src} 
        />
      }

      {slideBG && slideBG.type === 'solid' && slideBG.rgba &&
        <div 
          className='slidePreview-background-color' 
          style={{background: `${slideBG.rgba}` }}
        />
      }

    </div>
  );
}

export default SlidePreviewBG;