
import store from '../../store'



export async function downloadMissingImages(images) {

	const imagesList = await ipcRenderer.invoke('get-image-uploads-list'); 

	for (const image of images) {
		const imageId=image.id
		if (!imagesList.includes(imageId)) {
			console.log(`Downloading missing image: ${imageId}`);
			ipcRenderer.invoke('download-image-file',imageId,image.delivery_url) 
			store.dispatch({ type: 'SET_IMAGE_FILE_TO_DOWNLOAD', imageId})
		}else{
		//	console.log('we already have the recording ')
		}
	}
}
