import {webcamSizes as sizes, webcamPositions as positions, SLIDE_WIDTH, SLIDE_HEIGHT, FLOAT_CAMERA_INSET,defaultSlideClipWebcamLayout} from './webcamConfigs'
import {calculateWebcamRectForSizeAndPosition} from './calculateWebcamRectForSizeAndPosition'


export function getWebcamRectForTime(webcamLayout, currentTime, slideClips) {
  const defaultRect = calculateWebcamRectForSizeAndPosition(webcamLayout.size, webcamLayout.position);
  // If no slide clips, return default
  if (!slideClips || slideClips.length === 0) {
    return defaultRect
  }
  const overlappingSlideClip = slideClips.find(clip => 
    currentTime >= clip.startTime && currentTime <= clip.endTime
  );
  if (!overlappingSlideClip) {
    return defaultRect
  }else{
    let slideRect
    const slideWebcamLayout = overlappingSlideClip.metadata.webcamLayout
    if(slideWebcamLayout){
      slideRect=calculateWebcamRectForSizeAndPosition(slideWebcamLayout.size, slideWebcamLayout.position);
    }else{
      slideRect=calculateWebcamRectForSizeAndPosition(defaultSlideClipWebcamLayout.size, defaultSlideClipWebcamLayout.position); 
    }
    return slideRect
  }
  
}