import React, { useRef, useEffect, useState } from 'react';
import { Text } from '@react-three/drei';
import { SpringAnimator } from '../../../utils/animations/SpringAnimator';
import SlideDonutChartPartRing from './SlideDonutChartPartRing'
import SlideDonutChartFullRing from './SlideDonutChartFullRing'
import SlideDonutChartPostLabel from './SlideDonutChartPostLabel'
import SlideDonutChartPreLabel from './SlideDonutChartPreLabel'
import renderOrders from '../../../renderOrders'

const FPS = 60; // Frames per second
const calculateAnimationDuration = 10 // messy, just to guess how many frames to animate for

const SlideDonutChartLabels = ({ time, formattedValue, suffix, postLabel, preLabel, animationType, isHidden, fontFamily, fontWeight, donutValueTextColor, donutLabelTextColor }) => {
  

  //
  // LABELS

  let valueFontSize = 300
  let preLabelFontSize = 60
  let postLabelFontSize = 84
  

  const smallPostLabelFontSize = 64  

  let valueMaxOpacity = 0.95
  let preLabelMaxOpacity = 0.4
  let postLabelMaxOpacity = 0.7
    
  if(isHidden){
    valueMaxOpacity = 0
    preLabelMaxOpacity = 0
    postLabelMaxOpacity = 0
  }

  let valueYPos
  let postLabelYPos
  let preLabelYPos

  if(!preLabel && !postLabel){
    valueYPos = 0
  }

  if(!preLabel && postLabel){
    valueYPos = 60
    postLabelYPos = -80
  }

  if(preLabel && !postLabel){
    preLabelYPos = 140
    valueYPos = -10    
  }

  if(postLabel && preLabel){    
    preLabelYPos = 180
    valueYPos = 20
    postLabelYPos = -130
  }


  // FONT SIZES

  let postLabelLength = 0 
  if(postLabel){
   postLabelLength = postLabel.length
  }  

  if(postLabelLength > 20){
    postLabelFontSize = smallPostLabelFontSize
  }

  


  //
  // FONT WEIGHTS
  const postLabelFontWeight = "Medium"
  const preLabelFontWeight = "Medium"


  // 
  // ANIMATE

  const springParams = { mass: 1, stiffness: 180, damping: 50 };
  const [valueScaleFrames, setValueScaleFrames] = useState([]);
  const [valueOpacityFrames, setValueOpacityFrames] = useState([]);

  useEffect(() => {
    const valueScaleSpring = new SpringAnimator(springParams.mass, springParams.stiffness, springParams.damping, 0.8);
    const valueOpacitySpring = new SpringAnimator(springParams.mass, springParams.stiffness, springParams.damping, 0);

    const scaleFrames = [];
    const opacityFrames = [];
    for (let frame = 0; frame < FPS * calculateAnimationDuration; frame++) {
      valueScaleSpring.setTarget(1);
      valueScaleSpring.simulate(1000 / FPS);
      scaleFrames.push(valueScaleSpring.position);

      valueOpacitySpring.setTarget(1);
      valueOpacitySpring.simulate(1000 / FPS);
      opacityFrames.push(valueOpacitySpring.position);
    }

    setValueScaleFrames(scaleFrames);
    setValueOpacityFrames(opacityFrames);
  }, []);

  const frameIndex = Math.floor(time * FPS);
  let valueScale = valueScaleFrames[frameIndex] || 1;
  const animatedValueOpacity = valueOpacityFrames[frameIndex] || 0;

  let valueOpacity = animatedValueOpacity * valueMaxOpacity;

  if(animationType === 'none'){
    valueOpacity = valueMaxOpacity
    valueScale = 1
  }

  if(animationType === 'fadeIn'){    
    valueScale = 1    
  }  

  
  if(isHidden){
    valueOpacity = 0
  }


  return (
    <>    

     <group scale={[valueScale, valueScale, valueScale]} position={[0,0,0]}>
      <Text
        // anchorX="right"
        anchorX="center"
        anchorY="middle"
        fontSize={valueFontSize}
        textAlign="right"
        font={`../fonts/${fontFamily}/${fontFamily}-${fontWeight}.woff`}
        position={[0, valueYPos, 0]}
        letterSpacing={-0.04}   
        renderOrder={renderOrders.graphObject}
      >
        <meshBasicMaterial transparent={true} color={donutValueTextColor} opacity={valueOpacity} />
          {`${formattedValue}${suffix}`}
      </Text>
    </group>


      {preLabel && 
        <SlideDonutChartPreLabel 
          preLabel={preLabel}
          fontSize={preLabelFontSize}
          fontWeight={preLabelFontWeight}
          targetYPos={preLabelYPos}          
          time={time}
          animationType={animationType}
          isHidden={isHidden}
          fontFamily={fontFamily}
          maxOpacity={preLabelMaxOpacity}
          color={donutLabelTextColor}
        />
      }

      {postLabel && 
        <SlideDonutChartPostLabel 
          postLabel={postLabel}
          fontSize={postLabelFontSize}
          fontWeight={postLabelFontWeight}
          targetYPos={postLabelYPos}          
          time={time}
          animationType={animationType}
          isHidden={isHidden}
          fontFamily={fontFamily}
          maxOpacity={postLabelMaxOpacity}
          color={donutLabelTextColor}
        />
      }





     

    </>
  );
};

export default SlideDonutChartLabels;
