import React, { useState, useEffect } from 'react';
import Icon from '../../../misc/Icon';
import EditorNewScreenClipPopoverSelectWindow from './EditorNewScreenClipPopoverSelectWindow'
import EditorNewScreenClipPopoverPhoneSetup from './EditorNewScreenClipPopoverPhoneSetup'
import EditorNewScreenClipPopoverPhoneRecord from './EditorNewScreenClipPopoverPhoneRecord'
import {getOrgDomain} from '../../../../utils/getOrgDomain'

const EditorNewScreenClipPopover = ({windowList, closeModal, devicesList }) => {
  const [activeTab, setActiveTab] = useState('desktop');
  const deviceFound = devicesList && devicesList.length > 0  
  const isFaire = getOrgDomain()=='faire.com'
  const includeiPadLabel = isFaire

  return (
    <React.Fragment>
      <div className='editorPopover-header'>
        <div className='editorPopover-header-tabs'>
          <button data-state={activeTab === 'desktop' ? "active" : "inactive"}  onClick={()=> setActiveTab('desktop')} className='editorPopover-header-tabs-tab'>
            <div className='editorPopover-header-tabs-tab-label'>
              Desktop              
            </div>   
            <div className='editorPopover-header-tabs-tab-bottomBorder' />
          </button>
          <button data-state={activeTab === 'iPhone' ? "active" : "inactive"} onClick={()=> setActiveTab('iPhone')} className='editorPopover-header-tabs-tab'>
            <div className='editorPopover-header-tabs-tab--iPhone-recordIconContainer'>
              {activeTab === 'iPhone' && !deviceFound && 
                <div className='editorPopover-header-tabs-tab--iPhone-recordIcon editorPopover-header-tabs-tab--iPhone-recordIcon--searching'>
                  <div className='editorPopover-header-tabs-tab--iPhone-recordIcon--searching-spinner' />                
                </div>
              }
              {activeTab === 'desktop' && !deviceFound && 
                <div className='editorPopover-header-tabs-tab--iPhone-recordIcon editorPopover-header-tabs-tab--iPhone-recordIcon--inactive'>                
                  <div className='editorPopover-header-tabs-tab--iPhone-recordIcon--inactive-spinner' />
                </div>
              }
              {deviceFound && 
                <div className='editorPopover-header-tabs-tab--iPhone-recordIcon editorPopover-header-tabs-tab--iPhone-recordIcon--active' />                
              }
            </div>
            {!includeiPadLabel &&
              <div className='editorPopover-header-tabs-tab-label'>
                iPhone
              </div>
            }   
            {includeiPadLabel &&
              <div className='editorPopover-header-tabs-tab-label'>
                iPhone<span className='editorPopover-header-tabs-tab-label-sub'>/</span>iPad
              </div>
            }          
            <div className='editorPopover-header-tabs-tab-bottomBorder' />
          </button>
        </div>      
      </div>

      {activeTab === 'desktop' && 
        <EditorNewScreenClipPopoverSelectWindow 
          windowList={windowList}
          closeModal={closeModal}
        />
      }

      {activeTab === 'iPhone' && !deviceFound && 
        <EditorNewScreenClipPopoverPhoneSetup 
          includeiPadLabel={includeiPadLabel}
        />
      }

      {activeTab === 'iPhone' && deviceFound && 
        <EditorNewScreenClipPopoverPhoneRecord 
          includeiPadLabel={includeiPadLabel}
          closeModal={closeModal}
          devicesList={devicesList}
        />
      }

    </React.Fragment>
  );
};

export default EditorNewScreenClipPopover;