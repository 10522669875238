
export function timeToPixels(time, pixelsPerSec, scenes, sceneGap) {


	let totalSceneTime = 0
	let totalPixels = 0
	for (let i = 0; i < scenes.length; i++) {
		if (time < totalSceneTime + scenes[i].duration || i==scenes.length-1) {
			let timeInCurrentScene = time - totalSceneTime;


			return totalPixels + (timeInCurrentScene * pixelsPerSec);
		}
		totalSceneTime += scenes[i].duration;
		totalPixels += scenes[i].duration * pixelsPerSec;
		if (i < scenes.length - 1) {
			totalPixels += sceneGap 
		}
	}


	return totalPixels
}