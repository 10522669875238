import React, { useState,useEffect,useCallback } from 'react';
import * as Popover from '@radix-ui/react-popover';
import Icon from '../../misc/Icon';
import TemplateBarTemplate from './TemplateBarTemplate'


function AllTemplatesPopover(props) {
  const { 
    children,    
    showPopover,
    handleClosePopover,
    slideTemplates,
    archivedSlideTemplates,
    useSlideTemplate,
    activeBackgroundId,    
  }=props

  const closePopover = () =>{
    handleClosePopover()
  }  

  const [showArchived, setShowArchived] = useState(false);
  const templatesToShow = showArchived ? archivedSlideTemplates : slideTemplates;
  
  return (
    <Popover.Root open={showPopover} modal={true}>
      <Popover.Trigger asChild>
        {children}
      </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content 
            side="top"
            sideOffset={0}
            align="end"    
            alignOffset={20}            
            className='popover popover--allTemplatesPopover forceDarkTheme'
            onEscapeKeyDown={closePopover}
            onPointerDownOutside={closePopover}
            onFocusOutside={closePopover}
            onInteractOutside={closePopover}   
            collisionPadding={10}     
          >
          <div className='popover--allTemplatesPopover-header'>
            <div className='popover--allTemplatesPopover-header-title'>
              {showArchived ? 'Archived Templates' : 'All Templates'}
            </div>
            <button onClick={() => setShowArchived(!showArchived)} className='popover--allTemplatesPopover-header-btn'>
              {showArchived ? 'Done' : 'Show Archived'}
            </button>
          </div>

          <div className='popover--allTemplatesPopover-list'>
            {templatesToShow.map((template, index) => (
              <TemplateBarTemplate
                key={index}
                template={template}
                displayWidth={210}
                useSlideTemplate={useSlideTemplate}
                activeBackgroundId={activeBackgroundId}
                archived={showArchived}      
                disabled={showArchived}
                popover
              />
            ))}
          </div>

        </Popover.Content>
      </Popover.Portal>

    </Popover.Root>
  );
}

export default AllTemplatesPopover;
