import React, { useMemo } from 'react';
import * as THREE from 'three';
import renderOrders from '../../../three/renderOrders'

const CurvedCorner = ({ radius, thickness, color, opacity, position, rotation, delta }) => {
  // Create the curve for the TubeGeometry
  const curve = useMemo(() => new THREE.EllipseCurve(
    0, 0,            // ax, aY
    radius, radius,  // xRadius, yRadius
    Math.PI, Math.PI * 1.5,  // aStartAngle, aEndAngle
    true,            // aClockwise
    0                // aRotation
  ), [radius]);

  // Create the TubeGeometry from the curve
  const tubeGeometry = useMemo(() => new THREE.TubeGeometry(new THREE.CurvePath().add(curve), 20, thickness, 8, false), [curve, thickness, delta]);

  // Create the material
  const material = useMemo(() => new THREE.MeshBasicMaterial({
    color: color,
    opacity: opacity,
    transparent: true
  }), [color, opacity]);

  return (
    <mesh
      geometry={tubeGeometry}
      material={material}
      position={position}
      rotation={rotation}
      renderOrder={renderOrders.graphObject}
    />
  );
};

const CHRectBorderCorners = ({ adjustedWidth, adjustedHeight, adjustedBorderRadius, borderColor, borderOpacity, borderLineWidth }) => {
  // Positions and rotations for each corner
  const corners = [
    { x: adjustedBorderRadius - borderLineWidth, y: adjustedHeight - adjustedBorderRadius + borderLineWidth, rotation: new THREE.Euler(0, 0, 0) },
    { x: adjustedWidth - adjustedBorderRadius + borderLineWidth, y: adjustedHeight - adjustedBorderRadius + borderLineWidth, rotation: new THREE.Euler(0, 0, -Math.PI / 2) },
    { x: adjustedWidth - adjustedBorderRadius + borderLineWidth, y: adjustedBorderRadius - borderLineWidth, rotation: new THREE.Euler(0, 0, Math.PI) },
    { x: adjustedBorderRadius - borderLineWidth, y: adjustedBorderRadius - borderLineWidth, rotation: new THREE.Euler(0, 0, Math.PI / 2) },
  ];

  return (
    <>
      {corners.map((corner, index) => (
        <CurvedCorner
          key={index}
          radius={adjustedBorderRadius}
          thickness={borderLineWidth / 2} // TubeGeometry uses radius, so we use half the border line width
          color={borderColor}
          opacity={borderOpacity}
          position={new THREE.Vector3(corner.x, corner.y, 0)}
          rotation={corner.rotation}
          delta={0.5}

        />
      ))}
    </>
  );
};

export default CHRectBorderCorners;
