import { getOrgBrand } from './getOrgBrand'
import getChartColors from '../charts/getChartColors'
import find from 'lodash/find'

export function getBackgroundForId(id) {
  const orgBrand = getOrgBrand()
  let background
  if (id) {
    if (id.toLowerCase().includes('chart')) {
      // If the id contains 'chart', look for it in the chart colors
      const chartColors = getChartColors()      
      background = find(chartColors, { id: id })
    } else {
      // Otherwise, look in the orgBrand backgrounds
      background = find(orgBrand.backgrounds, { id: id })
    }
  } else {
    background = find(orgBrand.backgrounds, { isDefault: true })
  }

  if (!background) {
    background = find(orgBrand.backgrounds, { isDefault: true })
  }

  return background
}