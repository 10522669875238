import React, {Component} from 'react'
import { connect } from 'react-redux'
import { withRouter} from 'react-router-dom'
import {createPaymentLink,cancelSubscription} from '../../actions/subscription'
import {suspendUser,activateUser,updateProMode} from '../../actions/user'
import SubscriptionPanel from '../../components/settings/SubscriptionPanel'


//subscription_status is active or cancelled
//if it is cancelled then there is a subscription_end_date



class SubscriptionContainer extends Component {


	handleUpgradeClick=(priceId)=>{
		this.props.createPaymentLink(priceId)
			.then(paymentLink => {
				//console.log(paymentLink); // Example log, replace with actual handling logic
				//Lets open it in external browser window
				//use open-outh-window IPC because we already have it 
				window.ipcRenderer.send('open-oauth-window',{url:paymentLink})   
			})
		.catch(error => {

		});
	}
	

	render() {
		const {organization,orgMembers} = this.props
		const {subscription_status,subscription_seats,next_billing_date,subscription_end_date} = organization
		return(
				<>
				<SubscriptionPanel 
					organization={organization}
					orgMembers={orgMembers}		
					activateUser={this.props.activateUser}
					suspendUser={this.props.suspendUser}       
					handleUpgradeClick={this.handleUpgradeClick}   
					cancelSubscription={this.props.cancelSubscription}      
					updateProMode={this.props.updateProMode}
					userIsProMode={this.props.userIsProMode}

				/>
			</>
		)
	}
}

function mapStateToProps(state,ownProps) {
	let userIsProMode=false 
	if(state.user && state.user.is_pro_mode){
		userIsProMode=true
	}
	return {
		organization:state.organization, //to force rerender when org loads
		orgMembers:state.orgMembers,
		user:state.user,
		userIsProMode:userIsProMode
	}
}

export default withRouter(connect(
	mapStateToProps,
	{
		createPaymentLink,
		cancelSubscription,
		suspendUser,
		activateUser,
		updateProMode

	})(SubscriptionContainer))


