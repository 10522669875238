import React, { useState, useEffect } from 'react';
import Icon from '../../../misc/Icon';
import {startDeviceRecording as startRecording} from '../../../../utils/recordingUtils/deviceRecording/startDeviceRecording';
import {stopDeviceRecording as stopRecording} from '../../../../utils/recordingUtils/deviceRecording/stopDeviceRecording';
import {cancelDeviceRecording as cancelRecording} from '../../../../utils/recordingUtils/deviceRecording/cancelDeviceRecording';


//on record start countdown and also trigger prepare to record
// when coundown gets to 0 if prepare to record has succeeded then start recording
//if still waiting for preparetorecord response then show a loading state
//if at any time pepare to record fails then cancel countdown and show an error message


const COUNTDOWN_TIME = 5

const EditorNewScreenClipPopoverPhoneRecord = ({closeModal, devicesList, includeiPadLabel}) => {  
  
  const [pollInterval, setPollInterval] = useState(null) //for recording elapsed time
  const [recordingElapsedTime, setRecordingElapsedTime] = useState(0)

  const [isRecording, setIsRecording] = useState(false)
  
  const [isPreparingToRecord, setIsPreparingToRecord] = useState(false)
  const [prepareSuccess, setPrepareSuccess] = useState(false);
  
  const [countdown, setCountdown] = useState(0);
  const [countdownCancelled, setCountdownCancelled] = useState(false);
  
  const [failedToPrepare, setFailedToPrepare] = useState(false)
 

  const recordStartSound = new Audio('/recordStart.mp3'); // Adjust the path as needed
  recordStartSound.volume = 0.4; // Set volume to 50%
  const recordEndSound = new Audio('/recordEnd.mp3'); // Adjust the path as needed
  recordEndSound.volume = 0.4; // Set volume to 50%

  useEffect(() => {
    return () => {
      if (pollInterval) {
        clearInterval(pollInterval);
      }
    };
  }, [])

 useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else if (countdown === 0 && !countdownCancelled) {
      checkPreparationAndStartRecording();
    }
  }, [countdown]);


  const checkPreparationAndStartRecording = () => {
    if (prepareSuccess) {
      setIsPreparingToRecord(false);
      actuallyStartRecording();
    } else if (isPreparingToRecord) {
      console.log('still preparing to record')
      //setError('Still preparing... Please wait.');
    } else {
     // setError('Failed to prepare device for recording.');
    }
  };

  const prepareToRecordDevice = async (deviceId) => {
    setIsPreparingToRecord(true);
    setFailedToPrepare(false)
    try {
      const response = await window.ipcRenderer.invoke('prepare-to-record-device', { deviceId });
      console.log(response)
      if (response.success) {
        setPrepareSuccess(true)
      }else{
        console.log('Failed to prepare device for recording')
        setFailedToPrepare(true)
        cancelCountdown()
      }
      // setIsPreparingToRecord(false);
    } catch (error) {
      //console.log('Failed to prepare device for recording')
     // setError('Failed to prepare device for recording.');
     // setIsPreparingToRecord(false);
    }
  };



  const actuallyStartRecording = () => { //actally start recording
    startRecording();
      recordStartSound.play();
      setIsRecording(true);
      const interval = setInterval(() => {
        setRecordingElapsedTime(prev => prev + 1);
      }, 1000);
      setPollInterval(interval);
  };


  const startDeviceRecording = () => {
    setCountdownCancelled(false);
    const deviceId = devicesList[0]; // Assuming devicesList[0] contains the device ID
    setCountdown(COUNTDOWN_TIME);
    prepareToRecordDevice(deviceId.id);
  };


  const stopDeviceRecording = async () => {
    clearInterval(pollInterval);
    recordEndSound.play(); // Play the sound when recording starts
    setIsRecording(false);
    setRecordingElapsedTime(0);
    await stopRecording()
    closeModal();
  };


  const cancelDeviceRecording = async () => {
    clearInterval(pollInterval);
    recordEndSound.play(); // Play the sound when recording starts
    setIsRecording(false);
    setRecordingElapsedTime(0);
    await cancelRecording()
    //closeModal();
  };

  const cancelCountdown = () => {
    recordEndSound.play();
    setCountdownCancelled(true);
    setCountdown(0);
    setIsPreparingToRecord(false)
  };



  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  let deviceLabel = 'iPhone'
  if(includeiPadLabel){
    deviceLabel = 'iPhone or iPad'
  }

  return (
    
      <div className='editorPopover-body'>
          <div className='editorPopover--screenClip-recordPhoneBtnContainer'>
            {!isRecording && !isPreparingToRecord &&                   
              <button onClick={startDeviceRecording} className='editorPopover--screenClip-recordPhoneBtn editorPopover--screenClip-recordPhoneBtn--start'>
                <div className='editorPopover--screenClip-recordPhoneBtn-iconContainer'>
                  <Icon name='deviceiPhone' />
                </div>
                <div className='editorPopover--screenClip-recordPhoneBtn-label'>
                  Record {deviceLabel}
                </div>
              </button>                            
            }

            {!isRecording && isPreparingToRecord &&                   
              <button onClick={cancelCountdown} className='editorPopover--screenClip-recordPhoneBtn editorPopover--screenClip-recordPhoneBtn--countdownActive'>
                
                <div className='editorPopover--screenClip-recordPhoneBtn-countdown'>
                  <div className='editorPopover--screenClip-recordPhoneBtn-countdown-digit'>
                    5
                  </div>
                  <div className='editorPopover--screenClip-recordPhoneBtn-countdown-digit'>
                    4
                  </div>
                  <div className='editorPopover--screenClip-recordPhoneBtn-countdown-digit'>
                    3
                  </div>
                  <div className='editorPopover--screenClip-recordPhoneBtn-countdown-digit'>
                    2
                  </div>
                  <div className='editorPopover--screenClip-recordPhoneBtn-countdown-digit'>
                    1
                  </div>
                </div>

                {/*}
                <div className='editorPopover--screenClip-recordPhoneBtn-label'>
                  {countdown}
                </div>                

                <div className='editorPopover--screenClip-recordPhoneBtn--countdownActive-progressBar'>
                  <div className='editorPopover--screenClip-recordPhoneBtn--countdownActive-progressBar-bar' />
                  <div className='editorPopover--screenClip-recordPhoneBtn--countdownActive-progressBar-track' />
                </div>
                */}
              </button>                            
            }
            {isRecording && 
              <>              
                <button onClick={stopDeviceRecording} className='editorPopover--screenClip-recordPhoneBtn editorPopover--screenClip-recordPhoneBtn--stop'>
                  <div className='editorPopover--screenClip-recordPhoneBtn--stop-square' />                  
                  <div className='editorPopover--screenClip-recordPhoneBtn-activeTimer'>
                    {formatTime(recordingElapsedTime)}
                  </div>
                </button>                              
              </>
            }
          </div>


          {/*}
          {!isRecording && !isPreparingToRecord && !failedToPrepare &&                   
            <div className='editorPopover-body-footer editorPopover-body-footer--startUnlocked'>
              <div className='editorPopover-body-footer-iconContainer'>
                <Icon name='unlocked' />
              </div>
              <div className='editorPopover-body-footer-label'>
                Keep iPhone unlocked
              </div>
            </div>
          }
          */}

          {!isRecording && !failedToPrepare &&                   
            <div className='editorPopover-body-footer editorPopover-body-footer--keepUnlocked'>
              <div className='editorPopover-body-footer-iconContainer'>
                <Icon name='unlocked' />
              </div>
              <div className='editorPopover-body-footer-label'>
                Keep {deviceLabel} unlocked while recording
              </div>
            </div>                          
          }
            
            
          {isRecording && !failedToPrepare &&
            <div onClick={cancelDeviceRecording} className='editorPopover-body-footer editorPopover-body-footer--cancelRecording'>              
              <div className='editorPopover-body-footer-label'>
                Cancel Recording
              </div>
            </div>
          }
                  
          {failedToPrepare &&
            <div className='editorPopover-body-footer editorPopover-body-footer--error'>              
              <div className='editorPopover-body-footer-label'>
                Double-check your {deviceLabel} and try again
              </div>
            </div>
          }

        </div>    
  );
};

export default EditorNewScreenClipPopoverPhoneRecord