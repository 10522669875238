const LayoutGroupNodeSpec = {
  attrs: {
    id: { default: 'defaultID' },
    depth: { default: 0 },
    type: { default: 'vStack' },
    hAlign: { default: 'left' },
    vAlign: { default: 'middle' },
    x: { default: 50 },
    y: { default: 100 },
    bottomMargin:{default:0},
    rightMargin:{default:0},
  },
  content: "(layoutGroup | slideElement)*",  // Allows nesting of layout groups and elements
};



 export default LayoutGroupNodeSpec
 