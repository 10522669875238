export function fontWeightToNumber(fontWeight) {
  switch (fontWeight) {
    case 'Regular':
      return 400;
    case 'Medium':
      return 500;
    case 'Semibold':
      return 600;
    case 'Bold':
      return 700;
    case 'Heavy':
      return 800;
    case 'Black':
      return 900;
    default:
      return 400; // Default value if no match
  }
}
