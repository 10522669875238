import {fetch,put} from '../api'
import { Mixpanel } from '../Mixpanel'
import LogRocket from 'logrocket';

export function fetchOrganization() {
	return (dispatch) => fetch(`/organization`)
		.then((org) => {
			let response={...org}



			if(response.domain=='cosmashealth.com'){
				response.subscription_status='active'
				response.next_billing_date='2024-03-06 21:48:14+00'
				response.subscription_seats=1
			}
			if(response.domain=='tankpayments.com'){
				response.subscription_status='active'
				response.next_billing_date='2024-08-24 21:48:14+00'
				response.subscription_seats=1
			}





			if(response.domain=='sent.dm'){
				response.subscription_status='active'
				response.next_billing_date='2024-03-24 21:22:15+00'
				response.subscription_seats=1
			}

			if(response.domain=='brightcanary.io'){
				response.subscription_status='active'
				response.next_billing_date='2024-06-08 21:22:15+00'
				response.subscription_seats=2
			}


			if(response.domain=='heyday.xyz'){
				response.subscription_status='active'
				response.next_billing_date='2024-06-31 21:22:15+00'
				response.subscription_seats=1
			}

			// if(response.domain=='cartage.ai'){
			// 	response.subscription_status='active'
			// 	response.next_billing_date='2024-07-06 21:22:15+00'
			// 	response.subscription_seats=1
			// }


if(response.domain=='aidygov.com'){
	
				response.subscription_status='active'
				response.next_billing_date='2024-08-10 21:22:15+00'
				response.subscription_seats=1
			}

		if(response.domain=='intryc.com'){
	
				response.subscription_status='active'
				response.next_billing_date='2024-08-12 21:22:15+00'
				response.subscription_seats=1
			}
	



		if(response.domain=='hypar.io'){
				response.subscription_status='active'
				response.next_billing_date='2024-09-27 21:22:15+00'
				response.subscription_seats=3
			}

			if(response.domain=='incident.io'){
				response.subscription_status='active'
				response.next_billing_date='2024-11-14 21:22:15+00'
				//response.subscription_seats=3
			}


			if(response.domain=='clay.com'){
				response.subscription_status='active'
				response.next_billing_date='2025-01-01 21:22:15+00'

			}





			// if(response.domain=='mavenagi.com'){
			// 	response.subscription_status='active'
			// 	response.next_billing_date='2024-06-17 20:00:25+00'
			// 	response.subscription_seats=3
			// }




			// if(response.domain=='axle.insure'){
			// 	response.subscription_status='active'
			// 	response.next_billing_date='2024-06-21 20:00:25+00'
			// 	response.subscription_seats=1
			// }


			// if(response.domain=='yarn.so'){
			// 	response.subscription_status='active'
			// 	response.next_billing_date='2024-06-21 20:00:25+00'
			// 	response.subscription_seats=1
			// }


			


	// if(response.domain=='yarn.so'){
	// 			response.subscription_status='active'
	// 			response.next_billing_date='2024-06-17 20:00:25+00'
	// 			response.subscription_seats=3
	// 		}


			



			// if(response.domain=='yarn.so'){
			// 	response.subscription_status='active'
			// 	response.next_billing_date='2024-06-08 21:22:15+00'
			// 	response.subscription_seats=2
			// }


			


		////// SSO people
			if(response.domain=='example.com'){
				response.subscription_status='active'
	
			}

			if(response.domain=='faire.com'){
				response.subscription_status='active'
	
			}

			dispatch({ type: 'FETCH_ORG_SUCCESS', response })
			return response
		})
		.catch((error) => {
			return error
		})
}



			// if(response.domain=='yarn.so'){
			// 	response.subscription_status=null
			// 	response.next_billing_date='2024-03-24 21:22:15+00'
			// 	response.subscription_seats=1
			// }


			// if(response.domain=='alphainfoscience.com'){
			// 	response.subscription_status='active'
			// 	response.next_billing_date='2024-05-04 20:29:35+00'
			// 	response.subscription_seats=1
			// }

			// if(response.domain=='befrontier.com'){
			// 	response.subscription_status='active'
			// 	response.next_billing_date='2024-05-12 20:29:35+00'
			// 	response.subscription_seats=1
			// }

			// if(response.domain=='smobi.com'){
			// 	response.subscription_status='active'
			// 	response.next_billing_date='2024-05-18 20:29:35+00'
			// 	response.subscription_seats=1
			// }

			
			// if(response.domain=='stack-ai.com'){
			// 	response.subscription_status='active'
			// 	response.next_billing_date='2024-05-18 20:29:35+00'
			// 	response.subscription_seats=1
			// }

			

			// if(response.domain=='catalistai.com'){
			// 	response.subscription_status='active'
			// 	response.next_billing_date='2024-03-24 21:22:15+00'
			// 	response.subscription_seats=1
			// }



		// if(response.domain=='lightdash.com'){
		// 		response.subscription_status='active'
		// 		response.next_billing_date='2024-03-26 21:22:15+00'
		// 		response.subscription_seats=1
		// 	}


		// if(response.domain=='catalistai.com'){
		// 		response.subscription_status='active'
		// 		response.next_billing_date='2024-04-19 01:45:33+00'
		// 		response.subscription_seats=1
		// 	}

			// if(response.domain=='greenlite.ai'){
			// 	response.subscription_status='active'
			// 	response.next_billing_date='2024-04-24 01:45:33+00'
			// 	response.subscription_seats=1
			// }


		// if(response.domain=='getsavings.app'){
		// 		response.subscription_status='active'
		// 		response.next_billing_date='2024-04-25 01:45:33+00'
		// 		response.subscription_seats=1
		// 	}



		// if(response.domain=='keeper.app'){
		// 		response.subscription_status='active'
		// 		response.next_billing_date='2024-05-01 20:29:35+00'
		// 		response.subscription_seats=1
		// 	}




			// if(response.domain=='posthog.com'){
			// 	response.subscription_status='active'
			// 	response.next_billing_date='2024-03-21 10:39:37+00'
			// 	response.subscription_seats=5
			// }



		// if(response.domain=='unsupervised.com'){
		// 		response.subscription_status='active'
		// 		response.next_billing_date='2024-04-05 17:09:57+00'
		// 		response.subscription_seats=1
		// 	}







			// if(response.domain=='sevn.ai'){
			// 	response.subscription_status='active'
			// 	response.next_billing_date='2024-04-13 03:23:11+00'
			// 	response.subscription_seats=1
			// }



		// if(response.domain=='voltview.co.uk'){
		// 		response.subscription_status='active'
		// 		response.next_billing_date='2024-04-03 19:24:17+00'
		// 		response.subscription_seats=1
		// 	}

			// if(response.domain=='cetient.com'){
			// 	response.subscription_status='active'
			// 	response.next_billing_date='2024-04-04 23:08:43+00'
			// 	response.subscription_seats=1
			// }

			// if(response.domain=='onegrep.dev'){
			// 	response.subscription_status='active'
			// 	response.next_billing_date='2024-04-04 23:08:43+00'
			// 	response.subscription_seats=1
			// }



		// if(response.domain=='spectacles.dev'){
		// 		response.subscription_status='active'
		// 		response.next_billing_date='2024-04-05 17:09:57+00'
		// 		response.subscription_seats=1
		// 	}


		// if(response.domain=='datrics.ai'){
		// 		response.subscription_status='active'
		// 		response.next_billing_date='2024-04-07 18:39:13+00'
		// 		response.subscription_seats=1
		// 	}


		// if(response.domain=='lexolve.com'){
		// 		response.subscription_status='active'
		// 		response.next_billing_date='2024-04-07 18:39:13+00'
		// 		response.subscription_seats=1
		// 	}


			// if(response.domain=='usetwine.com'){
			// 	response.subscription_status='active'
			// 	response.next_billing_date='2024-04-07 18:39:13+00'
			// 	response.subscription_seats=1
			// }





	// if(response.domain=='unfair.poker'){
	// 			response.subscription_status='active'
	// 			response.next_billing_date='2024-03-28 21:22:15+00'
	// 			response.subscription_seats=1
	// 		}




		// if(response.domain=='upduo.com'){
		// 		response.subscription_status='active'
		// 		response.next_billing_date='2024-04-05 17:09:57+00'
		// 		response.subscription_seats=1
		// 	}




			// if(response.domain=='jsonify.co'){
			// 	response.subscription_status='active'
			// 	response.next_billing_date='2024-04-09 09:44:21+00'
			// 	response.subscription_seats=1
			// }





			
			// if(response.domain=='opencopilot.so'){
			// 	response.subscription_status='active'
			// 	response.next_billing_date='2024-04-01 04:04:09+00'
			// 	response.subscription_seats=1
			// }





		// if(response.domain=='breadboard.com'){
		// 		response.subscription_status='active'
		// 		response.next_billing_date='2024-03-27 21:22:15+00'
		// 		response.subscription_seats=1
		// 	}



	// if(response.domain=='canvasapp.com'){
	// 			response.subscription_status='active'
	// 			response.next_billing_date='2024-03-28 21:22:15+00'
	// 			response.subscription_seats=1
	// 		}








	// if(response.domain=='finleycms.com'){
	// 			response.subscription_status='active'
	// 			response.next_billing_date='2024-03-28 21:22:15+00'
	// 			response.subscription_seats=1
	// 		}

			// if(response.domain=='validio.io'){
			// 	response.subscription_status='active'
			// 	response.next_billing_date='2024-03-28 21:22:15+00'
			// 	response.subscription_seats=1
			// }




		// if(response.domain=='vapi.ai'){
		// 		response.subscription_status='active'
		// 		response.next_billing_date='2024-04-01 04:04:09+00'
		// 		response.subscription_seats=1
		// 	}



			
			// if(response.domain=='parcha.ai'){
			// 	response.subscription_status='active'
			// 	response.next_billing_date='2024-04-01 23:24:44+00'
			// 	response.subscription_seats=1
			// }



			// if(response.domain=='aidevstudio.ai'){
			// 	response.subscription_status='active'
			// 	response.next_billing_date='2024-04-01 23:24:44+00'
			// 	response.subscription_seats=1
			// }


			// if(response.domain=='rootly.com'){
			// 	response.subscription_status='active'
			// 	response.next_billing_date='2024-04-01 04:04:09+00'
			// 	response.subscription_seats=1
			// }


	// if(response.domain=='pegbo.com'){
	// 			response.subscription_status='active'
	// 			response.next_billing_date='2024-03-28 21:22:15+00'
	// 			response.subscription_seats=1
	// 		}



			// if(response.domain=='osmoslearn.com'){
			// 	response.subscription_status='active'
			// 	response.next_billing_date='2024-03-24 21:22:15+00'
			// 	response.subscription_seats=1
			// }




			// if(response.domain=='stempath.com'){
			// 	response.subscription_status='active'
			// 	response.next_billing_date='2024-03-25 21:22:15+00'
			// 	response.subscription_seats=1
			// }



			// if(response.domain=='getfluently.app'){
			// 	response.subscription_status='active'
			// 	response.next_billing_date='2024-03-25 21:22:15+00'
			// 	response.subscription_seats=1
			// }


			// if(response.domain=='shiboleth.ai'){
			// 	response.subscription_status='active'
			// 	response.next_billing_date='2024-03-25 21:22:15+00'
			// 	response.subscription_seats=1
			// }


		// if(response.domain=='botterfly.app'){
		// 		response.subscription_status='active'
		// 		response.next_billing_date='2024-03-27 21:22:15+00'
		// 		response.subscription_seats=1
		// 	}








 

			// if(response.domain=='learnontil.com'){
			// 	response.subscription_status='active'
			// 	response.next_billing_date='2024-03-23 21:22:15+00'
			// 	response.subscription_seats=1
			// }






			// if(response.domain=='yarn.so'){
			// 	response.subscription_status='active'
			// 	response.next_billing_date='2024-03-21 10:39:37+00'
			// 	response.subscription_seats=5
			// }





			//27
			// if(response.domain=='govdash.com'){
			// 	response.subscription_status='active'
			// 	response.next_billing_date='2024-03-12 21:48:14+00'
			// 	response.subscription_seats=1
			// }


			// if(response.domain=='itsaffinity.com'){
			// 	response.subscription_status='active'
			// 	response.next_billing_date='2024-03-19 21:07:18+00'
			// 	response.subscription_seats=1
			// }


			// if(response.domain=='martini.ai'){
			// 	response.subscription_status='active'
			// 	response.next_billing_date='2024-03-13 20:37:34+00'
			// 	response.subscription_seats=1
			// }


