import { SpringAnimator } from './SpringAnimator';

const DEFAULT_THRESHOLD = 0.1 // delta from end value before treated done
const OPACITY_THRESHOLD = 0.0025

export function simulateAnimationDuration(initialValues, targetValues, animationParams, FPS) {
  let stableFramesCount = {};
  let lastValues = {};
  let frame = 0;
  let tempSprings = {};

  const precision = animationParams.precision || 0.01;
  const instant = animationParams.instant || false; // Retrieve the 'instant' flag from animationParams


  // Initialize temporary springs for each property
  Object.keys(initialValues).forEach(property => {
    tempSprings[property] = new SpringAnimator(
      animationParams.mass,
      animationParams.stiffness,
      animationParams.damping,
      initialValues[property],
      0,
      precision,
      instant
    );
    stableFramesCount[property] = 0;
    lastValues[property] = initialValues[property];
  });

  // Simulate until all properties are stable for 3 frames in a row or until a specific property, like opacity, reaches zero
  while (true) {
    let allPropertiesStable = true;

    Object.keys(targetValues).forEach(property => {
      tempSprings[property].setTarget(targetValues[property]);
      tempSprings[property].simulate(1000 / FPS);
      const currentPropertyValue = tempSprings[property].position;

      let stabilityThreshold = DEFAULT_THRESHOLD // this was all new before HN, should work
      if(property === 'opacity'){
        stabilityThreshold = OPACITY_THRESHOLD // this is a bit fudgy
      }    

      // Check if the current value is stable
      if (Math.abs(currentPropertyValue - lastValues[property]) < stabilityThreshold) {
        stableFramesCount[property]++;
      } else {
        stableFramesCount[property] = 0;
        allPropertiesStable = false;
      }
      lastValues[property] = currentPropertyValue;
    });

    // Break if all properties are stable for 3 consecutive frames, or if opacity has reached zero
    if (Object.values(stableFramesCount).every(count => count >= 10) || Object.keys(targetValues).some(key => key === 'opacity' && lastValues[key] < OPACITY_THRESHOLD)) {
      break;
    }

    frame++;
  }

  // Return the number of frames it took for the animation to stabilize or when a property like opacity reached zero
  return frame / FPS;
}
