import {Schema} from 'prosemirror-model'
import EditorNodes from './nodes/EditorNodes'
import EditorMarks from './marks/EditorMarks'

const editorSchema = new Schema({
  nodes:EditorNodes,
  marks: EditorMarks
})


export default editorSchema