import React, { useRef, useEffect, useContext,useMemo } from 'react';
import * as THREE from 'three';


const ScreenVideoMaterial = ({ videoElement, opacity}) => {
  


  const texture = useMemo(() => {
   if (videoElement) {
      const videoTexture = new THREE.VideoTexture(videoElement);
      videoTexture.colorSpace = THREE.SRGBColorSpace;
      videoTexture.needsUpdate=true
      return videoTexture;
    }
  }, [videoElement]);


//   useEffect(() => {
//   if (texture) {
//     console.log('Texture has been updated');
//     // You can add more detailed logging here if needed
//   }
// }, [texture]);
  


  const material = useMemo(() => {
    return new THREE.MeshBasicMaterial({
      map: texture,
      transparent: true,
      opacity: opacity,
    });
  }, [texture, opacity]);


  return <primitive object={material} attach="material" />;
};

export default ScreenVideoMaterial;

