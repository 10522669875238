import React, { useState, useEffect } from 'react';
import Icon from '../misc/Icon'

const RecordWebcamWindow = () => {
  

  return (    
    <div className='recordWebcamWindow'>
      <div className='recordWebcamWindow-mainContainer'>
        <div className='recordWebcamWindow-main'>
          
        </div>
      </div>
      <div className='recordWebcamWindow-trayContainer'>
        <div className='recordWebcamWindow-tray'>

        </div>
      </div>
    </div>
  );
};

export default RecordWebcamWindow;