import {getVideoFreezePeriods} from '../../../utils/recordingUtils/getVideoFreezePeriods'


export const identifyLegacyStillVideoPeriods = async (captureId,isDeviceRecording,noiseLevel) => {
  try {
    const freezePeriods = await getVideoFreezePeriods(captureId,isDeviceRecording,noiseLevel)
    if (!freezePeriods) {
      throw new Error('Failed to fetch freezePeriods');
    }
    return freezePeriods
  } catch (error) {
    console.error('Failed to identify silent video periods:', error);
    return []; // Return an empty array in case of error
  }
};
