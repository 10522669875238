import React, { useEffect } from 'react';
import Icon from '../../../misc/Icon';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

const TranscriptPanelDragHandleDropdown = ({ showDropdown, setShowDropdown,playClipFromTranscript,recalculateAudioClip,clipId, requiresUpdate, isPreviewPlaying, nearBottom,cancelPreviewingAudioClip }) => {
  

   useEffect(() => {
    const handleKeyDown = (event) => {
      if (showDropdown) {
        if (event.key === 'Enter') {
          if (event.metaKey || event.ctrlKey) { // Command/Ctrl + Enter
            event.preventDefault();
            if (isPreviewPlaying) {
              cancelPreviewingAudioClip(clipId);
            } else {
              playClipFromTranscript(clipId);
            }
          } else if (event.shiftKey) { // Shift + Enter
            event.preventDefault();
            recalculateAudioClip(clipId);
          }
        } else if (event.key === 'Escape') {
          setShowDropdown(false);
        }
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [showDropdown, isPreviewPlaying, clipId, playClipFromTranscript, cancelPreviewingAudioClip, recalculateAudioClip, setShowDropdown]);


  return (    
    <DropdownMenu.Root open={showDropdown}> 
      <DropdownMenu.Trigger asChild>
        <div className='editor-transcriptPanel-dropdownTrigger' />
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
      <DropdownMenu.Content             
        side={nearBottom ? "top" : "bottom"}
        align="start"
        alignOffset={-13}            
        className='dropdownMenu dropdownMenu--transcriptChunk forceDarkTheme'
        onEscapeKeyDown={()=> setShowDropdown(false)}
        onPointerDownOutside={()=> setShowDropdown(false)}
        onFocusOutside={()=> setShowDropdown(false)}
        onInteractOutside={()=> setShowDropdown(false)}        
      >

        {!isPreviewPlaying &&
        <DropdownMenu.Item onSelect={()=>{playClipFromTranscript(clipId)}} className={'dropdownMenu-item-transcript ' + (isPreviewPlaying ? ' dropdownMenu-item-transcript--previewPlaying ' : ' ') + (requiresUpdate ? ' dropdownMenu-item-transcript--disabled ' : ' ')}>
          <div className='dropdownMenu-item-iconContainer'>
             <Icon name='chunkPreview' /> 
          </div>
              <div className='dropdownMenu-item-transcript-label'>
                Preview
              </div>
              <div className='dropdownMenu-item-transcript-shortcut'>
                Command Enter
              </div>                 
        </DropdownMenu.Item>
      }

      {isPreviewPlaying &&
         <DropdownMenu.Item onSelect={()=>{cancelPreviewingAudioClip(clipId)}} className={'dropdownMenu-item-transcript ' + (isPreviewPlaying ? ' dropdownMenu-item-transcript--previewPlaying ' : ' ') + (requiresUpdate ? ' dropdownMenu-item-transcript--disabled ' : ' ')}>
          <div className='dropdownMenu-item-iconContainer'>
            <Icon name='chunkPause' />           
          </div>
              <div className='dropdownMenu-item-transcript-label'>
                Pause
              </div>
              <div className='dropdownMenu-item-transcript-shortcut'>
                Command Enter
              </div>    
        </DropdownMenu.Item>
      }


        <DropdownMenu.Item onSelect={()=>{recalculateAudioClip(clipId)}}  className={'dropdownMenu-item-transcript' + (requiresUpdate ? ' dropdownMenu-item-transcript--disabled ' : ' ')}>
          {!requiresUpdate && 
            <div className='dropdownMenu-item-iconContainer'>
              <Icon name='chunkRegen' />
            </div>
          }
          {requiresUpdate && 
            <div className='dropdownMenu-item-iconContainer'>
              <div className='dropdownMenu-item-transcript-regenSpinnerContainer'>
                <div className='dropdownMenu-item-transcript-regenSpinner' />
              </div>
            </div>
          }
          <div className='dropdownMenu-item-transcript-label'>
            Regen
          </div>              
          <div className='dropdownMenu-item-transcript-shortcut'>
            Shift Enter
          </div>
        </DropdownMenu.Item>           
      </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>          
  );
};

export default TranscriptPanelDragHandleDropdown;