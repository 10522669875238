


export default function getSlideConfigs() {
  return{
    slideHPadding:160,
    slideVPadding:80,
    slideWidth:1920,
    slideHeight:1080,
    slideAspect:16/9
  }
}




