// selectionPlugin.js
import { Plugin, PluginKey, TextSelection } from 'prosemirror-state';

// Define a unique PluginKey for the selection plugin
const selectionPluginKey = new PluginKey('selectionPlugin');

// Helper function to find the parent node matching a predicate
function findParentNode(predicate, $pos) {
  for (let depth = $pos.depth; depth > 0; depth--) {
    const node = $pos.node(depth);
    if (predicate(node)) {
      return { node, pos: $pos.before(depth), depth };
    }
  }
  return null;
}

// Selection plugin to adjust selection behaviors
const selectionPlugin = new Plugin({
  key: selectionPluginKey,
  props: {
    // Handle selection changes
    handleDOMEvents: {
      mouseup: (view, event) => {
        adjustSelection(view);
        return false;
      },
      keyup: (view, event) => {
        adjustSelection(view);
        return false;
      },
    },
  },
});

function adjustSelection(view) {
  const { state, dispatch } = view;
  const { selection, doc } = state;
    const { from, to } = selection;

  const $from = doc.resolve(from);
  const $to = doc.resolve(to);
  // console.log('selection is',from)

  // if (selection.empty) {
  //   return;
  // }
  if (selection.empty) {

    const parent = findParentNode(
      node => node.type.name === 'webcamRecordingChunk',
      $from
    );


    if (!parent) return;

    const currentNode = $from.parent;

    if (currentNode.type.name === 'webcamTranscriptWord') {
      const wordEnd = $from.after($from.depth);
      
      // // If we're at the very end of the word, move one position back
      // if (from === wordEnd) {
        const newPos = Math.max(wordEnd - 2, $from.before($from.depth));
        dispatch(state.tr.setSelection(TextSelection.create(doc, newPos)));
      //}
    }
    return;
  }




  // Find the transcript groups at the selection start and end
  const startGroup = findParentNode(node => node.type.name === 'transcriptGroup', $from);
  const endGroup = findParentNode(node => node.type.name === 'transcriptGroup', $to);

  if (!startGroup || !endGroup) return;

  // If the selection crosses transcript group boundaries, adjust it
  if (startGroup.pos !== endGroup.pos) {
    const adjustedFrom = Math.max(from, startGroup.pos + 1); // +1 to skip the group node start
    const adjustedTo = Math.min(to, startGroup.pos + startGroup.node.nodeSize - 1); // -1 to skip the group node end

    dispatch(state.tr.setSelection(TextSelection.create(doc, adjustedFrom, adjustedTo)));
    return;
  }

  // Check if we're inside a webcamRecordingChunk
  const webcamParent = findParentNode(
    node => node.type.name === 'webcamRecordingChunk',
    $from
  );

  if (!webcamParent) return;

  // Adjust selection to whole words within webcamRecordingChunk
  const wordNodes = [];
  doc.nodesBetween(from, to, (node, pos) => {
    if (node.type.name === 'webcamTranscriptWord') {
      wordNodes.push({ node, pos });
    }
  });

  if (wordNodes.length > 0) {
    const startPos = wordNodes[0].pos;
    const endPos = wordNodes[wordNodes.length - 1].pos + wordNodes[wordNodes.length - 1].node.nodeSize;

    if (from !== startPos || to !== endPos) {
      dispatch(state.tr.setSelection(TextSelection.create(doc, startPos, endPos)));
    }
  } else {
    // If no word nodes are found, adjust selection to nearest word
    const nearestWord = findNearestWord(doc, from, webcamParent.pos + 1, webcamParent.pos + webcamParent.node.nodeSize - 1);
    if (nearestWord) {
      const wordStart = nearestWord.pos;
      const wordEnd = nearestWord.pos + nearestWord.node.nodeSize;
      dispatch(state.tr.setSelection(TextSelection.create(doc, wordStart, wordEnd)));
    }
  }
}

function findNearestWord(doc, pos, minPos, maxPos) {
  // Search backward
  let $pos = doc.resolve(pos);
  while ($pos.pos > minPos) {
    const node = $pos.nodeBefore;
    if (node && node.type.name === 'webcamTranscriptWord') {
      return { node, pos: $pos.pos - node.nodeSize };
    }
    $pos = doc.resolve($pos.pos - 1);
  }

  // Search forward
  $pos = doc.resolve(pos);
  while ($pos.pos < maxPos) {
    const node = $pos.nodeAfter;
    if (node && node.type.name === 'webcamTranscriptWord') {
      return { node, pos: $pos.pos };
    }
    $pos = doc.resolve($pos.pos + 1);
  }

  return null;
}

export default selectionPlugin;
