import store from '../store'


export function getCurrentUserId() {	
	let id
	const state = store.getState()
	const currentUser=state.user
	if(currentUser){
		id=currentUser.id
	}
	return id 
}
