import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../misc/Icon';

const DPTextInput = ({
  value,
  setValue,
  iconName,
  label,
  disabled,
  width,
  fullWidth,
  placeholder
}) => {
  const [localInputValue, setLocalInputValue] = useState(value);
  const [isFocused, setIsFocused] = useState(false);

  const handleInputChange = (event) => {
    const newInputValue = event.target.value;
    setLocalInputValue(newInputValue);
    setValue(newInputValue);
  };

  const handleInputFocus = (event) => {
    setIsFocused(true);
    event.target.select();
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  useEffect(() => {
    if (!isFocused) {
      setLocalInputValue(value);
    }
  }, [value, isFocused]);


  let elementWidth = `${width}px` || '120px';
  if(fullWidth){
    elementWidth = '100%'
  }

  return (
    <div style={{width: elementWidth}} data-disabled={disabled} className='dpTextInput' data-input-focused={isFocused}>      
      <input             
        disabled={disabled}
        className='dpTextInput-input'         
        value={localInputValue}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        placeholder={placeholder}
      />
    </div>
  );
};

DPTextInput.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  iconName: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  width: PropTypes.number
};

export default DPTextInput;