import React from 'react'
import { useHistory, useLocation } from 'react-router-dom';

class ElectronMacDots extends React.Component{  


  render(){     

      const hideMacDots = location.pathname === '/recording-controls';

        
    return (            
      
        <>
          {!hideMacDots && 
            <div className={'electronMacDots ' + (this.props.apiErrorActive ? 'electronMacDots--apiErrorState' : '')}>
              <div className='electronMacDots-dot' />
              <div className='electronMacDots-dot' />
              <div className='electronMacDots-dot' />
            </div>      
          }

        </>
            
    )
  }
}

export default ElectronMacDots
