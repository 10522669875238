import {getOrgBrand} from './getOrgBrand'
import find from 'lodash/find'

//Figure out if its a light or dark background and then pick the 

function luminance(r, g, b) {
		const a = [r, g, b].map(function (v) {
				v /= 255;
				return v <= 0.03928 ? v / 12.92 : Math.pow( (v + 0.055) / 1.055, 2.4 );
		});
		return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

// Function to determine if background is light or dark
function isLightBackground(rgba) {
		const regex = /^rgba?\((\d+),\s*(\d+),\s*(\d+)/i;
		const matches = regex.exec(rgba);
		if (matches) {
				const lum = luminance(parseInt(matches[1]), parseInt(matches[2]), parseInt(matches[3]));
				return lum > 0.5;
		}
		return false; // Default in case of an error
}

export function getChartColorForBackgroundId(id) {
	const orgBrand=getOrgBrand()
	const background = orgBrand.backgrounds.find(bg => bg.id === id);
	if (!background) return orgBrand.textColors[0]; // there should always be a background but just in case

	//console.log('background',background)
	let isLight


	if(background.type=='image'){ //use isLight field
		isLight=background.isLightbackground

	}else{
		isLight=isLightBackground(background.rgba)
	}

	// console.log('isLight', isLight)


	if (isLight) {
		return "chartBlue"		
	} else {
		return "white"		
	}
}





// export function getTextColorForBackgroundId(id) {

//   let textColor
//   textColor = orgBrand.textColors[1]

//   return textColor
// }
