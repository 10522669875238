import { Parser } from "./parser";

const createParserFromUrl = (url) => {
  const parser = new Parser();
  const parserWritable = parser.writable.getWriter();

  const pump = async () => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const reader = response.body.getReader();

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          parserWritable.close();
          return;
        }
        // console.log("Pumping data", value.length);
        await parserWritable.write(value);
      }
    } catch (error) {
      console.error("Error fetching or parsing video:", error);
      parserWritable.abort(error);
    }
  };

  pump();
  return parser;
};

export { createParserFromUrl };