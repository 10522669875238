import React, { useState, useEffect } from 'react';
import Icon from '../../misc/Icon';
import EditorNewScreenClipPopoverContainer from '../popovers/EditorNewScreenClipPopover/EditorNewScreenClipPopoverContainer'
import AddFromLibraryDialog from '../popovers/AddFromLibraryDialog/AddFromLibraryDialog'
import AddFromLibraryPopover from './AddFromLibraryPopover'
import { TextSlideTooltipContent, ChartsTooltipContent, MediaTooltipContent, ScreenVideoTooltipContent, AddFromLibraryTooltipContent } from './EditorToolbarInsertBarTooltipContents';
import AddMediaPopover from '../popovers/addMediaPopover/AddMediaPopover'
import {getOrgDomain} from '../../../utils/getOrgDomain'

function EditorToolbarInsertBar(props){

	const {
		handleSlideImageFileUpload,
		addSlide,
		addRecordingFromVideoLibrary,
		addVideoClipFromUpload,
		handleNewImageFileUpload,
		insertImageFromRecent,
		handleNewVideoFileUpload,
		insertVideoFromRecent,
		addSlideElement,
		activePanelClip,
		handleNewWebcamFileUpload,
		insertWebcamFromRecent,
		slideClipAtPlayhead
	} = props

	const [showNewScreenClipPopover, setShowNewScreenClipPopover] = useState(false);
	const [showAddImagePopover, setShowAddImagePopover] = useState(false)  
	const [showAddWebcamPopover, setShowAddWebcamPopover] = useState(false)  
	const [showAddFromLibraryDialog, setShowAddFromLibraryDialog] = useState(false)

	const [showAddFromLibraryPopover, setShowAddFromLibraryPopover] = useState(false)


  const openNewScreenClipPopover = () => {
    if (!showNewScreenClipPopover) {
      setShowNewScreenClipPopover(true);
    }
    if (showNewScreenClipPopover) {    
      setShowNewScreenClipPopover(false);
    }
  };  


  const hideTooltip = showAddFromLibraryDialog || showNewScreenClipPopover || showAddFromLibraryPopover
  //const hideTooltip = showAddImagePopover || showAddFromLibraryDialog || showNewScreenClipPopover || setShowAddFromLibraryPopover

  let showUploadMediaButton = true

   const handleClickTextButton = () => {
    if (slideClipAtPlayhead ) {
      addSlideElement(slideClipAtPlayhead.id,'text');
    } else{
    	addSlide()
    }
  };

  const handleClickChartButton = () => {
    if (slideClipAtPlayhead) {
     addSlideElement(slideClipAtPlayhead.id,'chart');
    } else{
    	const imageObj = null 
    	const elementId = null
    	addSlide(imageObj,elementId,null,'chart')
    }
  };


  const showWebcamButton = getOrgDomain()=='yarn.so'
  // const showWebcamButton = true


  return (

			<>

			<div className='editor-toolbar-insertBar'>    
				<div className='editor-toolbar-insertBar-inner'>        
				{/*<button className='editor-toolbar-insertBar-btn' onClick={addTextSlide}>
					<Icon name='textSlideClipMedium' />					
					{!hideTooltip && 
						<div className='editor-toolbar-insertBar-btn-tooltipContainer'>
							<div className='editor-toolbar-insertBar-btn-tooltip'>
								<TextSlideTooltipContent hideTooltip={hideTooltip} />
								<div className='editor-toolbar-insertBar-btn-tooltip-label'>
									Add Text Slide
								</div>
							</div>
						</div>
					}
				</button>*/}


				<button className='editor-toolbar-insertBar-btn' onClick={handleClickTextButton}>
					<Icon name='textElementMedium' />					
					{!hideTooltip && 
						<div className='editor-toolbar-insertBar-btn-tooltipContainer'>
							<div className='editor-toolbar-insertBar-btn-tooltip'>
								<TextSlideTooltipContent hideTooltip={hideTooltip} />
								<div className='editor-toolbar-insertBar-btn-tooltip-label'>
									Add Text
								</div>
							</div>
						</div>
					}
				</button>


					{showWebcamButton &&
			<AddMediaPopover
          showPopover={showAddWebcamPopover}
          handleClosePopover={() => setShowAddWebcamPopover(false)}
          mediaType='webcam'
          isWebcam
         	handleNewWebcamFileUpload={handleNewWebcamFileUpload}
					insertWebcamFromRecent={insertWebcamFromRecent}     
        >          
          <button data-state={showAddImagePopover ? 'active' : 'inactive'} onClick={() => setShowAddWebcamPopover(!showAddWebcamPopover)} className='editor-toolbar-insertBar-btn'>
						<Icon name='speakerRectangleMedium' />
						{!hideTooltip && 
							<div className='editor-toolbar-insertBar-btn-tooltipContainer'>
								<div className='editor-toolbar-insertBar-btn-tooltip'>
									<MediaTooltipContent />
									<div className='editor-toolbar-insertBar-btn-tooltip-label'>
										Add Webcam
									</div>
								</div>
							</div>
						}
					</button>
        </AddMediaPopover> 
        }
						


				
				   <AddMediaPopover
            showPopover={showAddImagePopover}
            handleClosePopover={() => setShowAddImagePopover(false)}
          //  mediaType='image'
            mediaType='mixed'//image and video
						handleNewImageFileUpload={handleSlideImageFileUpload}
						handleNewVideoFileUpload={handleNewVideoFileUpload}   
						insertImageFromRecent={insertImageFromRecent}  
						insertVideoFromRecent={insertVideoFromRecent}   
          >          
            <button data-state={showAddImagePopover ? 'active' : 'inactive'} onClick={() => setShowAddImagePopover(!showAddImagePopover)} className='editor-toolbar-insertBar-btn'>
							<Icon name='mediaClipMedium' />
							{!hideTooltip && 
								<div className='editor-toolbar-insertBar-btn-tooltipContainer'>
									<div className='editor-toolbar-insertBar-btn-tooltip'>
										<MediaTooltipContent />
										<div className='editor-toolbar-insertBar-btn-tooltip-label'>
											Add Image or Video
										</div>
									</div>
								</div>
							}
						</button>
          </AddMediaPopover>  


				   




{/*
				{showUploadMediaButton &&

				<AddImagePopover
					showPopover={showAddImagePopover}
          handleClosePopover={() => setShowAddImagePopover(false)}
          handleNewImageFileUpload={handleNewImageFileUpload}
          insertImageFromRecent={insertImageFromRecent}
          handleNewVideoFileUpload={handleNewVideoFileUpload}
          insertVideoFromRecent={insertVideoFromRecent}
        >          
          <button data-state={showAddImagePopover ? 'active' : 'inactive'} onClick={() => setShowAddImagePopover(!showAddImagePopover)} className='editor-toolbar-insertBar-btn'>
						<Icon name='mediaClipMedium' />
						{!hideTooltip && 
							<div className='editor-toolbar-insertBar-btn-tooltipContainer'>
								<div className='editor-toolbar-insertBar-btn-tooltip'>
									<MediaTooltipContent />
									<div className='editor-toolbar-insertBar-btn-tooltip-label'>
										Add Image or Video
									</div>
								</div>
							</div>
						}
					</button>
        </AddImagePopover>	
      }
*/}
				
				<EditorNewScreenClipPopoverContainer
        	showModal={showNewScreenClipPopover}
        	closeModal={() => setShowNewScreenClipPopover(false)}
      	>
					<button data-state={showNewScreenClipPopover ? 'active' : 'inactive'} onClick={openNewScreenClipPopover} className='editor-toolbar-insertBar-btn'>
						<Icon name='screenVideoClipMedium' />
						{!hideTooltip && 
							<div className='editor-toolbar-insertBar-btn-tooltipContainer'>
								<div className='editor-toolbar-insertBar-btn-tooltip'>
									<ScreenVideoTooltipContent />
									<div className='editor-toolbar-insertBar-btn-tooltip-label'>
										Record Screen Clip
									</div>
								</div>
							</div>
						}
					</button>			
				</EditorNewScreenClipPopoverContainer>
				
				{/*}
				<button className='editor-toolbar-insertBar-btn' onClick={addImageClip}>
					<Icon name='imageClipMedium' />
					<div className='editor-toolbar-insertBar-btn-tooltipContainer'>
						<div className='editor-toolbar-insertBar-btn-tooltip'>
							<ChartsTooltipContent />
							<div className='editor-toolbar-insertBar-btn-tooltip-label'>
								Add Image
							</div>
						</div>
					</div>
				</button> 
				*/}

				
				
      

        {/*}
        <AddVideoPopover
					showPopover={showAddVideoPopover}
          handleClosePopover={() => setShowAddVideoPopover(false)}
          handleNewImageFileUpload={handleNewImageFileUpload}
          insertImageFromRecent={insertImageFromRecent}
          //addRecordingFromVideoLibrary={addRecordingFromVideoLibrary}
        >          
          <button data-state={showAddVideoPopover ? 'active' : 'inactive'} onClick={() => setShowAddVideoPopover(!showAddVideoPopover)} className='editor-toolbar-insertBar-btn'>
						<Icon name='imageClipMedium' />
						{!hideTooltip && 
							<div className='editor-toolbar-insertBar-btn-tooltipContainer'>
								<div className='editor-toolbar-insertBar-btn-tooltip'>
									<ChartsTooltipContent />
									<div className='editor-toolbar-insertBar-btn-tooltip-label'>
										Add Video
									</div>
								</div>
							</div>
						}
					</button>
        </AddVideoPopover>	




				<button className='editor-toolbar-insertBar-btn' onClick={addVideoClipFromUpload}>
					<Icon name='videoClipMedium' />
					{!hideTooltip && 
						<div className='editor-toolbar-insertBar-btn-tooltipContainer'>
							<div className='editor-toolbar-insertBar-btn-tooltip'>
								<ChartsTooltipContent />
								<div className='editor-toolbar-insertBar-btn-tooltip-label'>
									Add Video
								</div>
							</div>
						</div>
					}
				</button>

				*/}
				<button className='editor-toolbar-insertBar-btn' onClick={handleClickChartButton}>
					<Icon name='chartClipMedium' />
					{!hideTooltip && 
						<div className='editor-toolbar-insertBar-btn-tooltipContainer'>
							<div className='editor-toolbar-insertBar-btn-tooltip'>
								<ChartsTooltipContent />
								<div className='editor-toolbar-insertBar-btn-tooltip-label'>
									Add Chart
								</div>
							</div>
						</div>
					}
				</button>


				{/*}
				<AddFromLibraryPopover
					showPopover={showAddFromLibraryPopover}
          handleClosePopover={() => setShowAddFromLibraryPopover(false)}
          addRecordingFromVideoLibrary={addRecordingFromVideoLibrary}
        >          
          <button onClick={() => setShowAddFromLibraryPopover(!showAddFromLibraryPopover)} className='editor-toolbar-insertBar-btn'>
						<Icon name='folderClipMedium' />
						{!hideTooltip && 
						<div className='editor-toolbar-insertBar-btn-tooltipContainer'>
							<div className='editor-toolbar-insertBar-btn-tooltip'>
							<AddFromLibraryTooltipContent />
							<div className='editor-toolbar-insertBar-btn-tooltip-label'>
								Add from Library
							</div>
						</div>
						</div>
						}
					</button>
        </AddFromLibraryPopover>			
        */}


				{/*}
				<AddFromLibraryDialog
					showPopover={showAddFromLibraryDialog}
          handleClosePopover={() => setShowAddFromLibraryDialog(false)}
          addRecordingFromVideoLibrary={addRecordingFromVideoLibrary}
        >          
          <button onClick={() => setShowAddFromLibraryDialog(!showAddFromLibraryDialog)} className='editor-toolbar-insertBar-btn'>
						<Icon name='folderClipMedium' />
						<div className='editor-toolbar-insertBar-btn-tooltipContainer'>
							<div className='editor-toolbar-insertBar-btn-tooltip'>
							<AddFromLibraryTooltipContent />
							<div className='editor-toolbar-insertBar-btn-tooltip-label'>
								Add from Library
							</div>
						</div>
						</div>
					</button>

        </AddFromLibraryDialog>			
        */}
        
			</div>		  
			</div>		  

			</>
  );
}

export default EditorToolbarInsertBar