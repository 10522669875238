import React, {useState} from 'react'

function SlidePreviewImageElement({imageElement}) {

  //console.log(imageElement)

  let imageWidth = imageElement.width
  let imageHeight = imageElement.height
  let imageX = imageElement.x
  let imageY = imageElement.y
  let imageCornerRounding = imageElement.metadata.cornerRounding
  let isFill = imageElement.metadata.isFill

  if(imageElement.metadata.isBGImage){
    imageWidth = 1920
    imageHeight = 1080
    imageX = 0
    imageY = 0
    imageCornerRounding = 0
    isFill = true
  }


  const imageContainerStyle = {
    position: 'absolute',
    overflow: 'hidden',
    left: `${imageX}px`,
    top: `${imageY}px`,
    width: `${imageWidth}px`,
    height: `${imageHeight}px`,
    borderRadius: `${imageCornerRounding}px`,
    zIndex: imageElement.metadata.zOrder + 5,
  };

  // console.log('imageElement', imageElement)
  // console.log('imageElement.metadata.isBGImage', imageElement.metadata.isBGImage)
  // console.log('imageX', imageX)
  // console.log('imageY', imageY)
  // console.log('imageWidth', imageWidth)

  const imageStyle = {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    objectFit: isFill ? 'cover' : 'contain',   
  }


  return (
    <div style={imageContainerStyle} className='slidePreview-imageContainer'>
      <img style={imageStyle} src={imageElement.metadata.imgSrc} className='slidePreview-image' /> 
    </div>
  );
}

export default SlidePreviewImageElement;