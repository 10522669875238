//import {TimelineClip} from './Timeline'
import {TimelineClip} from './TimelineClip'

//zindex 2

class ZoomClip extends TimelineClip {

	constructor(options,scene) {
		super(options,scene)    
	}

  destroy(){
    //console.log('destroy ZOOM clip------')
  }

  toJSON() {
    return {
      id: this.id,
      type:this.type, 
      startTime:this._startTime, //time relative to the scene
      //startTime:this.startTime,
      duration:this.duration,
      metadata:this.metadata,
      zIndex:this.zIndex
    };
  }
  
}


export { ZoomClip }
