import * as THREE from 'three';

const SCENE_WIDTH = 1920;
const SCENE_HEIGHT = 1080;


// Helper function to resize the object to fit within the scene with fixed padding
const resizeDeviceToFit = (objWidth, objHeight,padding) => {
  // Calculate the effective scene size after applying the fixed padding
  const effectiveSceneWidth = SCENE_WIDTH - 2 * padding;
  const effectiveSceneHeight = SCENE_HEIGHT - 2 * padding;

  // Calculate the scale factors for width and height to fit the object within the effective scene dimensions
  const widthScale = effectiveSceneWidth / objWidth;
  const heightScale = effectiveSceneHeight / objHeight;

  // Use the smallest scale factor to ensure the object fits within the scene
  const scale = Math.min(widthScale, heightScale);

  // Calculate the new dimensions of the object based on the scale factor
  const deviceMeshWidth = objWidth * scale;
  const deviceMeshHeight = objHeight * scale;  

  return { deviceMeshWidth, deviceMeshHeight };
};

export default resizeDeviceToFit;
