import React, { useState, Suspense, useRef, useEffect} from 'react'


function formatDuration(seconds) {
  let minutes = Math.floor(seconds / 60);
  let wholeSeconds = Math.floor(seconds % 60);
  let milliseconds = (seconds % 1).toFixed(4).substring(2); // Extract and format milliseconds

  let minutesStr = minutes.toString().padStart(2, '0');
  let secondsStr = wholeSeconds.toString().padStart(2, '0');

  return minutesStr + ':' + secondsStr + '.' + milliseconds;
}


const ExportDevModeUI = ({togglePlayPause, currentTime, duration, handleSeek}) => {

	return (            				
			<>
			<div className='exportPlayer-timeline'>
				<div className='exportPlayer-timeline-controls'>
					<button onClick={()=>{handleSeek(0)}}>
						Go to start
					</button>
					<button onClick={togglePlayPause} >
						Play pause
					</button>
					<button onClick={()=>{handleSeek(currentTime+1/60)}}>
						Next Frame
					</button>
					<div className='exportPlayer-timeline-controls-timestamp'>
						{formatDuration(currentTime)}
					</div>
					<div className='exportPlayer-timeline-controls-timestamp'>
						{formatDuration(duration)}
					</div>
				</div>
				<div className='exportPlayer-timeline-slider'>

				</div>
							
			</div>
			</>	
	)
	
}

export default ExportDevModeUI
