import React, { useState, useEffect } from 'react';
import Icon from '../../../misc/Icon';

const EditorNewScreenClipPopoverPhoneSetup = ({includeiPadLabel}) => {  

  let deviceLabel = 'iPhone'
  if(includeiPadLabel){
    deviceLabel = 'iPhone or iPad'
  }

  return (
    <React.Fragment>      
      <div className='editorPopover-body'>
        <div className='editorPopover-body--iPhoneSetup'>
          <div className='editorPopover-body--iPhoneSetup-panel'>
            <div className='editorPopover-body--iPhoneSetup-panel-illustrationContainer'>
              <svg className='phoneSetupIcon phoneSetupIcon--plugIn' width="128" height="128" viewBox="0 0 128 128">
                <path id="device" d="M52.183 75.098h22.99c2.661 0 4.753-.74 6.276-2.221 1.523-1.481 2.284-3.51 2.284-6.088V15.606c0-2.577-.761-4.607-2.284-6.088-1.523-1.48-3.615-2.221-6.276-2.221h-22.99c-2.653 0-4.743.74-6.27 2.221-1.526 1.481-2.29 3.51-2.29 6.088V66.79c0 2.577.764 4.607 2.29 6.088 1.527 1.48 3.617 2.221 6.27 2.221Zm.485-4.05c-1.662 0-2.909-.402-3.739-1.206-.83-.804-1.245-2.007-1.245-3.61v-50.06c0-1.608.415-2.815 1.245-3.619.83-.804 2.077-1.206 3.739-1.206h2.156c.486 0 .729.235.729.706v.592c0 .619.188 1.12.564 1.503.376.384.87.576 1.481.576h12.205c.612 0 1.1-.192 1.465-.576.365-.383.548-.884.548-1.503v-.592c0-.47.242-.706.728-.706h2.167c1.648 0 2.886.402 3.716 1.206.83.804 1.246 2.01 1.246 3.62v50.06c0 1.602-.415 2.805-1.246 3.609-.83.804-2.068 1.206-3.716 1.206H52.668Zm4.195-2.302h13.675c.364 0 .667-.122.911-.366.245-.244.367-.559.367-.944 0-.378-.122-.686-.367-.923a1.257 1.257 0 0 0-.91-.355H56.862c-.37 0-.681.119-.933.355-.251.237-.377.545-.377.923 0 .385.126.7.377.944.252.244.563.366.933.366Z" />
                <path id="cable" d="M62.726 112.142h2.406V100.7h-2.406v11.443Zm-1.26-9.63h4.915c.51 0 .88-.117 1.115-.35.234-.235.351-.61.351-1.126V91.95c0-.511-.117-.886-.351-1.125-.234-.238-.606-.358-1.115-.358h-4.915c-.517 0-.892.12-1.127.358-.234.24-.35.614-.35 1.125v9.086c0 .516.116.89.35 1.125.235.234.61.351 1.127.351Zm.12-13.489h4.664v-2.611c0-.516-.12-.89-.357-1.125-.238-.234-.613-.351-1.123-.351h-1.695c-.517 0-.894.117-1.132.351-.238.234-.357.609-.357 1.125v2.611Z" />                              
              </svg>
            </div>
            <div className='editorPopover-body--iPhoneSetup-panel-label'>
              Connect {deviceLabel} to your Mac
            </div>
          </div>
          <div className='editorPopover-body--iPhoneSetup-panel'>
            <div className='editorPopover-body--iPhoneSetup-panel-illustrationContainer'>
              <svg className='phoneSetupIcon phoneSetupIcon--unlock' width="128" height="128" viewBox="0 0 128 128">
                <path id="device" d="M43.623 66.78c0 2.593.764 4.625 2.29 6.097 1.528 1.472 3.62 2.208 6.28 2.208h22.972c2.659 0 4.752-.736 6.279-2.208 1.527-1.472 2.29-3.504 2.29-6.097V15.594c0-2.57-.763-4.597-2.29-6.08-1.527-1.484-3.62-2.225-6.279-2.225H52.193c-2.66 0-4.752.741-6.28 2.225-1.526 1.483-2.29 3.51-2.29 6.08V66.78Zm4.054-.56V16.187c0-1.626.418-2.84 1.253-3.642s2.076-1.203 3.724-1.203h22.05c1.648 0 2.889.4 3.724 1.203.835.802 1.252 2.016 1.252 3.642v50.031c0 1.604-.417 2.813-1.252 3.626-.835.813-2.076 1.22-3.724 1.22h-22.05c-1.648 0-2.89-.407-3.724-1.22-.835-.813-1.253-2.022-1.253-3.626Zm4.384-16.71c0 1.845.868 2.768 2.604 2.768h11.173c1.735 0 2.603-.923 2.603-2.769v-8.602c0-1.802-.868-2.703-2.603-2.703H54.665c-1.736 0-2.604.901-2.604 2.703v8.602Zm11.437-9.921h2.801v-4.186c0-1.319.34-2.362 1.022-3.131.681-.77 1.615-1.154 2.801-1.154 1.165 0 2.088.385 2.769 1.154.681.769 1.022 1.812 1.022 3.13v2.011c0 .418.131.764.395 1.039.264.274.593.412.989.412.373 0 .698-.138.972-.412.275-.275.412-.621.412-1.039v-2.01c0-2.066-.599-3.757-1.796-5.076-1.198-1.318-2.785-1.977-4.763-1.977-2 0-3.603.675-4.812 2.027-1.208 1.35-1.812 3.081-1.812 5.19v4.022Z"/>
                <path id="cable" d="M62.787 115h2.45V88.302h-2.45V115Zm-1.283-24.872h5.003c.519 0 .897-.117 1.135-.353.239-.236.358-.613.358-1.133v-9.15c0-.514-.12-.891-.358-1.131S67.026 78 66.507 78h-5.003c-.526 0-.908.12-1.146.36-.239.24-.358.618-.358 1.133v9.15c0 .519.12.896.358 1.132.238.236.62.353 1.146.353Z"/>
              </svg>
            </div>        
            <div className='editorPopover-body--iPhoneSetup-panel-label'>
              Unlock {includeiPadLabel ? 'Device' : 'iPhone' }
            </div>
          </div>
          <div className='editorPopover-body--iPhoneSetup-panel'>
            <div className='editorPopover-body--iPhoneSetup-panel-illustrationContainer'>
              <svg className='phoneSetupIcon phoneSetupIcon--accept' width="128" height="128" viewBox="0 0 128 128">
                <path id="device" d="M44 66.96c0 2.6.761 4.639 2.284 6.115 1.523 1.477 3.61 2.215 6.262 2.215h22.908c2.652 0 4.739-.738 6.262-2.215C83.239 71.6 84 69.56 84 66.96V15.621c0-2.579-.761-4.612-2.284-6.1-1.523-1.487-3.61-2.231-6.262-2.231H52.546c-2.652 0-4.739.744-6.262 2.231C44.761 11.01 44 13.042 44 15.621v51.338Zm4.043-.563V16.216c0-1.631.416-2.849 1.249-3.653.832-.805 2.07-1.207 3.714-1.207h21.988c1.644 0 2.882.402 3.714 1.207.833.804 1.25 2.022 1.25 3.653v50.181c0 1.61-.417 2.821-1.25 3.637-.832.815-2.07 1.223-3.714 1.223H53.006c-1.644 0-2.882-.408-3.714-1.223-.833-.816-1.25-2.028-1.25-3.637Z"/>
                <path id="cable" d="M62.787 115h2.45V88.302h-2.45V115Zm-1.283-24.872h5.003c.519 0 .897-.117 1.135-.353.239-.236.358-.613.358-1.133v-9.15c0-.514-.12-.891-.358-1.131S67.026 78 66.507 78h-5.003c-.526 0-.908.12-1.146.36-.239.24-.358.618-.358 1.133v9.15c0 .519.12.896.358 1.132.238.236.62.353 1.146.353Z"/>
                <path id="check" d="M63.994 54.229c1.795 0 3.483-.341 5.066-1.022a13.316 13.316 0 0 0 4.19-2.838 13.405 13.405 0 0 0 2.843-4.19c.685-1.582 1.028-3.27 1.028-5.065 0-1.794-.343-3.482-1.028-5.065a13.459 13.459 0 0 0-7.033-7.02C67.477 28.342 65.784 28 63.982 28c-1.795 0-3.481.343-5.06 1.028a13.403 13.403 0 0 0-4.176 2.838 13.382 13.382 0 0 0-2.838 4.183c-.685 1.583-1.028 3.271-1.028 5.065 0 1.795.343 3.483 1.028 5.066a13.459 13.459 0 0 0 2.838 4.19 13.24 13.24 0 0 0 4.183 2.837c1.583.68 3.271 1.022 5.065 1.022Zm-1.409-6.856c-.245 0-.465-.05-.66-.152a1.886 1.886 0 0 1-.571-.495l-3.11-3.784a1.816 1.816 0 0 1-.255-.444 1.285 1.285 0 0 1-.088-.47c0-.321.112-.598.336-.831.224-.233.497-.35.819-.35.203 0 .385.043.546.128.16.084.326.232.495.444l2.437 3.098 5.256-8.405c.271-.44.614-.66 1.028-.66.314 0 .595.102.845.305s.374.47.374.8c0 .144-.034.294-.101.45a3.285 3.285 0 0 1-.229.438l-5.941 9.268c-.153.22-.328.385-.527.495-.199.11-.417.165-.654.165Z" />
              </svg>
            </div>
            <div className='editorPopover-body--iPhoneSetup-panel-label'>
              Accept Connection
            </div>
            <div className='editorPopover-body--iPhoneSetup-panel-description'>
              If prompted, select <br/> <emph>Trust</emph> or <emph>Other Device</emph>
            </div>
          </div>


        </div>
      </div>


    </React.Fragment>
  );
};

export default EditorNewScreenClipPopoverPhoneSetup