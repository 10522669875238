import React from 'react'


const EditorTimelineDropLines = (props) => {
	const {
		dropLines,dragZIndex
	} = props


	const {originalStartPx,originalEndPx,originalMidPx,dragMidPx,dropPositions,closestPosition,activeDropType} = dropLines


	// console.log(`dragZIndex----- ${dragZIndex}`)

	let dropLineYPos = 20
	let dropLineHeight = 36
	let dropLineArmWidth = 8

	if(dragZIndex == 2){
		dropLineYPos = 10
		dropLineHeight = 22
		dropLineArmWidth = 7
	}
	if(dragZIndex == 1){
		dropLineYPos = 38
	}
	if(dragZIndex == 0){
		dropLineYPos = 78
	}


		return (
			<>
				{/*}
				<div style={{left: `${dragMidPx}px`}} className='editor-timeline-dropLine editor-timeline-dropLine--dragMid ' />
				<div style={{left: `${originalStartPx}px`}} className='editor-timeline-dropLine editor-timeline-dropLine--original ' />
				<div style={{left: `${originalEndPx}px`}} className='editor-timeline-dropLine editor-timeline-dropLine--original ' />
				<div style={{left: `${originalMidPx}px`}} className='editor-timeline-dropLine editor-timeline-dropLine--originalMid ' />
				*/}
				
				{dropPositions && dropPositions.map((position,i) => {
					const isClosestDropPoint = position == closestPosition					
					return(

						<div key={`drop_line_${i}`} style={{left: `${position}px`, top: `${dropLineYPos}px`, height: `${dropLineHeight}px`}} className={'editor-timeline-dropLine ' + (isClosestDropPoint ? 'editor-timeline-dropLine--active' : ' ') + (activeDropType ? ` editor-timeline-dropLine--${activeDropType} ` : ' ')}>
							{isClosestDropPoint && activeDropType === 'end' &&
								<div className='editor-timeline-dropLine-type editor-timeline-dropLine-type--end'>
									<div style={{width: `${dropLineArmWidth}px`}} className='editor-timeline-dropLine-type-topBar'/>
									<div style={{width: `${dropLineArmWidth}px`}} className='editor-timeline-dropLine-type-bottomBar'/>
								</div>
							}

							{isClosestDropPoint && activeDropType === 'start' &&
								<div className='editor-timeline-dropLine-type editor-timeline-dropLine-type--start'>
									<div style={{width: `${dropLineArmWidth}px`}} className='editor-timeline-dropLine-type-topBar'/>
									<div style={{width: `${dropLineArmWidth}px`}} className='editor-timeline-dropLine-type-bottomBar'/>
								</div>
							}

						</div>

						)
							
					})}
			 </>
	
	);
} 


export default EditorTimelineDropLines;

