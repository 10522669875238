import React, {Component} from 'react';
import { fontWeightToNumber } from '../../utils/fontWeightToNumber';
import { getFontForTextStyle } from '../../../utils/brands/getFontForTextStyle'

class SlideImageElementPreview extends React.Component {
  
  render() {

    const {element,calculatePositions} = this.props
    
    const isFill = element.metadata.isFill
    
    return (
      <>
        <div data-fill-type={isFill ? 'fill' : 'fit'} style={{borderRadius: `${element.metadata.cornerRounding}px`}} className='editor-slideEditor-imagePreview'>
          <img className='editor-slideEditor-imagePreview-img' src={element.metadata.imgSrc} />
        </div>        
      </>
    );
  }
}


export default SlideImageElementPreview
