import React, { useState, useCallback } from 'react';
import Icon from '../misc/Icon'
import LibraryProjectListItem from './projectItem/LibraryProjectListItem'

function LibraryProjectGalleryList({ projects }) {

  const [itemMenuOpen, setItemMenuOpen] = useState(false);

  const handleMenuOpen = useCallback(() => {
    setItemMenuOpen(true);
  }, []);

  const handleMenuClose = useCallback(() => {
    setItemMenuOpen(true); // Set to true immediately when closing
    setTimeout(() => {
      setItemMenuOpen(false);
    }, 200);
  }, []);

return (  
  <div data-item-menu-open={itemMenuOpen ? "true" : "false"} className='libraryPage-projectGallery libraryPage-projectGallery--list'>
    {projects.map(project => (
      <LibraryProjectListItem
        key={project.id}
        project={project}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        // id={project.id}
        // name={project.name}
        // duration={project.duration}
        // editedTimestamp={project.editedTimestamp}
        // collectionIds={project.collectionIds}
        // isDraft={project.isDraft}        
      />
    ))}
  </div>
);
}

export default LibraryProjectGalleryList;
