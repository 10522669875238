import React, { useRef, useEffect, useContext,useMemo } from 'react';
import * as THREE from 'three';
//https://github.com/mrdoob/three.js/issues/26183


// // //// USE MEMORISED MATERIAL AND RF3 PRIMATIVE--> DOES NOT CREATE MATERIAL ON EVERY RENDER 
// // // VIDEO PERFORMANCE GOES FROM ~15FPS TO 60!

const BasicVideoMaterial = ({videoElement, opacity}) => {

  const texture = useMemo(() => {
    if (videoElement) {
      const videoTexture = new THREE.VideoTexture(videoElement);
      videoTexture.colorSpace = THREE.SRGBColorSpace;
      videoTexture.needsUpdate = true;
      return videoTexture;
    }
  }, [videoElement]);


  const material = useMemo(() => {
    return new THREE.MeshBasicMaterial({
      map: texture,
      transparent: true,
      opacity: opacity,
    });
  }, [texture, opacity]);


  return <primitive object={material} attach="material" transparent={true}/>;
};

export default BasicVideoMaterial;


