const emDOM = ["em", 0], strongDOM = ["strong", 0], codeDOM = ["code", 0]

// :: Object [Specs](#model.MarkSpec) for the marks in the schema.
export const marks = {
  // :: MarkSpec A link. Has `href` and `title` attributes. `title`
  // defaults to the empty string. Rendered and parsed as an `<a>`
  // element.

  textColor: {
    attrs: {
      colorId: {},
      rgba:{},
      isVariable:{default:false}
    },
    parseDOM: [{
    tag: "span[data-color-id]",
    getAttrs(dom) {
      return {
        colorId: dom.getAttribute("data-color-id"),
        rgba: dom.style.color
      }
    }
  }],
  toDOM(node) {
    let {colorId, rgba} = node.attrs;
    return ["span", {
      "data-color-id": colorId      
    }, 0]
  }
},

   textOpacity: {
    attrs: {
      opacity: {}
    },
    parseDOM: [{
      tag: "span[data-opacity]",
      getAttrs(dom) {
        return {
          opacity: parseFloat(dom.getAttribute("data-opacity"))
        }
      }
    }],
    toDOM(node) {
      let {opacity} = node.attrs;
      return ["span", {
        "data-opacity": opacity        
      }, 0]
    }
  },




  link: {
    attrs: {
      href: {},
      title: {default: null}
    },
    inclusive: false,
    parseDOM: [{tag: "a[href]", getAttrs(dom) {
      return {href: dom.getAttribute("href"), title: dom.getAttribute("title")}
    }}],
    toDOM(node) { let {href, title} = node.attrs; return ["a", {href, title}, 0] }
  },

  // :: MarkSpec An emphasis mark. Rendered as an `<em>` element.
  // Has parse rules that also match `<i>` and `font-style: italic`.
  em: {
    parseDOM: [{tag: "i"}, {tag: "em"}, {style: "font-style=italic"}],
    toDOM() { return emDOM }
  },

  // :: MarkSpec A strong mark. Rendered as `<strong>`, parse rules
  // also match `<b>` and `font-weight: bold`.
  strong: {
    parseDOM: [{tag: "strong"},
               // This works around a Google Docs misbehavior where
               // pasted content will be inexplicably wrapped in `<b>`
               // tags with a font-weight normal.
               {tag: "b", getAttrs: node => node.style.fontWeight != "normal" && null},
               {style: "font-weight", getAttrs: value => /^(bold(er)?|[5-9]\d{2,})$/.test(value) && null}],
    toDOM() { return strongDOM }
  },
  strike:{
    parseDOM: [
      { tag: 's' },
    ],
    toDOM: () => ['s']
  },



  // :: MarkSpec Code font mark. Represented as a `<code>` element.
  code: {
    parseDOM: [{
      tag: "span",
      getAttrs: dom => {
        let classname = dom.getAttribute("class")
        return classname==='doc-span--style--code'
        }
    }],
    //parseDOM: [{tag: "code"}],
    toDOM:()=>["span",{class:"doc-span--style--code"},0]
  },
  light: {
    parseDOM: [{
      tag: "span",
      getAttrs: dom => {
        let classname = dom.getAttribute("class")
        return classname==='doc-span--style--light'
        }
    }],
    //parseDOM: [{tag: "code"}],
    toDOM:()=>["span",{class:"doc-span--style--light"},0]
  },

  underline:{
    parseDOM: [
      { tag: 'u' },
    ],
    toDOM: () => ['u']
  }

}


export default marks