import React, { useState, useRef, useEffect, Suspense } from 'react';
import ThreeCanvas from '../../three/ThreeCanvas';
import Camera from '../../three/Camera';
import { Canvas } from '@react-three/fiber';
import { computeCursorDataForLegacyClips } from '../../utils/recordings/legacyScreenRecordings/getLegacyCursorPositionAtTime'
import { computeCursorDataForClips } from '../../utils/recordings/screenRecordings/getCursorPositionAtTime'
import ExportProdUI from './ExportProdUI';
import {getBackgroundForId} from '../../utils/brands/getBackgroundForId'
import { Mixpanel } from '../../Mixpanel'
import {handleSaveExport} from '../../actions/exportUpload'
import { Muxer, ArrayBufferTarget } from 'mp4-muxer';
import tracks from '../../utils/backgroundMusic/backgroundMusicTracks' 
import * as THREE from 'three';
import { CodecVideoClip } from '../../timeline/CodecVideoClip';

const FPS = 60 //temp --------- TODO put this back to 60
const SCENE_WIDTH = 1920
const SCENE_HEIGHT = 1080
const canvasWidth = 1280
const canvasHeight = 720

const NORMAL_TIMEOUT =8
const encodingBitRate = 10e6;

const formatTime = (seconds) => {
	const pad = (num) => num.toString().padStart(2, '0');
	const hrs = Math.floor(seconds / 3600);
	const mins = Math.floor((seconds % 3600) / 60);
	const secs = Math.floor(seconds % 60);
	return `${pad(hrs)}:${pad(mins)}:${pad(secs)}`;
};

const VARIABLE_VALUES={
	profile_pic:'http://res.cloudinary.com/yarn/image/upload/v1714086693/image_uploads/1798620531.png'

}

const ExportCanvasContainer = ({ projectBackgroundId, audioClips,audioClipsJSON,webcamClipsJSON,basicVideoClipsJSON,webcamClips, slideClips, textSlideClips,chartClips, videoClips, zoomClips, currentTime, isPlaying, isDraggingProgressMarker, setTime,duration,projectId, devMode,project,imageClips,setSelectedSlideElement }) => {
	
	const projectBackground=getBackgroundForId(projectBackgroundId)
	const [videoFilePath, setVideoFilePath] = useState(null); 
	const cameraRef = useRef();
	const [readyVideoClips, setReadyVideoClips] = useState([]);
	const isFrameRenderedRef = useRef(false);
	const canvasRef = useRef()
	const [exportStartTime, setExportStartTime] = useState(null);
	const [currentFrame, setCurrentFrame] = useState(0);
	const [totalFrames, setTotalFrames] = useState(0);

	//For when export is complete and user want to open exported video file
	const handleOpenVideo = () => {
		if (videoFilePath) {
			window.ipcRenderer.send('open-video-file', videoFilePath);
		}
	};

	useEffect(() => {
		const computeData = async () => {
			const legacyClips = videoClips.filter(clip => clip.isBasicVideo !== true && clip.isDeviceRecording!==true && clip.type!=='webcam' && clip.isScreenRecording!=true);
			const screenClips = videoClips.filter(clip => clip.isBasicVideo !== true && clip.isDeviceRecording!==true && clip.type!=='webcam' && clip.isScreenRecording==true)
			 try {
        const [legacyClipsData, screenClipsData] = await Promise.all([
          computeCursorDataForLegacyClips(legacyClips),
          computeCursorDataForClips(screenClips)
        ]);
        const allCalculatedClipsIds = [
          ...legacyClipsData,
          ...screenClipsData
        ];
        setReadyVideoClips(allCalculatedClipsIds);
      } catch (error) {
        console.error('Error computing cursor data:', error);
      }
		};
		computeData();
	}, [videoClips]);



	
	useEffect(() => {
		window.ipcRenderer.receive('export-video-reply', (response) => {
		if (response.error) {
			console.error("Export failed:", response.error);
		} else {
			console.log("Video processing complete");
			handleSaveExport(projectId,response.fileName)
			setVideoFilePath(response.fileName); // Assuming 'fileName' contains the path
		}
	});
		return () => {
			window.ipcRenderer.removeListener('export-video-reply');
		};
	}, []);

	
function captureFrame(renderer, scene, camera, encoder, frameIndex) {
  const canvas = renderer.domElement;
  renderer.render(scene, camera);
  const videoFrame = new VideoFrame(canvas, { timestamp: frameIndex * (1 / FPS) * 1e6 });
  encoder.encode(videoFrame);
  //take out the encoder flush--- we can just wait for it at the end
  videoFrame.close();
}

	async function finalizeVideo(muxer, encoder,is4kRes) {
		await encoder.flush(); // wait for queue to empty
		muxer.finalize(); // close muxer
		const blob = new Blob([muxer.target.buffer], {type: 'video/mp4'}); // create a blob from the buffer
		const arrayBuffer = await blob.arrayBuffer();
		window.ipcRenderer.send('export-video-buffer',{projectId:projectId, duration:duration,project:project,audioClips:audioClipsJSON,is4kRes:is4kRes,arrayBuffer:arrayBuffer,backgroundTracks:tracks,webcamClips:webcamClipsJSON,basicVideoClips:basicVideoClipsJSON});
	}

const MAX_ENCODE_QUEUE_SIZE = 4;

	const handleExport = async (is4kRes) => {
		const exportStartTime = performance.now();
		if (cameraRef.current) {
			const camera = cameraRef.current.getCamera();
			const renderer = cameraRef.current.getGL();
			const scene = cameraRef.current.getScene();
			renderer.setPixelRatio(1) //Fix ultrawide monitor bug 
			////// new stuff to fix ultrawide/////////
			let targetWidth=is4kRes?3840:1920 
			let targetHeight =is4kRes?2160: 1080
			// Adjust renderer and camera for the target resolution and aspect ratio
			renderer.setSize(targetWidth, targetHeight);
			camera.aspect = targetWidth / targetHeight;
			camera.updateProjectionMatrix();
			//////////////////////////////////////////
			const renderSeconds = duration;
			const renderFrames = renderSeconds * FPS;
			setExportStartTime(Date.now());
			setTotalFrames(renderFrames);

			const muxerOptions = {
				target: new ArrayBufferTarget(),
				video: {
					codec: 'avc', // must be the same codec as used in the encoder
					width: targetWidth, // same width as used in the encoder
					height: targetHeight, // same height as used in the encoder
				},
				fastStart: 'in-memory', // we don't mind the medatada to be stored at the end of the file
				firstTimestampBehavior: 'offset', // reset the timestamp to 0 on first frame
			}

			const  muxer = new Muxer(muxerOptions)
			const encoder = new VideoEncoder({
				output(chunk, meta) {
					muxer.addVideoChunk(chunk, meta);
				},
				error(e) {
				console.error(e);
				}
			});

			encoder.configure({
				codec: 'avc1.640033',
				width: targetWidth,
				height: targetHeight,
				bitrate: encodingBitRate,
				framerate: FPS,
			});

			const videoSegments = [...videoClips, ...webcamClips].map(clip => ({
				startTime: clip.startTime,
				endTime: clip.endTime,
				isBasicVideo: clip.isBasicVideo,
				semiTransparent: clip.metadata?.semiTransparent || false,
				hasVideoTrack:clip.hasVideoTrack,
				clip: clip
			}));


			let textureUpdateResolver = null;
			let textureUpdatePromise = new Promise(resolve => {
				textureUpdateResolver = resolve;
			});

			const globalTextureUpdateCallback = () => {
				console.log("CALLBACK Texture updated for video segment in canvas container");
				if (textureUpdateResolver) {
					textureUpdateResolver();
					textureUpdateResolver = null;
				}
      };

			// Set texture update callback for both video and webcam clips
			[...videoClips, ...webcamClips].forEach(clip => {
				if (clip instanceof CodecVideoClip) {
					clip.setTextureUpdatedCallback(globalTextureUpdateCallback);
				}
			});


			const frameTimes = [];
			const captureTimes = [];
			const loopStartTime = performance.now();

			for (let i = 0; i < renderFrames; i++) {
				const frameStartTime = performance.now();
				const frameTime = i / FPS;
				const currentSegment = videoSegments.find(segment => frameTime >= segment.startTime && frameTime <= segment.endTime);
				
				setCurrentFrame(i)
				if (currentSegment && currentSegment.hasVideoTrack) {
					console.log("Video segment found, waiting for texture update");
					textureUpdatePromise = new Promise(resolve => {
						textureUpdateResolver = resolve;
					});
					setTime(frameTime);

         	await textureUpdatePromise;      
				} else {
					setTime(frameTime);
					await new Promise(resolve => setTimeout(resolve, NORMAL_TIMEOUT));
				}
					const frameEndTime = performance.now();
					frameTimes.push(frameEndTime - frameStartTime);
					const captureStartTime = performance.now();
					//await captureFrame(renderer,scene, camera,encoder, i, renderFrames);
					captureFrame(renderer,scene, camera,encoder, i, renderFrames);
					const captureEndTime = performance.now();
					captureTimes.push(captureEndTime - captureStartTime);
					while (encoder.encodeQueueSize >= MAX_ENCODE_QUEUE_SIZE) {
					await new Promise(resolve => setTimeout(resolve, 1));
				}
			}

			await finalizeVideo(muxer, encoder,is4kRes);

			const exportEndTime = performance.now();
			const totalExportTime = exportEndTime - exportStartTime;

      console.log(`Total export time: ${totalExportTime.toFixed(2)}ms`);
      console.log(`Average frame render time: ${(frameTimes.reduce((a, b) => a + b, 0) / frameTimes.length).toFixed(2)}ms`);
      console.log(`Average frame capture time: ${(captureTimes.reduce((a, b) => a + b, 0) / captureTimes.length).toFixed(2)}ms`);
      
      console.log('Frame render times:', frameTimes);
      console.log('Frame capture times:', captureTimes);
		} else {
			console.error('Canvas objects are not available for export.');
		}
	} 

	const elapsedTime = exportStartTime ? (Date.now() - exportStartTime) / 1000 : 0;
	const percentageComplete = totalFrames > 0 ? (currentFrame / totalFrames) * 100 : 0;
	const estimatedTotalTime = percentageComplete > 0 ? elapsedTime / (percentageComplete / 100) : 0;
	const estimatedTimeRemaining = estimatedTotalTime > elapsedTime ? estimatedTotalTime - elapsedTime : 0;

	let exportState = 'pre'
	
	if(percentageComplete > 0){
		exportState = 'active'
	}
	if(videoFilePath){
		exportState = 'complete'
	}

	let percentageCompleteProgress = Math.min(percentageComplete, 98)
	if(videoFilePath){
		percentageCompleteProgress = 100
	}


	return (
		<>
			<ExportProdUI 
				percentageComplete={percentageCompleteProgress}				
				exportState={exportState}
				handleExport={handleExport}
				estimatedTimeRemaining={estimatedTimeRemaining}
				handleOpenVideo={handleOpenVideo}
				isReadyToExport={project?true:false}		
			/> 

			{devMode && 
			<div className='exportPlayer-exportStatus'>

				{percentageComplete.toFixed(2)}% through - elapsed time {formatTime(elapsedTime)} and estimated time remaining {formatTime(estimatedTimeRemaining)}
				{!videoFilePath &&
				<button className='exportPlayer-exportBtn' onClick={()=>handleExport(true)}>
					Start Export
				</button>
				}
				{videoFilePath &&
				<button className='exportPlayer-exportBtn' onClick={handleOpenVideo}>Open Video</button>
				}
			</div>
			}



			<div style={{ width: `${canvasWidth}px`, height: `${canvasHeight}px` }} className='editor-center-center-canvasOuterOuterContainer-guide'>
				<div style={{ transform: `scale(${canvasWidth / SCENE_WIDTH})` }} className='editor-center-center-canvasContainer'>
					<div style={{ width: `${SCENE_WIDTH}px`, height: `${SCENE_HEIGHT}px` }} className='editor-center-center-canvasInnerContainer' >
						<Suspense fallback={<div>Loading...</div>}>
							<Canvas 
								ref={canvasRef}
								style={{ width: `calc(${SCENE_WIDTH}px * 1/${canvasWidth / SCENE_WIDTH})`, height: `calc(${SCENE_HEIGHT}px * 1/${canvasWidth / SCENE_WIDTH})` }} 								
								gl={{ 
						    		preserveDrawingBuffer: true, 
						    		toneMapping: THREE.NoToneMapping,
						    		outputColorSpace: THREE.SRGBColorSpace
						  		}}>
								<ThreeCanvas
									currentTime={currentTime}
									slideClips={slideClips}
									videoClips={videoClips}
									imageClips={imageClips}
									textSlideClips={textSlideClips}
									zoomClips={zoomClips}
									chartClips={chartClips}
									textEditorIsFocused={false}
									projectBackground={projectBackground}
									readyVideoClips={readyVideoClips}
									hideRenderedTextSlide={false}
									isFrameRenderedRef={isFrameRenderedRef}
									showChartAnimated={true}
									showChartStatic={false}
									showBasicVideoStatic={false}
									projectBackground={projectBackground}
									variableValues={VARIABLE_VALUES}
									audioClips={audioClips}
									showSlideChartAnimated={true}
									showSlideChartStatic={false}
									webcamClips={webcamClips}
								/>
								<Camera ref={cameraRef} currentTime={currentTime} zoomClips={zoomClips} readyVideoClips={readyVideoClips} />
							</Canvas>
						</Suspense>
					</div>
				</div>
			</div>
		</>
	);
};

export default ExportCanvasContainer;


