import React, { useState,useEffect } from 'react';
import Icon from '../../misc/Icon';

const EditorDetailPanelMixedSelection = (props) => {

  return (
    <>
      <div className='editor-detailPanel-header'>
        <div className='editor-detailPanel-header-label'>
          Mixed Selection
        </div>
        <div className='editor-detailPanel-header-vSpacer' />        
      </div>

      
    {/*  <div className='editor-detailPanel-row'>
        <button 
          onClick={()=>{props.groupSlideItems('horizontal')}}          
          className='dpButton dpButton--labelOnly dpButton--fullWidth dpButton--strong'
        >
          <div className='dpButton-label'>
            Create H-Stack Group
          </div>
        </button>
      </div>
      <div className='editor-detailPanel-divider' />
      <div className='editor-detailPanel-row'>
        <button 
          onClick={()=>{props.groupSlideItems('vertical')}}          
          className='dpButton dpButton--labelOnly dpButton--fullWidth dpButton--strong'
        >
          <div className='dpButton-label'>
            Create V-Stack Group
          </div>
        </button>
      </div>*/}
      
        <div className='editor-detailPanel-row'>
        <button 
          onClick={()=>{props.groupSlideItems()}}          
          className='dpButton dpButton--labelOnly dpButton--fullWidth dpButton--strong'
        >
          <div className='dpButton-label'>
            Make Layout Group
          </div>
        </button>
      </div>
    


    </>
  );
};

export default EditorDetailPanelMixedSelection;