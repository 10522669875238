import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import { useLoader, useThree, useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import SlideBarChart from './SlideBarChart';
import { getOrgBrand } from '../../../../utils/brands/getOrgBrand'
import {getTextColorForBackgroundId} from '../../../../utils/brands/getTextColorForBackgroundId'
import {getBackgroundForId} from '../../../../utils/brands/getBackgroundForId'
import {getChartColorForBackgroundId} from '../../../../utils/brands/getChartColorForBackgroundId'
import renderOrders from '../../../renderOrders'


const SlideBarChartContainer = ({ time, element, showChartStatic, showChartAnimated,projectBackground,chartData, slideBackgroundId, slideStartTime}) => {
	const orgBrand=getOrgBrand()
	
	const animationType = element.metadata.barChartData.animationType
	const motionStyle = element.metadata.barChartData.motionStyle	

	const outOfRange = time < element.startTime || time > (element.startTime + element.duration)		

	const localTime = time - element.startTime;
	const isAnimatedHidden = outOfRange || !showChartAnimated
	
	const outOfSlideRange = time < slideStartTime || time > (element.startTime + element.duration)
	const isPreviewHidden = outOfSlideRange || !showChartStatic		

	

	

	const fontFamily = orgBrand.font.fontFamily
	// const fontFamily = "YSohne"
	const fontWeight = "Bold"
	const chartLabelFontWeight = "Medium"

	// get project background id or slide id
	
	let chartBackground = projectBackground
	const isNoneBGColor = slideBackgroundId =='none'

	if(slideBackgroundId && !isNoneBGColor){
  	chartBackground=getBackgroundForId(slideBackgroundId)
	}

	let chartColorId = element.metadata.uniformColorId
	
	if(!element.metadata.uniformColorId){ // auto
		chartColorId = getChartColorForBackgroundId(chartBackground.id)				
	}

	// chartColorId id ether a brand bg color (not image) or chart colors
	// that to a real rgba value

	let chartsBarBackground = getBackgroundForId(chartColorId).rgba
	
	if(chartColorId === 'white'){
		chartsBarBackground = 'rgba(255,255,255)'
	}
	
	let chartsTextColor = getTextColorForBackgroundId(chartBackground.id).rgba

	// console.log('chartBackground.id')
	// console.log(chartBackground.id)	
	// console.log('chartsTextColor')
	// console.log(chartsTextColor)

	// more advanced handling in future for charts colors
	let numberValueTextColor = chartsTextColor
	let numberLabelTextColor = chartsTextColor
	
	let barChartBarFillColor = chartsBarBackground
	let barChartBarBorderColor = chartsBarBackground

	let barChartValueLabelColor = chartsTextColor
	let barChartAxisLabelLabelColor = chartsTextColor	

	// new from data	
	let barMaxOpacity = 0.18
	if(element.metadata.barOpacity){
		barMaxOpacity = element.metadata.barOpacity
	}

	let barChartMaxOpacities = {
    border: {
        default: Math.min(3.3 * barMaxOpacity, 1), // 0.6 original
        lowlight: Math.min(2.2 * barMaxOpacity, 1), // 0.4 original
        highlight: Math.min(5 * barMaxOpacity, 1) // 0.9 original
    },
    fill: {
        default: barMaxOpacity, // original 0.18
        lowlight: 0.66 * barMaxOpacity, // original 0.12
        highlight: Math.min(barMaxOpacity * 1.55, 1) // original 0.28
    },
    value: {
        default: 0.8,
        lowlight: 0.6,
        highlight: 1.0
    },
    label: {
        default: 0.8,
        lowlight: 0.6,
        highlight: 1.0
    }
	};

	// console.log('barChartMaxOpacities')
	// console.log(barChartMaxOpacities)

	const chartWidth = element.width - 4 // account for borders around bar
	const chartX = element.x + 2 // account for borders around bar	

	const activeLabelType = element.metadata.barChartData.labelTypes || 'all'


	const showValueLabels = activeLabelType=='all' || activeLabelType=='valueOnly'
	const showAxisLabels = activeLabelType=='all' || activeLabelType=='axisOnly'

	let enterDelay = 0
	if(element.metadata.enterDelay){
		enterDelay = element.metadata.enterDelay
	}

	const animationDuration = element.duration

	// console.log('element.duration')
	// console.log(element.duration)

	// console.log('animationDuration')
	// console.log(animationDuration)

	return (
		<>
			
			
			{chartData && !isAnimatedHidden &&
		<SlideBarChart       
			key={`${element.id}--animated`}    
          isHidden={false}          
          rowData={chartData.barItems}
          time={localTime}    
          animationType={chartData.animationType}      
          fontFamily={fontFamily}
          titleFontWeight={fontWeight}
          chartLabelFontWeight={chartLabelFontWeight}

          barChartBarFillColor={barChartBarFillColor}
          barChartBarBorderColor={barChartBarBorderColor}
   
          barChartValueLabelColor={barChartValueLabelColor}
          barChartAxisLabelLabelColor={barChartAxisLabelLabelColor}
   
          barChartMaxOpacities={barChartMaxOpacities}
          chartX={chartX}
          chartY={element.y}
          chartWidth={chartWidth}
          chartHeight={element.height}
          element={element}
          showValueLabels={showValueLabels}
          showAxisLabels={showAxisLabels}

          //duration={element.duration - enterDelay}
          animationDuration={animationDuration}


    />
    	}
      
      	
      {chartData && !isPreviewHidden &&
        <SlideBarChart
        	key={`${element.id}--preview`}    
          isHidden={false}
          
          rowData={chartData.barItems}
          time={localTime}    
          animationType={'none'}
          fontFamily={fontFamily}          
          chartLabelFontWeight={chartLabelFontWeight}

          barChartBarFillColor={barChartBarFillColor}
          barChartBarBorderColor={barChartBarBorderColor}

          barChartValueLabelColor={barChartValueLabelColor}
          barChartAxisLabelLabelColor={barChartAxisLabelLabelColor}          

          barChartMaxOpacities={barChartMaxOpacities}		          
          chartX={chartX}
          chartY={element.y}
          chartWidth={chartWidth}
          chartHeight={element.height}
          element={element}
          showValueLabels={showValueLabels}
          showAxisLabels={showAxisLabels}

          //duration={element.duration - enterDelay}
          animationDuration={animationDuration}
        />	        
    	
			}
		

			
			
			
  
		</>
	);
};

export default SlideBarChartContainer;



	//
	// Yarn White BG - Dark Text Color
	//if(activeBrand === 'Yarn' && chartsTextColor && chartsTextColor === 'rgba(80, 81, 85, 1)'){		
	// if(chartsTextColor && chartsTextColor === 'rgba(80, 81, 85, 1)'){		
	// 	const yarnDarkerGrey = 'rgba(40, 40, 55, 1)'
	// 	const yarnBlue = 'rgba(105, 133, 255, 1)'

	// 	//numberValueTextColor = 'rgba(105, 133, 255, 1)' // blue
	// 	numberValueTextColor = yarnDarkerGrey // darker grey
		
	// 	donutValueTextColor = yarnDarkerGrey
	// 	donutFillColor = yarnBlue
	// 	donutPartRingMaxOpacity = 1
	// 	donutFullRingMaxOpacity = 0.2

	// 	barChartTitleColor = yarnDarkerGrey
	// 	barChartTitleMaxOpacity = 1

	// 	//barChartValueLabelColor = 'rgba(40, 40, 55, 1)'
	// 	barChartAxisLabelLabelColor = 'rgba(40, 40, 55, 1)'

	// 	barChartBarFillColor= yarnBlue
	// 	barChartBarBorderColor= yarnBlue

	// 	barChartMaxOpacities.border.default = 0
	// 	barChartMaxOpacities.border.lowlight = 0
	// 	barChartMaxOpacities.border.highlight = 0

	// 	barChartMaxOpacities.fill.default = 0.7
	// 	barChartMaxOpacities.fill.lowlight = 0.4
	// 	barChartMaxOpacities.fill.highlight = 1

	// 	barChartMaxOpacities.value.default = 0.8
	// 	barChartMaxOpacities.value.lowlight = 0.6
	// 	barChartMaxOpacities.value.highlight = 1

	// 	barChartMaxOpacities.label.default = 0.6
	// 	barChartMaxOpacities.label.lowlight = 0.4
	// 	barChartMaxOpacities.label.highlight = 1
	// }

