import { getMasterRecordingData } from './getMasterRecordingData';
import { getNormalizedCursorData } from './getNormalizedCursorData';
import { getSmoothedCursorData } from './getSmoothedCursorData';
import {binarySearchNearest} from '../../../three/utils/animations/binarySearchNearest'
import resizeDeviceToFit from '../../../three/utils/resizeDeviceToFit'
import {calulateVideoTimeFromTimelineTime} from '../../../timeline/utils/calulateVideoTimeFromTimelineTime'

let clipsData = {};

const WINDOW_PADDING = 18 
const CHROME_OFFSET_DEFAULT = 87;
const CHROME_OFFSET_BOOKMARKS_BAR = 120;

export const calculateMeshScale = (masterRecordingData,screenVideoApp,screenVideoDeviceFrame) => {
	let chromeHeightOffset = 0
  if(screenVideoApp === 'chrome' && screenVideoDeviceFrame === 'color'){
    chromeHeightOffset = CHROME_OFFSET_DEFAULT
  }
  if(screenVideoApp === 'chrome' && screenVideoDeviceFrame === 'colorBB'){
    chromeHeightOffset = CHROME_OFFSET_BOOKMARKS_BAR
  }
	const data = resizeDeviceToFit(
		masterRecordingData.recordingWidth,
		masterRecordingData.recordingHeight - chromeHeightOffset,
		WINDOW_PADDING // Make sure WINDOW_PADDING is accessible
	);
	return masterRecordingData.recordingWidth > 0 ? data.meshWidth / masterRecordingData.recordingWidth : 1;
};

// Function to precalculate and store or update cursor data for each clip
export const computeCursorDataForClips = async (clips) => {
	let calculatedClipsIds = [];
	for (let clip of clips) {
		const clipId = clip.id;
		// Check if cursor data for this clip has already been calculated
		if (!clipsData[clipId] || !clipsData[clipId].cursorData || clipsData[clipId].screenVideoDeviceFrame!==clip.metadata.deviceFrame ) {
			try {

				let chromeHeightOffset = 0
				const screenVideoApp=clip.metadata.screenVideoApp
				const screenVideoDeviceFrame = clip.metadata.deviceFrame

			  if(screenVideoApp === 'chrome' && screenVideoDeviceFrame === 'color'){
			    chromeHeightOffset = CHROME_OFFSET_DEFAULT
			  }
			  if(screenVideoApp === 'chrome' && screenVideoDeviceFrame === 'colorBB'){
			    chromeHeightOffset = CHROME_OFFSET_BOOKMARKS_BAR
			  }

				const masterRecordingData = await getMasterRecordingData(clip.captureId);

				const normalizedCursorData = await getNormalizedCursorData(
					masterRecordingData.cursorData,
					masterRecordingData.recordingWidth,
					masterRecordingData.recordingHeight,
					0,
					(chromeHeightOffset / 2)
				);

				const meshScale = calculateMeshScale(masterRecordingData,screenVideoApp,screenVideoDeviceFrame);
			
				const smoothedCursorData = await getSmoothedCursorData(
          normalizedCursorData,
          masterRecordingData.recordingVideoDuration
        );

				const meshScaledCursorData = smoothedCursorData.map(point => ({
					...point,
					x: point.x * meshScale,
					y: point.y * meshScale 
				}));

				const meshScaledNormalisedData = normalizedCursorData.map(point => ({
					...point,
					x: point.x * meshScale,
					y: point.y * meshScale
				}));

				// Store or update cursor data
				clipsData[clipId] = {
					clip,
					cursorData: meshScaledCursorData,
					nonSmoothedData:meshScaledNormalisedData,
					screenVideoDeviceFrame:screenVideoDeviceFrame //also key by this so can recalc when switch frame
				};
				calculatedClipsIds.push(clipId);
			} catch (error) {
				console.error('Error precalculating data for clip:', error);
			}
		} else {
			// Update the clip information without touching the cursor data
			clipsData[clipId].clip = clip;
			calculatedClipsIds.push(clipId);
		}
	}
	return calculatedClipsIds
};


// //This does with local in video time (what we currently use in the screenvideocursor)
export const getCursorPositionAtLocalTime = (clipId, localTime) => {
	if (clipsData[clipId] && clipsData[clipId].cursorData) {
		const meshNormalizedCursorData = clipsData[clipId].cursorData;
		const mostRecentSmoothPosition = binarySearchNearest(meshNormalizedCursorData, localTime, 'time');
		if (mostRecentSmoothPosition) {
			return {
				x: mostRecentSmoothPosition.x,
				y: mostRecentSmoothPosition.y,
				cursorType:mostRecentSmoothPosition.cursorType
			};
		}
	}
	return null; // Return null if no position is found or data is missing
};



// TODO we can combine some of these 
export const getCursorPositionAtTimelineTime = (clip, timelineTime) => {
	const clipId=clip.id
	const localTime = calulateVideoTimeFromTimelineTime(timelineTime,clip)
	if (clipsData[clipId] && clipsData[clipId].cursorData) {
		const meshNormalizedCursorData = clipsData[clipId].cursorData;
		let mostRecentSmoothPosition
		if(meshNormalizedCursorData && meshNormalizedCursorData.length>0){
			mostRecentSmoothPosition = binarySearchNearest(meshNormalizedCursorData, localTime, 'time');
		}
		if (mostRecentSmoothPosition) {
			return {
				x: mostRecentSmoothPosition.x,
				y: mostRecentSmoothPosition.y,
				cursorType:mostRecentSmoothPosition.cursorType
			};
		}
	}
	return null; // Return null if no position is found or data is missing
};


//Find active clip and then find local time in that clip

////// this is what we use for the zoom stuff
export function getCursorPositionAtTime(timelineTime){
	for (let clipId in clipsData) {
		const clip = clipsData[clipId].clip;
		 if(timelineTime>=clip.startTime && timelineTime<clip.startTime+clip.duration){
			const localTime = calulateVideoTimeFromTimelineTime(timelineTime,clip)
			// Get cursor position at local time
			const position = getCursorPositionAtLocalTime(clipId, localTime);
			return position;
		}
	}
	return {x:0,y:0}

}


export const clearScreenClipsData = () => {
	clipsData = {};
};
