export const endTransitionConfigs = {
  none: {
    label: "None",
    endTo: {
      opacity: 1,      
    },    
  },
  fade: {
    label: "Fade Out",
    endTo: {
      opacity: 0,      
    },    
  },
  fadeAndMoveDown: {
    label: "Fade Down",
    endTo: {
      opacity: 0,
      positionY: -50, 
    },    
  },
  fadeAndMoveRight: {
    label: "Move Right",
    endTo: {
      opacity: 0,
      positionX: 60, 
    },
  },
  fadeAndMoveLeft: {
    label: "Move Left",
    endTo: {
      opacity: 0,
      positionX: -60, 
    },
  },
  fadeAndScaleUp: {
    label: "Scale Up",
    endTo: {
      opacity: 0,
      scale: 1.2,
    },    
  },

  fadeAndScaleDown: {
    label: "Scale Down",
    endTo: {
      opacity: 0,
      scale: 0.8,
    },    
  },  
};
