export function getAnimationParamsForMotionStyle(style, adjustedSpeed = 0, adjustedBounciness = 0) {
  let baseParams;
  
  switch (style) {
    case 'smooth':
      baseParams = {
        stiffness: 120,
        damping: 12,
        perWordAnimateOverlapRatio: 0.3,
        perLineAnimateOverlapRatio: 0.6,
      };
      break;
    case 'bouncy':
      baseParams = {
        stiffness: 200,
        damping: 8,
        perWordAnimateOverlapRatio: 0.1,
        perLineAnimateOverlapRatio: 0.4,
      };
      break;
    case 'slow':
      baseParams = {
        stiffness: 80,
        damping: 20,
        perWordAnimateOverlapRatio: 0.35,
        perLineAnimateOverlapRatio: 0.4,
      };
      break;
    case 'molasses':
      baseParams = {
        stiffness: 50,
        damping: 40,
        perWordAnimateOverlapRatio: 0.4,
        perLineAnimateOverlapRatio: 0.4,
      };
      break;
    case 'zippy':
      baseParams = {
        stiffness: 400,
        damping: 30,
        perWordAnimateOverlapRatio: 0.25,
        perLineAnimateOverlapRatio: 0.4,
      };
      break;
    default:
      baseParams = {
        stiffness: 120,
        damping: 12,
        perWordAnimateOverlapRatio: 0.3,
        perLineAnimateOverlapRatio: 0.6,
      };
  }

  // If both adjustments are 0, return the base parameters unchanged
  if (adjustedSpeed === 0 && adjustedBounciness === 0) {
    return { ...baseParams, mass: 1 };
  }

  let normalizedAdjustedSpeed = adjustedSpeed
  if(adjustedSpeed < 0){
  	normalizedAdjustedSpeed = adjustedSpeed * 1.5
  }

  // Calculate natural frequency and damping ratio
  const baseNaturalFreq = Math.sqrt(baseParams.stiffness);
  const baseDampingRatio = baseParams.damping / (2 * Math.sqrt(baseParams.stiffness));

  // Apply non-linear scaling to speed adjustment
  const speedFactor = Math.pow(2, adjustedSpeed);

  // Adjust natural frequency based on speed
  const adjustedNaturalFreq = baseNaturalFreq * speedFactor;

  // Adjust damping ratio based on bounciness
  const bouncinessEffect = Math.pow(2, -adjustedBounciness);
  let adjustedDampingRatio = baseDampingRatio * bouncinessEffect;

  // Calculate new stiffness
  const newStiffness = Math.pow(adjustedNaturalFreq, 2);

  // Smoothly increase damping for very low stiffness values
  const stiffnessThreshold = 40; // Adjust this value as needed
  const dampingIncrease = Math.max(0, 1 - newStiffness / stiffnessThreshold);
  adjustedDampingRatio += dampingIncrease * 2; // Adjust the multiplier as needed

  // Calculate new damping
  const newDamping = 2 * adjustedDampingRatio * adjustedNaturalFreq;

  // console.log('newDamping', newDamping)
  // console.log('newStiffness', newStiffness)

  return {
    mass: 1,
    stiffness: Math.max(0.1, Math.min(1000, newStiffness)),
    damping: Math.max(0.1, Math.min(100, newDamping)),
    perWordAnimateOverlapRatio: baseParams.perWordAnimateOverlapRatio,
    perLineAnimateOverlapRatio: baseParams.perLineAnimateOverlapRatio,
  };
}