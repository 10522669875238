
export function binarySearchNearest(arr, target, key) {
  let start = 0;
  let end = arr.length - 1;
  let mid;

  while (start <= end) {
    mid = Math.floor((start + end) / 2);

    if (arr[mid][key] === target) {
      return arr[mid];
    } else if (arr[mid][key] < target) {
      start = mid + 1;
    } else {
      end = mid - 1;
    }
  }

  // Handle edge cases where the target is beyond the array bounds
  if (mid === 0) {
    return arr[0];
  } else if (mid === arr.length - 1) {
    return arr[arr.length - 1];
  }

  // Check which of the two elements is closer to the target
  const prevDiff = Math.abs(arr[mid - 1][key] - target);
  const nextDiff = Math.abs(arr[mid][key] - target);
  return prevDiff <= nextDiff ? arr[mid - 1] : arr[mid];
}
