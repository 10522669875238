export const endTransitionConfigs = {
  none: {
    label: "None",
    endTo: {
      opacity: 1,      
    },    
  },  
  fade: {
    label: "Fade Out",
    endTo: {
      opacity: 0,      
    },    
  },  
  fadeAndMoveDown: {
    label: "Fade Down",
    endTo: {
      opacity: 0,
      positionY: -20, 
    },    
  },
  fadeAndMoveUp: {
    label: "Fade Up",
    endTo: {
      opacity: 0,
      positionY: 20, 
    },    
  },
  fadeAndMoveRight: {
    label: "Fade Right",
    endTo: {
      opacity: 0,
      positionX: 40, 
    },    
  },
  fadeAndMoveLeft: {
    label: "Fade Left",
    endTo: {
      opacity: 0,
      positionX: -40, 
    },    
  },
  fadeAndScaleDown: {
    label: "Scale Down",
    endTo: {
      opacity: 0,
      scale: 0.8,
    },    
  },  
  fadeAndScaleUp: {
    label: "Scale Up",
    endTo: {
      opacity: 0,
      scale: 1.2,
    },    
  },  
};
