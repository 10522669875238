import {fetch,put} from '../api'



export function fetchOrgMembers() {
	return (dispatch) => fetch(`/organization/members`)
		.then((response) => {
			dispatch({ type: 'FETCH_MEMBERS_SUCCESS', response })
			bumpUsersVersion()
			return response
		})
		.catch((error) => {
			return error
		})
}
