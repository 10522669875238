import { SlideElement } from './SlideElement';
import { getFontForTextStyle } from '../../../utils/brands/getFontForTextStyle';
import { fontWeightToNumber } from '../../../three/utils/fontWeightToNumber';

class TextElement extends SlideElement {
  constructor(options, slideClip,handleTextElementFontLoaded) {
    super(options, slideClip);
    this.handleTextElementFontLoaded=handleTextElementFontLoaded
    if (!this.metadata.textProperties) { //legacy support
      this.populateTextProperties();
    }

    this.isFontLoaded = false;
    this.loadFont();
  }


updateTextProperties(textStyle,newTextProperties){
  this.metadata.textStyle = textStyle 
  this.metadata.textProperties = newTextProperties
  this.isFontLoaded = false
  this.loadFont()
}

  populateTextProperties() {
    const textStyle = this.metadata.textStyle || 'body';
    const values = getFontForTextStyle(textStyle);
    this.metadata.textProperties = { ...values };
    this.loadFont();
  }

  async loadFont() {
    const { fontFamily } = this.metadata.textProperties;
    const fontWeight = fontWeightToNumber(this.metadata.textProperties.fontWeight);
    
   // console.log(`Loading font family: ${fontFamily}, weight: ${fontWeight}`);
    //const startTime = performance.now();

    if ('fonts' in document) {
      try {
        this.isFontLoaded = false;
        await document.fonts.load(`${fontWeight} 12px "${fontFamily}"`);
        await document.fonts.ready;
      //  console.log(`Font ${fontFamily} (weight: ${fontWeight}) loaded successfully for TextElement ${this.id}`);
       // const endTime = performance.now();
        //const fontLoadTime = endTime - startTime;
        //console.log(`Font ${fontFamily} (weight: ${fontWeight}) loaded successfully for TextElement ${this.id}`);
        //console.log(`Font load time: ${fontLoadTime.toFixed(2)}ms`);
        this.isFontLoaded = true;
        this.onFontLoaded();      
      } catch (error) {
        //console.error(`Error loading font ${fontFamily} (weight: ${fontWeight}) for TextElement ${this.id}:`, error);
        this.isFontLoaded = false;
      }
    } else {
      //console.warn('Font Loading API not supported in this browser');
      // Fallback: assume font is loaded after a short delay
      await new Promise(resolve => setTimeout(resolve, 500));
      this.isFontLoaded = true;
      this.onFontLoaded();
    }
  }

  onFontLoaded() {
    if (this.handleTextElementFontLoaded) {
      this.handleTextElementFontLoaded()
    }
  }

 

  toJSON() {
    return {
      id: this.id,
      type: this.type,
      width: this.width,
      height: this.height,
      x: this.x,
      y: this.y,
      metadata: this.metadata,
      bottomMargin: this.bottomMargin,
      rightMargin: this.rightMargin,
    };
  }
}

export { TextElement };