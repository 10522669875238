import React from 'react'
import * as Popover from '@radix-ui/react-popover';
import Icon from '../../misc/Icon';
import { getOrgBrand } from '../../../utils/brands/getOrgBrand'

function DPFontFamilyPickerPopoverItem({ label, isActive, onClick }) {
  return (          
    <button 
      data-active-state={isActive ? 'active' : 'inactive'}
      className='dpFontFamilyPickerPopover-item'
      onClick={onClick}
    >      
      <div className='dpFontFamilyPickerPopover-item-label'>
        {label}
      </div>
    </button>
  );
}

function DPFontFamilyPickerPopover({ children, selectedFontFamily, onFontFamilyChange, availableFontFamilies }) {
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  
  const handleClosePopover = () => {
    setPopoverOpen(false);
  };

  const handleFontFamilyClick = (fontFamilyValue) => {
    onFontFamilyChange(fontFamilyValue);    
    handleClosePopover();
  };

  const orgBrand = getOrgBrand();

  // Extract unique font families from the org brand
  const brandFontFamilies = new Set(
    Object.values(orgBrand)
      .filter(value => typeof value === 'object' && value.fontFamily)
      .map(value => value.fontFamily)
  );

  // Filter available font families to get brand fonts
  const brandFonts = availableFontFamilies
    .filter(font => brandFontFamilies.has(font.value))
    .sort((a, b) => a.label.localeCompare(b.label));

  // Sort all available fonts alphabetically
  const sortedFonts = [...availableFontFamilies].sort((a, b) => a.label.localeCompare(b.label));

  return (
    <Popover.Root 
      open={popoverOpen}       
      onOpenChange={setPopoverOpen}
      modal={false}
    >
      <Popover.Trigger asChild>
        {children}
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content           
          side="bottom"
          align="start"      
          sideOffset={-3}    
          className='dpFontFamilyPickerPopover forceDarkTheme'
        >
          {brandFonts.length > 0 && (
            <>
              {brandFonts.map((font) => (
                <DPFontFamilyPickerPopoverItem
                  key={`brand-${font.value}`}
                  label={font.label}            
                  isActive={selectedFontFamily === font.value}
                  onClick={() => handleFontFamilyClick(font.value)}
                />
              ))}
              
            </>
          )}
          <div className='dpFontFamilyPickerPopover-brandFonts-divider' />
          {sortedFonts.map((font) => (
            <DPFontFamilyPickerPopoverItem
              key={font.value}
              label={font.label}            
              isActive={selectedFontFamily === font.value}
              onClick={() => handleFontFamilyClick(font.value)}
            />
          ))}         
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

export default DPFontFamilyPickerPopover;