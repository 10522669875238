import * as THREE from 'three';

const createAdvancedRoundedRectShape = (width, height, radiusTL, radiusTR, radiusBR, radiusBL) => {
  const shape = new THREE.Shape();
  const x = -width / 2, y = -height / 2;

  // Start at the top left corner
  shape.moveTo(x + radiusTL, y);

  // Top edge
  shape.lineTo(x + width - radiusTR, y);
  if (radiusTR > 0) {
    shape.quadraticCurveTo(x + width, y, x + width, y + radiusTR);
  }

  // Right edge
  shape.lineTo(x + width, y + height - radiusBR);
  if (radiusBR > 0) {
    shape.quadraticCurveTo(x + width, y + height, x + width - radiusBR, y + height);
  }

  // Bottom edge
  shape.lineTo(x + radiusBL, y + height);
  if (radiusBL > 0) {
    shape.quadraticCurveTo(x, y + height, x, y + height - radiusBL);
  }

  // Left edge
  shape.lineTo(x, y + radiusTL);
  if (radiusTL > 0) {
    shape.quadraticCurveTo(x, y, x + radiusTL, y);
  }

  return shape;
};

export default createAdvancedRoundedRectShape;
