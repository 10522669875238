import React from 'react'
import Measure from 'react-measure'
import EditorCanvasContainer from './EditorCanvasContainer'
import EditorCanvasZoomUI from './EditorCanvasZoomUI'
import EditorCanvasZoomAutoUI from './EditorCanvasZoomAutoUI'
import EditorCanvasAddMediaUI from './EditorCanvasAddMediaUI'
import EditorCanvasResizeMediaUI from './EditorCanvasResizeMediaUI'
import EditorCanvasUploadMediaSpinner from './EditorCanvasUploadMediaSpinner'

const SCENE_ASPECT = 16/9

function shouldShowResizeMediaUI(clip,currentTimeIsInActiveClip,isDraggingProgressMarker,clips,currentTime,isTrimMode){
	if(!currentTimeIsInActiveClip || isDraggingProgressMarker || isTrimMode){
		return false
	}else{
		if(clip.type=='image' && !clip.isUploadingImage){
			if(clip.imageId ){
				return true
			}else{
				return false
			}
		} //TODO videos
		if(clip.type=='video'){
			if(clip.isBasicVideo && !clip.isUploadingVideo){
				//check there is not an clip above it
				let hasClipAbove
					clips.forEach((clip)=>{
						if(clip.zIndex==1 && currentTime >= clip.startTime && currentTime < (clip.startTime + clip.duration)){
							hasClipAbove=true
					}
					})
					if(!hasClipAbove){
						return true
					}else{
						return false
					}
				
			}else{
				return false
			}
		}
	}
	return false
}

function shouldShowUploadingMediaSpinner(clip,currentTimeIsInActiveClip,isDraggingProgressMarker){
	if(!currentTimeIsInActiveClip || isDraggingProgressMarker || !(clip.type=='image' || clip.type=='video')){
		return false
	}else{
		if(clip.isUploadingImage || clip.isUploadingVideo){
			return true
		}
	}
	return false
}

class EditorCanvasOuterContainer extends React.Component{  

	constructor(props) {
		super(props);
		this.state = {
      dimensions: {
        width: -1,
        height: -1,
        x: 0,
        y: 0
      }
    };
	}

	onResize = (contentRect) => {
	  this.setState({ 
	    dimensions: {
	      ...contentRect.bounds,
	      x: contentRect.bounds.left,
	      y: contentRect.bounds.top
	    }
	  });
	};
 
  render(){       
  	const {activePanelClip,currentTime,isDraggingProgressMarker} = this.props
		const {width, height} = this.state.dimensions
		const containerWidth = width
		const containerHeight = height
		let canvasWidth, canvasHeight
		 // Calculate the aspect ratio of the container
    const containerAspectRatio = containerWidth / containerHeight;
    if (containerAspectRatio > SCENE_ASPECT) {
      canvasHeight = containerHeight;
      canvasWidth = canvasHeight * SCENE_ASPECT;
    } else {
      canvasWidth = containerWidth;
      canvasHeight = canvasWidth / SCENE_ASPECT;
    }

    const canvasX = this.state.dimensions.x + ((containerWidth - canvasWidth) / 2) - 2 // don't know why 2 is required
    const canvasY = this.state.dimensions.y + ((containerHeight - canvasHeight) / 2)

    const showZoomUI = activePanelClip && activePanelClip.type=='zoom'

    //
    const currentTimeIsInActiveClip = activePanelClip && (currentTime >= activePanelClip.startTime) && (currentTime <= activePanelClip.endTime)
    const showAddMediaUI=false
    const showResizeMediaUI=shouldShowResizeMediaUI(activePanelClip,currentTimeIsInActiveClip,isDraggingProgressMarker,this.props.clips,currentTime,this.props.trimMode)
    
    const showUploadingMediaSpinner=shouldShowUploadingMediaSpinner(activePanelClip,currentTimeIsInActiveClip,isDraggingProgressMarker)   







		return (            				
			<div className='editor-center-center-canvasOuterOuterContainer'>
				<div style={{width: `${canvasWidth}px`, height: `${canvasHeight}px`}} className='editor-center-center-canvasOuterOuterContainer-guide'>
					<EditorCanvasContainer 
						canvasWidth={canvasWidth}
						canvasHeight={canvasHeight}
						canvasX={canvasX}
            canvasY={canvasY}
						{...this.props}
					/>
					{showZoomUI && activePanelClip.metadata.zoomValues && activePanelClip.metadata.zoomValues.originX !== 'auto' &&
						<EditorCanvasZoomUI							
							canvasWidth={canvasWidth}
							canvasHeight={canvasHeight}							
							updateZoomValues={this.props.updateZoomValues}
							zoomValues={this.props.activePanelClip.metadata.zoomValues}							
							updateZoomBox={this.props.updateZoomBox}
							clipId={this.props.activePanelClip.id}
							phoneRecording={activePanelClip.metadata.parentClipIsDeviceRecording}
							scalar={canvasWidth / 1920}
						/>
					}
					{showZoomUI && activePanelClip.metadata.zoomValues && activePanelClip.metadata.zoomValues.originX === 'auto' &&
						<EditorCanvasZoomAutoUI																					
							updateZoomValues={this.props.updateZoomValues}
							zoomValues={this.props.activePanelClip.metadata.zoomValues}							
							clipId={this.props.activePanelClip.id}
							updateZoomBox={this.props.updateZoomBox}
						/>
					}
					
					
					{/* {showAddMediaUI &&
						<EditorCanvasAddMediaUI
							show={showAddMediaUI}
							canvasWidth={canvasWidth}
							canvasHeight={canvasHeight}
							mediaType={activePanelClip.type=='image' ? 'image' : 'basicVideo'}
							handleNewImageFileUpload={this.props.handleNewImageFileUpload}
							clipId={activePanelClip.id}
						/>
					} */}

					
					{showUploadingMediaSpinner && 
						<EditorCanvasUploadMediaSpinner 
							canvasWidth={canvasWidth}
							canvasHeight={canvasHeight}
							clip={activePanelClip}
							mediaType={activePanelClip.type =='image'  ? 'image' : 'video'}
							projectBackgroundId={this.props.projectBackgroundId}
						/>
					}

					

					{showResizeMediaUI &&
						<EditorCanvasResizeMediaUI																					
							canvasWidth={canvasWidth}
							canvasHeight={canvasHeight}
							clip={activePanelClip}
							mediaType={activePanelClip.type =='image'  ? 'image' : 'video'}
							updateClipDisplayWidth={this.props.updateClipDisplayWidth}							
							originalMediaWidth={activePanelClip.metadata.originalWidth || 1000}
							originalMediaHeight={activePanelClip.metadata.originalHeight || 600}
							isDragResizingMedia={this.props.isDragResizingMedia}
    					startDragResizingMedia={this.props.startDragResizingMedia}
    					stopDragResizingMedia={this.props.stopDragResizingMedia}
    					projectBackgroundId={this.props.projectBackgroundId}
						/>
					}

				</div>
				<Measure
       		bounds
          onResize={this.onResize}
        >
          {({ measureRef }) => (
            <div ref={measureRef} className='editor-center-center-canvasMeasurer' id='canvasMeasurer' />
          )}
        </Measure>  			
			</div>					
		)
  }
}

export default EditorCanvasOuterContainer


