
//Dirty for now- hardcode the variable text color ids for the bacckground
const variableTextColorsForBG=[
  {
   "label": "blue",
    "projectBackground": "3",
    "variableTextColor":"5"
  },
  {
    "label": "green",
    "projectBackground": "4",
    "variableTextColor":"16"
  },
  {
    "label": "red",
    "projectBackground": "7",
    "variableTextColor":"12"
  },
  {
    "label": "purple",
    "projectBackground": "2",
    "variableTextColor":"8"
  },
  {
    "label": "orange",
    "projectBackground": "5",
    "variableTextColor":"14"
  },
  {
    "label": "navy",
    "projectBackground": "6",
    "variableTextColor":"3"
  }
]


export function getVariableTextColorForProject(project){
  let variableTextColor = null;
  if (project.background) {
    const matchingBackground = variableTextColorsForBG.find(
      bg => bg.projectBackground === project.background.toString()
    );
    if (matchingBackground) {
      variableTextColor = matchingBackground.variableTextColor;
    }
  }
  return variableTextColor
}




