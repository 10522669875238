function applyVoiceoverPlaybackRateToCaptions(captionsGroups, voiceoverPlaybackRate) {
  return captionsGroups.map(group => {
    const adjustedGroup = {
      ...group,
      startTime: (group.originalStartTime || group.startTime) / voiceoverPlaybackRate,
      endTime: (group.originalEndTime || group.endTime) / voiceoverPlaybackRate,
      words: group.words.map(word => ({
        ...word,
        startTime: (word.originalStartTime || word.startTime) / voiceoverPlaybackRate,
        endTime: (word.originalEndTime || word.endTime) / voiceoverPlaybackRate
      }))
    };
    return adjustedGroup;
  });
}

export { applyVoiceoverPlaybackRateToCaptions };