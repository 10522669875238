import React from 'react';
import Icon from '../../misc/Icon'
import {isLightBackground} from '../../../utils/brands/isLightBackground'

const EditorCanvasUploadMediaSpinner = ({
    canvasWidth,
    canvasHeight,
    clip,
    mediaType,    
    projectBackgroundId,
  }) => {
  
  
  
  const clipId = clip.id;
    
  const spinnerSide1920 = 400

  const scalar = canvasWidth / 1920

  const scaledSpinnerSide = spinnerSide1920 * scalar

  const backgroundId = clip.metadata.backgroundId || projectBackgroundId

  const isLightBG = isLightBackground(backgroundId)

  return (
    <div      
      className={'editor-canvasMediaUI editor-canvasMediaUI--uploading ' + (isLightBG ? ' editor-canvasMediaUI--lightBG ' : ' editor-canvasMediaUI--darkBG ')}
      style={{ width: canvasWidth, height: canvasHeight, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <div style={{ transform: `scale(${scalar})`}} className='editor-canvasMediaUI--uploading-spinnerContainer'>
        {mediaType === 'image' &&
          <Icon name='imageClipLight' />
        }
        {mediaType === 'video' &&
          <Icon name='videoClipLight' />
        }
      </div>
    </div>
  );
};

export default EditorCanvasUploadMediaSpinner;
