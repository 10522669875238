import React, {Component} from 'react';
import { connect } from 'react-redux';


class RecordingWidgetContainer extends Component {


    render() {
        return (
            <div>
                Recording Widget

                <button onClick={()=>{ window.ipcRenderer.send('stop-recording',{})}}> stop </button>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    
});

export default connect(
    mapStateToProps,
    {
    
    }
)(RecordingWidgetContainer);

