import tracks from './backgroundMusicTracks'
import find from 'lodash/find'
import {getUploadedBackgroundTracks} from './getUploadedBackgroundTracks'

export function getBackgroundMusicTrackForId(trackId) {
  let track = find(tracks,{id:trackId})

  if(!track){
    const uploadedBackgroundTracks = getUploadedBackgroundTracks()
    track = find(uploadedBackgroundTracks,{id:trackId})
  }
  if(!track){
    track={}
  }
  return track
}
