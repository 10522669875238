import React from 'react';

class TimelineSegment extends React.Component {
  render() {
    const { left, label, markers } = this.props;
    return (
      <React.Fragment>
        <div style={{ left: `${left}px` }} className="editor-timeline-timeRuler-segment">
          <div className="editor-timeline-timeRuler-segment-label">
            {label}
          </div>
          <div className="editor-timeline-timeRuler-segment-mainMarker" />
        </div>
        {markers.map((markerLeft, index) => (
          <div
            key={index}
            style={{ left: `${markerLeft}px` }}
            className="editor-timeline-timeRuler-segment-marker"
          />
        ))}
      </React.Fragment>
    );
  }
}

class EditorTimelineSceneTimeRuler extends React.PureComponent {
  render() {
    const { width, pixelsPerMs, timelineDurationSeconds, startTime = 0 } = this.props;

    const minDisplaySeconds = 10;
    const maxDisplaySeconds = timelineDurationSeconds;

    const pixelsPerSecond = pixelsPerMs * 1000;

    let markerInterval;
    let subMarkerInterval;

    if (pixelsPerSecond > 140) {
      markerInterval = 1;
      subMarkerInterval = 0.25; // Minor ticks at every 0.5 seconds
    } else if (pixelsPerSecond > 80) {
      markerInterval = 1;
      subMarkerInterval = 0.5; // Minor ticks at every 0.5 seconds
    } else if (pixelsPerSecond > 60) {
      markerInterval = 1;
      subMarkerInterval = null;
    } else if (pixelsPerSecond > 22) {
      markerInterval = 5;
      subMarkerInterval = 1;
    } else if (pixelsPerSecond > 7) {
      markerInterval = 10;
      subMarkerInterval = 2;
    } else {
      markerInterval = 30;
      subMarkerInterval = 5;
    }

    const timelineSegments = [];
    const initialSecond = Math.floor(startTime / markerInterval) * markerInterval;
    const endSecond = startTime + timelineDurationSeconds;

    for (let i = initialSecond; i <= endSecond; i += markerInterval) {
      const left = (i - startTime) * pixelsPerSecond;
      const markers = [];

      if (left + 10 > width) { // If the segment is too close to the right edge, skip rendering it
        continue;
      }

      if (subMarkerInterval) {
        for (let j = subMarkerInterval; j < markerInterval; j += subMarkerInterval) {
          markers.push(left + j * pixelsPerSecond);
        }
      }

      const totalSeconds = i;
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;

      let label = '';
      if (totalSeconds < 60) {
        label = `${String(seconds).padStart(2, '0')}`;
      } else {
        label = `${minutes}:${String(seconds).padStart(2, '0')}`;
      }

      timelineSegments.push(<TimelineSegment key={i} left={left} label={label} markers={markers} />);
    }

    return (
      <div className="editor-timeline-timeRuler" style={{ width: `${pixelsPerSecond * timelineDurationSeconds}px` }}>
        {timelineSegments}
      </div>
    );
  }
}

export default EditorTimelineSceneTimeRuler;
