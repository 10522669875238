import { Plugin, PluginKey, TextSelection } from 'prosemirror-state';
import { DecorationSet, Decoration } from 'prosemirror-view';
import {findParentNode} from './utils/findParentNode'
import {findFirstWordInLine} from './utils/findFirstWordInLine'
import {isFirstWordInChunk} from './utils/isFirstWordInChunk'
import {findFirstWordInChunk} from './utils/findFirstWordInChunk'
import {findClosestWordInChunk} from './utils/findClosestWordInChunk'
import {handleBackspaceInRecordingChunk} from './utils/handleBackspaceInRecordingChunk'

const webcamRecordingKeyPressPluginKey = new PluginKey('webcamKeypress');


const webcamRecordingKeyPressPlugin = new Plugin({

	key: webcamRecordingKeyPressPluginKey,
	props: {
		handleKeyDown(view, event) {
			const { state } = view;
			const { selection, doc } = state;
			const parent = findParentNode(
				node => node.type.name === 'transcriptGroup',
				selection
			);
		
			//This only applies to webcam recording groups
			if(!parent || parent.node.attrs.groupType !== 'webcam-recording'){
				return false;
			}

			if(event.key == 'Backspace'){

				//If we are the start of a chunk lets join it with the chunk above (unless its the first chunk)
				return handleBackspaceInRecordingChunk(view,event)

			}
			
			if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
				const { $from } = selection;
				// Only handle up/down if we're at chunk start
				let isAtChunkStart = false;
				let currentChunkPos = null;
				let currentChunk = null;
				//at +1 to parent pos to catch when the cursor is at the very start of the chunk
				doc.nodesBetween(parent.pos+1, parent.pos + parent.node.nodeSize, (node, pos) => {
					if (node.type.name === 'webcamRecordingChunk' && $from.pos === pos + 1) {
						isAtChunkStart = true;
						currentChunkPos = pos;
						currentChunk = node;
						return false;
					}
					return true;
				});
				// If not at chunk start, don't handle the key
				if (!isAtChunkStart || !currentChunk) {
					return false;
				}
				// Get current line's Y coordinate
				const domNode = view.domAtPos($from.pos)?.node;
				if (!domNode) return false;
				const currentRect = domNode.getBoundingClientRect();
				// Try to find line in current chunk first
				if (event.key === 'ArrowDown') {
	
					const nextLinePos = findFirstWordInLine(
						view,
						doc,
						currentChunk,
						currentChunkPos,
						currentRect.top + 20 // Look below current position
					);
			
					if (nextLinePos !== null) {
						view.dispatch(state.tr.setSelection(TextSelection.create(doc, nextLinePos)));
						event.preventDefault();
						return true;
					}
				}
				// If no line found in current chunk (or going up), look in adjacent chunks
				const chunks = [];
				doc.nodesBetween(parent.pos, parent.pos + parent.node.nodeSize, (node, pos) => {
					if (node.type.name === 'webcamRecordingChunk') {
						chunks.push({ node, pos });
					}
				return true;
				});

				const currentIndex = chunks.findIndex(chunk => chunk.pos === currentChunkPos);

				if (currentIndex === -1) return false;

						const targetIndex = event.key === 'ArrowUp' ? currentIndex - 1 : currentIndex + 1;
						if (targetIndex < 0 || targetIndex >= chunks.length) return false;

						const targetChunk = chunks[targetIndex];
						const targetPos = findFirstWordInLine(
							view,
							doc,
							targetChunk.node,
							targetChunk.pos,
							currentRect.top
						);

						if (targetPos !== null) {
							view.dispatch(state.tr.setSelection(TextSelection.create(doc, targetPos)));
							event.preventDefault();
							return true;
						}
						return false;
					}

					if (event.key === 'ArrowLeft') {
						const { $from } = selection;
						const chunkStart = parent.pos + 1; // Position after the opening tag
						const chunkEnd = parent.pos + parent.node.nodeSize - 1;
						let isAlreadyAtChunkStart = false;
						doc.nodesBetween(parent.pos, parent.pos + parent.node.nodeSize, (node, pos) => {
							if (node.type.name === 'webcamRecordingChunk' && $from.pos === pos + 1) { // +1 because chunk start is after opening tag
								isAlreadyAtChunkStart = true;
								return false;
							}
							return true;
						});
						// If already at chunk start, let default behavior handle it
						if (isAlreadyAtChunkStart) {
							return false;
						}
						const result = isFirstWordInChunk(doc, $from.pos, parent.pos);
						if (result.isFirst) {
							// Move to the start of the chunk using the chunk position we found
							view.dispatch(state.tr.setSelection(TextSelection.create(doc, result.chunkPos)));
							event.preventDefault();
							return true;
						}
						// Otherwise, find the previous word as before
						const prevPos = findNextNodePosition(
							doc,
							$from.pos,
							chunkStart,
							['webcamTranscriptWord'],
							'backward'
						);
						if (prevPos !== null) {
							view.dispatch(state.tr.setSelection(TextSelection.create(doc, prevPos)));
							event.preventDefault();
							return true;
						}
						return false;
					}

					if (event.key === 'ArrowRight') {
						const { $from } = selection;
						const chunkEnd = parent.pos + parent.node.nodeSize - 1;
						// Check if we're at a chunk start position
						let isAtChunkStart = false;
						let chunkStartPos = null;
						doc.nodesBetween(parent.pos, parent.pos + parent.node.nodeSize, (node, pos) => {
							if (node.type.name === 'webcamRecordingChunk' && $from.pos === pos + 1) { // +1 because chunk start is after opening tag
								isAtChunkStart = true;
								chunkStartPos = pos + 1;
								return false;
							}		
						return true;
						});
						if (isAtChunkStart && chunkStartPos) {
							// Find the first word in this chunk
							const firstWordPos = findFirstWordInChunk(doc, chunkStartPos);
							if (firstWordPos) {
								view.dispatch(state.tr.setSelection(TextSelection.create(doc, firstWordPos)));
								event.preventDefault();
								return true;
							}
						}
						// If we're not at chunk start or couldn't find first word, use normal behavior
						const nextPos = findNextNodePosition(
							doc,
							$from.pos,
							chunkEnd,
							['webcamTranscriptWord'],
							'forward'
						);
						if (nextPos !== null) {
							view.dispatch(state.tr.setSelection(TextSelection.create(doc, nextPos)));
							event.preventDefault();
							return true;
						}
						return false;
					}

					// Allow only arrow keys and enter key
					const allowedKeys = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Enter','Backspace'];
					if (allowedKeys.includes(event.key)) {
						return false; // Allow default behavior
					} else {
						event.preventDefault();
						return true;
					}
        },
        // Rest of the plugin implementation remains the same...
        handleKeyPress(view, event) {
					const { state } = view;
					const { selection } = state;
					const parent = findParentNode(
						node => node.type.name === 'webcamRecordingChunk',
						selection
					);
					if (parent) {
						event.preventDefault();
						return true;
					}
					return false;
        },

				handleDOMEvents: {
					beforeinput(view, event) {
						const { state } = view;
						const { selection } = state;
						const parent = findParentNode(
							node => node.type.name === 'webcamRecordingChunk',
							selection
						);
						if (!parent) return false;
						
						const blockedInputTypes = [
							'insertText',
							'insertCompositionText',
							'insertParagraph',
							'insertLineBreak',
							'deleteContent',
							'deleteByCut',
							'deleteContentBackward',
							'deleteContentForward',
							'insertFromPaste',
							'insertFromDrop',
						];
						if (blockedInputTypes.includes(event.inputType)) {
							event.preventDefault();
							return true;
						}
						return false;
					},
				},
         decorations(state) {
            const { selection } = state;
            const parent = findParentNode(
                node => node.type.name === 'webcamRecordingChunk',
                selection
            );
            if (!parent) return null;
            const { $from } = selection;
            const decorations = [];
            // Check if cursor is at the start of a chunk
            let isAtChunkStart = false;
            let chunkStartPos = null;
            state.doc.nodesBetween(parent.pos, parent.pos + parent.node.nodeSize, (node, pos) => {
                if (node.type.name === 'webcamRecordingChunk' && 
                    $from.pos === pos + 1) { // +1 because chunk start is after opening tag
                    isAtChunkStart = true;
                    chunkStartPos = pos + 1;
                    return false;
                }
                return true;
            });
            // If at chunk start, add gap cursor
            if (isAtChunkStart && chunkStartPos !== null) {
                decorations.push(
                    Decoration.widget(chunkStartPos, () => {
                        const span = document.createElement('span');
                        span.className = 'editor-transcriptPanel-transcriptGroup--cameraRecording-gapCursor';
                        return span;
                    })
                );
            }
            // If within a word, add existing decorations
            const currentNode = $from.parent;
            if (currentNode.type.name === 'webcamTranscriptWord') {
                decorations.push(
                    Decoration.node($from.before($from.depth), $from.after($from.depth), {
                        class: 'editor-transcriptPanel-transcriptGroup--cameraRecording-word--active'
                    }),
                    Decoration.widget($from.after($from.depth), () => {
                        const span = document.createElement('span');
                        span.className = 'editor-transcriptPanel-transcriptGroup--cameraRecording-gapCursor';
                        return span;
                    })
                );
            }
            return decorations.length > 0 ? DecorationSet.create(state.doc, decorations) : null;
        }
    },
});

function findNextNodePosition(doc, startPos, endPos, nodeTypes, direction = 'forward') {
	let foundPos = null;
	const positions = [];
	doc.nodesBetween(
		Math.min(startPos, endPos),
		Math.max(startPos, endPos),
		(node, pos) => {
			if (nodeTypes.includes(node.type.name)) {
				positions.push({ node, pos, nodeSize: node.nodeSize, type: node.type.name, text: node.textContent });
			}
		}
	);
	if (direction === 'forward') {
		for (let i = 0; i < positions.length; i++) {
			if (positions[i].pos > startPos) {
				foundPos = positions[i].pos + positions[i].nodeSize - 2;
				break;
			}
		}
	} else {
		for (let i = positions.length - 1; i >= 0; i--) {
			if (positions[i].pos < startPos) {
				foundPos = positions[i].pos - 2;
				break;
			}
		}
	}
	return foundPos;
}

export default webcamRecordingKeyPressPlugin;