import React, { useRef, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import DPToggleGrid from './DPToggleGrid';

const DPLayoutBox = ({
  vAlign,
  setVAlign,
  hAlign,
  setHAlign,
  alignmentType,
  setAlignmentType,
  isDragging,
  setIsDragging  
}) => {
  const containerRef = useRef(null);
  
  // Define different button widths and heights
  const btnWidth = 24; // width of each column
  const btnHeight = 22; // height of each row
  const padding = 3; // padding around the edge

  const getPosition = () => {
    const positions = {
      top: 0 + padding,
      middle: btnHeight + padding,
      bottom: btnHeight * 2 + padding,
      left: 0 + padding,
      center: btnWidth + padding,
      right: btnWidth * 2 + padding
    };
    return {
      top: positions[vAlign],
      left: positions[hAlign]
    };
  };

  const { top, left } = getPosition();

  const springProps = useSpring({
    top,
    left,
    config: { tension: 800, friction: 40 }
  });

  const updateAlignment = (clientX, clientY) => {
    if (!containerRef.current) return;
    const rect = containerRef.current.getBoundingClientRect();
    const x = clientX - rect.left - padding;
    const y = clientY - rect.top - padding;
    const newHAlign = x < btnWidth ? 'left' : x < btnWidth * 2 ? 'center' : 'right';
    const newVAlign = y < btnHeight ? 'top' : y < btnHeight * 2 ? 'middle' : 'bottom';
    setHAlign(newHAlign);
    setVAlign(newVAlign);
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    updateAlignment(e.clientX, e.clientY);
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      updateAlignment(e.clientX, e.clientY);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleWindowBlur = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
      window.addEventListener('blur', handleWindowBlur);
    }
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('blur', handleWindowBlur);
    };
  }, [isDragging]);

  const backgroundBoxes = Array(9).fill(null).map((_, index) => (
    <div
      key={index}
      style={{
        width: `${btnWidth}px`,
        height: `${btnHeight}px`,        
      }}
      className='dpLayoutBox-box-dotBox'
    >
      <div className='dpLayoutBox-box-dotBox-dot' />
    </div>
  ));

  return (
    <>
      <div className='dpLayoutBox'>
        <div className='dpLayoutBox-leftContainer'>
          <div 
            ref={containerRef}
            style={{
              width: `${btnWidth * 3 + padding * 2}px`, 
              height: `${btnHeight * 3 + padding * 2}px`, 
              padding: `${padding}px`,
              position: 'relative',
              display: 'flex',
              flexWrap: 'wrap',
            }} 
            className='dpLayoutBox-box'
            data-dragging-state={isDragging}
            onMouseDown={handleMouseDown}
          >
            {backgroundBoxes}
            <animated.div 
              style={{
                width: `${btnWidth}px`,
                height: `${btnHeight}px`,
                position: 'absolute',
                top: springProps.top,
                left: springProps.left,
              }}
              className='dpLayoutBox-box-indicatorSquare'
            >
              <div className='dpLayoutBox-indicatorSquare-inner' />
            </animated.div>
          </div>
        </div>
        
              
      </div>
      <div className='editor-detailPanel-header-2rowToggleBottonVSpacer'/>
    </>
  );
};

export default DPLayoutBox;