const pronunciationDictionary = [
    {
        word: 'Replo',
        ph: "/'rɛpː.loʊ/",
        alt: "rep-low"
    },
    {
        word: 'Claygent',
        // alt: "Clayjent"
        alt: "Clayjint"
    },
    {
        word: 'baseten',
        alt: "base ten"
    },
     {
        word: 'pos',
        alt: "pee-oh-ess"
    },
    
    {   word:'ltd.',
        alt:"ell-tee-dee"
    },
    {   word:'ltd',
        alt:"ell-tee-dee"
    }

];

export default pronunciationDictionary;