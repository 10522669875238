import {updateProjectPrivateStatus} from '../../actions/projects'
import store from '../../store'

export async function shareProjectWithTeam(projectId) {
  const isPrivate=false
  store.dispatch(updateProjectPrivateStatus(projectId,isPrivate))
}


 

