import React, { useRef, useEffect, useState,useMemo } from 'react';
import * as THREE from 'three';
import { animated, useSpring } from '@react-spring/three';
import SlideFitImageElementMaterial from './SlideFitImageElementMaterial';
import SlideFillImageElementMaterial from './SlideFillImageElementMaterial';
import createRoundedRectShape from '../../utils/createRoundedRectShape';
import defaultImageAnimationValues from '../../utils/animations/defaultImageAnimationValues'

const sceneWidth = 1920;
const sceneHeight = 1080;

const SlideImagePreviewElement = ({ 
  imageElement,   
  time,
  localTime,
  visible,   
  variableValues, 
  isDraggingElement,
  isInDraggingGroup,
  isResizingElement,
  anyElementResizing,
  isDraggingSpacer,
  isDraggingToReorder
}) => {  
  const deviceRef = useRef();
  const [isDelayedDraggingToReorder, setIsDelayedDraggingToReorder] = useState(isDraggingToReorder);
  const delayTimeoutRef = useRef(null);

  let maxImageOpacity = 1
  if(imageElement.metadata.opacity){
    maxImageOpacity = imageElement.metadata.opacity
  }

  let imageWidth = imageElement.width
  let imageHeight = imageElement.height
  let imageX = imageElement.x
  let imageY = imageElement.y
  let imageCornerRounding = imageElement.metadata.cornerRounding
  let isFill = imageElement.metadata.isFill

  if(imageElement.metadata.isBGImage){
    imageWidth = 1920
    imageHeight = 1080
    imageX = 0
    imageY = 0
    imageCornerRounding = 0
    isFill = true
  }


  useEffect(() => {
    if (isDraggingToReorder) {
      setIsDelayedDraggingToReorder(true);
      if (delayTimeoutRef.current) {
        clearTimeout(delayTimeoutRef.current);
      }
    } else {
      delayTimeoutRef.current = setTimeout(() => {
        setIsDelayedDraggingToReorder(false);
      }, 200);
    }

    // Cleanup function
    return () => {
      if (delayTimeoutRef.current) {
        clearTimeout(delayTimeoutRef.current);
      }
    };
  }, [isDraggingToReorder]);

  const isAnimated = isDelayedDraggingToReorder && !isDraggingElement && !isInDraggingGroup;  

  const notAnimated = !isAnimated
  //const notAnimated = isDraggingElement || isInDraggingGroup || anyElementResizing || isDraggingSpacer;
  const prevNotAnimated = useRef(notAnimated);  
  
  const originalImageWidth = imageElement.metadata.original.width;
  const originalImageHeight = imageElement.metadata.original.height; 
  const originalImageAspect = originalImageWidth / originalImageHeight;
  const meshWidth = imageWidth;
  const meshHeight = imageHeight;
  const meshAspect = meshWidth / meshHeight;

  const meshX = imageX
  const meshY = imageY

  const meshNormalizedLeft = imageX + meshWidth / 2 - sceneWidth / 2;
  const meshNormalizedTop = -imageY - meshHeight / 2 + sceneHeight / 2;
  
  const initialProperties = defaultImageAnimationValues

  //
  // Fill Image Stuff
  let fillImageWidth, fillImageHeight;

  if (meshAspect > originalImageAspect) {
    fillImageWidth = meshWidth;
    fillImageHeight = meshWidth / originalImageAspect;
  } else {
    fillImageHeight = meshHeight;
    fillImageWidth = meshHeight * originalImageAspect;
  }

  // console.log('image preview here------')

  // console.log('imageElement.previewX')
  // console.log(imageElement.previewX)
  // console.log(imageElement.x)
  // console.log('imageElement.x')
  // console.log(imageElement.x)

  const calculatePosition = () => {
    let x, y;
    //if (isDraggingElement && !isInDraggingGroup && 'previewX' in imageElement && 'previewY' in imageElement) {
          // if (isDraggingElement && !isInDraggingGroup && 'previewX' in imageElement && 'previewY' in imageElement) {
      x = imageElement.previewX || imageX;
      y = imageElement.previewY || imageY;
    // } else {
    //   x = imageElement.x;
    //   y = imageElement.y;
    // }
    return [
      x + meshWidth / 2 - sceneWidth / 2,
      -(y + meshHeight / 2 - sceneHeight / 2),
      0
    ];
  };

  const [springs, api] = useSpring(() => ({
    position: calculatePosition(),
    config: { tension: 400, friction: 32 },
    immediate: notAnimated,
  }));

  useEffect(() => {
    const newPosition = calculatePosition();
    api.start({
      position: newPosition,
      immediate: notAnimated,
    });
    prevNotAnimated.current = notAnimated;
  }, [imageX, imageY, imageElement.previewX, imageElement.previewY, imageWidth, imageHeight, notAnimated, isInDraggingGroup]);


  //
  //  Calc Device Geemotry

  const maxRadius = Math.min(meshWidth, meshHeight) / 2;
  let meshRadius = imageCornerRounding ? Math.min(imageCornerRounding, maxRadius) : 0;
  const isCircle = meshRadius >= maxRadius && meshWidth === meshHeight;

  const deviceGeometry = useMemo(() => {
    if (isCircle) {
      return new THREE.CircleGeometry(maxRadius, 64);
    } else if (meshRadius === 0) {
      return new THREE.PlaneGeometry(meshWidth, meshHeight);
    } else {
      const shape = createRoundedRectShape(meshWidth, meshHeight, meshRadius);
      const geometry = new THREE.ShapeGeometry(shape);
      const uvs = new Float32Array(geometry.attributes.position.count * 2);
      for (let i = 0; i < geometry.attributes.position.count; i++) {
        const vertex = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, i);
        uvs[i * 2] = (vertex.x + meshWidth / 2) / meshWidth;
        uvs[i * 2 + 1] = (vertex.y + meshHeight / 2) / meshHeight;
      }
      geometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));
      return geometry;
    }
  }, [meshWidth, meshHeight, meshRadius, isCircle, maxRadius]);

  let zIndex = imageElement.zIndex;
  const imgSrc = imageElement.metadata.imgSrc

  if(isDraggingElement){
    zIndex = 1000
  }
  
  
  return (
    <>
      {imgSrc && (
        <animated.group renderOrder={zIndex} position={springs.position}>
          <mesh
            ref={deviceRef}
            position={[initialProperties.positionX, initialProperties.positionY, 0]}
            scale={[initialProperties.scale, initialProperties.scale, initialProperties.scale]}
            geometry={deviceGeometry}
            visible={visible}
            renderOrder={zIndex}
          >
            {!isFill &&
              <SlideFitImageElementMaterial 
                imgSrc={imgSrc}   
                opacity={maxImageOpacity}
                renderOrder={zIndex}
              />
            }

            {isFill &&
              <SlideFillImageElementMaterial 
                imgSrc={imgSrc}   
                opacity={maxImageOpacity}
                meshWidth={meshWidth}
                meshHeight={meshHeight}
                imageWidth={fillImageWidth}
                imageHeight={fillImageHeight}
                renderOrder={zIndex}
              />     
            }

          </mesh>
        </animated.group>
      )}
    </>
  );
};

export default SlideImagePreviewElement;