import {fetch,put} from '../api'
import { Mixpanel } from '../Mixpanel'
import LogRocket from 'logrocket';
import posthog from 'posthog-js';

import {fetchOrgMembers} from './orgMembers'



// function identifyUser(userId, userEmail) {
//   posthog.identify(userId, {
//     $email: userEmail, 
//     $name: userName
//   });
// }


export function fetchUser() {
	return (dispatch) => fetch(`/user`)
		.then((response) => {
			Mixpanel.identify(response.id)
			
			Mixpanel.people.set({
				$name: response.display_name,
				$email: response.email
			});
			
			LogRocket.identify(response.id, {
				name: response.display_name,
				email: response.email
			});

		//	identifyUser(response.id,response.email,response.display_name)
		
		dispatch({ type: 'FETCH_USER_SUCCESS', response })
		
		return response
})
.catch((error) => {
return error
})
}



export function updateProMode(isProMode) {

    const reqBody = { isProMode: isProMode };

    return (dispatch) => {
        dispatch({ type: 'UPDATE_PRO_MODE',isProMode});
        return put(`/user/pro-mode`, reqBody)
            .then((response) => {
                Mixpanel.track('update_pro_mode', { isProMode: isProMode });
                return response;
            })
            .catch((error) => {
                console.log(error);
                
                return error;
            });
    };
}
// export function updateProMode(isProMode) {
// 	console.log('update is pro mode')
// 	const reqBody={isProMode:isProMode}

// 	return (dispatch) => put(`/user/pro-mode`,reqBody)
// 		.then((response) => {
// 			//dispatch(fetchOrgMembers())
// 			//Mixpanel.track('suspend-user',{userId:userId})
// 		return response
// 		})
// 		.catch((error) => {
// 			console.log(error)
// 			return error
// 		})
// }



export function suspendUser(userId) {
	return (dispatch) => put(`/suspend-user/${userId}`)
		.then((response) => {
			dispatch(fetchOrgMembers())
			Mixpanel.track('suspend-user',{userId:userId})
		return response
		})
		.catch((error) => {
			console.log(error)
			return error
		})
}




export function activateUser(userId) {
	return (dispatch) => put(`/activate-user/${userId}`)
	.then((response) => {
		dispatch(fetchOrgMembers())
		Mixpanel.track('activate-user',{userId:userId})		
		return response
	})
	.catch((error) => {
		console.log(error)
		return error
	})
}

