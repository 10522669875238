import React, { useState,useEffect } from 'react';
import Icon from '../../../misc/Icon';
import DPToggleGrid from '../DPToggleGrid';
import DPTextInput from '../DPTextInput';
import DPItemAnimationPanel from '../DPItemAnimationPanel';
import DPNumberInput from '../DPNumberInput';
import {parseDataIntoDonutChart} from '../../../../utils/charts/parseDataIntoDonutChart'
import { getOrgBrand } from '../../../../utils/brands/getOrgBrand'
import DPColorPicker from '../DPColorPicker';

const sampleData = [    
  'Up to 60% faster',
  '90% of US adults have bought from Stripe businesses',    
  'Over 80% increase in positive call outcomes',    
  '85% Fortune 500',
];



const EditorDetailPanelChartDonut = (props) => {
  const {updateSlideElementMetadata,clipId,element,handleDimensionsUpdatedFromDetailPanel} = props
  
  const handleElementUpdateMetadata= (field,value) => {
    updateSlideElementMetadata(clipId,element.id,{...element.metadata,[field]:value})
  }  

  
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSampleDataClick = () => {
    const nextIndex = currentIndex + 1 === sampleData.length ? 0 : currentIndex + 1;
    setCurrentIndex(nextIndex);
    setDonutInputRawData(sampleData[currentIndex])
  };



  const handleUpdateDonutChartAnimationType=(newValue)=>{
    let newMetadata={...element.metadata}
    newMetadata.donutChartData.animationType=newValue
    updateSlideElementMetadata(clipId,element.id,newMetadata)
  }

  const handleUpdateMetadata = (field,value) => { 
    updateSlideElementMetadata(clipId,element.id,{...element.metadata,[field]:value})
  }  
  

  const fillOpacity = element.metadata.donutOpacity*100 || 50

  const setFillOpacity= (value) => {  
    const opacity = value/100
    updateSlideElementMetadata(clipId,element.id,{...element.metadata,donutOpacity:opacity})
  }  

  const getAvailableChartColorsForBrand = () => {
    const orgBrand = getOrgBrand();
    const solidColors = orgBrand.backgrounds.filter(bg => bg.type === 'solid');
    return [
      { id: 'auto', value: 'auto', label: 'Auto' },
      ...solidColors.map(color => ({
        id: color.id,
        value: color.rgba,
        type: 'solid'
      }))
    ];
  };

  const availableChartColors = getAvailableChartColorsForBrand();

  let donutFillColorId = element.metadata.donutFillColorId
  if(!donutFillColorId){
    donutFillColorId='auto'
  }


  const handleSetFillColorId=(value)=>{
    let colorId=value
    if(value =='auto'){
      colorId = null
    }
    let newMetadata={...element.metadata}
    newMetadata.donutFillColorId=colorId
    updateSlideElementMetadata(clipId,element.id,newMetadata)
  }

  // const [donutAnimationTypeRaw, setDonutAnimationTypeRaw] = useState(clip.metadata.donutChartData.animationType);  
  const [donutChartData, setDonutChartData] = useState({ compatible: false });
  const [donutInputRawData, setDonutInputRawData] = useState(element.metadata.donutChartData.rawData);

  useEffect(() => {
    if(donutChartData.compatible){
      let metadata={...element.metadata}
      metadata.donutChartData.rawData = donutInputRawData
      metadata.donutChartData.aboveLabel=donutChartData.aboveLabel
      metadata.donutChartData.belowLabel=donutChartData.belowLabel
      metadata.donutChartData.value=donutChartData.value
      updateSlideElementMetadata(clipId,element.id,metadata)
    }
  }, [donutChartData]);


  useEffect(() => {
    const donutChartData = parseDataIntoDonutChart(donutInputRawData);
    setDonutChartData(donutChartData);
  }, [donutInputRawData]);


  return (
    <>      
 
        <div className='editor-detailPanel-header-alignVSpacer' />
        <div className='editor-detailPanel-divider' />
      
        <div className='editor-detailPanel-row'>
          <div className='editor-detailPanel-row-label'>
            Data Input
          </div>
        </div>  
        <div className='editor-detailPanel-row editor-detailPanel-row--donutDataInput'>          
          <DPTextInput            
            value={donutInputRawData}
            setValue={setDonutInputRawData}
            fullWidth
          />
        </div>
        

     
        <div className='editor-detailPanel-row'>
        <button 
          onClick={handleSampleDataClick}          
          className='dpButton dpButton--labelOnly dpButton--fullWidth'
        >
          <div className='dpButton-label'>
            Sample Data
          </div>
        </button>
      </div>


      <div className='editor-detailPanel-divider' />

      <div className='editor-detailPanel-row'>
        <div className='editor-detailPanel-row-label'>
          Fill Color
        </div>
        <div className='editor-detailPanel-row-hSpacer' />            
            <DPColorPicker                    
              activeColorId={donutFillColorId}
              onChange={handleSetFillColorId}
              options={availableChartColors}              
              chartColors
              valueBtnAutoWidth={72}
            />   
        </div>



  
      <div className='editor-detailPanel-divider' />
               
       <div className='editor-detailPanel-row'>
        <div className='editor-detailPanel-row-label'>
          Fill Opacity
        </div>
        <div className='editor-detailPanel-row-hSpacer' />            
        <DPNumberInput 
          percentage
          min={20}
          max={100}
          value={fillOpacity}
          setValue={setFillOpacity}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          iconName='opacity'                  
        />
      </div>  

     
      <div className='editor-detailPanel-divider' />
     
    
      <DPItemAnimationPanel 
        item={props.element}
        handleUpdateMetadata={handleUpdateMetadata}
        handleUpdateChartAnimationType={handleUpdateDonutChartAnimationType}
        activeStartTransition={props.element.metadata.donutChartData.animationType}
        itemType='donutChart'
      />
      
  
       
    </>
  );
};

export default EditorDetailPanelChartDonut;

