import React, {Component} from 'react'
import { connect } from 'react-redux'
import { withRouter} from 'react-router-dom'
//import LibraryPage from '../components/library/LibraryPage'

// import CaliTest from '../threejs/CaliTest'
import { Canvas, useLoader, useThree, useFrame } from '@react-three/fiber';
// import VPerfTest from '../threejs/VPerfTest';

//import TimeStretch from '../playground/timeStretch/TimeStretch'
import CursorGame from '../playground/CursorGame'


class CursorGameContainer extends Component {

	constructor(){
		super()
	}

	componentDidMount() {	

	}
	componentWillUnmount() {

	}


	render() {

		return(				
				
				<>

				 	{/* <TimeStretch />*/}
					<CursorGame /> 
					
				</>
	

			)
	}
}

function mapStateToProps(state,ownProps) {
	
	return {

	}
}

export default withRouter(connect(
	mapStateToProps,
	{})(CursorGameContainer))


