export function formatTimelineDurationProMode(seconds) {
  let minutes = Math.floor(seconds / 60);
  let wholeSeconds = Math.floor(seconds % 60);
  let milliseconds = (seconds % 1).toFixed(2).substring(2); // Extract and format milliseconds

  let minutesStr = minutes.toString().padStart(2, '0');
  let secondsStr = wholeSeconds.toString().padStart(2, '0');

  return minutesStr + ':' + secondsStr + ':' + milliseconds;
}
