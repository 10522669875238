

//00:45
//ignore hours for now
export function formatTimelineDuration(seconds) {
  let roundedSeconds = Math.round(seconds);
  let minutes = Math.floor(roundedSeconds / 60);
  let secs = roundedSeconds % 60;
  let minutesStr = minutes.toString().padStart(2, '0');
  let secondsStr = secs.toString().padStart(2, '0');
  return minutesStr + ':' + secondsStr;
}
