import {setFilesToBeDownloaded} from '../../actions/downloads'
import store from '../../store'


export async function downloadMissingScreenRecordings(recordings) {
	const screenRecordingsList = await ipcRenderer.invoke('get-screen-recordings-list'); 
	for (const recording of recordings) {
		const captureId=recording.capture_id
		if (!screenRecordingsList.includes(captureId)) {
			console.log(`Downloading missing screen recording: ${captureId}`);
			ipcRenderer.invoke('download-screen-recording-files',captureId); 
			const isDevice = false 
			const isScreenRecording = true
			store.dispatch(setFilesToBeDownloaded(captureId,isDevice,isScreenRecording))
		}else{
		//	console.log('we already have the recording ')
		}
	}
}

