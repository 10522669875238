import {openProjectRequest} from '../../actions/projects'
import store from '../../store'
import {addTab} from '../../actions/tabs'


export function openProject(projectId,history) {
  if(history){
     history.push(`/project/${projectId}`)
  }else{
    const state = store.getState()
    const tabs = state.tabs
    if(!tabs.some(tab => tab.projectId === projectId)){
      const newTab = {
        id: projectId,
        projectId: projectId,
        path: `/project/${projectId}`,
        active: false
      };
    store.dispatch(addTab(newTab)) 
    }
  }
  store.dispatch(openProjectRequest(projectId)) 
}
