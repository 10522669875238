async function getDeviceDimensionsForCaptureId(captureId) {
  const relativePath = `deviceRecordings/${captureId}/metadata.json`;
  const content = await ipcRenderer.invoke('read-file', relativePath);
  const metadata = JSON.parse(content);
  if(metadata){
   return metadata.dimensions
  }
  else return{
    height:1000,
    width:800
  }
}

export { getDeviceDimensionsForCaptureId }


