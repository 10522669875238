import React, { useState, useEffect } from 'react';
import { Rnd } from 'react-rnd';
import { Slider } from '../../uiKit/Slider';
import Icon from '../../misc/Icon';
import { convertZoomValuesToZoomBox } from '../../../three/utils/zoom/convertZoomValuesToZoomBox'; 
import { useSpring, animated } from 'react-spring';

const ZOOM_MIN = 0.55
const ZOOM_MAX = 1

const DragTipOverlay = ({ isVisible }) => {
  const animation = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? 'scale(1)' : 'scale(0.95)',
    delay: isVisible ? 100 : 200,
    config: { tension: 300, friction: 20 }
  });

  return (
    <div className='editor-canvasZoomUI-tipOverlayContainer'>
      <animated.div style={animation} className='editor-canvasZoomUI-tipOverlay'>
        <div className='editor-canvasZoomUI-tipOverlay-iconContainer'>
          <Icon name='moveFinger' />
        </div>
        <div className='editor-canvasZoomUI-tipOverlay-label'>
          Drag to move the zoom
        </div>
      </animated.div>
    </div>
  );
};

const ScrollTipOverlay = ({ isVisible }) => {
  const animation = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? 'scale(1)' : 'scale(0.95)',
    delay: isVisible ? 600 : 200,
    config: { tension: 300, friction: 20 }
  });

  return (
    <div className='editor-canvasZoomUI-tipOverlayContainer'>
      <animated.div style={animation} className='editor-canvasZoomUI-tipOverlay'>
        <div className='editor-canvasZoomUI-tipOverlay-label'>
          Scroll trackpad to zoom in and out
        </div>
      </animated.div>
    </div>
  );
};

const calculateOrigin = (scale, canvasWidth, canvasHeight, positionX, positionY) => {
    const panObjectWidth = canvasWidth / scale;
    const panObjectHeight = canvasHeight / scale;
    
    const scaleTo1920 = 1920 / panObjectWidth;

    const scaledWindowWidth = canvasWidth * scaleTo1920;
    const scaledWindowHeight = canvasHeight * scaleTo1920;

    const scaledPanObjectWidth = panObjectWidth * scaleTo1920;
    const scaledPanObjectHeight = panObjectHeight * scaleTo1920;

    // Adjust for the 3x size of Rnd
    const scaledX = (positionX + panObjectWidth) * scaleTo1920;
    const scaledY = (positionY + panObjectHeight) * scaleTo1920;

    const newOriginX = (scaledX * -1) - ((scaledPanObjectWidth - scaledWindowWidth) / 2);
    const newOriginY = (scaledY * -1) - ((scaledPanObjectHeight - scaledWindowHeight) / 2);
    
    return { newOriginX, newOriginY };
};

const calculatePosition = (scale, canvasWidth, canvasHeight, originX, originY) => {
    const panObjectWidth = canvasWidth / scale;
    const panObjectHeight = canvasHeight / scale;
    
    const scaleTo1920 = 1920 / panObjectWidth;

    const scaledWindowWidth = canvasWidth * scaleTo1920;
    const scaledWindowHeight = canvasHeight * scaleTo1920;

    const scaledPanObjectWidth = panObjectWidth * scaleTo1920;
    const scaledPanObjectHeight = panObjectHeight * scaleTo1920;

    // Reverse the calculations to get positionX and positionY from originX and originY
    const scaledX = (originX + ((scaledPanObjectWidth - scaledWindowWidth) / 2)) * -1;
    const scaledY = (originY + ((scaledPanObjectHeight - scaledWindowHeight) / 2)) * -1;

    // Adjust for the 3x size of Rnd
    const x = scaledX / scaleTo1920 - panObjectWidth;
    const y = scaledY / scaleTo1920 - panObjectHeight;

    return { x, y };
};

const EditorCanvasZoomUI = ({ canvasWidth, canvasHeight, updateZoomValues, updateZoomBox, zoomValues, clipId, phoneRecording, scalar }) => {
    const { scale, originX, originY } = zoomValues;

    const initialPosition = calculatePosition(scale, canvasWidth, canvasHeight, originX, originY);
        
    const [position, setPosition] = useState(initialPosition);

    const positionX = position.x
    const positionY = position.y

    const panObjectWidth = canvasWidth / scale;
    const panObjectHeight = canvasHeight / scale;

    const [showXSnapline, setShowXSnapline] = useState(false);
    const [showYSnapline, setShowYSnapline] = useState(false);
    const [isDragging, setIsDragging] = useState(true);

    const [hasDragged, setHasDragged] = useState(false);    
    const [hasScrolled, setHasScrolled] = useState(false);     
      const [showDragTip, setShowDragTip] = useState(false);

     useEffect(() => {
        // Show the drag tip after a short delay
        const timer = setTimeout(() => {
            setShowDragTip(true);
        }, 50);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (hasDragged) {
            setShowDragTip(false);
        }
    }, [hasDragged]);



    useEffect(() => {
      const newPosition = calculatePosition(scale, canvasWidth, canvasHeight, originX, originY);
      setPosition(newPosition);
    }, [scale, canvasWidth, canvasHeight, originX, originY]);

    //let preventXMovement = phoneRecording
    let preventXMovement = false

    const handleDrag = (e, d) => {
      setHasDragged(true);

      const canvasZoomEdgeMargin = 80 * scalar;
      const adjustedPanObjectWidth = panObjectWidth * 3 - canvasZoomEdgeMargin - canvasZoomEdgeMargin;
      const adjustedPanObjectHeight = panObjectHeight * 3 - canvasZoomEdgeMargin - canvasZoomEdgeMargin;

      const newX = Math.max(adjustedPanObjectWidth * -1, Math.min(d.x, panObjectWidth));
      const newY = Math.max(adjustedPanObjectHeight * -1, Math.min(d.y, panObjectHeight));

      setPosition({ x: newX, y: newY });

      let { newOriginX, newOriginY } = calculateOrigin(scale, canvasWidth, canvasHeight, newX, newY);

      const SNAP_THRESHOLD = 10 

      // Check if within snap threshold and update snapline visibility
      if (Math.abs(newOriginX) < SNAP_THRESHOLD) {
        newOriginX = 0;
        setShowXSnapline(true);
      } else {
        setShowXSnapline(false);
      }

      if (Math.abs(newOriginY) < SNAP_THRESHOLD) {
        newOriginY = 0;
        setShowYSnapline(true);
      } else {
        setShowYSnapline(false);
      }

      

      const updatedZoomValues = {
        ...zoomValues,
        originX: newOriginX,
        originY: newOriginY,     
      };     
      updateZoomValues(clipId, updatedZoomValues);

      const updatedZoomBox = convertZoomValuesToZoomBox(updatedZoomValues);
      updateZoomBox(clipId, updatedZoomBox);    
    };

    const handleDragStart = (event) => {
       event.preventDefault();
       setHasDragged(true);
    };

    const handleWheel = (event) => {        
      setHasScrolled(true);
      const scaleChange = event.deltaY * -0.001;
      const newScale = Math.min(Math.max(zoomValues.scale + scaleChange, ZOOM_MIN), ZOOM_MAX);
      
      const updatedZoomValues = {
          ...zoomValues,
          scale: newScale
      };
      
      updateZoomValues(clipId, updatedZoomValues);        

      const updatedZoomBox = convertZoomValuesToZoomBox(updatedZoomValues);
      updateZoomBox(clipId, updatedZoomBox);     
    };

    const showScrollTip = hasDragged && !hasScrolled;


    return (
        <div className='editor-canvasZoomUI' onWheel={handleWheel} style={{ width: canvasWidth, height: canvasHeight, overflow: 'hidden' }}>

          

          {showYSnapline && isDragging &&
            <div style={{height: `1 * ${1 / scalar}`}} className='editor-canvasZoomUI-snaplineMiddle' />
          }
          
          {showXSnapline && isDragging &&
            <div style={{width: `1 * ${1 / scalar}`}} className='editor-canvasZoomUI-snaplineCenter' />
          }

          <Rnd
            size={{ width: panObjectWidth * 3, height: panObjectHeight * 3 }}
            position={position}
            onDragStart={()=> setIsDragging(true)}
            onDrag={handleDrag}
            onDragStop={()=> setIsDragging(false)}
            enableResizing={false}   
            className='editor-canvasZoomUI-rnd'
            dragAxis={preventXMovement ? 'y' : 'both'}            
          >
            <div className='editor-canvasZoomUI-draggable' style={{ width: '100%', height: '100%', display: 'flex', flexWrap: 'wrap' }} />              
            
          </Rnd>

          
          <DragTipOverlay isVisible={showDragTip} />
          <ScrollTipOverlay isVisible={showScrollTip} />

        </div>
    );
};

export default EditorCanvasZoomUI;