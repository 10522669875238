import axios from 'axios';


const imageCache = {}; // Simple in-memory cache


export function fetchProxyImage(url) {

	if (imageCache[url]) {
    console.log('Returning cached image...');
    return Promise.resolve(imageCache[url]);
  }


 // console.log('Fetching via proxy...');
  return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/proxy-image`, {
    params: { url },
    responseType: 'arraybuffer' // Ensure Axios treats the response as binary data
  })
    .then(response => {
    //  console.log('Response received from proxy...', response);
      
      // Convert ArrayBuffer to Blob
      const contentType = response.headers['content-type']; // Get the content type from the response
      const blob = new Blob([response.data], { type: contentType });
      const blobUrl = URL.createObjectURL(blob);
      // Cache the Blob URL
      imageCache[url] = blobUrl;
      return blobUrl;
    })
    .catch(error => {

      //console.error('Error fetching proxy image:', error);
      throw error;
    });
}
