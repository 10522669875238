import React from 'react';
import * as ContextMenu from '@radix-ui/react-context-menu'
import Icon from '../../../../misc/Icon'
//while dragging in dndMode we show the proposedClip location which is where it will be when dropped




const EditorTimelineClipContextMenuContents = (props) => {
 
  const {clip, canChangePlaybackRate} = props

  const playbackRates = [0.5, 0.8, 0.9, 1, 1.1, 1.2, 1.3, 1.4, 1.5, 2,3.5, 5, 10];   // Array of playback rates
  const activePlaybackRate = clip.clipPlaybackRate || 1
  

  return (
    <>                        
      <ContextMenu.Content collisionPadding={10} className='contextMenu contextMenu--timelineClip forceDarkTheme'>                                
        
        {/*}
        <ContextMenu.Item onSelect={()=>{props.cutClip(clip)}}>
          Cut
        </ContextMenu.Item>          
        <ContextMenu.Item onSelect={()=>{props.copyClip(clip)}}>
          Copy
        </ContextMenu.Item>
        */}

        {clip.type=='video' && 
          <>
            <ContextMenu.Item onSelect={()=>{props.splitRecordingClip()}} >
              <div className='dropdownMenu-item-iconContainer'>
                <Icon name='splitClipAlt2' />
              </div>
              Split Clip
              <div className='contextMenu-item-keyboardShortcut'>
                ⌘⇧S
              </div>
            </ContextMenu.Item>

            <ContextMenu.Separator />

            <ContextMenu.Item onSelect={()=>{props.addFreezeFrame(clip.id)}} >
              <div className='dropdownMenu-item-iconContainer'>
                <Icon name='playbackPause' />
              </div>
              Add Freeze Frame
            </ContextMenu.Item>
        
            <ContextMenu.Item onSelect={()=>{props.addSkipSegment(clip.id)}} >
              <div className='dropdownMenu-item-iconContainer'>
                <Icon name='skipSegment' />
              </div>
              Add Skip Segment
            </ContextMenu.Item>
          </>
        }      

        

        {canChangePlaybackRate && 
          <ContextMenu.Sub>
            <ContextMenu.SubTrigger>
              Playback Rate          
              <div className='contextMenu-item-subMenuArrow'>
                <Icon name='chevronRight' />
              </div>                      
            </ContextMenu.SubTrigger>
            <ContextMenu.Portal>
              <ContextMenu.SubContent
                collisionPadding={20} className='contextMenu contextMenu-subMenu contextMenu-subMenu--playbackRate forceDarkTheme'
              >                  
                {playbackRates.map(rate => (
                  <ContextMenu.Item key={rate} className={'contextMenu-subMenu-item contextMenu-subMenu-item--playbackRate ' + (rate === activePlaybackRate ? ' contextMenu-subMenu-item--playbackRate--active ' : ' contextMenu-subMenu-item--playbackRate--inactive ')} onSelect={() => props.changeVideoClipPlaybackRate(clip.id, rate)}>
                    {rate.toFixed(1)} {rate === activePlaybackRate && <div className='contextMenu-subMenu-item--playbackRate-iconContainer'><Icon name='multiplySemibold'/></div>}
                  </ContextMenu.Item>
                ))}                     
              </ContextMenu.SubContent>
            </ContextMenu.Portal>
          </ContextMenu.Sub>
        }           

        

        {clip.type!=='video' && clip.type!=='audio' &&
        <ContextMenu.Item onSelect={()=>{props.duplicateClip(clip)}} >
          <div className='dropdownMenu-item-iconContainer'>
            <Icon name='duplicateMedium' />
          </div>
          Duplicate Clip
        </ContextMenu.Item>
      }

      {clip.type!=='audio' &&
          <ContextMenu.Separator />    
          }      
        <ContextMenu.Item onSelect={()=>{props.deleteClip(clip.id,true)}}>
          <div className='dropdownMenu-item-iconContainer'>
            <Icon name='deleted' />
          </div>
          Delete
        </ContextMenu.Item>   
      </ContextMenu.Content>
  
    </>
  );
};

export default EditorTimelineClipContextMenuContents;