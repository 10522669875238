import {LayoutGroup} from '../LayoutGroup'
import getSlideConfigs from './getSlideConfigs'

const slideConfigs = getSlideConfigs()
const {slideHPadding,slideVPadding,slideWidth,slideHeight,slideAspect}=slideConfigs







//layout type can be hStack,vStack or free

export function alignSlideElements(selectedSlideElements,alignType,alignValue) {

    if (selectedSlideElements.length === 1) {
      const element = selectedSlideElements[0];

      switch (alignType) {
        case 'hAlign':
          switch (alignValue) {
            case 'left':

              element.x = slideHPadding;              
              break;
            case 'center':
              element.x = (slideWidth / 2) - (element.width / 2);              
              break;
            case 'right':
              element.x = slideWidth - slideHPadding - element.width;              
              break;
          }
          break;
        case 'vAlign':
          switch (alignValue) {
            case 'top':
              element.y = slideVPadding;              
              break;
            case 'middle':
              element.y = (slideHeight / 2) - (element.height / 2);              
              break;
            case 'bottom':
              element.y = slideHeight - slideVPadding - element.height;              
              break;
          }
          break;
      }
    } else {
      //multiple elements here 
      if (alignType === 'vAlign') {
            let deltaY;
            switch (alignValue) {
                case 'top':
                    const minY = Math.min(...selectedSlideElements.map(el => el.y));
                    deltaY = slideVPadding - minY;
                    break;
                case 'middle':
                    const groupCenterY = selectedSlideElements.reduce((sum, el) => sum + el.y + el.height / 2, 0) / selectedSlideElements.length;
                    deltaY = (slideHeight / 2) - groupCenterY;
                    break;
                case 'bottom':
                    const maxY = Math.max(...selectedSlideElements.map(el => el.y + el.height));
                    deltaY = slideHeight - slideVPadding - maxY;
                    break;
            }
            selectedSlideElements.forEach(element => {
                element.y += deltaY;                
            });
        } else if (alignType === 'hAlign') {
            let deltaX;
            switch (alignValue) {
                case 'left':
                    const minX = Math.min(...selectedSlideElements.map(el => el.x));
                    deltaX = slideHPadding - minX;
                    break;
                case 'center':
                    const groupCenterX = selectedSlideElements.reduce((sum, el) => sum + el.x + el.width / 2, 0) / selectedSlideElements.length;
                    deltaX = (slideWidth / 2) - groupCenterX;
                    break;
                case 'right':
                    const maxX = Math.max(...selectedSlideElements.map(el => el.x + el.width));
                    deltaX = slideWidth - slideHPadding - maxX;
                    break;
            }
            selectedSlideElements.forEach(element => {
                element.x += deltaX;                
            });
        }
    }


}



