import React, { useState, useRef, useEffect, Suspense } from 'react';
import ThreeCanvas from '../three/ThreeCanvas';
import Camera from '../three/Camera';
import { Canvas } from '@react-three/fiber';
import { computeCursorDataForLegacyClips } from '../utils/recordings/legacyScreenRecordings/getLegacyCursorPositionAtTime'
import { computeCursorDataForClips } from '../utils/recordings/screenRecordings/getCursorPositionAtTime'
import {getBackgroundForId} from '../utils/brands/getBackgroundForId'
import { Muxer, ArrayBufferTarget } from 'mp4-muxer';
import * as THREE from 'three';

const SCENE_WIDTH = 1920
const SCENE_HEIGHT = 1080
const canvasWidth = 1280
const canvasHeight = 720

//const NORMAL_TIMEOUT = 80 
const NORMAL_TIMEOUT = 10 
const encodingBitRate = 20e6; 

let fpsCounterInterval = null;
let fps = 0;

let lastBenchmarkTimestamp = 0;

let timeBenchmark = {
	renderScene: 0,
	crateVideoFrame: 0,
	encodeVideoFrame: 0,
	flushEncoder: 0,
	closeVideoFrame: 0,
	findVideoSegment: 0,
	setTime: 0,
	setCurrentFrame: 0,
	timeout: 0,
}

function recordBenchmarkEvent(eventName)  {
	switch (eventName) {
		case 'reset':
			console.log(`Benchmark:${JSON.stringify(timeBenchmark)}`);
			timeBenchmark = {
				renderScene: 0,
				crateVideoFrame: 0,
				encodeVideoFrame: 0,
				flushEncoder: 0,
				closeVideoFrame: 0,
				findVideoSegment: 0,
				setTime: 0,
				setCurrentFrame: 0,
				timeout: 0,
			}
			break;
		case 'renderScene':
			timeBenchmark.renderScene = performance.now() - lastBenchmarkTimestamp;
			break;
		case 'crateVideoFrame':
			timeBenchmark.crateVideoFrame = performance.now() - lastBenchmarkTimestamp;
			break;
		case 'encodeVideoFrame':
			timeBenchmark.encodeVideoFrame = performance.now() - lastBenchmarkTimestamp;
			break;
		case 'flushEncoder':
			timeBenchmark.flushEncoder = performance.now() - lastBenchmarkTimestamp;
			break;
		case 'closeVideoFrame':
			timeBenchmark.closeVideoFrame = performance.now() - lastBenchmarkTimestamp;
			break;
		case 'findVideoSegment':
			timeBenchmark.findVideoSegment = performance.now() - lastBenchmarkTimestamp;
			break;
		case 'setTime':
			timeBenchmark.setTime = performance.now() - lastBenchmarkTimestamp;
			break;
		case 'setCurrentFrame':
			timeBenchmark.setCurrentFrame = performance.now() - lastBenchmarkTimestamp;
			break;
		case 'timeout':
			timeBenchmark.timeout = performance.now() - lastBenchmarkTimestamp;
			break;
		default:
			console.error('Unknown benchmark event:', eventName);
			return;
	}
	lastBenchmarkTimestamp = performance.now();
}

function countFrame() {
	if (!fpsCounterInterval) {
		fpsCounterInterval = setInterval(() => {
			console.log("frame capture FPS:", fps);
			fps = 0;
		}, 1000);
	}
	fps++;
}

function finishCountFrame() {
	clearInterval(fpsCounterInterval);
	fpsCounterInterval = null;
	console.log("FPS:", fps);
	fps = 0;
}

const ServerSideExportCanvas = ({
		setIsExporting,
		setExportComplete,
		projectBackgroundId,
		slideClips,
		textSlideClips,
		webcamClips,
		chartClips,
		videoClips,
		zoomClips,
		currentTime,
		isPlaying,
		setTime,
		duration,
		projectId,
		renderId,
		foxStartTime,
		foxMaxRenderSeconds,
		project,
		imageClips,
		setUploadComplete,
		segmentId,
		videoRenderUploadUrl,
		s3PresignedUploadUrl,
		renderVariables,
		audioClips,
		framesPerSecond,
		exportWidth,
		subtitlesType
	}) => {
	const FPS = framesPerSecond ||60
	const projectBackground=getBackgroundForId(projectBackgroundId)
	const [videoFilePath, setVideoFilePath] = useState(null);
	const cameraRef = useRef();
	const [readyVideoClips, setReadyVideoClips] = useState([]);
	const canvasRef = useRef()
	const [exportStartTime, setExportStartTime] = useState(null);
	const [currentFrame, setCurrentFrame] = useState(0);
	const [totalFrames, setTotalFrames] = useState(0);

	async function captureFrame(renderer,scene,camera, encoder, frameIndex) {
		const canvas = renderer.domElement;
		renderer.render(scene, camera);
		recordBenchmarkEvent('renderScene');
		const videoFrame = new VideoFrame(canvas, {timestamp: frameIndex * (1 / FPS) * 1e6}); //multiple by 1e6 (to get microseconds) not encoding bitrate
		recordBenchmarkEvent('crateVideoFrame');
		encoder.encode(videoFrame, {keyFrame: frameIndex % (2 * FPS) === 0}); // Keyframe every 2 seconds
		recordBenchmarkEvent('encodeVideoFrame');
		recordBenchmarkEvent('flushEncoder');
		countFrame();
		videoFrame.close();
		recordBenchmarkEvent('closeVideoFrame');
	}

	
	async function finalizeVideo(muxer, encoder, is4kRes) {
		const flushStart = performance.now();
		console.log('queueSize before flush', encoder.encodeQueueSize);
		await encoder.flush(); // wait for queue to empty
		console.log('queueSize after flush', encoder.encodeQueueSize);
		muxer.finalize(); // close muxer
		const blob = new Blob([muxer.target.buffer], { type: 'video/mp4' }); // create a blob from the buffer
		console.log('Video finalized in: ', performance.now() - flushStart, 'ms');
		setIsExporting(false);
		setExportComplete(true);
		console.log(`s3PresignedUploadUrl--------- ${s3PresignedUploadUrl}`);
		if (!s3PresignedUploadUrl) {
			console.log('UPLOAD FAILED', 'no s3PresignedUploadUrl');
			return;
		}

		const MAX_RETRIES = 3;

		async function uploadToS3WithRetry(attempt = 1) {
			try {
				const response = await fetch(s3PresignedUploadUrl, {
					method: 'PUT',
					body: blob,
					headers: {
						'Content-Type': 'video/mp4'
					}
				});

			if (response.ok) {
				const message = await response.text();
				console.log('UPLOAD SUCCESS', message);
				return true;
			} else {
				const message = await response.text();
				console.log(`UPLOAD FAILED (Attempt ${attempt}/${MAX_RETRIES})`, message);
				if (attempt < MAX_RETRIES) {
					console.log(`Retrying upload...`);
					return uploadToS3WithRetry(attempt + 1);
				}
			return false;
		}
	} catch (error) {
		console.error(`UPLOAD ERROR (Attempt ${attempt}/${MAX_RETRIES})`, error);
		if (attempt < MAX_RETRIES) {
			console.log(`Retrying upload...`);
			return uploadToS3WithRetry(attempt + 1);
		}
		return false;
		}
	}

		const uploadSuccess = await uploadToS3WithRetry();
		if (!uploadSuccess) {
			console.log('Upload failed after all retry attempts');
		}
		setUploadComplete(true);
	}

	const is4kRes = false
	const is2kRes = true
	const MAX_ENCODE_QUEUE_SIZE = 15;

	const handleExport = async () => {
		setIsExporting(true)
		if (cameraRef.current) {
			const camera = cameraRef.current.getCamera();
			const renderer = cameraRef.current.getGL();
			const scene = cameraRef.current.getScene();
			renderer.setPixelRatio(1) //ultrawide thing
			
			let targetWidth = 2880
			let targetHeight = 1620 

			if(exportWidth==1920){
				targetWidth = 1920 
				targetHeight = 1080 
			}

			if(exportWidth==3840){
				targetWidth = 3840 
				targetHeight = 2160 
			}
			// Adjust renderer and camera for the target resolution and aspect ratio
			renderer.setSize(targetWidth, targetHeight);
			camera.aspect = targetWidth / targetHeight;
			camera.updateProjectionMatrix();
			//////////////////////////////////////////
			const renderSeconds = foxMaxRenderSeconds || duration - foxStartTime;
			console.log(`render seconds is ${foxMaxRenderSeconds}`)
			const startFrame = foxStartTime * FPS || 0;
			const renderFrames = (renderSeconds * FPS) + startFrame;
			console.log('renderSeconds', renderSeconds)
			console.log('startFrame', startFrame)
			console.log('renderFrames', renderFrames)
			console.log('foxMaxRenderSeconds', foxMaxRenderSeconds)
			console.log('foxStartTime', foxStartTime)
			setExportStartTime(Date.now());
			setTotalFrames(renderFrames);


			const videoSegments = [...videoClips, ...webcamClips].map(clip => ({
				startTime: clip.startTime,
				endTime: clip.endTime,
				isBasicVideo: clip.isBasicVideo,
				semiTransparent: clip.metadata?.semiTransparent || false,
				hasVideoTrack:clip.hasVideoTrack,
				clip: clip
			}));

			let textureUpdateResolver = null;
			let textureUpdatePromise = new Promise(resolve => {
				textureUpdateResolver = resolve;
			});

			const globalTextureUpdateCallback = () => {
				if (textureUpdateResolver) {
					textureUpdateResolver();
					textureUpdateResolver = null;
				}
      };

      [...videoClips, ...webcamClips].forEach(clip => {

					clip.setTextureUpdatedCallback(globalTextureUpdateCallback);

			});

			const muxerOptions = {
				target: new ArrayBufferTarget(),
				video: {
					codec: 'avc', // must be the same codec as used in the encoder
					width: targetWidth, // same width as used in the encoder
					height: targetHeight, // same height as used in the encoder
				},
				fastStart: 'in-memory', // we don't mind the medatada to be stored at the end of the file
				firstTimestampBehavior: 'offset', // reset the timestamp to 0 on first frame
			}

			const  muxer = new Muxer(muxerOptions)
			const encoder = new VideoEncoder({
				output(chunk, meta) {
					muxer.addVideoChunk(chunk, meta);
				},
				error(e) {
				console.error(e);
				}
			});

			encoder.configure({
				codec: 'avc1.640033',
				width: targetWidth,
				height: targetHeight,
				bitrate: encodingBitRate,
				framerate: FPS,
			});

			let previousSegment
			
			for (let i = startFrame; i < renderFrames; i++) {
				const frameTime = i / FPS;
				setCurrentFrame(i);
				const currentSegment = videoSegments.find(segment => frameTime >= segment.startTime && frameTime < segment.endTime);
				if (currentSegment && currentSegment.hasVideoTrack) {
					// Create both promises - one for texture update and one for normal timeout
					textureUpdatePromise = new Promise(resolve => {
						textureUpdateResolver = resolve;
					});
					//New Dec 2nd make there a minimum timeout for video segments equal to normal_timeout
					const timeoutPromise = new Promise(resolve => setTimeout(resolve, NORMAL_TIMEOUT));
					// Wait for both promises to complete
					setTime(frameTime);
					await Promise.all([textureUpdatePromise, timeoutPromise]);
				} else {
					setTime(frameTime);
					await new Promise(resolve => setTimeout(resolve, NORMAL_TIMEOUT));
				}
				captureFrame(renderer, scene, camera, encoder, i, renderFrames);
				while (encoder.encodeQueueSize >= MAX_ENCODE_QUEUE_SIZE) {
				await new Promise(resolve => setTimeout(resolve, 1));
				}
			}
			finishCountFrame();
			await finalizeVideo(muxer, encoder,is4kRes);
		}			
		else {
			console.error('Canvas objects are not available for export.');
		}
	}

			

// useEffect(() => { //TODO check this cursor data is ready before export and also clean up after were done
// 		const computeData = async () => {
// 			const filteredClips = videoClips.filter(clip => clip.isBasicVideo !== true && clip.isDeviceRecording!==true && clip.isWebcam!=true);
// 			const calculatedClipsIds = await computeCursorDataForClips(filteredClips);
// 		};
// 		computeData();
// 	}, [videoClips]);


useEffect(() => {
		const computeData = async () => {
			const legacyClips = videoClips.filter(clip => clip.isBasicVideo !== true && clip.isDeviceRecording!==true && clip.type!=='webcam' && clip.isScreenRecording!=true);
			const screenClips = videoClips.filter(clip => clip.isBasicVideo !== true && clip.isDeviceRecording!==true && clip.type!=='webcam' && clip.isScreenRecording==true)
			 try {
        const [legacyClipsData, screenClipsData] = await Promise.all([
          computeCursorDataForLegacyClips(legacyClips),
          computeCursorDataForClips(screenClips)
        ]);
        const allCalculatedClipsIds = [
          ...legacyClipsData,
          ...screenClipsData
        ];
        // setReadyVideoClips(allCalculatedClipsIds);
      } catch (error) {
        console.error('Error computing cursor data:', error);
      }
		};
		computeData();
	}, [videoClips]);



	return (
		<>

			<div style={{ width: `${canvasWidth}px`, height: `${canvasHeight}px` }} className='editor-center-center-canvasOuterOuterContainer-guide'>
				<div style={{ transform: `scale(${canvasWidth / SCENE_WIDTH})` }} className='editor-center-center-canvasContainer'>
					<div style={{ width: `${SCENE_WIDTH}px`, height: `${SCENE_HEIGHT}px` }} className='editor-center-center-canvasInnerContainer' >
						<Suspense fallback={<div>Loading...</div>}>
							<Canvas 
								ref={canvasRef}
								style={{ width: `calc(${SCENE_WIDTH}px * 1/${canvasWidth / SCENE_WIDTH})`, height: `calc(${SCENE_HEIGHT}px * 1/${canvasWidth / SCENE_WIDTH})` }}
								gl={{ 
									preserveDrawingBuffer: true, 
									toneMapping: THREE.NoToneMapping,
								outputColorSpace: THREE.SRGBColorSpace,
									antialias: true,									 
								}}>
								<ThreeCanvas
									currentTime={currentTime}
									slideClips={slideClips}
									textSlideClips={textSlideClips}
									videoClips={videoClips}
									webcamClips={webcamClips}
									imageClips={imageClips}
									zoomClips={zoomClips}
									chartClips={chartClips}
									audioClips={audioClips}
									textEditorIsFocused={false}
									projectBackground={projectBackground}
									readyVideoClips={readyVideoClips}
									hideRenderedTextSlide={false}
									showChartAnimated={true}
									showChartStatic={false}
									showBasicVideoStatic={false}
									projectBackground={projectBackground}
									variableValues={renderVariables}
									showSlideChartAnimated={true}
									showSlideChartStatic={false}
									subtitlesType={subtitlesType}

								/>
								<Camera ref={cameraRef} currentTime={currentTime} zoomClips={zoomClips} readyVideoClips={readyVideoClips} />
							</Canvas>
						</Suspense>
					</div>
				</div>
			</div>
					<button id="exportButton" onClick={handleExport}> EXPORT </button>
		<br/>

		</>
	);
};

export default ServerSideExportCanvas;


