import ReactDOM from 'react-dom'
import React from 'react'
import TextSlideInputTextArea from '../../three/textSlide/TextSlideInputTextArea'




// let wordsArray=[
//   {
//     "word": "Headline",
//     "rect": {            
//       "normalLeft": -192.44748123419743,            
//       "normalTop": 55.034736291087235,            
//       "normalBottom": -54.79686018489258,
//       "width": 155.0972900390625,
//       "height": 45.523712158203125
//     },
//     "paragraphIndex": 0
//   }
// ]




function calculateWordsArrayForTextSlide(clip) {
  const {listType,textAlign,docJson,fontFamily,fontWeight,fontSize, letterSpacing} = clip.metadata

 //console.log('calculate words array for text slide here_____________>>>>>>>>')



 return new Promise((resolve, reject) =>
  {
    const el=document.createElement("DIV")
    el.setAttribute("id", clip.id)
    el.className = 'calculateWordsArrayContainer'; // Setting the class name
    document.body.appendChild(el)

    function handleSetWordsArray(wordsArray){
    if (document.body.contains(el)) {
      document.body.removeChild(el);
    }
      resolve(wordsArray);
    } 

  ReactDOM.render(
    <TextSlideInputTextArea
      key={clip.id}
      clipId={clip.id}
      slide={clip}
      slideId={clip.id}
      fontFamily={fontFamily}
      fontWeight={fontWeight}
      fontSize={fontSize}      
      textAlign={textAlign}
      listType={listType}
      letterSpacing={letterSpacing}
      canvasX={0}
      canvasY={0}
      handleSetWordsArray={handleSetWordsArray}
      invertScalar={1}
      initialValue={docJson}      
      isGhostCalc={true}
     />, 
    el)
  })

}


//  return wordsArray
 
//}

export { calculateWordsArrayForTextSlide }


// export function calculateMeasurements(question,fontSizeDiv){
//  return new Promise((resolve, reject) =>
//  {
//   const el=document.createElement("DIV")
//   el.setAttribute("id", question.questionId)
//   document.body.appendChild(el)
//   let measurements={
//     'bodyFS':0,
//     'choiceFS':0
//   }

//   function updateQuestionFontSizes(perfectCombo,question){  
//     const el= document.getElementById(question.questionId)
//     if(el){
//       measurements.bodyFS=perfectCombo.bodyFontSize
//       measurements.choiceFS=perfectCombo.choiceFontSize
//       let modifiedQuestion={...question}
//       modifiedQuestion.measurements=measurements  
//       document.body.removeChild(el)
//       resolve(modifiedQuestion)
//     }
//   }

//   ReactDOM.render(
//     <FontSizeCalculatorComponent
//       question={question}
//       updatePerfectCombo={(perfectCombo)=>{updateQuestionFontSizes(perfectCombo,question)}}
//       updateQuestionMediaDynamicHeight={()=>{return null}}
//       mediaRatio={'MM'}
//      />, 
//       el)
//  })

// }