import {getVideoFreezePeriods} from '../../../utils/recordingUtils/getVideoFreezePeriods'



export const identifyStillVideoPeriods = async (captureId,noiseLevel) => {
  try {
    const isScreenRecording = true
    const isDeviceRecording = false
    const freezePeriods = await getVideoFreezePeriods(captureId,isDeviceRecording,noiseLevel,isScreenRecording)
    if (!freezePeriods) {
      throw new Error('Failed to fetch freezePeriods');
    }
    return freezePeriods
  } catch (error) {
    console.error('Failed to identify silent video periods:', error);
    return []; // Return an empty array in case of error
  }
};
