import React, { useState, useEffect } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const EditorStatusBarExportBtnInProgress = ({ exportStatus, setExportStatus, estimatedTime = 10 }) => {
  const [timeLeft, setTimeLeft] = useState(estimatedTime);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let progressInterval;
    let timerInterval;

    if (exportStatus === 'inprogress') {
      const startTime = Date.now();
      const endTime = startTime + estimatedTime * 1000;

      progressInterval = setInterval(() => {
        const now = Date.now();
        const progressPercent = Math.min(((now - startTime) / (endTime - startTime)) * 100, 100);
        setProgress(progressPercent);

        if (now >= endTime) {
          clearInterval(progressInterval);
          setExportStatus('downloading');
        }
      }, 16); // Update roughly 60 times per second for smooth animation

      timerInterval = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timerInterval);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    } else {
      setProgress(0);
      setTimeLeft(estimatedTime);
    }

    return () => {
      clearInterval(progressInterval);
      clearInterval(timerInterval);
    };
  }, [exportStatus, setExportStatus, estimatedTime]);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <button 
      onClick={() => setExportStatus('downloading')}
      data-export-status='inprogress'
      className='editor-statusBar-exportLinkBtn'
    >                  
      <div className='editor-statusBar-exportLinkBtn-progressBarContainer'>
        <div className='editor-statusBar-exportLinkBtn-progressBarInnerContainer'>
          <CircularProgressbar 
            value={progress}
            strokeWidth={14}             
          />
        </div>
      </div>
      <div className='editor-statusBar-exportLinkBtn-label'>
        {formatTime(timeLeft)}
      </div>
    </button>                          
  );
};

export default EditorStatusBarExportBtnInProgress;