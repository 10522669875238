import React from 'react';

const getMobileDeviceModelImageSrc = (mobileDeviceModel) => {
  let mobileDeviceModelImageSrc = null

  if(mobileDeviceModel === 'iphone14plus'){
    mobileDeviceModelImageSrc = '/iPhones/iPhone14Plus.png'
    //mobileDeviceModelImageSrc = '/iPhones/iPhone14PlusSemi.png'
  }
  if(mobileDeviceModel === 'iphone14'){
    mobileDeviceModelImageSrc = '/iPhones/iPhone14.png'
    //mobileDeviceModelImageSrc = '/iPhones/iPhone14Semi.png'
  }
  if(mobileDeviceModel === 'iphone15pro'){
    mobileDeviceModelImageSrc = '/iPhones/iPhone15Pro.png'    
    //mobileDeviceModelImageSrc = '/iPhones/iPhone15ProSemi.png'    
  }
  if(mobileDeviceModel === 'iphone15promax'){
    mobileDeviceModelImageSrc = '/iPhones/iPhone15ProMax.png'
    //mobileDeviceModelImageSrc = '/iPhones/iPhone15ProMaxSemi.png'    
  }
  if(mobileDeviceModel === 'ipadminilandscape'){
    mobileDeviceModelImageSrc = '/iPads/iPadMiniLandscape.png'    
    //mobileDeviceModelImageSrc = '/iPads/iPadMiniLandscapeSemi.png'    
  }
  if(mobileDeviceModel === 'ipadminiportrait'){
    mobileDeviceModelImageSrc = '/iPads/iPadMiniPortrait.png'    
    //mobileDeviceModelImageSrc = '/iPads/iPadMiniPortraitSemi.png'    
  }
  if(mobileDeviceModel === 'ipadpro11landscape'){
    mobileDeviceModelImageSrc = '/iPads/iPadPro11Landscape.png'    
    //mobileDeviceModelImageSrc = '/iPads/iPadPro11LandscapeSemi.png'    
  }
  if(mobileDeviceModel === 'ipadpro11portrait'){
    mobileDeviceModelImageSrc = '/iPads/iPadPro11Portrait.png'    
    //mobileDeviceModelImageSrc = '/iPads/iPadPro11PortraitSemi.png'    
  }
  if(mobileDeviceModel === 'ipadpro129landscape'){
    mobileDeviceModelImageSrc = '/iPads/iPadPro129Landscape.png'    
    //mobileDeviceModelImageSrc = '/iPads/iPadPro129LandscapeSemi.png'    
  }
  if(mobileDeviceModel === 'ipadpro129portrait'){
    mobileDeviceModelImageSrc = '/iPads/iPadPro129Portrait.png'    
    //mobileDeviceModelImageSrc = '/iPads/iPadPro129PortraitSemi.png'    
  }
  if(mobileDeviceModel === 'ipad9landscape'){
    mobileDeviceModelImageSrc = '/iPads/iPad9Landscape.png'    
    //mobileDeviceModelImageSrc = '/iPads/iPad9LandscapeSemi.png'    
  }
  if(mobileDeviceModel === 'ipad9portrait'){
    mobileDeviceModelImageSrc = '/iPads/iPad9Portrait.png'    
    //mobileDeviceModelImageSrc = '/iPads/iPad9PortraitSemi.png'    
  }
  if(mobileDeviceModel === 'ipad10landscape'){
    mobileDeviceModelImageSrc = '/iPads/iPad10Landscape.png'    
    //mobileDeviceModelImageSrc = '/iPads/iPad10LandscapeSemi.png'    
  }
  if(mobileDeviceModel === 'ipad10portrait'){
    mobileDeviceModelImageSrc = '/iPads/iPad10Portrait.png'    
    //mobileDeviceModelImageSrc = '/iPads/iPad10PortraitSemi.png'    
  }

  return mobileDeviceModelImageSrc
};

export default getMobileDeviceModelImageSrc;
