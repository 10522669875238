import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import { getLegacyMasterRecordingData,fetchLegacyVideoURL } from '../../../utils/recordings/legacyScreenRecordings/getLegacyMasterRecordingData';
import {fetchDeviceVideoUrl} from '../../../utils/recordings/deviceRecordings/fetchDeviceVideoUrl';
import {getDeviceDimensionsForCaptureId} from '../../../utils/recordings/deviceRecordings/getDeviceDimensionsForCaptureId'
import {getMasterRecordingData} from '../../../utils/recordings/screenRecordings/getMasterRecordingData'
import {fetchBasicVideo} from '../../../utils/basicVideos/fetchBasicVideo'
import {getBasicVideoObjectForId} from '../../../utils/basicVideos/getBasicVideoObjectForId'
import {fetchWebcamVideo} from '../../../utils/webcam/fetchWebcamVideo'




const SCENE_WIDTH = 1920;
const SCENE_HEIGHT = 1080;
const SCENE_ASPECT = 16 / 9;

const EditTrimVideoPlayer = ({ clip,canvasWidth, canvasHeight, captureId, trimPreviewPlaying, trimPreviewProgress, onTrimPreviewPlayPause, onTrimPreviewSeekChange }) => {
  const [videoUrl, setVideoUrl] = useState('');
  const [recordingWidth, setRecordingWidth] = useState(0);
  const [recordingHeight, setRecordingHeight] = useState(0);
  const [recordingVideoDuration, setRecordingVideoDuration] = useState(0);
  const [fitVideoWidth, setFitVideoWidth] = useState(0);
  const [fitVideoHeight, setFitVideoHeight] = useState(0);

  const playerRef = useRef(null);

  useEffect(() => {
    const initialize = async () => {
      try {
         if(clip.isScreenRecording){
          const data = await getMasterRecordingData(captureId);
          const videoUrl = data.videoURL
          setVideoUrl(videoUrl);
          setRecordingWidth(data.recordingWidth);
          setRecordingHeight(data.recordingHeight);
          setRecordingVideoDuration(data.recordingVideoDuration);
          const recordingAspect = data.recordingWidth / data.recordingHeight;
          if (recordingAspect > SCENE_ASPECT) {
            setFitVideoWidth(SCENE_WIDTH);
            setFitVideoHeight(SCENE_WIDTH / recordingAspect);
          } else {
            setFitVideoHeight(SCENE_HEIGHT);
            setFitVideoWidth(SCENE_HEIGHT * recordingAspect);
          }
        }
        else if(clip.type=='webcam'){
          const videoUrl = await fetchWebcamVideo(clip.captureId)
          setVideoUrl(videoUrl)
          const recordingWidth = clip.metadata.originalWidth
          const recordingHeight=clip.metadata.originalHeight
          setRecordingWidth(recordingWidth);
          setRecordingHeight(recordingHeight);
          setRecordingVideoDuration(clip.recordingDuration);
          const recordingAspect = recordingWidth / recordingHeight;
          if (recordingAspect > SCENE_ASPECT) {
            // Width is the limiting factor
            setFitVideoWidth(SCENE_WIDTH);
            setFitVideoHeight(SCENE_WIDTH / recordingAspect);
          } else {
            // Height is the limiting factor

            setFitVideoHeight(SCENE_HEIGHT);
            setFitVideoWidth(SCENE_HEIGHT * recordingAspect);
          }
        }

        else if(clip.isBasicVideo){
          const videoUrl = await fetchBasicVideo(`${clip.videoId}.mp4`)
          setVideoUrl(videoUrl)
          const videoObj = getBasicVideoObjectForId(clip.videoId)
          const recordingWidth = videoObj.original_width
          const recordingHeight=videoObj.original_height
          setRecordingWidth(recordingWidth);
          setRecordingHeight(recordingHeight);
          setRecordingVideoDuration(videoObj.duration);
          const recordingAspect = recordingWidth / recordingHeight;
          if (recordingAspect > SCENE_ASPECT) {
            setFitVideoWidth(SCENE_WIDTH);
            setFitVideoHeight(SCENE_WIDTH / recordingAspect);
          } else {
            setFitVideoHeight(SCENE_HEIGHT);
            setFitVideoWidth(SCENE_HEIGHT * recordingAspect);
          }
        }else{
          const isDeviceRecording = clip.isDeviceRecording
          if(isDeviceRecording){
            const videoUrl = await fetchDeviceVideoUrl(captureId)
            setVideoUrl(videoUrl);
            const dimensions = await getDeviceDimensionsForCaptureId(captureId)
            setRecordingWidth(dimensions.width);
            setRecordingHeight(dimensions.height);
            const duration = await getDurationForCaptureId(captureId,isDeviceRecording)
            setRecordingVideoDuration(duration);

            const recordingAspect = dimensions.width / dimensions.height;
            if (recordingAspect > SCENE_ASPECT) {
              setFitVideoWidth(SCENE_WIDTH);
              setFitVideoHeight(SCENE_WIDTH / recordingAspect);
            } else {
              setFitVideoHeight(SCENE_HEIGHT);
              setFitVideoWidth(SCENE_HEIGHT * recordingAspect);
            }
          }else{
           // console.log('get master data in trim')
            const data = await getMasterRecordingData(null, captureId,isDeviceRecording);
            setVideoUrl(data.videoURL);
            setRecordingWidth(data.recordingWidth);
            setRecordingHeight(data.recordingHeight);
            setRecordingVideoDuration(data.recordingVideoDuration);
            const recordingAspect = data.recordingWidth / data.recordingHeight;
            if (recordingAspect > SCENE_ASPECT) {
              // Width is the limiting factor
              setFitVideoWidth(SCENE_WIDTH);
              setFitVideoHeight(SCENE_WIDTH / recordingAspect);
            } else {
              // Height is the limiting factor
              setFitVideoHeight(SCENE_HEIGHT);
              setFitVideoWidth(SCENE_HEIGHT * recordingAspect);
            }
          }
        }
        
      } catch (error) {
        console.error('Error initializing video player:', error);
      }
    };

    initialize();
  }, [captureId]);

   useEffect(() => {
    // Seek video when user changes the slider position and video is not playing
    if (playerRef.current && recordingVideoDuration > 0 && !trimPreviewPlaying) {
      const seekTime = trimPreviewProgress * recordingVideoDuration;
      if (isFinite(seekTime) && !isNaN(seekTime)) {
        playerRef.current.seekTo(seekTime, 'seconds');
      }
    }
  }, [trimPreviewProgress, recordingVideoDuration, trimPreviewPlaying]);


  const onProgress = progress => {
    if (trimPreviewPlaying) { // Only update when playing
      let played=progress.played || progress.playedSeconds/recordingVideoDuration
      onTrimPreviewSeekChange(played);
    }
  };


//	console.log(`recordingVideoDuration: ${recordingVideoDuration}`)
  const videoPlayerStyles = {
    width: `${fitVideoWidth}px`,
    height: `${fitVideoHeight}px`,    
  };




  return (
    <>
      <div style={{ width: `${SCENE_WIDTH}px`, height: `${SCENE_HEIGHT}px` }} className='editor-canvas-trimVideoPlayerOuterContainer'>
        <div style={videoPlayerStyles} className='editor-canvas-trimVideoPlayerInnerContainer'>
          <ReactPlayer
            ref={playerRef}
            url={videoUrl}
            config={{ file: { attributes: { preload: 'auto' } } }}
            playing={trimPreviewPlaying}
            width='100%'
            height='100%'
            onProgress={onProgress}
            progressInterval={50}
          />
        </div>
      </div>

      
    </>
  );
};

export default EditTrimVideoPlayer;