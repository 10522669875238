export function findParentNode(predicate, selection) {
    const { $from } = selection;
    for (let depth = $from.depth; depth > 0; depth--) {
        const node = $from.node(depth);
        if (predicate(node)) {
            return { node, pos: $from.before(depth), depth };
        }
    }
    return null;
}
