import React, { useState, useEffect } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { useSpring } from 'react-spring';
import 'react-circular-progressbar/dist/styles.css'; // Import styles
import { ToggleGroup, ToggleGroupBtn } from '../uiKit/ToggleUI';
import * as Tooltip from '@radix-ui/react-tooltip'

const ExportProdUI = ({ percentageComplete, exportState, handleExport, estimatedTimeRemaining, handleOpenVideo,isReadyToExport }) => {
  // State for the progress bar (original scale)
  const [progressBarValue, setProgressBarValue] = useState(0);
  // State for the percentage display (rounded to 0 decimal points)
  const [percentageDisplay, setPercentageDisplay] = useState(0);

  // Use react-spring to animate changes in percentageComplete (scaled by 100)
  useSpring({
    number: percentageComplete * 100,
    from: { number: 0 },
    config: { tension: 100, friction: 50 },
    onChange: ({ value }) => {
      setProgressBarValue(value.number); // Use the animated value directly for progress bar
      setPercentageDisplay(Math.round(value.number / 100)); // Divide by 100 and round for percentage display
    },
  });

  const [exportFormat, setExportFormat] = useState('4K');

  return (
    <>
      <Tooltip.Provider>
      <div className={'exportPlayer-UIContainer ' + (exportState ? `exportPlayer-UIContainer--state--${exportState}` : '')}>

        {isReadyToExport && exportState === 'pre' && (
          <button 
            className='exportPlayer-exportBtn' 
            onClick={() => {
              if (exportFormat === '4K') {
                handleExport(true);
              } else if (exportFormat === 'HD') {
                handleExport(false);
              }
            }}>
            Start Export
          </button>
        )}

        <div className='exportPlayer-formatToggleContainer'>
          <ToggleGroup fullWidth toggleCount={2}>
            <ToggleGroupBtn
              label='4K'
              active={exportFormat === '4K'}
              handleClick={() =>{setExportFormat('4K')}}
              tooltipLabel='Production quality'                        
              tooltipBelow
            />
            <ToggleGroupBtn
              label='HD'
              active={exportFormat === 'HD'}
              handleClick={() =>{setExportFormat('HD')}}
              tooltipLabel='Up to 2x faster'
              tooltipBelow
            />        
          </ToggleGroup>
        </div>

      
        {/*}
        {isReadyToExport && exportState === 'pre' && (
          <button  onClick={()=>{handleExport(false)}}>
            Start Export (HD)
          </button>
        )}
        <br/>
         {isReadyToExport && exportState === 'pre' && (
          <button onClick={()=>{handleExport(true)}}>
            Start Export (4k)
          </button>
        )}
        */}

        {exportState === 'active' && (
          <div className='exportPlayer-percentage'>
            <div className='exportPlayer-percentage-value'>
              {percentageDisplay}<span>%</span>
            </div>
          </div>
        )}

        {exportState === 'complete' && 
          <button className='exportPlayer-exportBtn' onClick={handleOpenVideo}>
            Go to File
          </button>
        }

        <div className='exportPlayer-UIContainer-progressBarOuterContainer'>
          <div className='exportPlayer-UIContainer-progressBarInnerContainer'>
            <CircularProgressbar 
              value={progressBarValue / 100}              
              strokeWidth={8}              
            />
          </div>
        </div>
      </div>
      </Tooltip.Provider>
    </>
  );
};

export default ExportProdUI;
