import React, { useState,useEffect } from 'react'
import { useDispatch } from 'react-redux';
import Icon from '../../misc/Icon';
import * as ContextMenu from '@radix-ui/react-context-menu';
import * as Popover from '@radix-ui/react-popover'; 
import LibrarySideMenuCollectionTabPopover from './LibrarySideMenuCollectionTabPopover'
import {updateCollection} from '../../../actions/collections'



function LibrarySideMenuCollectionTab({ title, active, iconName, activeIconName, collection, collectionColor, onClick, popoverOpen, setOpenPopoverId}) {
//  const [popoverOpen, setPopoverOpen] = useState(false);
  const [editTitle, setEditTitle] = useState(`${title}`);
  const [editColor, setEditColor] = useState(collectionColor)
  const dispatch = useDispatch()

  const handleDropdownClick = (e) => {
    e.stopPropagation();
    //onClick();
    if(!popoverOpen){
      let newPopoverOpenId = null   
      newPopoverOpenId=collection.id
      setOpenPopoverId(newPopoverOpenId);
    }    
  };

  const handleRightClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // onClick();
    setOpenPopoverId(collection.id);
  };

 useEffect(() => {
    if (!popoverOpen) {
      let newTitle = editTitle 
      if(newTitle =='Untitled Collection '){
        newTitle='Untitled Collection' //without the space.  so we can tell when the title has been edited
      }

      if(newTitle!=collection.title || editColor!=collection.color){
        dispatch(updateCollection(collection.id,newTitle,editColor));
      }
    }
  }, [popoverOpen])


 const setPopoverOpen=(openState)=>{
    if(openState){
        setOpenPopoverId(collection.id);
    }else{
      setOpenPopoverId(null);
    }
  }

  let isUntitled = false
  if(editTitle === 'Untitled Collection'){
    isUntitled = true
  }

  const handlePopoverClick = (e) => {
    e.stopPropagation();
  };




  return (
    
        <div className='libraryPage-sideMenu-tabContainer'>
          <button onClick={onClick} onContextMenu={handleRightClick} data-state={active ? 'active' : 'inactive'} data-popover-state={popoverOpen ? 'open' : 'closed'} className='libraryPage-sideMenu-tab'>
            <div className='libraryPage-sideMenu-tab-visual'>
              {collection && editColor &&
                <div className={'libraryPage-sideMenu-tab-visual-collectionColorContainer ' + (editColor ? `libraryPage-sideMenu-tab-visual-collectionColorContainer--${editColor}` : '')}>
                  <svg width="16" height="16" viewBox="0 0 14 14">
                    <path d="M2.564 0h7.872c.892 0 1.215.093 1.54.267.327.174.583.43.757.756.174.326.267.65.267 1.54v7.873c0 .892-.093 1.215-.267 1.54-.174.327-.43.583-.756.757-.326.174-.65.267-1.54.267H2.563c-.892 0-1.215-.093-1.54-.267a1.817 1.817 0 0 1-.757-.756C.093 11.65 0 11.327 0 10.437V2.563c0-.892.093-1.215.267-1.54.174-.327.43-.583.756-.757C1.35.093 1.673 0 2.563 0Z" />
                  </svg>
                </div>
              }
            </div>
            <div className={'libraryPage-sideMenu-tab-label ' + (isUntitled ? 'libraryPage-sideMenu-tab-label--untitled' : '')}>
              {editTitle}
            </div>
            <div data-state={popoverOpen ? 'open' : 'closed'} onClick={handleDropdownClick} className='libraryPage-sideMenu-tab-dropdownBtn'>
              <Icon name='ellipses' />
            </div>
            <div onClick={handlePopoverClick}>

            <Popover.Root open={popoverOpen} modal={true} onOpenChange={setPopoverOpen}>
              <Popover.Trigger asChild>
                <div className='libraryPage-sideMenu-tab-popoverTrigger' />
              </Popover.Trigger>
              <Popover.Portal>
                <LibrarySideMenuCollectionTabPopover 
                  editTitle={editTitle}
                  setEditTitle={setEditTitle}
                  editColor={editColor}
                  setEditColor={setEditColor}
                  setPopoverOpen={setPopoverOpen} 
                  collection={collection}
                />                
              </Popover.Portal>
            </Popover.Root>
            </div>
          </button>
        </div>
      
  );
}

export default LibrarySideMenuCollectionTab;
