import React, { useState,useEffect } from 'react';
import Icon from '../../../misc/Icon';
import DPToggleGrid from '../DPToggleGrid';
import DPNumberInput from '../DPNumberInput';
import DPCheckbox from '../DPCheckbox';
import DPSelectMenu from '../DPSelectMenu';
import DPColorPicker from '../DPColorPicker';
import DPItemAnimationPanel from '../DPItemAnimationPanel';
import {parseDataIntoBarChart} from '../../../../utils/charts/parseDataIntoBarChart'
import EditorDetailPanelChartSpreadsheet from './EditorDetailPanelChartSpreadsheet'
import { getOrgBrand } from '../../../../utils/brands/getOrgBrand'

const colorTypes = [
  { value: 'uniform', label: 'Uniform color'},    
  { value: 'category', label: 'Category colors'}
];




const EditorDetailPanelChartBar = (props) => {
  const {updateSlideElementMetadata,clipId,element,handleDimensionsUpdatedFromDetailPanel} = props
  
  const handleElementUpdateMetadata= (field,value) => {
    updateSlideElementMetadata(clipId,element.id,{...element.metadata,[field]:value})
  }  

  const handleUpdateBarChartAnimationType=(newValue)=>{
    let newMetadata={...element.metadata}
    newMetadata.barChartData.animationType=newValue
    updateSlideElementMetadata(clipId,element.id,newMetadata)
  }

  const barColorType = element.metadata.isUniformColor?'uniform':'category'

  const handleUpdateBarColorType=(value)=>{
    let newMetadata={...element.metadata}
    if(value=='uniform'){
      newMetadata.isUniformColor = true
    }else{
      newMetadata.isUniformColor = false
    }
    updateSlideElementMetadata(clipId,element.id,newMetadata)
  }

    const barOpacity = element.metadata.barOpacity*100


  const setBarOpacity= (value) => {
    const opacity = value/100
    updateSlideElementMetadata(clipId,element.id,{...element.metadata,barOpacity:opacity})
  }  

  let uniformColorId = element.metadata.uniformColorId
  if(!uniformColorId){
    uniformColorId='auto'
  }

  const handleSetUniformColorId=(value)=>{
    let colorId=value
    if(value =='auto'){
      colorId = null
    }
    let newMetadata={...element.metadata}
    newMetadata.uniformColorId=colorId
    updateSlideElementMetadata(clipId,element.id,newMetadata)
  }

  const catagoryColorIds = element.metadata.catagoryColorIds

  const getAvailableChartColorsForBrand = () => {
    const orgBrand = getOrgBrand();
    const solidColors = orgBrand.backgrounds.filter(bg => bg.type === 'solid');
    return [
      { id: 'auto', value: 'auto', label: 'Auto' },
      ...solidColors.map(color => ({
        id: color.id,
        value: color.rgba,
        type: 'solid'
      }))
    ];
  };

  const availableChartColors = getAvailableChartColorsForBrand();

  /////// BAR CHART ////////////
  const [barAnimationTypeRaw, setBarAnimationTypeRaw] = useState(element.metadata.barChartData.animationType);  
  const [barChartData, setBarChartData] = useState({ compatible: false });
  const [barInputRawData, setBarInputRawData] = useState(element.metadata.barChartData.rawData);
  const [barRowHighlightStates, setBarRowHighlightStates] = useState(element.metadata.barChartData.rowHighlightStates);
  const [barCategoryColorIds, setBarCategoryColorIds] = useState(element.metadata.barChartData.categoryColorIds);

  useEffect(() => {
    if(barChartData.compatible){
      let metadata={...element.metadata}
      metadata.barChartData.rawData = barInputRawData
      metadata.barChartData.rowHighlightStates = barRowHighlightStates
      metadata.barChartData.categoryColorIds = barCategoryColorIds
      metadata.barChartData.barItems=combineHighlightStatesWithBarItems(barChartData.barItems,barRowHighlightStates,barCategoryColorIds)
      updateSlideElementMetadata(clipId,element.id,metadata)
    }
  }, [barChartData,barRowHighlightStates,barCategoryColorIds]);

  useEffect(() => {
    const barChartData = parseDataIntoBarChart(barInputRawData);
    setBarChartData(barChartData);
  }, [barInputRawData]);

  useEffect(() => {
      let metadata={...element.metadata}
      metadata.barChartData.animationType = barAnimationTypeRaw
      updateSlideElementMetadata(clipId,element.id,metadata)
  }, [barAnimationTypeRaw])


function combineHighlightStatesWithBarItems(barItems, rowHighlightStates,categoryColorIds) {
  return barItems.map(item => {
    const highlightState = rowHighlightStates[item.rawRowIndex] || 'default';
    return {
      ...item,
      highlightState: highlightState,
      categoryColorId:categoryColorIds[item.rawRowIndex]
    };
  });
}
  const labelTypes = [
    { value: 'all', label: 'Show all'},    
    { value: 'axisOnly', label: 'Axis only'},
    { value: 'valueOnly', label: 'Value only'},
    { value: 'none', label: 'Hide all'}
  ];

  //const activeLabelType = 'all'
  const activeLabelType = element.metadata.barChartData.labelTypes || 'all'

  const setActiveLabelType= (value) => {
     let newMetadata={...element.metadata}
     newMetadata.barChartData.labelTypes=value
    updateSlideElementMetadata(clipId,element.id,newMetadata)
  }  


  return (
    <>
      <div className='editor-detailPanel-header-alignVSpacer' />
       <div className='editor-detailPanel-divider' />
        <div className='editor-detailPanel-row'>
          <DPSelectMenu
            value={barColorType}
            onValueChange={(value)=>{handleUpdateBarColorType(value)}}
            options={colorTypes}
            width={152}
          />        
          <div className='editor-detailPanel-row-hSpacer' />
          {barColorType === 'uniform' &&
            <DPColorPicker                    
              activeColorId={uniformColorId}
              onChange={handleSetUniformColorId}
              options={availableChartColors}
              valueBtnAutoWidth={54}
              chartColors
            />   
          }
                
        </div>   
       <div className='editor-detailPanel-divider' />
       <div className='editor-detailPanel-row'>
          <div className='editor-detailPanel-row-label'>
            Chart Data
          </div>
        </div>      
        <EditorDetailPanelChartSpreadsheet             
          data={barInputRawData}
          updateData={setBarInputRawData}  
          barRowHighlightStates={barRowHighlightStates}  
          setBarRowHighlightStates={setBarRowHighlightStates}
          setBarCategoryColorIds={setBarCategoryColorIds}
          barColorType={barColorType}
          availableChartColors={availableChartColors}
          barCategoryColorIds={barCategoryColorIds}
          uniformColorId={uniformColorId}
          projectBackgroundId={props.projectBackgroundId}
          slideBackgroundId={props.slideBackgroundId}
          barOpacity={barOpacity}
        />
        <div className='editor-detailPanel-row'>
        <button 
          //onClick={toggleTrimMode}          
          className='dpButton dpButton--labelOnly dpButton--fullWidth'
        >
          <div className='dpButton-label'>
            Sample Data
          </div>
        </button>
      </div>
      

      <div className='editor-detailPanel-divider' />
               
       <div className='editor-detailPanel-row'>
        <div className='editor-detailPanel-row-label'>
          Fill Opacity
        </div>
        <div className='editor-detailPanel-row-hSpacer' />            
        <DPNumberInput 
          percentage
          min={0}
          max={100}
          value={barOpacity}
          setValue={setBarOpacity}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          iconName='opacity'              
        />
      </div>  

      <div className='editor-detailPanel-divider' />
      <div className='editor-detailPanel-row'>       
        <div className='editor-detailPanel-row-label'>
          Bar Spacing
        </div>
        <div className='editor-detailPanel-row-hSpacer' />   
        <DPNumberInput 
          min={0}
          max={50}
          value={element.metadata.barSpacing}
          setValue={(value)=>handleElementUpdateMetadata('barSpacing',value)}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          iconName='doubleEndedArrow'
          percentage
        />
       </div>

      <div className='editor-detailPanel-divider' />

      <div className='editor-detailPanel-row'>
        <div className='editor-detailPanel-row-label'>
          Labels
        </div>
        <div className='editor-detailPanel-row-hSpacer' />            
        <DPSelectMenu
          value={activeLabelType}
          onValueChange={(value)=>{setActiveLabelType(value)}}
          options={labelTypes}
          width={95}
        /> 
      </div>  



      <div className='editor-detailPanel-divider' />
      <DPItemAnimationPanel 
        item={props.element}
        handleUpdateChartAnimationType={handleUpdateBarChartAnimationType}
        activeStartTransition={props.element.metadata.barChartData.animationType}
        itemType='barChart'
      />

      

    </>
  );
};

export default EditorDetailPanelChartBar;

