import React from 'react'
import { withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import Icon from './misc/Icon'
import * as Toast from '@radix-ui/react-toast'


const toastMetadata={
	'test':{
		duration:5000,
		emoji:"✈️"	
	},
	'deleteProject':{				
		iconName: "deleted",
		iconColor: "red"
	},
	'moveToTeamLibrary':{		
		iconName: "moveToMedium"
	},
	// 'updatedSlideTemplate':{		
	// 	emoji:"📝"	
	// },
	// 'savedSlideTemplate':{		
	// 	emoji:"✅"	
	// },
}





class ToastNotifications extends React.Component{  
	render(){			
		const {toastNotification}=this.props

		if(toastNotification){
	   	const { toastType, data, timestamp } = toastNotification
	    const metadata = toastMetadata[toastType] || {}

	    const duration = metadata.duration || 5000

	    const emoji = metadata.emoji
	    const iconName = metadata.iconName
	    const iconColor = metadata.iconColor

			const type = toastType

			let enterAnimationType = 'defaultFadeIn'
			let exitAnimationType = 'defaultFadeOut'		

			let isEditor = false
			if(type === 'updatedSlideTemplate' || type === 'savedSlideTemplate'){
				isEditor = true
			}


		  return (		
		  	<React.Fragment key={toastNotification.timestamp}>
					<Toast.Provider className='toastContainer' duration={duration}>

			   	 <Toast.Root className={'toast ' + (toastType ? ` toast--type--${toastType}` : '' ) + (isEditor ? ` toast--editor` : '' ) + (enterAnimationType ? ` toast--enterAnimationType--${enterAnimationType}` : '' ) + (exitAnimationType ? ` toast--exitAnimationType--${exitAnimationType}` : '' )}>

			    	
			    	{emoji && 
				      <div className='toast-emojiContainer'>
				      	{emoji}
				      </div>
				    }
				    {iconName &&
				    	<div className={'toast-iconContainer ' + (iconColor ? ` toast-iconContainer--iconColor--${iconColor} ` : '')}>
				      	<Icon name={iconName} />
				      </div>
				  	}

				    {type === 'deleteProject' &&
				    	<>
					    	<div className='toast-label'>			      			
					    		Deleted <span className='toast-label-emph'>{data.projectName}</span>
					    	</div>					    	
				    	</>
				  	}


				  	{type === 'savedSlideTemplate' &&
				    	<>
					    	<div className='toast-label'>			      			
					    		<span className='toast-label-emph'>Saved slide as template</span>
					    	</div>					    	
				    	</>
				  	}
				  	
				  	{type === 'archivedSlideTemplate' &&
				    	<>
					    	<div className='toast-label'>			      			
					    		<span className='toast-label-emph'>Archived slide template</span>
					    	</div>					    	
				    	</>
				  	}

				  	{type === 'restoredSlideTemplate' &&
				    	<>
					    	<div className='toast-label'>			      			
					    		<span className='toast-label-emph'>Restored slide template</span>
					    	</div>					    	
				    	</>
				  	}
				  	 	{type === 'updatedSlideTemplate' &&
				    	<>
					    	<div className='toast-label'>			      			
					    		<span className='toast-label-emph'>Updated slide template</span>
					    	</div>					    	
				    	</>
				  	}

				  	 {isEditor &&
				    	<>
					    	<div className='toast--editor-border'/>		
					    	<div className='toast--editor-bg'/>			      								    						    	
				    	</>
				  	}

				  	


				  	{type === 'moveToTeamLibrary' &&
				    	<>
					    	<div className='toast-label'>			      			
					    		Moved <span className='toast-label-emph'>{data.projectName}</span> to <span className='toast-label-emph'>{data.orgName}</span>
					    	</div>					    	
				    	</>
				  	}

				  	{type === 'addToSingleCollection' &&
				    	<>
					    	<div className='toast-label'>			      			
					    		Added <span className='toast-label-emph'>{data.projectName}</span> to 
					    	</div>					    	
					    	<div className='toast-collectionMarker'>
					    		<div className={'toast-collectionMarker-color ' + (data.collectionColor ? `toast-collectionMarker-color--${data.collectionColor}` : '')}/>
					    		<div className='toast-collectionMarker-label'>
					    			{data.collectionName}
					    		</div>
					    	</div>					    	
				    	</>
				  	}

				  	{type === 'removeFromSingleCollection' &&
				    	<>
					    	<div className='toast-label'>			      			
					    		Removed <span className='toast-label-emph'>{data.projectName}</span> from {data.collectionName}
					    	</div>					    						    	
				    	</>
				  	}

				  	{type === 'restoreProject' &&
				    	<>
					    	<div className='toast-label'>			      			
					    		Restored <span className='toast-label-emph'>{data.projectName}</span>
					    	</div>					    						    	
				    	</>
				  	}

				  	{type === 'addToTwoCollections' &&
				    	<>
					    	<div className='toast-label'>			      			
					    		Added <span className='toast-label-emph'>{data.projectName}</span> to 
					    	</div>					    	
					    	<div className='toast-collectionMarker'>
					    		<div className={'toast-collectionMarker-color ' + (data.collection1Color ? `toast-collectionMarker-color--${data.collection1Color}` : '')}/>
					    		<div className='toast-collectionMarker-label'>
					    			{data.collection1Name}
					    		</div>
					    	</div>	
					    	<div className='toast-smallHSpacer' />			      			
					    	<div className='toast-label'>			      			
					    		and
					    	</div>
					    	<div className='toast-collectionMarker'>
					    		<div className={'toast-collectionMarker-color ' + (data.collection2Color ? `toast-collectionMarker-color--${data.collection2Color}` : '')}/>
					    		<div className='toast-collectionMarker-label'>
					    			{data.collection2Name}
					    		</div>
					    	</div>				
				    	</>
				  	}

				  	{type === 'addToMoreThanTwoCollections' &&
				    	<>
					    	<div className='toast-label'>			      			
					    		Added <span className='toast-label-emph'>{data.projectName}</span> to multiple collections
					    	</div>					    	
				    	</>
				  	}

				  	{type === 'removeFromMultipleCollections' &&
				    	<>
					    	<div className='toast-label'>			      			
					    		Removed <span className='toast-label-emph'>{data.projectName}</span> from multiple collections
					    	</div>					    						    	
				    	</>
				  	}

				  	{type === 'deleteCollection' &&
				    	<>
					    	<div className='toast-label'>			      			
					    		Deleted
					    	</div>		
					    	<div className='toast-collectionMarker'>
					    		<div className={'toast-collectionMarker-color ' + (data.collectionColor ? `toast-collectionMarker-color--${data.collectionColor}` : '')}/>
					    		<div className='toast-collectionMarker-label'>
					    			{data.collectionName}
					    		</div>
					    	</div>				    						    					    
				    	</>
				  	}





							
			    </Toast.Root>
			    <Toast.Viewport className='toastContainer'/>
			  </Toast.Provider>
				</React.Fragment>
		  )
		}else return null
	}
}

const mapStateToProps = (state) => ({
	toastNotification: state.toastNotification
});

export default withRouter(connect(
	mapStateToProps,
	{
	})(ToastNotifications))



