import React, { useRef, useEffect, useCallback } from 'react';
import * as THREE from 'three';

const ScreenVideoCroppedMaterial = ({ videoElement, opacity, meshWidth, meshHeight, videoWidth, videoHeight }) => {
  const materialRef = useRef(null);
  const textureRef = useRef(null);



  const updateTexture = useCallback(() => {
    let needNewTexture = false;

    if (!textureRef.current || 
        (videoElement && !(textureRef.current instanceof THREE.VideoTexture))) {
      needNewTexture = true;
    }

    if (needNewTexture) {
      if (videoElement) {
        textureRef.current = new THREE.VideoTexture(videoElement);
      }

      if (textureRef.current) {
        textureRef.current.colorSpace = THREE.SRGBColorSpace;
        textureRef.current.magFilter = THREE.LinearFilter;
        textureRef.current.minFilter = THREE.LinearFilter;
        textureRef.current.generateMipmaps = false;
      }
    } else {
       if (videoElement && textureRef.current instanceof THREE.VideoTexture) {
        textureRef.current.image = videoElement;
      }
    }

    if (textureRef.current) {
      textureRef.current.needsUpdate = true;
    }

    return textureRef.current;
  }, [ videoElement]);

  const updateTextureSettings = useCallback(() => {
    if (textureRef.current && meshWidth && meshHeight && videoWidth && videoHeight) {
      const aspectRatioMesh = meshWidth / meshHeight;
      const aspectRatioVideo = videoWidth / videoHeight;
      let offsetX = 0;
      let offsetY = 0;
      let repeatX = 1;
      let repeatY = 1;

      if (aspectRatioMesh > aspectRatioVideo) {
        repeatX = aspectRatioVideo / aspectRatioMesh;
        offsetX = (1 - repeatX) / 2;
      } else {
        repeatY = aspectRatioMesh / aspectRatioVideo;
        offsetY = 0;
      }

      textureRef.current.offset.set(offsetX, offsetY);
      textureRef.current.repeat.set(repeatX, repeatY);
      textureRef.current.needsUpdate = true;
    }
  }, [meshWidth, meshHeight, videoWidth, videoHeight]);

  useEffect(() => {
    const texture = updateTexture();
    updateTextureSettings();

    if (!materialRef.current) {
      materialRef.current = new THREE.MeshBasicMaterial({
        map: texture,
        transparent: true,
        opacity: opacity,
        precision: 'highp',
      });
    } else {
      materialRef.current.map = texture;
      materialRef.current.needsUpdate = true;
    }
  }, [updateTexture, updateTextureSettings, opacity]);

  useEffect(() => {
    if (materialRef.current) {
      materialRef.current.opacity = opacity;
      materialRef.current.needsUpdate = true;
    }
  }, [opacity]);

  useEffect(() => {
    updateTexture();
    updateTextureSettings();
    if (materialRef.current) {
      materialRef.current.needsUpdate = true;
    }
  }, [videoElement, updateTexture, updateTextureSettings]);

  //console.log(`meshHeight Height is ${meshHeight}`)

  return materialRef.current ? <primitive object={materialRef.current} attach="material" /> : null;
};

export default ScreenVideoCroppedMaterial;