import React from 'react'
import Icon from '../../misc/Icon'
import ChartsPanel from './charts//ChartsPanel'


class EditorRightPanelContainer extends React.PureComponent{  


  render(){         		
  	const {activePanelClip} = this.props
  	const showFixedPanel = activePanelClip &&  activePanelClip.type=='chart'
  	const showChartPanel = activePanelClip && activePanelClip.type=='chart'
  	
			return (  
			<>          	
				{showFixedPanel && 
					<button onClick={this.props.handleDeactivateActiveClip} className='editor-center-rightPanelBackBtn'>
						<div className='editor-center-rightPanelBackBtn-inner'>
							<Icon name='alignTextLeft' />
						</div>
					</button>			  
				}
				
				<div className={'editor-center-rightPanelContainer ' + (showFixedPanel ? 'editor-center-rightPanelContainer--show' : 'editor-center-rightPanelContainer--hide')}>
				

					{showChartPanel && 
						<ChartsPanel 
							clip={activePanelClip}
							deleteClip={this.props.deleteClip}
							projectBackgroundId={this.props.projectBackgroundId}
							updateChartClip={this.props.updateChartClip}
							updateChartBackgroundColor={this.props.updateChartBackgroundColor}
							defaultMotionStyle={this.props.defaultMotionStyle}
						/>
					}
					

				</div>
				
				</>
			)
  }
}

export default EditorRightPanelContainer
