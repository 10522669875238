import React, { useRef, useEffect, useMemo } from 'react';
import * as THREE from 'three';
import defaultScreenDeviceFrameAnimationValues from '../utils/animations/defaultScreenDeviceFrameAnimationValues';
import renderOrders from '../renderOrders';
import SlideFillImageElementMaterial from '../slide/images/SlideFillImageElementMaterial';

const FPS = 60;

const ScreenVideoClayAvatarOverlay = ({ clip,frameWidth, variableValues, frameHeight, initialProperties, animationStates, localTime, visible, showScreenVideoStatic, meshScale, initialOffset }) => {
  const groupRef = useRef();
  const imageMeshRef = useRef();
  
  const circleDiameter = 30 * meshScale;
  const circleRadius = circleDiameter / 2;
  
  // Insets (similar to the text positioning in the original component)
  const insetRight = 28 * meshScale + initialOffset;
  const insetTop = 8 * meshScale + initialOffset;

  // Calculate position based on insets (center-center coordinate system)
  const circlePositionX = frameWidth / 2 - insetRight - circleRadius;
  const circlePositionY = frameHeight / 2 - insetTop - circleRadius;

  const originalImageWidth = clip.clayProfilePictureWidth || 500
  const originalImageHeight = clip.clayProfilePictureHeight || 500
  const originalImageAspect = originalImageWidth / originalImageHeight;
  const meshWidth = circleDiameter;
  const meshHeight = circleDiameter;
  const meshAspect = meshWidth / meshHeight;

  let imageWidth, imageHeight;

  if (meshAspect > originalImageAspect) {
    imageWidth = meshWidth;
    imageHeight = meshWidth / originalImageAspect;
  } else {
    imageHeight = meshHeight;
    imageWidth = meshHeight * originalImageAspect;
  }

  // Hardcoded image source
  let imgSrc 
  if(!clip.metadata.hidePlaceholderClayProfileImage){
    imgSrc = "https://res.cloudinary.com/yarn/image/upload/v1723642295/clay/Kareem-sq-1_ymj5zw.jpg";
  }


  if(clip.clayProfilePicture){
    imgSrc=clip.clayProfilePicture
  }

//  console.log('img src')
 // console.log(imgSrc)

  // Create circle geometry
  const circleGeometry = useMemo(() => {
    const geometry = new THREE.CircleGeometry(circleRadius, 32);
    const uvs = new Float32Array(geometry.attributes.position.count * 2);
    for (let i = 0; i < geometry.attributes.position.count; i++) {
      const vertex = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, i);
      uvs[i * 2] = (vertex.x / circleRadius + 1) / 2;
      uvs[i * 2 + 1] = (vertex.y / circleRadius + 1) / 2;
    }
    geometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));
    return geometry;
  }, [circleRadius]);

  // APPLY ANIMATIONS
  useEffect(() => {
    const frameIndex = Math.floor(localTime * FPS);
    if (groupRef.current) {
      Object.keys(animationStates).forEach(property => {
        let state = animationStates[property][frameIndex];
        if (showScreenVideoStatic) {
          state = defaultScreenDeviceFrameAnimationValues[property];
        }
        if (state !== undefined) {
          if (property === 'positionX') {
            groupRef.current.position.x = state;
          } else if (property === 'positionY') {
            groupRef.current.position.y = state;
          } else if (property === 'positionZ') {
            groupRef.current.position.z = state;
          } else if (property === 'scale' && groupRef.current.scale) {
            groupRef.current.scale.set(state, state, state);
          } else if (property.startsWith('rotation')) {
            const axis = property.slice('rotation'.length).toLowerCase();
            groupRef.current.rotation[axis] = state;
          } else if (property === 'opacity' && imageMeshRef.current.material) {
             imageMeshRef.current.material.opacity = state;
          }
        }
      });
    } 
    
    // Debug logging
    // console.log('Group position:', groupRef.current ? groupRef.current.position : 'N/A');
    // console.log('Group scale:', groupRef.current ? groupRef.current.scale : 'N/A');
    // console.log('Circle position:', circlePositionX, circlePositionY);    
  }, [localTime, animationStates, showScreenVideoStatic, frameWidth, frameHeight, meshScale, initialOffset, circlePositionX, circlePositionY]);

  return (
    <group
      ref={groupRef}
      position={[initialProperties.positionX, initialProperties.positionY, initialProperties.positionZ]}
      scale={[initialProperties.scale, initialProperties.scale, initialProperties.scale]}
      visible={visible}
    >
      <mesh         
        ref={imageMeshRef}
        renderOrder={renderOrders.screenVideoOverlay} 
        position={[circlePositionX, circlePositionY, 0]}
        geometry={circleGeometry}
      >
        <SlideFillImageElementMaterial 
          
          imgSrc={imgSrc}   
          opacity={1} // This will be updated by the animation
          meshWidth={circleDiameter}
          meshHeight={circleDiameter}
          imageWidth={imageWidth}
          imageHeight={imageHeight}
        />
      </mesh>
    </group>
  );
};

export default ScreenVideoClayAvatarOverlay;