import React, { useState } from 'react';
import Icon from '../../misc/Icon'


function LibrarySideMenuTab({label, active, iconName, activeIconName, collection, collectionColor,onClick, orgLibraryTab}) {
 
  return (    
      <div className='libraryPage-sideMenu-tabContainer'>
        <button onClick={onClick} data-state={active ? 'active' : 'inactive'} className={'libraryPage-sideMenu-tab ' + (label ? `libraryPage-sideMenu-tab--${label}` : '') + (orgLibraryTab ? ` libraryPage-sideMenu-tab--orgLibraryTab ` : '')}>          

          {!orgLibraryTab && 
            <div className='libraryPage-sideMenu-tab-visual'>
              {iconName && !active &&
                <div className='libraryPage-sideMenu-tab-visual-iconContainer'>
                  <Icon name={iconName} />
                </div>
              }
              {activeIconName && active &&
                <div className='libraryPage-sideMenu-tab-visual-iconContainer'>
                  <Icon name={activeIconName} />
                </div>
              }
              {collection && collectionColor &&
                <div className='libraryPage-sideMenu-tab-visual-collectionColorContainer'>
                  <svg width="13" height="13">
                    <path fill={collectionColor} d="M2.564 0h7.872c.892 0 1.215.093 1.54.267.327.174.583.43.757.756.174.326.267.65.267 1.54v7.873c0 .892-.093 1.215-.267 1.54-.174.327-.43.583-.756.757-.326.174-.65.267-1.54.267H2.563c-.892 0-1.215-.093-1.54-.267a1.817 1.817 0 0 1-.757-.756C.093 11.65 0 11.327 0 10.437V2.563c0-.892.093-1.215.267-1.54.174-.327.43-.583.756-.757C1.35.093 1.673 0 2.563 0Z" />
                  </svg>
                </div>
              }
            </div>
          }

          {orgLibraryTab && 
            <div className='libraryPage-sideMenu-tab-orgTabSpacer' />            
          }
        <div className='libraryPage-sideMenu-tab-label'>
          {label}
        </div>     
      </button>      
    </div>
  );
}

export default LibrarySideMenuTab;
