const TextSlideClipNodeSpec = {
	attrs: {
		id:{default:'defaultID'},
		type:{default:'textSlide'},
		relativeStartTime: {default:0},
		duration:  {default:0},
		metadata: { default: [] },
		wordsArray: { default: [] },
		zIndex:  {default:1},
		sceneId:{default:'defaultSceneId'}
	},
};

 export default TextSlideClipNodeSpec
 