import React, { useState, useEffect } from 'react';
import Icon from '../../misc/Icon';
import DPToggleGrid from './DPToggleGrid';
import DPNumberInput from './DPNumberInput';
import { getOrgBrand } from '../../../utils/brands/getOrgBrand'
import { getAvailableTextStyles } from '../../../utils/brands/getAvailableTextStyles'
import {getFontForTextStyle} from '../../../utils/brands/getFontForTextStyle'


const EditorDetailPanelTextStyleGrid = ({ element, updateSlideElementMetadata,updateSlideTextElementTextProperties, clipId }) => {
  const [activeTextStyle, setActiveTextStyle] = useState(element.metadata.textStyle);
  const textStyleLabels = {
    title: "Title",
    heading1: "H1",
    heading2: "H2",
    heading3: "H3",
    body: "Body",
    sub: "Sub",
    bodyAlt: "Body Alt",
    custom: "Custom", // Add the custom option label
    clayFrameTitle: "Frame",
    clayFrameTitleBig: "Frame Big",
    claySquareName: "Sq Name",
    claySquareTitle: "Sq Title",

  };
  const availableTextStyles = getAvailableTextStyles();

  useEffect(() => {
    if (element && element.type === 'text') {
      setActiveTextStyle(element.metadata.textStyle || 'body');
    }
  }, [element]);

  const handleUpdateTextStyle = (newTextStyle) => {
    let newTextProperties = element.metadata.textProperties 
    if(newTextStyle!=='custom'){
      newTextProperties=getFontForTextStyle(newTextStyle)
    }
    updateSlideTextElementTextProperties(clipId,element.id,newTextStyle,newTextProperties)
      // updateSlideElementMetadata(clipId, element.id, {
      //   ...element.metadata,
      //   textStyle: newTextStyle,
      //   textProperties:newTextProperties
      // });
      setActiveTextStyle(newTextStyle);    
  };

  // Define the desired order
  const desiredOrder = ['title', 'heading1', 'heading2', 'sub', 'body'];

  // Filter and sort the availableTextStyles based on the desired order
  const sortedTextStyles = desiredOrder
    .filter(style => availableTextStyles.includes(style))
    .map(style => ({
      value: style,
      label: textStyleLabels[style]
    }));



  // Add the custom option at the end
  sortedTextStyles.push({
    value: 'custom',
    label: textStyleLabels.custom
  });



  return (
    <>
      <DPToggleGrid
        items={sortedTextStyles}
        activeItem={activeTextStyle}
        onItemClick={handleUpdateTextStyle}
      />
      <div className='editor-detailPanel-header-2rowToggleBottonVSpacer' />


    </>
  );
};

export default EditorDetailPanelTextStyleGrid;