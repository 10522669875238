import ReactDOM from 'react-dom'
import React from 'react'
import {randomID} from '../../utils/randomID'
import CaptionsGroupGhost from '../../three/captions/CaptionsGroupGhost';

const SCENE_WIDTH = 1920
const SCENE_HEIGHT = 1080




//measure the height, width, normalLeft and normalTop of the captions group
// and for each word in it


function calculateCaptionWordGroupPositions(wordGroup) {


  return new Promise((resolve) => {
    const ghostContainer = document.createElement('div');
    ghostContainer.className = 'editor-captions-ghostContainer';
    ghostContainer.style.width = `${SCENE_WIDTH}px`;
    ghostContainer.style.height = `${SCENE_HEIGHT}px`;
    ghostContainer.style.position = 'absolute';
    ghostContainer.style.visibility = 'hidden';
    document.body.appendChild(ghostContainer);


    ReactDOM.render(
      <CaptionsGroupGhost
        wordGroup={wordGroup}
        visible={false}
      />,
      ghostContainer,
      () => {
        setTimeout(() => {


          const groupGhostElement = document.getElementById(`ghost_${wordGroup.id}`);
          const ghostParentContainer = document.getElementById(`ghost_container_${wordGroup.id}`);

          if (groupGhostElement && ghostParentContainer) {
            const groupRect = groupGhostElement.getBoundingClientRect();
            const parentRect = ghostParentContainer.getBoundingClientRect();

            const parentCenterX = parentRect.width / 2;
            const parentCenterY = parentRect.height / 2;

            // Calculate the position relative to the parent's top-left corner
            const relativeLeft = groupRect.left - parentRect.left;
            const relativeTop = groupRect.top - parentRect.top;

            // Convert to Three.js coordinate system (origin at center, Y-axis inverted)
            // Adjust for the group's own dimensions to position from its center
            const normalLeft = (relativeLeft + (groupRect.width / 2)) - parentCenterX;
            const normalTop = parentCenterY - (relativeTop + (groupRect.height / 2));  // Invert Y-axis

            // console.log(`groupRect.left ${groupRect.left}`)
            // console.log(`parentRect ${parentRect.left}`)

            // const normalLeft = (groupRect.left - parentRect.left) + (groupRect.width / 2) - parentRect.width/2
            // const normalTop = (groupRect.top - parentRect.top) - (groupRect.height / 2) - parentRect.height / 2

            // console.log('normalLeft', normalLeft)
            // console.log('normalTop', normalTop)

            let words = wordGroup.words;
        

            words.forEach((word)=>{

              // console.log(`for the word ${word}-------------`)
              // console.log(word)
              // console.log(word.id)

              const wordElement = document.getElementById(`word_ghost_${word.id}`)
              // console.log(`word element is`)
              // console.log(wordElement)
              if(wordElement){
                const wordRect = wordElement.getBoundingClientRect()

                //const parentRect = ghostParentContainer.getBoundingClientRect();

                // console.log('wordRect.left')
                // console.log(wordRect.left)

                // Calculate word position relative to parent
                const relativeWordLeft = wordRect.left - parentRect.left;
                const relativeWordTop = wordRect.top - parentRect.top;

                // Convert to Three.js coordinate system (origin at parent center, Y-axis inverted)
                const normalWordLeft = (relativeWordLeft + (wordRect.width / 2)  - parentCenterX);
                const normalWordTop = parentCenterY - (relativeWordTop + (wordRect.height / 2));

                // console.log('wordRect.left')
                // console.log(wordRect.left)

                // console.log('wordRect.left')              
                // console.log(wordRect.left)

                word.width = wordRect.width;
                word.height = wordRect.height;
                word.normalLeft = normalWordLeft;  // Relative to group center
                word.normalTop = normalWordTop;     // Relative to group center
                word.originalStartTime = word.startTime 
                word.originalEndTime=word.endTime
              }
            })           

            // Clean up
            ReactDOM.unmountComponentAtNode(ghostContainer);
            document.body.removeChild(ghostContainer);

            resolve({
              ...wordGroup,
              originalStartTime:wordGroup.startTime,
              originalEndTime:wordGroup.endTime,
              words: words,
              width: groupRect.width,
              height: groupRect.height,
              normalLeft: normalLeft,
              normalTop: normalTop
            });
          } else {
            console.error('Ghost element not found');
            resolve(wordGroup);
          }
        }, 500); // Short timeout to ensure rendering is complete
      }
    );
  });
}

export { calculateCaptionWordGroupPositions };