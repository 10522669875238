import React from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';
import Icon from '../misc/Icon'; // Adjust the path as needed

function ToggleGroup({children, fullWidth, className, toggleCount}) {
  return (
    <div className={'uiToggleGroup ' + (fullWidth ? 'uiToggleGroup--fullWidth':'') + (toggleCount ? ` uiToggleGroup--fullWidth--toggleCount--${toggleCount} `:'') + (className ? ` uiToggleGroup--${className} `:'')} >
      {children}
    </div>
  );
}

function ToggleGroupBtn({active, handleClick, iconName, label, tooltipLabel, longTooltipDelay, disabled, tooltipBelow}) {
  return (    
    <Tooltip.Root delayDuration={longTooltipDelay ? 400 : 0} disableHoverableContent={true}>
      <Tooltip.Trigger asChild>
        <button 
          data-state={active ? "active" : "inactive"} 
          className={'uiToggleButton ' + (!label ? ' uiToggleButton--iconOnly ' : '') + (label && iconName ? ' uiToggleButton--mixed ' : '')}
          onClick={handleClick}
          disabled={disabled}        
        >
          {iconName && 
            <div className='uiToggleButton-iconContainer'>
              <Icon name={iconName} />
            </div>
          }
          {label && 
            <div className='uiToggleButton-label'>
              {label}
            </div>
          }
        </button>  
      </Tooltip.Trigger>   

      {tooltipLabel &&                
        <Tooltip.Content side={tooltipBelow ? "bottom" : "top"} className="tooltip tooltip--toggleBtn">
          {tooltipLabel}                        
        </Tooltip.Content>                                
      }
    </Tooltip.Root>
  );
}

export { ToggleGroup, ToggleGroupBtn };
