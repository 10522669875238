const ImageClipNodeSpec = {
	attrs: {
		id:{default:'defaultID'},
		imageId:{default:null},
		type:{default:'image'},
		relativeStartTime: {default:0},
		//startTime: {default:0},
		duration:  {default:0},
		metadata: { default: [] },
		zIndex:  {default:1},
		sceneId:{default:'defaultSceneId'}
	},
};

 export default ImageClipNodeSpec
 