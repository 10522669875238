import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react';
import * as THREE from 'three';
import { useThree } from '@react-three/fiber';

const ExportScreenVideoCroppedMaterial = ({ codecVideoClip, opacity, meshWidth, meshHeight, videoWidth, videoHeight }) => {
  const { invalidate } = useThree();
  const materialRef = useRef();
  //const textureRef = useRef();

  // OCT 31st Initialize textureRef.current before the first render
  const textureRef = useRef(
    (() => {
      const texture = new THREE.Texture();
      texture.minFilter = THREE.LinearFilter;
      texture.magFilter = THREE.LinearFilter;
      texture.format = THREE.RGBAFormat;
      texture.colorSpace = THREE.SRGBColorSpace;
      return texture;
    })()
  );


  const [updateTrigger, setUpdateTrigger] = useState(0);

  useEffect(() => {
    // if (!textureRef.current) {
    //   textureRef.current = new THREE.Texture();
    //   textureRef.current.minFilter = THREE.LinearFilter;
    //   textureRef.current.magFilter = THREE.LinearFilter;
    //   textureRef.current.format = THREE.RGBAFormat;
    //   textureRef.current.colorSpace = THREE.SRGBColorSpace;
    // }
    if (codecVideoClip) {
      codecVideoClip.setExternalTexture(textureRef.current);
      codecVideoClip.setTextureUpdatedCallback(() => {
      //  console.log('Texture updated callback triggered');
        setUpdateTrigger(prev => prev + 1);
        invalidate();
      });
    }
    return () => {
      if (codecVideoClip) {
        codecVideoClip.setExternalTexture(null);
        codecVideoClip.setTextureUpdatedCallback(null);
      }
    };
  }, [codecVideoClip, invalidate]);

  const updateTextureSettings = useCallback(() => {
   if (textureRef.current && meshWidth && meshHeight && videoWidth && videoHeight) { //New October 31 add image check befor set needs update to true
  //  if (textureRef.current  && meshWidth && meshHeight && videoWidth && videoHeight) {
      //console.log('Updating texture settings');
      const aspectRatioMesh = meshWidth / meshHeight;
      const aspectRatioVideo = videoWidth / videoHeight;
      let offsetX = 0;
      let offsetY = 0;
      let repeatX = 1;
      let repeatY = 1;
      if (aspectRatioMesh > aspectRatioVideo) {
        repeatX = aspectRatioVideo / aspectRatioMesh;
        offsetX = (1 - repeatX) / 2;
      } else {
        repeatY = aspectRatioMesh / aspectRatioVideo;
        offsetY = 0;
      }
      textureRef.current.offset.set(offsetX, offsetY);
      textureRef.current.repeat.set(repeatX, repeatY);
      if(textureRef.current.image ){
        textureRef.current.needsUpdate = true;
      }
     // console.log('Texture settings updated:', { offsetX, offsetY, repeatX, repeatY });
    } else {
      console.log('Texture not ready for update', {
        textureExists: !!textureRef.current,
        meshWidth,
        meshHeight,
        videoWidth,
        videoHeight
      });
    }
  }, [meshWidth, meshHeight, videoWidth, videoHeight]);

  useEffect(() => {
    updateTextureSettings();
  }, [updateTextureSettings, updateTrigger]);


  useEffect(() => {
    updateTextureSettings();
  }, [updateTextureSettings, meshWidth, meshHeight, videoWidth, videoHeight]);

  const material = useMemo(() => {
  //  console.log('Creating new material');
    return new THREE.MeshBasicMaterial({
      map: textureRef.current,
      transparent: true,
      opacity: opacity,
      precision: 'highp',
    });
  }, [opacity, updateTrigger]);

  useEffect(() => {
    if (materialRef.current) {
      materialRef.current.map = textureRef.current;
      materialRef.current.needsUpdate = true;
   //   console.log('Material updated with new texture');
    }
  }, [material]);

  return <meshBasicMaterial ref={materialRef} map={textureRef.current} transparent opacity={opacity} attach="material" />;
};

export default ExportScreenVideoCroppedMaterial;
