import getChunkWordIndices from './getChunkWordIndices'

//Here we parse the prosemirror doc into the structure of timeline clips so we can sync the timeline with the transcriptpabel

//We increment clip index for webcam recording or transcript chunks/audio clips that are not in a placeholder
//for placeholder webcam audio clips dont give them an index, give them an index relative to the placeholder clip


const getParsedNodes = (view) => {
  
  const { state } = view;
  
  let sceneNodes = []
  let audioNodes = []
  let webcamRecordingNodes = []  
  let webcamPlaceholderNodes = []
  
  let currentGroup = null;
  let currentSceneId = null;
  
  let currentSkippedWords=[]
  let currentWebcamTranscriptChunks = [];

  let clipIndex = 0
  let indexInPlaceholder = 0

  state.doc.descendants((node, pos) => {

    if (node.type.name === "sceneHeader") {
      currentSceneId = node.attrs.sceneId;
      const parsedSceneNode = parseSceneNode(node,pos,view)
      sceneNodes.push(parsedSceneNode)
    }

    else if (node.type.name === "transcriptGroup") {
      // If we were processing a webcam recording group, finalize it
      if (currentGroup?.groupType === 'webcam-recording' && currentWebcamTranscriptChunks.length > 0) {
        webcamRecordingNodes.push({
          clipId: currentGroup.groupId,
          sceneId: currentSceneId,
          clipIndex: clipIndex, 
          chunks: currentWebcamTranscriptChunks,
          skippedWords: currentSkippedWords
        });
        clipIndex+=1
      }
      const groupId = node.attrs.groupId;
      const groupType = node.attrs.groupType;      
      currentGroup = {groupId,groupType};

      // Reset chunks array for new webcam recording group
      if (groupType === 'webcam-recording') {
        currentWebcamTranscriptChunks = [];
        currentSkippedWords = node.attrs.skippedWords || []
      }
      else if (groupType === 'webcam-placeholder') {
        indexInPlaceholder = 0
        webcamPlaceholderNodes.push({
          clipId: groupId,
          sceneId: currentSceneId,
          type: 'webcam-placeholder',
          clipIndex:clipIndex
        })
        clipIndex+=1
      }
    }
    else if (node.type.name === "transcriptChunk") {
      const attrs = node.attrs;
      const textContent = node.textContent.trim();
      
      if (textContent) {
         let audioClipIndex 
         let indexInParentClip
         if(currentGroup.groupType=='webcam-placeholder'){
            audioClipIndex = null 
            indexInParentClip = indexInPlaceholder
            indexInPlaceholder+=1
         } else{
            audioClipIndex = clipIndex 
            clipIndex+=1
         }
        const audioNode = {
          clipId: attrs.clipId,
          sceneId: attrs.sceneId,
          clipIndex: audioClipIndex,
          indexInParentClip:indexInParentClip,
          textContent,
          parentWebcamClip: (currentGroup?.groupType === 'webcam-placeholder' ? currentGroup.groupId : null)
        };
        audioNodes.push(audioNode);
      }
    }
    else if (node.type.name === "webcamRecordingChunk") {
      const attrs = node.attrs;
      const { firstWordIndex, lastWordIndex } = getChunkWordIndices(node);
      const transcriptChunk = {
        clipId: attrs.clipId,
        sceneId: attrs.sceneId,
        firstWordIndex,
        lastWordIndex
      };
      if (currentGroup?.groupType === 'webcam-recording') {
        currentWebcamTranscriptChunks.push(transcriptChunk);
      }
    }
  });

  // Handle final webcam recording group if exists
  if (currentGroup?.groupType === 'webcam-recording' && currentWebcamTranscriptChunks.length > 0) {
    webcamRecordingNodes.push({
      clipId: currentGroup.groupId,
      sceneId: currentSceneId,
      clipIndex: clipIndex,
      chunks: currentWebcamTranscriptChunks,
      skippedWords:currentSkippedWords
    });
  }


  return {
    audioNodes,
    sceneNodes,
    webcamRecordingNodes,
    webcamPlaceholderNodes
  };
};

export default getParsedNodes



function parseSceneNode(node,pos,view){
  const domNode = view.nodeDOM(pos);
  let dimensions = { top: 0, height: 0 };
  if (domNode) {
    dimensions = {
      top: domNode.offsetTop,
      height: domNode.offsetHeight
    }
  }
  const title = node.textContent || 'Untitled Scene'
  return{
    sceneId: node.attrs.sceneId,
    sceneTitle:title,
    dimensions
  }
}


