import React, { useRef, useEffect, useState, useContext } from 'react';
import { SpringAnimator } from '../../three/utils/animations/SpringAnimator';
import TextSlideContents from './TextSlideContents';
import CanvasBackground from '../CanvasBackground';
import { startTransitionConfigs } from '../../three/textSlide/transitionConfigs/startTransitionConfigs';
import { endTransitionConfigs } from '../../three/textSlide/transitionConfigs/endTransitionConfigs';
import { simulateAnimationDuration } from '../../three/utils/animations/simulateAnimationDuration'; // Import the utility function
import {calculateMinSlideDuration} from '../../three/utils/animations/calculateMinSlideDuration'
import TextSlideWordAnimateContents from './TextSlideWordAnimateContents'
import TextSlideLineAnimateContents from './TextSlideLineAnimateContents'
import TextSlideLineListAnimateContents from './TextSlideLineListAnimateContents'
import TextSlideWordListAnimateContents from './TextSlideWordListAnimateContents'
import {getAnimationParamsForMotionStyle} from '../utils/animations/getAnimationParamsForMotionStyle'

import {getBackgroundForId} from '../../utils/brands/getBackgroundForId'
import {getTextColorForBackgroundId} from '../../utils/brands/getTextColorForBackgroundId'
import {getTextColorForId} from '../../utils/brands/getTextColorForId'


const FPS = 60; // Frames per second

const defaultValues = {
  positionX: 0,
  positionY: 0,
  positionZ: 50,
  scale: 1,
  rotationX: 0,
  rotationY: 0,
  rotationZ: 0,
  opacity: 1,
};


const lineAnimateOverlapRatio = 0.85
const wordAnimateOverlapRatio = 0.3


  
function logObject(obj) {
    return JSON.parse(JSON.stringify(obj));
}



const TextSlide = ({clip,time, hideRenderedTextSlide,projectBackground}) => {


  const globalStartTime = clip.startTime
  const globalEndTime = clip.endTime
  const globalTime = time
  const duration= (clip.endTime - clip.startTime).toFixed(4)
  const startTransitionType = clip.metadata.startTransitionType
  const endTransitionType = clip.metadata.endTransitionType

  const localTime = time - globalStartTime // time since start of slide
  const localStartTime = 0
  const localEndTime = globalEndTime - globalStartTime
  const calculationWindowEnd = localEndTime + 0.2


  const motionStyle=clip.metadata.motionStyle
  const animationParams=getAnimationParamsForMotionStyle(motionStyle)





  // we're currently calculating animation states against global time
  // we want to calculate animation states to local time
  // we we'll pass through global time to fire the propertie
  const { startFrom: transitionStartFrom, startTo: transitionStartTo} = startTransitionConfigs[startTransitionType] || {};
  const { endTo: transitionEndTo } = endTransitionConfigs[endTransitionType] || {};
  const startFrom = { ...defaultValues, ...transitionStartFrom };
  const startTo = { ...defaultValues, ...transitionStartTo };
  const endTo = { ...defaultValues, ...transitionEndTo };


  
  const [propertySprings, setPropertySprings] = useState({});
  const [animationStates, setAnimationStates] = useState({});


  const [startToAnimationDuration, setStartToAnimationDuration] = useState(0); // used for word timings
  const [endToAnimationDuration, setEndToAnimationDuration] = useState(0); // used for word timings


  useEffect(() => {
    const newPropertySprings = {};
    const newAnimationStates = {};
    // Initialize the springs with 'startFrom' values
    Object.keys(startFrom).forEach(property => {
      newPropertySprings[property] = new SpringAnimator(
        animationParams.mass,
        animationParams.stiffness,
        animationParams.damping,
        startFrom[property]
      );
      newAnimationStates[property] = new Array(Math.round(calculationWindowEnd) * FPS).fill(startFrom[property])
    });

    const calcStartToAnimationDuration = simulateAnimationDuration(startFrom, startTo, animationParams, FPS);
    setStartToAnimationDuration(calcStartToAnimationDuration);

    // Calculate the animation duration for the end transition
    const calcEndToAnimationDuration = simulateAnimationDuration(startTo, endTo, animationParams, FPS);    
    setEndToAnimationDuration(calcEndToAnimationDuration)

    // Adjust the 'endInitiateAnimationTime' so that 'endTo' finishes at 'globalEndTime'
    const endInitiateAnimationTime = localEndTime - calcEndToAnimationDuration;


    // Simulate the entire animation sequence
    for (let frame = 0; frame < calculationWindowEnd * FPS; frame++) {
      const simulationLocalTime = frame / FPS;

      // Animate from 'startFrom' to 'startTo'
      if (simulationLocalTime >= localStartTime && simulationLocalTime < endInitiateAnimationTime) {
        Object.keys(startTo).forEach(property => {          
          newPropertySprings[property].setTarget(startTo[property]);
        });
      }

      // Start the 'endTo' animation at the 'endInitiateAnimationTime'
      if (simulationLocalTime >= endInitiateAnimationTime && simulationLocalTime <= localEndTime) {
        Object.keys(endTo).forEach(property => {
          newPropertySprings[property].setTarget(endTo[property]);
        });
      }

      // Simulate the springs for all properties
      Object.keys(newPropertySprings).forEach(property => {
        const spring = newPropertySprings[property];
        const isOpacity = property === 'opacity'
        spring.simulate(1000 / FPS, isOpacity, 0, 1); // Enable clamping only for opacity
        newAnimationStates[property][frame] = spring.position;
      });
    }

    // const end = performance.now();
    // const duration = end - start;
    // console.log(`Slide animation precalculation time: ${duration} milliseconds`);
    setPropertySprings(newPropertySprings);
    setAnimationStates(newAnimationStates);

  }, [duration,startTransitionType,endTransitionType, JSON.stringify(animationParams)]); 





  // Switch from conditional rendering to force override
 // const isHidden = globalTime <= globalStartTime || globalTime >= globalEndTime;
  const isHidden = globalTime < globalStartTime || globalTime >= globalEndTime; //CHANGED FEB 23rd so text slide shows at t0
  const isBGHidden = globalTime < globalStartTime || globalTime > globalEndTime;


  
  //
  // Animation Grouping Types

  const animationGrouping = clip.metadata.animationGrouping  

  //
  // Per Word calculations
  const [wordStartTimes, setWordStartTimes] = useState([]);
  const [wordIsHidden, setWordIsHidden] = useState([]);
  const [allWordsSyncAnimation, setAllWordsSyncAnimation] = useState(false);

  

  useEffect(() => {
    if (animationGrouping === 'word' && startToAnimationDuration) {
      let calculatedWordStartTimes = clip.wordsArray.map((_, index) => index * startToAnimationDuration * wordAnimateOverlapRatio);      
     
      setWordStartTimes(calculatedWordStartTimes);

      const calculatedWordIsHidden = calculatedWordStartTimes.map(startTime => 
        globalTime <= (globalStartTime + startTime) || globalTime > globalEndTime
      );
      setWordIsHidden(calculatedWordIsHidden);



      // Calculate the trigger time for synchronization
      const maxStartTime = Math.max(...calculatedWordStartTimes);
      const syncTriggerTime = globalStartTime + maxStartTime + startToAnimationDuration + 0.1;

      if (globalTime >= syncTriggerTime) {
        setAllWordsSyncAnimation(true);
      } else {
        setAllWordsSyncAnimation(false);
      }
    }
  }, [startToAnimationDuration, clip.wordsArray, animationGrouping, globalStartTime, globalEndTime, globalTime, motionStyle]);  

  // sets clip property here
  // final wordStartTime + startToAnimationDuration

  
  //
  // Per Line Calculations  
  const [lineStartTimes, setLineStartTimes] = useState([]);
  const [lineIsHidden, setLineIsHidden] = useState([]);
  const [allLinesSyncAnimation, setAllLinesSyncAnimation] = useState(false);  

  useEffect(() => {
  if (animationGrouping === 'line' && startToAnimationDuration) {
    // Calculate unique lines and their start times
    const lines = calculateLineStartTimes(clip.wordsArray, startToAnimationDuration, lineAnimateOverlapRatio);
    setLineStartTimes(lines.map(line => line.startTime));

    // Determine visibility of each line
    const calculatedLineIsHidden = lines.map(line => 
      globalTime <= (globalStartTime + line.startTime) || globalTime > globalEndTime
    );
    setLineIsHidden(calculatedLineIsHidden);

    // Calculate trigger time for synchronization of lines
    const maxStartTime = Math.max(...lines.map(line => line.startTime));
    const syncTriggerTime = globalStartTime + maxStartTime + startToAnimationDuration + 0.1;

    if (globalTime >= syncTriggerTime) {
      setAllLinesSyncAnimation(true);
    } else {
      setAllLinesSyncAnimation(false);
    }
  }
}, [startToAnimationDuration, clip.wordsArray, animationGrouping, globalStartTime, globalEndTime, globalTime, motionStyle]);
  

  // Function to calculate unique lines and their start times
  // Function to calculate unique lines and their start times
  function calculateLineStartTimes(wordsArray, duration, lineAnimateOverlapRatio) {
    let lines = [];
    let currentLineIndex = -1;
    let currentTime = 0;

    wordsArray.forEach((word, index) => {
      if (word.paragraphIndex !== currentLineIndex) {
        currentLineIndex = word.paragraphIndex;
        if (index !== 0) { // Ensure we don't adjust the start time for the very first line
          currentTime += duration * lineAnimateOverlapRatio;
        }
        lines.push({ startTime: currentTime, words: [word] });
      } else {
        lines[lines.length - 1].words.push(word);
      }
    });

    return lines;
  }

    
  const backgroundId = clip.metadata.backgroundId
  let textSlideBackground = projectBackground
  const isNoneBGColor = backgroundId =='none'
  // console.log(`?? isNoneBGColor----- ${isNoneBGColor}`)

  if(backgroundId && !isNoneBGColor){
    textSlideBackground=getBackgroundForId(backgroundId)
  }

  //
  // Example Text Slide Background

  //let textSlideBackground=clip.metadata.slideBackgroundColor
  const textColorIsAuto = !clip.metadata.textColorId
  let textSlideTextColor 
  if(textColorIsAuto){
    textSlideTextColor = getTextColorForBackgroundId(textSlideBackground.id).rgba
  }else{
    textSlideTextColor = getTextColorForId(clip.metadata.textColorId).rgba
  }

  //console.log('OOOOOO')
  // console.log(`is hidden---- ${isHidden}`)

  return (
    <>

      {textSlideBackground && !isBGHidden && !isNoneBGColor &&
        <CanvasBackground zPosition={8} background={textSlideBackground} />    
      }
    

      {animationGrouping === 'everything' && !hideRenderedTextSlide && !isHidden &&
        <TextSlideContents
         // key={clip.id}
          time={globalTime - globalStartTime}
          FPS={FPS}
          animationStates={animationStates}
          initialProperties={startFrom}
          wordsArray={clip.wordsArray}
          fontFamily={clip.metadata.fontFamily}
          fontWeight={clip.metadata.fontWeight}
          fontSize={clip.metadata.fontSize}
          letterSpacing={clip.metadata.letterSpacing}
          isHidden={isHidden}
          textColor={textSlideTextColor}
          listType={clip.metadata.listType}
        />
      }

      {animationGrouping === 'line' && !hideRenderedTextSlide &&!isHidden &&
        <>
        <TextSlideLineAnimateContents
          //key={clip.id}
          time={globalTime - globalStartTime}
          FPS={FPS}
          animationStates={animationStates}
          initialProperties={startFrom}
          wordsArray={clip.wordsArray}
          fontFamily={clip.metadata.fontFamily}
          fontWeight={clip.metadata.fontWeight}
          letterSpacing={clip.metadata.letterSpacing}
          fontSize={clip.metadata.fontSize}
          lineIsHidden={lineIsHidden}
          textColor={textSlideTextColor}
          lineStartTimes={lineStartTimes}
          allLinesSyncAnimation={allLinesSyncAnimation}
        />
        {clip.metadata.listType && !isHidden &&
          <TextSlideLineListAnimateContents
           // key={clip.id+100}
            time={globalTime - globalStartTime}
            FPS={FPS}
            animationStates={animationStates}
            initialProperties={startFrom}
            wordsArray={clip.wordsArray}
            fontFamily={clip.metadata.fontFamily}
            fontWeight={clip.metadata.fontWeight}
            fontSize={clip.metadata.fontSize}
            letterSpacing={clip.metadata.letterSpacing}
            lineIsHidden={lineIsHidden}
            textColor={textSlideTextColor}
            lineStartTimes={lineStartTimes}
            allLinesSyncAnimation={allLinesSyncAnimation}
            listType={clip.metadata.listType}
          />
        }
        </>

      }


      {animationGrouping === 'word' && !hideRenderedTextSlide && !isHidden && 
        <>
        <TextSlideWordAnimateContents
          //key={clip.id}
          time={globalTime - globalStartTime}
          FPS={FPS}
          animationStates={animationStates}
          initialProperties={startFrom}
          wordsArray={clip.wordsArray}
          fontFamily={clip.metadata.fontFamily}
          fontWeight={clip.metadata.fontWeight}
          letterSpacing={clip.metadata.letterSpacing}
          fontSize={clip.metadata.fontSize}
          wordIsHidden={wordIsHidden}
          textColor={textSlideTextColor}
          wordStartTimes={wordStartTimes}
          allWordsSyncAnimation={allWordsSyncAnimation}
        />
          {clip.metadata.listType && 
            <TextSlideWordListAnimateContents
             // key={clip.id+100}
              time={globalTime - globalStartTime}
              FPS={FPS}
              animationStates={animationStates}
              initialProperties={startFrom}
              wordsArray={clip.wordsArray}
              fontFamily={clip.metadata.fontFamily}
              fontWeight={clip.metadata.fontWeight}
              fontSize={clip.metadata.fontSize}
              letterSpacing={clip.metadata.letterSpacing}
              wordIsHidden={wordIsHidden}
              textColor={textSlideTextColor}
              wordStartTimes={wordStartTimes}
              allWordsSyncAnimation={allWordsSyncAnimation}
              listType={clip.metadata.listType}
            />    
          }     

        </>
      }


    </>
  );    
  
};

export default TextSlide;

