import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { Text } from '@react-three/drei'
import renderOrders from '../../../renderOrders'

const CHRectLabel = ({ height, width, position, labelHeight = 50, minLabelWidth, borderLineWidth = 1, labelGapVerticalOffset = 0, labelGapHorizontalOffset = 0, labelText, frameOpacity, fontFamily, fontWeight, color}) => {
  
  
  const fontSize = 20

  const bottomCenterGroupRef = useRef();
  const topCenterGroupRef = useRef();
  const topLeftGroupRef = useRef();  
  const bottomLeftGroupRef = useRef();
  const leftGroupRef = useRef();  
  const rightGroupRef = useRef();  

  let labelWidth = Math.max(width, minLabelWidth);  

  if(position === 'left' || position === 'right'){
    labelWidth = minLabelWidth
  }    

  let overflowOffset = 0;

  // Left
  useEffect(() => {    
    if (leftGroupRef.current) {
      leftGroupRef.current.position.x = (labelGapHorizontalOffset * - 1) - borderLineWidth
      leftGroupRef.current.position.y = height / 2
    }
  }, [height, width, labelWidth, labelHeight, minLabelWidth, labelGapHorizontalOffset, labelGapVerticalOffset]); // Dependencies


  // Right
  useEffect(() => {    
    if (rightGroupRef.current) {
      rightGroupRef.current.position.x = width + labelGapHorizontalOffset +  - borderLineWidth
      rightGroupRef.current.position.y = height / 2 
    }
  }, [height, width, labelWidth, labelHeight, minLabelWidth, labelGapHorizontalOffset, labelGapVerticalOffset]); // Dependencies


  // Top Left
  useEffect(() => {    
      
    if (topLeftGroupRef.current) {
      topLeftGroupRef.current.position.x = labelGapHorizontalOffset - borderLineWidth
      topLeftGroupRef.current.position.y = height + labelHeight / 2 + labelGapVerticalOffset    
    }
  }, [height, width, labelWidth, labelHeight, minLabelWidth, labelGapHorizontalOffset, labelGapVerticalOffset]); // Dependencies

  // Top Center
  useEffect(() => {
      
    if (width < minLabelWidth && position === 'topCenter') {
      overflowOffset = (width - minLabelWidth) / 2;
    }
    if (topCenterGroupRef.current) {
      topCenterGroupRef.current.position.x = labelWidth / 2 + overflowOffset + borderLineWidth
      topCenterGroupRef.current.position.y = height + labelHeight / 2 + labelGapVerticalOffset    
    }
  }, [height, width, labelWidth, labelHeight, minLabelWidth, labelGapHorizontalOffset, labelGapVerticalOffset]); // Dependencies

  // Bottom Left
  useEffect(() => {    
    
    if (width < minLabelWidth && position === 'bottomCenter') {
      overflowOffset = (width - minLabelWidth) / 2;
    }
    if (bottomLeftGroupRef.current) {
      bottomLeftGroupRef.current.position.x = labelGapHorizontalOffset - borderLineWidth
      bottomLeftGroupRef.current.position.y = -labelHeight / 2 - labelGapVerticalOffset    
    }
  }, [height, labelText, width, labelWidth, labelHeight, minLabelWidth, labelGapVerticalOffset, labelGapHorizontalOffset]); // Dependencies

  // Bottom Center
  useEffect(() => {    
    if (width < minLabelWidth && position === 'bottomCenter') {
      overflowOffset = (width - minLabelWidth) / 2;
    }
    if (bottomCenterGroupRef.current) {
      bottomCenterGroupRef.current.position.x = labelWidth / 2 + overflowOffset + borderLineWidth
      bottomCenterGroupRef.current.position.y = -labelHeight / 2 - labelGapVerticalOffset    
    }
  }, [height, labelText, width, labelWidth, labelHeight, minLabelWidth, labelGapVerticalOffset, labelGapHorizontalOffset]); // Dependencies


  let labelColor = color

  

  return (
    <>
      {position === "left" &&
        <Text                            
          ref={leftGroupRef}
          anchorX="right"
          anchorY="middle"
          fontSize={fontSize}                
          textAlign="right"
          font={`../fonts/${fontFamily}/${fontFamily}-${fontWeight}.woff`}
          position={[1,1,0]}
          renderOrder={renderOrders.graphObject}
        >
          <meshBasicMaterial transparent={true} color={labelColor} opacity={frameOpacity} />
           {labelText}
        </Text>        
      }

      {position === "right" &&
        <Text                            
          ref={rightGroupRef}
          anchorX="left"
          anchorY="middle"
          fontSize={fontSize}                
          textAlign="left"
          font={`../fonts/${fontFamily}/${fontFamily}-${fontWeight}.woff`}
          position={[1,1,0]}
          renderOrder={renderOrders.graphObject}
        >
          <meshBasicMaterial transparent={true} color={labelColor} opacity={frameOpacity} />
           {labelText}
        </Text>        
      }
      


      {position === "topLeft" && labelColor &&
        <Text                       
          ref={topLeftGroupRef}
          anchorX="left"
          anchorY="middle"
          fontSize={fontSize}                
          textAlign="left"
          font={`../fonts/${fontFamily}/${fontFamily}-${fontWeight}.woff`}
          position={[1,1,0]}
          renderOrder={renderOrders.graphObject}
        >
          <meshBasicMaterial transparent={true} color={labelColor} opacity={frameOpacity} />
           {labelText}
        </Text>    

      }

      {position === "bottomLeft" && labelColor &&      
        <Text               
          ref={bottomLeftGroupRef}
          anchorX="left"
          anchorY="middle"
          fontSize={fontSize}                
          textAlign="left"
          font={`../fonts/${fontFamily}/${fontFamily}-${fontWeight}.woff`}
          position={[1,1,0]}
          renderOrder={renderOrders.graphObject}
        >
          <meshBasicMaterial transparent={true} color={labelColor} opacity={frameOpacity} />
           {labelText}
        </Text>    

      }

      {position === "topCenter" && labelColor && 
        <Text                      
          ref={topCenterGroupRef}
          anchorX="center"
          anchorY="middle"
          //fontSize={fontSize}                
          fontSize={32}
          textAlign="left"
          font={`../fonts/${fontFamily}/${fontFamily}-${fontWeight}.woff`}
          position={[1,1,0]}
          renderOrder={renderOrders.graphObject}
        >
          <meshBasicMaterial transparent={true} color={labelColor} opacity={frameOpacity} />
           {labelText}
        </Text> 
      }

      {position === "bottomCenter" && labelColor &&        

        <Text                    
          ref={bottomCenterGroupRef}
          anchorX="center"
          anchorY="middle"
          //fontSize={fontSize}
          fontSize={34}
          textAlign="left"
          font={`../fonts/${fontFamily}/${fontFamily}-${fontWeight}.woff`}
          position={[1,1,0]}
          renderOrder={renderOrders.graphObject}
        >
          <meshBasicMaterial transparent={true} color={labelColor} opacity={frameOpacity} />
           {labelText}
        </Text> 

      }
    </>
  );
};

export default CHRectLabel;
