const initialState = {
	recordings: {} // Format: { [captureId]: { files: { [fileName]: { status, progress } } } }
};

export default function(state = {}, action) {
	switch(action.type) {
		case 'SET_WEBCAM_FILE_TO_DOWNLOAD':
			const { captureId } = action;
			return {
				...state,
				[captureId]:'downloading'
			}

   case 'WEBCAM_DOWNLOAD_SUCCESS':
      const { captureId: successCaptureId } = action;
      return {
        ...state,
        [successCaptureId]: 'completed', // Update the status to 'completed' on successful download
      };


		default:
			return state;
	}
}
