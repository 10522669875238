const isSentenceEnd = (word) => {
  return word.endsWith('.') || word.endsWith('!') || word.endsWith('?');
};

const parseTranscript = (transcriptData, trimStart, trimEnd) => {
  // Filter words based on trim parameters if they exist
  let filteredWords = transcriptData.words;
  if (trimStart !== undefined || trimEnd !== undefined) {
    filteredWords = transcriptData.words.filter(word => {
      const start = word.start;
      const end = word.end;
      
      // If only trimStart is defined
      if (trimStart !== undefined && trimEnd === undefined) {
        return start >= trimStart;
      }
      // If only trimEnd is defined
      if (trimEnd !== undefined && trimStart === undefined) {
        return end <= trimEnd;
      }
      // If both are defined
      if (trimStart !== undefined && trimEnd !== undefined) {
        return start >= trimStart && end <= trimEnd;
      }
      return true;
    });
  }

  // Map the filtered words to the simplified format
  const words = filteredWords.map((word, index) => ({
    start: word.start,
    end: word.end,
    word: word.punctuated_word,
    index: index
  }));

  // Create sentence chunks
  const chunks = [];
  let currentChunk = {
    start: words[0]?.start || 0,
    end: 0,
    words: [],
    text: '',
    firstWordIndex: words[0]?.index || 0, // Add firstWordIndex
    lastWordIndex: 0 // Initialize lastWordIndex
  };

  words.forEach((word, index) => {
    currentChunk.words.push(word);
    currentChunk.end = word.end;
    currentChunk.lastWordIndex = word.index; // Update lastWordIndex with each word
    
    // If we hit a sentence end or it's the last word, complete the chunk
    if (isSentenceEnd(word.word) || index === words.length - 1) {
      currentChunk.text = currentChunk.words
        .map(w => w.word)
        .join(' ')
        .replace(/\s+([.,!?])/g, '$1');
      
      chunks.push(currentChunk);
      
      // Start a new chunk if there are more words
      if (index < words.length - 1) {
        currentChunk = {
          start: words[index + 1].start,
          end: 0,
          words: [],
          text: '',
          firstWordIndex: words[index + 1].index, // Set firstWordIndex for new chunk
          lastWordIndex: 0 // Initialize lastWordIndex for new chunk
        };
      }
    }
  });

  // Create the full transcript from all words
  const transcript = words.map(w => w.word).join(' ').replace(/\s+([.,!?])/g, '$1');
  
  const skippedWords=[]
  return {
    transcript,
    words,
    chunks,
    skippedWords
  };
};

export const getTranscriptForWebcamCaptureId = async (captureId, trimStart, trimEnd) => {
  if (window.isElectron) {
    const relativePath = `webcamRecordings/${captureId}/transcript.json`;
    try {
      const content = await ipcRenderer.invoke('read-file', relativePath);
      const data = JSON.parse(content);
      const parsedTranscript = parseTranscript(data.transcription, trimStart, trimEnd);
     // console.log('Parsed transcript:', parsedTranscript);
      return parsedTranscript;
    } catch (error) {
      console.error('Error reading or parsing transcript:', error);
      throw error;
    }
  }
  return null;
};