//calculate the minimum slide duration based on animation params
// min slide duration is enter animation time + exit time + buffer

import { simulateAnimationDuration } from './simulateAnimationDuration';
import { startTransitionConfigs } from '../../textSlide/transitionConfigs/startTransitionConfigs';
import { endTransitionConfigs } from '../../textSlide/transitionConfigs/endTransitionConfigs';
import {getAnimationParamsForMotionStyle} from './getAnimationParamsForMotionStyle'

const buffer = 0
const FPS = 60; // Frames per second

const defaultValues = {
  positionX: 0,
  positionY: 0,
  positionZ: 1,
  scale: 1,
  rotationX: 0,
  rotationY: 0,
  rotationZ: 0,
  opacity: 1,
};





const lineAnimateOverlapRatio = 0.85
const wordAnimateOverlapRatio = 0.3



export function calculateAnimationDurations(startTransitionType, endTransitionType,motionStyle) {  
  let startDuration 
  let endDuration

  //for active transition type get config values and modify default values with whatever properties are specified in config
  const configStartFrom =  startTransitionConfigs[startTransitionType].startFrom ||{}
  const configStartTo =  startTransitionConfigs[startTransitionType].startTo || {}
  const configEndTo = endTransitionConfigs[endTransitionType].endTo || {}
  
  const startFrom = { ...defaultValues, ...configStartFrom };
  const startTo = { ...defaultValues, ...configStartTo };
  const endTo = { ...defaultValues, ...configEndTo };
  const endFrom = startTo 

  const animationParams = getAnimationParamsForMotionStyle(motionStyle)
  startDuration = simulateAnimationDuration(startFrom, startTo, animationParams, FPS);
  endDuration = simulateAnimationDuration(endFrom, endTo, animationParams, FPS);
  return {startDuration:startDuration,endDuration:endDuration}

}




export function calculateDurationByEverything(startTransitionType, endTransitionType,motionStyle) {  
  // console.log('by everything----')
  // console.log(motionStyle)
  
  const everythingGroupingDurations = calculateAnimationDurations(startTransitionType, endTransitionType, motionStyle) 

  return everythingGroupingDurations

}


export function calculateDurationByWord(startTransitionType, endTransitionType,motionStyle,wordsArray) {  
  // run the calculation
  // find final duration as the index of the final word * duration * ratio
  const perWordDurations = calculateAnimationDurations(startTransitionType, endTransitionType,motionStyle)   
  const perWordStartToDuration = perWordDurations.startDuration
  const calculatedWordStartTimes = wordsArray.map((_, index) => index * perWordStartToDuration * wordAnimateOverlapRatio);
  const lastWordStartTime = Math.max(...calculatedWordStartTimes)
  const totalStartDuration = lastWordStartTime + perWordStartToDuration + 0.1
  return {startDuration:totalStartDuration,endDuration:perWordDurations.endDuration}
}



function calculateLineCount(wordsArray) {  
  let currentLineIndex = -1;
  let lineCount = 0
  wordsArray.forEach((word, index) => {
    if (word.paragraphIndex !== currentLineIndex) {
      currentLineIndex = word.paragraphIndex;
      lineCount +=1
    }
  });

  return lineCount;
}


export function calculateDurationByLine(startTransitionType, endTransitionType,motionStyle,wordsArray) {  
  // run the calculation
  // find final duration as the index of the final word * duration * ratio
  const perLineDurations = calculateAnimationDurations(startTransitionType, endTransitionType,motionStyle)   
  // console.log('per line durations')
  // console.log(perLineDurations)

  const perLineStartToDuration = perLineDurations.startDuration
  const lineCount = calculateLineCount(wordsArray)
  // console.log(`line count---- ${lineCount}`)
  const totalStartDuration = (lineCount-1) * perLineStartToDuration * lineAnimateOverlapRatio + perLineStartToDuration

  return {startDuration:totalStartDuration,endDuration:perLineDurations.endDuration}
}





export function calculateAnimationDurationsForClip(clip){
  const motionStyle=clip.metadata.motionStyle
  // console.log('ooooooo here')
  // console.log(motionStyle)
  const {wordsArray}=clip
  const {startTransitionType,endTransitionType,animationGrouping}=clip.metadata 
  if(animationGrouping=='everything'){
    return calculateDurationByEverything(startTransitionType, endTransitionType,motionStyle)
  }
  else if(animationGrouping=='line'){
    return calculateDurationByLine(startTransitionType, endTransitionType,motionStyle,wordsArray)
  }
  else if(animationGrouping=='word'){
    return calculateDurationByWord(startTransitionType, endTransitionType,motionStyle,wordsArray)
  }


}



