import findIndex from 'lodash/findIndex'




export default function (state = [], action) {
	switch (action.type) {
	

    case 'SIGN_OUT':
      return []
   
    case 'FETCH_ARCHIVED_SLIDE_TEMPLATES_SUCCESS':
      return action.response
      
   
    default:
      return state;
	}
}
