import React from 'react'
import Icon from '../../components/misc/Icon'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import {formatDuration} from '../../utils/dateFormatters/formatDuration'

class SceneHeaderRightContainer extends React.Component {  
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     isDropdownOpen: false
  //   };
  // }

  // handleDropdownOpenChange = (open) => {
  //   this.setState({ isDropdownOpen: open });
  // }

  render() {   

    const {sceneId,sceneDuration} = this.props
    return ( 
      <>
        <div className='editor-transcriptPanel-sceneHeader-duration'>
          {formatDuration(sceneDuration)}
        </div>
        
        <DropdownMenu.Root 
          key={sceneId}
          modal={false}
          open={this.props.isDropdownOpen}                    
          onOpenChange={this.props.toggleDropdown}
          //defaultOpen
        > 
          <DropdownMenu.Trigger asChild>
            <button className='editor-transcriptPanel-sceneHeader-dropdownBtn'>
              <Icon name='ellipses'/>
            </button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Portal>
            <DropdownMenu.Content             
              align="end"
              alignOffset={-10}
              className='dropdownMenu dropdownMenu--sceneHeader forceDarkTheme'
              // onEscapeKeyDown={()=>{this.props.toggleDropdown()}}
              // onPointerDownOutside={()=>{this.props.toggleDropdown()}}
              // onFocusOutside={()=>{this.props.toggleDropdown()}}
              // onInteractOutside={()=>{this.props.toggleDropdown()}}
            >          		
              <DropdownMenu.Item>
                <div className='dropdownMenu-item-iconContainer'>
                  <Icon name='folder' />
                </div>
                Save to Library              
              </DropdownMenu.Item>
              <DropdownMenu.Separator/>
              <DropdownMenu.Item>            	
                Add Scene above           
              </DropdownMenu.Item>              
              <DropdownMenu.Item>            	
              	Merge into Scene above  
              </DropdownMenu.Item>                           
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      </>        
    )
  }
}

export default SceneHeaderRightContainer