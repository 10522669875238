import { MD5 } from 'crypto-js';
// clipID_voiceID_textHash.mp3

export function createMD5Hash(text) {
  return MD5(text).toString();
}



export function createFileNameForAudioClip(clipId,voiceId,text){
 
  const hash = createMD5Hash(text)

  let name=`${clipId}_${voiceId}_${hash}`
  return name

}

