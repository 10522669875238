import React from 'react'
import Icon from '../../components/misc/Icon'



class TranscriptChunkRight extends React.Component{  
  render(){   
    const {requiresUpdate,transcriptChunkCount} = this.props
    
    const isOnlyClip = transcriptChunkCount<2


    return ( 
      <>
        <div className={'editor-transcriptPanel-transcriptChunk-right ' + (requiresUpdate ? ' editor-transcriptPanel-transcriptChunk-right--requiresUpdate ' : ' editor-transcriptPanel-transcriptChunk-right--ready ')}>      
          {/*<button className='editor-transcriptPanel-transcriptChunk-right-controlBtn'>
            <Icon name='chunkPreview' />
          </button>
          <button className='editor-transcriptPanel-transcriptChunk-right-controlBtn'>
            <Icon name='chunkRegen' />
          </button>       
          */}
        </div>
      </>

        

    )
  }
}

export default TranscriptChunkRight
