

const initialState = {
  copiedClip: null
};


export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_COPIED_CLIP':
      return {
        ...state,
        copiedClip: action.payload
      };
    default:
      return state;
  }
};

