import { SpringAnimator } from '../animations/SpringAnimator';
// import { getCursorPositionAtTime } from '../getCursorPositionAtTime';
import { convertZoomValuesToZoomBox } from './convertZoomValuesToZoomBox';
import { calculateManualZoomSequence } from './manualZoomSequence';
// import { calculateAutoZoomSequence } from './autoZoomSequence';
import { simulateAnimationDuration } from '../animations/simulateAnimationDuration';

const FPS = 60; // Frames per second

const defaultCameraBox = {
    width: 1920,
    height: 1080,
    x: 0,
    y: 0,
    motionSettings: 'zoomSmooth',
};

// Animation parameters for different motion settings
const animationParamsMap = {
  zoomSmooth: { mass: 1, stiffness: 120, damping: 30, precision: 10, instant: false },
  zoomZippy: { mass: 1, stiffness: 220, damping: 28, precision: 10, instant: false },
  zoomSlow: { mass: 1, stiffness: 120, damping: 80, precision: 10, instant: false },
  zoomInstant: { mass: 1, stiffness: 120, damping: 80, precision: 10, instant: true } // Set instant to true for zoomInstant
};


export const calculateCameraBoxSequence = (zoomClips) => {
    const cameraBoxSequence = {};
    const bufferTime = 0.25; // buffer time in seconds
    let lastEndTime = 0; // Tracks the end time of the last clip

    // Sort the clips by their start time
    const sortedZoomClips = zoomClips.sort((a, b) => a.startTime - b.startTime);

    sortedZoomClips.forEach((zoomClip, index) => {
        const { startTime, duration, metadata: { zoomValues } } = zoomClip;
        let originalEndTime = startTime + duration;

        const endMotionSettings = zoomValues.endMotionSettings || zoomValues.motionSettings || 'zoomSmooth';

        // Calculate initial camera box
        // let initialCameraBox = zoomValues.originX === 'auto' && zoomValues.originY === 'auto'
        //   ? convertZoomValuesToZoomBox({originX: getCursorPositionAtTime(startTime).x, originY: getCursorPositionAtTime(startTime).y * -1, scale: zoomValues.scale})
        //   : convertZoomValuesToZoomBox({originX: zoomValues.originX, originY: zoomValues.originY, scale: zoomValues.scale});

        let initialCameraBox = convertZoomValuesToZoomBox({originX: zoomValues.originX, originY: zoomValues.originY, scale: zoomValues.scale});

        let durations = ['x', 'y', 'width', 'height'].map(property => {
            const startValueObj = { [property]: defaultCameraBox[property] };
            const endValueObj = { [property]: initialCameraBox[property] };
            return simulateAnimationDuration(startValueObj, endValueObj, animationParamsMap[zoomValues.motionSettings], FPS);
        });

        let maxAnimationDuration = Math.max(...durations);
        let adjustedStartTime = Math.max(startTime - (maxAnimationDuration * 0.8), lastEndTime + (1 / FPS));

        // Add default camera box before the start of the clip if there's enough gap
        if (adjustedStartTime - lastEndTime > bufferTime) {
            cameraBoxSequence[adjustedStartTime - (1 / FPS)] = { ...defaultCameraBox, motionSettings: 'zoomSmooth' };
        }

        // let sequence = zoomValues.originX === 'auto' && zoomValues.originY === 'auto'
        //   ? calculateAutoZoomSequence(zoomClip, adjustedStartTime)
        //   : calculateManualZoomSequence({...zoomClip, startTime: adjustedStartTime});

        let sequence = calculateManualZoomSequence({...zoomClip, startTime: adjustedStartTime});

        Object.assign(cameraBoxSequence, sequence);

        // Adjust the end time of the previous clip if needed
        const nextClip = sortedZoomClips[index + 1];
        if (nextClip && originalEndTime > nextClip.startTime) {
            originalEndTime = nextClip.startTime - (1 / FPS);
        }

        // Check if there's enough gap after the zoom clip for a default camera box
        if (!nextClip || originalEndTime + bufferTime <= nextClip.startTime) {
            cameraBoxSequence[originalEndTime + (1 / FPS)] = { ...defaultCameraBox, motionSettings: endMotionSettings };
        }

        lastEndTime = originalEndTime;
    });

    // Add default camera box at time 0 if no clip at the beginning
    if (Object.keys(cameraBoxSequence).every(key => parseFloat(key) >= 0.05)) {
        cameraBoxSequence[0] = { ...defaultCameraBox };
    }

    // console.log('cameraBoxSequence')
    // console.log(cameraBoxSequence)
    
    return cameraBoxSequence;
};
