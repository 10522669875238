import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, matchPath } from 'react-router-dom';
import {createNewProject} from '../utils/projectUtils/createNewProject'

import {closeProject} from '../utils/projectUtils/closeProject'
import {removeTab} from '../actions/tabs'


const AppShortcuts = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // Add necessary selectors here

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.metaKey && e.key === 'n') {
        e.preventDefault();
        createNewProject(history)
      }

      if (e.metaKey && e.key === 'w') {
        e.preventDefault();
        
        const match = matchPath(location.pathname, {
          path: '/project/:id',
          exact: true
        });

        if (match) {
          const projectId = match.params.id;
          console.log('Current project ID:', projectId);
          dispatch(removeTab(projectId,history))
        } else {
          console.log('Not on a project page');
        }
      }

      
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [history, dispatch]);

  return null;
};

export default AppShortcuts;