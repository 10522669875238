import {randomID} from '../../../../../utils/randomID'

export const updateClipIndexAndSceneCounts = (state) => {
  let tr = state.tr;
  let totalChunkCount = 0;
  let sceneChunkCount = 0;
  let totalSceneCount = 0;
  let currentSceneId = null;
  let sceneHeaderPositions = [];

  //Count total chunks, scenes, and update transcript chunks
  
  state.doc.descendants((node, pos) => {
    if (node.type.name === "sceneHeader") {
      totalSceneCount++;
      sceneHeaderPositions.push({ pos, sceneId: node.attrs.sceneId });
      if (currentSceneId !== null) {
        tr = updateSceneChunks(tr, currentSceneId, sceneChunkCount);
      }
      currentSceneId = node.attrs.sceneId;
      sceneChunkCount = 0;
    } else if (node.type.name === "transcriptChunk" || node.type.name=='webcamRecordingChunk') {
      totalChunkCount++;
      sceneChunkCount++;
      tr = updateTranscriptChunk(tr, node, pos, sceneChunkCount, totalChunkCount, currentSceneId);
    }
  });
  // Update the last scene's chunks
  if (currentSceneId !== null) {
    tr = updateSceneChunks(tr, currentSceneId, sceneChunkCount);
  }
  //update all scene headers with the total scene count
  sceneHeaderPositions.forEach(({ pos, sceneId }) => {
    tr = updateSceneHeader(tr, pos, sceneId, totalSceneCount);
  });
  return tr;
};



const updateTranscriptChunk = (tr, node, pos, sceneChunkCount, totalChunkCount, sceneId) => {
  const needsUpdate = 
    node.attrs.transcriptChunkIndex !== sceneChunkCount - 1 || 
    node.attrs.transcriptChunkCount !== totalChunkCount ||
    node.attrs.sceneId !== sceneId ||
    node.attrs.clipId === 'defaultID';

  if (needsUpdate) {
    const updatedAttrs = {
      ...node.attrs,
      transcriptChunkIndex: sceneChunkCount - 1,
      transcriptChunkCount: totalChunkCount,
      sceneId: sceneId
    };

    // Generate new ID if it's the default
    if (node.attrs.clipId === 'defaultID') {
      updatedAttrs.clipId = randomID();
    }

    return tr.setNodeMarkup(pos, null, updatedAttrs);
  }
  return tr;
};



const updateSceneChunks = (tr, sceneId, chunkCount) => {
  tr.doc.descendants((node, pos) => {
    if (node.type.name === "transcriptChunk" && node.attrs.sceneId === sceneId) {
      if (node.attrs.sceneChunkCount !== chunkCount) {
        tr = tr.setNodeMarkup(pos, null, {
          ...node.attrs,
          sceneChunkCount: chunkCount
        });
      }
    }
  });
  return tr;
};

const updateSceneHeader = (tr, pos, sceneId, totalSceneCount) => {
  return tr.setNodeMarkup(pos, null, {
    sceneId: sceneId,
    sceneCount: totalSceneCount
  });
};