import React, { useState } from 'react';
import LibraryProjectCard from './projectItem/LibraryProjectCard';

const COLUMN_GAP = 15; // Column gap between cards
const MAX_CARD_WIDTH = 380; // Define the maximum card width

function LibraryProjectGalleryGrid({ projects, containerWidth }) {
const projectCardWidth = getProjectCardWidth(containerWidth, MAX_CARD_WIDTH, COLUMN_GAP);


const [isMuted, setIsMuted] = useState(true);



return (
  <div
    className='libraryPage-projectGallery libraryPage-projectGallery--grid'
    style={{
      display: 'flex',
      flexWrap: 'wrap',
      gap: `${COLUMN_GAP}px`,
      rowGap: `15px`
    }}
  >

    {projects.map(project => (

      <LibraryProjectCard
        key={project.id}
        project={project}
        width={projectCardWidth}
        // id={project.id}
        // name={project.name}
        // duration={project.duration}
        // editedTimestamp={project.editedTimestamp}
        // collectionIds={project.collectionIds}
        // isDraft={project.isDraft}
        
        // isMuted={isMuted}
        // toggleMuted={() => setIsMuted(!isMuted)}

      />
    ))}
  </div>
);
}

function getProjectCardWidth(containerWidth, maxCardWidth, columnGap) {
  const effectiveWidth = containerWidth;
  let numCards = Math.floor((effectiveWidth + columnGap) / (maxCardWidth + columnGap));

  if (numCards < 1) {
      numCards = 1;
  }

  const totalGapWidth = (numCards - 1) * columnGap;
  return (effectiveWidth - totalGapWidth) / numCards;
}

export default LibraryProjectGalleryGrid;
