
const SlideClipNodeSpec = {
  attrs: {
    id: { default: 'defaultID' },
    type: { default: 'slide' },
    relativeStartTime: { default: 0 },
    duration: { default: 0 },
    metadata: { default: {} },
    zIndex: { default: 1 },
    sceneId: { default: 'defaultSceneId' }
  },
  content: "(layoutGroup | slideElement)*", 
};


 export default SlideClipNodeSpec
 