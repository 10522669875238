import store from '../store'


export function getOrgDomain() {	
	const state = store.getState()
	let domain
	const organization=state.organization
	if(organization){
		domain=organization.domain
	}
	return domain 
}
