import moment from 'moment';

export const formatTimestamp = (timestamp) => {
  const now = moment();
  const time = moment(timestamp);
  const diffMinutes = now.diff(time, 'minutes');
  const diffHours = now.diff(time, 'hours');
  const diffDays = now.diff(time, 'days');
  
  // Just now (less than a minute ago)
  if (diffMinutes < 1) {
    return 'Just now';
  }
  
  // Minutes format (1-59 minutes)
  if (diffMinutes < 60) {
    return `${diffMinutes} minutes ago`;
  }
  
  // Hours format (1-23 hours)
  if (diffHours < 24) {
    return diffHours === 1 ? '1 hour ago' : `${diffHours} hours ago`;
  }
  
  // Within the last 6 days - show day name and time
  if (diffDays < 7) {
    return time.format('dddd h:mmA').replace(':00', '');
  }
  
  // Older than 6 days - show abbreviated day name and month/day
  return time.format('ddd MMM D');
};
