import React, { useRef, useEffect, useState, useContext } from 'react';
import { SpringAnimator } from '../../three/utils/animations/SpringAnimator';
import { startTransitionConfigs } from './textTransitionConfigs/startTransitionConfigs';
import { endTransitionConfigs } from './textTransitionConfigs/endTransitionConfigs';
import { simulateAnimationDuration } from '../../three/utils/animations/simulateAnimationDuration'; // Import the utility function
import {calculateMinSlideDuration} from '../../three/utils/animations/calculateMinSlideDuration'
import {getAnimationParamsForMotionStyle} from '../utils/animations/getAnimationParamsForMotionStyle'

import {getBackgroundForId} from '../../utils/brands/getBackgroundForId'
import {getTextColorForBackgroundId} from '../../utils/brands/getTextColorForBackgroundId'
import {getTextColorForId} from '../../utils/brands/getTextColorForId'

import SlideTextElementMeshes from './SlideTextElementMeshes'
import { calculateLetterStartTimes } from '../utils/calculateLetterStartTimes'; // Update the path as needed


const FPS = 60; // Frames per second

const defaultValues = {
  positionX: 0,
  positionY: 0,
  positionZ: 0,
  scale: 1,
  rotationX: 0,
  rotationY: 0,
  rotationZ: 0,
  opacity: 1,
};
  
function logObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}

const SCENE_WIDTH = 1920;
const SCENE_HEIGHT = 1080;

const SlideTextElement = ({textElement,time, hideRenderedTextSlide,projectBackground,slideBackgroundId,showSlideTextStatic, focusedSlideElement, slideVisible}) => {
  
  let globalStartTime = textElement.startTime  

  const globalEndTime = textElement.endTime
  const globalTime = time
  const duration = (textElement.endTime - globalStartTime).toFixed(4)
  const startTransitionType = textElement.metadata.startTransitionType
  const endTransitionType = textElement.metadata.endTransitionType

  const localTime = time - globalStartTime // time since start of slide
  const localStartTime = 0
  const localEndTime = globalEndTime - globalStartTime
  const calculationWindowEnd = localEndTime + 0.2  

  const motionStyle=textElement.metadata.motionStyle // not really using this anymore, should just be smooth, but might be some legacy content out there
  

  let adjustedSpeed = 0
  let adjustedBounciness = 0
  if(textElement.metadata.enterTransitionSpeedFactor){
    adjustedSpeed = textElement.metadata.enterTransitionSpeedFactor
  }
  if(textElement.metadata.enterTransitionBouncinessFactor){
    adjustedBounciness = textElement.metadata.enterTransitionBouncinessFactor
  }

  const animationParams=getAnimationParamsForMotionStyle(motionStyle, adjustedSpeed, adjustedBounciness)

  let scalar = 1
  if(textElement.metadata.enterTransitionValueFactor){
    scalar = textElement.metadata.enterTransitionValueFactor + 1 // convert to scalar
  }

  const { startFrom: transitionStartFrom, startTo: transitionStartTo} = (startTransitionConfigs[startTransitionType] || (() => ({})))(scalar);
  const { endTo: transitionEndTo } = endTransitionConfigs[endTransitionType] || {};
  
  const startFrom = { 
    ...defaultValues, 
    ...transitionStartFrom,    
  };

  const startTo = { 
    ...defaultValues, 
    ...transitionStartTo,    
  };

  const endTo = { 
    ...defaultValues, 
    ...transitionEndTo,    
  };

  const [propertySprings, setPropertySprings] = useState({});
  const [animationStates, setAnimationStates] = useState({});


  const [startToAnimationDuration, setStartToAnimationDuration] = useState(0); // used for word timings
  const [endToAnimationDuration, setEndToAnimationDuration] = useState(0); // used for word timings



  let initialProperties = startFrom
  if(showSlideTextStatic){
    initialProperties = defaultValues
  }


  useEffect(() => {
    const newPropertySprings = {};
    const newAnimationStates = {};
    // Initialize the springs with 'startFrom' values
    Object.keys(startFrom).forEach(property => {
      newPropertySprings[property] = new SpringAnimator(
        animationParams.mass,
        animationParams.stiffness,
        animationParams.damping,
        startFrom[property]
      );
      newAnimationStates[property] = new Array(Math.round(calculationWindowEnd) * FPS).fill(startFrom[property])
    });

    const calcStartToAnimationDuration = simulateAnimationDuration(startFrom, startTo, animationParams, FPS);
    setStartToAnimationDuration(calcStartToAnimationDuration);

    // Calculate the animation duration for the end transition
    const calcEndToAnimationDuration = simulateAnimationDuration(startTo, endTo, animationParams, FPS);    
    setEndToAnimationDuration(calcEndToAnimationDuration)

    // Adjust the 'endInitiateAnimationTime' so that 'endTo' finishes at 'globalEndTime'
    const endInitiateAnimationTime = localEndTime - calcEndToAnimationDuration;


    // Simulate the entire animation sequence
    for (let frame = 0; frame < calculationWindowEnd * FPS; frame++) {
      const simulationLocalTime = frame / FPS;

      // Animate from 'startFrom' to 'startTo'
      if (simulationLocalTime >= localStartTime && simulationLocalTime < endInitiateAnimationTime) {
        Object.keys(startTo).forEach(property => {          
          newPropertySprings[property].setTarget(startTo[property]);
        });
      }

      // Start the 'endTo' animation at the 'endInitiateAnimationTime'
      if (simulationLocalTime >= endInitiateAnimationTime && simulationLocalTime <= localEndTime) {
        Object.keys(endTo).forEach(property => {
          newPropertySprings[property].setTarget(endTo[property]);
        });
      }

      // Simulate the springs for all properties
      Object.keys(newPropertySprings).forEach(property => {
        const spring = newPropertySprings[property];
        const isOpacity = property === 'opacity'
        spring.simulate(1000 / FPS, isOpacity, 0, 1); // Enable clamping only for opacity
        newAnimationStates[property][frame] = spring.position;
      });
    }

    setPropertySprings(newPropertySprings);
    setAnimationStates(newAnimationStates);

  }, [duration,startTransitionType,endTransitionType, JSON.stringify(animationParams)], scalar, adjustedSpeed, adjustedBounciness); 


  // Switch from conditional rendering to force override
  // const isHidden = globalTime <= globalStartTime || globalTime >= globalEndTime;
  let isHidden = globalTime < globalStartTime || globalTime >= globalEndTime; //CHANGED FEB 23rd so text slide shows at t0

  if(showSlideTextStatic && slideVisible){
    isHidden = false
  }

  const isBGHidden = globalTime < globalStartTime || globalTime > globalEndTime;
  
  //
  // Animation Grouping Types
  const animationGrouping = textElement.metadata.animationGrouping  

  //
  // Per Letter calculations
  const [letterStartTimes, setLetterStartTimes] = useState([]);
  const [letterIsHidden, setLetterIsHidden] = useState([]);
  const [allLettersSyncAnimation, setAllLettersSyncAnimation] = useState(false);

  // console.log('textElement.metadata')
  // console.log(textElement.metadata)

  let perWordAnimateOverlapRatio = animationParams.perWordAnimateOverlapRatio
  if(textElement.metadata.wordEnterDelay){
    perWordAnimateOverlapRatio = textElement.metadata.wordEnterDelay
  }
  let perLineAnimateOverlapRatio = animationParams.perLineAnimateOverlapRatio
  if(textElement.metadata.lineEnterDelay){
    perLineAnimateOverlapRatio = textElement.metadata.lineEnterDelay
  }
  



  useEffect(() => {
    if (startToAnimationDuration && textElement.metadata.lettersArray) {
      
      const calculatedLetterStartTimes = calculateLetterStartTimes(
        textElement.metadata.lettersArray,
        startToAnimationDuration,        
        animationGrouping,
        perWordAnimateOverlapRatio,
        perLineAnimateOverlapRatio
      );

      setLetterStartTimes(calculatedLetterStartTimes);

      // Calculate visibility for each letter
      const calculatedLetterIsHidden = calculatedLetterStartTimes.map(startTime => 
        globalTime < (globalStartTime + startTime) || globalTime > globalEndTime
      );

      setLetterIsHidden(calculatedLetterIsHidden);    


      

      // Calculate the trigger time for synchronization (placeholder value for now)
      const maxStartTime = Math.max(...calculatedLetterStartTimes);
      const syncTriggerTime = globalStartTime + maxStartTime + startToAnimationDuration + 0.1;

      if (globalTime >= syncTriggerTime) {
        setAllLettersSyncAnimation(true);
      } else {
        setAllLettersSyncAnimation(false);
      }
    }
  }, [startToAnimationDuration, textElement.metadata.lettersArray, animationGrouping, globalStartTime, globalEndTime, globalTime, perWordAnimateOverlapRatio, perLineAnimateOverlapRatio]);


  const backgroundId = slideBackgroundId
  let textSlideBackground = projectBackground
  if(backgroundId && backgroundId!=='none'){
    textSlideBackground=getBackgroundForId(backgroundId)
  }

  //let textSlideBackground=clip.metadata.slideBackgroundColor
  const textColorIsAuto = !textElement.metadata.textColorId
  let textSlideTextColor 
  if(textColorIsAuto){
    textSlideTextColor = getTextColorForBackgroundId(textSlideBackground.id).rgba
  }else{
    textSlideTextColor = getTextColorForId(textElement.metadata.textColorId).rgba
  }

  // console.log(textElement.metadata.lettersArray)
  // To-do — add bullet points and numbers at appropriate places
  // probably find paragraph index words,

  let textStyle
  if(textElement.metadata.textStyle){
    textStyle = textElement.metadata.textStyle
  }
  
  let textProperties = textElement.metadata.textProperties


  const zIndex = textElement.zIndex

  const isFocused = focusedSlideElement === textElement.id

  const textElementOpacity = textElement.metadata.textOpacity
  
    // Calculate the group's position in Three.js coordinates
  const normalizedElementX = textElement.x + textElement.width / 2 - SCENE_WIDTH / 2;
  const normalizedElementY = -textElement.y - textElement.height / 2 + SCENE_HEIGHT / 2;

  return (
    <>

    <group position={[normalizedElementX, normalizedElementY, 0]}>

      {!hideRenderedTextSlide && !isHidden && textElement.metadata.lettersArray && textProperties && !isFocused &&
        <>
          <SlideTextElementMeshes
            time={globalTime - globalStartTime}
            FPS={FPS}
            animationStates={animationStates}
            initialProperties={initialProperties}
            lettersArray={textElement.metadata.lettersArray}
            fontFamily={textProperties.fontFamily}
            fontWeight={textProperties.fontWeight}
            letterSpacing={textProperties.letterSpacing}
            fontSize={textProperties.fontSize}
            letterIsHidden={letterIsHidden}
            textColor={textSlideTextColor}
            letterStartTimes={letterStartTimes}
            allLettersSyncAnimation={allLettersSyncAnimation}            
            zIndex={zIndex}
            defaultValues={defaultValues}
            showSlideTextStatic={showSlideTextStatic}
            slideVisible={slideVisible}
            textElementOpacity={textElementOpacity}
            textElementX={textElement.x}
            textElementY={textElement.y}
          />          
        </>
      }

      </group>

    </>
  );    
  
};

export default SlideTextElement;

