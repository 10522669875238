//const SNAP_THRESHOLD = 15;

const SNAP_THRESHOLD = 15;

export const calculateSnappedPosition = (item, x, y, deltaX, deltaY, snapLines, isLayoutGroup = false) => {
  let snappedX = x;
  let snappedY = y;
  let snapType = null;

  const isWithinThreshold = (value, target) => Math.abs(value - target) <= SNAP_THRESHOLD;
  
  const leftEdge = x;
  const rightEdge = x + item.width;
  const topEdge = y;
  const bottomEdge = y + item.height;
  const centerX = x + item.width / 2;
  const centerY = y + item.height / 2;

  // Check vertical snap
  for (const line of snapLines.vertical) {
    if (isWithinThreshold(leftEdge, line.position)) {
      snappedX = line.position;
      snapType = 'left';
      break;
    }
    if (isWithinThreshold(rightEdge, line.position)) {
      snappedX = line.position - item.width;
      snapType = 'right';
      break;
    }
    if (isWithinThreshold(centerX, line.position)) {
      snappedX = line.position - item.width / 2;
      snapType = 'center-x';
      break;
    }
  }

  // Check horizontal snap
  for (const line of snapLines.horizontal) {
    if (isWithinThreshold(topEdge, line.position)) {
      snappedY = line.position;
      snapType = snapType ? `${snapType}-top` : 'top';
      break;
    }
    if (isWithinThreshold(bottomEdge, line.position)) {
      snappedY = line.position - item.height;
      snapType = snapType ? `${snapType}-bottom` : 'bottom';
      break;
    }
    if (isWithinThreshold(centerY, line.position)) {
      snappedY = line.position - item.height / 2;
      snapType = snapType ? `${snapType}-center-y` : 'center-y';
      break;
    }
  }

  if (isLayoutGroup) {
    const snappedDeltaX = snappedX - (x - deltaX);
    const snappedDeltaY = snappedY - (y - deltaY);
    return { snappedDeltaX, snappedDeltaY, snapType };
  } else {
    return { x: snappedX, y: snappedY, snapType };
  }
};