import React, { useState, useEffect, useRef } from 'react';
import Icon from '../../misc/Icon';
import * as Popover from '@radix-ui/react-popover';
import collectionColors from '../../../data/collectionColors'

function LibrarySideMenuCollectionTabPopover({ editTitle, setEditTitle, editColor, setEditColor,setPopoverOpen,collection }) {
  const [inputValue, setInputValue] = useState(editTitle);
  const [activeColor, setActiveColor] = useState(editColor); // Initialize active color from editColor
  const inputRef = useRef(null); // Ref for the input element

  useEffect(() => {
    setInputValue(editTitle);
  }, [editTitle]);

  useEffect(() => {
    setActiveColor(editColor); // Initialize active color from editColor
  }, [editColor]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select(); // Select all text in the input
    }
  }, []);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setEditTitle(e.target.value); // Update the label in the parent component
  };

  const handleColorChange = (color) => {
    setActiveColor(color);
    setEditColor(color); // Update the color in the parent component
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setPopoverOpen(false); // Close the popover when Enter is pressed
    }
    else if (e.key === 'Escape') {
      setEditTitle(collection.title); // Reset to original title
      setEditColor(collection.color); // Reset to original color
      setPopoverOpen(false); // Close the popover
    }
  };

    const handlePopoverClick = (e) => {
    e.stopPropagation();
  };



  return (
    <Popover.Content
      align="start"
      alignOffset={0}
      side="bottom"
      sideOffset={2}
      className='miniPopover miniPopover--editCollection forceDarkTheme'
      onOpenAutoFocus={(e) => e.preventDefault()} // Prevent popover from stealing focus
      onClick={handlePopoverClick}
    >
      <div className='miniPopover--editCollection-inputContainer'>
        <input 
          ref={inputRef} // Attach the ref to the input
          className='miniPopover--editCollection-input'
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown} 
          maxLength={40}
        />
      </div>
      <div className='miniPopover--editCollection-colorBtnCarousel'>
        {collectionColors.map(color => (
          <button
            key={color}
            data-state={color === activeColor ? 'active' : 'inactive'}                        
             onClick={(e) => {
              e.preventDefault(); // Prevent default action
              handleColorChange(color);
            }}
            className={'miniPopover--editCollection-colorBtn ' + (color ? `miniPopover--editCollection-colorBtn--${color}` : '')}
            onMouseDown={(e) => e.preventDefault()}
          >
            <div className='miniPopover--editCollection-colorBtn-inner' />
            <div className='miniPopover--editCollection-colorBtn-outline' />
          </button>
        ))}
      </div>
    </Popover.Content>
  );
}

export default LibrarySideMenuCollectionTabPopover;
