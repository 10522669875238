import React, { useState } from 'react';
import Measure from 'react-measure';
import LibraryHomePageForYou from './LibraryHomePageForYou';
import LibraryHomePageAllVideos from './LibraryHomePageAllVideos';
import LibraryProjectGalleryGrid from './LibraryProjectGalleryGrid';
import LibraryProjectGalleryList from './LibraryProjectGalleryList';
import LibraryPageHeaderGalleryDisplay from './headers/LibraryPageHeaderGalleryDisplay';
import LibraryPageHeaderSort from './headers/LibraryPageHeaderSort';
import sampleProjects from './tempDev/sampleProjects';

const LEFT_MARGIN = 96; // Adjust the left margin as needed
const RIGHT_MARGIN = 72; // Adjust the right margin as needed

function LibraryHomePage({ projects, downloads, subscriptionStatus,sortOption,handleSortChange,displayMode }) {
  const [dimensions, setDimensions] = useState({ width: -1 });
  const hasFilter = false
  const hasSearch = false
  const containerWidth = dimensions.width
  const adjustedContainerWidth = containerWidth - LEFT_MARGIN - RIGHT_MARGIN + 8 + 8;
  const resultCount = projects.length
    return (
      <>
        <Measure
          bounds
          onResize={(contentRect) => {
            if (contentRect.bounds) {
              setDimensions({ width: contentRect.bounds.width });
            }
          }}
        >
          {({ measureRef }) => (
            <div ref={measureRef} className='libraryPage-measurer' />
          )}
        </Measure>
        

        {!hasFilter && !hasSearch &&
          <>

          
            {/*}
            <LibraryHomePageForYou containerWidth={dimensions.width} />
            */}
          
          
          
          <LibraryHomePageAllVideos 
            projects={projects} 
            containerWidth={dimensions.width} 
            sortOption={sortOption}
            handleSortChange={handleSortChange}
            displayMode={displayMode}
            />

          </>
        }

        {(hasFilter || hasSearch) &&

        <>

          <div className='libraryPage-sectionHeader'>
            <div className='libraryPage-sectionHeader-label'>
              {resultCount} results 
            </div>
            <div className='libraryPage-sectionHeader-rightContainer'>
              <LibraryPageHeaderGalleryDisplay 
                galleryDisplay={displayMode} 
                setGalleryDisplay={setGalleryDisplay} // Pass the setter function
              />                        
              <div className='libraryPage-header-smallSpacer'/>
              <LibraryPageHeaderSort 
                sortOption={sortOption}
                handleSortChange={handleSortChange}
              />
            </div>
          </div>                  
          {displayMode === 'grid' && 
            <LibraryProjectGalleryGrid 
              projects={sampleProjects} 
              containerWidth={adjustedContainerWidth} 
            />
          }
          {displayMode === 'list' && 
            <LibraryProjectGalleryList 
              projects={sampleProjects}             
            />
          }          


        </>
        }

      </>
    );
}

export default LibraryHomePage;
