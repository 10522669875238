import React, {useState} from 'react'
import Icon from '../misc/Icon';
import {findParentLayoutGroup} from '../../three/slide/slideEditor/utils/findParentLayoutGroup'

const SlideEditorContextMenu = ({left, top,updateZOrder,duplicateSlideItems,deleteSlideItems,selectedSlideItems,activePanelClip,handleSetIsBGImage,handleSetShowReplaceImageMediaModal,groupSlideItems}) => {
 
  const xOffset = 1
  const yOffset = 1
  let isImage = false

  let selectedElement
  let isInLayoutGroup
  let isMultiSelect = selectedSlideItems.length >1


  if (selectedSlideItems.length === 1 && selectedSlideItems[0].type=='element' && activePanelClip) {
      const selectedElementId = selectedSlideItems[0].id;
      selectedElement = activePanelClip.elements.find(element => element.id === selectedElementId);
      if (selectedElement) {
        const parentGroup = findParentLayoutGroup(selectedElement.id, activePanelClip.layout, false);
        isInLayoutGroup = parentGroup? true:false 

        if(selectedElement.type=='image'){
          isImage=true
        }
      }
    }




  return (
    <div style={{left: `${left + xOffset}px`, top: `${top + yOffset}px`, transform: 'scale(0.95)', transformOrigin: 'top left'}} className='slideEditorContextMenu contextMenu forceDarkTheme'>

          {isMultiSelect &&
           <div onClick={()=>{groupSlideItems()}} role='menuitem'>              
              Make Layout Group             
            </div>
          }


          {isImage && 
            <>
            <div onClick={()=>{handleSetShowReplaceImageMediaModal(true)}} role='menuitem'>              
              Replace Image...             
            </div> 

            {!isInLayoutGroup &&
             <div onClick={()=>{handleSetIsBGImage(selectedElement,!selectedElement.metadata.isBGImage)}} role='menuitem'>              
              {selectedElement.metadata.isBGImage ? 'Detach BG image' : 'Use as BG image'}
            </div> 
            }

            </>
          }

          <div onClick={duplicateSlideItems} role='menuitem'>              
            Duplicate             
          </div> 

          <div role='separator' />

          {!isMultiSelect &&
            <>
           <div onClick={()=>{updateZOrder('front')}} role='menuitem'>              
              Move to Front              
            </div>

            <div onClick={()=>{updateZOrder('forward')}} role='menuitem'>              
              Move Forward              
            </div>

            <div onClick={()=>{updateZOrder('backward')}} role='menuitem'>              
              Move Backward              
            </div>

            <div onClick={()=>{updateZOrder('back')}} role='menuitem'>              
              Move to Back              
            </div> 

            <div role='separator' />
            </>
          }

          
          
          <div onClick={deleteSlideItems} role='menuitem'>              
            Delete            
          </div> 
    </div>
  );
}


export default SlideEditorContextMenu;
