import React, { useRef, useEffect, useState, useContext } from 'react';
import { SpringAnimator } from '../../three/utils/animations/SpringAnimator';
import { startTransitionConfigs } from './textTransitionConfigs/startTransitionConfigs';
import { endTransitionConfigs } from './textTransitionConfigs/endTransitionConfigs';
import { simulateAnimationDuration } from '../../three/utils/animations/simulateAnimationDuration'; // Import the utility function
import {calculateMinSlideDuration} from '../../three/utils/animations/calculateMinSlideDuration'
import {getAnimationParamsForMotionStyle} from '../utils/animations/getAnimationParamsForMotionStyle'
import { animated, useSpring } from '@react-spring/three';
import {getBackgroundForId} from '../../utils/brands/getBackgroundForId'
import {getTextColorForBackgroundId} from '../../utils/brands/getTextColorForBackgroundId'
import {getTextColorForId} from '../../utils/brands/getTextColorForId'

import SlideTextPreviewElementMeshes from './SlideTextPreviewElementMeshes'
import { calculateLetterStartTimes } from '../utils/calculateLetterStartTimes'; // Update the path as needed


const FPS = 60; // Frames per second

const defaultValues = {
  positionX: 0,
  positionY: 0,
  positionZ: 0,
  scale: 1,
  rotationX: 0,
  rotationY: 0,
  rotationZ: 0,
  opacity: 1,
};

const SCENE_WIDTH = 1920;
const SCENE_HEIGHT = 1080;

const SlideTextPreviewElement = ({
  textElement,
  hideRenderedTextSlide,
  projectBackground,
  slideBackgroundId,
  showSlideTextStatic,
  focusedSlideElement,
  slideVisible,
  isDraggingElement,
  isInDraggingGroup,
  anyElementResizing,
  isDraggingSpacer,  
  isDraggingToReorder
}) => {
  
  let textElementOpacity = 1
  if(textElement.metadata.textOpacity){
    textElementOpacity = textElement.metadata.textOpacity
  }

  let initialProperties = defaultValues
  

  const backgroundId = slideBackgroundId
  let textSlideBackground = projectBackground
  if(backgroundId && backgroundId!=='none'){
    textSlideBackground=getBackgroundForId(backgroundId)
  }


  const textColorIsAuto = !textElement.metadata.textColorId
  let textSlideTextColor 
  if(textColorIsAuto){
    textSlideTextColor = getTextColorForBackgroundId(textSlideBackground.id).rgba
  }else{
    textSlideTextColor = getTextColorForId(textElement.metadata.textColorId).rgba
  }
  
  let textStyle
  if(textElement.metadata.textStyle){
    textStyle = textElement.metadata.textStyle
  }
  
  let textProperties = textElement.metadata.textProperties

  let zIndex = textElement.zIndex
  if(isDraggingElement){
    zIndex = 1000
  }

  const isFocused = focusedSlideElement === textElement.id

  //const isStatic = isDraggingElement || isInDraggingGroup || anyElementResizing || isDraggingSpacer
  
  const [isDelayedDraggingToReorder, setIsDelayedDraggingToReorder] = useState(isDraggingToReorder);
  const delayTimeoutRef = useRef(null);

  useEffect(() => {
    if (isDraggingToReorder) {      
      setIsDelayedDraggingToReorder(true);
      if (delayTimeoutRef.current) {
        clearTimeout(delayTimeoutRef.current);
      }
    } else {
      delayTimeoutRef.current = setTimeout(() => {
        setIsDelayedDraggingToReorder(false);        
      }, 200);
    }

    // Cleanup function
    return () => {
      if (delayTimeoutRef.current) {
        clearTimeout(delayTimeoutRef.current);
      }
    };
  }, [isDraggingToReorder]);


  const isAnimated = isDelayedDraggingToReorder && !isDraggingElement && !isInDraggingGroup;  
  //const isAnimated = isDraggingToReorder && !isDraggingElement && !isInDraggingGroup;  
  
  // console.log('isAnimated', isAnimated)
  // console.log('isDelayedDraggingToReorder', isDelayedDraggingToReorder)
  // console.log('isDraggingElement', isDraggingElement)
  // console.log('isInDraggingGroup', isInDraggingGroup)

  const notAnimated = !isAnimated


  // Calculate the group's position in Three.js coordinates
  const normalizedElementX = textElement.x + textElement.width / 2 - SCENE_WIDTH / 2;
  const normalizedElementY = -textElement.y - textElement.height / 2 + SCENE_HEIGHT / 2;


   const calculatePosition = () => {
    let x, y;
    // if (isDraggingElement && !isInDraggingGroup && 'previewX' in textElement && 'previewY' in textElement) {
      x = textElement.previewX || textElement.x

      y = textElement.previewY || textElement.y

    // } else {
    //   x = textElement.x;
    //   y = textElement.y;
    // }
    return [
      x + textElement.width / 2 - SCENE_WIDTH / 2,
      -(y + textElement.height / 2 - SCENE_HEIGHT / 2),
      0
    ];
  };

  const [springs, api] = useSpring(() => ({
    position: calculatePosition(),
    config: { tension: 400, friction: 32 },
    immediate: notAnimated,
  }));

  const prevNotAnimated = useRef(notAnimated);

  useEffect(() => {
    const newPosition = calculatePosition();
    api.start({
      position: newPosition,
      immediate: notAnimated,
    });
    prevNotAnimated.current = notAnimated;
  }, [textElement.x, textElement.y, textElement.previewX, textElement.previewY, textElement.width, textElement.height, notAnimated, isInDraggingGroup]);




  const isFontLoaded = textElement.isFontLoaded


  return (
    <>

    <animated.group renderOrder={zIndex} position={springs.position}>

      {isFontLoaded && textElement.metadata.lettersArray && textProperties && !isFocused &&
        <>
          <SlideTextPreviewElementMeshes         
            FPS={FPS}            
            initialProperties={initialProperties}
            lettersArray={textElement.metadata.lettersArray}
            fontFamily={textProperties.fontFamily}
            fontWeight={textProperties.fontWeight}
            letterSpacing={textProperties.letterSpacing}
            fontSize={textProperties.fontSize}            
            textColor={textSlideTextColor}                      
            zIndex={zIndex}
            defaultValues={defaultValues}          
            textElementOpacity={textElementOpacity}                              
          />          
        </>
      }

      </animated.group>

    </>
  );    
  
};

export default SlideTextPreviewElement;

