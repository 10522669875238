const initialState = {
	recordings: {} // Format: { [captureId]: { files: { [fileName]: { status, progress } } } }
};

export default function(state = {}, action) {
	switch(action.type) {
		case 'SET_MUSIC_FILE_TO_DOWNLOAD':
			const { trackId } = action;
			return {
				...state,
				[trackId]:'downloading'
			}

   case 'MUSIC_DOWNLOAD_SUCCESS':
      const { trackId: successMusicId } = action;
      return {
        ...state,
        [successMusicId]: 'completed', // Update the status to 'completed' on successful download
      };


		default:
			return state;
	}
}
