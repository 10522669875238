import React from 'react'
import * as Popover from '@radix-ui/react-popover';
import Icon from '../../misc/Icon';


function DPAnimationPanelTransitionPopoverGroupingTabs({ handleClosePopover,animationGroupingType,updateAnimationGrouping }) {
  
  const handleGroupingChange = (groupingType) => {
    updateAnimationGrouping(groupingType);
    //handleClosePopover();
  };

  const groupingOptions = [
    { type: 'everything', label: 'All At Once' },
    { type: 'line', label: 'By Line' },
    { type: 'word', label: 'By Word' },
  ];

  return (
    <>
      <div className='dpItemAnimationPanel-transitionPopover-groupingTabs'>
        {groupingOptions.map(({ type, label }) => (
          <button 
            key={type}
            data-active-state={animationGroupingType === type ? 'active' : 'inactive'}
            className='dpItemAnimationPanel-transitionPopover-groupingTabs-tab'
            onClick={() => handleGroupingChange(type)}
          >      
            <div className='dpItemAnimationPanel-transitionPopover-groupingTabs-tab-inner'>
              {label}
            </div>
          </button>
        ))}
      </div>
      <div className='dpItemAnimationPanel-transitionPopover-groupingTabsDivider' />
    </>
  );
}


function DPAnimationPanelTransitionPopoverItem({ label, iconName, isActive, onClick }) {
  return (          
    <button 
      data-active-state={isActive ? 'active' : 'inactive'}
      className='dpItemAnimationPanel-transitionPopover-item'
      onClick={onClick}
    >
      <div className='dpItemAnimationPanel-transitionPopover-item-iconContainer'>
        <Icon name={iconName} />
      </div>
      <div className='dpItemAnimationPanel-transitionPopover-item-label'>
        {label}
      </div>
    </button>
  );
}

function DPAnimationPanelTransitionPopover({ children, activeTransition, onTransitionChange, transitionConfigs, sideOffset, alignOffset, showGroupingOptions,animationGroupingType,updateAnimationGrouping,modalMode }) {
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  
  const handleClosePopover = () => {
    setPopoverOpen(false);
  };

  const handleTransitionClick = (transitionKey) => {
    onTransitionChange(transitionKey);    
    handleClosePopover();
  };

  return (
    <Popover.Root 
      open={popoverOpen} 
      onOpenChange={setPopoverOpen}
      modal={modalMode}
    >
      <Popover.Trigger asChild>
        {children}
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content           
          side="bottom"
          align="middle"      
          alignOffset={alignOffset ? alignOffset : 0}    
          sideOffset={sideOffset ? sideOffset : 0}    
          className='dpItemAnimationPanel-transitionPopover forceDarkTheme'
        >
          {showGroupingOptions &&
            <DPAnimationPanelTransitionPopoverGroupingTabs 
              handleClosePopover={handleClosePopover}
              animationGroupingType={animationGroupingType}
              updateAnimationGrouping={updateAnimationGrouping}
            />
          }
          {Object.entries(transitionConfigs).map(([key, config]) => (
            <DPAnimationPanelTransitionPopoverItem
              key={key}
              label={config.label}
              iconName={config.iconName}
              isActive={activeTransition === key}
              onClick={() => handleTransitionClick(key)}
            />
          ))}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

export default DPAnimationPanelTransitionPopover;
