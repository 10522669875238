export const endTransitionConfigs = {
  none: (scalar) => ({  
    label: "None",
    endTo: {
      opacity: 1,      
    },    
  }),
  fade: (scalar) => ({  
    label: "Fade Out",
    endTo: {
      opacity: 0,      
    },    
  }),
  fadeAndMoveDown: (scalar) => ({  
    label: "Fade Down",    
    endTo: {
      opacity: 0,
      positionY: -20 * scalar,
    },
  }),
  fadeAndMoveRight: (scalar) => ({  
    label: "Fade Right",    
    endTo: {
      opacity: 0,
      positionX: 60 * scalar,
    },
  }),
  fadeAndMoveLeft: (scalar) => ({  
    label: "Fade Left",    
    endTo: {
      opacity: 0,
      positionX: -60 * scalar,
    },
  }),
  fadeAndScaleUp: (scalar) => ({    
    label: "Scale Up",
    endTo: {
      opacity: 0,
      scale: 1.2,
    },    
  }),
  fadeAndScaleDown: (scalar) => ({    
    label: "Scale Down",
    endTo: {
      opacity: 0,
      scale: 0.8,
    },    
  }),
};
