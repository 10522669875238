import React, { useState, useEffect, useRef, useCallback } from 'react';
import Icon from '../misc/Icon';
import { useSelector,useDispatch } from 'react-redux';
import find from 'lodash/find';
import debounce from 'lodash/debounce';
import * as Popover from '@radix-ui/react-popover';
import { saveProjectName } from '../../utils/projectUtils/saveProjectName';
import { shareProjectWithTeam } from '../../utils/projectUtils/shareProjectWithTeam';
import MoveToCollectionPopover from '../uiKit/moveToCollection/MoveToCollectionPopover'
import { useMoveToCollectionPopover } from '../uiKit/moveToCollection/useMoveToCollectionPopover';
import {getOrgName} from '../../utils/getOrgName' 
import {duplicateProject} from '../../utils/projectUtils/duplicateProject'
import {archiveProject} from '../../utils/projectUtils/archiveProject'
import { useHistory } from 'react-router-dom';
import {orgHasCollections} from '../../utils/collectionUtils/orgHasCollections'

function TabBarItem({ id, projectId, path, active, onClick, removeTab, tabWidth }) {
  const dispatch = useDispatch()
  const history = useHistory()
  

  const [inputValue, setInputValue] = useState('');
  const [popoverOpen, setPopoverOpen] = useState(false);
  // const [moveToPopoverOpen, setMoveToPopoverOpen] = useState(false);
 const project = useSelector(state => find(state.projects, { id: projectId }));

  const {
    showMoveToPopover,
    setShowMoveToPopover,
    pendingChanges,
    handleCollectionChange,
    saveMoveToCollectionChanges
  } = useMoveToCollectionPopover(project);


  const [inputFocused, setInputFocused] = useState(false)



  useEffect(() => {
    if (project && !inputValue) {
      setInputValue(project.name);
      setIsSharingInProgress(false);
    }
  }, [project]);

  // const debouncedSaveProjectName = useCallback(debounce((name) => {
  //   saveProjectName(projectId, name);
  // }, 1000), [projectId]);

  const handleInputChange = (e) => {
    const newName = e.target.value;
    setInputValue(newName);
//    debouncedSaveProjectName(newName);
  };

  const inputRef = useRef(null);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === 'Escape') {
      inputRef.current?.blur();
      setPopoverOpen(false);
    }    
  };

  const handleRemoveTabClick = (e) => {
    e.stopPropagation();
    removeTab(id);
  };

  const handleInputFocus = () => {
    setInputFocused(true);
    setPopoverOpen(true);
   // if (inputValue === 'Untitled' && inputRef.current) { //If project is named untitled then select all the text when you focus input
     if (inputRef.current) { 
      inputRef.current.select();
    }
  };

  const handleInputBlur = () => {
    setInputFocused(false); 
     saveProjectName(projectId, inputValue);
  };


  const handleShowMoveToPopover = (e) => {        
    e.preventDefault(); // Prevent the default action
    e.stopPropagation(); // Stop the event from bubbling up
    setShowMoveToPopover(true);
  };


  const handleClosePopover = () => {    
    setInputFocused(false);    
    if(!inputFocused){
      setPopoverOpen(false);
    }    
    saveMoveToCollectionChanges()
  };

  const bgClickClose = () => {    
    setPopoverOpen(false);   
    setInputFocused(false); 
    setTimeout(() => {
      setShowMoveToPopover(false);
      saveMoveToCollectionChanges()
    }, 75);
  };


  const handleDuplicateProject = async () => {
    const isDraft = true;
    try {
      const newProjectId = await duplicateProject(projectId, isDraft);
      setPopoverOpen(false);
      console.log('Navigating to new project:', newProjectId);
      history.push(`/project/${newProjectId}`);
    } catch (error) {
      console.error('Failed to duplicate project:', error);
    }
  };

  const handleArchiveProject = (e) => {
    e.stopPropagation();
    archiveProject(projectId,history)
  };

  const preventBlur = (e) => {
    e.preventDefault();
  };

  const isDraft = project && project.is_private

  const orgName = getOrgName()
  let orgNameLabel = orgName
  if(orgName === 'yarn'){
    orgNameLabel = 'Acme'
  }

  const hasCollections = orgHasCollections() // org has collections

  const [isSharingInProgress, setIsSharingInProgress] = useState(false);


  return (
    <>
    <div 
      key={id} 
      onClick={() => onClick(path)}
      className='tabBar-tab'
      data-state={active ? "active" : "inactive"}
      style={{ width: `${tabWidth}px` }}
    >
      <div data-input-focused-state={inputFocused ? true : false} data-active-state={active ? "active" : "inactive"} data-draft-state={isDraft ? true : false} className='tabBar-tab-labelContainer'>
                  
        <input 
          ref={inputRef}
          className='tabBar-tab-titleInput'
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
                      
        <div className='tabBar-tab-label'>
          <div className='tabBar-tab-label-label'>
            {inputValue}
          </div>
          {isDraft && !isSharingInProgress && !popoverOpen &&
            <div className='tabBar-tab-label-draft'>
              Draft
            </div>
          }
          </div>
          
          
      </div>
      <div onClick={handleRemoveTabClick} className='tabBar-tab-closeBtnContainer'>
        <div className='tabBar-tab-closeBtn'>
          <Icon name='cross-small' />
        </div>
      </div>
      <div className='tabBar-tab-popoverContainer'>
        <Popover.Root key={id} open={popoverOpen}>
          <Popover.Trigger asChild>
            <div className='tabBar-tab-popoverTrigger' />
          </Popover.Trigger>
          <Popover.Portal>
            <Popover.Content 
              align="start"
              alignOffset={0}
              side="bottom" 
              sideOffset={2} 
              className='miniPopoverWrapper miniPopoverWrapper--tabBar'
              onOpenAutoFocus={(e) => e.preventDefault()} // Prevent popover from stealing focus              
              onEscapeKeyDown={() => bgClickClose()}
              // onPointerDownOutside={() => handleClosePopover()}
              // onFocusOutside={() => handleClosePopover()}
              // onInteractOutside={() => handleClosePopover()}
            >
              {!showMoveToPopover && 
                <div data-state={popoverOpen ? 'open' : 'closed'} className='miniPopover miniPopover--tabBar forceDarkTheme'>
                  
                  
                  {(isDraft || isSharingInProgress) &&
                    <button 
                      className='miniPopover-item' 
                      onClick={() => {
                        setIsSharingInProgress(true);
                        setPopoverOpen(false);                        
                        shareProjectWithTeam(projectId);
                        setTimeout(() => {                          
                          setIsSharingInProgress(false);
                        }, 200);
                      }}>
                      <div className='dropdownMenu-item-iconContainer'>
                        <Icon name='moveToMedium' />
                      </div>
                      Move to {orgNameLabel}
                    </button>
                  }

                  {!isDraft && !isSharingInProgress && hasCollections &&
                    <button className='miniPopover-item' onClick={handleShowMoveToPopover} onMouseDown={preventBlur}>
                      <div className='dropdownMenu-item-iconContainer'>
                        <Icon name='plusMedium' />
                      </div>
                      Add to Collection
                    </button>
                  }
                
                  <button 
                    className='miniPopover-item'                     
                    onClick={handleDuplicateProject}>
                    <div className='dropdownMenu-item-iconContainer'>
                      <Icon name='duplicateMedium' />
                    </div>
                    Make a Copy
                  </button>
                  <div className='popoverMenu-separator' />
                  <button 
                    className='miniPopover-item miniPopover-item--light' 
                    onClick={handleArchiveProject}>
                    <div className='dropdownMenu-item-iconContainer'>
                      <Icon name='deletedMedium' />
                    </div>
                    Delete
                  </button>
                </div>
              }

              <MoveToCollectionPopover 
                showMoveToPopover={showMoveToPopover}
                project={project}
                pendingChanges={pendingChanges}
                handleCollectionChange={handleCollectionChange}
              />          

            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>

        

      </div>
    </div>
    {popoverOpen && 
      <div onClick={()=> bgClickClose(false)} className='tabBar-bgClick' />
    }
    </>
  );
}

export default TabBarItem;
