
export function pixelsToTime(pixels, pixelsPerSec, scenes, sceneGap) {
    let totalSceneTime = 0
    let totalPixels = 0

    for (let i = 0; i < scenes.length; i++) {
        // Calculate current scene's duration in pixels
        let sceneDurationPixels = scenes[i].duration * pixelsPerSec;

        // Check if the pixels fall within the current scene
        if ((pixels < totalPixels + sceneDurationPixels) || i==scenes.length-1) {
            // Pixels are within the current scene
            // Find the pixel offset within the current scene
            let pixelOffsetInScene = pixels - totalPixels;
            // Convert this pixel offset back to time
            return Math.max(totalSceneTime + (pixelOffsetInScene / pixelsPerSec),0)
        }

        // Add the current scene's duration to the total time
        totalSceneTime += scenes[i].duration;
        // Add the current scene's pixels to the total pixels
        totalPixels += sceneDurationPixels;

        if(pixels<(totalPixels+0.5*sceneGap)){
            return scenes[i].endTime-0.001
        }else if(pixels<(totalPixels+ sceneGap)){
            return scenes[i+1].startTime+0.001
        }

        // Add the gap pixels if not the last scene
        if (i < scenes.length - 1) {
            totalPixels += sceneGap;  // Add gap pixels to total
        }
    }

    return Math.max(totalSceneTime,0)
}