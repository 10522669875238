import sortBy from 'lodash/sortBy'
import {randomID} from '../../../../../utils/randomID'
import editorSchema from '../../../../../prosemirror/schema/editorSchema'

export function makeDocJson(webcamClips, clips, unsortedScenes) {   
    let contentArray = [];
    const scenes = unsortedScenes.sort((a, b) => a.sceneIndex - b.sceneIndex);
    const sceneCount = scenes.length;
    let totalChunkCount = 0;

    scenes.forEach((scene) => {
        // Scene header creation with proper count
        let title = scene.title;
        if (title == 'Untitled Scene') {
            title = '';
        }
        
        let paragraphNode = title ? 
            editorSchema.nodes.paragraph.create({}, [editorSchema.text(title)]) :
            editorSchema.nodes.paragraph.create({});
        
        const sceneHeaderNode = editorSchema.nodes.sceneHeader.createAndFill({ 
            sceneId: scene.id,
            sceneDuration: scene.duration,
            sceneCount: sceneCount 
        }, [paragraphNode]);
        
        contentArray.push(sceneHeaderNode);

        // Filter and sort clips
        const sceneAudioClips = clips.filter(clip => clip.sceneId === scene.id);
        const sceneWebcamClips = webcamClips.filter(clip => clip.sceneId === scene.id);
        const sceneClips = [...sceneAudioClips, ...sceneWebcamClips];
        const sortedClips = sortBy(sceneClips, 'startTime');

        // Organize clips into groups
        const groups = [];
        let currentStandardGroup = null;

        function finalizeCurrentStandardGroup() {
            if (currentStandardGroup && currentStandardGroup.clips.length > 0) {
                groups.push(currentStandardGroup);
                currentStandardGroup = null;
            }
        }

        if (sortedClips.length === 0) {
            // Create a default empty group for scenes with no clips
            groups.push({
                type: 'standard',
                id: randomID(),
                clips: [null] // Indicates empty chunk needed
            });
        } else {
            sortedClips.forEach(clip => {
                if (clip.type === 'webcam') {
                    finalizeCurrentStandardGroup();
                    
                    if (clip.isPlaceholder) {
                        // Find any audio clips that reference this webcam
                        const associatedAudioClips = sceneAudioClips.filter(
                            audioClip => audioClip.parentWebcamClip === clip.id
                        );
                        
                        groups.push({
                            type: 'webcam-placeholder',
                            id: clip.id,
                            clips: associatedAudioClips.length > 0 ? associatedAudioClips : [null]
                        });
                    } else {
                        groups.push({
                            type: 'webcam-recording',
                            id: clip.id,
                            clips: [clip],
                            isProcessing: clip.isUploadingVideo,
                            transcript: clip.metadata?.transcript
                        });
                    }
                } else if (!clip.parentWebcamClip) { // Audio clip with no parent webcam
                    if (!currentStandardGroup) {
                        currentStandardGroup = {
                            type: 'standard',
                            id: randomID(),
                            clips: []
                        };
                    }
                    currentStandardGroup.clips.push(clip);
                }
                // Audio clips with parentWebcamClip are handled in the webcam-placeholder case
            });
            
            finalizeCurrentStandardGroup();
        }

        // Create nodes from groups
        const sceneContentArray = groups.map(group => {
            if (group.type === 'webcam-recording') {
                const webcamClip = group.clips[0];
                const transcript = group.transcript;
                
                if (transcript?.chunks && transcript.chunks.length > 0) {
                    const chunkNodes = transcript.chunks.map((chunk, idx) => {
                        totalChunkCount++;
                        return createChunkNode(
                            'webcam-recording',
                            webcamClip,
                            scene.id,
                            chunk.words,
                            idx,
                            transcript.chunks.length,
                            chunk.start,
                            chunk.end,
                            transcript.skippedWords
                        );
                    });
                    
                    return editorSchema.nodes.transcriptGroup.createAndFill({
                        groupId: group.id,
                        groupType: 'webcam-recording',
                        isProcessing: group.isProcessing,
                        skippedWords: transcript.skippedWords || []
                    }, chunkNodes);
                    
                } else {
                    // Fallback for no chunks or old data format
                    const words = transcript?.words || [];
                    totalChunkCount++;
                    
                    const chunkNode = createChunkNode(
                        'webcam-recording',
                        webcamClip,
                        scene.id,
                        words,
                        0,
                        1
                    );
                    
                    return editorSchema.nodes.transcriptGroup.createAndFill({
                        groupId: group.id,
                        groupType: 'webcam-recording',
                        isProcessing: group.isProcessing
                    }, [chunkNode]);
                }
            } else {
                // standard or webcam-placeholder groups
                const chunks = group.clips.map(clip => {
                    totalChunkCount++;
                    
                    // Handle empty chunk case
                    if (!clip) {
                        const emptyParagraphNode = editorSchema.nodes.paragraph.create({});
                        return editorSchema.nodes.transcriptChunk.createAndFill({
                            clipId: randomID(),
                            sceneId: scene.id,
                            requiresUpdate: false,
                            transcriptChunkIndex: 0,
                            transcriptChunkCount: 1
                        }, [emptyParagraphNode]);
                    }

                    let paragraphNode;
                    if (clip.metadata.text.trim() !== '' && clip.metadata.text.trim() !== '#') {
                        paragraphNode = editorSchema.nodes.paragraph.create({}, 
                        [editorSchema.text(clip.metadata.text)]);
                    } else {
                        paragraphNode = editorSchema.nodes.paragraph.create({});
                    }

                    return editorSchema.nodes.transcriptChunk.createAndFill({
                        clipId: clip.id,
                        sceneId: scene.id,
                        requiresUpdate: clip.requiresUpdate,
                        transcriptChunkIndex: 0,
                        transcriptChunkCount: group.clips.length
                    }, [paragraphNode]);
                });

                return editorSchema.nodes.transcriptGroup.createAndFill({
                    groupId: group.id,
                    groupType: group.type,
                    skippedWords: []
                }, chunks);
            }
        });
        
        contentArray.push(...sceneContentArray);
    });

    return editorSchema.nodes.doc.createAndFill({}, contentArray);
}

function createChunkNode(type, clip, sceneId, words = [], chunkIndex = 0, chunkCount = 1, chunkStart = 0, chunkEnd = 0, skippedWords = []) {
    const nodeType = type === 'webcam-recording' ? 'webcamRecordingChunk' : 'transcriptChunk';
    const clipId = clip.id
    if (type === 'webcam-recording' && words.length > 0) {
        // Create a Set of skipped word indices for efficient lookup
        const skippedIndices = new Set(skippedWords.map(word => word.originalWordIndex));

        // Filter out skipped words and create word nodes
        const nodesWithSpaces = words.reduce((acc, word) => {
            // Skip words that are in the skipped set
            if (skippedIndices.has(word.index)) {
                return acc;
            }
            if(word.end < clip.metadata.trimStart || word.start>clip.metadata.trimEnd){
                return acc
            }

            let text = word.word;
            const wordNode = editorSchema.nodes.webcamTranscriptWord.create(
                {
                    startTime: word.start,
                    endTime: word.end,
                    originalWordIndex: word.index
                },
                [editorSchema.text(text)]
            );
            acc.push(wordNode);
            return acc;
        }, []);

        return editorSchema.nodes[nodeType].create({
            clipId,
            sceneId,
            requiresUpdate: false,
            transcriptChunkIndex: chunkIndex,
            transcriptChunkCount: chunkCount
        }, nodesWithSpaces);
    } else {
        return editorSchema.nodes[nodeType].create({
            clipId,
            sceneId,
            requiresUpdate: false,
            transcriptChunkIndex: chunkIndex,
            transcriptChunkCount: chunkCount
        });
    }
}
