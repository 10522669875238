import React from 'react';
import { fontWeightToNumber } from '../../three/utils/fontWeightToNumber';
import { getTextColorForBackgroundId } from '../../utils/brands/getTextColorForBackgroundId';
import { getTextColorForId } from '../../utils/brands/getTextColorForId';

function SlideTextElementLetterSpan({ letter, globalOpacity, letterOpacity, globalColorId, letterColorId, slideBGId }) {
  const opacity = letterOpacity !== null ? letterOpacity : globalOpacity;
  
  let color;
  if (letterColorId) {
    color = getTextColorForId(letterColorId).rgba;
  } else if (globalColorId) {
    color = getTextColorForId(globalColorId).rgba;
  } else {
    color = getTextColorForBackgroundId(slideBGId).rgba;
  }

  return (
    <span style={{ opacity, color, display: 'inline-block' }}>
      {letter}
    </span>
  );
}

function SlideTextElement({ textElement, slideBGId }) {
  const textStyle = {
    position: 'absolute',
    left: `${textElement.x}px`,
    top: `${textElement.y}px`,
    width: `${textElement.width}px`,
    height: 'auto',
    textAlign: textElement.metadata.textHAlign,
    fontSize: `${textElement.metadata.textProperties.fontSize}px`,
    fontFamily: textElement.metadata.textProperties.fontFamily,
    fontWeight: fontWeightToNumber(textElement.metadata.textProperties.fontWeight),
    letterSpacing: `${textElement.metadata.textProperties.letterSpacing}em`,
    lineHeight: `${textElement.metadata.textProperties.lineHeight}`,
    zIndex: textElement.metadata.zOrder + 5,
    overflowWrap: 'normal',
    wordBreak: 'keep-all',
    whiteSpace: 'pre-wrap'
  };

  const renderContent = () => {
    const { lettersArray } = textElement.metadata;
    const fullText = textElement.metadata.text;
    let textIndex = 0;
    const content = [];
    let currentWord = [];
    
    const flushWord = () => {
      if (currentWord.length > 0) {
        content.push(
          <span key={`word-${textIndex}`} style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
            {currentWord}
          </span>
        );
        currentWord = [];
      }
    };

    lettersArray.forEach((letterData, index) => {
      // Handle line breaks and spaces
      while (textIndex < fullText.length && fullText[textIndex] !== letterData.letter) {
        if (fullText[textIndex] === '\n') {
          flushWord();
          content.push(<br key={`linebreak-${textIndex}`} />);
        } else if (fullText[textIndex] === ' ') {
          flushWord();
          content.push(
            <span key={`space-${textIndex}`} style={{ display: 'inline-block' }}>
              {' '}
            </span>
          );
        }
        textIndex++;
      }
      
      // Add letter to current word
      currentWord.push(
        <SlideTextElementLetterSpan
          key={`letter-${index}`}
          letter={letterData.letter}
          globalOpacity={textElement.metadata.textOpacity}
          letterOpacity={letterData.opacity}
          globalColorId={textElement.metadata.textColorId}
          letterColorId={letterData.colorId}
          slideBGId={slideBGId}
        />
      );
      textIndex++;
    });

    // Flush any remaining word
    flushWord();

    return content;
  };

  return (
    <div style={textStyle} className='slidePreview-text'>
      {renderContent()}
    </div>
  );
}

export default SlideTextElement;