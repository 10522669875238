import {getBrandForDomain} from '../utils/brands/brandsByOrg'


export default function (state = getBrandForDomain(), action) {
	switch (action.type) {
	
    case 'FETCH_ORG_SUCCESS':
      const orgBrand = getBrandForDomain(action.response.domain)
      return orgBrand

    // case 'SIGN_OUT':
    //   return {}
   
    // case 'FETCH_ORG_SUCCESS':
    //   return action.response

    default:
      return state;
	}
}
