import {fetch,post,put,deleteRequest} from '../api'
import { Mixpanel } from '../Mixpanel'
import store from '../store'
import collectionColors from '../data/collectionColors'
import {fetchProjects} from './projects'
import {showToastNotification} from './toastNotification'

export function fetchCollections(){
	return (dispatch) => fetch(`/collections`)
	.then((response) => {
		dispatch({ type: 'FETCH_COLLECTIONS_SUCCESS', response })
		return response
	})
	.catch((error) => {
		console.log(error)
		return error
	})
} 


export function createCollection(collectionId) {
  const nowDate=new Date()
  const state = store.getState()
  const collections = state.collections 
  const color = collectionColors[collections.length % collectionColors.length];
  let collection={
    id:collectionId,
    created_at:nowDate,
    title:'Untitled Collection ', //add a space at the end so we can tell if its a new one or is after the title input has closed
    color:color
  }

  const requestBody={
    collection:collection
  }
  return (dispatch) =>{ 
    dispatch({ type: 'CREATE_COLLECTION_REQUEST', collection })
     return post(`/collections`,requestBody)
      .then((response) => {
        dispatch({ type: 'CREATE_COLLECTION_SUCCESS', response })
        Mixpanel.track('create_collection')
        return response
      })
      .catch((error) => {
        console.log('create collection error')
        console.log(error)
        return error
      })
    }
}


export function archiveCollectionRequest(collectionId) {
  return (dispatch) =>{ 
    dispatch({ type: 'ARCHIVE_COLLECTION_REQUEST', collectionId })
     return put(`/collections/${collectionId}/archive`)
      .then((response) => {
        dispatch(fetchProjects())
        //show toast
        const toastType='deleteCollection'
        const data={      
          collectionName:response.title,
          collectionColor:response.color,
        }
        dispatch(showToastNotification(toastType,data))
        return response
      })
      .catch((error) => {
        console.log('archive collection error')
        console.log(error)
        return error
      })
      }
}




export function updateCollection(collectionId,title,color) {
  const requestBody={title:title,color:color}
  return (dispatch) =>{ 
     return put(`/collections/${collectionId}`,requestBody)
      .then((response) => {
        dispatch({ type: 'UPDATE_COLLECTION_SUCCESS', response })
        return response
      })
      .catch((error) => {
        console.log('update collection error')
        console.log(error)
        return error
      })
      }
}







export function addProjectToCollection(projectId, collectionId) {
  console.log(`add project to collection------- `)

  return (dispatch) => {
    dispatch({ type: 'ADD_PROJECT_TO_COLLECTION_REQUEST', projectId, collectionId });
    return post(`/collections/${collectionId}/projects/${projectId}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {

        return error;
      });
  };
}




export function removeProjectFromCollection(projectId, collectionId) {
  console.log(`remove project from collection`)

  return (dispatch) => {
    dispatch({ type: 'REMOVE_PROJECT_FROM_COLLECTION_REQUEST', projectId, collectionId });

    return deleteRequest(`/collections/${collectionId}/projects/${projectId}`)
      .then((response) => {
        console.log('response');
        console.log(response);
        return response;
      })
      .catch((error) => {
      	
        return error;
      });
  };
}


