import React from 'react';
import Icon from '../../misc/Icon';

function LibraryPageHeaderGalleryDisplay({ displayMode, setDisplayMode }) {

  return (
    <div className='libraryPage-header-galleryDisplay'>
      <button 
        data-active-state={displayMode === 'grid' ? 'true' : 'false'} 
        className='uiButton uiButton--galleryDisplay uiButton--galleryDisplay--grid'
        onClick={() => setDisplayMode('grid')} // Set display type to grid
      >
        <div className='uiButton-iconContainer'>
          <Icon name='galleryDisplayGrid' />
        </div>
        <div className='uiButton-label'>
          Grid
        </div>
      </button>
      <button 
        data-active-state={displayMode === 'list' ? 'true' : 'false'} 
        className='uiButton uiButton--galleryDisplay uiButton--galleryDisplay--list'
        onClick={() => setDisplayMode('list')} // Set display type to list
      >
        <div className='uiButton-iconContainer'>
          <Icon name='galleryDisplayListMedium' />
        </div>
        <div className='uiButton-label'>
          List
        </div>
      </button>
    </div>
  );
}

export default LibraryPageHeaderGalleryDisplay;
