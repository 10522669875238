import findIndex from 'lodash/findIndex'



const updateCollectionInState = (state, updatedCollection) => {
  const index = findIndex(state, { id: updatedCollection.id });
  if (index === -1) {
    return state;
  }
  return [
    ...state.slice(0, index),
    { ...state[index], ...updatedCollection },
    ...state.slice(index + 1),
  ];
};



export default function (state = [], action) {
	switch (action.type) {
	

    case 'SIGN_OUT':
      return []
   
    case 'FETCH_COLLECTIONS_SUCCESS':
      return action.response
      

    case 'UPDATE_COLLECTION_SUCCESS':
    case 'CREATE_COLLECTION_SUCCESS':
      return updateCollectionInState(state, action.response);


  case 'ARCHIVE_COLLECTION_REQUEST': 
    const id = action.collectionId
    let idx = findIndex(state,(collection => {
      return collection.id === id
    }))
    return [
      ...state.slice(0,idx),
      ...state.slice(idx + 1)
    ]

      

    //  case 'UPDATE_COLLECTION_SUCCESS': {
    //   const updatedCollection = action.response;
    //   const index = findIndex(state, { id: updatedCollection.id });
    //   if (index === -1) {
    //     return state;
    //   }
    //   return [
    //     ...state.slice(0, index),
    //     { ...state[index], ...updatedCollection },
    //     ...state.slice(index + 1),
    //   ];
    // }

    case 'CREATE_COLLECTION_REQUEST':
      const collection = action.collection    
      return [...state,collection]



    default:
      return state;
	}
}
