export function	findActiveSegmentForVideoClip(currentTime, clip){
	// console.log('this one!!!!')

  	const clipTime = currentTime - clip.startTime
		const segments = clip.segments
		let accumulatedDuration = 0
    let previousSegment = null

    for (const segment of segments) {
     // const segmentDuration = segment.isQuiet ? segment.originalDuration / segment.playbackRate : segment.originalDuration;

      const segmentDuration = segment.duration
     
      if (clipTime >= accumulatedDuration && clipTime < accumulatedDuration + segmentDuration) {
      //if (clipTime > accumulatedDuration && clipTime <= accumulatedDuration + segmentDuration) {

      //  console.log(previousSegment)
        const isAfterCollapsedSkipSegment = previousSegment ? previousSegment.isSkipSegment&&!previousSegment.isExpanded : false;

        return {
          segment,
          isAfterCollapsedSkipSegment
        }
      }
      accumulatedDuration += segmentDuration;
      previousSegment=segment
    }
    return null;
	}