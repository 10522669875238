
  // Helper to get word indices from a webcam recording chunk
  const getChunkWordIndices = (node) => {
    let firstIndex = Infinity;
    let lastIndex = -1;
    node.forEach(child => {
      if (child.type.name === 'webcamTranscriptWord') {
        const wordIndex = child.attrs.originalWordIndex;
        firstIndex = Math.min(firstIndex, wordIndex);
        lastIndex = Math.max(lastIndex, wordIndex);
      }
    });
    return {
      firstWordIndex: firstIndex === Infinity ? 0 : firstIndex,
      lastWordIndex: lastIndex === -1 ? 0 : lastIndex
    };
  };


export default getChunkWordIndices