import {getOrgBrand} from './getOrgBrand'
import find from 'lodash/find'


export function getTextColorForId(id) {
  const orgBrand=getOrgBrand()
  let textColor
  // if(id){
    textColor = find(orgBrand.textColors,{id:id})
  // }else{
  //  textColor = orgBrand.textColors[0]
 // }
    if(!textColor){
      textColor = orgBrand.textColors[0]
    }

  return textColor
}
