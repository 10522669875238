export const startTransitionConfigs = {
  none: {
    label: "None",
    startFrom: {
      opacity: 1,      
    },
    startTo: {
      opacity: 1,      
    },
  },
  fade: {
    label: "Fade In",
    startFrom: {
      opacity: 0,      
    },
    startTo: {
      opacity: 1,      
    },
  },
  fadeAndMoveUp: {
    label: "Fade Up",
    startFrom: {
      opacity: 0,
      positionY: -20,
    },
    startTo: {
      opacity: 1,
      positionY: 0,
    },
  },
  fadeAndMoveDown: {
    label: "Fade Down",
    startFrom: {
      opacity: 0,
      positionY: 20,
    },
    startTo: {
      opacity: 1,
      positionY: 0,
    },
  },
  fadeAndMoveLeft: {
    label: "Fade Right",
    startFrom: {
      opacity: 0,
      positionX: -60,
    },
    startTo: {
      opacity: 1,
      positionX: 0,
    },
  },
  fadeAndMoveRight: {
    label: "Fade Left",
    startFrom: {
      opacity: 0,
      positionX: 60,
    },
    startTo: {
      opacity: 1,
      positionX: 0,
    },
  },
  fadeAndScaleUp: {
    label: "Scale Up",
    startFrom: {
      opacity: 0,
      scale: 0.9,
    },
    startTo: {
      opacity: 1,
      scale: 1,
    },
  },
  fadeAndScaleDown: {
    label: "Scale Down",
    startFrom: {
      opacity: 0,
      scale: 1.1,
    },
    startTo: {
      opacity: 1,
      scale: 1,
    },
  },
  
  
};