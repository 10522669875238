import {webcamSizes as sizes, webcamPositions as positions, SLIDE_WIDTH, SLIDE_HEIGHT, FLOAT_CAMERA_INSET} from './webcamConfigs'
import {calculateWebcamRectForSizeAndPosition} from './calculateWebcamRectForSizeAndPosition'



export function updateWebcamLayoutForTime(updateClipMetadata,clip,selectedSize,selectedPostion,currentTime, slideClips) {


  //check for overlapping slide clips.  if there is update the slide clip metadata otherwise do the webcamp




  const overlappingSlideClip = slideClips.find(clip => 
    currentTime >= clip.startTime && currentTime <= clip.endTime
  );

  if(overlappingSlideClip){
    let newWebcamLayout={
      size:selectedSize,
      position:selectedPostion
    }
    updateClipMetadata(overlappingSlideClip.id,{webcamLayout:newWebcamLayout})

  }else{
    let newLayout = {...clip.metadata.layout}
    newLayout.size = selectedSize 
    newLayout.position = selectedPostion 
    updateClipMetadata(clip.id,{layout:newLayout})
  }



}