import {Plugin,PluginKey} from "prosemirror-state"
import {Decoration, DecorationSet} from "prosemirror-view"


function deco(from, to) {
  return Decoration.inline(from, to, {class: "selection-span",id: "selection-span"})
}

export function selectionStylingPlugin(){
	return new Plugin({
		key: new PluginKey("selectionStylingPlugin"),
		props: {
			decorations(editorState) {
				const selection=editorState.selection
				if(!selection.empty){
					let decoArray=[]
					const selectionDeco=deco(selection.from,selection.to)
					decoArray.push(selectionDeco)
					return DecorationSet.create(editorState.doc, decoArray)
				}else return null
			}
		},
	})
}
