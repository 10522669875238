//Find the skip segment ranges from the skipped words

export function calculateWebcamSkipRangesFromWords(skippedWords = []) {
  if (!skippedWords.length) return [];

  // Sort skipped words by index
  const sortedWords = [...skippedWords].sort((a, b) => 
    a.originalWordIndex - b.originalWordIndex
  );

  const ranges = [];
  let currentRange = null;

  sortedWords.forEach((word, index) => {
    if (!currentRange) {
      // Start new segment
      currentRange = {
        startTime: word.startTime,
        endTime: word.endTime,
        startWordIndex: word.originalWordIndex,
        endWordIndex: word.originalWordIndex,
        words: [word]
      };
    } else {
      const previousWord = sortedWords[index - 1];
      const isConsecutive = word.originalWordIndex === previousWord.originalWordIndex + 1;

      if (isConsecutive) {
        // Extend current segment
        currentRange.endTime = word.endTime;
        currentRange.endWordIndex = word.originalWordIndex;
        currentRange.words.push(word);
      } else {
        // Push current segment and start new one
        ranges.push(currentRange);
        currentRange = {
          startTime: word.startTime,
          endTime: word.endTime,
          startWordIndex: word.originalWordIndex,
          endWordIndex: word.originalWordIndex,
          words: [word]
        };
      }
    }
  });

  // Push final segment if exists
  if (currentRange) {
    ranges.push(currentRange);
  }

  return ranges;
}