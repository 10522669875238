import { Plugin, PluginKey } from 'prosemirror-state'

export function marksInSelectionPlugin(updateSlideElementTextSelectionInfo) {
  return new Plugin({
    key: new PluginKey('selection'),
    view(editorView) {
      return {
        update: (view, prevState) => {
          const { state } = view
          if (prevState && prevState.selection.eq(state.selection)) {
            return
          }
          updateSelectionMarks(state, updateSlideElementTextSelectionInfo)
        }
      }
    }
  });
}

function updateSelectionMarks(state, updateSlideElementTextSelectionInfo) {
  const { from, to } = state.selection
  const marks = []
  let totalTextLength = 0
  let selectedTextLength = to - from

  // Collect all marks from the entire document
  state.doc.descendants((node, pos) => {
    if (node.isText) {
      totalTextLength += node.text.length
      node.marks.forEach(mark => {
        marks.push({
          type: mark.type,
          attrs: mark.attrs,
          from: pos,
          to: pos + node.nodeSize
        })
      })
    }
  })

  // Combine marks of the same type and attributes
  const uniqueMarks = marks.reduce((acc, mark) => {
    const existingMarkIndex = acc.findIndex(m => 
      m.type.name === mark.type.name && 
      JSON.stringify(m.attrs) === JSON.stringify(mark.attrs)
    )
    if (existingMarkIndex === -1) {
      acc.push(mark)
    } else {
      const existingMark = acc[existingMarkIndex]
      existingMark.from = Math.min(existingMark.from, mark.from)
      existingMark.to = Math.max(existingMark.to, mark.to)
    }
    return acc
  }, [])

 const docText = state.doc.textContent
  const selectionText = state.doc.textBetween(from, to)
  const isAllSelected = docText === selectionText

  // const isAllSelected = selectedTextLength >= totalTextLength
  const info = {
    isAllSelected: isAllSelected,
    hasSelection: selectedTextLength > 0,
    marks: uniqueMarks,
    selectionFrom: from,
    selectionTo: to,
    totalTextLength: totalTextLength
  }
  updateSlideElementTextSelectionInfo(info)
}