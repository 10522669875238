
function isEffectivelyZero(number, threshold = 1e-4) {
  return Math.abs(number) < threshold;
}


//when dragging a clip we snap the start or end time to other clips 
// When dragging a clip we snap the start or end time to other clips
//When dragging non audio clip do not snap to audio clip

//const snapThresholdTime = 0.2;



//Ignore snaptype is optional start/end.  when resizing with left handle you want to ignore the snaps on the end of the clip.  when resizing right handle ignore start of clip snaps


export const getSnappedClipTimes = (pixelsPerSecond,newStartTime, draggedClip, allClips,sceneStartTime,sceneEndTime,ignoreSnapType) => {
	
	const snapThresholdPixels = 6
	// console.log(`get snapped clip  pixelsPerSecond ${pixelsPerSecond}`)	
	const snapThresholdTime = snapThresholdPixels / pixelsPerSecond;

	const sortedClips = allClips.filter(clip => clip.type != 'audio' && clip.type!=='zoom') //dont snap to audio track

	let closestSnapPointStart = newStartTime;
	let closestSnapPointEnd = newStartTime + draggedClip.duration;
	
	
	let minSnapDistanceStart = snapThresholdTime;
	let minSnapDistanceEnd = snapThresholdTime;

	sortedClips.forEach(clip => {
		if (clip.id !== draggedClip.id) {
			const startSnapDistanceStart = Math.abs(newStartTime - clip.startTime);
			const endSnapDistanceStart = Math.abs(newStartTime - (clip.startTime + clip.duration));
			const startSnapDistanceEnd = Math.abs(newStartTime + draggedClip.duration - clip.startTime);
			const endSnapDistanceEnd = Math.abs(newStartTime + draggedClip.duration - (clip.startTime + clip.duration));

			if (startSnapDistanceStart < minSnapDistanceStart) {
				closestSnapPointStart = clip.startTime;
				minSnapDistanceStart = startSnapDistanceStart;
			}

			if (endSnapDistanceStart < minSnapDistanceStart) {
				closestSnapPointStart = clip.startTime + clip.duration;
				minSnapDistanceStart = endSnapDistanceStart;
			}

			if (startSnapDistanceEnd < minSnapDistanceEnd ) {
				closestSnapPointEnd = clip.startTime;
				minSnapDistanceEnd = startSnapDistanceEnd;
			}

			if (endSnapDistanceEnd < minSnapDistanceEnd) {
				closestSnapPointEnd = clip.startTime + clip.duration;
				minSnapDistanceEnd = endSnapDistanceEnd;
			}
		}
	});


	//check if should snap to scene
	const snapToSceneStartDistance = newStartTime-sceneStartTime 
	if(snapToSceneStartDistance<minSnapDistanceStart){
		closestSnapPointStart = sceneStartTime+0.00001;
		minSnapDistanceStart = snapToSceneStartDistance;
	}


	//Take out snap to scene
	// const snapToSceneEndDistance = sceneEndTime-(newStartTime+draggedClip.duration)
	// if(sceneEndTime !== draggedClip.endTime && snapToSceneEndDistance<minSnapDistanceEnd){ //dont snap to the scene end if it is the last clip in scene
	// 	closestSnapPointEnd = sceneEndTime;
	// 	minSnapDistanceEnd = snapToSceneEndDistance;
	// }

	//
	// After all the clip snapping checks and scene start checks, but before final distance calculations:
	closestSnapPointStart = Math.max(sceneStartTime + 0.00001, closestSnapPointStart);


	let snapStartDistance = Math.abs(newStartTime - closestSnapPointStart);
	let snapEndDistance = Math.abs(newStartTime + draggedClip.duration - closestSnapPointEnd);


	if(ignoreSnapType=='start'){
		snapStartDistance=0
	}
	if(ignoreSnapType=='end'){
		snapEndDistance=0
	}



	// console.log(`snapStartDistance ${snapStartDistance}`)
	// console.log(`snapEndDistance ${snapEndDistance}`)

	//if is already snappe at the start then should snap at the end even through snapStartDistance is smaller (basically 0)


	if (!isEffectivelyZero(snapStartDistance) && snapStartDistance <= snapThresholdTime && (snapStartDistance <= snapEndDistance || snapEndDistance==0)&& closestSnapPointStart !== newStartTime) {
		return {
			startTime: closestSnapPointStart,
			endTime: closestSnapPointStart + draggedClip.duration,
			snapAmount:snapStartDistance,
			snapType:'start'
		};
	} else if (!isEffectivelyZero(snapEndDistance) && snapEndDistance <= snapThresholdTime && closestSnapPointEnd !== newStartTime + draggedClip.duration) {
		return {
			startTime: closestSnapPointEnd - draggedClip.duration,
			endTime: closestSnapPointEnd,
			snapAmount:snapEndDistance,
			snapType:'end'
		};
	} else {
		return {
			startTime: newStartTime,
			endTime: newStartTime + draggedClip.duration,
			snapAmount:null,
			snapType:null
		};
	}
};



