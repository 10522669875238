import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const RedirectAuthenticated = ({
	path,
	location,
	isAuthenticated,
	willAuthenticate,
	component: Component,
}) =>
{
	if (isAuthenticated ){
		 return( 
			<Redirect to={{ pathname: '/library' }} /> 
		)
	} 
	else if(!isAuthenticated && !willAuthenticate){
		return(	
			<Route
		 		exact
		 		path={path}
		 		render={(props) => {
		 			return <Component {...props} /> }}
			/>
			
		)
	}
	else{
		// console.log('HERE-------')
		// console.log(path)
		// console.log(location)
		// console.log(isAuthenticated)
		// console.log(willAuthenticate)
		
		return null
	} 
}

export default RedirectAuthenticated