import { convertZoomValuesToZoomBox } from './convertZoomValuesToZoomBox';

export const calculateManualZoomSequence = (zoomClip) => {
    const cameraBoxSequence = {}; // Now an object

    const { startTime, duration, metadata: { zoomValues } } = zoomClip;
    const endTime = startTime + duration;

    let cameraBox = convertZoomValuesToZoomBox({
        originX: zoomValues.originX,
        originY: zoomValues.originY,
        scale: zoomValues.scale
    });

    const endMotionSettings = zoomValues.endMotionSettings || zoomValues.motionSettings || 'zoomSmooth';

    cameraBoxSequence[startTime] = { ...cameraBox, motionSettings: zoomValues.motionSettings };
    cameraBoxSequence[endTime] = { ...cameraBox, motionSettings: endMotionSettings };

    return cameraBoxSequence;
};
