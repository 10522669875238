import React, { useState } from 'react';
import Icon from '../../../../misc/Icon';
import * as Tooltip from '@radix-ui/react-tooltip';

const SpreadsheetRowBtns = ({cellHeight,barRowHighlightStates,setBarRowHighlightStates, focusSpreadsheet}) => {
  // Initialize an array of 6 buttons, each with a default rowStatus
  // const initialRowStatuses = Array(6).fill('default');
  // const [rowStatuses, setRowStatuses] = useState(initialRowStatuses);

  // Function to handle button click and update rowStatus
  const toggleRowStatus = (index, event) => {
    event.preventDefault();  // Prevent the default action of the button
    event.stopPropagation(); // Stop the click event from bubbling up


    const newRowStatuses = [...barRowHighlightStates];
    switch (newRowStatuses[index]) {
      case 'default':
        newRowStatuses[index] = 'highlight';
        break;
      case 'highlight':
        newRowStatuses[index] = 'lowlight';
        break;
      case 'lowlight':
        newRowStatuses[index] = 'default';
        break;
      default:
        break;
    }
    setBarRowHighlightStates(newRowStatuses);

    focusSpreadsheet();
  };

  // Map over rowStatuses to render buttons
  return (
    <div className='rightPanel--charts-spreadsheet-rowStatesList'>
      {barRowHighlightStates.map((status, index) => (
        <Tooltip.Root delayDuration={0}>
          <Tooltip.Trigger asChild> 
            <button
              key={index}
              onClick={(e) => toggleRowStatus(index, e)}  // Pass the event to the handler
              className={`rightPanel--charts-spreadsheetRowBtn rightPanel--charts-spreadsheetRowBtn--state--${status}`}
              style={{ height: `${cellHeight}px` }} // replace with the desired height
            >
              {status == 'default' &&
                <Icon name='circleEmpty' />
              }
              {status == 'highlight' &&
                <Icon name='circleFull' />
              }
              {status == 'lowlight' &&
                <Icon name='circleDashed' />
              }
            </button> 
          </Tooltip.Trigger>        
          <Tooltip.Portal>
          <Tooltip.Content side="left" className="tooltip">
            {status == 'default' && 
              <span>Default</span>
            }
            {status == 'highlight' && 
              <span>Highlighted</span>
            }
            {status == 'lowlight' && 
              <span>Lowlighted</span>
            }

          </Tooltip.Content>    
          </Tooltip.Portal>                      
        </Tooltip.Root>    

        
      ))}
    </div>
  );
};

export default SpreadsheetRowBtns;
