import React, { useState, useEffect } from 'react';
import Icon from '../../misc/Icon';
import { convertZoomValuesToZoomBox } from '../../../three/utils/zoom/convertZoomValuesToZoomBox'; 

const ZOOM_MIN = 0.55
const ZOOM_MAX = 0.95

const EditorCanvasZoomAutoUI = ({ canvasWidth, canvasHeight, updateZoomValues, updateZoomBox, zoomValues, clipId }) => {
        
    const handleWheel = (event) => {        
      const scaleChange = event.deltaY * -0.001; // Adjust this value for faster or slower zoom
      const newScale = Math.min(Math.max(zoomValues.scale + scaleChange, ZOOM_MIN), ZOOM_MAX); // Ensure newScale is within bounds
      
      // Update local zoom values
      const updatedZoomValues = {
          ...zoomValues,
          scale: newScale
      };
      
      updateZoomValues(clipId, updatedZoomValues);        
      const updatedZoomBox = convertZoomValuesToZoomBox(updatedZoomValues);
      updateZoomBox(clipId, updatedZoomBox);     
    };
    

    return (
        <div className='editor-canvasZoomUI' onWheel={handleWheel} style={{ width: canvasWidth, height: canvasHeight, overflow: 'hidden' }}>
            
       </div>
    );
};

export default EditorCanvasZoomAutoUI;
