import React, { useRef, useEffect, useState } from 'react';
import { TorusGeometry, MeshBasicMaterial, Mesh } from 'three';
import renderOrders from '../../../three/renderOrders'

const CHDonutFullRing = ({outerRadius, innerRadius, color, opacity}) => {

  const donutRef = useRef();
  
  const tubeRadius = (outerRadius - innerRadius) / 2;
  const radius = innerRadius + tubeRadius; // Center radius of the torus

  // render order doesn't seem to be working quite right here
  
  return (
    <>

      <mesh ref={donutRef} position={[0, 0, 0]} renderOrder={renderOrders.graphObject}>
        <torusGeometry args={[radius, tubeRadius, 30, 100]} renderOrder={renderOrders.graphObject} />
        <meshBasicMaterial color={color} transparent={true} opacity={opacity} renderOrder={renderOrders.graphObject} />

      </mesh>

  

    </>
  );
};

export default CHDonutFullRing;
