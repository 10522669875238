export const SLIDE_WIDTH = 1920;
export const SLIDE_HEIGHT = 1080;
export const FLOAT_CAMERA_INSET = 40;


export const webcamBorderRadius = 24

export const defaultColorAdjustments={
  exposure:0,
  highlights:0,
  shadows:0,
  brightness:0,
  contrast:0,
  saturation:0,
  whiteBalance:0
}


export const defaultWebcamLayout={	
	isFullScreen:false,
	isFixed:false,
	size:"smallSquare",
	position:"bottomLeft"
}


export const defaultSlideClipWebcamLayout={
  size:"mediumPortrait",
  position:"bottomRight"
}


export const webcamPositions = [
  {name:'topLeft',          anchor: "bottomRight",   altPositions: ['middleLeft', 'fillLeft', 'center']},
  {name:'middleLeft',       anchor: "topRight",      altPositions: ['bottomLeft', 'fillLeft', 'center']},
  {name:'bottomLeft',       anchor: "topRight",      altPositions: ['middleLeft', 'fillLeft', 'center']},
  {name:'fillLeft',         anchor: "topRight",      altPositions: ['bottomLeft', 'middleLeft', 'center']},
  {name:'center',           anchor: "topLeft",       altPositions: ['bottomRight', 'middleRight', 'fillRight']},
  {name:'topRight',         anchor: "bottomLeft",    altPositions: ['middleRight', 'fillRight', 'center']},
  {name:'middleRight',      anchor: "topLeft",       altPositions: ['bottomRight', 'fillRight', 'center']},
  {name:'bottomRight',      anchor: "topLeft",       altPositions: ['middleRight', 'fillRight', 'center']},
  {name:'fillRight',        anchor: "topLeft",       altPositions: ['bottomRight', 'middleRight', 'center']},
];


export const webcamSizes = [
  {name:'xSmallPortait',    width: 240,    height: 300, validPositions: ["topLeft", "bottomLeft", "topRight", "bottomRight"]},
  {name:'xSmallSquare',     width: 280,    height: 280, validPositions: ["topLeft", "bottomLeft", "topRight", "bottomRight"]},
  {name:'xSmallLandscape',  width: 340,    height: 240, validPositions: ["topLeft", "bottomLeft", "topRight", "bottomRight"]},
  {name:'smallPortait',     width: 360,    height: 440, validPositions: ["topLeft", "bottomLeft", "topRight", "bottomRight"]},
  {name:'smallSquare',      width: 440,    height: 440, validPositions: ["topLeft", "bottomLeft", "topRight", "bottomRight"]},
  {name:'smallLandscape',   width: 480,    height: 380, validPositions: ["topLeft", "bottomLeft", "topRight", "bottomRight"]},
  {name:'mediumPortrait',   width: 520,    height: 670, validPositions: ["topLeft", "bottomLeft", "middleLeft", "topRight", "middleRight", "bottomRight"]},
  {name:'mediumSquare',     width: 600,    height: 600, validPositions: ["topLeft", "bottomLeft", "middleLeft", "topRight", "middleRight", "bottomRight"]},
  {name:'mediumLandscape',  width: 670,    height: 520, validPositions: ["topLeft", "bottomLeft", "middleLeft", "topRight", "middleRight", "bottomRight"]},
  {name:'largePortrait',    width: 700,    height: 920, validPositions: ["middleLeft", "middleRight"]},
  {name:'largeSquare',      width: 900,    height: 900, validPositions: ["middleLeft", "middleRight"]},
  {name:'largeLandscape',   width: 890,    height: 720, validPositions: ["middleLeft", "middleRight"]},
  {name:'halfFloat',        width: 890,    height:1010, validPositions: ["middleLeft", "middleRight"]},
  {name:'halfFill',         width: 960,    height:1080, validPositions: ["fillLeft", "fillRight"]},
  {name:'overHalfFloat',    width: 1200,   height:960, validPositions: ["middleLeft", "middleRight"]},
  {name:'overHalfFill',     width: 1120,   height:1080, validPositions: ["fillLeft", "fillRight"]},
  {name:'fullScreen',       width: 1920,   height:1080, validPositions: ["center"]}
];




export const webcamClipDefaultMetadata={
  backgroundId:'none',
  startTransitionType:'none',
  endTransitionType:'none',
  isAutoMotionStyle:true,
  motionStyle:"smooth",
  label:"placeholder webcam",
  positionStyle:'right',
  zoom:0,
  xOffset:0,
  yOffset:0,
  layout:defaultWebcamLayout
}
  
