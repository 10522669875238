import React , { useState , useEffect, useRef} from 'react';
import find from 'lodash/find';
import {getBackgroundForId} from '../../../utils/brands/getBackgroundForId'
import {getTextColorForBackgroundId} from '../../../utils/brands/getTextColorForBackgroundId'
import {getTextColorForId} from '../../../utils/brands/getTextColorForId'
import Icon from '../../misc/Icon'
import { fontWeightToNumber } from '../../../three/utils/fontWeightToNumber';
import {fetchLegacyVideoURL} from '../../../utils/recordings/legacyScreenRecordings/getLegacyMasterRecordingData'
import {fetchScreenRecordingURL} from '../../../utils/recordings/screenRecordings/getMasterRecordingData'


///TODO if first clip is a video do we want to use the background color is not auto

function LibraryProjectItemPreview({project, scaledBorderRadius}) {

  const findFirstClip = () => {
	  if (project.timeline && project.timeline.scenes && project.timeline.scenes.length > 0) {
	    const allClips = project.timeline.scenes.flatMap(scene => scene.clips || []);
	    // Filter clips by 'textSlide' and 'video' types
	    const filteredClips = allClips.filter(clip =>
	      clip.type === 'textSlide' || (clip.type === 'video' && !clip.isBasicVideo && !clip.isDeviceRecording) //TODO basic video in preview
	    );
	    // Find the first clip by the smallest startTime
	    if (filteredClips.length > 0) {
	      return filteredClips.reduce((earliestClip, currentClip) =>
	        (earliestClip.startTime < currentClip.startTime ? earliestClip : currentClip)
	      );
	    }
	  }else if (project.timeline && project.timeline.clips && project.timeline.clips.length > 0) { //old pre scenes stuff
	       // Filter clips by 'textSlide' and 'video' types
	      const filteredClips = project.timeline.clips.filter(clip => 
	         clip.type === 'textSlide' || (clip.type === 'video' && !clip.isBasicVideo && !clip.isDeviceRecording) //TODO basic video in preview
	      );
	      // Find the first clip by the smallest startTime
	      if (filteredClips.length > 0) {
	        return filteredClips.reduce((earliestClip, currentClip) => 
	          (earliestClip.startTime < currentClip.startTime ? earliestClip : currentClip)
	        );
	      }
	    }
	    return null;
	};


  let projectBackground = getBackgroundForId(project.background)
	let previewBG = projectBackground
  const firstClip = findFirstClip();
  // Extract the type of the first clip
  const firstClipType = firstClip ? firstClip.type : 'No Clip';
  let textSlideColor
  let textSlideBG
  let textSlideText
  let textSlideTextAlign

  if(firstClip && firstClipType === 'textSlide'){
		const backgroundId = firstClip.metadata.backgroundId
		textSlideBG = projectBackground
		if(backgroundId && backgroundId!=='none'){
			textSlideBG=getBackgroundForId(backgroundId)
		}
		const textColorIsAuto = !firstClip.metadata.textColorId
  	if(textColorIsAuto){
    	textSlideColor = getTextColorForBackgroundId(textSlideBG.id)
  	}else{
    	textSlideColor = getTextColorForId(firstClip.metadata.textColorId)
  	}
  	textSlideText = firstClip.metadata.text
  	textSlideTextAlign = firstClip.metadata.textAlign
  }

  if(project.id === '463782364'){
  	textSlideText = 'Fancy agencies'
  }

  let textSlideStyle
  let textSlideFlexAlign
  if(textSlideTextAlign && textSlideTextAlign === 'left'){
  	textSlideFlexAlign = 'flex-start'
  }
  if(textSlideTextAlign && textSlideTextAlign === 'center'){
  	textSlideFlexAlign = 'center'
  }
  if(textSlideTextAlign && textSlideTextAlign === 'right'){
  	textSlideFlexAlign = 'flex-end'
  }


  if(firstClip && firstClipType === 'textSlide' && textSlideColor){
  	textSlideStyle= {
  		fontFamily: `${firstClip.metadata.fontFamily}`,
			fontWeight: `${fontWeightToNumber(firstClip.metadata.fontWeight)}`, 
			fontSize: `${firstClip.metadata.fontSize}px`,		
			lineHeight: `${firstClip.metadata.lineHeight}`,		
			letterSpacing: `${firstClip.metadata.letterSpacing}em`,		
			color: `${textSlideColor.rgba}`,
			justifyContent: `${textSlideFlexAlign}`,
			textAlign: `${textSlideTextAlign}`
  	}  
  }
  
  // 
  // ATTIO

  if(project.id === '1118139449'){ // ATTIODECEMBER
  	textSlideStyle= {
  		fontFamily: 'YGilroy',
			fontWeight: `${fontWeightToNumber('Bold')}`, 
			fontSize: '104px',
			lineHeight: '1.13',		
			letterSpacing: `-0.020em`,		
			color: `rgb(255,255,255)`,			
  	}
  }

  if(project.id === '139352495'){ // Notion University
  	textSlideStyle= {
  		fontFamily: 'YSFProDisplay',
			fontWeight: `${fontWeightToNumber('Semibold')}`, 
			fontSize: '82px',
			lineHeight: '1.115',		
			letterSpacing: `0.004em`,		
			color: `rgb(0,0,0)`,			
  	}
  }

   if(project.id === '1199967705'){ // Notion 2.32
  	textSlideStyle= {
  		fontFamily: 'YSFProDisplay',
			fontWeight: `${fontWeightToNumber('Semibold')}`, 
			fontSize: '96px',
			lineHeight: '1.115',		
			letterSpacing: `0.004em`,		
			color: `rgb(255,255,255)`,			
  	}
  }


  if(project.id === '364377278'){ // Causal Simulations Release
  	textSlideStyle= {
  		fontFamily: 'YUntitledSans',
			fontWeight: `${fontWeightToNumber('Medium')}`, 
			fontSize: '82px',
			lineHeight: '1.1',		
			letterSpacing: `-0.032em`,
			color: `rgb(0,0,0)`,			
  	}
  }

  if(project.id === '2018311596'){ // V12 Proto Linear
  	textSlideStyle= {
  		fontFamily: 'YInter',
			fontWeight: `${fontWeightToNumber('Regular')}`, 
			fontSize: '122px',
			lineHeight: '1.1',		
			letterSpacing: `-0.017em`,
			color: `rgb(255,255,255)`,			
  	}
  }

  //
  // SCREEN VIDEO
	
 	const [videoUrl, setVideoUrl] = useState(null);
 	const videoRef = useRef(null); 


  // Define pauseVideoAtSeekTime function
  const pauseVideoAtSeekTime = () => {
    if (videoRef.current && screenVideoSeekTime) {
      videoRef.current.currentTime = screenVideoSeekTime;
      videoRef.current.pause();
    }
  };


  useEffect(() => {
	  const fetchVideo = async () => {
      if (firstClip && firstClipType === 'video') {
        const captureId = firstClip.captureId;
        if(firstClip.isScreenRecording){
					try {
						const url = await fetchScreenRecordingURL(captureId);
						setVideoUrl(url);  // Set the fetched URL to state
					} catch (error) {
						console.error('Error fetching video URL:', error);          
					}
        }
        else{
	        try {
	          const url = await fetchLegacyVideoURL(captureId);
	          setVideoUrl(url);  // Set the fetched URL to state
	        } catch (error) {
	          console.error('Error fetching video URL:', error);          
	        }
	      }
      }
	  };
	  fetchVideo();  // Call the async function
   }, [firstClip]);

	let screenVideoSeekTime
	let screenVideoWidth
	let screenVideoHeight
	
	if(firstClip && firstClipType === 'video'){
		 screenVideoSeekTime = firstClip.metadata.trimStart
		 screenVideoWidth = firstClip.metadata.meshWidth
		 screenVideoHeight = firstClip.metadata.meshHeight
  }

  useEffect(() => {
    pauseVideoAtSeekTime();
  }, [videoUrl, screenVideoSeekTime]); // Depend on videoUrl and screenVideoSeekTime

	//
  // BACKGROUND

	if(firstClip && firstClipType === 'textSlide' && textSlideBG){
		previewBG = textSlideBG		
	}

	let previewBGSolid
	let previewBGImage

	if(previewBG && previewBG.type === 'solid'){
		previewBGSolid = previewBG.rgba
	}

	if(previewBG && previewBG.type === 'image'){
		previewBGImage = previewBG.src
	}

	if(project.id === '1118139449' || project.id === '710775157'){ // ATTIODECEMBER and ATTIO Q4 Enterprise Demo
		previewBGSolid = 'rgba(60, 108, 232, 1)'
	}

	if(project.id === '1199967705'){ // Notion 2.32 Release
		previewBGSolid = 'rgba(0, 0, 0, 1)'
	}

	if(project.id === '139352495'){ // Notion Univeristy
		previewBGSolid = 'rgba(246, 246, 244, 1)'
	}

	if(project.id === '1681855067'){ // LINEAR SETUP GUIDE
		previewBGSolid = null
		previewBGImage = '../bgImages/linear/darkpurple.png'
	}

	if(project.id === '364377278'){ // LINEAR SETUP GUIDE
		previewBGSolid = null
		previewBGImage = '../bgImages/causal/causalBG.png'
	}

	if(project.id === '2018311596'){ // V12 PROTO LINEAR
		previewBGSolid = null
		previewBGImage = '../bgImages/linear/greenred.png'
	}

	return (
		<div className='libraryPage-project-preview'>
			<div className='libraryPage-project-preview-content'>
				{firstClipType === 'textSlide' && textSlideStyle && textSlideText &&
					<div style={textSlideStyle} className='libraryPage-project-preview-content-textSlide'>
						{textSlideText}
					</div>			
				}
				{firstClipType === 'video' && videoUrl &&
          <div style={{width: `${screenVideoWidth}px`, height: `${screenVideoHeight}px`}} className='libraryPage-project-preview-content-screenVideo'>
	          <video
	          	className='libraryPage-project-preview-content-screenVideo-video'
	            preload="none" 
	            ref={videoRef}
	            src={videoUrl}            
	            onLoadedMetadata={pauseVideoAtSeekTime} // Pause video when it's ready
	          />
          </div>
        }
			</div>
			{previewBGSolid &&
				<div style={{background: `${previewBGSolid}`, borderRadius: `${scaledBorderRadius}px`}} className='libraryPage-project-preview-content-bg libraryPage-project-preview-content-bg--solid' />
			}
			{previewBGImage &&
				<div className='libraryPage-project-preview-content-bg libraryPage-project-preview-content-bg--image'>
					<img style={{borderRadius: `${scaledBorderRadius}px`}} src={previewBGImage} />
				</div>
			}
		</div>
	);
}

export default LibraryProjectItemPreview;
