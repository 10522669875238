
import store from '../../store'



export async function downloadMissingMusic(tracks) {
	const tracksList = await ipcRenderer.invoke('get-music-uploads-list'); 
	for (const track of tracks) {
		const trackId=track.id
		if (!tracksList.includes(trackId)) {
			console.log(`Downloading missing music track: ${trackId}`);
			ipcRenderer.invoke('download-music-file',trackId,track.delivery_url) 
			store.dispatch({ type: 'SET_MUSIC_FILE_TO_DOWNLOAD', trackId})
		}else{
		//	console.log('we already have the recording ')
		}
	}
}
