import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import createAdvancedRoundedRectShape from './createAdvancedRoundedRectShape';

const CHRectFill = ({ height, width, borderRadius, fillColor, fillOpacity }) => {
  const middleRectRef = useRef();
  
  const topCenterCapRef = useRef();
  const bottomCenterCapRef = useRef();


  const cornerRefs = useRef([]);
  cornerRefs.current = [useRef(), useRef(), useRef(), useRef()]; // One ref for each corner


  useEffect(() => {
    // Adjust the scale of the middle rectangle
  if (middleRectRef.current) {
    // Scale calculations
    const newHeightScale = (height - borderRadius) / borderRadius;
    const newWidthScale = (width + borderRadius) / borderRadius;

    middleRectRef.current.scale.x = newWidthScale;
    middleRectRef.current.scale.y = newHeightScale;

    // Position adjustments
    // Anchor to the bottom for height
    middleRectRef.current.position.y = (newHeightScale * borderRadius / 2) + borderRadius;

    // Anchor to the left for width
    middleRectRef.current.position.x = (newWidthScale * borderRadius / 2);
  }

  // Adjust the position and scale of the top center cap
  if (topCenterCapRef.current) {
    const newCenterTopWidthScale = (width - (borderRadius)) / borderRadius;

    topCenterCapRef.current.position.x = (newCenterTopWidthScale * borderRadius / 2) + borderRadius

    topCenterCapRef.current.scale.x = newCenterTopWidthScale;

    topCenterCapRef.current.position.y = height - borderRadius / 2 + borderRadius;
    
  }

  // Adjust the position and scale of the bottom center cap
  if (bottomCenterCapRef.current) {      
    const newCenterBottomWidthScale = (width - (borderRadius)) / borderRadius;
    
    bottomCenterCapRef.current.position.x = (newCenterBottomWidthScale * borderRadius / 2) + borderRadius

    bottomCenterCapRef.current.scale.x = newCenterBottomWidthScale;

    bottomCenterCapRef.current.position.y = borderRadius / 2;
    
  }

    const rotations = [270, 180, 90, 0]; // Rotations for each corner
    cornerRefs.current.forEach((ref, index) => {
      if (ref.current) {
        const pos = getPositionForCorner(index, width, height, borderRadius);
        ref.current.position.set(...pos);
        ref.current.rotation.set(0, 0, THREE.MathUtils.degToRad(rotations[index]));
      }
    });


  }, [height, width, borderRadius]);


  // Shared geometry for all corners
  const cornerGeometry = new THREE.ShapeGeometry(
    createAdvancedRoundedRectShape(borderRadius, borderRadius, borderRadius, 0, 0, 0)
  );
  const centerCapGeometry = new THREE.PlaneGeometry(borderRadius, borderRadius);
  const middleRectGeometry = new THREE.PlaneGeometry(borderRadius, borderRadius);

  return (
    <>
      {/* Corners */}
       
      {cornerRefs.current.map((ref, index) => (
        <mesh key={index} ref={ref} geometry={cornerGeometry}>
          <meshBasicMaterial attach="material" transparent={true} color={fillColor} opacity={fillOpacity} />
        </mesh>
      ))}


      {/* Top Center Cap */}
      <mesh ref={topCenterCapRef} geometry={centerCapGeometry} position={[0, 0, 0]} scale={[1, 1, 1]}>
        <meshBasicMaterial attach="material" transparent={true} color={fillColor} opacity={fillOpacity} />
      </mesh>
      

      {/* Middle Rectangle*/}
      <mesh ref={middleRectRef} geometry={middleRectGeometry} position={[0, 0, 0]} scale={[1, 1, 1]}>
        <meshBasicMaterial attach="material" transparent={true} color={fillColor} opacity={fillOpacity} />
      </mesh>
      
       

      {/* Bottom Center Cap*/}
      <mesh ref={bottomCenterCapRef} geometry={centerCapGeometry} position={[0, 0, 0]} scale={[1, 1, 1]}>
        <meshBasicMaterial attach="material" transparent={true} color={fillColor} opacity={fillOpacity} />
      </mesh>
       
      
       

    </>
  );
};

function getPositionForCorner(index, width, height, borderRadius) {
  let x, y;

  // For left corners (top left: 0, bottom left: 3)
  if (index === 0 || index === 3) {
    x = borderRadius / 2; // X position is at 0 for left corners
  }
  // For right corners (top right: 1, bottom right: 2)
  else {
    x = width - borderRadius / 2 + borderRadius; // X position is at the width for right corners
  }

  // Top corners (0: top left, 1: top right)
  if (index === 0 || index === 1) {
    y = height - borderRadius / 2 + borderRadius; // Y position changes with height
  }
  // Bottom corners (2: bottom right, 3: bottom left)
  else {
    y = borderRadius / 2; // Y position remains constant
  }

  return [x, y, 0];
}


export default CHRectFill;
