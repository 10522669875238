import React, { useRef, useEffect, useMemo } from 'react';
import CHRectBorderEdges from './CHRectBorderEdges'
import CHRectBorderCorners from './CHRectBorderCorners'

const CHRectBorder = ({ height, borderRadius, width, borderColor, borderOpacity, borderLineWidth }) => {

  const adjustedBorderRadius = borderRadius

  const adjustedBorderWidth = Math.max(width - borderRadius - borderRadius, borderRadius)
  const adjustedBorderHeight = Math.max(height - borderRadius - borderRadius, borderRadius)

  const adjustedWidth = adjustedBorderWidth + borderLineWidth + borderLineWidth;
  const adjustedHeight = adjustedBorderHeight + borderLineWidth + borderLineWidth;
  

  return (
    <>
      
      
      <CHRectBorderCorners 
        adjustedWidth={adjustedWidth}
        adjustedHeight={adjustedHeight}
        adjustedBorderRadius={adjustedBorderRadius}
        borderColor={borderColor}
        borderOpacity={borderOpacity}
        borderLineWidth={borderLineWidth}
      />

      

      
      <CHRectBorderEdges
        adjustedHeight={adjustedHeight}
        adjustedWidth={adjustedWidth}
        adjustedBorderRadius={adjustedBorderRadius}
        borderColor={borderColor}
        borderOpacity={borderOpacity}
        borderLineWidth={borderLineWidth}
      />
      
      
    </>
  );
};

export default CHRectBorder;
