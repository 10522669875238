import React, { useEffect, useState, useMemo } from 'react';
import * as THREE from 'three';
import renderOrders from '../../../renderOrders'

const SlideDonutChartPartRing = ({ percentFill, outerRadius, innerRadius, color, opacity }) => {
  const arc = 2 * Math.PI * percentFill;
  const [rotation, setRotation] = useState([0, 0, 0]);

  useEffect(() => {
    const initialRotation = Math.PI / 2;
    const arcAdjustment = -arc;
    setRotation([0, 0, initialRotation + arcAdjustment]);
  }, [percentFill, arc]);

  const geometry = useMemo(() => {
    const shape = new THREE.Shape();
    
    // Outer arc
    shape.moveTo(outerRadius, 0);
    shape.absarc(0, 0, outerRadius, 0, arc, false);
    
    // Line to inner radius
    shape.lineTo(innerRadius * Math.cos(arc), innerRadius * Math.sin(arc));
    
    // Inner arc
    shape.absarc(0, 0, innerRadius, arc, 0, true);
    
    // Close the shape
    shape.lineTo(outerRadius, 0);

    // Increase the number of segments for a smoother circle
    const segments = Math.max(64, Math.floor(arc * 64 / (2 * Math.PI)));

    return new THREE.ShapeGeometry(shape, segments);
  }, [outerRadius, innerRadius, arc]);  

  return (
    <mesh
      position={[0, 0, 0]}
      rotation={rotation}
      renderOrder={renderOrders.graphObject}            
    >
      <primitive object={geometry} />
      <meshBasicMaterial color={color} transparent={true} opacity={opacity} side={THREE.DoubleSide} renderOrder={renderOrders.graphObject} />
    </mesh>
  );
};

export default SlideDonutChartPartRing;