import React, { useMemo, useEffect } from 'react';
import * as THREE from 'three';

const ImageMaterial = ({ opacity, imgSrc, renderOrder }) => {
  // Use useMemo to load the image texture and ensure it's only loaded once or when imageSrc changes
  const texture = useMemo(() => {
    const loader = new THREE.TextureLoader();
    // Load the image texture from the provided URL
    const texture = loader.load(imgSrc, (tex) => {
      // This callback function is executed when the texture is successfully loaded
      tex.colorSpace = THREE.SRGBColorSpace;

    });
    return texture;
  }, [imgSrc]);

  // Create the material with the image texture
  const material = useMemo(() => {
    return new THREE.MeshBasicMaterial({
      map: texture, // Use the loaded image texture
      transparent: true, // Allow transparency to handle any PNG transparency or similar effects
      opacity: opacity, // Control the opacity of the texture
    });
  }, [texture, opacity]);

  // Use useEffect to set the renderOrder after the material is created
  useEffect(() => {
    if (material && renderOrder !== undefined) {
      material.renderOrder = renderOrder;
    }
  }, [material, renderOrder]);

  return <primitive object={material} attach="material" />;
};

export default ImageMaterial;