import {randomID} from '../../../utils/randomID'
import {TextElement} from '../slideElements/TextElement'
import {ImageElement} from '../slideElements/ImageElement'
import {ChartElement} from '../slideElements/ChartElement'
import {getOrgBrand} from '../../../utils/brands/getOrgBrand'
import { numberChart, donutChart, barChart } from '../../../components/editor/chartData';

const DEFAULT_Z_INDEX= 500

export function makeTextElement(slideClip,elementId){
	let textStyle='title'
    const orgBrand=getOrgBrand()
    if(orgBrand.defaultTextStyle){
    	textStyle=orgBrand.defaultTextStyle
    }
	const elements = slideClip.elements 
	//const textElementsCount = elements.filter(element => element.type === 'text').length
	const verticalOrderIndex = slideClip.elements.length
	let elementText=`Text`
	let paragraphs=[]
	paragraphs.push({
		type: "paragraph",
		attrs: { indentLevel: 0 },
		content: [
			{ type: "text", text: elementText }
		]
	});

	const nodeJson = JSON.stringify({
		type: "doc",
		content: paragraphs
	})

	// const xPos =480
	// const yPos =478

		const xPos =0
	const yPos =0

	const el=	{
			"id": elementId || randomID(),
			"type": "text",
			"metadata":{
				"textOpacity":1,
				"animationOrderIndex":verticalOrderIndex,
				"zOrder":verticalOrderIndex,
				"variables":slideClip.meta,
				"backgroundId":'none',
				"textStyle":textStyle,
				"text":elementText,
				"docJson":nodeJson,
				"startTransitionType":'fadeAndMoveUp',
				"endTransitionType":'fadeAndMoveDown',
				"animationGrouping":'everything',
				"motionStyle": "smooth",
				"enterTransitionValueFactor":0, //animation settings
				"enterTransitionSpeedFactor":0,//animation settings
				"enterTransitionBouncinessFactor":0,//animation settings
				"wordEnterDelay":0.3,
				"lineEnterDelay":0.6,
				"textStyle":'title',
				"textHAlign":'center', //center, left or right 
				"textVAlign":'top', //top, middle or bottom
				//"textResizeType" : 'autoWidth', // autoHeight, fixed
				"textResizeType" : 'autoHeight', // autoHeight, fixed
				"lettersArray":[],
				"textColorId":null,
				"enterDelay":0,
				"variableTextOptions":{ //options for text elements with variable text
					"noWrap":false,
					"maxLines":null
				}
					},
			"bottomMargin":50,
			"rightMargin":50,
			"width": 1360,
			"height": 40,
			"x": xPos,
			"y": yPos,		
		}
			return new TextElement(el,slideClip)
 
}



const imgSrc="http://res.cloudinary.com/yarn/image/upload/v1714086693/image_uploads/1798620531.png"

export function makeImageElement(slideClip,isVariable,elementId){
	const elements = slideClip.elements 
	const verticalOrderIndex = slideClip.elements.length
	const el=	{
			"id": elementId || randomID(),
			"type": "image",
			"metadata":{
				"isFill":false,
				"opacity":1,
				"cornerRounding":0,
				"zOrder":verticalOrderIndex,
				"animationOrderIndex":verticalOrderIndex,
				//"verticalOrderIndex":verticalOrderIndex,
				"variables":[],
				"isVariable":isVariable,
				"backgroundId":'none',
				"startTransitionType":'fadeAndMoveUp',
				"endTransitionType":'fadeAndMoveDown',
				"enterTransitionValueFactor":0, //animation settings
				"enterTransitionSpeedFactor":0,//animation settings
				"enterTransitionBouncinessFactor":0,//animation settings
				"imgSrc":isVariable?null:imgSrc,	
				"isBGImage":false,			
				"original":{
					"width": 479,
					"height": 447,
				},
				"motionStyle": "smooth",
				"enterDelay":0
			},
			"width": 400,
			"height":400,
			"bottomMargin":50,
			"rightMargin":50,
			"x": 30,
			"y": 70,
			"zIndex":DEFAULT_Z_INDEX
		}

		return new ImageElement(el,slideClip)
 
}



export function makeChartElement(slideClip,elementId){
	const elements = slideClip.elements 
	const verticalOrderIndex = slideClip.elements.length

	const el=	{
		"id": elementId || randomID(),
		"type": "chart",
		"metadata":{
			"animationOrderIndex":verticalOrderIndex,
			"zOrder":verticalOrderIndex,
			"backgroundId":'none',
			"motionStyle": "smooth",
			"enterDelay":0,
			"activeChartType":'donut',
			"barSpacing":20,
			"barOpacity":0.18,
			"donutOpacity":0.5,
			"donutFillColorId":null,
			"uniformColorId":null,
			// "catagoryColorIds":["chartBlue", "chartGreen", "chartOrange", "chartPurple", "chartRed", "chartYellow"],
			"isUniformColor":true,
			"barChartData":{...barChart, motionStyle:'smooth'},
			// "numberChartData":{...numberChart, motionStyle:'smooth'},
			"donutChartData":{...donutChart, motionStyle:'smooth'},
		},
		"width": 1400,
		"height":800,
		"bottomMargin":50,
		"rightMargin":50,
		"x": 30,
		"y": 70,
		"zIndex":DEFAULT_Z_INDEX
	}
	return new ChartElement(el,slideClip)
}










