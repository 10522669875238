import React, {useState,useEffect} from 'react'
import Icon from '../../../misc/Icon'
import * as Tooltip from '@radix-ui/react-tooltip';
import Spreadsheet from './spreadsheet/Spreadsheet'
import { ToggleGroup, ToggleGroupBtn } from '../../../uiKit/ToggleUI';
import SampleBarChartData from './sampleBarChartData'
import { SmallTabGroup, SmallTab } from '../../../uiKit/SmallTabsUI';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'


 const motionOptions = [
    { value: 'auto', label: 'Auto' },
    { value: 'smooth', label: 'Smooth' },
    { value: 'bouncy', label: 'Bouncy' },
    { value: 'molasses', label: 'Molasses' },
    { value: 'slow', label: 'Slow' },
    { value: 'zippy', label: 'Zippy' },     
  ];


function ChartsPanelBarChart({spreadsheetData,setSpreadsheetData,barRowHighlightStates, setBarRowHighlightStates,setBarTitle, barTitle, animationType, setAnimationType,metadata,setMotionStyle}) {  

  const handleSampleDataClick = () => {
    const keys = Object.keys(SampleBarChartData); // Get all keys from the sample data object
    const randomKey = keys[Math.floor(Math.random() * keys.length)]; // Select a random key
    const selectedData = SampleBarChartData[randomKey]; // Get the data for the selected key
    setSpreadsheetData(selectedData); // Update the spreadsheet data with the selected sample data
  };

  // Handler to update the input data
  const handleTitleInputChange = (e) => {
    setBarTitle(e.target.value);
  };

   
  let motionStyle = metadata.motionStyle

  if(metadata.isAutoMotionStyle){
    motionStyle='Auto'
  }

  return (
    <>
      
      <div className='rightPanel--charts-titleInput'>
        <input 
          type="text" 
          placeholder="Chart Title (Optional)"
          className='rightPanel--charts-titleInput-input'
          id="numberInput" 
          value={barTitle} 
          onChange={handleTitleInputChange}
        />          
      </div>

      <div className='rightPanel--charts-spreadsheetContainer'>   
        {spreadsheetData && 
          <Spreadsheet             
            data={spreadsheetData}
            updateData={setSpreadsheetData}  
            barRowHighlightStates={barRowHighlightStates}  
            setBarRowHighlightStates={setBarRowHighlightStates}    
          />
        }
      </div>  

      <button className='uiButton' onClick={handleSampleDataClick}>
        <div className='uiButton-label'>
          Sample Data
        </div> 
      </button>

      <div className='rightPanel-separator' />


      {/*}
      <div className='rightPanel-subheader'>
        Direction
      </div>

      <div className='rightPanel-row rightPanel-row--fit1'>    

          <ToggleGroup fullWidth toggleCount={2}>
            <ToggleGroupBtn                
              active={true}
              //active={direction === 'vertical'}
              //handleClick={() => setAnimationType('none')}
              label='Vertical'                                
            />
            <ToggleGroupBtn                
              active={false}
              //active={direction === 'horizontal'}
              //handleClick={() => setAnimationType('countUp')}
              label='Horizontal'                  
            />            
          </ToggleGroup>        
      </div>

      */}


      <div className='rightPanel-row rightPanel-row--tabAndDropdown'>
                
        <SmallTabGroup>
          <SmallTab
            label='Animate In'
            active={true}
            //handleClick={() => setAnimateActiveTab('in')}              
            nullBar
          />
          {/*<SmallTab
            label='Animate Out'
            active={animateActiveTab === 'out'}
            handleClick={() => setAnimateActiveTab('out')}                              
          /> 
          */}         
        </SmallTabGroup>
        
     
      {/*}
        <DropdownMenu.Root modal={false}>
          <DropdownMenu.Trigger asChild>
            <button className='uiButton uiButton--motionStyle'>
              <div className='uiButton-iconContainer'>
                <Icon name='stopwatch' />
              </div>                
              <div className='uiButton-label'>
                {motionStyle}                
              </div>                
            </button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content             
            align="end"
            alignOffset={-48}
            className='dropdownMenu dropdownMenu--selectMotionPanel forceDarkTheme'
          >            
            <div className='dropdownMenu-explainer'>
              Slide animation settings.
            </div>
            {motionOptions.map(option => (
              <DropdownMenu.Item key={option.value} onSelect={() => setMotionStyle(option.value)} className={motionStyle === option.label ? 'dropdownMenu-item--active' : ''}>
                {option.label}
              </DropdownMenu.Item>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Root>  
        */}
 

      </div>

      <div className='rightPanel-row rightPanel-row--transitionBy rightPanel-row--transitionBy--empty' />                                        


        {/*
        <button 
          data-state={animationType === 'countUp' ? "active" : "inactive"}            
          onClick={() => setAnimationType('countUp')}
          className='uiButton uiButton--preview'
        >
          <div className='uiButton-label'>
            Count Up
          </div>            
        </button>
        */}

        <button 
          data-state={animationType === 'none' ? "active" : "inactive"}            
          onClick={() => setAnimationType('none')}
          className='uiButton uiButton--preview'
        >
          <div className='uiButton-label'>
            None
          </div> 
        </button>    

        <button 
          data-state={animationType === 'countUp' ? "active" : "inactive"}            
          onClick={() => setAnimationType('countUp')}
          className='uiButton uiButton--preview'
        >
          <div className='uiButton-label'>
            Count Up
          </div>            
        </button>

        {/*
        <button 
          data-state={animationType === 'scaleUp' ? "active" : "inactive"}            
          onClick={() => setAnimationType('scaleUp')}
          className='uiButton uiButton--preview'
        >
          <div className='uiButton-label'>
            Scale Up
          </div>            
        </button>      
        */}








    </>
  );
}

export default ChartsPanelBarChart;

