import React, { useState, useEffect,memo,useRef } from 'react';
import Icon from '../../misc/Icon';
import ReactSlider from 'react-slider';
import * as Tooltip from '@radix-ui/react-tooltip';
import SelectSoundsDropdown from '../../uiKit/SelectSoundsDropdown'
import {getBackgroundMusicTrackForId} from '../../../utils/backgroundMusic/getBackgroundMusicTrackForId'
import EditorTimelineFooterMusicVolumeControl from './EditorTimelineFooterMusicVolumeControl'
import { useSpring, animated } from 'react-spring';
import UpdatesPopover from '../../changelog/UpdatesPopover'
import EditorShortcutsPopover from '../popovers/EditorShortcutsPopover'

const EditorTimelineFooter = memo((props) => {
  
  const transformValue = (value) => Math.pow(value, 1 / 2.7); // Root 2.7 transformation
  const inverseTransformValue = (value) => Math.pow(value, 2.7); // Inverse transformation (cube)

  const [sliderValue, setSliderValue] = useState(transformValue(props.initialSliderValue || 0.08));

  const handleSliderChange = (value) => {
    setSliderValue(value);
    props.handleTimelineZoomChange(inverseTransformValue(value)); // Apply inverse transform
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if ((event.metaKey || event.ctrlKey) && (event.key === '=' || event.key === '-')) {
        if (event.key === '=') {
          incrementSlider();
        } else {
          decrementSlider();
        }
        event.preventDefault();
      }
    };

    window.addEventListener('keydown', handleKeyPress, true);

    return () => {
      window.removeEventListener('keydown', handleKeyPress, true);
    };
  }, []);

  const incrementSlider = () => {
    setSliderValue((prevValue) => {
      const newValue = Math.min(prevValue + 0.05, 1);
      props.handleTimelineZoomChange(inverseTransformValue(newValue));
      return newValue;
    });
  };

  const decrementSlider = () => {
    setSliderValue((prevValue) => {
      const newValue = Math.max(prevValue - 0.05, 0);
      props.handleTimelineZoomChange(inverseTransformValue(newValue));
      return newValue;
    });
  };

  const [soundDropdownOpen, setSoundDropdownOpen] = useState(false);

  const hideFooterElements = !soundDropdownOpen && props.timelineCmdIsDown

  const hideElementAnimationProps = useSpring({
    opacity: hideFooterElements  ? 0 : 1,
    transform: hideFooterElements ? 'translateY(15px)' : 'translateY(0px)',
    config: { tension: 400, friction: 24 },
    delay: hideFooterElements ? 0 : 75
  });

  const showAltTooltipAnimationProps = useSpring({
    opacity: hideFooterElements  ? 1 : 0,
    transform: hideFooterElements ? 'scale(1)' : 'scale(0.9)',
    config: { tension: 400, friction: 24 },
    delay: hideFooterElements ? 75 : 0
  });


  const hideBottomRightElementAnimationProps = useSpring({
    opacity: hideFooterElements  ? 0 : 1,    
    config: { tension: 400, friction: 24 },
    delay: hideFooterElements ? 0 : 75
  });



  const hasUnreadUpdates = false

  const [showUpdatesPopover, setShowUpdatesPopover] = useState(false);


  const openUpdatesPopover = () => {
    if (!showUpdatesPopover) {
      setShowUpdatesPopover(true);
    }
    if (showUpdatesPopover) {    
      setShowUpdatesPopover(false);
    }
  };  

  

  const openShortcutsPopover = () => {
    if (!props.showShortcutsPopover) {
      props.openShortcutsPopover();
    }
    if (props.showShortcutsPopover) {    
      props.closeShortcutsPopover();
    }
  };  



  return (
    <div data-promote-zindex-state={props.showShortcutsPopover ? true : false} data-pro-mode={props.userIsProMode ? true : false} className={'editor-timelineFooter ' + (props.isEmpty ?  ' editor-timelineFooter--isEmpty ' : '' )}>        

        <div className='editor-timelineFooter-leftContainer'>                

          {/*}*/}
          <UpdatesPopover
            showModal={showUpdatesPopover}
            closeModal={() => setShowUpdatesPopover(false)}            
          >          
            <button data-state={showUpdatesPopover ? 'active' : 'inactive'} onClick={openUpdatesPopover}  className='editor-timelineFooter-footerBtn editor-timelineFooter-footerBtn--updates'>
              {hasUnreadUpdates &&
                <div className='editor-timelineFooter-footerBtn--updates-unreadIndicator' /> 
              }              
              <div className='editor-timelineFooter-footerBtn-label'>
                Updates
              </div>
            </button>    

          </UpdatesPopover>
          
          

          {/*}
          <button className='editor-timelineFooter-supportBtn'> 
            Tutorials
          </button>
          <button className='editor-timelineFooter-supportBtn'>
            Support
          </button>
          */}

          {props.userIsProMode && 
            <div className='editor-timelineFooter-proShortcut'>
              Seek back ⇧⌘J &nbsp;&nbsp;&nbsp; Seek forward ⇧⌘K
            </div>          
          }
        </div>

        <div className='editor-timelineFooter-centerContainer'>

        
          <animated.div style={hideElementAnimationProps} className='editor-timelineFooter-addMusicBtnContainer'>
            <SelectSoundsDropdown
              updateBackgroundMusicTrack={props.updateBackgroundMusicTrack}
              activeBackgroundMusicTrack = {props.activeBackgroundMusicTrack}
              handleClosePopover={() => setSoundDropdownOpen(false)}
              handleNewMusicFileUpload={props.handleNewMusicFileUpload}
            >
              <button onClick={() => setSoundDropdownOpen(!soundDropdownOpen)} className={'uiButton uiButton--addMusic uiButton--addMusic--empty'}>                                          
                <div className='uiButton-iconContainer'>
                  <Icon name='musicNotes' />
                </div>
                <div className='uiButton-label'>
                  {props.activeBackgroundMusicTrack ?getBackgroundMusicTrackForId(props.activeBackgroundMusicTrack).title:"Add Sounds"}
                </div>              
              </button>
            </SelectSoundsDropdown>

            {props.activeBackgroundMusicTrack && 
              <EditorTimelineFooterMusicVolumeControl 
                backgroundMusicVolume={props.backgroundMusicVolume}
                updateBackgroundMusicVolume={props.updateBackgroundMusicVolume}
              />
            }
          </animated.div>


          <animated.div style={showAltTooltipAnimationProps} className='editor-timelineFooter-skipSegmentTooltipContainer'>
            <div className='editor-timelineFooter-skipSegmentTooltip'>
              {/*Drag and hold to select video segment*/}
              Drag-and-drop clips or resize with snapping
            </div>
          </animated.div>
          
        </div>


        <div className='editor-timelineFooter-rightContainer'>        
        {/*  */}

          {/*}
              <button onClick={props.deleteScene} className={'uiButton uiButton--addMusic'}>      
              delete Scene
              </button>

               <button onClick={props.addSceneToTimeline} className={'uiButton uiButton--addMusic'}>      
              add Scene
              </button>
          */}
        
          <div className='editor-timelineFooter-zoomSliderContainer'>
                  
            <div className='editor-timelineFooter-zoomSliderLabel'>
              Timeline
            </div>              
            
            <div className='editor-timelineFooter-zoomSliderInnerContainer'>
              <ReactSlider
                className="editor-timelineFooter-zoomSlider"
                thumbClassName="editor-timelineFooter-zoomSlider-thumb"
                trackClassName="editor-timelineFooter-zoomSlider-track"                  
                renderThumb={(props, state) => <div className='editor-timelineFooter-zoomSlider-thumb-thumb' {...props} />}
                min={0}
                max={0.95}
                step={0.01}
                value={sliderValue}
                onChange={handleSliderChange}
              />
            </div>
            
          </div>

          <button onClick={props.onZoomToFit} className='editor-timelineFooter-footerBtn'>
            <div className='editor-timelineFooter-footerBtn-iconContainer'>
              <Icon name='fitTimelineMedium' />
            </div>                              
            <div className='editor-timelineFooter-footerBtn-label'>
              Fit
            </div>            
          </button> 

          {/*}
            <button data-state={props.showShortcutsPopover ? 'active' : 'inactive'} onClick={openShortcutsPopover}  className='editor-timelineFooter-footerBtn editor-timelineFooter-footerBtn--shortcuts'>
              <div className='editor-timelineFooter-footerBtn-iconContainer'>
                <Icon name='keyboard' />
              </div>
              <div className='editor-timelineFooter-footerBtn-label'>
                Shortcuts
              </div>
            </button>  
          */}                           

      

        </div>

                      
    </div>
  );
})

export default EditorTimelineFooter;


