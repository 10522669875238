import {post,put,postCloudinary,fetch} from '../api'
import {downloadMissingImages} from '../utils/assets/downloadMissingImages'
import { Mixpanel } from '../Mixpanel'
import * as Sentry from '@sentry/electron/renderer'


function calculateDisplayWidth(originalWidth, originalHeight) {
  const maxWidth = 1600;
  const maxHeight = 900;
  let displayWidth = originalWidth;
  let displayHeight = originalHeight;
  if (originalWidth <= maxWidth && originalHeight <= maxHeight) { //Dont scale up
    return  displayWidth
  }
  const originalAspectRatio = originalWidth / originalHeight;
  const targetAspectRatio = maxWidth / maxHeight;
  if (originalAspectRatio > targetAspectRatio) {// If the image is wider than the target space, limit by width
    displayWidth = maxWidth;
    displayHeight = maxWidth / originalAspectRatio;
  } else {// If the image is taller than the target space, limit by height
    displayHeight = maxHeight;
    displayWidth = maxHeight * originalAspectRatio;
  }
  return Math.round(displayWidth)
}

export const uploadImageAndHandleResponse = (file, imageId) => async (dispatch) => {
  try {
    const signatureResponse = await post('/image/upload', { public_id: imageId });
    const { apiKey, timestamp, signature } = signatureResponse;
    let form = new FormData();
    form.append('file', file);
    form.append('api_key', apiKey);
    form.append('timestamp', timestamp);
    form.append('signature', signature);
    form.append('folder', 'image_uploads');
    form.append('public_id', imageId);
    form.append('media_metadata', true);
    form.append('eager', 'f_png')

    const uploadResponse = await dispatch(uploadFileToCloudinary(form))
    const {width,height,original_filename,public_id,asset_id,url,format,version}=uploadResponse
    //Set so it fits into a 1600 wide by 900 high space
    const display_width = calculateDisplayWidth(uploadResponse.width,uploadResponse.height)
    const reqBody={
    	original_filename:uploadResponse.original_filename, 
    	public_id:uploadResponse.public_id, 
    	format:uploadResponse.format, 
    	original_height:uploadResponse.height, 
    	original_width:uploadResponse.width,
    	semi_transparent:uploadResponse.semi_transparent,
    	asset_id:uploadResponse.asset_id,
    	version:uploadResponse.version,
      display_width:display_width
    }
    const updateImageObjResponse = await put(`/image/${imageId}`, reqBody)

    Mixpanel.track('upload_image',{delivery_url:updateImageObjResponse.delivery_url})
    
    dispatch(fetchUploadedImages())
    await ipcRenderer.invoke('download-image-file',imageId,updateImageObjResponse.delivery_url); 
   	return updateImageObjResponse
  } catch (error) {
    console.error('Error during image upload process:', error);
    error.name = 'Image upload error'
    Sentry.captureException(error)

  }
};



//Update insert count

export function updateImageInsertCount(imageId) {
  return (dispatch) => put(`/image/${imageId}/update-insert-count`)
    .then((response) => {
      Mixpanel.track('insert_image_from_recent');
    return response
    })
    .catch((error) => {
      console.log(error)
      return error
    })
}

export function updateImageDefaultDisplayWidth(imageId,width) {
  
  const reqBody={
    width:Math.round(width)
  }
  return (dispatch) => put(`/image/${imageId}/update-default-width`,reqBody)
    .then((response) => {
    dispatch(fetchUploadedImages())
    return response
    })
    .catch((error) => {
      console.log(error)
      return error
    })
}






export function uploadFileToCloudinary(form) {
	return (dispatch) => postCloudinary(form)
		.then((uploadResponse) => {
			return uploadResponse
		})
		.catch((error) => {
			console.log(error)
		})
}


export function fetchUploadedImages(){
  return (dispatch) => fetch(`/images`)
  .then((response) => {
    dispatch({ type: 'FETCH_IMAGES_SUCCESS', response })
    downloadMissingImages(response)
    return response
  })
  .catch((error) => {
    console.log(error)
    return error
  })
}



// export const uploadImageAndHandleResponse = (file, imageId) => async (dispatch) => {
//   try {
//     let signatureResponse;
//     try {
//       // Attempt to get the signature for uploading
//       signatureResponse = await post('/image/upload', { public_id: imageId });
//     } catch (error) {
//       error.step = 'postSignature';
//       throw error;
//     }

//     console.log(signatureResponse)
//     let uploadResponse;
//     try {
//       // Prepare the form data for upload
//       let form = new FormData();
//       form.append('file', file);
//       form.append('api_key', signatureResponse.apiKey);
//       form.append('timestamp', signatureResponse.timestamp);
//       form.append('signature', signatureResponse.signature);
//       form.append('folder', 'image_uploads');
//       form.append('public_id', imageId);
//       form.append('media_metadata', true);
//       form.append('eager', 'f_png');

//       // Upload the image to Cloudinary
//       uploadResponse = await dispatch(uploadFileToCloudinary(form));
//       console.log('upload resoinse-------')
//       console.log(uploadResponse)
//     } catch (error) {
//       console.log('this bit here')
//       error.step = 'uploadToCloudinary';
//       throw error;
//     }

//     try {
//       const display_width = calculateDisplayWidth(uploadResponse.width, uploadResponse.height);
//       console.log('display width is-------')
//       console.log(display_width)
//       const reqBody = {
//         original_filename: uploadResponse.original_filename,
//         public_id: uploadResponse.public_id,
//         format: uploadResponse.format,
//         original_height: uploadResponse.height,
//         original_width: uploadResponse.width,
//         semi_transparent: uploadResponse.semi_transparent,
//         asset_id: uploadResponse.asset_id,
//         version: uploadResponse.version,
//         display_width: display_width
//       };

//       // Update the image record with the response from Cloudinary
//       await put(`/image/${imageId}`, reqBody);
//     } catch (error) {
//       error.step = 'updateImageRecord';
//       throw error;
//     }

//     // Additional logic for tracking and response handling...
//   } catch (error) {
//     error.name = 'Image upload error'

//     console.error('Error during image upload process:', error);
//     Sentry.withScope(scope => {
//       scope.setExtra("StepDetails", {
//         action: error.step || "Unknown",
//         imageId: imageId,
//         fileInfo: { name: file.name, type: file.type }
//       });
//       Sentry.captureException(error);
//     });
//     dispatch({ type: 'UPLOAD_IMAGE_FAILURE', error });
//     //throw error; // Optionally rethrow for further handling
//   }
// };



