//import voices from './voicesData'
import {getVoicesForOrg} from './voicesData'
import find from 'lodash/find'

export function getNameForVoiceId(voiceId) {
  const voices = getVoicesForOrg()
  const voice = find(voices,{id:voiceId})
  let name='' 
  if(voice){
    name=voice.name
  }

  return name
}
