import React from 'react';
import { useTransition, animated } from 'react-spring';
import Icon from '../misc/Icon'
import { updateUserSettings } from '../../actions/userSettings'
import { useDispatch } from 'react-redux';

const RELEASE_SLUG='aaaaaaa'


const UpdatesMajorReleaseBanner = ({ showMajorReleaseBanner, animateIn }) => {
  const dispatch = useDispatch();

  const handleHideBanner = (e) => {
    e.stopPropagation(); // Prevent event from bubbling up
    dispatch(updateUserSettings('updateBannerSlidesDismissed', true));
  };


  const handleOpenUpdate = (e) => {
    e.stopPropagation(); // Prevent event from bubbling up
    dispatch(updateUserSettings('updateBannerSlidesDismissed', true));
    //also open window
    window.ipcRenderer.send('open-changelog-window', RELEASE_SLUG);


  };

  const transition = useTransition(showMajorReleaseBanner, {
    from: { opacity: 0, transform: 'translateY(5px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(5px)' },
    config: { tension: 220, friction: 24 },
    immediate: !animateIn
  });

  return transition((style, item) =>
    item && (
      <animated.div 
        style={style}
        onClick={handleOpenUpdate}  
        className='updates-majorReleaseBanner'
      >
        <div className='updates-majorReleaseBanner-banner'>
          <div className='updates-majorReleaseBanner-banner-border' />
          <img src='https://res.cloudinary.com/yarn/image/upload/q_auto:best,w_780/v1727133729/static/slidesscreenshot_a8avye.png' />          
        </div>
        <div className='updates-majorReleaseBanner-footer'>
          {/*}
          <div className='updates-majorReleaseBanner-footer-iconContainer'>
            <Icon name='updates' />
          </div>
          */}
          <div className='updates-majorReleaseBanner-footer-title'>
            <div className='updates-majorReleaseBanner-footer-title-newTag'>
              New 
            </div>
            <div className='updates-majorReleaseBanner-footer-title-label'>
              Slides
            </div>
          </div>
          <div className='updates-majorReleaseBanner-footer-hSpacer'/>
          <button 
            onClick={handleHideBanner} 
            className='updates-majorReleaseBanner-footer-hideBtn'
          >
            Hide
          </button>
        </div>
      </animated.div>
    )
  );
};

export default UpdatesMajorReleaseBanner;