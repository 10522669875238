import React, { useState } from 'react';
import { Rnd } from 'react-rnd';

function SlideEditorSpacers(props) {
  const { slide, scalar, handleSpacerDrag, setSlideEditorDraggingSpacer,isSlideItemLocked } = props;
  const [hoveredSpacerId, setHoveredSpacerId] = useState(null);
  const [draggingSpacerId, setDraggingSpacerId] = useState(null);

  const handleLongSide = 40 * (1/scalar);
  const handleShortSide = 12 * (1/scalar);
  const handleVisibleLongSide = 18 * (1/scalar);
  const handleVisibleShortSide = 3 * (1/scalar);
  const innerBoxMargin = 2 * (1/scalar);
  const innerBoxBorderRadius = 3 * (1/scalar);
  const draggableBoxBorderRadius = 3 * (1/scalar);

  function renderSpacers(layout, depth = 0) {
    if (!layout || !layout.children || layout.children.length === 0) {
      return null;
    }

    return layout.children.map((child, index) => {
      if (index === layout.children.length - 1) {
        // Don't render spacer after the last child
        return child.children ? renderSpacers(child, depth + 1) : null;
      }
      if(isSlideItemLocked(child.id)){
        return null
      }

      const nextChild = layout.children[index + 1];
      let spacerProps;

      if (layout.type === 'vstack') {
        spacerProps = {
          width: child.width,
          height: child.bottomMargin,
          x: child.x,
          y: child.y + child.height,
          handleX: child.x + ((child.width - handleLongSide) / 2),
          handleY: child.y + child.height + ((child.bottomMargin - handleShortSide) / 2),
          dragAxis: 'y',
          spacerType: 'bottom'
        };
      } else if (layout.type === 'hstack') {
        spacerProps = {
          width: child.rightMargin,
          height: child.height,
          x: child.x + child.width,
          y: child.y,
          handleX: child.x + child.width + ((child.rightMargin - handleShortSide) / 2),
          handleY: child.y + ((child.height - handleLongSide) / 2),
          dragAxis: 'x',
          spacerType: 'right'
        };
      } else {
        // Don't render spacers for 'free' layout
        return child.children ? renderSpacers(child, depth + 1) : null;
      }

      const handleWidth = spacerProps.spacerType === 'right' ? handleShortSide : handleLongSide;
      const handleHeight = spacerProps.spacerType === 'right' ? handleLongSide : handleShortSide;

      const handleVisibleWidth = spacerProps.spacerType === 'right' ? handleVisibleShortSide : handleVisibleLongSide;
      const handleVisibleHeight = spacerProps.spacerType === 'right' ? handleVisibleLongSide : handleVisibleShortSide;

      const cursor = spacerProps.spacerType === 'right' ? 'ew-resize' : 'ns-resize';

      const spacerId = `spacer_${child.id}_${spacerProps.spacerType}_${depth}`;
      const displayOutline = hoveredSpacerId === spacerId || draggingSpacerId === spacerId;

      return (
        <React.Fragment key={spacerId}>
          <div 
            data-force-visible-state={displayOutline}
            style={{
              width: `${spacerProps.width - innerBoxMargin * 2}px`,
              height: `${spacerProps.height - innerBoxMargin * 2}px`,
              left: `${spacerProps.x + innerBoxMargin}px`,
              top: `${spacerProps.y + innerBoxMargin}px`,
              borderRadius: `${innerBoxBorderRadius}px`
            }}
            className={`editor-slideEditor-spacerOutline editor-slideEditor-spacerOutline--${spacerProps.spacerType}`}   
          >
            <div 
              style={{width: `${handleVisibleWidth}px`, height: `${handleVisibleHeight}px`}} 
              className={`editor-slideEditor-spacerHandle editor-slideEditor-spacerHandle--render editor-slideEditor-spacerHandle--${spacerProps.spacerType}`} 
            />
          </div>
          <Rnd
            size={{ 
              width: handleWidth,
              height: handleHeight,
            }}
            position={{ x: spacerProps.handleX, y: spacerProps.handleY }}
            onDrag={(e, data) => handleSpacerDrag(child, data, spacerProps.spacerType)}
            onDragStart={() => {
              setDraggingSpacerId(spacerId);
              setSlideEditorDraggingSpacer(spacerProps.spacerType);
            }}
            onDragStop={() => {
              setDraggingSpacerId(null);
              setSlideEditorDraggingSpacer(null);
            }}
            onMouseEnter={() => setHoveredSpacerId(spacerId)}
            onMouseLeave={() => setHoveredSpacerId(null)}
            scale={scalar}
            dragAxis={spacerProps.dragAxis}
            className={`editor-slideEditor-spacerHandle editor-slideEditor-spacerHandle--dnd editor-slideEditor-spacerHandle--${spacerProps.spacerType}`}
            enableResizing={false}
            style={{cursor: cursor, borderRadius: `${draggableBoxBorderRadius}px`}}
          />
          {child.children && renderSpacers(child, depth + 1)}
        </React.Fragment>
      );
    });
  }

  return <>{renderSpacers(slide.layout)}</>;
}

export default SlideEditorSpacers;