import { InputRule } from 'prosemirror-inputrules';
import { TextSelection } from 'prosemirror-state';
import { randomID } from '../../../../../../utils/randomID';

export const insertWebcamGroupInputRule = new InputRule(
  /@$/, // Matches @ anywhere
  (state, match, start, end) => {
    // First find the parent positions using the resolved position
    const $pos = state.doc.resolve(start);
    let transcriptChunkDepth = null;
    let transcriptGroupDepth = null;
    // Find the depth of our nodes
    for (let d = $pos.depth; d > 0; d--) {
      const node = $pos.node(d);
      if (node.type.name === 'transcriptChunk') {
        transcriptChunkDepth = d;
      }
      if (node.type.name === 'transcriptGroup') {
        transcriptGroupDepth = d;
      }
    }
    
    if (!transcriptChunkDepth || !transcriptGroupDepth) return null;
    // Get the actual nodes and their positions
    const currentChunk = $pos.node(transcriptChunkDepth);
    const currentGroup = $pos.node(transcriptGroupDepth);
    const groupPos = $pos.before(transcriptGroupDepth);
    const chunkPos = $pos.before(transcriptChunkDepth);
    // Check if this was the only chunk in the group
    const isOnlyChunk = currentGroup.content.childCount === 1;
    let tr = state.tr;
    // 1. Delete the @ character
    tr = tr.delete(start, end);
    
    // 2. Create new webcam group with the current chunk
    const newGroupNode = state.schema.nodes.transcriptGroup.createAndFill({
      groupId: randomID(),
      groupType: 'webcam-placeholder'
    }, [currentChunk.copy(currentChunk.content)]);
    
    if (!newGroupNode) return null;
    let newCursorPos;
    
    if (isOnlyChunk) {
      // 3a. If this was the only chunk, replace the entire old group
      tr = tr.replaceWith(groupPos, groupPos + currentGroup.nodeSize, newGroupNode);
      newCursorPos = groupPos + 2; // Position cursor at start of the chunk in new group
    } else {
      // 3b. If there were other chunks:
      // First, find all chunks after the current one
      const chunksAfterCurrent = [];
      let foundCurrent = false;
      
      currentGroup.forEach((node) => {
        if (foundCurrent) {
          chunksAfterCurrent.push(node);
        }
        if (node === currentChunk) {
          foundCurrent = true;
        }
      });
      
      // Get chunks before current
      const chunksBeforeCurrent = [];
      foundCurrent = false;
      currentGroup.forEach((node) => {
        if (node === currentChunk) {
          foundCurrent = true;
        } else if (!foundCurrent) {
          chunksBeforeCurrent.push(node);
        }
      });

      if (chunksBeforeCurrent.length === 0) {
        // We're at the first chunk
        // Create a group for the remaining chunks
        if (chunksAfterCurrent.length > 0) {
          const afterGroup = state.schema.nodes.transcriptGroup.createChecked({
            groupId: currentGroup.attrs.groupId,
            groupType: currentGroup.attrs.groupType
          }, chunksAfterCurrent);
          
          // Replace entire current group with new webcam group and after group
          tr = tr.replaceWith(groupPos, groupPos + currentGroup.nodeSize, [newGroupNode, afterGroup]);
        } else {
          // Just replace with the new webcam group
          tr = tr.replaceWith(groupPos, groupPos + currentGroup.nodeSize, newGroupNode);
        }
        newCursorPos = groupPos + 2;
      } else {
        // We're in the middle or end of the group
        // Create updated version of the current group with only the chunks before
        const updatedCurrentGroup = state.schema.nodes.transcriptGroup.createChecked({
          groupId: currentGroup.attrs.groupId,
          groupType: currentGroup.attrs.groupType
        }, chunksBeforeCurrent);
        
        if (chunksAfterCurrent.length > 0) {
          // If we have chunks after, create a group for them
          const afterGroup = state.schema.nodes.transcriptGroup.createChecked({
            groupId: currentGroup.attrs.groupId,
            groupType: currentGroup.attrs.groupType
          }, chunksAfterCurrent);
          
          // Replace entire current group with all three groups
          tr = tr.replaceWith(groupPos, groupPos + currentGroup.nodeSize, [updatedCurrentGroup, newGroupNode, afterGroup]);
        } else {
          // Replace with just the before group and new webcam group
          tr = tr.replaceWith(groupPos, groupPos + currentGroup.nodeSize, [updatedCurrentGroup, newGroupNode]);
        }
        newCursorPos = groupPos + updatedCurrentGroup.nodeSize + 2;
      }
    }

    // Set the cursor position
    tr = tr.setSelection(TextSelection.create(tr.doc, newCursorPos));
    
    return tr;
  }
);