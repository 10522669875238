import React, { useMemo } from 'react';
import { Text } from '@react-three/drei';
import * as THREE from 'three';
import createRoundedRectShape from '../utils/createRoundedRectShape';

const SubtitlesGroupBox = ({ width, height, position }) => {
  
  const extraTop = 10;
  const extraBottom = extraTop;
  const extraLeft = 20;
  const extraRight = extraLeft;
  const groupBoxWidth = width + extraLeft + extraRight;
  const groupBoxHeight = height + extraTop + extraBottom;


  const boxGeometry = useMemo(() => {
    const shape = createRoundedRectShape(groupBoxWidth, groupBoxHeight, 16);
    const geometry = new THREE.ShapeGeometry(shape);
    
    // Compute UV mapping for the geometry
    const uvs = new Float32Array(geometry.attributes.position.count * 2);
    for (let i = 0; i < geometry.attributes.position.count; i++) {
      const vertex = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, i);
      uvs[i * 2] = (vertex.x + groupBoxWidth / 2) / groupBoxWidth;
      uvs[i * 2 + 1] = (vertex.y + groupBoxHeight / 2) / groupBoxHeight;
    }
    geometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));
    return geometry;
  }, [groupBoxWidth, groupBoxHeight]);

  return (
    <mesh
      geometry={boxGeometry}
      position={position}
      renderOrder={500}
    >
      <meshBasicMaterial 
        color="black" 
        transparent={true} 
        opacity={0.65}
        depthWrite={false}
        //depthTest={false}
        renderOrder={500}
      />
    </mesh>
  );
};

export default SubtitlesGroupBox;