import React from 'react'
import TextSlideClipNodeSpec from './TextSlideClipNodeSpec'
import SlideClipNodeSpec from './SlideClipNodeSpec'
import ChartClipNodeSpec from './ChartClipNodeSpec'
import VideoClipNodeSpec from './VideoClipNodeSpec'
import ImageClipNodeSpec from './ImageClipNodeSpec'
import AudioClipNodeSpec from './AudioClipNodeSpec'
import ZoomClipNodeSpec from './ZoomClipNodeSpec'
import ProjectSettingsNodeSpec from './ProjectSettingsNodeSpec'
import SceneNodeSpec from './SceneNodeSpec'
import LayoutGroupNodeSpec from './LayoutGroupNodeSpec'
import SlideElementNodeSpec from './SlideElementNodeSpec'
import WebcamClipNodeSpec from './WebcamClipNodeSpec'

const textNode: NodeSpec = {
    group: "inline"
};


export const nodes = {
  doc: {
    content: "(textSlideClip | slideClip | chartClip | videoClip | imageClip | audioClip | zoomClip | projectSettings | scene | webcamClip)*"
  },
  text: textNode,
  textSlideClip:TextSlideClipNodeSpec,
  slideClip:SlideClipNodeSpec,
  chartClip:ChartClipNodeSpec,
  videoClip:VideoClipNodeSpec,
  imageClip:ImageClipNodeSpec,
  audioClip:AudioClipNodeSpec,
  zoomClip:ZoomClipNodeSpec,
  projectSettings:ProjectSettingsNodeSpec,
  layoutGroup:LayoutGroupNodeSpec,
  slideElement:SlideElementNodeSpec,
  webcamClip:WebcamClipNodeSpec,
  scene:SceneNodeSpec,
  paragraph: {
    attrs:{
     // indentLevel:{default:0},
    },
    content: "inline*",
    group: "block",
    parseDOM: [
      {
        tag: "p"
      },
    ],
    toDOM(node) {
      return ["div",{class:`doc-para`}, 0]
    }},
}

export default nodes

//content: "(scene| projectSettings)*"
// export const nodes = {
//   doc: {
//     //content: "(slideClip | chartClip | videoClip | imageClip | audioClip | zoomClip | projectSettings)*"
//     content: "(scene)*"
//   },
//   text: textNode,
//   slideClip:SlideClipNodeSpec,
//   chartClip:ChartClipNodeSpec,
//   videoClip:VideoClipNodeSpec,
//   imageClip:ImageClipNodeSpec,
//   audioClip:AudioClipNodeSpec,
//   zoomClip:ZoomClipNodeSpec,
//   projectSettings:ProjectSettingsNodeSpec,
//   scene:SceneNodeSpec
// }