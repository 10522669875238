import React, { useState, useEffect, useRef } from 'react';
import sanityClient from './sanityClient';
import moment from 'moment';
import UpdatesPagePost from './UpdatesPagePost'

const lastChecked = moment('2023-06-25T18:00:00');

const UpdatesPage = () => {  
  const [posts, setPosts] = useState([]);
  const postsRef = useRef({});

  useEffect(() => {
    const fetchPosts = async () => {
      const query = `
        *[_type == "post" && publishedAt < now()] {
          ...,
          "mainVideoPlaybackId": mainVideo.asset->playbackId,
          body[]{
            ...,
            _type == "mux.video" => {
              "type": _type,
              "asset": asset->{
                playbackId,
              },
            },
          }
        } | order(publishedAt desc)
      `;
      const fetchedPosts = await sanityClient.fetch(query);
      const sortedPosts = fetchedPosts.sort((a, b) => moment(b.publishedAt).diff(moment(a.publishedAt)));
      setPosts(sortedPosts);
    };
    fetchPosts();
  }, []);

  useEffect(() => {
    const handleHash = () => {
      const hash = window.location.hash.slice(1); // Remove the '#' character
      console.log('Current hash:', hash);
      if (hash && postsRef.current[hash]) {
        setTimeout(() => {
          postsRef.current[hash].scrollIntoView({ behavior: 'instant' });
        }, 10); // Small delay to ensure the element is rendered
      }
    };

    // Handle the hash when the posts are loaded
    if (posts.length > 0) {
      handleHash();
    }

    // Add an event listener for hash changes
    window.addEventListener('hashchange', handleHash);

    // Clean up the event listener
    return () => {
      window.removeEventListener('hashchange', handleHash);
    };
  }, [posts]); // Run this effect when posts change

  return (
    <div className='updatesPage'>
      <div className='updatesPage-header'>
        <div className='updatesPage-header-label'>
          Updates
        </div>
      </div>
      {/*}
      <div className='updatesPage-navigation'>
        {posts.map(post => (
          <a 
            key={post.slug.current} 
            href={`#${post.slug.current}`}
            className='updatesPage-navigation-link'
          >
            {post.title}
          </a>
        ))}
      </div>
      */}
      <div className='updatesPage-list'>
        {posts.map(post => {
          const publishedAt = moment(post.publishedAt);
          const isUnread = publishedAt.isAfter(lastChecked);        
          return (
            <div 
              key={post.slug.current} 
              ref={el => postsRef.current[post.slug.current] = el}
            >
              <UpdatesPagePost 
                post={post}            
              />            
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UpdatesPage;