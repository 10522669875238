



export function removeQuietOrSkipSegment(originalSegments, segmentId,totalDuration) {
 // let quietSegments =[] 


  let specialSegments =[] //quiet or skip segments 

  originalSegments.forEach((segment)=>{
    if((segment.isQuiet || segment.isSkipSegment) && segment.id!=segmentId){
      specialSegments.push(segment)
    }
  })

  let segments = [];
  let lastEnd = 0;

  specialSegments.forEach(period => {
      if (lastEnd < period.originalStart) {
        // Add active segment before special segment
        segments.push({
          id: segments.length,
          originalDuration: period.originalStart - lastEnd,
          originalStart: lastEnd,
          originalEnd: period.originalStart,
          isQuiet: false,
          playbackRate:1,
          timeStretch:1
        });
      }

      // Add special segment
      // segments.push({
      //   id: segments.length,
      //   originalDuration: period.originalEnd - period.originalStart,
      //   originalStart: period.originalStart,
      //   originalEnd: period.originalEnd,
      //   isQuiet: period.isQuiet,
      //   isManualFreeze:period.isManualFreeze,

      //   playbackRate:period.playbackRate,
      //   timeStretch:period.timeStretch
      // });

      segments.push(period)


      lastEnd = period.originalEnd;
    });

    if (lastEnd < totalDuration) {
      // Add remaining active segment
      segments.push({
        id: segments.length,
        originalStart:lastEnd,
        originalDuration: totalDuration - lastEnd,
        originalEnd: totalDuration,
        isQuiet: false,
        playbackRate:1,
        timeStretch:1
      });
    }

  return segments
}



//Prod function
// export function removeQuietSegment(originalSegments, segmentId,totalDuration) {
//   let quietSegments =[] 

//   originalSegments.forEach((segment)=>{
//     if(segment.isQuiet && segment.id!=segmentId){
//       quietSegments.push(segment)
//     }
//   })

//   let segments = [];
//   let lastEnd = 0;

//   quietSegments.forEach(period => {
//       if (lastEnd < period.originalStart) {
//         // Add active segment before the quiet period
//         segments.push({
//           id: segments.length,
//           originalDuration: period.originalStart - lastEnd,
//           originalStart: lastEnd,
//           originalEnd: period.originalStart,
//           isQuiet: false,
//           playbackRate:1,
//           timeStretch:1
//         });
//       }
//       // Add quiet segment
//       segments.push({
//         id: segments.length,
//         originalDuration: period.originalEnd - period.originalStart,
//         originalStart: period.originalStart,
//         originalEnd: period.originalEnd,
//         isQuiet: true,
//         playbackRate:period.playbackRate,
//         timeStretch:period.timeStretch
//       });
//       lastEnd = period.originalEnd;
//     });

//     if (lastEnd < totalDuration) {
//       // Add remaining active segment
//       segments.push({
//         id: segments.length,
//         originalStart:lastEnd,
//         originalDuration: totalDuration - lastEnd,
//         originalEnd: totalDuration,
//         isQuiet: false,
//         playbackRate:1,
//         timeStretch:1
//       });
//     }

//   return segments
// }
