import { fetchRecordingDimensions } from './getLegacyRecordingDimensions';
import { getLegacyRecordingVideoTimes } from './getLegacyRecordingVideoTimes';
import { fetchMasterCursorData } from './getLegacyMasterCursorData';
import { fetchMasterClickData } from './getLegacyMasterClickData';


const blobCache = {};

export const fetchLegacyVideoURL = async (captureId) => {
  let videoURL
  const videoPath = `recordings/${captureId}/channel-1-display-0.mp4`;
  if (blobCache[videoPath]) {
    return blobCache[videoPath];
  } else {
    if(window.isElectron){
      const videoBuffer = await ipcRenderer.invoke('read-file-buffer', videoPath);
      const videoBlob = new Blob([videoBuffer], { type: 'video/mp4' });
      videoURL = URL.createObjectURL(videoBlob);
    }else{
      const s3BaseUrl = 'https://yarn-assets.s3.amazonaws.com/';
      videoURL = s3BaseUrl + videoPath;
    }
    blobCache[videoPath] = videoURL;
    return videoURL;
  }
};



// export const fetchLegacyVideoURL = async (projectPath, captureId,isDeviceRecording) => {
//   let videoURL;
//   if (captureId) {
//     let videoPath 
//     if(isDeviceRecording){
//       videoPath = `deviceRecordings/${captureId}/deviceRecording.mov`;
//     }else{
//       videoPath = `recordings/${captureId}/channel-1-display-0.mp4`;
//     }
//     if (blobCache[videoPath]) {
//       return blobCache[videoPath];
//    } else {
//     if(window.isElectron){
//       const videoBuffer = await ipcRenderer.invoke('read-file-buffer', videoPath);
//       const videoBlob = new Blob([videoBuffer], { type: 'video/mp4' });
//       videoURL = URL.createObjectURL(videoBlob);
//     }else{
//       const s3BaseUrl = 'https://yarn-assets.s3.amazonaws.com/';
//       videoURL = s3BaseUrl + videoPath;
//     }
//     blobCache[videoPath] = videoURL;
//     return videoURL;
//     }
//   } else {
//     return `${projectPath}/recording/channel-1-display-0.mp4`;
//   }
// };


// Modified getMasterRecordingData function
export const getLegacyMasterRecordingData = async (projectPath,captureId,isDevice) => {
  try {


    const videoURL = await fetchLegacyVideoURL(captureId);


    // Fetch device width and height
    const recordingDimensions = await fetchRecordingDimensions(projectPath,captureId);
    if (!recordingDimensions) {
      throw new Error('Failed to fetch device dimensions');
    }

    // Fetch video times
    const recordingVideoTimes = await getLegacyRecordingVideoTimes(projectPath,captureId);
    if (!recordingVideoTimes) {
      throw new Error('Failed to fetch video times');
    }
    // console.log(recordingVideoTimes)

    // Fetch video times
    const cursorData = await fetchMasterCursorData(projectPath,captureId);
    if (!cursorData) {
      throw new Error('Failed to fetch video times');
    }
    // console.log(cursorData)

    const clickData = await fetchMasterClickData(projectPath,captureId);
    if (!clickData) {
      throw new Error('Failed to fetch click data');
    }


    return { videoURL, ...recordingDimensions, ...recordingVideoTimes, cursorData,clickData };

  } catch (error) {
    console.error('Failed to get master recording data:', error);
    return []; // Return an empty array in case of error
  }
};




