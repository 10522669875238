import React, { useState, useEffect } from 'react';
import { Text } from '@react-three/drei';
import { SpringAnimator } from '../../three/utils/animations/SpringAnimator';
import renderOrders from '../../three/renderOrders'

const FPS = 60; // Frames per second
const animationDuration = 10;

const CHBarChartTitle = ({ title, time, animationType, isHidden, fontFamily, color, maxOpacity }) => {
  const fontWeight = 'Medium';
  const fontSize = 56;
  const maxTitleOpacity = maxOpacity;

  const springParams = { mass: 1, stiffness: 180, damping: 50 };
  const [titleOpacityFrames, setTitleOpacityFrames] = useState([]);

  useEffect(() => {
    const titleOpacitySpring = new SpringAnimator(springParams.mass, springParams.stiffness, springParams.damping, 0);

    const opacityFrames = [];

    for (let frame = 0; frame < FPS * animationDuration; frame++) {
      titleOpacitySpring.setTarget(maxTitleOpacity); // Target opacity
      titleOpacitySpring.simulate(1000 / FPS);
      opacityFrames.push(titleOpacitySpring.position);
    }

    setTitleOpacityFrames(opacityFrames);

  }, [maxTitleOpacity]);

  const frameIndex = Math.floor(time * FPS);
  const animatedTitleOpacity = titleOpacityFrames[frameIndex] || 0;

  let titleOpacity = animatedTitleOpacity


  if(animationType === 'none'){
    titleOpacity = maxTitleOpacity
  }
  
  if(isHidden){
    titleOpacity = 0
  }

  // console.log('animationTypeTitle')
  // console.log(animationType)



  return (
    <>
      <Text          
        anchorX="center"
        anchorY="bottom"
        fontSize={fontSize}
        textAlign="center"
        font={`../fonts/${fontFamily}/${fontFamily}-${fontWeight}.woff`}
        position={[1, 350, 0]}
        renderOrder={renderOrders.graphObject}  
        //letterSpacing={0.02}
      >
        <meshBasicMaterial transparent={true} color={color} opacity={titleOpacity} />
        {title}
      </Text>
    </>
  );
};

export default CHBarChartTitle;
