import React from 'react';


const getMobileDeviceModelForClip = (width, height) => {
    const aspectRatio = width / height;

    // Define the known models with their aspect ratios
    const knownModels = {
      'iphone15promax': 0.4613733906, // also 14 pro max, 15 plus
      'iphone15pro': 0.4612676056,    // also 14 pro, 15
      'iphone14': 0.4620853081,       // also 13, 13 pro, 12, 12 pro
      'iphone14plus': 0.4622030238,   // also 13 pro max, 12 pro max
      'ipad10landscape': 1.43902439,  // also ipad Air 5th Gen and iPad Air 4th gen
      'ipad10portrait': 0.694915,     // 
      'ipadminilandscape': 1.522849462,// 6th gen ipad Mini
      'ipadminiportrait': 0.656664,   // 
      'ipadpro11landscape': 1.431654676, //
      'ipadpro11portrait': 0.698492,   // 
      'ipadpro129landscape': 1.333984375, //
      'ipadpro129portrait': 0.749634,   // 
      'ipad9landscape': 1.333333333,   // 
      'ipad9portrait': 0.75,   // 
    };

    // Filter models to those within ±0.2 range of the calculated aspect ratio
    const filteredModels = Object.entries(knownModels).filter(([_, knownAspectRatio]) =>
      Math.abs(aspectRatio - knownAspectRatio) <= 0.2);

    if (filteredModels.length === 0) {
      return null;
    }

    // Sort filtered models by closeness to actual aspect ratio
    const sortedModels = filteredModels.sort((a, b) => 
      Math.abs(aspectRatio - a[1]) - Math.abs(aspectRatio - b[1])
    );

    // Check if the top two are 'iphone15pro' and 'iphone15promax'
    if (width < 1200 && sortedModels.length >= 2 &&
        sortedModels[0][0] === 'iphone15pro' && sortedModels[1][0] === 'iphone15promax') {
      return 'iphone15pro';
    } else if (width < 1200 && sortedModels.length >= 2 &&
        sortedModels[0][0] === 'iphone15promax' && sortedModels[1][0] === 'iphone15pro') {
      return 'iphone15pro';
    }


    // Return the closest model
    return sortedModels[0][0];
  
};

export default getMobileDeviceModelForClip;


