import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../misc/Icon';

const DPNumberInput = ({
  min,
  max,
  value,
  setValue,
  setIsDragResizingNumberInput,
  percentage,
  iconName,
  label,
  disabled,
  width,
  relaxedWidth, 
  isMixed,
  step = 1
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [localInputValue, setLocalInputValue] = useState(
    isMixed ? "Mixed" : (percentage ? `${value}%` : `${value}`)
  );
  const [pendingValue, setPendingValue] = useState(null);
  const dragStartX = useRef(0);
  const dragContainer = useRef(null);
  const inputRef = useRef(null);
  let pixelsPerIncrement = percentage ? 3 : 1;  
  
  // Helper function to safely handle number formatting
  const formatNumber = (num, stepPrecision) => {
    if (typeof num !== 'number' || isNaN(num)) {
      return '0';
    }
    const precision = String(step).split('.')[1]?.length || 0;
    return num.toFixed(precision);
  };

  const handleInputChange = (event) => {
    const newInputValue = event.target.value;
    setLocalInputValue(newInputValue);
    
    const numericValue = parseFloat(newInputValue.replace('%', ''));
    if (!isNaN(numericValue)) {
      setPendingValue(numericValue);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && pendingValue !== null) {
      let newValue = pendingValue;
      
      // Ensure the value is within min and max bounds
      newValue = Math.max(min, Math.min(max, newValue));
      
      // Add percentage sign if it's a percentage and missing
      let formattedValue = percentage 
        ? newValue.toString().includes('%') ? newValue : `${newValue}%`
        : newValue.toString();
      
      setValue(newValue);
      setLocalInputValue(formattedValue);
      setPendingValue(null);
      
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.select();
        }
      }, 0);
    }
  };

  const handleMouseDown = (event) => {
    setIsDragging(true);
    dragStartX.current = event.clientX;
    setIsDragResizingNumberInput(true);
  };

  const handleMouseMove = (event) => {
    if (isDragging) {
      const dragDelta = event.clientX - dragStartX.current;
      const valueChange = dragDelta / pixelsPerIncrement;
      
      // Calculate stepped value
      const steps = Math.round(valueChange / step) * step;
      const newValue = Math.min(Math.max(value + steps, min), max);
      
      // Use the safe format function
      const roundedValue = Number(formatNumber(newValue));
      setValue(roundedValue);
      
      dragStartX.current = event.clientX;
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setIsDragResizingNumberInput(false);
  };

  const handleWindowBlur = () => {
    if (isDragging) {
      setIsDragging(false);
      setIsDragResizingNumberInput(false);
    }
  };

  const handleInputFocus = (event) => {
    setIsFocused(true);
    event.target.select();
  };

  const handleInputBlur = () => {
    setIsFocused(false);
    if (pendingValue !== null) {
      let newValue = Math.max(min, Math.min(max, pendingValue));
      
      newValue = percentage
        ? Math.round(newValue)
        : Number(formatNumber(newValue)); // Use safe format function
        
      setValue(newValue);
      setLocalInputValue(
        isMixed 
          ? "Mixed" 
          : percentage 
            ? `${newValue}%` 
            : `${newValue}`
      );
      setPendingValue(null);
    }
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
      window.addEventListener('blur', handleWindowBlur);
    }
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('blur', handleWindowBlur);
    };
  }, [isDragging, value]);

  useEffect(() => {
    if (!isFocused) {
      const roundedValue = percentage 
        ? Math.round(value)
        : Number(formatNumber(value)); // Use safe format function
        
      setLocalInputValue(
        isMixed 
          ? "Mixed" 
          : percentage 
            ? `${roundedValue}%` 
            : `${roundedValue}`
      );
    }
  }, [value, percentage, isFocused, isMixed, step]);

  let elementWidth = 72;
  if(width){
    elementWidth = width;
  }

  return (
    <div style={{width: `${elementWidth}px`}} data-state-mixed={isMixed ? 'true' : 'false'} data-disabled={disabled} data-relaxed-width={relaxedWidth} className='dpNumberInput' data-input-focused={isFocused || isDragging}>
      <div 
        className='dpNumberInput-dragContainer'
        data-dragging={isDragging ? true : false}
        ref={dragContainer}
        onMouseDown={handleMouseDown}
      >        
        {iconName && <Icon name={iconName} />}
        {label && <span className='dpNumberInput-dragContainer-label'>{label}</span>}
      </div>
      <input             
        ref={inputRef}
        disabled={disabled}
        className='dpNumberInput-input'         
        value={localInputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        min={min}
        max={max}
      />
    </div>
  );
};

DPNumberInput.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
  setIsDragResizingNumberInput: PropTypes.func.isRequired,
  percentage: PropTypes.bool,
  iconName: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  width: PropTypes.number,
  step: PropTypes.number,
};

export default DPNumberInput;