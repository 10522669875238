

const imageCache = {};

export const fetchImageUrl = async (imageId) => {
  let imageURL;
  // Update the path pattern according to how your images are stored
  const imagePath = `imageUploads/${imageId}.png`; // Assuming images are PNGs; adjust as necessary

  if (imageCache[imagePath]) {
    return imageCache[imagePath];
  } else {
    // Request the image file buffer via IPC
    const imageBuffer = await ipcRenderer.invoke('read-file-buffer', imagePath);
  // Create a Blob from the buffer; adjust MIME type as necessary
    const imageBlob = new Blob([imageBuffer], { type: 'image/png' }); // Adjust MIME type based on your image format
  // console.log(`Blob created. Blob size: ${imageBlob.size} bytes`);

  // Generate a URL for the Blob
  imageURL = URL.createObjectURL(imageBlob);
  // Cache this URL for future use to avoid unnecessary filesystem access
  imageCache[imagePath] = imageURL;
  return imageURL;
  }
  
};
