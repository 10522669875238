import React, { useState } from 'react';
import Icon from '../../misc/Icon'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'

function LibraryPageHeaderSort({ galleryDisplay,sortOption,handleSortChange }) {  

    return (
      
      <DropdownMenu.Root modal={false}>
        <DropdownMenu.Trigger asChild>
          <button data-state={sortOption !== 'newest' ? 'active' : ''} className='uiButton uiButton--iconOnly uiButton--sort'>
            <div className='uiButton-iconContainer'>
              <Icon name='sortOrderMedium' />
            </div>
          </button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content             
          align="end"      
          className='dropdownMenu dropdownMenu--sortOrder forceDarkTheme'
        >            
          <div className='dropdownMenu-explainer'>
            Sort order
          </div>
            <DropdownMenu.Item className={sortOption=='newest'?'dropdownMenu-item--active':''} onSelect={()=>{handleSortChange('newest')}}>
              Newest first
            </DropdownMenu.Item >
            <DropdownMenu.Item className={sortOption=='oldest'?'dropdownMenu-item--active':''} onSelect={()=>{handleSortChange('oldest')}}>
              Oldest first
            </DropdownMenu.Item>
            <DropdownMenu.Item className={sortOption=='a-z'?'dropdownMenu-item--active':''} onSelect={()=>{handleSortChange('a-z')}}>
              A - Z
            </DropdownMenu.Item>
            <DropdownMenu.Item className={sortOption=='z-a'?'dropdownMenu-item--active':''} onSelect={()=>{handleSortChange('z-a')}}>
              Z - A
            </DropdownMenu.Item>              
        </DropdownMenu.Content>
      </DropdownMenu.Root>  

      
          
    );
}

export default LibraryPageHeaderSort
