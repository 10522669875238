import React from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';
import Icon from '../misc/Icon';

function Checkbox({checked, toggleChecked}) {
  return (
    <div data-state={checked ? 'checked' : 'unchecked'} className='checkboxItem-checkbox'>
      {checked &&
        <Icon name='checkmark' /> 
      }
    </div>
  );
}

function CheckboxItem({checked, toggleChecked, label, labelLight, description}) {
  return (    
    <div onClick={toggleChecked} data-state={checked ? 'checked' : 'unchecked'} className='checkboxItem'>
      <div className='checkboxItem-checkboxContainer'>
        <Checkbox checked={checked} toggleChecked={toggleChecked} />
      </div>
      <div className='checkboxItem-textContainer'>
        <div className='checkboxItem-label'>
          {label} {labelLight && <span className='checkboxItem-label-light'>{labelLight}</span>}
        </div>
        {description && 
        <div className='checkboxItem-description'>
          {description}
        </div>
        }
      </div>    
    </div>
  );
}

export { CheckboxItem };
