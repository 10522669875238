const initialState = {
	recordings: {} // Format: { [captureId]: { files: { [fileName]: { status, progress } } } }
};

export default function(state = {}, action) {
	switch(action.type) {
		 case 'SET_FILES_TO_DOWNLOAD':
			const { captureId, files } = action;
			return {
				...state,
					[captureId]:{
					files: files.reduce((acc, file) => {
						acc[file.name] = { status: 'pending', type: file.type };
						return acc;
					}, {}),
					status:'downloading'
				}
				
			};


   case 'DOWNLOAD_SUCCESS':
			const { captureId: successCaptureId, fileName } = action
			const updatedFiles = {
				...state[successCaptureId].files,
				[fileName]: {
					...state[successCaptureId].files[fileName],
					status: 'completed'
				}
			};
			 const allFilesCompleted = Object.values(updatedFiles).every(file => file.status === 'completed')

			return {
				...state,
					[successCaptureId]: {
						...state[successCaptureId],
						files: updatedFiles,
						status: allFilesCompleted ? 'complete' : 'downloading' // Update status of the capture
					}
			};

		default:
			return state;
	}
}
