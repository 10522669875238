import React from 'react';
import * as Select from '@radix-ui/react-select';
import Icon from '../../misc/Icon';

const DPSelectMenu = ({ value, onValueChange, options, width, btnLabelLight }) => {
  const buttonWidth = width || 80;
  const menuWidth = buttonWidth + 12;

  return (
    <Select.Root value={value} onValueChange={onValueChange}>
      <Select.Trigger style={{width: `${buttonWidth}px`}} className='dpSelectBtn'>
        <div data-light-state={btnLabelLight} className='dpSelectBtn-label'>
          <Select.Value /> {value.subLabel && 'test'}
        </div>
        <Select.Icon className='dpSelectBtn-iconContainer'>
          <Icon name='chevronUpDownMedium' />
        </Select.Icon>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content style={{width: `${menuWidth}px`}} className="dpSelectMenu forceDarkTheme">
          <Select.Viewport className="dpSelectMenu-viewport">
            {options.map((option) => (
              <Select.Item key={option.value} value={option.value} className='dpSelectMenu-item'>
                <Select.ItemText>{option.label}</Select.ItemText>
              </Select.Item>
            ))}
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};

export default DPSelectMenu;