import store from '../store'


export function getCurrentUserName() {	
	const state = store.getState()
	const user = state.user 
	let name ='' 
	if(user){
		name = user.display_name
	}

	return name 
}
