import React from 'react';

const SlideEditorSnapLines = ({ snapLines, slideWidth, slideHeight, dragItem, scalar }) => {
  // const draggedElement = elements.find(el => el.id === dragElementId);


  const isLineActive = (line, orientation) => {
    if (!dragItem) return false;

    const centerX = dragItem.x + dragItem.width / 2;
    const centerY = dragItem.y + dragItem.height / 2;

    if (orientation === 'vertical') {
      return (
        dragItem.x === line.position || 
        (dragItem.x + dragItem.width) === line.position ||
        centerX === line.position
      );
    } else {
      return (
        dragItem.y === line.position || 
        (dragItem.y + dragItem.height) === line.position ||
        centerY === line.position
      );
    }
  };

  const inverseScalar = 1 / scalar
  

  const getLineStyle = (line, orientation) => {
   const isActive = isLineActive(line, orientation);

    return {
      position: 'absolute',
      left: orientation === 'vertical' ? `${line.position}px` : 0,
      top: orientation === 'horizontal' ? `${line.position}px` : 0,
      width: orientation === 'vertical' ? (isActive ? `${inverseScalar}px` : '1px') : `${slideWidth}px`,
      height: orientation === 'horizontal' ? (isActive ? `${inverseScalar}px` : '1px') : `${slideHeight}px`,
      backgroundColor: isActive ? 'rgba(255, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0)',
      pointerEvents: 'none',
      zIndex: 1000
    };
  };

  return (
    <>
      {snapLines.vertical && snapLines.vertical.map((line, index) => (
        <div
          key={`v-${index}`}          
          style={getLineStyle(line, 'vertical')}
        />
      ))}
      {snapLines.horizontal && snapLines.horizontal.map((line, index) => (
        <div
          key={`h-${index}`}
          style={getLineStyle(line, 'horizontal')}
        />
      ))}
    </>
  );
};

export default SlideEditorSnapLines;