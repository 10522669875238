import {TextSelection, Plugin, PluginKey} from "prosemirror-state"
import {estimateAudioDuration} from '../../../../../utils/estimateAudioDuration'
import {randomID} from '../../../../../utils/randomID'

const isFirstChunkInGroup = (groupNode, chunkNode) => {
  let firstChunk = null;
  groupNode.forEach((node) => {
    if (!firstChunk && (node.type.name === 'transcriptChunk' || node.type.name === 'webcamRecordingChunk')) {
      firstChunk = node;
    }
  });
  return firstChunk === chunkNode;
};

const isLastChunkInGroup = (groupNode, chunkNode) => {
  let lastChunk = null;
  groupNode.forEach((node) => {
    if (node.type.name === 'webcamRecordingChunk' || node.type.name === 'transcriptChunk') {
      lastChunk = node;
    }
  });
  return lastChunk === chunkNode;
};

export const enterKeyHandler = () => {
	return (state, dispatch, view) => {
		const { selection } = state;
		const { $from, $to } = selection;
		let transcriptChunkNode = null;
		let webcamRecordingChunk = null
		let sceneHeaderNode = null;
		let transcriptGroupNode = null;
		let nodePos;
		let groupPos;
		let tr = state.tr;
	
		state.doc.nodesBetween($from.pos, $to.pos, (node, pos) => {
			if (node.type.name === 'transcriptChunk' || node.type.name =='webcamRecordingChunk') {
				transcriptChunkNode = node;
				nodePos = pos;
			}
			if (node.type.name === 'sceneHeader') {
				sceneHeaderNode = node;
				nodePos = pos;
			}
			if (node.type.name === 'transcriptGroup') {
				transcriptGroupNode = node;
				groupPos = pos;
			}
		});

		const isWebcamGroup = transcriptGroupNode?.attrs.groupType === 'webcam-placeholder';
		const isWebcamRecordingGroup = transcriptGroupNode?.attrs.groupType === 'webcam-recording';
		const isStandardGroup = transcriptGroupNode?.attrs.groupType === 'standard';
  
		// Helper function to set cursor position at start of a chunk
		const setCursorToChunkStart = (chunkPos) => {
			return tr.setSelection(TextSelection.create(tr.doc, chunkPos + 2));
		};

		const createStandardGroup = (content) => {
			return state.schema.nodes.transcriptGroup.createChecked({
				groupId: randomID(),
				groupType: 'standard'
			}, content);
		};

		if (transcriptChunkNode) {
			const relativePos = $from.pos - nodePos - 2;
			const textBeforeCursor = transcriptChunkNode.textContent.slice(0, relativePos).trim();
			const textAfterCursor = transcriptChunkNode.textContent.slice(relativePos).trim();
			if (isStandardGroup && !textBeforeCursor && !textAfterCursor) {
				return true;
			}

			////WEBCAM RECORDINGS (not placeholder webcam)
			if (isWebcamRecordingGroup) {
				//if the cursor is at the start of the first chunk in the group then we insert a standard group/chunk above the webcam group.
				//if the cursor is at the end of the last chunk in the group then enter will add a starndard group/chunk below the webcam recording group
				//otherwise we split the chunk and put the text after the cursor into new chunk
		  	const relativePos = $from.pos - nodePos - 2;
  			const isAtStart = relativePos === -1;
	  		// Determine if there are any words that would go into second chunk
  			let hasWordsAfterCursor = false;
  			let currentPos = nodePos + 1;

			  transcriptChunkNode.content.forEach((node) => {
			    if (node.type.name === 'webcamTranscriptWord') {
			      const nodeStartPos = currentPos;
			      // If any word starts after the cursor, we're not at the end
			      if (nodeStartPos >= $from.pos) {
			        hasWordsAfterCursor = true;
			      }
			      currentPos += node.nodeSize;
			    }
			  });

 			 const isAtEnd = !hasWordsAfterCursor;
  
	  		// Create empty chunk for standard group
			  const newChunk = state.schema.nodes.transcriptChunk.createChecked({
			    clipId: randomID(),
			    sceneId: transcriptChunkNode.attrs.sceneId,
			    requiresUpdate: false,
			  }, [state.schema.nodes.paragraph.createChecked()]);

  			const standardGroup = createStandardGroup([newChunk]);
		  	const isFirstChunk=isFirstChunkInGroup(transcriptGroupNode, transcriptChunkNode)

		  // Case 1: Cursor at start of first chunk
		  if (isAtStart) {
		  	if(isFirstChunk){
		  		tr = tr.insert(groupPos, standardGroup);
		    	tr = setCursorToChunkStart(groupPos);
		    	dispatch(tr);
		    	return true;
		  	}else{ // we are at the start of a chunk and its not the first chunk--> we should do nothing
		  		return true
		  	}
		   
		  }

 		 const isLastChunk=isLastChunkInGroup(transcriptGroupNode, transcriptChunkNode)
	  // Case 2: Cursor at end of last chunk - only add standard group if it's the last chunk
  	if (isAtEnd &&isLastChunk ) {
    	const insertPos = groupPos + transcriptGroupNode.nodeSize;
   	 	tr = tr.insert(insertPos, standardGroup);
    	tr = setCursorToChunkStart(insertPos);
    	dispatch(tr);
    	return true;
  	}
	  // If we're at the end of a non-last chunk, do nothing
  	if (isAtEnd) {
    	return true;
  	}
	  // Case 3: Split current chunk
  	let wordsBeforeCursor = [];
  	let wordsAfterCursor = [];
  	currentPos = nodePos + 1; // Reset currentPos for splitting
	  transcriptChunkNode.content.forEach((node) => {
	    if (node.type.name === 'webcamTranscriptWord') {
	      const nodeStartPos = currentPos;
	      const nodeEndPos = currentPos + node.nodeSize;
	      // If the word starts before the cursor position, it goes in the first chunk
	      if (nodeStartPos < $from.pos) {
	        wordsBeforeCursor.push(node);
	      } else {
	        wordsAfterCursor.push(node);
	      }
	      currentPos = nodeEndPos;
	    }
	  });

	  // Create new chunks with the split word nodes
	  const updatedOriginalChunk = state.schema.nodes.webcamRecordingChunk.createChecked(
	    transcriptChunkNode.attrs,
	    wordsBeforeCursor
	  );

	  const newSplitChunk = state.schema.nodes.webcamRecordingChunk.createChecked({
	    clipId: randomID(),
	    sceneId: transcriptChunkNode.attrs.sceneId,
	    requiresUpdate: false,
	    transcriptChunkIndex: transcriptChunkNode.attrs.transcriptChunkIndex + 1,
	    transcriptChunkCount: transcriptChunkNode.attrs.transcriptChunkCount
	  }, wordsAfterCursor);

	  tr = tr.replaceWith(nodePos, nodePos + transcriptChunkNode.nodeSize, updatedOriginalChunk);
	  const insertPos = nodePos + updatedOriginalChunk.nodeSize;
	  tr = tr.insert(insertPos, newSplitChunk);
	  tr = setCursorToChunkStart(insertPos);
	  dispatch(tr);
	  return true;
	}
	////Placeholder webcam
	else if (isWebcamGroup) {

		//if we are in an empty chunk only do something if its the last chunk
		if (!textBeforeCursor && !textAfterCursor) {
			const isLastChunk = isLastChunkInGroup(transcriptGroupNode, transcriptChunkNode);
			if (!isLastChunk) {
				// If it's not the last chunk, do nothing
				return true;
			}

			///If it is the only chunk in the group then we can convert it into a standard group
			const isOnlyChunk = transcriptGroupNode.childCount === 1;
			if (isOnlyChunk) {
				const standardGroup = state.schema.nodes.transcriptGroup.createChecked({
					groupId: transcriptGroupNode.attrs.groupId,
					groupType: 'standard'
				}, [transcriptChunkNode]);
					tr = tr.replaceWith(groupPos, groupPos + transcriptGroupNode.nodeSize, standardGroup);
					tr = setCursorToChunkStart(groupPos); // Position cursor at start of the chunk
					dispatch(tr);
					return true;
				}

				//If it is the lastchunk in the group lets make a standard group below it 
				if (isLastChunk) {
					const newChunk = state.schema.nodes.transcriptChunk.createChecked({
						clipId: randomID(),
						sceneId: transcriptChunkNode.attrs.sceneId,
						requiresUpdate: false,
						transcriptChunkIndex: 0,
						transcriptChunkCount: 1
					}, [state.schema.nodes.paragraph.createChecked()]);

					const newGroup = state.schema.nodes.transcriptGroup.createChecked({
						groupId: randomID(),
						groupType: 'standard'
					}, [newChunk]);

					const chunksWithoutEmpty = [];
					transcriptGroupNode.forEach((node) => {
						if (node !== transcriptChunkNode) {
							chunksWithoutEmpty.push(node);
						}
					});

					const updatedCurrentGroup = state.schema.nodes.transcriptGroup.createChecked({
						groupId: transcriptGroupNode.attrs.groupId,
						groupType: 'webcam-placeholder'
					}, chunksWithoutEmpty);

					tr = tr.replaceWith(groupPos, groupPos + transcriptGroupNode.nodeSize, updatedCurrentGroup);
					const newGroupPos = groupPos + updatedCurrentGroup.nodeSize;
					tr = tr.insert(newGroupPos, newGroup);
					tr = setCursorToChunkStart(newGroupPos); // Position cursor at start of new chunk
					dispatch(tr);
					return true;
					} else { //the chunk is not an empty chunk lets work out what to do
						const chunksBeforeCurrent = [];
						const chunksAfterCurrent = [];
						foundCurrent = false;
						transcriptGroupNode.forEach((node, pos) => {
							if (node === transcriptChunkNode) {
								foundCurrent = true;
							} else if (!foundCurrent) {
								chunksBeforeCurrent.push(node);
							} else {
								chunksAfterCurrent.push(node);
							}
						});

						const firstGroupNode = state.schema.nodes.transcriptGroup.createChecked({
							groupId: transcriptGroupNode.attrs.groupId,
							groupType: 'webcam-placeholder'
						}, chunksBeforeCurrent);

						const secondGroupNode = state.schema.nodes.transcriptGroup.createChecked({
							groupId: randomID(),
							groupType: 'webcam-placeholder'
						}, chunksAfterCurrent);

						tr = tr.replaceWith(groupPos, groupPos + transcriptGroupNode.nodeSize, firstGroupNode);
						const secondGroupPos = groupPos + firstGroupNode.nodeSize;
						tr = tr.insert(secondGroupPos, secondGroupNode);
						tr = setCursorToChunkStart(secondGroupPos); // Position cursor at start of first chunk in second group
						dispatch(tr);
						return true;
					}
				} else {
					const isAtEndOfChunk = relativePos === transcriptChunkNode.textContent.length;
					
					if (isAtEndOfChunk) {
						let nextChunk = null;
						let nextChunkPos = null;
						let foundCurrent = false;
						
						transcriptGroupNode.forEach((node, pos, index) => {
							if (foundCurrent && !nextChunk && node.type.name === 'transcriptChunk') {
								nextChunk = node;
								nextChunkPos = groupPos + pos + 1;
							}
							if (node === transcriptChunkNode) {
								foundCurrent = true;
							}
						});

						if (nextChunk && nextChunk.textContent.trim() === '') {
							tr = setCursorToChunkStart(nextChunkPos);
							dispatch(tr);
							return true;
						}
					}

					const paragraphWithContentBefore = state.schema.nodes.paragraph.createChecked(
						{},
						textBeforeCursor ? [state.schema.text(textBeforeCursor)] : null
					);

					const paragraphWithContentAfter = state.schema.nodes.paragraph.createChecked(
						{},
						textAfterCursor ? [state.schema.text(textAfterCursor)] : null
					);

					const updatedOriginalChunk = state.schema.nodes.transcriptChunk.createChecked(
						transcriptChunkNode.attrs,
						[paragraphWithContentBefore]
					);

					const newChunk = state.schema.nodes.transcriptChunk.createChecked({
						clipId: randomID(),
						sceneId: transcriptChunkNode.attrs.sceneId,
						requiresUpdate: false,
						transcriptChunkIndex: transcriptChunkNode.attrs.transcriptChunkIndex + 1,
						transcriptChunkCount: transcriptChunkNode.attrs.transcriptChunkCount
					}, [paragraphWithContentAfter]);

					tr = tr.replaceWith(nodePos, nodePos + transcriptChunkNode.nodeSize, updatedOriginalChunk);
					const insertPos = nodePos + updatedOriginalChunk.nodeSize;
					tr = tr.insert(insertPos, newChunk);
					tr = setCursorToChunkStart(insertPos); // Position cursor at start of new chunk
				}
			} else {// Standard group behavior
				const isAtEndOfChunk = relativePos === transcriptChunkNode.textContent.length;
				
				if (isAtEndOfChunk) {
					let nextChunk = null;
					let nextChunkPos = null;
					let foundCurrent = false;
					
					transcriptGroupNode.forEach((node, pos, index) => {
						if (foundCurrent && !nextChunk && node.type.name === 'transcriptChunk') {
							nextChunk = node;
							nextChunkPos = groupPos + pos + 1;
						}
						if (node === transcriptChunkNode) {
							foundCurrent = true;
						}
					});

					if (nextChunk && nextChunk.textContent.trim() === '') {
						tr = setCursorToChunkStart(nextChunkPos);
						dispatch(tr);
						return true;
					}
				}

				const paragraphBefore = state.schema.nodes.paragraph.createChecked(
					{},
					textBeforeCursor ? [state.schema.text(textBeforeCursor)] : null
				);

				const paragraphAfter = state.schema.nodes.paragraph.createChecked(
					{},
					textAfterCursor ? [state.schema.text(textAfterCursor)] : null
				);

				const newClipId = randomID();
				const estimatedDuration = estimateAudioDuration(textAfterCursor)/1000;

				const newOriginalChunk = state.schema.nodes.transcriptChunk.createChecked(
					{...transcriptChunkNode.attrs},
					[paragraphBefore]
				);

				const newChunk = state.schema.nodes.transcriptChunk.createChecked({
					clipId: newClipId,
					clipDuration: estimatedDuration,
					sceneId: transcriptChunkNode.attrs.sceneId,
					requiresUpdate: false,
				}, [paragraphAfter]);

				tr = tr.replaceWith(nodePos, nodePos + transcriptChunkNode.nodeSize, newOriginalChunk);
				const insertPos = nodePos + newOriginalChunk.nodeSize;
				tr = tr.insert(insertPos, newChunk);
				tr = setCursorToChunkStart(insertPos); // Position cursor at start of new chunk
			}
			
			dispatch(tr);
			return true;
		} else if (sceneHeaderNode) {
			tr = setCursorToChunkStart(nodePos + sceneHeaderNode.nodeSize);
			dispatch(tr);
			return true;
		}
		
		return false;
	};
};