import store from '../../store'
import find from 'lodash/find'


export function orgHasCollections() {	
	const state = store.getState()

	if(state.collections && state.collections.length>0){
		return true
	}else{
		return false
	}

}
