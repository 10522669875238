import React, { useMemo } from 'react';
import { Text } from '@react-three/drei';
import createRoundedRectShape from '../utils/createRoundedRectShape';
import * as THREE from 'three';
import CaptionsWordBox from './CaptionsWordBox'
import CaptionsWordText from './CaptionsWordText'
import CaptionsGroupBox from './CaptionsGroupBox'

const CaptionsGroup = ({ captionsGroup, globalGroupStartTime, time, isPlaying }) => {
  const globalStartTime = globalGroupStartTime;
  const globalEndTime = globalStartTime + (captionsGroup.endTime - captionsGroup.startTime);
  const duration = (globalEndTime - globalStartTime).toFixed(4);
  const localTime = time - globalStartTime; // time since start of slide
  const isHidden = time < globalStartTime || time >= globalEndTime;

  if (isHidden) {
    return null;
  }

  const fontSize = 70;
  const padding = 0.07 * fontSize;

  return (
    <>

      <CaptionsGroupBox 
        width={captionsGroup.width}
        height={captionsGroup.height}
        position={[captionsGroup.normalLeft, captionsGroup.normalTop, 1]}
        renderOrder={200}
      />
      
      {/* Words with individual rounded boxes and text */}
      {captionsGroup.words.map((word) => {        
        return (
          <group key={word.id} position={[0, 0 , 2]} renderOrder={200}>
            <CaptionsWordBox 
              word={word}
              localTime={localTime} 
            />
            <CaptionsWordText 
              word={word} 
              localTime={localTime} 
              fontSize={fontSize} 
              padding={padding}              
            />
          </group>
        );
      })} 
    </>   
  );
};

export default CaptionsGroup;