import React, { useRef, useEffect, useState, useMemo } from 'react';
import { Text } from '@react-three/drei';
import { calculateParagraphLayout } from '../utils/calculateParagraphLayout';

const TextSlideContents = ({ time, FPS, animationStates, initialProperties, wordsArray, fontFamily, fontWeight, fontSize, letterSpacing, isHidden, textColor, listType }) => {
  const groupRef = useRef();
  const [refsUpdated, setRefsUpdated] = useState(false); 
  const textRefs = useRef([]);

  useEffect(() => {
    const totalRefs = wordsArray.length + 
      ((listType === 'bullet' || listType === 'number') ? 
      Object.keys(calculateParagraphLayout(wordsArray)).length : 0);
    textRefs.current = Array(totalRefs).fill(null).map(() => React.createRef());
    setRefsUpdated(true);
  }, [wordsArray, listType]);

  useEffect(() => {
    if (refsUpdated) {
      const initialOpacity = isHidden ? 0 : 1;
      textRefs.current.forEach(ref => {
        if (ref.current && ref.current.material) {
          ref.current.material.opacity = initialOpacity;
        }
      });
      setRefsUpdated(false);
    }
  }, [refsUpdated, isHidden]);

  useEffect(() => {
    const initialOpacity = isHidden ? 0 : 1;
    textRefs.current.forEach(ref => {
      if (ref.current && ref.current.material) {
        ref.current.material.opacity = initialOpacity;
      }
    });
  }, [isHidden]);

  useEffect(() => {
    const frameIndex = Math.floor(time * FPS);
    if (groupRef.current) {
      Object.keys(animationStates).forEach(property => {
        const state = animationStates[property][frameIndex];
        if (state !== undefined) {
          if (property.startsWith('position')) {
            const axis = property.slice('position'.length).toLowerCase();
            groupRef.current.position[axis] = state;
          } else if (property === 'scale' && groupRef.current.scale) {
            groupRef.current.scale.set(state, state, state);
          } else if (property.startsWith('rotation')) {
            const axis = property.slice('rotation'.length).toLowerCase();
            groupRef.current.rotation[axis] = state;
          } else if (property === 'opacity') {
            textRefs.current.forEach(ref => {
              if (ref.current && ref.current.material) {
                ref.current.material.opacity = isHidden ? 0 : state;
              }
            });
          }
        }
      });
    }
  }, [time, FPS, animationStates, isHidden, wordsArray]);

  const paragraphs = useMemo(() => calculateParagraphLayout(wordsArray), [wordsArray]);

  return (
    <group
      ref={groupRef}
      position={[initialProperties.positionX, initialProperties.positionY, initialProperties.positionZ]}
      scale={[initialProperties.scale, initialProperties.scale, initialProperties.scale]}
    >
      {wordsArray.map((wordObj, index) => (
        <Text        
          key={index}
          ref={textRefs.current[index]}
          anchorX="left"
          anchorY="top"
          fontSize={fontSize}                
          textAlign="left"
          letterSpacing={letterSpacing}
          font={`../fonts/${fontFamily}/${fontFamily}-${fontWeight}.woff`}
          position={[wordObj.rect.normalLeft, wordObj.rect.normalTop, 10]}
        >
          <meshBasicMaterial color={textColor} opacity={1} transparent={false}/>
          {wordObj.word}
        </Text>  
      ))}

      {listType && listType === 'bullet' && Object.entries(paragraphs).map(([index, paragraph], pIndex) => (
        <Text        
          key={index}
          ref={textRefs.current[wordsArray.length + pIndex]} 
          anchorX="left"
          anchorY="top"
          fontSize={fontSize}                
          textAlign="right"
          font={`../fonts/${fontFamily}/${fontFamily}-${fontWeight}.woff`}          
          position={[-760, paragraph.minTop, 10]}
        >
          <meshBasicMaterial color={textColor} />
          •
        </Text>     
      ))}

      {listType && listType === 'number' && Object.entries(paragraphs).map(([index, paragraph], pIndex) => (
        <Text        
          key={index}
          ref={textRefs.current[wordsArray.length + pIndex]} 
          anchorX="left"
          anchorY="top"
          fontSize={fontSize}                
          textAlign="right"
          font={`../fonts/${fontFamily}/${fontFamily}-${fontWeight}.woff`}          
          position={[-800, paragraph.minTop, 10]}
        >
          <meshBasicMaterial color={textColor} />
          {`${parseInt(index) + 1}.`}
        </Text>     
      ))}
    </group>
  );
};

export default TextSlideContents;
