import { ExportTimelineClip } from './ExportTimelineClip'
import { calculateCaptionWordGroupPositions } from '../../timeline/utils/calculateCaptionWordGroupPositions'
import { calculateSubtitleGroupWordPositions } from '../../timeline/utils/calculateSubtitleGroupWordPositions'

class ExportAudioClip extends ExportTimelineClip {
  constructor(options, scene) {
    super(options, scene)
    this.scene = scene
    this.loadPromise = this.processCaptionGroups()
  }

  // async processCaptionGroups() {
  //   if (this.metadata.unprocessedCaptionGroups && this.metadata.unprocessedCaptionGroups.length > 0) {
  //    // console.log('Processing unprocessed caption groups')
  //     try {
  //       const updatedCaptionsGroups = this.metadata.unprocessedCaptionGroups.map(async (group) => {
  //         return await calculateCaptionWordGroupPositions(group)
  //       })
  //       const processedGroups = await Promise.all(updatedCaptionsGroups)
  //       this.metadata.captionGroups = processedGroups
  //       this.metadata.unprocessedCaptionGroups = null // Clear the unprocessed groups
  //    //   console.log('Updated caption groups', this.metadata.captionGroups)
  //     } catch (error) {
  //  //     console.error('Error processing caption groups:', error)
  //       // Don't throw the error, as we want the promise to resolve even if processing fails
  //     }
  //   } else {
  //  //   console.log('No unprocessed caption groups to process')
  //   }
  //   // Always resolve the promise, even if there were no captions to process or if processing failed
  //   return Promise.resolve()
  // }


   async processCaptionGroups() {
    if (this.metadata.unprocessedSubtitlesGroups && this.metadata.unprocessedSubtitlesGroups.length > 0) {
     // console.log('Processing unprocessed caption groups')
      try {
        const updatedSubtitlesGroups = this.metadata.unprocessedSubtitlesGroups.map(async (group) => {
          return await calculateSubtitleGroupWordPositions(group)
        })
        const processedGroups = await Promise.all(updatedSubtitlesGroups)
        this.metadata.subtitlesGroups = processedGroups
        this.metadata.unprocessedSubtitlesGroups = null // Clear the unprocessed groups
     //   console.log('Updated caption groups', this.metadata.captionGroups)
      } catch (error) {
   //     console.error('Error processing caption groups:', error)
        // Don't throw the error, as we want the promise to resolve even if processing fails
      }
    } else {
   //   console.log('No unprocessed caption groups to process')
    }
    // Always resolve the promise, even if there were no captions to process or if processing failed
    return Promise.resolve()
  }

  destroy() {
    // Add any cleanup logic here if needed
  }
}

export { ExportAudioClip };