import store from '../../store'
import find from 'lodash/find'


export function getCollectionForId(collectionId) {	
	const state = store.getState()

	const collection = find(state.collections,{id:collectionId})

	return collection 
}
