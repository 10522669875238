import * as THREE from 'three';

export const createDropShadow = (deviceWidth, deviceHeight, shadowOptions, meshRadius) => {
  const {
    xOffset = 0,
    yOffset = 0,
    blur = 10,
    spread = 1,
    shadowColor = 'rgba(0,0,0,0.5)',
    opacity = 0.5,
  } = shadowOptions;

  const canvasWidth = 1920;
  const canvasHeight = 1080;
  const canvas = document.createElement('canvas');
  canvas.width = canvasWidth;
  canvas.height = canvasHeight;
  const context = canvas.getContext('2d');

  context.filter = `blur(${blur}px)`;
  context.fillStyle = shadowColor;
  context.globalAlpha = opacity;

  const centerX = (canvasWidth - deviceWidth) / 2;
  const centerY = (canvasHeight - deviceHeight) / 2;

  // Function to draw a rounded rectangle
  const drawRoundedRect = (x, y, width, height, radius) => {
    context.beginPath();
    context.moveTo(x + radius, y);
    context.arcTo(x + width, y, x + width, y + height, radius);
    context.arcTo(x + width, y + height, x, y + height, radius);
    context.arcTo(x, y + height, x, y, radius);
    context.arcTo(x, y, x + width, y, radius);
    context.closePath();
    context.fill();
  };

  // Draw the shadow with rounded corners
  drawRoundedRect(
    centerX + xOffset - spread - blur,
    centerY - yOffset - spread - blur,
    deviceWidth + spread * 2 + blur * 2,
    deviceHeight + spread * 2 + blur * 2,
    meshRadius + spread // Increase corner radius by spread amount
  );

  context.globalAlpha = 1.0;

  const texture = new THREE.CanvasTexture(canvas);
  texture.needsUpdate = true;
  const material = new THREE.MeshBasicMaterial({
    map: texture,
    transparent: true,
  });

  const shadowMesh = new THREE.Mesh(
    new THREE.PlaneGeometry(canvasWidth, canvasHeight),
    material
  );

  return shadowMesh;
};