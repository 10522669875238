
const recordingFiles= [
    { "name": "keystrokes-0.json", "type": "application/json" },
	{ "name": "mouseclicks-0.json", "type": "application/json" },
	{ "name": "mousemoves-0.json", "type": "application/json" },
	{ "name": "metadata.json", "type": "application/json" },
	{ "name": "channel-1-display-0.mp4", "type": "video/mp4" }
]


const deviceFiles= [
	{ "name": "metadata.json", "type": "application/json" },
	{ "name": "recordKit.json", "type": "application/json" },
	{ "name": "deviceRecording.mov", "type": "video/mp4" }
  ]

const screenRecordingFiles= [
	{ "name": "log.json", "type": "application/json" },
	{ "name": "recording.input-events.json", "type": "application/json" },
	{ "name": "metadata.json", "type": "application/json" },
	{ "name": "recording.mp4", "type": "video/mp4" }
]


export function setFilesToBeDownloaded(captureId,isDevice,isScreenRecording){
	return (dispatch) => {
		let files = recordingFiles 
		if(isDevice){
			files=deviceFiles
		}
		if(isScreenRecording){
			files=screenRecordingFiles
		}

		dispatch({ type: 'SET_FILES_TO_DOWNLOAD', captureId,files})
	}
}


export function handleDownloadFileSucess(captureId,fileName){
	return (dispatch) => {
		dispatch({ type: 'DOWNLOAD_SUCCESS', captureId,fileName})
	}
}


export function handleImageDownloadSucess(imageId){
	return (dispatch) => {
		dispatch({ type: 'IMAGE_DOWNLOAD_SUCCESS', imageId})
	}
}

export function handleBasicVideoDownloadSucess(fileId){
	return (dispatch) => {
		dispatch({ type: 'BASIC_VIDEO_DOWNLOAD_SUCCESS', fileId})
	}
}


export function handleMusicDownloadSucess(trackId){
	return (dispatch) => {
		dispatch({ type: 'MUSIC_DOWNLOAD_SUCCESS', trackId})
	}
}