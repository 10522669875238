const skipMark = {
    attrs: {},
    parseDOM: [{
        tag: "span.editor-transcriptPanel-transcriptGroup--cameraRecording-word--skip"
    }],
    toDOM() {
        return ["span", {
            class: "editor-transcriptPanel-transcriptGroup--cameraRecording-word--skip",
            "data-skip": "true"
        }];
    }
};

export default skipMark;