import React, { useRef, useState, useEffect } from 'react';
import { Rnd } from 'react-rnd';
import Icon from '../misc/Icon';

const EditorTrimBarHandles = ({
  onTrimChange,
  onTrimPositionChange,
  dragAreaWidth,
  currentVideoPosition,
  isVideoPaused,
  initialTrimStart,
  initialTrimEnd
}) => {

  const [startHandlePosition, setStartHandlePosition] = useState(initialTrimStart); // as decimal
  const [endHandlePosition, setEndHandlePosition] = useState(initialTrimEnd); // as decimal





  const convertToPixels = (decimal) => decimal * dragAreaWidth;
  const convertToDecimal = (pixels) => pixels / dragAreaWidth;

  const handleDrag = (handleType, e, d) => {
  let newX, newStartDecimal, newEndDecimal;

  if (handleType === 'start') {
    newX = convertToPixels(startHandlePosition) + d.deltaX;
    newStartDecimal = convertToDecimal(newX);
    onTrimPositionChange(newStartDecimal);

    if (newStartDecimal >= endHandlePosition) {
      newEndDecimal = Math.min(1, endHandlePosition + (newStartDecimal - startHandlePosition));
      setEndHandlePosition(newEndDecimal);
      setStartHandlePosition(endHandlePosition);
      onTrimChange(endHandlePosition, 'start');
      onTrimChange(newEndDecimal, 'end');
    } else {
      setStartHandlePosition(newStartDecimal);
      onTrimChange(newStartDecimal, 'start');
    }
  } else if (handleType === 'end') {
    newX = convertToPixels(endHandlePosition) + d.deltaX;
    newEndDecimal = convertToDecimal(newX);

    if (newEndDecimal <= startHandlePosition) {
      newStartDecimal = Math.max(0, startHandlePosition - (endHandlePosition - newEndDecimal));
      setStartHandlePosition(newStartDecimal);
      setEndHandlePosition(startHandlePosition);
      onTrimChange(newStartDecimal, 'start');
      onTrimChange(startHandlePosition, 'end');
    } else {
      setEndHandlePosition(newEndDecimal);
      onTrimChange(newEndDecimal, 'end');
    }
  }
};

  const jumpStartToCurrentPosition = () => {    
    setStartHandlePosition(currentVideoPosition);
    onTrimChange(currentVideoPosition, 'start');  
  };

  const jumpEndToCurrentPosition = () => {    
    setEndHandlePosition(currentVideoPosition);
    onTrimChange(currentVideoPosition, 'end');    
  };


  const showJumpStartToPositionBtn = isVideoPaused && 
                                     currentVideoPosition < endHandlePosition && 
                                     currentVideoPosition !== startHandlePosition;

  const showJumpEndToPositionBtn = isVideoPaused && 
                                   currentVideoPosition > startHandlePosition && 
                                   currentVideoPosition !== endHandlePosition;



  const videoPixelPosition = convertToPixels(currentVideoPosition);
  const totalPixelWidth = convertToPixels(1);

  const startHandlePixelPosition = convertToPixels(startHandlePosition);
  const endHandlePixelPosition = convertToPixels(endHandlePosition);


  const jumpBtnWidth = 24
  const bufferJumpBtn = jumpBtnWidth + 20
  let jumpStartBtnPosition = 'left'
  if(startHandlePixelPosition < bufferJumpBtn){
    jumpStartBtnPosition = 'right'
  }
  if(videoPixelPosition < bufferJumpBtn){
    jumpStartBtnPosition = 'none'
  }

  let jumpEndBtnPosition = 'right'
  if(totalPixelWidth - endHandlePixelPosition < bufferJumpBtn){
    jumpEndBtnPosition = 'left'
  }
  if(totalPixelWidth - videoPixelPosition < bufferJumpBtn){
    jumpEndBtnPosition = 'none'
  }


  let showJumpStartBtnArrowIcon = 'arrowLeft'
  if(currentVideoPosition > startHandlePosition){
    showJumpStartBtnArrowIcon = 'arrowRight'
  }

  let showJumpEndBtnArrowIcon = 'arrowLeft'
  if(currentVideoPosition > endHandlePosition){
    showJumpEndBtnArrowIcon = 'arrowRight'
  }


  return (
    <>
      <Rnd
        className="editor-trimbar-trimHandle editor-trimbar-trimHandle--start"
        size={{ width: 1, height: 29 }}
        position={{ x: convertToPixels(startHandlePosition), y: 0 }}
        onDrag={(e, d) => handleDrag('start', e, d)}
        enableResizing={false}
        bounds="parent"
        style={{cursor: 'grab'}}
        axis="x"
      >
        <div className='editor-trimbar-trimHandle-handle'>

        <div className='editor-trimbar-trimHandle-handle-bar' />
        {showJumpStartToPositionBtn &&
          <button style={{width: `${jumpBtnWidth}px`}} onClick={jumpStartToCurrentPosition} className={'editor-trimbar-trimHandle-handle-jumpBtn ' + (jumpStartBtnPosition ? `editor-trimbar-trimHandle-handle-jumpBtn--${jumpStartBtnPosition}` : '')}>
            <Icon name={showJumpStartBtnArrowIcon} />
          </button>
        }

        </div>

      </Rnd>

      <Rnd
        className="editor-trimbar-trimHandle editor-trimbar-trimHandle--end"
        size={{ width: 1, height: 29 }}
        position={{ x: convertToPixels(endHandlePosition), y: 0 }}
        onDrag={(e, d) => handleDrag('end', e, d)}
        enableResizing={false}
        bounds="parent"
        style={{cursor: 'grab'}}
        axis="x"
      >
        <div className='editor-trimbar-trimHandle-handle'>

        <div className='editor-trimbar-trimHandle-handle-bar' />

          {showJumpEndToPositionBtn &&
            <button style={{width: `${jumpBtnWidth}px`}} onClick={jumpEndToCurrentPosition} className={'editor-trimbar-trimHandle-handle-jumpBtn ' + (jumpEndBtnPosition ? `editor-trimbar-trimHandle-handle-jumpBtn--${jumpEndBtnPosition}` : '')}>
              <Icon name={showJumpEndBtnArrowIcon} />
            </button>
          }
        </div>
      </Rnd>

      <div style={{width:`${startHandlePixelPosition}px`}} className='editor-trimbar-leftBlock' />

      <div style={{width:`${totalPixelWidth - endHandlePixelPosition}px`}} className='editor-trimbar-rightBlock' />



    </>
  );
};

export default EditorTrimBarHandles;
