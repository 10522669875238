// abbreviations.js
const abbreviations = {
    "e\\.g\\.": "PLACEHOLDER_EG", 
    "etc\\.": "PLACEHOLDER_ETC",
    "i\\.e\\.": "PLACEHOLDER_IE",
    "Mrs\\.": "PLACEHOLDER_MRS",
    "Mr\\.": "PLACEHOLDER_MR",
    "Ms\\.": "PLACEHOLDER_MS",
    "Dr\\.": "PLACEHOLDER_DR",
    "Prof\\.": "PLACEHOLDER_PROF",
    "vs\\.": "PLACEHOLDER_VS",
    "St\\.": "PLACEHOLDER_ST", // For Saint or Street, depending on context
    "Lt\\.": "PLACEHOLDER_LT",
    "Gen\\.": "PLACEHOLDER_GEN",
    "Sen\\.": "PLACEHOLDER_SEN",
    "Corp\\.": "PLACEHOLDER_CORP",
    "Inc\\.": "PLACEHOLDER_INC",
    "Ltd\\.": "PLACEHOLDER_LTD",
    "Co\\.": "PLACEHOLDER_CO",
    "Jr\\.": "PLACEHOLDER_JR",
    "Sr\\.": "PLACEHOLDER_SR",
    "Ph\\.D\\.": "PLACEHOLDER_PHD",
};

export default abbreviations;
