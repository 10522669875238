class TranscriptGroupNodeView {

	constructor(node, view, getPos) {
		this.dom = document.createElement('div');
		this.dom.classList.add('editor-transcriptPanel-transcriptGroup');

		this.bgDiv = document.createElement('div');
		this.bgDiv.classList.add('editor-transcriptPanel-transcriptGroup-bg');

		this.titleDiv = document.createElement('div');
		this.titleDiv.classList.add('editor-transcriptPanel-transcriptGroup-title');

		this.contentDOM = document.createElement('div');
		this.contentDOM.classList.add('transcript-group-content');

		// Apply initial styles
		this.updateStyles(node.attrs.groupType,node.attrs.isProcessing);

		this.dom.appendChild(this.bgDiv);
		this.dom.appendChild(this.titleDiv);
		this.dom.appendChild(this.contentDOM);
	}

	
	updateStyles(groupType,isProcessing) {
		// Remove both possible classes
		this.dom.classList.remove(
			'editor-transcriptPanel-transcriptGroup--cameraDraft',
			'editor-transcriptPanel-transcriptGroup--cameraRecording',
			'editor-transcriptPanel-transcriptGroup--processing'
		);
		
		///Temp for nicole is processing styling	
		this.bgDiv.style.backgroundColor = '';
		////
		
		// Add the appropriate class
		if (groupType === 'webcam-placeholder') {
			this.dom.classList.add('editor-transcriptPanel-transcriptGroup--cameraDraft');
		} else if (groupType === 'webcam-recording') {
			this.dom.classList.add('editor-transcriptPanel-transcriptGroup--cameraRecording');
		}
		
		if (isProcessing) {
			this.dom.classList.add('editor-transcriptPanel-transcriptGroup--processing');
			///temp for nicole is processing styling
			this.bgDiv.style.backgroundColor = 'rgba(255, 192, 203, 0.3)';
			/////
		}
	}



	 update(node) {
		// Always update styles when update is called
		this.updateStyles(node.attrs.groupType,node.attrs.isProcessing);
		return true;
	}


}

export default TranscriptGroupNodeView;