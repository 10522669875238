// prefillTerms.js

const prefillSearchesImage = [
  { label: 'Gradient', previewMediaUrl: 'https://res.cloudinary.com/yarn/image/upload/q_auto:best/w_640/f_png/v1715795908/prefillsearches/gradient_p78uia.png' },
    { label: 'Abstract', previewMediaUrl: 'https://res.cloudinary.com/yarn/image/upload/q_auto:best/w_640/f_png/v1715795909/prefillsearches/abstract_nbfssd.png' },
    { label: 'Landscape', previewMediaUrl: 'https://res.cloudinary.com/yarn/image/upload/q_auto:best/w_640/f_png/v1715795910/prefillsearches/landscape_l3ir6g.png' },
    { label: 'City', previewMediaUrl: 'https://res.cloudinary.com/yarn/image/upload/q_auto:best/w_640/f_png/v1715795908/prefillsearches/city_adc4eg.png' },    
    { label: 'Tech', previewMediaUrl: 'https://res.cloudinary.com/yarn/image/upload/q_auto:best/w_640/f_png/v1715797318/prefillsearches/tech_wfltcj.png' },    
    { label: 'Fine Art', previewMediaUrl: 'https://res.cloudinary.com/yarn/image/upload/q_auto:best/w_640/f_png/v1715797508/prefillsearches/pexels-enginakyurt-6688470_n65dr4.png' },        
    { label: 'Crowd', previewMediaUrl: 'https://res.cloudinary.com/yarn/image/upload/q_auto:best/w_640/f_png/v1715797318/prefillsearches/crowd_ypp7su.png' },        
    { label: 'Space', previewMediaUrl: 'https://res.cloudinary.com/yarn/image/upload/q_auto:best/w_640/f_png/v1715797319/prefillsearches/space_zwcepa.jpg' },    
  ];

const prefillSearchesVideo = [
  { label: 'Aerial', previewMediaUrl: 'https://res.cloudinary.com/yarn/video/upload/so_0,eo_10,w_340,c_scale/v1715803674/prefillsearches/aerial_oli6fp.mp4' },      
      { label: 'City', previewMediaUrl: 'https://res.cloudinary.com/yarn/video/upload/so_0,eo_10,w_340,c_scale/v1715803677/prefillsearches/city_wlni9v.mp4' },
      { label: 'Nature', previewMediaUrl: 'https://res.cloudinary.com/yarn/video/upload/so_0,eo_10,w_340,c_scale/v1715803683/prefillsearches/nature_wxo7ry.mp4' },
      { label: 'Gradient', previewMediaUrl: 'https://res.cloudinary.com/yarn/video/upload/so_0,eo_10,w_340,c_scale/v1715803676/prefillsearches/gradient_upldwp.mp4' },
      { label: 'Abstract', previewMediaUrl: 'https://res.cloudinary.com/yarn/video/upload/so_0,eo_10,w_340,c_scale/v1715803680/prefillsearches/abstract_jkjfyt.mp4' },
      { label: 'Landscape', previewMediaUrl: 'https://res.cloudinary.com/yarn/video/upload/so_0,eo_10,w_340,c_scale/v1715803687/prefillsearches/landscapes_gbj3v1.mp4' },
      { label: 'Space', previewMediaUrl: 'https://res.cloudinary.com/yarn/video/upload/so_0,eo_10,w_340,c_scale/v1715803692/prefillsearches/space_klhrcs.mp4' },
      { label: 'Crowd', previewMediaUrl: 'https://res.cloudinary.com/yarn/video/upload/so_0,eo_10,w_340,c_scale/v1715803663/prefillsearches/crowd_vtt8zg.mp4' },
    ];

export { prefillSearchesImage, prefillSearchesVideo };
