export const parseDataIntoBarChart = (data) => {
  // Keep a copy of the original data for raw row index tracking
  const originalData = [...data];
  // Filter out completely empty rows
  data = data.filter(row => row.some(cell => cell.value.trim() !== ""));
  // Remove completely empty columns
  let columnLength = Math.max(...data.map(row => row.length));
  let emptyColumnsIndexes = [];
  for (let i = 0; i < columnLength; i++) {
    if (data.every(row => !row[i] || row[i].value.trim() === "")) {
      emptyColumnsIndexes.push(i);
    }
  }
  data = data.map(row => row.filter((_, index) => !emptyColumnsIndexes.includes(index)));
  if (!Array.isArray(data) || data.length === 0) {
    return { compatible: false };
  }
  const chartItems = [];
  let rawRowIndex = 0; // Initialize raw row index counter
  for (const row of originalData) {
    // Skip completely empty rows for chart item generation
    if (row.some(cell => cell.value.trim() !== "")) {
      let label, value, numberColumn, extracted, firstExtracted, secondExtracted;

      // Single column logic
      if (row.length === 1) {
        extracted = extractData(row[0].value);
        if (extracted) {
          extracted.value.number = parseFloat(extracted.value.number.replace(/,/g, ''))
          chartItems.push({
            value: extracted.value,
            label: extracted.postLabel,
            rawRowIndex: rawRowIndex
          });
        }
      } 
      // Two columns logic
      else if (row.length === 2) {
        firstExtracted = extractData(row[0].value);
        secondExtracted = extractData(row[1].value);
        numberColumn = (secondExtracted && !isNaN(parseFloat(secondExtracted.value.number.replace(/,/g, '')))) ? 1 : 0;
        
        if (firstExtracted && secondExtracted && !isNaN(parseFloat(firstExtracted.value.number.replace(/,/g, ''))) && !isNaN(parseFloat(secondExtracted.value.number.replace(/,/g, '')))) {
          numberColumn = 1;
        }

        if ((numberColumn === 0 && firstExtracted) || (numberColumn === 1 && secondExtracted)) {
          value = numberColumn === 0 ? firstExtracted.value : secondExtracted.value;
          label = row[1 - numberColumn].value;
           value.number = parseFloat(value.number.replace(/,/g, ''));

          chartItems.push({
            value: value,
            label: label,
            rawRowIndex: rawRowIndex
          });
        }
      }
    }

    // Increment raw row index regardless of row content
    rawRowIndex++;

    // Limit the number of chart items
    if (chartItems.length > 6) {
      break;
    }
  }

  return {
    compatible: chartItems.length > 0,
    barItems: chartItems,
    title:'test',
    barType:'vertical'
  };
};


const extractData = (str) => {
  // Start of the string or a whitespace character
  const startOrWhitespace = "(?:^|\\s)";
  // Captures any non-digit, non-space characters (prefix)
  const prefix = "([^\\d\\s]*)";
  // Matches 1 to 3 digits
  const initialDigits = "\\d{1,3}";
  // Optionally matches groups of a comma followed by three digits
  const commaGroups = "(,\\d{3})*";
  // Optionally matches a decimal point followed by one or more digits
  const decimalPart = "(\\.\\d+)?";
  

  // // Combines the parts for capturing the numeric value
  // const numericValue = `(${initialDigits}${commaGroups}${decimalPart})`;

  //const numericValue = "(\\d{1,3}(?:,\\d{3})*|\\d+)"; //handle morethan 3 numbers
 // const numericValue = `(${initialDigits}${commaGroups}${decimalPart}|\\d+(\\.\\d+)?)`;
 
 //const numericValue = "(\\d+(?:,\\d{3})*(?:\\.\\d+)?)"; //this works but breaks while typing e.g 10,00
 const numericValue = "(\\d+(?:,\\d+)*(?:\\.\\d+)?)";
 

  //const numericValue = "(\\d{1,3}(?:,\\d{3})*|\\d+)(\\.\\d+)?";
  // Optionally captures non-digit, non-space characters (suffix)
  const suffix = "([^\\d\\s]+)?";
  // Captures any text following the number (post-label), starting with a space or end of the string
  //const postLabel = "(\\s.*|$)";
  const postLabel = "(?:\\s+(.*))?$";
  // Combine all parts to form the final regex
  const regexPattern = `${startOrWhitespace}${prefix}${numericValue}${suffix}${postLabel}`;
  const regex = new RegExp(regexPattern);
  // Use the constructed regex to match the string
  const match = str.match(regex);
  // console.log(match);
  // Process the match result
  return match ? {
    aboveLabel: str.substring(0, match.index).trim(),
    value: {
      prefix: match[1].trim(),
      number: match[2],
      suffix: match[3] ? match[3].trim() : '',
    },
     belowLabel: match[4] ? match[4].trim() : ''
  } : null;
};


// export const parseDataIntoBarChart = (data) => {
//   const originalData = [...data];

//   // Remove completely empty rows
//   data = data.filter(row => row.some(cell => cell.value.trim() !== ""));
//   // Remove completely empty columns
//   let columnLength = Math.max(...data.map(row => row.length));
//   let emptyColumnsIndexes = [];
//   for (let i = 0; i < columnLength; i++) {
//     if (data.every(row => !row[i] || row[i].value.trim() === "")) {
//       emptyColumnsIndexes.push(i);
//     }
//   }
//   data = data.map(row => row.filter((_, index) => !emptyColumnsIndexes.includes(index)));

//   if (!Array.isArray(data) || data.length === 0) {
//     return { compatible: false };
//   }


//   const chartItems = [];
//   let rawRowIndex = 0; // for mapping to the highlight states

//   for (const [index, row] of data.entries()) {
//   let label, value, numberColumn, extracted, firstExtracted, secondExtracted;

//   // Single column logic
//   if (row.length === 1) {
//     extracted = extractData(row[0].value);
//     if (extracted && !isNaN(parseFloat(extracted.value.number.replace(/,/g, '')))) {
//       chartItems.push({
//         value: extracted.value, // Make sure to use the number from the value object
//         label: extracted.postLabel
//       });
//     }
//   } 
//   // Two columns logic
//   else if (row.length === 2) {
//     firstExtracted = extractData(row[0].value);
//     secondExtracted = extractData(row[1].value);
//     numberColumn = (secondExtracted && !isNaN(parseFloat(secondExtracted.value.number.replace(/,/g, '')))) ? 1 : 0;

//     // If both columns contain a number, choose the second column as the value
//     if (firstExtracted && secondExtracted && !isNaN(parseFloat(firstExtracted.value.number.replace(/,/g, ''))) && !isNaN(parseFloat(secondExtracted.value.number.replace(/,/g, '')))) {
//       numberColumn = 1;
//     }

//     // Ensure that we have valid extracted data before trying to access its properties
//     if ((numberColumn === 0 && firstExtracted) || (numberColumn === 1 && secondExtracted)) {
//       value = numberColumn === 0 ? firstExtracted.value : secondExtracted.value; // Use the number from the value object
//       label = row[1 - numberColumn].value;

//       const cleanedRowIndex = index + titleOffset; // Adjust index for title

//       chartItems.push({
//         value: value, // Make sure to use the number, not the entire value object
//         label: label,
//         cleanedRowIndex: cleanedRowIndex
//       });
//     }
//   }

//   // Stop if we have more than 6 chartItems
//   if (chartItems.length > 6) {
//     break;
//   }
// }

//  console.log(chartItems)
//   return {
//     compatible: chartItems.length > 0,
//     barItems: chartItems
//   };
// };
