const SceneNodeSpec = {
	attrs: {
		type:{default:'scene'},
		id:{default:'defaultId'},
		title:{default:'defaultTitle'},
		sceneIndex:{default:0}
	},
};

 export default SceneNodeSpec
 