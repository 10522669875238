export const mediaGallerySizing = (media, rowHeight, rowWidth, maxRows, minItemWidth, maxItemWidth) => {
    const rows = [];
    let currentRow = [];
    let currentRowWidth = 0;

    media.forEach((item, index) => {
        const aspectRatio = item.original_width / item.original_height;
        let width = rowHeight * aspectRatio;

        // Enforce minimum and maximum initial width constraints
        if (width < minItemWidth) width = minItemWidth;
        if (width > maxItemWidth) width = maxItemWidth;

        currentRowWidth += width;

        if (currentRowWidth <= rowWidth) {
            currentRow.push({ ...item, width });
        } else {
            // Calculate the total current row width before adjustment
            const totalCurrentRowWidth = currentRow.reduce((sum, img) => sum + img.width, 0);
            // Calculate the scaling factor
            const scale = rowWidth / totalCurrentRowWidth;

            // Adjust widths proportionally
            currentRow = currentRow.map(img => ({
                ...img,
                containerWidth: img.width * scale
            }));

            // Push the adjusted row and reset for the next row
            rows.push(currentRow);

            // Reset for the new row
            currentRow = [{ ...item, width }];
            currentRowWidth = width;

            // Stop if maxRows is reached
            if (rows.length >= maxRows) {
                return;
            }
        }
    });

    // Add the last row if it hasn't been added
    if (currentRow.length > 0 && rows.length < maxRows) {
        // Calculate the total current row width before adjustment
        const totalCurrentRowWidth = currentRow.reduce((sum, img) => sum + img.width, 0);
        // Calculate the scaling factor
        const scale = rowWidth / totalCurrentRowWidth;

        currentRow = currentRow.map(img => ({
            ...img,
            containerWidth: img.width * scale
        }));
        rows.push(currentRow);
    }

    return rows.slice(0, maxRows);
};
