import { normalizePositionsToCenterCenter } from './normalizePositions';

export const centerBarChartHorizontally = (positions, sceneWidth, barWidth, barSpacing) => {
  // Correct the calculation to include only the necessary barSpacing between bars
  const totalBarsWidth = positions.length * barWidth + (positions.length - 1) * barSpacing;
  const offsetX = (sceneWidth - totalBarsWidth) / 2;

  return positions.map(pos => ({
    ...pos,
    x: pos.x + offsetX
  }));
};


export const createBarChartMeshData = (rowData, title, barWidth, barSpacing, maxBarHeight) => {
  
  const sceneWidth = 1920;
  const sceneHeight = 1080;
  const bottomOffset = 0;
  
  //const barCount = rowData.length
  // let barSpacing = 40;
  // let barWidth = 120;

  // if(barCount === 1){
  //   barSpacing = 0
  //   barWidth = 340
  // }

  // if(barCount === 2){
  //   barSpacing = 120
  //   barWidth = 240
  // }

  // if(barCount === 3){
  //   barSpacing = 110
  //   barWidth = 220
  // }

  // if(barCount === 4){
  //   barSpacing = 100
  //   barWidth = 200
  // }

  // if(barCount === 5){
  //   barSpacing = 80
  //   barWidth = 190
  // }

  // if(barCount === 6){
  //   barSpacing = 70
  //   barWidth = 180
  // }


  
    const maxValue = Math.max(...rowData.map(item => item.value.number));

  if (rowData && Array.isArray(rowData)) {
    const initialPositions = rowData.map((item, index) => {
      const numericValue = item.value.number; // Directly use the numeric value
      const height = (numericValue / maxValue) * maxBarHeight;
      const x = (index * (barWidth + barSpacing));        
      const y = bottomOffset;

      return {
        label: item.label,
        x: x,
        y: y,
        dimensions: [barWidth, height, 10],
        value: numericValue,
        valueSuffix: item.value.suffix,
        valuePrefix: item.value.prefix,
        highlightState: item.highlightState,
      };
    });

    // Center bars group horizontally using the centerBarChartHorizontally function
    const centeredPositions = centerBarChartHorizontally(initialPositions, sceneWidth, barWidth, barSpacing);

    // Normalize positions to scene center
    return normalizePositionsToCenterCenter(centeredPositions, sceneWidth, sceneHeight);
  } else {
    return [];
  }
};
