import React, { useState, useEffect,useMemo,useCallback } from 'react';
import ReactSlider from 'react-slider';
import { timeToPixels } from '../utils/timeToPixels';
import { pixelsToTime } from '../utils/pixelsToTime';
import * as ContextMenu from '@radix-ui/react-context-menu';
import Icon from '../../../misc/Icon';

const EditorTimelineProgressMarker = (props) => {
	const {
		currentTime,
		scenes,
		pixelsPerSec,
		timelineDurationSeconds,
		isPreviewing,
		prePreviewState,
		maxTimelineDurationSeconds,
		sceneGap,
		handleSeek,

		//handleDeactivateActiveClip,    
		playheadCurrentTime,
		scrollRef,
		scrollToEnsureVisible,
		isPlaying,
		setIsDraggingProgressMarker
	} = props;

	// const [sliderPosition, setSliderPosition] = useState(0);

	const sliderPosition = useMemo(() => 
		timeToPixels(currentTime, pixelsPerSec, scenes, sceneGap),
		[currentTime, pixelsPerSec, scenes, sceneGap]
	)

	const playheadPosition = useMemo(() => 
		timeToPixels(playheadCurrentTime, pixelsPerSec, scenes, sceneGap),
		[playheadCurrentTime, pixelsPerSec, scenes, sceneGap]
	)

	 useEffect(() => {
    if (isPlaying) {
      requestAnimationFrame(() => {
        scrollToEnsureVisible(sliderPosition);
      });
    }
  }, [isPlaying, sliderPosition, scrollToEnsureVisible]);


	// useEffect(() => {
	// 	const markerPos = timeToPixels(currentTime, pixelsPerSec, scenes, sceneGap);
	// 	setSliderPosition(markerPos);
		
	// 	if (isPlaying) {
	// 		requestAnimationFrame(() => {
	// 			scrollToEnsureVisible(markerPos);
	// 		});
	// 	}
	// }, [currentTime, pixelsPerSec, scenes, sceneGap, scrollToEnsureVisible]);

	// const handleSliderChange = (value) => {
	// 	setSliderPosition(value);
	// 	const time = pixelsToTime(value, pixelsPerSec, scenes, sceneGap);
	// 	handleSeek(time);
	// 	handleDeactivateActiveClip();
	// };

	const handleSliderChange = useCallback((value) => {
    const time = pixelsToTime(value, pixelsPerSec, scenes, sceneGap);
    handleSeek(time);
    // handleDeactivateActiveClip();

  }, [pixelsPerSec, scenes, sceneGap, handleSeek]);



	const handleContextMenu = (event) => {
		const rect = event.currentTarget.getBoundingClientRect();
		const clickX = event.clientX - rect.left;
		const clickTime = pixelsToTime(clickX, pixelsPerSec, scenes, sceneGap);
		handleSeek(clickTime);
	};


	const handleBeforeChange = useCallback(() => {
    setIsDraggingProgressMarker(true);
  }, [setIsDraggingProgressMarker]);

  const handleAfterChange = useCallback(() => {
    setIsDraggingProgressMarker(false);
  }, [setIsDraggingProgressMarker]);



	const sliderProps = {
		className: "editor-timeline-playheadZone-slider",
		trackClassName: "editor-timeline-playheadZone-slider-track",
		thumbClassName: "editor-timeline-playheadZone-slider-thumb",
		value: sliderPosition,
		onChange: handleSliderChange,
		onBeforeChange: handleBeforeChange,
  	onAfterChange: handleAfterChange,
		min: 0,
		max: pixelsPerSec * maxTimelineDurationSeconds
	};

	//const playheadPosition = timeToPixels(playheadCurrentTime, pixelsPerSec, scenes, sceneGap);

	const [contextMenuOpen, setContextMenuOpen] = useState(false);

	useEffect(() => {
		if (contextMenuOpen) {
			const handleScroll = () => {
				document.dispatchEvent(new KeyboardEvent("keydown", { key: "Escape" }));
				// hack to close the context menu
			};

			const scrollElement = scrollRef.current;
			if (scrollElement) {
				scrollElement.addEventListener('scroll', handleScroll, { passive: true });

				// Clean up function to remove the event listener
				return () => {
					scrollElement.removeEventListener('scroll', handleScroll);
				};
			}
		}
	}, [contextMenuOpen]); // Only re-run this effect when contextMenuOpen changes

	const afterLastScene = currentTime>timelineDurationSeconds


	//timelineDurationSeconds

	return (
		<>
		<ContextMenu.Root modal={false} onOpenChange={(open) => setContextMenuOpen(open)}>
			<ContextMenu.Trigger 
				asChild
			>
				<div
					style={{ width: `${pixelsPerSec * maxTimelineDurationSeconds}px`, zIndex: 50 }}
					className="editor-timeline-playheadZone"
					onContextMenu={handleContextMenu}
				>
					<ReactSlider {...sliderProps} />
				</div>
			</ContextMenu.Trigger>
			<ContextMenu.Portal>
				<ContextMenu.Content className="contextMenu contextMenu--timelineClip forceDarkTheme">
					{!afterLastScene && 
						<ContextMenu.Item onSelect={props.splitScene}>
							<div className='dropdownMenu-item-iconContainer'>
								<Icon name='splitClip' />
							</div>
							Split Scene
							<div className='contextMenu-item-keyboardShortcut'>
								⌘⇧C
							</div>
						</ContextMenu.Item>
					}			
					{afterLastScene && 
						<ContextMenu.Item onSelect={props.splitScene}>
							<div className='dropdownMenu-item-iconContainer'>
								<Icon name='plusMedium' />
							</div>
							New Scene							
						</ContextMenu.Item>
					}					
				</ContextMenu.Content>
			</ContextMenu.Portal>
		</ContextMenu.Root>

		
		<div 
			style={{left: `${playheadPosition}px`}}
			className='editor-timeline-playheadMarker'
			data-previewing-time={props.isResizingSkipSegment ? true : false}
		>
				<div className='editor-timeline-playheadMarker-markerContainer'>
					<div className='editor-timeline-playheadMarker-marker'>
						<svg width="11" height="16">
							<g fill="none" fillRule="evenodd">
								<path id="inner" d="M2 0h7a2 2 0 0 1 2 2v9.364a2 2 0 0 1-.904 1.673l-3.44 2.254a2 2 0 0 1-2.172.014l-3.56-2.27A2 2 0 0 1 0 11.347V2a2 2 0 0 1 2-2Z" />
								<path id="border" d="M9 0a2 2 0 0 1 2 2v9.364a2 2 0 0 1-.904 1.673l-3.44 2.254a2 2 0 0 1-2.172.014l-3.56-2.27A2 2 0 0 1 0 11.347V2a2 2 0 0 1 2-2h7Zm0 1H2a1 1 0 0 0-1 1v9.348a1 1 0 0 0 .462.843l3.56 2.27a1 1 0 0 0 1.086-.006l3.44-2.255a1 1 0 0 0 .452-.836V2a1 1 0 0 0-1-1Z" />
							</g>
						</svg>
					</div>
				</div>
				<div className='editor-timeline-playheadMarker-line'/>
			</div>
		</>
	);
};

export default EditorTimelineProgressMarker;