
export const calculateParagraphLayout = (wordsArray) => {
  const paragraphs = {};
  wordsArray.forEach(word => {
    const { paragraphIndex, rect: { normalTop } } = word;

    if (!paragraphs[paragraphIndex]) {
      paragraphs[paragraphIndex] = { minTop: normalTop, words: [] };
    }

    paragraphs[paragraphIndex].words.push(word);
    paragraphs[paragraphIndex].minTop = Math.max(paragraphs[paragraphIndex].minTop, normalTop);
  });

  return paragraphs;
};