import {post,put,postCloudinary,fetch} from '../api'
import {downloadMissingMusic} from '../utils/assets/downloadMissingMusic'
import { Mixpanel } from '../Mixpanel'



export const uploadMusicAndHandleResponse = (file, musicId) => async (dispatch) => {
  try {
    const signatureResponse = await post('/music/upload', { public_id: musicId });
    const { apiKey, timestamp, signature } = signatureResponse;
    let form = new FormData();
    form.append('file', file);
    form.append('api_key', apiKey);
    form.append('timestamp', timestamp);
    form.append('signature', signature);
    form.append('folder', 'music_uploads');
    form.append('public_id', musicId);
    form.append('media_metadata', true);
    form.append('eager', 'ac_mp3,br_320k,q_auto:best');

    const uploadResponse = await dispatch(uploadFileToCloudinary(form))

    const reqBody={
    	original_filename:uploadResponse.original_filename, 
    	public_id:uploadResponse.public_id, 
    	format:uploadResponse.format, 
    	asset_id:uploadResponse.asset_id,
    	version:uploadResponse.version,
    }
    const updateMusicObjResponse = await put(`/music/${musicId}`, reqBody)


    // console.log('updtae music obkect response here-----------')
    // console.log(updateMusicObjResponse)

    // Mixpanel.track('upload_image',{delivery_url:updateImageObjResponse.delivery_url})
    const withDownload = false
    const response = await dispatch(fetchUploadedMusic())

    await ipcRenderer.invoke('download-music-file',updateMusicObjResponse.id,updateMusicObjResponse.delivery_url) 

    return
    // await ipcRenderer.invoke('download-image-file',imageId,updateImageObjResponse.delivery_url); 
   	// return updateImageObjResponse
  } catch (error) {
    console.error('Error during image upload process:', error);
  }
};



export function uploadFileToCloudinary(form) {
	return (dispatch) => postCloudinary(form)
		.then((uploadResponse) => {
			return uploadResponse
		})
		.catch((error) => {
			console.log(error)
		})
}


export function fetchUploadedMusic(withDownload){

  return (dispatch) => fetch(`/music`)
  .then((response) => {
 
    dispatch({ type: 'FETCH_MUSIC_SUCCESS', response })
    if(withDownload!==false){
      downloadMissingMusic(response)
    }
    
    return response
  })
  .catch((error) => {
    console.log(error)
    return error
  })
}




