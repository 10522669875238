import React from 'react';
import Icon from '../../misc/Icon';

const DPToggleGrid = ({ items, activeItem, onItemClick, columns: propColumns, rows: propRows, disabled, fixedWidth }) => {
  const itemCount = items.length;
  let columns, rows;

  if (propColumns && propRows) {
    columns = propColumns;
    rows = propRows;
  } else {
    if (itemCount <= 3) {
      columns = itemCount;
      rows = 1;
    } else if (itemCount === 4) {
      columns = 2;
      rows = 2;
    } else if (itemCount === 5 || itemCount === 6) {
      columns = 3;
      rows = 2;
    } else if (itemCount >= 7 && itemCount <= 9) {
      columns = 3;
      rows = 3;
    } else if (itemCount >= 10 && itemCount <= 12) {
      columns = 4;
      rows = 4;
    } else {
      console.warn('DPToggleGrid: Unsupported number of items. Please use 2-9 items.');
      return null;
    }
  }

  // Determine the data-item-type
  const allHaveIcons = items.every(item => item.iconName);
  const allHaveLabels = items.every(item => item.label);
  let dataItemType = 'mixedLayout';

  if (allHaveIcons && !allHaveLabels) {
    dataItemType = 'iconOnly';
  } else if (!allHaveIcons && allHaveLabels) {
    dataItemType = 'labelOnly';
  }

  const containerStyle = fixedWidth ? { width: `${fixedWidth}px` } : {};

  return (
    <div 
      data-disabled={disabled} 
      data-item-type={dataItemType}
      style={containerStyle}
      className={`dpToggleGrid-container dpToggleGrid-container--${columns}wide dpToggleGrid-container--${rows}tall`}
    >
      {items.map((item) => {
        let layoutClassName = '';
        if (item.iconName && item.label) {
          layoutClassName = 'dpToggleGrid-item--mixedLayout';
        } else if (item.iconName && !item.label) {
          layoutClassName = 'dpToggleGrid-item--iconOnly';
        } else if (!item.iconName && item.label) {
          layoutClassName = 'dpToggleGrid-item--labelOnly';
        }

        return (
          <button
            key={item.value}
            data-active-state={activeItem === item.value}
            className={`dpToggleGrid-item ${layoutClassName}`}
            onClick={() => onItemClick(item.value)}
          >
            {item.iconName && (
              <div className='dpToggleGrid-item-iconContainer'>
                <Icon name={item.iconName} />
              </div>
            )}
            {item.label && (
              <div className='dpToggleGrid-item-label'>
                {item.label}
              </div>
            )}
          </button>
        );
      })}
    </div>
  );
};

export default DPToggleGrid;