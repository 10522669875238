import React, { useEffect,useState } from 'react';
import { connect,useDispatch } from 'react-redux';
import {fetch} from '../api'
import ServerSideExportCanvasContainer from './ServerSideExportCanvasContainer'
import {getVariableTextColorForProject} from './utils/getVariableTextColorForProject'
import axios from 'axios'
import {fetchLogoForDomain} from '../actions/fetchLogoForDomain'

const DEV_API_ENDPOINT='https://y-dev-api.onrender.com'
const PROD_API_ENDPOINT='https://y-prod-api.onrender.com'


const ServerSideExportPage = ({
		projectId,
		renderId,
		foxStartTime,
		foxMaxRenderSeconds,
		segmentId,
		videoRenderUploadUrl,
		renderVariables,
		apiTarget,
		exportWidth,
		s3PresignedUploadUrl
	}) => {


	const [projectData, setProjectData] = useState(null);
	const [variableTextColor, setVariableTextColor] = useState(null);
	const dispatch = useDispatch();

	useEffect(() => {
		const fetchProject = async () => {
			try {
				let endpoint = DEV_API_ENDPOINT 
				if(apiTarget=='prod'){
					endpoint=PROD_API_ENDPOINT
				}
				 const response = await axios.get(`${endpoint}/unauth/projects/${projectId}/with-org`);
       	const { project, organization } = response.data;
				//dispatch action with org object so its in state for org brands 
				dispatch({ type: 'FETCH_ORG_SUCCESS', response:organization })
				const variableTextColor = getVariableTextColorForProject(project)
				setVariableTextColor(variableTextColor)
				setProjectData({
					...project,
					renderId,
					foxStartTime,
					foxMaxRenderSeconds,
					segmentId: segmentId,
					videoRenderUploadUrl: videoRenderUploadUrl,
					s3PresignedUploadUrl:s3PresignedUploadUrl
				})
			} catch (error) {
				console.error('Error fetching project:', error);
			}
		};
		fetchProject();
	}, [projectId]);


	//hacky stuff for gifs
	let framesPerSecond=60 
	if(projectData && (projectData.name=='Clay Template GIF' || projectData.name=='Clay Template GIF (Voice Match)' || projectData.name=='Gif Test')){
		framesPerSecond=10
	}

	const updatedRenderVariables = {
		...renderVariables,
		variableTextColor: variableTextColor,
	};

	return (
		<div>
			{projectData &&
				<ServerSideExportCanvasContainer 
					framesPerSecond={framesPerSecond}
					renderVariables={updatedRenderVariables}
					project={projectData}
					exportWidth={exportWidth}
				/>
			}
			export for project {projectId}, renderId: {renderId}, foxStartTime: {foxStartTime}, foxMaxRenderSeconds: {foxMaxRenderSeconds}, segmentId: {segmentId}, videoRenderUploadUrl: {videoRenderUploadUrl}
			<br/>
		</div>
	);
}


function mapStateToProps(state, ownProps) {
	const projectId = ownProps.match.params.id;
	const urlSearch = new URLSearchParams(ownProps.location.search);
	const renderId = urlSearch.get("renderId")
	const foxStartTime = parseFloat(urlSearch.get("foxStartTime")) || 0;
	const foxMaxRenderSeconds = parseFloat(urlSearch.get("foxMaxRenderSeconds")) || null;
	const segmentId = urlSearch.get("segmentId")
	const videoRenderUploadUrl = urlSearch.get("videoRenderUploadUrl")
	const options= urlSearch.get("options") //base64
	const apiTarget = urlSearch.get("apiTarget")
	const exportWidth = urlSearch.get("exportWidth")

	const fullSearch = ownProps.location.search;


	const encodedS3Url= urlSearch.get("s3PresignedUploadUrl") //base64
	const s3PresignedUploadUrl = encodedS3Url ? decodeURIComponent(escape(atob(encodedS3Url))) : null;

	console.log('s3PresignedUploadUrl',s3PresignedUploadUrl)
  
	let renderVariables = {};
	if (options) {
		try {
			// Define a safe atob function to handle non-ASCII characters
			const safeatob = (str) => decodeURIComponent(escape(atob(str)));
			// Use the safe atob function to decode the options
			const decodedOptions = safeatob(options);
			renderVariables = JSON.parse(decodedOptions);
		} catch (error) {
			console.error('Error decoding or parsing options:', error);
		}
	}

	return {
		projectId: projectId,
		renderId: renderId,
		foxStartTime: foxStartTime,
		foxMaxRenderSeconds: foxMaxRenderSeconds,
		segmentId: segmentId,
		videoRenderUploadUrl: videoRenderUploadUrl,
		renderVariables:renderVariables,
		apiTarget:apiTarget,
		exportWidth:exportWidth,
		s3PresignedUploadUrl:s3PresignedUploadUrl
	};
}

export default connect(mapStateToProps)(ServerSideExportPage);
