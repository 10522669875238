// VideoPlayerSubtitles.jsx
import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { parseSRT } from './srtParser';

function VideoPlayerSubtitles({ playbackId, progressSeconds, showingControls }) {
  const [subtitles, setSubtitles] = useState([]);
  const [currentSubtitle, setCurrentSubtitle] = useState(null);


  useEffect(() => {
    fetch(`${playbackId}.srt`)
      .then(response => response.text())
      .then(data => {
        const parsedSubtitles = parseSRT(data);
        setSubtitles(parsedSubtitles);
        console.log('Parsed subtitles:', parsedSubtitles); // For debugging
      })
      .catch(error => console.error('Error loading subtitles:', error));
  }, []);

  useEffect(() => {
    const current = subtitles.find(subtitle => 
      progressSeconds >= subtitle.start && progressSeconds <= subtitle.end
    );
    setCurrentSubtitle(current);
  }, [progressSeconds, subtitles]);

  const springProps = useSpring({
    transform: `translateY(${showingControls ? -40 : -4}px)`,
    config: { tension: 600, friction: 32 }
  });

  return (
    <div className='videoPlayer-subtitlesContainer'>
      {currentSubtitle && (
        <animated.div 
          style={springProps} 
          className='videoPlayer-subtitleBox'
        >
          <div className='videoPlayer-subtitleBox-line'>{currentSubtitle.line1}</div>
          {currentSubtitle.line2 && <div className='videoPlayer-subtitleBox-line'>{currentSubtitle.line2}</div>}
        </animated.div>
      )}
    </div>  
  );
}

export default VideoPlayerSubtitles;