import ReactDOM from 'react-dom'
import React from 'react'
import {randomID} from '../../utils/randomID'
import SubtitlesGroupGhost from '../../three/subtitles/SubtitlesGroupGhost';

const SCENE_WIDTH = 1920
const SCENE_HEIGHT = 1080


function calculateSubtitleGroupWordPositions(subtitleGroup) {
  return new Promise((resolve) => {
    const ghostContainer = document.createElement('div');
    ghostContainer.className = 'editor-subtitles-ghostContainer';
    ghostContainer.style.width = `${SCENE_WIDTH}px`;
    ghostContainer.style.height = `${SCENE_HEIGHT}px`;
    ghostContainer.style.position = 'absolute';
    ghostContainer.style.visibility = 'hidden';
    document.body.appendChild(ghostContainer);


    ReactDOM.render(
      <SubtitlesGroupGhost
        subtitleGroup={subtitleGroup}
        visible={false}
      />,
      ghostContainer,
      () => {
        setTimeout(() => {
          const groupGhostElement = document.getElementById(`ghost_${subtitleGroup.id}`);
          const ghostParentContainer = document.getElementById(`ghost_container_${subtitleGroup.id}`);

          if (groupGhostElement && ghostParentContainer) {
            const groupRect = groupGhostElement.getBoundingClientRect();
            const parentRect = ghostParentContainer.getBoundingClientRect();

            const parentCenterX = parentRect.width / 2;
            const parentCenterY = parentRect.height / 2;

            // Calculate the position relative to the parent's top-left corner
            const relativeLeft = groupRect.left - parentRect.left;
            const relativeTop = groupRect.top - parentRect.top;

            // Convert to Three.js coordinate system (origin at center, Y-axis inverted)
            // Adjust for the group's own dimensions to position from its center
            const normalLeft = (relativeLeft + (groupRect.width / 2)) - parentCenterX;
            const normalTop = parentCenterY - (relativeTop + (groupRect.height / 2));  // Invert Y-axis

    

            let lines = subtitleGroup.lines;
        

            lines.forEach((line)=>{

              line.words.forEach((word)=>{
                const wordElement = document.getElementById(`subtitle_word_ghost_${word.id}`)
                if(wordElement){
                  const wordRect = wordElement.getBoundingClientRect()
                  const relativeWordLeft = wordRect.left - parentRect.left;
                  const relativeWordTop = wordRect.top - parentRect.top;
                  // Convert to Three.js coordinate system (origin at parent center, Y-axis inverted)
                  const normalWordLeft = (relativeWordLeft + (wordRect.width / 2)  - parentCenterX);
                  const normalWordTop = parentCenterY - (relativeWordTop + (wordRect.height / 2));
                  word.width = wordRect.width;
                  word.height = wordRect.height;
                  word.normalLeft = normalWordLeft;  // Relative to group center
                  word.normalTop = normalWordTop;     // Relative to group center
              }
              })
            })           

            // Clean up
            ReactDOM.unmountComponentAtNode(ghostContainer);
            document.body.removeChild(ghostContainer);

            resolve({
              ...subtitleGroup,
              originalStartTime:subtitleGroup.startTime,
              originalEndTime:subtitleGroup.endTime,
              lines:lines,
              width: groupRect.width,
              height: groupRect.height,
              normalLeft: normalLeft,
              normalTop: normalTop
            });
          } else {
            console.error('Ghost element not found');
            resolve(subtitleGroup);
          }
        }, 500); // Short timeout to ensure rendering is complete
      }
    );
  });
}

export { calculateSubtitleGroupWordPositions };