


//direction (left or right) tells us if we adjust the start time or the end time


    // const skipSegment = {
    //     id: randomID(),
    //     originalStart: skipTime,
    //     originalEnd: skipTime + DEFAULT_SKIP_DURATION,
    //     playbackRate: null,
    //     originalDuration: skipDuration,
    //     isSkipSegment:true,
    //     isExpanded:true,
    //     timeStretch:null
    // };



export function updateSkipSegmentDuration(originalSegments, segmentId,newDuration,totalDuration,direction) {

  let specialSegments =[] //quiet or skip segments 

  originalSegments.forEach((segment)=>{
    if((segment.isQuiet || segment.isSkipSegment) && segment.id!=segmentId){
      specialSegments.push(segment)
    }
    if(segment.id==segmentId){
      let updatedSegment = {...segment}
      updatedSegment.originalDuration = newDuration 
      if(direction =='left'){
        updatedSegment.originalStart=segment.originalEnd - newDuration
      } else{
        updatedSegment.originalEnd=segment.originalStart + newDuration
      }
      specialSegments.push(updatedSegment)
    }
  })

  let segments = [];
  let lastEnd = 0;

  specialSegments.forEach(period => {
      if (lastEnd < period.originalStart) {
        // Add active segment before special segment
        segments.push({
          id: segments.length,
          originalDuration: period.originalStart - lastEnd,
          originalStart: lastEnd,
          originalEnd: period.originalStart,
          isQuiet: false,
          playbackRate:1,
          timeStretch:1
        });
      }
      segments.push(period)
      lastEnd = period.originalEnd;
    });

    if (lastEnd < totalDuration) {
      // Add remaining active segment
      segments.push({
        id: segments.length,
        originalStart:lastEnd,
        originalDuration: totalDuration - lastEnd,
        originalEnd: totalDuration,
        isQuiet: false,
        playbackRate:1,
        timeStretch:1
      });
    }

  return segments
}
