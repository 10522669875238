import React, { useState,useEffect } from 'react';
import Icon from '../../../misc/Icon';
import DPToggleGrid from '../DPToggleGrid';
import EditorDetailPanelChartBar from './EditorDetailPanelChartBar'
import EditorDetailPanelChartDonut from './EditorDetailPanelChartDonut'

const chartTypes = [    
  { value: 'donut', label: 'Donut', iconName: 'chartDonut' },        
  { value: 'bar', label: 'Bar', iconName: 'chartBar' },
];

const EditorDetailPanelChart = (props) => {
  const {updateSlideElementMetadata,clipId,element,handleDimensionsUpdatedFromDetailPanel} = props
  
    

  const handleElementUpdateMetadata= (field,value) => {
    updateSlideElementMetadata(clipId,element.id,{...element.metadata,[field]:value})
  }  

  // const [chartType, setChartType] = useState('bar');  
  const chartType = element.metadata.activeChartType

  return (
    <>
      <div className='editor-detailPanel-header'>
        <div className='editor-detailPanel-header-label'>
          Chart
        </div>
        <div className='editor-detailPanel-header-vSpacer' />        
      </div>
      <div className='editor-detailPanel-header-alignVSpacer' />
        
      <DPToggleGrid
        items={chartTypes}
        activeItem={chartType}
        onItemClick={(value)=>handleElementUpdateMetadata('activeChartType',value)}          
      />


      {chartType === 'donut' && 
        <EditorDetailPanelChartDonut
          element={element}
          updateSlideElementMetadata={updateSlideElementMetadata}
          clipId={clipId}
          handleDimensionsUpdatedFromDetailPanel={handleDimensionsUpdatedFromDetailPanel}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}          
        />
      }
      
      
      {chartType === 'bar' && 
        <EditorDetailPanelChartBar 
          element={element}
          handleUpdateMetadata={updateSlideElementMetadata}
          updateSlideElementMetadata={updateSlideElementMetadata}
          clipId={clipId}
          handleDimensionsUpdatedFromDetailPanel={handleDimensionsUpdatedFromDetailPanel}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          projectBackgroundId={props.projectBackgroundId}
          slideBackgroundId={props.slideBackgroundId}
        />
      }
      
      {/*}
       <div className='editor-detailPanel-row'>


        <div className='editor-detailPanel-row-label'>
          Bar Spacing
        </div>
        <div className='editor-detailPanel-row-hSpacer' />   

        <DPNumberInput 
          min={0}
          max={100}
          value={element.metadata.barSpacing}
          setValue={(value)=>handleElementUpdateMetadata('barSpacing',value)}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          iconName='cornerRadius'              
        />
       </div>

     

       <div className='editor-detailPanel-divider' />
       <div className='editor-detailPanel-row'>
          <div className='editor-detailPanel-row-label'>
            Chart Data
          </div>
        </div>      
        <EditorDetailPanelChartSpreadsheet             
          data={barInputRawData}
          updateData={setBarInputRawData}  
          barRowHighlightStates={barRowHighlightStates}  
          setBarRowHighlightStates={setBarRowHighlightStates}    
        />

      <div className='editor-detailPanel-divider' />
      <DPItemAnimationPanel 
        handleUpdateAnimationType={handleUpdateChartAnimationType}
        activeStartTransition={props.element.metadata.barChartData.animationType}
        itemType='chart'
      />
      */}
       

    </>
  );
};

export default EditorDetailPanelChart;




  // x
  // y

  // normal number inputs

  // independent

  // width
    // min 500
    // default 1400
    // max 1800
  // height
    // min 400
    // max 100

  // number input
  // bar spacing
      // default 40
      // 0 100 (no units)

  // animationPanel
    // Appear / None
    // Something - animate in?


  // together
  //

  // spreadsheet
  // getChartLayout
    // height
    // barItemWidth


{/*
      <div className='editor-detailPanel-divider' />

        <div className='editor-detailPanel-row'>
          <DPNumberInput 
            min={0}
            max={1920}          
            value={element.x}
            setValue={(value)=>{handleChangePositon('x',value)}}
            setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
            label='X'          
            width={79}
          />
          <div className='editor-detailPanel-row-smallHSpacer' />
          <DPNumberInput
            min={0}
            max={1920}               
            value={element.y}
            setValue={(value)=>{handleChangePositon('y',value)}}
            setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
            label='Y'
            width={79}
          />
          </div>
      <div className='editor-detailPanel-row'>
        <DPNumberInput 
          min={500}
          max={1800}          
          value={element.width}
          setValue={handleWidthChange}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          label='W'          
          width={79}
        />
        <div className='editor-detailPanel-row-smallHSpacer' />
        <DPNumberInput
          min={400}
          max={1000}               
          value={element.height}
          setValue={handleHeightChange} 
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          label='H'
          width={79}
        />
      </div>

      const handleWidthChange = (newWidth) => {
    element.width = newWidth
    handleDimensionsUpdatedFromDetailPanel()
  };

  const handleHeightChange = (newHeight) => {
    element.height = newHeight
    handleDimensionsUpdatedFromDetailPanel()
  };

  const handleChangePositon = (axis,newValue) => {
    if(axis=='x'){
      element.x = newValue       
    }
    if(axis=='y'){
      element.y = newValue       
    }
    handleDimensionsUpdatedFromDetailPanel()
  };

      */}