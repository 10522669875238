import React, { useEffect, useState } from 'react';
import { Text } from '@react-three/drei';
import { SpringAnimator } from '../../../utils/animations/SpringAnimator';
import renderOrders from '../../../renderOrders'

const FPS = 60; // Frames per second
const calculateAnimationDuration = 10;

const SlideDonutChartPostLabel = ({ maxOpacity, fontWeight, targetYPos, fontSize, time, postLabel, animationType, isHidden, fontFamily, color }) => {
  const initialYPos = targetYPos + 20; // Starting Y position for the animation
  const springParams = { mass: 1, stiffness: 180, damping: 50 };
  const [labelOpacityFrames, setLabelOpacityFrames] = useState([]);
  const [yPosFrames, setYPosFrames] = useState([]);

  let delay = 0.7

  if(animationType === 'fadeIn'){
    delay = 0
  }

  if(animationType === 'scaleUp'){
    delay = 1.0
  }


  const adjustedTime = time - delay

  useEffect(() => {
    const labelOpacitySpring = new SpringAnimator(springParams.mass, springParams.stiffness, springParams.damping, 0);
    const yPosSpring = new SpringAnimator(springParams.mass, springParams.stiffness, springParams.damping, initialYPos);

    const opacityFrames = [];
    const yPosFrames = [];
    for (let frame = 0; frame < FPS * calculateAnimationDuration; frame++) {
      labelOpacitySpring.setTarget(1); // Target opacity
      labelOpacitySpring.simulate(1000 / FPS);
      opacityFrames.push(labelOpacitySpring.position);

      yPosSpring.setTarget(targetYPos); // Target Y position
      yPosSpring.simulate(1000 / FPS);
      yPosFrames.push(yPosSpring.position);
    }

    setLabelOpacityFrames(opacityFrames);
    setYPosFrames(yPosFrames);
  }, [targetYPos]);

  const frameIndex = Math.floor(adjustedTime * FPS);
  const animatedLabelOpacity = labelOpacityFrames[frameIndex] || 0;
  let animatedYPos = yPosFrames[frameIndex] || initialYPos;
  let labelOpacity = animatedLabelOpacity * maxOpacity;

  if(animationType === 'none'){
    animatedYPos = targetYPos
    labelOpacity = maxOpacity
  }

  if(animationType === 'fadeIn' || animationType === 'scaleUp'){
    animatedYPos = targetYPos
  }

  if(isHidden){
    labelOpacity = 0
  }

  return (
    <>    
      <Text
        anchorX="center"
        anchorY="top"
        lineHeight={1.1}
        fontSize={fontSize}
        textAlign="center"
        font={`../fonts/${fontFamily}/${fontFamily}-${fontWeight}.woff`}
        position={[1, animatedYPos, 0]} // Apply animated Y position
        maxWidth={600}
        letterSpacing={-0.01}
        renderOrder={renderOrders.graphObject}  
      >
        <meshBasicMaterial transparent={true} color={color} opacity={labelOpacity} />
        {postLabel}
      </Text>
    </>
  );
};

export default SlideDonutChartPostLabel;
