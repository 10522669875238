import React, { useState, useRef } from 'react';

const formatDuration = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  
  if (hours > 0) {
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const MAX_FPS = 1; // Maximum of 1 frame per second
const FRAME_COUNT = 50; // This will now be the maximum possible frames

const AddFromLibraryDialogGridVideo = ({ mediaItem, footerHeight, innerPadding, frameWidth, frameHeight }) => {
  const duration = mediaItem.duration;
  const [currentFrame, setCurrentFrame] = useState(0);
  const [progressPosition, setProgressPosition] = useState(0);
  const containerRef = useRef(null);
  
  // Calculate the maximum safe frame time to avoid Cloudinary's limitation
  const maxSafeTime = Math.floor(duration) - 0.01;
  
  const actualFrameCount = Math.min(Math.ceil(duration * MAX_FPS), FRAME_COUNT);
  
  // Generate frames but filter out ones that would exceed the safe time limit
  const frames = Array.from({ length: actualFrameCount }, (_, index) => {
    const time = (index / (actualFrameCount - 1) * duration).toFixed(2);
    return {
      time,
      percent: ((index / (actualFrameCount - 1)) * 100).toFixed(1),
      isValid: parseFloat(time) <= maxSafeTime
    };
  }).filter(frame => frame.isValid);

  // Update the actual frame count after filtering
  const validFrameCount = frames.length;

  const handleMouseMove = (e) => {
    if (!containerRef.current) return;
    
    const rect = containerRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const percentage = Math.max(0, Math.min(1, x / rect.width));
    
    // Update progress line position
    setProgressPosition(percentage * 100);
    
    const frameIndex = Math.min(
      Math.floor(percentage * validFrameCount),
      validFrameCount - 1
    );
    
    const translateY = -frameIndex * frameHeight;
    
    const framesContainer = containerRef.current.querySelector(
      '.addFromLibraryDialog-grid-item-preview-video-frameStack'
    );
    if (framesContainer) {
      framesContainer.style.transform = `translateY(${translateY}px)`;
    }
    
    setCurrentFrame(frameIndex);
  };

  const handleMouseLeave = () => {
    const framesContainer = containerRef.current?.querySelector(
      '.addFromLibraryDialog-grid-item-preview-video-frameStack'
    );
    if (framesContainer) {
      framesContainer.style.transform = `translateY(0)`;
    }
    setCurrentFrame(0);
    setProgressPosition(0);
  };

  return (
    <div 
      className='addFromLibraryDialog-grid-item-preview-video'
      ref={containerRef} 
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <div className='addFromLibraryDialog-grid-item-preview-video-duration'>
        {formatDuration(duration)}
      </div>
      <div 
        style={{ left: `${progressPosition}%` }} 
        className='addFromLibraryDialog-grid-item-preview-video-progressLine' 
      />
      <div className='addFromLibraryDialog-grid-item-preview-video-innerContainer'>
        <div 
          className="addFromLibraryDialog-grid-item-preview-video-frameStack"
          style={{ height: `${frameHeight * validFrameCount}px`, width: `${frameWidth}px` }}
        >
          {frames.map((frame, index) => (
            <div
              key={index}
              className="addFromLibraryDialog-grid-item-preview-video-frameStack-frame"
              style={{ height: `${frameHeight}px`, width: `${frameWidth}px` }}
            >
              <img 
                className='addFromLibraryDialog-grid-item-preview-video-frameStack-frame-img' 
                src={`https://res.cloudinary.com/yarn/video/upload/so_${frame.time},h_460/${mediaItem.cloudinaryId}.webp`} 
              />
            </div>
          ))}
        </div>      
      </div>
    </div>
  );
};

export default AddFromLibraryDialogGridVideo;