export function isFirstWordInChunk(doc, currentPos, parentPos) {
	let chunk = null;
	let chunkPos = null;
	doc.nodesBetween(parentPos, parentPos + doc.nodeAt(parentPos).nodeSize, (node, pos) => {
		if (node.type.name === 'webcamRecordingChunk' && 
			currentPos > pos && 
			currentPos < (pos + node.nodeSize)) {
			chunk = node;
			chunkPos = pos;
			return false; // Stop traversing once we find our chunk
		}
		return true;
	});

	if (!chunk || !chunkPos) return { isFirst: false };
	// Get the first word in this chunk
	let firstWordPos = null;
	let firstWordSize = null;
	chunk.forEach((node, offset) => {
		if (!firstWordPos && node.type.name === 'webcamTranscriptWord') {
			firstWordPos = chunkPos + offset + 1;
			firstWordSize = node.nodeSize;
		}
	});
	if (!firstWordPos || !firstWordSize) return { isFirst: false };
	// Check if our cursor is in the first word's range
	return {
		isFirst: currentPos >= firstWordPos && currentPos <= (firstWordPos + firstWordSize),
		chunkPos: chunkPos + 1 // +1 to move past chunk start
	};
}