import React, { useState } from 'react'
import ReactSlider from 'react-slider'
import Icon from '../../misc/Icon';

function VideoPlayerControlsVolumeSlider({ volume, muted, onVolumeChange, onToggleMute }) {
  const [isDragging, setIsDragging] = useState(false);

  const handleVolumeChange = (newVolume) => {
    onVolumeChange(newVolume);
  };

  const onBeforeChange = () => {
    setIsDragging(true);
  };

  const onAfterChange = () => {
    setIsDragging(false);
  };

  let volumeIcon;
  if (muted || volume === 0) {
    volumeIcon = 'speaker-muted';
  } else if (volume > 0 && volume <= 0.33) {
    volumeIcon = 'speaker-wave-0';
  } else if (volume > 0.33 && volume <= 0.66) {
    volumeIcon = 'speaker-wave-1';
  } else if (volume > 0.66) {
    volumeIcon = 'speaker-wave-2';
  }

  const adjustedVolume = muted ? 0 : volume

  return (
    <div data-is-dragging={isDragging ? true : false} className='videoPlayer-controls-volumeSlider'>
      <button onClick={onToggleMute} className='videoPlayer-controls-volumeSlider-muteButton'>
        <div className='videoPlayer-controls-volumeSlider-muteButton-iconContainer'>
          <Icon name={volumeIcon} />
        </div>
      </button>      
      <div className='videoPlayer-controls-volumeSlider-sliderContainer'>
        <ReactSlider
          className="videoPlayer-controls-volumeSlider-slider"
          thumbClassName="videoPlayer-controls-volumeSlider-slider-thumb"
          trackClassName="videoPlayer-controls-volumeSlider-slider-track"
          value={muted ? 0 : volume}
          onChange={handleVolumeChange}
          onBeforeChange={onBeforeChange}
          onAfterChange={onAfterChange}
          min={0}
          max={1}
          step={0.01}
          renderThumb={(props, state) => <div {...props}></div>}
        />        
        <div className='videoPlayer-controls-volumeSlider-renderSliderContainer'>
          <div className='videoPlayer-controls-volumeSlider-renderSlider'>
            <div style={{width: `${adjustedVolume * 100}%`}} className='videoPlayer-controls-volumeSlider-renderSlider-activeTrack' />            
            <div className='videoPlayer-controls-volumeSlider-renderSlider-bgTrack' />
          </div>
          <div style={{left: `${adjustedVolume * 100}%`}}  className='videoPlayer-controls-volumeSlider-renderSliderThumb' />
        </div>
      </div>
      <button onClick={() => handleVolumeChange(1)} className='videoPlayer-controls-volumeSlider-maxBtn' />
    </div>
  );
}

export default VideoPlayerControlsVolumeSlider;