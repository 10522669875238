import {updateProjectName} from '../../actions/projects'
import store from '../../store'
import find from 'lodash/find'
 
export async function saveProjectName(projectId, newProjectName) {
  const state = store.getState()
  const project = find(state.projects,{id:projectId})
  if(project && project.name!=newProjectName){
    let name = newProjectName || 'Untitled'
    store.dispatch(updateProjectName(projectId,name))
  }
}