import React, { useRef, useEffect, useState, useMemo, useContext } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import createRoundedRectShape from '../utils/createRoundedRectShape';
import ScreenVideoMaterial from './ScreenVideoMaterial'
import ScreenVideoCroppedMaterial from './ScreenVideoCroppedMaterial'
import ScreenVideoCursor from './ScreenVideoCursor'
import { createDropShadow } from './ScreenVideoDropShadow'; // Import the drop shadow function
import defaultVideoAnimationValues from '../utils/animations/defaultVideoAnimationValues'
import defaultScreenDeviceFrameAnimationValues from '../utils/animations/defaultScreenDeviceFrameAnimationValues'
import {getOrgScreenVideoStyle} from '../../utils/brands/getOrgScreenVideoStyle'
import { meshBasicMaterial } from '@react-three/drei';
import { hsl } from 'd3-color';
import ScreenVideoClayTitleOverlay from './ScreenVideoClayTitleOverlay'
import ScreenVideoClayAvatarOverlay from './ScreenVideoClayAvatarOverlay'
import renderOrders from '../renderOrders'

const SCENE_WIDTH = 1920;
const SCENE_HEIGHT = 1080;
const FPS = 60; // Frames per second


const rgbToHsl = (r, g, b) => {
  r /= 255, g /= 255, b /= 255;
  var max = Math.max(r, g, b), min = Math.min(r, g, b);
  var h, s, l = (max + min) / 2;
  if (max === min) {
    h = s = 0; // achromatic
  } else {
    var d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break; // Fixed line
    }
    h /= 6;
  }
  return [h, s, l];
};


// Function to get mesh color based on screenVideoBackground
const getMeshColor = (screenVideoBackground) => {

  if(screenVideoBackground.colorFrameBG){
    return screenVideoBackground.colorFrameBG
  }else{
    if (screenVideoBackground.type === 'solid') {
      const [r, g, b] = screenVideoBackground.rgba.match(/\d+/g).map(Number);
      const [h, s, l] = rgbToHsl(r, g, b);
      // Adjust lightness and saturation
       const adjustedL = Math.min(l * 1.7, 0.80);
      //const adjustedS = s * 1.5;
      const adjustedS = s * 0.8;
      const lighterColor = `hsl(${h * 360}, ${adjustedS * 100}%, ${adjustedL * 100}%)`;
      return lighterColor;
     }
     // Return a default color if the type is not 'solid'
      return '#ffffff';
    }
  };

const ScreenVideoDeviceFrame = ({frameWidth,variableValues, frameHeight, meshRadius, meshScale, clip, time, localTime, visible, initialProperties, animationStates, animationParams, startTransitionType, endTransitionType, showScreenVideoStatic, screenVideoDevice, screenVideoDeviceFrame, screenVideoBackground, frameOffset, chromeFrameMeshScale}) => {
  const screenVideoStyle=getOrgScreenVideoStyle()  
  const deviceFrameRef = useRef();  
  const deviceShadowRef = useRef();

  //
  // DEVICE GEOMETRY  
  const deviceGeometry = useMemo(() => {
    const shape = createRoundedRectShape(frameWidth, frameHeight, meshRadius);
    const geometry = new THREE.ShapeGeometry(shape);        
    // Compute UV mapping for the geometry using the provided logic that works
    const uvs = new Float32Array(geometry.attributes.position.count * 2);
    for (let i = 0; i < geometry.attributes.position.count; i++) {
      const vertex = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, i);
      // Map x, y vertex position to uv coordinates
      uvs[i * 2] = (vertex.x + frameWidth / 2) / frameWidth;
      uvs[i * 2 + 1] = (vertex.y + frameHeight / 2) / frameHeight;
    }
    geometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));
    return geometry;
  }, [frameWidth, frameHeight, meshRadius]);


  //
  // SHADOW SETUP
  let shadowXOffset = 0;
  let shadowYOffset = -6;
  let shadowBlur = 18;
  let shadowSpread = -16;
  // const shadowOpacity = 0.68;
  let shadowOpacity = 0.72;

  if(screenVideoStyle && screenVideoStyle.shadowOpacity && !screenVideoDeviceFrame){
    shadowOpacity = screenVideoStyle.shadowOpacity
  }

  const deviceFrameShadowMesh = useMemo(() => {
    const shadowOptions = {
      xOffset: shadowXOffset, 
      yOffset: shadowYOffset, 
      blur: shadowBlur,
      spread: shadowSpread,
      shadowColor: 'rgba(0,0,0,1)',
      opacity: shadowOpacity, // Opacity from Leva
    };
    return createDropShadow(frameWidth, frameHeight, shadowOptions);
  }, [frameWidth, frameHeight, shadowXOffset, shadowYOffset, shadowBlur, shadowSpread, shadowOpacity]);


  const deviceFrameMaterialRef = useRef();

  let colorFrameMaxOpacity = 1
  if(screenVideoBackground && screenVideoBackground.colorFrameMaxOpacity){
    colorFrameMaxOpacity = screenVideoBackground.colorFrameMaxOpacity
  }
  
  //
  // APPLY ANIMATIONS
    useEffect(() => {
      
      const frameIndex = Math.floor(localTime * FPS);
      if (deviceFrameRef.current) {      
       // console.log(animationStates)
        Object.keys(animationStates).forEach(property => {
          let state = animationStates[property][frameIndex]; 
         // console.log(state)
          if(showScreenVideoStatic){
            state=defaultScreenDeviceFrameAnimationValues[property]
          }       
          if (state !== undefined) {
            if (property === 'positionX') {
              deviceFrameRef.current.position.x = state;
            } else if (property === 'positionY') {
              deviceFrameRef.current.position.y = state;
            } else if (property === 'positionZ') {              
              deviceFrameRef.current.position.z = defaultScreenDeviceFrameAnimationValues['positionZ']; 
            }
            else if (property === 'scale' && deviceFrameRef.current.scale) {
              deviceFrameRef.current.scale.set(state, state, state);
            } else if (property.startsWith('rotation')) {
              const axis = property.slice('rotation'.length).toLowerCase();
              deviceFrameRef.current.rotation[axis] = state;
            } else if (property === 'opacity' && deviceFrameMaterialRef.current) {
              deviceFrameMaterialRef.current.opacity = state * colorFrameMaxOpacity;
            }
          }
        });
      }    
    }, [localTime,startTransitionType,endTransitionType,animationStates, showScreenVideoStatic]);

    useEffect(() => {
      
      const frameIndex = Math.floor(localTime * FPS);

      if (deviceShadowRef.current) {
        // Initialize default values
        let animatedOpacity = 0, animatedScale = 1, animatedPosX = 0, animatedPosY = 0, animatedPosZ = 0;

        // Check if the animation states for specific properties exist and assign values
        if (animationStates['opacity'] && animationStates['opacity'][frameIndex] !== undefined) {
          animatedOpacity = animationStates['opacity'][frameIndex];
        }
        if (animationStates['scale'] && animationStates['scale'][frameIndex] !== undefined) {
          animatedScale = animationStates['scale'][frameIndex];
        }
        if (animationStates['positionX'] && animationStates['positionX'][frameIndex] !== undefined) {
          animatedPosX = animationStates['positionX'][frameIndex];
        }
        if (animationStates['positionY'] && animationStates['positionY'][frameIndex] !== undefined) {
          animatedPosY = animationStates['positionY'][frameIndex];
        }
        if (animationStates['positionZ'] && animationStates['positionZ'][frameIndex] !== undefined) {
          animatedPosZ = 0
        }
        

        // Apply animated values to the shadow mesh
        const scaleWithShadowSpread = animatedScale * (1 + shadowSpread * 2 / frameWidth);
        deviceShadowRef.current.scale.set(scaleWithShadowSpread, scaleWithShadowSpread, scaleWithShadowSpread);

        // Apply animated position combined with static offsets to the shadow mesh
        deviceShadowRef.current.position.x = animatedPosX + shadowXOffset;
        deviceShadowRef.current.position.y = animatedPosY + shadowYOffset;
        // deviceShadowRef.current.position.z = 0.1
        
        // Set the shadow's opacity based on animated opacity and Leva's opacity control
        deviceShadowRef.current.material.opacity = shadowOpacity * (animatedOpacity * animatedOpacity * animatedOpacity);

        // if(showScreenVideoStatic){
        //   deviceShadowRef.current.position.x = shadowXOffset
        //   deviceShadowRef.current.position.y = shadowYOffset
        //   deviceShadowRef.current.position.z = 0.05
        //   deviceShadowRef.current.material.opacity = shadowOpacity
        // }
      }
    }, [localTime, FPS, shadowXOffset, shadowYOffset, shadowSpread, shadowOpacity, frameWidth, startTransitionType,endTransitionType,JSON.stringify(animationParams), showScreenVideoStatic]);

  
    

    // Add something for specific brand kit
    const meshColor = getMeshColor(screenVideoBackground);

    const showClayOverlay = clip.metadata.clayTitle


  
  return (
    <>      


      
      <primitive 
        object={deviceFrameShadowMesh}
        ref={deviceShadowRef} 
        visible={visible}       
        renderOrder={renderOrders.screenVideoFrameShadow}
      /> 

      
      

      <mesh        
        ref={deviceFrameRef}
        position={[initialProperties.positionX, initialProperties.positionY, initialProperties.positionZ]}
        scale={[initialProperties.scale, initialProperties.scale, initialProperties.scale]}
        geometry={deviceGeometry}
        visible={visible}
        renderOrder={renderOrders.screenVideoFrame}
      >
          <meshBasicMaterial ref={deviceFrameMaterialRef} color={meshColor} transparent={true} />
      </mesh>

      {showClayOverlay &&   
        <ScreenVideoClayTitleOverlay 
          frameWidth={frameWidth}
          frameHeight={frameHeight}
          initialOffset={frameOffset}
          initialProperties={initialProperties}
          animationStates={animationStates}
          localTime={localTime}
          visible={visible}
          showScreenVideoStatic={showScreenVideoStatic}
          meshScale={chromeFrameMeshScale}    
          variableValues={variableValues}      
          clip={clip}              
      /> 
    }   
    {showClayOverlay &&   
        <ScreenVideoClayAvatarOverlay 
          frameWidth={frameWidth}
          frameHeight={frameHeight}
          initialOffset={frameOffset}
          initialProperties={initialProperties}
          animationStates={animationStates}
          localTime={localTime}
          visible={visible}
          showScreenVideoStatic={showScreenVideoStatic}
          meshScale={chromeFrameMeshScale}    
          variableValues={variableValues}   
          clip={clip}

      /> 
    }   

    </>
  );
};

export default ScreenVideoDeviceFrame;


