import {Plugin} from "prosemirror-state"
import { Decoration, DecorationSet } from "prosemirror-view"



export function cursorInsideNodePlugin(handleCursorInsideTranscriptChunk) {
	return new Plugin({
		props: {
			decorations: ({ doc, plugins, selection }) => {
				let {empty,from,to} = selection
				let decorations=[]
				if(empty){
					doc.nodesBetween(from,to,(node, pos) => {
						if(node.type.name=='transcriptChunk'){
							const decoration = Decoration.node(pos, pos + node.nodeSize, {
								class: 'editor-transcriptPanel-transcriptChunk--cursorInside'
							})
							decorations.push(decoration)
							handleCursorInsideTranscriptChunk(node.attrs.clipId)
						}
					})
				}
			return DecorationSet.create(doc, decorations)
		}
	}
	})
}
