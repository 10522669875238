import {fetch,fetchAuth} from '../api'
import store from '../store'
import {loadInitialData} from './initialData'


export function authenticateSession() {
	return async (dispatch) => {
		dispatch({ type: 'AUTHENTICATION_REQUEST' });
		try {
			const token = await window.ipcRenderer.invoke('get-auth-token');
			const url = '/auth/self';
			const response = await fetchAuth(url, {
				headers: {
					'Authorization': `Bearer ${token}`  // Assuming you use Bearer token
				}
			});

			dispatch({ type: 'AUTHENTICATION_SUCCESS' ,token:token});
			dispatch(loadInitialData())

		} catch (error) {
			console.error('Authentication error:', error);
			dispatch({ type: 'AUTHENTICATION_FAILURE' });
		}
	};
}


export function logout() {
	window.ipcRenderer.invoke('revoke-auth-token')
	store.dispatch({ type: 'AUTHENTICATION_FAILURE' });
	store.dispatch(authenticateSession())
}

