import React, { useState, useEffect,useMemo } from 'react';
import { useSpring, animated } from 'react-spring';
import Icon from '../../misc/Icon';
import { useSelector } from 'react-redux';
import TemplateBarTemplate from './TemplateBarTemplate';
import AllTemplatesPopover from './AllTemplatesPopover';

const TemplateBar = (props) => {
  const slideTemplates = useSelector(state => state.slideTemplates);
  const archivedSlideTemplates = useSelector(state => state.archivedSlideTemplates);
  const { projectBackgroundId, currentSlide, currentSlideBackgroundId } = props;
  

  const slideWidth = 95;
  let slidesPerPage = 5;  
  const visibleWidth = slideWidth * slidesPerPage;  

  const [showAllTemplatesPopover, setShowAllTemplatesPopover] = useState(0);

  const sortedSlideTemplates = [...slideTemplates].sort((a, b) => 
      new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
    );

  const sortedArchivedSlideTemplates = [...archivedSlideTemplates].sort((a, b) => 
    new Date(b.archived_at).getTime() - new Date(a.archived_at).getTime()
  );


  const filteredSlideTemplates = sortedSlideTemplates.slice(0, slidesPerPage)

   const handleUseSlideTemplate = (template) => {
    props.useSlideTemplate(template);
    setShowAllTemplatesPopover(false);
  };

  const hasTemplates = slideTemplates.length > 0;
  // const hasTemplates = false


  

  return (
    <div className='editor-templateBar'>

      {!hasTemplates &&
        <div className='editor-templateBar-centerContainer'>
          <button onClick={props.saveSlideAsTemplate} className='editor-templateBar-firstTemplateBtn'>                    
            <div className='editor-templateBar-firstTemplateBtn-iconContainer'>
              <Icon name='plusMedium' />
            </div>    
            <div className='editor-templateBar-firstTemplateBtn-label'>
              Add as Template
            </div>                        
          </button>
        </div>
    }

      {hasTemplates &&
        <div data-popover-open={showAllTemplatesPopover ? true : false} className='editor-templateBar-centerContainer'>
          <button onClick={props.saveSlideAsTemplate} className='editor-templateBar-btn editor-templateBar-btn--left'>        
            <div className='editor-templateBar-btn-hSpacer'/>
            <div className='editor-templateBar-btn-label'>
              Add as template
            </div>
            <div className='editor-templateBar-btn-iconContainer'>
              <Icon name='plusMedium' />
            </div>                  
          </button>
          
          <div  className='editor-templateBar-templatesOuter'>
            <div className='editor-templateBar-templatesInner'>
              {filteredSlideTemplates.map((template, index) => (
                <TemplateBarTemplate
                  key={index}
                  template={template}
                  displayWidth={slideWidth}
                  useSlideTemplate={props.useSlideTemplate}
                  activeBackgroundId={currentSlideBackgroundId === 'none' ? projectBackgroundId : currentSlideBackgroundId}
                />
              ))}
            </div>
          </div>        
          {slideTemplates.length <= slidesPerPage && 
            <button className='editor-templateBar-btn editor-templateBar-btn--right' />
          }
          {slideTemplates.length > slidesPerPage && 
            <AllTemplatesPopover
              showPopover={showAllTemplatesPopover}
              handleClosePopover={() => setShowAllTemplatesPopover(false)}
              slideTemplates={sortedSlideTemplates}
              archivedSlideTemplates={sortedArchivedSlideTemplates}
              activeBackgroundId={currentSlideBackgroundId === 'none' ? projectBackgroundId : currentSlideBackgroundId}
              useSlideTemplate={handleUseSlideTemplate}            
            >
            <button onClick={() => setShowAllTemplatesPopover(true)} className='editor-templateBar-btn editor-templateBar-btn--right'>        
              <div className='editor-templateBar-btn-iconContainer'>
                <Icon name='ellipses' />
              </div>        
              <div className='editor-templateBar-btn-label'>
                All templates
              </div>     
              <div className='editor-templateBar-btn-hSpacer'/>  
            </button>
            </AllTemplatesPopover>
          }
        </div>
      }
    </div>
  );
};

export default React.memo(TemplateBar);
// export default TemplateBar;