import React, {useState,useEffect} from 'react'
import Icon from '../misc/Icon'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import moment from 'moment'
import {getPricingPlan} from '../../utils/subscriptions/getPricingPlan'
import {Mixpanel} from '../../Mixpanel'
import {CheckboxItem} from '../uiKit/CheckboxItem'
import {orgIsSSO} from '../../utils/orgIsSSO'
import {getOrgDomain} from '../../utils/getOrgDomain'


function formatDate(date){
  let formattedDate = moment(date).format("MMMM D, YYYY")
  return formattedDate
}

function formatPrice(price) {
  return price >= 1000 ? price.toLocaleString() : price;
}

function PriceChecklistItem({label}) {
  return (
    <div className='settingsModal-panel--subscription-upgrade-checklist-item'> 
      <div className='settingsModal-panel--subscription-upgrade-checklist-item-iconContainer'> 
        <Icon name='toDoItemCheckmark' />
      </div>
      <div className='settingsModal-panel--subscription-upgrade-checklist-item-label'> 
        {label}
      </div>        
    </div>
  );
}


function SubscriptionPanel({orgMembers, organization, activateUser, suspendUser, handleUpgradeClick, cancelSubscription,userIsProMode, updateProMode}) {

  const {subscription_status, subscription_seats,next_billing_date,subscription_end_date} = organization
  
  const plan = getPricingPlan()

  const midmarketPlan = plan.isMidmarketPlan // leya law
  const isGrowthPlan=plan.isGrowthPlan
  const label = plan.label
  const isEnterprise = plan.isEnterprisePlan

  const isSSO=orgIsSSO()
  //const isSSO=true

  let price = plan.price
  let planName = 'Yarn Standard'
  if(midmarketPlan){
    planName = 'Yarn Starter'
  }
  if(isGrowthPlan){
    planName="Yarn Growth"
  }
  if(label){
    planName=label
  }
  if(isSSO){
    planName = "Yarn Enterprise"
  }


  const orgDomain = getOrgDomain()
  const isClay = (orgDomain=='clay.com' ||orgDomain=='clay.run' )
  if(isClay){
    planName = "Yarn Growth"
  }




  const activeSeats = subscription_seats
  

  let activeSeatLabel = '1 active seat'
  if(activeSeats > 1){
    activeSeatLabel = `${activeSeats} active seats`
  }

  useEffect(() => { // Step 2: Use useEffect for the analytics event
      Mixpanel.track('open_payment_modal')
  }, []);





 // console.log(`isSSO--------- ${isSSO}`)

  // console.log(plan)

  //const [proMode, setProMode] = useState(false);
  
  return (
    
    <div className='settingsModal-panel settingsModal-panel--subscription'> 
    
        <div className='settingsModal-panel-section settingsModal-panel-section--preferences'>
          <div className='settingsModal-panel-section-header'>
            <div className='settingsModal-panel-section-header-label'>
              Preferences
            </div>
            <div className='settingsModal-panel-section-header-divider'/>
          </div>        
          <div className='settingsModal-panel-section-contents'>
        
            <CheckboxItem 
              checked={userIsProMode}
              toggleChecked={()=> updateProMode(!userIsProMode)}
              label='Pro mode'
              description='Displays keyboard shortcuts inline and detailed playback info.'
            />

          </div>
        </div>


        <div className='settingsModal-panel-section'>
          <div className='settingsModal-panel-section-header'>
            {!subscription_status &&
              <div className='settingsModal-panel-section-header-label'>
                Upgrade
              </div>
            }
            {subscription_status &&
              <div className='settingsModal-panel-section-header-label'>
                Plan
              </div>
            }
            <div className='settingsModal-panel-section-header-divider'/>
          </div>
          <div className='settingsModal-panel-section-contents'>


            {!subscription_status &&
              <div className='settingsModal-panel--subscription-upgrade'>
                <div className='settingsModal-panel--subscription-upgrade-top'>
                  <div className='settingsModal-panel--subscription-upgrade-price'>
                    <div className='settingsModal-panel--subscription-upgrade-price-price'>
                      ${formatPrice(price)}
                    </div>

                    {!isGrowthPlan && !isEnterprise &&
                    <div className='settingsModal-panel--subscription-upgrade-price-sub'>
                      {!isEnterprise &&
                      <div className='settingsModal-panel--subscription-upgrade-price-sub-sub'>
                        per user
                      </div>
                    }
                      <div className='settingsModal-panel--subscription-upgrade-price-sub-sub'>
                        per month
                      </div>
                    </div>
                  }
                  {isGrowthPlan &&
                    <div className='settingsModal-panel--subscription-upgrade-price-sub'>
                      <div className='settingsModal-panel--subscription-upgrade-price-sub-sub'>
                        per month (5 seats)
                      </div>
                      <div className='settingsModal-panel--subscription-upgrade-price-sub-sub'>
                        
                      </div>
                    </div>
                  }

                  </div>
                  <button onClick={()=>{handleUpgradeClick(plan.priceId)}} className='settingsModal-panel--subscription-upgrade-btn'>
                    Upgrade
                  </button>
                </div>
                <div className='settingsModal-panel--subscription-upgrade-checklist'>
                  <div className='settingsModal-panel--subscription-upgrade-checklist-col'>
                    <PriceChecklistItem label='Unlimited videos' />
                    <PriceChecklistItem label='Unlimited AI voice gen' />
                    {midmarketPlan && 
                      <PriceChecklistItem label='Shared team library' />
                    }
                    <PriceChecklistItem label='Text slides' />
                  </div>
                  <div className='settingsModal-panel--subscription-upgrade-checklist-col'>
                    <PriceChecklistItem label='Custom branding' />
                    <PriceChecklistItem label='Music library' />
                    <PriceChecklistItem label='Support via Slack Connect' />          
                  </div>
                </div>
                <div className='settingsModal-panel--subscription-upgrade-footer'>
                  {!isEnterprise &&
                  <span>Billed monthly, cancel anytime.</span>
                  }
                  {isEnterprise &&
                  <span>Billed monthly</span>
                  }
                </div>
              </div>
            }

            {subscription_status == 'active' &&
              <div className='settingsModal-panel--subscription-plan'>
                <div className='settingsModal-panel--subscription-plan-top'>
                  <div className='settingsModal-panel--subscription-plan-current'>
                    {planName}
                  </div> 
                  {!isSSO &&                   
                    <button onClick={cancelSubscription} className='settingsModal-panel--subscription-plan-cancelBtn'>
                      Cancel
                    </button>
                  }
                  
                </div>         
                {!isGrowthPlan && !isSSO && !isClay &&
                <> 
                  <div className='settingsModal-panel--subscription-plan-mid'>
                    ${price}/seat/month ({activeSeatLabel}, billed monthly)
                  </div>
                  <div className='settingsModal-panel--subscription-plan-low'>
                    Your plan will renew on {formatDate(next_billing_date)}
                  </div>
                </> 
              }
              {isGrowthPlan && !isSSO &&  !isClay &&
                <> 
                  <div className='settingsModal-panel--subscription-plan-mid'>
                    ${price}/month
                  </div>
                  <div className='settingsModal-panel--subscription-plan-low'>
                    Your plan will renew on {formatDate(next_billing_date)}
                  </div>
                </> 
              }
              {isSSO &&
                <>
                  <div className='settingsModal-panel--subscription-plan-mid'>
                    Faire
                  </div>
                  {/*}
                  <div className='settingsModal-panel--subscription-plan-low'>
                    Contact Amy
                  </div>
                  */}
                </>
              }
              {isClay &&
                <>
                  <div className='settingsModal-panel--subscription-plan-mid'>
                    Clay
                  </div>
                  {/*}
                  <div className='settingsModal-panel--subscription-plan-low'>
                    Contact Amy
                  </div>
                  */}
                </>
              }

              </div>
            }

            {subscription_status == "canceled" &&
              <div className='settingsModal-panel--subscription-plan'>
                <div className='settingsModal-panel--subscription-plan-top'>
                  <div className='settingsModal-panel--subscription-plan-current'>
                    {planName} <span>(Canceled)</span>
                  </div>                                                    
                </div>              
                <div className='settingsModal-panel--subscription-plan-mid'>
                  Your plan will end on {formatDate(subscription_end_date)}
                </div>                
              </div>
            }


          </div>
        </div>

        <div className='settingsModal-panel-section settingsModal-panel-section--members'>
          <div className='settingsModal-panel-section-header'>
            <div className='settingsModal-panel-section-header-label'>
              Team Members
            </div>
            <div className='settingsModal-panel-section-header-divider'/>
          </div>

            

          <div className='settingsModal-panel-section-contents'>

              {orgMembers.map((user)=>{
                
                const userIsSuspended=user.suspended_at?true:false                
                
                return(                 
                  <div className={'settingsModal-panel-section--members-rowContainer ' + (userIsSuspended ? ' settingsModal-panel-section--members-rowContainer--user--suspended ' : ' settingsModal-panel-section--members-rowContainer--user--active ')}>
                    <div className='settingsModal-panel-section--members-row'>
                      {user.profile_image_url && 
                        <div className='settingsModal-panel-section--members-row-avatarContainer'>
                          <img referrerpolicy="no-referrer" src={user.profile_image_url} className='settingsModal-panel-section--members-row-avatar' />
                        </div>
                      }
                      <div className='settingsModal-panel-section--members-row-primary'>
                        {user.display_name}
                      </div>
                      <div className='settingsModal-panel-section--members-row-secondary'>
                        {user.email}
                      </div>          
                      {userIsSuspended &&           
                        <div className='settingsModal-panel-section--members-row-suspended'>
                          Suspended
                        </div>
                      }
                      <div className='settingsModal-panel-section--members-row-dropdownBtnContainer'>
                        <DropdownMenu.Root modal={false}> 
                          <DropdownMenu.Trigger asChild>              
                            <button data-state="inactive" className='settingsModal-panel-section--members-row-dropdownBtn'>
                              <Icon name='ellipses' />
                            </button>        
                          </DropdownMenu.Trigger>
                          <DropdownMenu.Content             
                            align="end"              
                            className='dropdownMenu dropdownMenu--memberTable forceDarkTheme'
                          >            
                            {userIsSuspended &&   
                              <DropdownMenu.Item onClick={()=>{activateUser(user.id)}}>
                                Activate user
                              </DropdownMenu.Item>
                            }
                            {!userIsSuspended &&   
                              <DropdownMenu.Item onClick={()=>{suspendUser(user.id)}} className='dropdownMenu-item--warning'>
                                Suspend user
                              </DropdownMenu.Item>
                            }
                          </DropdownMenu.Content>
                        </DropdownMenu.Root> 
                      </div> 
                    </div>
                  </div>
                )
              })}




            

          </div>
        </div>

    </div>
  );
}

export default SubscriptionPanel;
