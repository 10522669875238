const SlideElementNodeSpec = {
	attrs: {
		id:{default:'defaultID'},
		bottomMargin:{default:0},
		rightMargin:{default:0},
		x:{default:50},
		y:{default:100},
		height:{default:50},
		width:{default:100},
		metadata:{default:{}},
		type:{default:'text'},
	},
};





 export default SlideElementNodeSpec
 