import { Plugin } from 'prosemirror-state'

//We want to prevent the up arrow if we are on the first line of the first chunk in a doc with only 1 scene or left from the start of the first chunk
// (otherwise cursor gets eaten by the hidden scene header)

const isAtStartOfFirstChunk = (state, $pos) => {
  // Find first chunk in document
  let firstChunkPos = null;
  let firstChunk = null;
  state.doc.descendants((node, pos) => {
    if (firstChunkPos === null && 
        (node.type.name === 'transcriptChunk' || node.type.name === 'webcamRecordingChunk')) {
      firstChunkPos = pos;
      firstChunk = node;
      return false;
    }
  });

  if (!firstChunk) return false;

  // Check if we're in first chunk and at its start
  let currentChunkPos = null;
  state.doc.nodesBetween($pos.pos, $pos.pos, (node, pos) => {
    if (node.type.name === 'transcriptChunk' || node.type.name === 'webcamRecordingChunk') {
      currentChunkPos = pos;
      return false;
    }
  });

  return currentChunkPos === firstChunkPos && $pos.parentOffset === 0;
};


const hasOnlyOneScene = (state) => {
  let sceneCount = 0;
  state.doc.descendants(node => {
    if (node.type.name === 'sceneHeader') {
      sceneCount++;
      if (sceneCount > 1) return false;
    }
  });
  return sceneCount === 1;
};

export const arrowPlugin = new Plugin({
  props: {
    handleKeyDown: (view, event) => {
      const { state } = view;

      if (event.key === 'ArrowLeft') {
       // console.log('isAtStartOfFirstChunk',isAtStartOfFirstChunk(state, state.selection.$from))

        if (isAtStartOfFirstChunk(state, state.selection.$from) && hasOnlyOneScene(state)) {
          event.preventDefault();
          return true;
        }
      }
      else if (event.key === 'ArrowUp') {
        if (isFirstLineOfFirstChunk(view) && hasOnlyOneScene(state)) {
          event.preventDefault();
          return true;
        }
      }
      
      return false;
    }
  }
});

// Previous isFirstLineOfFirstChunk function from the up arrow version remains the same
const isFirstLineOfFirstChunk = (view) => {
  const { state } = view;
  const { selection } = state;
  const { $from } = selection;

  // Get current chunk we're in
  let currentChunk = null;
  let currentChunkPos = null;
  state.doc.nodesBetween($from.pos, $from.pos, (node, pos) => {
    if (node.type.name === 'transcriptChunk' || node.type.name === 'webcamRecordingChunk') {
      currentChunk = node;
      currentChunkPos = pos;
      return false;
    }
  });

  if (!currentChunk) return false;

  // Check if this is the first chunk
  let isFirstChunk = false;
  state.doc.descendants((node, pos) => {
    if (pos >= currentChunkPos) return false;
    
    if (node.type.name === 'sceneHeader') {
      isFirstChunk = true;
      return false;
    }
    
    if ((node.type.name === 'transcriptChunk' || node.type.name === 'webcamRecordingChunk') 
        && pos !== currentChunkPos) {
      isFirstChunk = false;
      return false;
    }
  });

  //console.log(`isFirstChunk---- ${isFirstChunk}`)

  if (!isFirstChunk) return false;

  const cursorCoords = view.coordsAtPos($from.pos);
  const chunkStartCoords = view.coordsAtPos(currentChunkPos + 1);
  const isFirstLine = Math.abs(cursorCoords.top - chunkStartCoords.top) < 5;

  return isFirstLine;
};