import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store';
import AppContainer from './containers/AppContainer';
import './styles/App.scss';
// import posthog from 'posthog-js';


// posthog.init(process.env.REACT_APP_POSTHOG_API_KEY, { api_host: 'https://app.posthog.com' })

const { init } = require("@sentry/electron/renderer");

import LogRocket from 'logrocket';

if(process.env.REACT_APP_ENV=="production"){
  LogRocket.init(process.env.REACT_APP_LOGROCKET_APP_ID);
}


if(process.env.REACT_APP_ENV=="production"){
init({
  dsn: 'https://cf3a83a0f2d29c91038d3917942a0be1@o4503976675508224.ingest.sentry.io/4506445097730048',
  environment: process.env.REACT_APP_ENV=="production" ? 'production' : 'dev'
});
}

const originalConsoleError = console.error;
console.error = function (message) {
  if (typeof message === 'string' && message.includes('Warning: ReactDOM.render is no longer supported') ||
      typeof message === 'string' && message.includes('The play() request')
    ) {
    // Mute this specific warning
    return;
  }
  // If it's not the warning you want to mute, log it as usual
  originalConsoleError.apply(console, arguments);
};

function render() {
  const root = createRoot(document.getElementById('root'));

  root.render(
    <Provider store={store}>
      <AppContainer />
    </Provider>
  );
}

render();

