import { Plugin, PluginKey } from "prosemirror-state"
import { Decoration, DecorationSet } from "prosemirror-view"

const showInlineControlsKey = new PluginKey("showInlineControls")

export function getAnchorNode($from, nodeType) {
	for (let i = $from.depth; i >= 0; i--) {
		let node = $from.node(i)
		if (node.type.name == nodeType) {
			return { node, pos: i > 0 ? $from.before(i) : 0, depth: i }
		}
	}
	return null
}

export function showInlineControlsPlugin(delay = 500) {
	let timeout = null
	let lastDecorationPos = null
	let isUpdating = false

	return new Plugin({
		key: showInlineControlsKey,
		state: {
			init() {
				return DecorationSet.empty
			},
			apply(tr, oldState) {
				const meta = tr.getMeta(showInlineControlsKey)
				if (meta !== undefined) return meta
				return oldState
			}
		},
		props: {
			decorations(state) {
				return this.getState(state)
			}
		},
		view(editorView) {
			const updateDecorations = (decorations) => {
				if (!isUpdating) {
					isUpdating = true
					editorView.dispatch(editorView.state.tr.setMeta(showInlineControlsKey, decorations))
					isUpdating = false
				}
			}

			return {
				update: (view, prevState) => {
					if (isUpdating) return

					const { state } = view
					const { empty, $from } = state.selection

					const result = getAnchorNode($from, 'transcriptChunk')

					if (!result && lastDecorationPos !== null) {
						clearTimeout(timeout)
						lastDecorationPos = null
						updateDecorations(DecorationSet.empty)
						return
					}

					if (!empty) {
						clearTimeout(timeout)
						if (lastDecorationPos !== null) {
							lastDecorationPos = null
							updateDecorations(DecorationSet.empty)
						}
						return
					}

					if (result && result.pos !== lastDecorationPos) {
						clearTimeout(timeout)
						if (lastDecorationPos !== null) {
							updateDecorations(DecorationSet.empty)
						}
						timeout = setTimeout(() => {
							const decoration = Decoration.node(result.pos, result.pos + result.node.nodeSize, {
								class: 'editor-transcriptPanel-transcriptChunk--showInlineControls'
							})
							lastDecorationPos = result.pos
							updateDecorations(DecorationSet.create(state.doc, [decoration]))
						}, delay)
					}
				}
			}
		}
	})
}