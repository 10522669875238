export function updateTranscriptChunks(existingTranscript, newChunkBoundaries) {
  if (!existingTranscript?.words || !newChunkBoundaries?.length) {
    return null;
  }

  const words = existingTranscript.words;
  const chunks = [];

  // Sort chunks by firstWordIndex to ensure we process in order
  const sortedChunks = [...newChunkBoundaries].sort((a, b) => 
    a.firstWordIndex - b.firstWordIndex
  );

  // Create new chunks based on the provided boundaries
  sortedChunks.forEach(boundary => {
    const chunkWords = words.filter(word => 
      word.index >= boundary.firstWordIndex && 
      word.index <= boundary.lastWordIndex
    );

    if (chunkWords.length > 0) {
      const chunk = {
        start: chunkWords[0].start,
        end: chunkWords[chunkWords.length - 1].end,
        words: chunkWords,
        text: chunkWords
          .map(w => w.word)
          .join(' ')
          .replace(/\s+([.,!?])/g, '$1'),
        firstWordIndex: boundary.firstWordIndex,
        lastWordIndex: boundary.lastWordIndex
      };
      chunks.push(chunk);
    }
  });

  // Update the full transcript (though this shouldn't change)
  const transcript = words
    .map(w => w.word)
    .join(' ')
    .replace(/\s+([.,!?])/g, '$1');

  return {
    transcript,
    words,
    chunks
  };
}