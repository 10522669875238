import { SpringAnimator } from '../../../three/utils/animations/SpringAnimator';

const animationParams = {
  mass: 1,
  stiffness: 170,
  damping: 26,
};

export const getSmoothedLegacyCursorData = async (
  normalizedCursorData,
  masterRecordingVideoDuration
) => {
  try {
    const FPS = 60;
    const totalFrames = masterRecordingVideoDuration * FPS;
    const smoothedCursorData = [];

    // Create a new array with every third row from normalizedCursorData
    const filteredCursorData = normalizedCursorData.filter((_, index) => index % 3 === 0);

    let springX = new SpringAnimator(animationParams.mass, animationParams.stiffness, animationParams.damping, filteredCursorData[0].x);
    let springY = new SpringAnimator(animationParams.mass, animationParams.stiffness, animationParams.damping, filteredCursorData[0].y);

    let nextDataPointIndex = 0;

    for (let frame = 0; frame < totalFrames; frame++) {
      const currentTime = frame / FPS;

      if (nextDataPointIndex < filteredCursorData.length && filteredCursorData[nextDataPointIndex].time <= currentTime) {
        springX.setTarget(filteredCursorData[nextDataPointIndex].x);
        springY.setTarget(filteredCursorData[nextDataPointIndex].y);
        nextDataPointIndex++;
      }

      springX.simulate(1000 / FPS);
      springY.simulate(1000 / FPS);

      smoothedCursorData.push({ x: springX.getPosition(), y: springY.getPosition(), time: currentTime });
    }

    return smoothedCursorData;
  } catch (error) {
    console.error('Failed to calculate smoothedCursor Data:', error);
    return [];
  }
};
