export function findFirstWordInLine(view, doc, chunk, chunkPos, targetY) {
	// Get all words in the chunk with their coordinates
	const words = [];
	chunk.descendants((node, pos) => {
		if (node.type.name === 'webcamTranscriptWord') {
			const domNode = view.domAtPos(chunkPos + pos)?.node;
			if (domNode) {
				const rect = domNode.getBoundingClientRect();
					words.push({
						pos: chunkPos + pos + 1, // +1 to move after word start tag
						x: rect.left,
						y: rect.top,
						nodeSize:node.nodeSize
					});
				}
			}
		});

		if (words.length === 0) return null;
		if (words.length ==1){
			return words[0].pos + words[0].nodeSize -1
		}

		// Group words by line (y-coordinate)
		const lines = new Map();
		words.forEach(word => {
			const key = Math.round(word.y);
			if (!lines.has(key)) {
				lines.set(key, []);
			}
			lines.get(key).push(word);
		});
		// If no lines found, return null
		const lineYs = Array.from(lines.keys());
		if (lineYs.length === 0) return null;
		// Find the line closest to our target Y
		const targetLine = lineYs.reduce((closest, y) => {
		return Math.abs(y - targetY) < Math.abs(closest - targetY) ? y : closest;
		}, lineYs[0]);
		// Get the first word from that line
		const lineWords = lines.get(targetLine);
		if (!lineWords?.length) return null;
		// Sort by x position and take the first word
		lineWords.sort((a, b) => a.x - b.x);
		return lineWords[0].pos-1; // Just return the position with the offset
	}