// srtParser.js

export function parseSRT(srtContent) {
  const subtitles = [];
  const subtitleBlocks = srtContent.trim().split('\n\n');

  for (const block of subtitleBlocks) {
    const lines = block.split('\n');
    if (lines.length < 3) continue; // Skip invalid blocks

    const timeCode = lines[1].split(' --> ');
    if (timeCode.length !== 2) continue; // Skip invalid timecodes

    subtitles.push({
      start: parseTimeCode(timeCode[0]),
      end: parseTimeCode(timeCode[1]),
      line1: lines[2] || '',
      line2: lines[3] || ''
    });
  }

  return subtitles;
}

function parseTimeCode(timeCode) {
  const [time, milliseconds] = timeCode.split(',');
  const [hours, minutes, seconds] = time.split(':');

  return (
    parseInt(hours) * 3600 +
    parseInt(minutes) * 60 +
    parseInt(seconds) +
    parseInt(milliseconds) / 1000
  );
}