import {TimelineClip} from './TimelineClip'
import {fetchVideoURL} from '../utils/recordings/legacyScreenRecordings/getLegacyMasterRecordingData'
import {calulateVideoTimeFromTimelineTime} from './utils/calulateVideoTimeFromTimelineTime'
import { Seeker } from './lib/seeker';
import { createParserFromUrl } from './lib/createParserFromUrl'
import { useRef } from "react";
import { fetchProxyImage } from '../actions/proxyImage';

//Basic video is a user uploaded video
//basic video false is a screen recording
//isDeviceRecording is an iphone/ipad recoding


function getImageDimensions(url) {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.onload = function() {
			resolve({ width: img.width, height: img.height });
		};
		img.onerror = function(error) {
			reject(error);
		};
		img.src = url; // Set the source to start loading the image
	});
}


class CodecVideoClip extends TimelineClip {

	constructor(options, scene,renderVariables) {
		super(options,scene)
		this.isBasicVideo = options.isBasicVideo
		this.isDeviceRecording = options.isDeviceRecording || false
		this.isUploadingVideo = options.isUploadingVideo || false
		this.videoId = options.videoId
		this.segments = options.segments 
		this.isWebcam = options.type=='webcam'
		this.isScreenRecording = options.isScreenRecording
		this.recordingSegments = options.recordingSegments
		this.hasSegments = this.segments.length
		this.hasRecordingSegments = this.recordingSegments.length
		this.clipPlaybackRate = options.clipPlaybackRate || 1
		this.resolveSeek;

		 //for pablo's thing
    if(this.videoId=='549026026'){
      this.isUploadingVideo = false
      this.metadata.originalWidth =3840
      this.metadata.originalHeight = 2088
      this.metadata.displayWidth=1600
    }

    if(this.videoId=='23891685'){
      console.log('----------------------------here this clip is here')
      this.isUploadingVideo=false
      this.metadata.originalHeight = 2160
      this.metadata.originalWidth=3456
      this.metadata.displayWidth=1440
      this.metadata.trimStart = 0 
      this.metadata.trimEnd=14.333333
     // this.metadata.semiTransparent = false

    }
    if(this.videoId=='1633266791'){
      this.isUploadingVideo=false
       this.metadata.originalHeight = 2160
      this.metadata.originalWidth=3456
       this.metadata.displayWidth=1440
         this.metadata.trimStart = 0 
      this.metadata.trimEnd=4.766667
    }

		
		
		if (this.metadata.label=='Google Chrome') {
			this.metadata.screenVideoApp='chrome'
		} else {
			this.metadata.screenVideoApp=''
		}


		this.renderVariables = renderVariables

		this.isExport = true

		this.captureId = options.captureId;

		this.loadPromise = this.load(options.captureId);

		this.seeker = null
		this.resolveSeek = null;

		this.externalTexture = null;
		this.externalAlphaTexture = null;
		this.hasAlphaChannel = false;   


		//we use this to cache the results for freeze frames
		this.lastVideoTime = null; // To store the last requested video time
    this.cachedVideoBitmap = null; // To store the cached video ImageBitmap
    this.cachedAlphaBitmap = null; // To store the cached alpha ImageBitmap

    console.log(this)

		let clayTitle 
    this.clayProfilePicture='' 
    
    this.initializationPromise = (async () => {
      if (this.metadata.clayTitle) {
        clayTitle = this.metadata.clayTitle;
        const variableRegex = /{{\s*([^{}\s]+)\s*}}/g;
        let matches = [];
        let match;
        while ((match = variableRegex.exec(this.metadata.clayTitle)) !== null) {
        matches.push(match[1]);
      }
      matches.forEach(variable => {
        const variableValue = this.renderVariables[variable] || `{{${variable}}}`;
        clayTitle = clayTitle.replace(new RegExp(`{{${variable}}}`, 'g'), variableValue);
      });
      this.metadata.clayTitle = clayTitle;
    }
    if (renderVariables && renderVariables.pictureUrl) {
      try {
        const imageUrl = await fetchProxyImage(renderVariables.pictureUrl);
        if (imageUrl) {
          this.clayProfilePicture = imageUrl;
          try {
            const dimensions = await getImageDimensions(imageUrl);
            this.clayProfilePictureWidth = dimensions.width;
            this.clayProfilePictureHeight = dimensions.height;
          } catch (dimensionError) {
            this.clayProfilePictureWidth = 0;
            this.clayProfilePictureHeight = 0;
          }
        } else {
          this.clayProfilePicture = null;
          this.clayProfilePictureWidth = 0;
          this.clayProfilePictureHeight = 0;
        }
      } catch (error) {
        console.error('Error processing profile picture:', error);
        this.clayProfilePicture = null;
        this.clayProfilePictureWidth = 0;
        this.clayProfilePictureHeight = 0;
      }
    }
    })();
	}


	setExternalTexture(texture) {
		// console.log('CodecVideoClip: Setting external texture', texture);
		this.externalTexture = texture;
	}

	setExternalAlphaTexture(alphaTexture) {
		// console.log('CodecVideoClip: Setting external alpha texture', alphaTexture);
		this.externalAlphaTexture = alphaTexture;
	}

	setTextureUpdatedCallback(callback) {
		this.textureUpdatedCallback = callback;
	}




	displayCallback = async (video, alpha) => {
    console.log('CodecVideoClip: Display callback called', video ? 'with video' : 'no video', alpha ? 'with alpha' : 'no alpha');
    
    this.hasAlphaChannel = !!alpha;
    
     if (video) {
      if (this.cachedVideoBitmap) {
        this.cachedVideoBitmap.close(); // Close the previous bitmap
      }
      try {
        const options = {
          imageOrientation: 'flipY',
          resizeQuality: 'high',
        };
        this.cachedVideoBitmap = await createImageBitmap(video, options);
      } catch (error) {
        console.error('CodecVideoClip: Error creating main ImageBitmap:', error);
        this.cachedVideoBitmap = null;
      }
      video.close(); // Close the original frame
    }

    if (alpha) {
      if (this.cachedAlphaBitmap) {
        this.cachedAlphaBitmap.close(); // Close the previous bitmap
      }
      try {
        const options = {
          imageOrientation: 'flipY',
          resizeQuality: 'high',
        };
        this.cachedAlphaBitmap = await createImageBitmap(alpha, options);
      } catch (error) {
        console.error('CodecVideoClip: Error creating alpha ImageBitmap:', error);
        this.cachedAlphaBitmap = null;
      }
      alpha.close(); // Close the original frame
    }
    
    // console.log('i am here now11111')
    // console.log(this.cachedVideoBitmap)

    if (this.externalTexture && this.cachedVideoBitmap) {
      this.externalTexture.image = this.cachedVideoBitmap;
      this.externalTexture.needsUpdate = true;
    }

    if (this.externalAlphaTexture && this.cachedAlphaBitmap) {
      this.externalAlphaTexture.image = this.cachedAlphaBitmap;
      this.externalAlphaTexture.needsUpdate = true;
    }

    if (this.textureUpdatedCallback) {
    //	console.log('texture updated callllback!!!')
      // console.log('CodecVideoClip: Calling texture updated callback');
      this.textureUpdatedCallback(this.hasAlphaChannel);
    }

  };

	async downloadVideo(url) {
		const response = await fetch(url);
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}
		return await response.blob();
	}

load() {
	return new Promise(async (resolve, reject) => {
		try {
		 // http://res.cloudinary.com/yarn/video/upload/vc_h264:auto:auto:bframes_no/f_mp4/v1727970747/recordings/1697726274.mp4
			let videoUrl 
			if(this.isScreenRecording){
				videoUrl = `https://res.cloudinary.com/yarn/video/upload/vc_h264:auto:auto:bframes_no/screenRecordings/${this.captureId}.mp4`;
			}

			else if(this.isWebcam){
				//videoUrl = `https://res.cloudinary.com/yarn/video/upload/vc_h264:auto:auto:bframes_no/webcamVideos/${this.videoId}.mp4`;
        videoUrl = `https://yarn-assets.s3.amazonaws.com/webcamRecordings/${this.captureId}/recording_exportReady.mp4`;
			}
			else{

			if(this.isBasicVideo){
				if(this.metadata.semiTransparent){
					videoUrl = `https://yarn-assets.s3.amazonaws.com/videoUploads/${this.videoId}/video-exportready.mp4`;
				}else{
					videoUrl = `https://res.cloudinary.com/yarn/video/upload/vc_h264:auto:auto:bframes_no/f_mp4/video_uploads/${this.videoId}.mp4`;
				}        
			}else{
				if(this.isDeviceRecording){
					videoUrl = `https://res.cloudinary.com/yarn/video/upload/vc_h264:auto:auto:bframes_no/f_mp4/deviceRecordings/${this.captureId}.mp4`;
				}else{
				 // videoUrl = `https://res.cloudinary.com/yarn/video/upload/vc_h264:auto:auto:bframes_no/f_mp4/recordings/${this.captureId}.mp4`;
					videoUrl = `https://res.cloudinary.com/yarn/video/upload/vc_h264:auto:auto:bframes_no/recordings/${this.captureId}.mp4`;
				}
			}
		}



			console.log('**********************')
			console.log('Loading video from URL:', videoUrl);
			this.parser = await createParserFromUrl(videoUrl);
			console.log('Parser created successfully');

      
        if (this.isBasicVideo) {
        // Wait for a short time to allow parsing to complete
        let attempts = 0;
        const maxAttempts = 10;
        
        while (!this.parser.isReady && attempts < maxAttempts) {
          await new Promise(resolve => setTimeout(resolve, 100));
          attempts++;
        }

        if (!this.parser.hasVideoTrack) {
          console.log('No video track found in basic video:', this.videoId);
          this.hasVideoTrack = false;
          resolve();
          return;
        }
      }

      this.hasVideoTrack = true;
      console.log('HAS VIDEO TRACK IN CODEC CLIP')

      
			this.seeker = new Seeker(this.displayCallback);
			console.log('Seeker created');
			this.seeker.setEvictCallback(this.parser.evictSamples.bind(this.parser));
			console.log('Evict callback set');
			this.seeker.setInput(this.parser.readable);
			console.log('Parser readable set as seeker input');
			await this.seek(this.startTime);
			// console.log('Initial seek completed');
			resolve();
		} catch (error) {
			console.error('Error in CodecVideoClip load method:', error);
			reject(error);
		}
	});
}


  toJSON() {
   const json = {
      id: this.id,
      type:this.type,
      captureId:this.captureId,
      isDeviceRecording:this.isDeviceRecording,
      isUploadingVideo:this.isUploadingVideo,
      videoId:this.videoId,
      fileName:this.fileName,
      isBasicVideo:this.isBasicVideo,
      startTime:this._startTime,
      absoluteStartTime:this.startTime, //for server side export add this for audio- TODO maybe update ssr to calc based on scenes
      duration:this.duration,
      name:this.name,
      metadata:this.metadata,
      zIndex:this.zIndex,
      recordingSegments:this.recordingSegments,
      segments:this.segments,
      clipPlaybackRate:this.clipPlaybackRate,
      absoluteStartTime:this.startTime,
    };
    return json
  }

	
  seek(currentTime) {
    if (currentTime >= this.startTime && currentTime < this.endTime) {
      const videoTime = calulateVideoTimeFromTimelineTime(currentTime, this);
      // console.log(`CodecVideoClip: Seeking to videoTime ${videoTime}`);

      const frameTolerance = 0.001; // Use frameDuration or default to 1ms
     // console.log(`frameTolerance ${frameTolerance}`);

      if (
        this.lastVideoTime !== null &&
        Math.abs(this.lastVideoTime - videoTime) <= frameTolerance &&
        this.cachedVideoBitmap == null
      ) {
      	console.log('************** within tolerance but no image cached!!!!')
      }

      // Check if the requested videoTime is within the frame duration of the last one and cached ImageBitmaps are available
      if (
        this.lastVideoTime !== null &&
        Math.abs(this.lastVideoTime - videoTime) <= frameTolerance &&
        this.cachedVideoBitmap !== null &&
        (this.hasAlphaChannel ? this.cachedAlphaBitmap !== null : true)
      ) {
        console.log(
          'CodecVideoClip: Same videoTime (within frame tolerance) requested, updating textures from cached ImageBitmaps'
        );

        if (this.externalTexture && this.cachedVideoBitmap) {
          this.externalTexture.image = this.cachedVideoBitmap;
          this.externalTexture.needsUpdate = true;
        }
        if (this.externalAlphaTexture && this.cachedAlphaBitmap) {
          this.externalAlphaTexture.image = this.cachedAlphaBitmap;
          this.externalAlphaTexture.needsUpdate = true;
        }
        if (this.textureUpdatedCallback) {
        	console.log('CALL TEXTURE UPDATED CALLBACK')
          this.textureUpdatedCallback(this.hasAlphaChannel);
        }
        return;
      }

      this.lastVideoTime = videoTime; // Update the last requested videoTime

      if (!this.seeker) return;
      this.seeker.seek(videoTime);
    }
  }




}

export { CodecVideoClip }