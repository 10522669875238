import React, {useState} from 'react'
import Icon from '../../misc/Icon'

function LibraryProjectItemLockedBanner({isLocked,lockedUserFirstName, lockedAvatarUrl}) {


	return (		
		
		
		<div className='libraryPage-project-previewUI-lockedBannerContainer'>
			<div className='libraryPage-project-previewUI-lockedBanner'>
							
					{lockedAvatarUrl && 
						<div className='libraryPage-project-previewUI-lockedBanner-avatar'>
						 <img referrerpolicy="no-referrer" src={lockedAvatarUrl} />
						</div>				
					}

					{!lockedAvatarUrl && 
						<div className='libraryPage-project-previewUI-lockedBanner-avatar'>
						 <Icon name='userCircle' />
						</div>				
					}
				
					{lockedUserFirstName && 
						<div className='libraryPage-project-previewUI-lockedBanner-label'>
							<span className='libraryPage-project-previewUI-lockedBanner-name'>{lockedUserFirstName}</span> is editing
						</div>
					}

					{!lockedUserFirstName && 
						<div className='libraryPage-project-previewUI-lockedBanner-label'>
							<span className='libraryPage-project-previewUI-lockedBanner-name'>Someone</span> is editing
						</div>
					}

			</div>      				
		</div>			

		
	);
}

export default LibraryProjectItemLockedBanner;
