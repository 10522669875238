import {randomID} from '../../utils/randomID'
import {getDurationForCaptureId} from '../../utils/recordings/getDurationForCaptureId'
import {getLabelForCaptureId} from '../../utils/recordings/getLabelForCaptureId'
import {getDeviceDimensionsForCaptureId} from '../../utils/recordings/deviceRecordings/getDeviceDimensionsForCaptureId'
import {getOrgScreenVideoStyle} from '../../utils/brands/getOrgScreenVideoStyle'

export async function createVideoClipObjFromCaptureId(captureId,isDevice,isScreenRecording,motionStyle,currentTime) {
		let duration  = await getDurationForCaptureId(captureId,isDevice,isScreenRecording)
		let label = await getLabelForCaptureId(captureId,isDevice,isScreenRecording)
		

		let metadata={
			label:label,
			startTransitionType:'fadeAndMoveUp',
			endTransitionType:'fadeAndMoveDown',
			motionStyle:motionStyle,
			isAutoMotionStyle:true,
			backgroundId:'none',
		}

		let canHaveDeviceFrame = false
		if(label=='Google Chrome'){
			canHaveDeviceFrame = true
		}

		if(canHaveDeviceFrame){
			const orgScreenVideoStyle = getOrgScreenVideoStyle()
			if(orgScreenVideoStyle && orgScreenVideoStyle.defaultColorFrame){
				metadata.deviceFrame='color'
			}
		}

		if(isDevice){
			const deviceDimensions = await getDeviceDimensionsForCaptureId(captureId)
			metadata.deviceWidth = deviceDimensions.width 
			metadata.deviceHeight = deviceDimensions.height 
		}

		let newClip = {
			id: randomID(),
			captureId: captureId,
			isDeviceRecording:isDevice,
			type: 'video',
			isScreenRecording:isScreenRecording?true:false,
			startTime: currentTime,
			duration: duration,
			zIndex: 0,
			metadata:metadata
		};
		return newClip
	}

