import React, {useRef, useState, useEffect} from 'react';
import Icon from '../../misc/Icon';
import ReactPlayer from 'react-player/file';
import VideoPlayerControls from './VideoPlayerControls';
import VideoPlayerPrePlayControls from './VideoPlayerPrePlayControls'
import VideoPlayerSubtitles from './VideoPlayerSubtitles'
import Hls from 'hls.js';
import mux from "mux-embed";

const MUX_DATA_ENV_KEY = 'i82d6jem8j27egsirrokd61ds'

function VideoPlayer({playbackId, posterTime, videoTitle}) {
  
  const playbackUrl = `https://stream.mux.com/${playbackId}.m3u8`  
  const playerRef = useRef(null);
  const hlsRef = useRef(null);
  const muxMonitorRef = useRef(null);

  useEffect(() => {
    let hls;

    if (Hls.isSupported() && playerRef.current) {
      const video = playerRef.current.getInternalPlayer();
      
      hls = new Hls();
      hlsRef.current = hls;
      hls.loadSource(playbackUrl);
      hls.attachMedia(video);
      hls.config.maxBufferLength = 60;
      hls.config.abrEwmaDefaultEstimate = 10000000;
      hls.config.abrBandWidthFactor = 0.95;
      hls.config.abrBandWidthUpFactor = 0.95;

      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        hls.startLevel = hls.levels.length - 1;
      });

      // Setup Mux monitoring only once per video element
      if (!muxMonitorRef.current) {
        const initTime = mux.utils.now();
        muxMonitorRef.current = mux.monitor(video, {
          debug: false,
          hlsjs: hls,
          Hls,
          data: {
            env_key: MUX_DATA_ENV_KEY,
            player_name: "Yarn Static Player",
            player_init_time: initTime,
            video_id: playbackId,
            video_title: videoTitle                  
          }
        });
      }
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
      if (muxMonitorRef.current) {
        muxMonitorRef.current.destroy();
        muxMonitorRef.current = null;
      }
      if (playerRef.current) {
        const video = playerRef.current.getInternalPlayer();
        if (video) {
          video.src = "";
          video.load();
        }
      }
    };
  }, [playbackUrl, playbackId, videoTitle]);

  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [visualVolume, setVisualVolume] = useState(1);
  const [muted, setMuted] = useState(false);  
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0); // Between 0 and 1
  const [progressSeconds, setProgressSeconds] = useState(0);
  const [hasPlayed, setHasPlayed] = useState(false);
  const [seeking, setSeeking] = useState(false);
  const [showCaptions, setShowCaptions] = useState(true);
  const [lastPlayPauseChange, setLastPlayPauseChange] = useState(Date.now());

  const handlePlay = () => {
    setPlaying(true);
    setHasPlayed(true);
    setLastPlayPauseChange(Date.now());
  };

  const handlePause = () => {
    setPlaying(false);
    setLastPlayPauseChange(Date.now());
  };

  const handleEnded = () => {
    setPlaying(false);
    // Reset progress if needed
  };

  const handleDuration = (duration) => {
    setDuration(duration);
  };

  const handleProgress = (state) => {
    if (!seeking) {
      setProgress(state.played);
      setProgressSeconds(state.playedSeconds);
    }
  };

  const handleSeekChange = (newValue) => {
    const newProgress = parseFloat(newValue);
    const newProgressSeconds = newProgress * duration;
    if (playerRef.current) {
      playerRef.current.seekTo(newProgress);
    }
    setProgress(newProgress);
    setProgressSeconds(newProgressSeconds);
  };

  const handleSeekMouseDown = () => {
    setSeeking(true);    
  };

  const handleSeekMouseUp = (newValue) => {
    const newProgress = parseFloat(newValue);
    const newProgressSeconds = newProgress * duration;
    if (playerRef.current) {
      playerRef.current.seekTo(newProgress);
    }
    setProgress(newProgress);
    setProgressSeconds(newProgressSeconds);
    setSeeking(false);    
  };

  // Ready handler
  const handlePlayerReady = () => {
    console.log('Player is ready');
  };

  let time = 4 
  if(posterTime){
    time = posterTime
  }

  const posterImageURL = `https://image.mux.com/${playbackId}/thumbnail.webp?time=${time}`

  //

  const handleVolumeChange = (newVolume) => {
    setVolume(newVolume);
    setVisualVolume(newVolume);
    setMuted(newVolume === 0);
  };

  const handleToggleMute = () => {
    setMuted(!muted);
    if (muted) {
      setVolume(visualVolume === 0 ? 1 : visualVolume);
    }
  };

  // Mouse States  
  const [mouseMovedAt, setMouseMovedAt] = useState(null);

  const handleMouseMove = () => {
    setMouseMovedAt(Date.now());
  };

  const isShortMouseMove = mouseMovedAt && (Date.now() - mouseMovedAt < 2000);
  const isLongMouseMove = mouseMovedAt && (Date.now() - mouseMovedAt < 5000);

  useEffect(() => {
    let timer;
    if (mouseMovedAt) {
      timer = setTimeout(() => {
        setMouseMovedAt(null);
      }, 5000);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [mouseMovedAt]);

  const shouldHideControls = () => {
    const timeSinceLastPlayPauseChange = Date.now() - lastPlayPauseChange;
    return !isShortMouseMove && playing && timeSinceLastPlayPauseChange > 2000;
  };

  const hideControls = shouldHideControls();
  const hidePointer = !isLongMouseMove && playing

    const [isFullscreen, setIsFullscreen] = useState(false);

const containerRef = useRef(null);

const handleToggleFullscreen = () => {
  if (!document.fullscreenElement) {
    if (containerRef.current.requestFullscreen) {
      containerRef.current.requestFullscreen();
    } else if (containerRef.current.webkitRequestFullscreen) {
      containerRef.current.webkitRequestFullscreen();
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  }
};

  const handleFullscreenChange = () => {
    setIsFullscreen(!!document.webkitFullscreenElement);
  };

  const handleDoubleClick = (event) => {    
    handleToggleFullscreen();
  };


  return (
    <div 
      ref={containerRef}
      className='videoPlayer'      
      onMouseMove={handleMouseMove}      
      data-hide-pointer={hidePointer ? true : false}
      data-fullscreen={isFullscreen}      
    >
      {!hasPlayed && 
        <div className='videoPlayer-posterImageContainer'>
          <img src={posterImageURL} className='videoPlayer-posterImage' />
        </div>
      }
      <ReactPlayer
        ref={playerRef}
        className='videoPlayer-video'
        width='100%'
        height='100%'
        url={playbackUrl}
        playing={playing}        
        progressInterval={50}
        volume={seeking || muted ? 0 : volume}
        muted={seeking || muted}
        onPlay={handlePlay}
        onPause={handlePause}
        onEnded={handleEnded}
        onDuration={handleDuration}
        onProgress={handleProgress}
        onReady={handlePlayerReady}
        onFullscreenChange={handleFullscreenChange}
        controls={false}
      />

      {/*{!hasPlayed &&
        <VideoPlayerPrePlayControls 
          duration={duration}
        />
        {hasPlayed || progress > 0 && 
      }*/}

      
      {showCaptions && progress > 0 &&
        <VideoPlayerSubtitles
          progressSeconds={progressSeconds}
          showingControls={!hideControls}
          playbackId={playbackId}          
        />
      }

      <VideoPlayerControls 
        showControls={!hideControls}
        playbackId={playbackId}
        playing={playing}
        onPlayPauseClick={() => {
          setPlaying(!playing);
          setLastPlayPauseChange(Date.now());
        }}
        onVolumeChange={(e) => setVolume(parseFloat(e.target.value))}        
        progress={progress}
        onSeek={handleSeekChange}
        onSeekMouseDown={handleSeekMouseDown}
        onSeekMouseUp={handleSeekMouseUp}
        duration={duration}
        showCaptions={showCaptions}
        toggleShowCaptions={()=> setShowCaptions(!showCaptions)}        
        volume={visualVolume}
        muted={muted}
        onVolumeChange={handleVolumeChange}
        onToggleMute={handleToggleMute}
        handleToggleFullscreen={handleToggleFullscreen}
        isFullscreen={isFullscreen}
      />
      

      <button onDoubleClick={handleDoubleClick} onClick={() => setPlaying(!playing)} className='videoPlayer-bgPlayPauseBtn' />

    </div>
  );
}

export default VideoPlayer;