import React, { useRef, useEffect, useState } from 'react';
import { Text } from '@react-three/drei';
import { SpringAnimator } from '../../../three/utils/animations/SpringAnimator';
import CHNumberValue from './CHNumberValue'
import CHNumberPreLabel from './CHNumberPreLabel'
import CHNumberPostLabel from './CHNumberPostLabel'
import CHNumberValueCountUp from './CHNumberValueCountUp'

const FPS = 60; // Frames per second

function countDecimals(value) {
  if (value === undefined || value === null || isNaN(value)) return 0;
  if (Math.floor(value) === value) return 0;
  return value.toString().split(".")[1]?.length || 0;
}


const CHNumber = ({ time, targetValue, prefix, suffix, postLabel, preLabel, animationType, isHidden, fontFamily, numberValueTextColor, numberLabelTextColor}) => {
  
  // console.log(`number text color`)
  // console.log(numberLabelTextColor)
  //
  // FONT SIZES
  let valueFontSize = 360;
  let preLabelFontSize = 80
  let postLabelFontSize = 125

  //
  // FONT WEIGHTS
  let valueFontWeight = "Bold"
  let preLabelFontWeight = 'Semibold';
  let postLabelFontWeight = 'Semibold';

  //
  // MAX OPACITIES
  let valueMaxOpacity = 0.95
  let preLabelMaxOpacity = 0.8
  let postLabelMaxOpacity = 0.8

  if(isHidden){
    valueMaxOpacity = 0
    preLabelMaxOpacity = 0
    postLabelMaxOpacity = 0
  }

  //
  // Y POSITIONS

  let valueYPos
  let postLabelYPos
  let preLabelYPos

  if(!preLabel && !postLabel){
    valueYPos = 20
  }

  if(!preLabel && postLabel){
    valueYPos = 100
    postLabelYPos = -220
  }

  if(preLabel && !postLabel){
    preLabelYPos = 180
    valueYPos = -20    
  }

  if(postLabel && preLabel){    
    preLabelYPos = 225
    valueYPos = 34
    postLabelYPos = -280
  }

  // greenlite specific
  if(fontFamily === 'YRaptor' && postLabel && preLabel){
    valueYPos = 10
  }



  return (
    <group position={[0,0,0]}>

      <CHNumberValue
        targetValue={targetValue}
        prefix={prefix}
        suffix={suffix}
        fontSize={valueFontSize}
        fontWeight={valueFontWeight}
        yPos={valueYPos}
        time={time}
        animationType={animationType}
        isHidden={isHidden}
        fontFamily={fontFamily}
        maxOpacity={valueMaxOpacity}
        color={numberValueTextColor}
      />

      {preLabel && 
        <CHNumberPreLabel
          preLabel={preLabel}        
          fontSize={preLabelFontSize}
          fontWeight={preLabelFontWeight}
          fontFamily={fontFamily}
          targetYPos={preLabelYPos}
          maxOpacity={preLabelMaxOpacity}
          time={time}
          animationType={animationType}
          isHidden={isHidden}     
          color={numberLabelTextColor}         
        />
      }

      
      {postLabel && 
        <CHNumberPostLabel
          postLabel={postLabel}        
          fontSize={postLabelFontSize}
          fontWeight={postLabelFontWeight}
          fontFamily={fontFamily}
          targetYPos={postLabelYPos}
          maxOpacity={postLabelMaxOpacity}
          time={time}
          animationType={animationType}
          isHidden={isHidden}   
          color={numberLabelTextColor}         
        />
      }
      
    </group>
  );
};

export default CHNumber;
