import store from '../store'


//org name unless its yarn in which case replace with "Acme"

export function getOrgNameLabel() {	
	const state = store.getState()
	let label
	const organization=state.organization
	if(organization){
		label=organization.name
	}
	if(label=='yarn'){
		label= 'Acme'
	}

	return label 
}
