import React, { useRef, useEffect, useState, useMemo } from 'react';
import * as THREE from 'three';
import { useThree } from '@react-three/fiber';

const ExportBasicAlphaVideoMaterial = ({ codecVideoClip, opacity }) => {
  const { invalidate } = useThree();
  const materialRef = useRef();
  const videoTextureRef = useRef();
  const alphaTextureRef = useRef();
  const [updateTrigger, setUpdateTrigger] = useState(0);

  console.log('*************************EXPORT SCREEN MATERIAL ALPHAAAAAAAA');

  useEffect(() => {
    if (!videoTextureRef.current) {
      videoTextureRef.current = new THREE.Texture();
      videoTextureRef.current.minFilter = THREE.LinearFilter;
      videoTextureRef.current.magFilter = THREE.LinearFilter;
      videoTextureRef.current.format = THREE.RGBAFormat;
      videoTextureRef.current.colorSpace = THREE.SRGBColorSpace;
      console.log('New video texture created:', videoTextureRef.current);
    }

    if (!alphaTextureRef.current) {
      alphaTextureRef.current = new THREE.Texture();
      alphaTextureRef.current.minFilter = THREE.LinearFilter;
      alphaTextureRef.current.magFilter = THREE.LinearFilter;
      alphaTextureRef.current.format = THREE.RedFormat;
      alphaTextureRef.current.colorSpace = THREE.NoColorSpace;
      console.log('New alpha texture created:', alphaTextureRef.current);
    }

    if (codecVideoClip) {
      console.log('Setting external textures and callback');
      codecVideoClip.setExternalTexture(videoTextureRef.current);
      codecVideoClip.setExternalAlphaTexture(alphaTextureRef.current);
      codecVideoClip.setTextureUpdatedCallback(() => {
        console.log('Texture updated callback called');
        console.log('Current video texture image:', videoTextureRef.current.image);
        console.log('Current alpha texture image:', alphaTextureRef.current.image);
        setUpdateTrigger(prev => prev + 1);
        invalidate();
      });
    }

    return () => {
      if (codecVideoClip) {
        codecVideoClip.setExternalTexture(null);
        codecVideoClip.setExternalAlphaTexture(null);
        codecVideoClip.setTextureUpdatedCallback(null);
      }
    };
  }, [codecVideoClip, invalidate]);

  const material = useMemo(() => {
    console.log('Creating new ShaderMaterial');
    return new THREE.ShaderMaterial({
      uniforms: {
        videoTexture: { value: videoTextureRef.current },
        alphaTexture: { value: alphaTextureRef.current },
        opacity: { value: opacity },
      },
      vertexShader: `
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        uniform sampler2D videoTexture;
        uniform sampler2D alphaTexture;
        uniform float opacity;
        varying vec2 vUv;
        void main() {
          vec4 videoColor = texture2D(videoTexture, vUv);
          float alpha = texture2D(alphaTexture, vUv).r;
          gl_FragColor = vec4(videoColor.rgb, alpha * opacity);
        }
      `,
      transparent: true,
    });
  }, [opacity, updateTrigger]);

  useEffect(() => {
    if (materialRef.current) {
      materialRef.current.uniforms.videoTexture.value = videoTextureRef.current;
      materialRef.current.uniforms.alphaTexture.value = alphaTextureRef.current;
      materialRef.current.uniforms.opacity.value = opacity;
      materialRef.current.needsUpdate = true;
    }
  }, [material, opacity]);

  return <primitive object={material} ref={materialRef} attach="material" />;
};

export default ExportBasicAlphaVideoMaterial;