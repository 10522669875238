const ZoomClipNodeSpec = {
	attrs: {
		id:{default:'defaultID'},
		type:{default:'zoom'},
		relativeStartTime: {default:0},
		//startTime: {default:0},
		duration:  {default:0},
		metadata: { default: [] },
		zIndex:  {default:2},
		sceneId:{default:'defaultSceneId'}
	},
};

 export default ZoomClipNodeSpec
 