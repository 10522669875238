/**
 * Calculates zoom and offset values to properly frame a face in a video.
 *
 * @param {Object} params - The parameters for cropping.
 * @param {number} params.originalWidth - Original video width in pixels.
 * @param {number} params.originalHeight - Original video height in pixels.
 * @param {number} params.targetWidth - Desired display width in pixels.
 * @param {number} params.targetHeight - Desired display height in pixels.
 * @param {Object} params.faceBox - AWS Rekognition face bounding box (Left, Top, Width, Height as percentages).
 * @param {number} [params.faceVerticalPosition=0.3] - Target relative vertical position of the face from the top (0.3 = upper third).
 * @param {number} [params.targetPaddingRatio=0.5] - Padding around the face as a ratio of the face size.
 * @param {number} [params.targetZoomRatio=0.3] - Maximum zoom ratio relative to the original height.
 * @returns {Object} - Crop parameters as relative percentages: { cropX, cropY, cropWidth, cropHeight }.
 */
export function calculateWebcamCrop({
  originalWidth,
  originalHeight,
  targetWidth,
  targetHeight,
  faceBox,
  zoomIn = false,
}) {


  const faceVerticalPosition = 0.3;
  const targetPaddingRatio = 0.4;
  const targetZoomRatio = 0.3;

  const disableCrop = true

  if (!faceBox || disableCrop) {
    // If no face, create largest possible crop while maintaining aspect ratio
    const targetAspectRatio = targetWidth / targetHeight;
    const originalAspectRatio = originalWidth / originalHeight;

    if (targetAspectRatio > originalAspectRatio) {
      // Target is wider than original - crop height
      const cropWidth = 1;
      const cropHeight = originalWidth / targetAspectRatio / originalHeight;
      const cropY = (1 - cropHeight) / 2;
      return {
        cropX: 0,
        cropY: cropY,
        cropWidth: cropWidth,
        cropHeight: cropHeight,
      };
    } else {
      // Target is taller than original - crop width
      const cropHeight = 1;
      const cropWidth = (originalHeight * targetAspectRatio) / originalWidth;
      const cropX = (1 - cropWidth) / 2;
      return {
        cropX: cropX,
        cropY: 0,
        cropWidth: cropWidth,
        cropHeight: cropHeight,
      };
    }
  }

  // Extract the bounding box from AWS Rekognition response.
  const boundingBox = faceBox.boundingBox;

  // Calculate the target aspect ratio.
  const targetAspectRatio = targetWidth / targetHeight;

  // Convert percentage-based bounding box to absolute pixels.
  const facePixels = {
    left: boundingBox.Left * originalWidth,
    top: boundingBox.Top * originalHeight,
    width: boundingBox.Width * originalWidth,
    height: boundingBox.Height * originalHeight,
  };

  // Calculate the center coordinates of the face.
  const faceCenterX = facePixels.left + facePixels.width / 2;
  // const faceCenterY = facePixels.top + facePixels.height / 2;

  // Calculate minimum required dimensions with padding.
  const minWidth = facePixels.width * (1 + 2 * targetPaddingRatio);
  const minHeight = facePixels.height * (1 + 2 * targetPaddingRatio);

  // Enforce maximum zoom ratio based on original height.
  const maxFaceHeight = originalHeight * targetZoomRatio;
  let scaledMinWidth = minWidth;
  let scaledMinHeight = minHeight;

  if (facePixels.height > maxFaceHeight) {
    const scaleFactor = maxFaceHeight / facePixels.height;
    scaledMinWidth *= scaleFactor;
    scaledMinHeight *= scaleFactor;
  }

  // Initialize crop dimensions.
  let cropWidth, cropHeight;

  // Determine crop dimensions based on target aspect ratio.
  if (targetAspectRatio >= 1) {
    // Wide or square aspect ratio.
    cropHeight = Math.max(scaledMinHeight, minHeight);
    cropWidth = cropHeight * targetAspectRatio;
  } else {
    // Tall aspect ratio.
    cropWidth = Math.max(scaledMinWidth, minWidth);
    cropHeight = cropWidth / targetAspectRatio;
  }

  // Adjust crop dimensions to stay within original frame boundaries.
  cropWidth = Math.min(cropWidth, originalWidth);
  cropHeight = Math.min(cropHeight, originalHeight);

  // Re-adjust crop dimensions if aspect ratio constraints caused overflow.
  if (cropWidth / cropHeight !== targetAspectRatio) {
    cropWidth = cropHeight * targetAspectRatio;
    if (cropWidth > originalWidth) {
      cropWidth = originalWidth;
      cropHeight = cropWidth / targetAspectRatio;
    }
  }

  // Calculate desired Y position to place the face at the specified vertical position within the crop.
  const desiredFaceY = cropHeight * faceVerticalPosition;
  let cropY = facePixels.top - desiredFaceY;

  // Ensure the crop Y position doesn't exceed image boundaries.
  cropY = Math.max(0, Math.min(cropY, originalHeight - cropHeight));

  // Center the crop horizontally around the face center.
  let cropX = faceCenterX - cropWidth / 2;

  // Ensure the crop X position doesn't exceed image boundaries.
  cropX = Math.max(0, Math.min(cropX, originalWidth - cropWidth));
  const cropBox = {
    cropX: cropX / originalWidth,
    cropY: cropY / originalHeight,
    cropWidth: cropWidth / originalWidth,
    cropHeight: cropHeight / originalHeight,
  };

  if (zoomIn) {
    return zoomInCrop(
      cropBox,
      faceBox.boundingBox
    );
  }
  return cropBox;
}

function zoomInCrop(cropBox, faceBoundingBox, paddingRatio = 0.1) {
  // Calculate minimum required dimensions with padding
  const minWidthRatio = faceBoundingBox.Width * (1 + 2 * paddingRatio);
  const minHeightRatio = faceBoundingBox.Height * (1 + 2 * paddingRatio);

  // Calculate maximum zoom that keeps the face in frame with padding
  const widthZoomFactor = minWidthRatio / cropBox.cropWidth;
  const heightZoomFactor = minHeightRatio / cropBox.cropHeight;
  const zoomFactor = Math.max(widthZoomFactor, heightZoomFactor, 0.9); // Don't zoom out (max 0.9)

  // Calculate new dimensions
  const newWidth = cropBox.cropWidth * zoomFactor;
  const newHeight = cropBox.cropHeight * zoomFactor;

  // Calculate new positions to maintain face position
  const faceCenterX = faceBoundingBox.Left + faceBoundingBox.Width / 2;
  const faceCenterY = faceBoundingBox.Top + faceBoundingBox.Height / 2;
  const newX = faceCenterX - newWidth / 2;
  const verticalOffset = faceCenterY - cropBox.cropY;
  const newY = faceCenterY - verticalOffset * zoomFactor;

  return {
    cropX: newX,
    cropY: newY,
    cropWidth: newWidth,
    cropHeight: newHeight,
  };
}
