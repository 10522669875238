
export const findImmediateParentLayoutGroup = (itemId, layoutGroup) => {
    const search = (currentGroup, depth) => {
        // Check if the current group directly contains the element
        if (depth > 0 && currentGroup.children.some(child => child.id === itemId)) {
            return currentGroup;
        }
        // Search in child layout groups
        for (const child of currentGroup.children) {
            if (child.isLayoutGroup) {
                const result = search(child, depth + 1);
                if (result) {
                    return result;
                }
            }
        }
        return null;
    };

    return search(layoutGroup, 0);  // Start at 0 for the root group
};
