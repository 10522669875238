import React, { useState, useEffect } from 'react';
import * as Popover from '@radix-ui/react-popover';
import sanityClient from './sanityClient'; // path to your sanityClient file
import moment from 'moment';
import UpdatesMajorReleaseBanner from './UpdatesMajorReleaseBanner'

// Custom function to format dates
const formatDate = (date) => {
  const now = moment();
  const then = moment(date);
  const diffMinutes = now.diff(then, 'minutes');
  const diffHours = now.diff(then, 'hours');
  const diffDays = now.diff(then, 'days');
  
  // Get the start of the current week (Sunday)
  const startOfWeek = now.clone().startOf('week');

  if (diffMinutes < 1) return 'just now';
  else if (diffMinutes === 1) return '1 min ago';
  else if (diffMinutes < 60) return `${diffMinutes} mins ago`;
  else if (diffHours === 1) return '1 hr ago';
  else if (diffHours < 24) return `${diffHours} hrs ago`;
  else if (diffDays === 1) return 'yesterday';
  else if (then.isSameOrAfter(startOfWeek)) {
    // If it's this week (not before Sunday) but not yesterday, return the day name
    return then.format('dddd');
  }
  else if (now.year() === then.year()) {
    // If it's this year, return Month Day
    return then.format('MMMM D');
  }
  else {
    // If it's a different year, return Month Day, Year
    return then.format('MMMM D, YYYY');
  }
};


const lastChecked = moment('2024-10-07T18:00:00');

const UpdatesPopover = ({ showModal, openModal, closeModal, children }) => {
  const [posts, setPosts] = useState([]);
  const [forceMount, setForceMount] = useState(false);

   useEffect(() => {
    const fetchPosts = async () => {
      const query = '*[_type == "post"]{title, slug, "publishedAt": publishedAt}';
      const fetchedPosts = await sanityClient.fetch(query);

      // Sort posts by 'publishedAt' in descending order
      const sortedPosts = fetchedPosts.sort((a, b) => moment(b.publishedAt).diff(moment(a.publishedAt)));
      setPosts(sortedPosts);
    };

    fetchPosts();
  }, []);


  useEffect(() => {
    if (showModal) {
      setForceMount(true);
    } else {
      setForceMount(false);
    }
  }, [showModal]);

  const openChangelogWindow = (slug) => {
    window.ipcRenderer.send('open-changelog-window', slug);
    closeModal()
  };


  return (
    <React.Fragment>

      <Popover.Root open={showModal} modal={true} onOpenChange={(open) => {
          if (!open) {
            closeModal();
          }
        }}>
        <Popover.Trigger asChild>
          {children}
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            className="editorPopover editorPopover--updatesPopover forceDarkTheme"            
            // forceMount={showModal ? true : forceMount}
            side="top"
            sideOffset={8}
            align="start"
            alignOffset={2}
          >
          	<div className='editorPopover-header'>
        			<div className='editorPopover-header-label'>
        				Updates          			
		        	</div>      
		      	</div>        

            {posts.map(post => {
              const publishedAt = moment(post.publishedAt);
              const isUnread = publishedAt.isAfter(lastChecked);
              return (
                <button 
                  key={post.slug.current}
                  className='editorPopover--updates-row'
                  data-state={isUnread ? "unread" : "read"}
                  onClick={() => openChangelogWindow(post.slug.current)}
                >
                  
                  <div className='editorPopover--updates-row-primaryLabel'>
                    {isUnread && 
                      <div className='editorPopover--updates-row-primaryLabel-newTab'>
                        New
                      </div>
                    }
                    <div className='editorPopover--updates-row-primaryLabel-label'>
                    {post.title}
                    </div>                    
                  </div>
                  <div className='editorPopover--updates-row-date'>
                   {formatDate(post.publishedAt)} 
                  </div>
                </button>
              );
            })}

          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </React.Fragment>
  );
};

export default UpdatesPopover;
