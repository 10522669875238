const calculateValue = (scalar, defaultValue, min, max) => {
  if (scalar === 1) return defaultValue;
  if (scalar < 1) {
    return min + (defaultValue - min) * scalar;
  } else {
    return defaultValue + (max - defaultValue) * (scalar - 1);
  }
};

export const startTransitionConfigs = {
  none: (scalar) => ({
    label: "None",
    startFrom: {
      opacity: 1,      
    },
    startTo: {
      opacity: 1,      
    },
  }), 
  fade: (scalar) => ({  
    label: "Fade In",
    startFrom: {
      opacity: 0,      
    },
    startTo: {
      opacity: 1,      
    },
  }),
  fadeAndMoveUp: (scalar) => ({ 
    label: "Fade Up",
    startFrom: {
      opacity: 0,
      positionY: -1 * calculateValue(scalar, 20, 0, 100),
    },
    startTo: {
      opacity: 1,
      positionY: 0,
    },
  }),
  fadeAndMoveDown: (scalar) => ({ 
    label: "Fade Down",
    startFrom: {
      opacity: 0,
      positionY: calculateValue(scalar, 20, 0, 100),
    },
    startTo: {
      opacity: 1,
      positionY: 0,
    },
  }),
  fadeAndMoveLeft: (scalar) => ({ 
    label: "Fade Right",
    startFrom: {
      opacity: 0,
      positionX: -1 * calculateValue(scalar, 40, 0, 100),    
    },
    startTo: {
      opacity: 1,
      positionX: 0,
    },
  }),
  fadeAndMoveRight: (scalar) => ({ 
    label: "Fade Left",
    startFrom: {
      opacity: 0,
      positionX: calculateValue(scalar, 40, 0, 100),    
    },
    startTo: {
      opacity: 1,
      positionX: 0,
    },
  }),
  fadeAndScaleUp: (scalar) => ({   
    label: "Scale Up",
    startFrom: {
      opacity: 0,
      scale: calculateValue(scalar, 0.8, 0.5, 1),    
    },
    startTo: {
      opacity: 1,
      scale: 1,
    },
  }),
  fadeAndScaleDown: (scalar) => ({ 
    label: "Scale Down",
    startFrom: {
      opacity: 0,
      scale: calculateValue(scalar, 1.5, 1, 2.5),      
    },
    startTo: {
      opacity: 1,
      scale: 1,
    },
  }),
  
};