

export function findFirstWordInChunk(doc, chunkPos) {
	const chunk = doc.nodeAt(chunkPos - 1); // -1 because chunkPos is after the opening tag
	if (!chunk) return null;
	let firstWordPos = null;
	let firstWordSize = null;
	chunk.forEach((node, offset) => {
		if (!firstWordPos && node.type.name === 'webcamTranscriptWord') {
			firstWordPos = chunkPos + offset;
			firstWordSize = node.nodeSize;
		}
	});
	return firstWordPos ? firstWordPos + firstWordSize - 2 : null;
}
