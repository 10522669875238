import React, {useState,useEffect} from 'react'
import Icon from '../../../misc/Icon'
import * as Tooltip from '@radix-ui/react-tooltip';
import { ToggleGroup, ToggleGroupBtn } from '../../../uiKit/ToggleUI';
import { SmallTabGroup, SmallTab } from '../../../uiKit/SmallTabsUI';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'

 const motionOptions = [
    { value: 'auto', label: 'Auto' },
    { value: 'smooth', label: 'Smooth' },
    { value: 'bouncy', label: 'Bouncy' },
    { value: 'molasses', label: 'Molasses' },
    { value: 'slow', label: 'Slow' },
    { value: 'zippy', label: 'Zippy' },     
  ];

  const sampleData = [    
    'Up to 60% faster',
    '90% of US adults have bought from Stripe businesses',    
    'Over 80% increase in positive call outcomes',    
    '85% Fortune 500',
  ];


function ChartsPanelNumber({inputData, setInputData, animationType, setAnimationType,metadata,setMotionStyle}) {  
  


  const [currentIndex, setCurrentIndex] = useState(0);

   // Handler to update the input data
  const handleInputChange = (e) => {
    setInputData(e.target.value);
  };

  const handleSampleDataClick = () => {
    setInputData(sampleData[currentIndex]);
    const nextIndex = currentIndex + 1 === sampleData.length ? 0 : currentIndex + 1;
    setCurrentIndex(nextIndex);
  };

  const [animateActiveTab, setAnimateActiveTab] = useState('in')

  
  let motionStyle = metadata.motionStyle

  if(metadata.isAutoMotionStyle){
    motionStyle='Auto'
  }

  return (
    <>
      <div className='rightPanel-row'>        
        <div className='rightPanel--charts-numberInput'>
          <input 
            type="text" 
            placeholder="ChartData data"
            className='rightPanel--charts-numberInput-input'
            id="chartsInput" 
            value={inputData} 
            onChange={handleInputChange}
          />
          {/*}
          <div className='rightPanel--charts-numberInput-ui'>
            <button className='rightPanel--charts-numberInput-ui-sample'>
              <Icon name='shuffleMedium' />
            </button>
          </div>
          */}
        </div>
      </div>

      <button className='uiButton' onClick={handleSampleDataClick}>
        <div className='uiButton-label'>
          Sample Data
        </div> 
      </button>

      <div className='rightPanel-separator' />

      <div className='rightPanel-row rightPanel-row--tabAndDropdown'>
                
        <SmallTabGroup>
          <SmallTab
            label='Animate In'
            active={true}
            //handleClick={() => setAnimateActiveTab('in')}              
            nullBar
          />
          {/*<SmallTab
            label='Animate Out'
            active={animateActiveTab === 'out'}
            handleClick={() => setAnimateActiveTab('out')}                              
          /> 
          */}         
        </SmallTabGroup>
        
      {/*}
        <DropdownMenu.Root modal={false}>
          <DropdownMenu.Trigger asChild>
            <button className='uiButton uiButton--motionStyle'>
              <div className='uiButton-iconContainer'>
                <Icon name='stopwatch' />
              </div>                
              <div className='uiButton-label'>
                {motionStyle}                
              </div>                
            </button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content             
            align="end"
            alignOffset={-48}
            className='dropdownMenu dropdownMenu--selectMotionPanel forceDarkTheme'
          >            
            <div className='dropdownMenu-explainer'>
              Slide animation settings.
            </div>
            {motionOptions.map(option => (
              <DropdownMenu.Item key={option.value} onSelect={() => setMotionStyle(option.value)} className={motionStyle === option.label ? 'dropdownMenu-item--active' : ''}>
                {option.label}
              </DropdownMenu.Item>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Root>  
        */} 
      
      </div>        
          
      <div className='rightPanel-row rightPanel-row--transitionBy rightPanel-row--transitionBy--empty' />                                        

      <button 
        data-state={animationType === 'none' ? "active" : "inactive"}            
        onClick={() => setAnimationType('none')}
        className='uiButton uiButton--preview'
      >
        <div className='uiButton-label'>
          None
        </div>            
      </button>

      <button 
        data-state={animationType === 'countUp' ? "active" : "inactive"}            
        onClick={() => setAnimationType('countUp')}
        className='uiButton uiButton--preview'
      >
        <div className='uiButton-label'>
          Count Up
        </div>            
      </button>          

      <button 
        data-state={animationType === 'fadeIn' ? "active" : "inactive"}            
        onClick={() => setAnimationType('fadeIn')}
        className='uiButton uiButton--preview'
      >
        <div className='uiButton-label'>
          Fade In
        </div>            
      </button>

      <button 
        data-state={animationType === 'scaleUp' ? "active" : "inactive"}            
        onClick={() => setAnimationType('scaleUp')}
        className='uiButton uiButton--preview'
      >
        <div className='uiButton-label'>
          Scale Up
        </div>            
      </button>






    </>
  )
}

export default ChartsPanelNumber;

