import {findParentNode} from './findParentNode'
import { findNextNodePosition } from './findNextNodePosition';
import { TextSelection } from 'prosemirror-state';
function storeAndRemoveWord(tr, group, groupPos, wordNode, wordPos) {
    const skippedWord = {
        text: wordNode.textContent,
        startTime: wordNode.attrs.startTime,
        endTime: wordNode.attrs.endTime,
        originalWordIndex: wordNode.attrs.originalWordIndex
    };

    // Get the current group from tr.doc
    const currentGroup = tr.doc.nodeAt(groupPos);
    const currentSkippedWords = currentGroup?.attrs.skippedWords || [];
    
    // Add to group's skippedWords array
    const newSkippedWords = [...currentSkippedWords, skippedWord];
   
    // Update group attributes
    tr.setNodeMarkup(groupPos, null, {
        ...currentGroup.attrs,
        skippedWords: newSkippedWords
    });

    // Remove the word from the document
    tr.delete(wordPos, wordPos + wordNode.nodeSize);
}



export function handleBackspaceInRecordingChunk(view, event) {
    const { state } = view;
    const { selection, doc } = state;
    const { $from, $to } = selection;
    
    const parent = findParentNode(
        node => node.type.name === 'transcriptGroup',
        selection
    );
    
    if (!parent || parent.node.attrs.groupType !== 'webcam-recording') {
        return false;
    }

    if (!selection.empty) {
        const tr = state.tr;
        let foundWords = false;
        let firstWordPos = null;
        let wordsToSkip = [];

        // Find all words in the selection
        tr.doc.nodesBetween($from.pos, $to.pos, (node, pos) => {
            if (node.type.name === 'webcamTranscriptWord') {
                foundWords = true;
                if (firstWordPos === null) {
                    firstWordPos = pos;
                }
                wordsToSkip.push({ node, pos });
            }
        });

        if (foundWords) {
            // Collect all words first
            const wordsToStore = wordsToSkip.map(({ node }) => ({
                text: node.textContent,
                startTime: node.attrs.startTime,
                endTime: node.attrs.endTime,
                originalWordIndex: node.attrs.originalWordIndex
            }));

            // Update group attributes once with all words
            const currentSkippedWords = parent.node.attrs.skippedWords || [];
            tr.setNodeMarkup(parent.pos, null, {
                ...parent.node.attrs,
                skippedWords: [...currentSkippedWords, ...wordsToStore]
            });

            // Then remove words in reverse order
            for (let i = wordsToSkip.length - 1; i >= 0; i--) {
                const { node, pos } = wordsToSkip[i];
                tr.delete(pos, pos + node.nodeSize);
            }

            // Set cursor position
            if (firstWordPos !== null) {
                tr.setSelection(TextSelection.create(tr.doc, firstWordPos));
            }
            
            view.dispatch(tr);
            event.preventDefault();
            return true;
        }
    }

    // Single word handling
    const wordParent = findParentNode(
        node => node.type.name === 'webcamTranscriptWord',
        selection
    );

    if (wordParent && parent) {
        const tr = state.tr;
        
        // Store and remove the word
        storeAndRemoveWord(
            tr, 
            parent.node, 
            parent.pos, 
            wordParent.node, 
            wordParent.pos
        );

        // Move cursor before the removed word
        tr.setSelection(TextSelection.create(tr.doc, wordParent.pos - 2));
        
        view.dispatch(tr);
        event.preventDefault();
        return true;
    }

    // Rest of the chunk handling code stays the same...
    let currentChunkInfo = {
        chunk: null,
        pos: null,
        isAtStart: false,
        isAtEnd: false,
        previousChunk: null,
        previousChunkPos: null
    };


    let previousChunk = null;
    let previousChunkPos = null;
    doc.nodesBetween(parent.pos, parent.pos + parent.node.nodeSize, (node, pos) => {
        if (node.type.name === 'webcamRecordingChunk') {
            if ($from.pos > pos && $from.pos < (pos + node.nodeSize)) {
                currentChunkInfo.chunk = node;
                currentChunkInfo.pos = pos;
                currentChunkInfo.isAtStart = $from.pos === pos + 1;
                currentChunkInfo.isAtEnd = $from.pos === pos + node.nodeSize - 1;
                currentChunkInfo.previousChunk = previousChunk;
                currentChunkInfo.previousChunkPos = previousChunkPos;
                return false;
            }
            previousChunk = node;
            previousChunkPos = pos;
        }
        return true;
    });

    if (!currentChunkInfo.chunk) {
        return false;
    }

    if (currentChunkInfo.isAtStart) {
        if (currentChunkInfo.previousChunk) {
            const tr = state.tr;
            
            const previousChunkContent = currentChunkInfo.previousChunk.content;
            const currentChunkContent = currentChunkInfo.chunk.content;
            
            const newChunk = currentChunkInfo.previousChunk.type.create(
                currentChunkInfo.previousChunk.attrs,
                previousChunkContent.append(currentChunkContent)
            );

            tr.replaceWith(
                currentChunkInfo.previousChunkPos,
                currentChunkInfo.pos + currentChunkInfo.chunk.nodeSize,
                newChunk
            )
            .setSelection(TextSelection.create(
                tr.doc,
                currentChunkInfo.previousChunkPos + previousChunkContent.size + 1
            ));
            
            view.dispatch(tr);
            event.preventDefault();
            return true;
        } else {
            return false;
        }
    }

    const prevWordPos = findNextNodePosition(
        doc,
        $from.pos,
        currentChunkInfo.pos + 1,
        ['webcamTranscriptWord'],
        'backward'
    );

    if (prevWordPos !== null) {
        view.dispatch(state.tr.setSelection(TextSelection.create(doc, prevWordPos)));
        event.preventDefault();
        return true;
    }

    if (!currentChunkInfo.isAtStart) {
        view.dispatch(state.tr.setSelection(TextSelection.create(doc, currentChunkInfo.pos + 1)));
        event.preventDefault();
        return true;
    }

    return false;
}