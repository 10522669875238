export default function tabReducer(state = [], action) {
  switch (action.type) {
    case 'ADD_TAB':
      return [...state.map(tab => ({ ...tab, active: false })), action.payload];
    case 'REMOVE_TAB':
      return state.filter(tab => tab.id !== action.payload);
    case 'SET_ACTIVE_TAB':
      return state.map(tab => ({
        ...tab,
        active: tab.id === action.payload,
      }));
    default:
      return state;
  }
}