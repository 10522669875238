import React, { useState } from 'react';
import RecordWebcamWindow from '../components/recordWebcamWindow/RecordWebcamWindow'

const RecordWebcamContainer = () => {
 

  return (
    <>
      <RecordWebcamWindow />
    </>
  );
};

export default RecordWebcamContainer;
