//if isDevice then need to use the different metadata.json
async function getDurationForDeviceCaptureId(captureId) {
  let basePath = `deviceRecordings` 
  const metadataPath = `${basePath}/${captureId}/metadata.json`
  const content = await ipcRenderer.invoke('read-file', metadataPath);
  const metadata = JSON.parse(content);
  if(metadata){
    return metadata.durationSeconds
  }else{
    return 10
  }
}

export { getDurationForDeviceCaptureId }


