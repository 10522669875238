import React, { useState } from 'react';
import Icon from '../misc/Icon'; // Adjust the path as needed
import ReactSlider from 'react-slider';


function Slider({sliderValue, updateSliderValue, max, min, inverted, step = 0.01, label, minLabel, maxLabel, showMinus, showPlus, onStartChange, onEndChange}) {

  const [isDragging, setIsDragging] = useState(false);
  
  const handleStartChange = () => {
    setIsDragging(true);
    onStartChange(); // Propagate the start of change event to the parent
  };

  const handleEndChange = () => {
    setIsDragging(false);
    onEndChange(); // Propagate the end of change event to the parent
  };

  const handleSliderChange = (newValue) => {
    if (!isDragging) {
      handleStartChange();
    }
    // Invert the slider value if the inverted prop is true
    const effectiveValue = inverted ? max + min - newValue : newValue;
    updateSliderValue(effectiveValue);
  };

  const displayValue = inverted ? max + min - sliderValue : sliderValue;

  return (    
    <div className='uiSlider'>
      {label && 
        <div className='uiSlider-label'>
          {label}
        </div>
      }
      {minLabel && 
        <div className='uiSlider-edgeLabel uiSlider-edgeLabel--min'>
          {minLabel}
        </div>
      }
      {maxLabel && 
        <div className='uiSlider-edgeLabel uiSlider-edgeLabel--max'>
          {maxLabel}
        </div>
      }

      {showMinus && 
        <div className='uiSlider-edgeIcon uiSlider-edgeIcon--min'>
          <Icon name='minus' />
        </div>
      }
      {showPlus && 
        <div className='uiSlider-edgeIcon uiSlider-edgeIcon--max'>
          <Icon name='plus' />
        </div>
      }


      <div className='uiSlider-slider'>
        <ReactSlider
          className="uiSlider-slider-slider"
          thumbClassName="uiSlider-slider-slider-thumb"
          trackClassName="uiSlider-slider-slider-track"                  
          renderThumb={(props, state) => <div className='uiSlider-slider-slider-thumb-thumb' {...props} />}
          min={min}
          max={max}
          step={step}
          value={displayValue}
          onChange={handleSliderChange}
          onBeforeChange={handleStartChange}
          onAfterChange={handleEndChange}
        />
      </div>
    </div> 
  );
}

export { Slider };
