const calculateValue = (scalar, defaultValue, min, max) => {
  if (scalar === 1) return defaultValue;
  if (scalar < 1) {
    return min + (defaultValue - min) * scalar;
  } else {
    return defaultValue + (max - defaultValue) * (scalar - 1);
  }
};

export const startTransitionConfigs = {
  none: (scalar, imageScaleScalar) => ({
    label: "None",
    startFrom: {
      opacity: 1,      
    },
    startTo: {
      opacity: 1,      
    },
  }),
  fade: (scalar, imageScaleScalar) => ({
    label: "Fade In",
    startFrom: {
      opacity: 0,      
    },
    startTo: {
      opacity: 1,      
    },
  }),
  fadeAndMoveUp: (scalar, imageScaleScalar) => ({  
    label: "Fade Up",
    startFrom: {
      opacity: 0,
      //positionY: -60 * scalar,
      positionY: -1 * calculateValue(scalar * imageScaleScalar, 60, 0, 150),
    },
    startTo: {
      opacity: 1,
      positionY: 0,
    },
  }),
  fadeAndMoveDown: (scalar, imageScaleScalar) => ({  
    label: "Fade Down",
    startFrom: {
      opacity: 0,
      //positionY: 60 * scalar,
      positionY: calculateValue(scalar * imageScaleScalar, 60, 0, 150),
    },
    startTo: {
      opacity: 1,
      positionY: 0,
    },
  }),
  fadeAndMoveLeft: (scalar, imageScaleScalar) => ({    
    label: "Fade Right",
    startFrom: {
      opacity: 0,
      //positionX: -60 * scalar,
      positionX: -1 * calculateValue(scalar * imageScaleScalar, 60, 0, 150),
    },
    startTo: {
      opacity: 1,
      positionX: 0,
    },
  }),
  fadeAndMoveRight: (scalar, imageScaleScalar) => ({    
    label: "Fade Left",
    startFrom: {
      opacity: 0,
      //positionX: 60 * scalar,
      positionX: calculateValue(scalar * imageScaleScalar, 60, 0, 150),
    },
    startTo: {
      opacity: 1,
      positionX: 0,
    },
  }),
  fadeAndScaleUp: (scalar, imageScaleScalar) => ({     
    label: "Scale Up",
    startFrom: {
      opacity: 0,
      //scale: 0.9,
      scale: calculateValue(scalar * imageScaleScalar, 0.9, 0.5, 1),
    },
    startTo: {
      opacity: 1,
      scale: 1,
    },
  }),
  fadeAndScaleDown: (scalar, imageScaleScalar) => ({  
    label: "Scale Down",
    startFrom: {
      opacity: 0,
      //scale: 1.1,
      scale: calculateValue(scalar * imageScaleScalar, 1.1, 1, 1.5),
    },
    startTo: {
      opacity: 1,
      scale: 1,
    },
  }),
};