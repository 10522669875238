import React, { useEffect,useState,useRef } from 'react';
import {createExportTimeline} from './timeline/createExportTimeline'
import ServerSideExportCanvas from './ServerSideExportCanvas'
import filter from 'lodash/filter'

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const ServerSideExportCanvasContainer = ({ project,renderVariables,framesPerSecond,exportWidth }) => {

  const timelineRef = useRef(null)
  const [currentTime, setCurrentTime] = useState(project.foxStartTime)
  const [isPlaying, setIsPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [clips, setClips] = useState([])
  const [isTimelineLoaded, setIsTimelineLoaded] = useState(false)
  const [isExporting, setIsExporting] = useState(false)
  const [exportComplete, setExportComplete] = useState(false)
  const [uploadComplete, setUploadComplete] = useState(false)
  const [readyToExport, setReadyToExport] = useState(false)
  const [slideClips, setSlideClips] = useState([]);
  const [textSlideClips, setTextSlideClips] = useState([]);
  const [imageClips, setImageClips] = useState([]);
  const [chartClips, setChartClips] = useState([]);
  const [videoClips, setVideoClips] = useState([]);
  const [zoomClips, setZoomClips] = useState([]);
  const [audioClips, setAudioClips] = useState([]);
  const [webcamClips, setWebcamClips] = useState([]);


  useEffect(() => {
    const initializeTimeline = async () => {
      if (project) {
        try {

          const onTimeUpdate = (newTime) => {
            setCurrentTime(newTime);
          };
          
          const startTime = project.foxStartTime 
          let endTime = project.duration 

          if(project.foxMaxRenderSeconds){
            endTime = project.foxStartTime + project.foxMaxRenderSeconds
          }
          const timelineInstance = await createExportTimeline(project,startTime,endTime,onTimeUpdate,setReadyToExport,renderVariables);
          timelineRef.current = timelineInstance;         
          setDuration(timelineInstance.duration);
          setClips(timelineInstance.clips);
          setSlideClips(timelineInstance.clips.filter(clip => clip.zIndex === 1 && clip.type === 'slide'));
          setTextSlideClips(timelineInstance.clips.filter(clip => clip.zIndex === 1 && clip.type === 'textSlide'));
          setImageClips(timelineInstance.clips.filter(clip => clip.zIndex === 1 && clip.type === 'image'));
          setChartClips(timelineInstance.clips.filter(clip => clip.type === 'chart'));
          setVideoClips(timelineInstance.clips.filter(clip => clip.zIndex === 0));
          setZoomClips(timelineInstance.clips.filter(clip => clip.zIndex === 2));
          setAudioClips(timelineInstance.clips.filter(clip => clip.type=='audio'));
          setWebcamClips(timelineInstance.clips.filter(clip => clip.type=='webcam'));
          setIsTimelineLoaded(true)
          // // /// some stuff to try and prvent the dropped frame between export segments
          // const test = project.foxStartTime - 1/60
          // timelineRef.current.seek(test) 
          // await delay(500);
          // timelineRef.current.seek(project.foxStartTime) 
          await delay(1000);
          setReadyToExport(true);
          console.log('**************************IS READY TO EXPORT******************************* ')
        } catch (error) {
          console.error('Failed to create timeline:', error);
        }
      }
    };
    initializeTimeline();
  }, [project]); // Runs this effect when project changes

  const handleSeek = (newTime) => {
    if (timelineRef.current) {
      timelineRef.current.seek(newTime) 
    }
  };


 const handlePlay = () => {
    if (timelineRef.current) {
      timelineRef.current.play()
    }
  };
 
  const handlePause = () => {
    if (timelineRef.current) {
      timelineRef.current.pause()
    }
  };

  const subtitlesType = project.subtitles_type
 
  return (
    <div> 
     {isTimelineLoaded && 

        <ServerSideExportCanvas 
          isPlaying = {isPlaying}
          currentTime={currentTime}
          projectBackgroundId={project.background}
          slideClips = {slideClips}
          textSlideClips = {textSlideClips}
          imageClips = {imageClips}
          chartClips = {chartClips}
          videoClips = {videoClips}
          zoomClips = {zoomClips}   
          webcamClips={webcamClips}   
          audioClips={audioClips}
          setTime={handleSeek}
          duration={duration}
          projectId={project.id}
          renderId={project.renderId}
          foxStartTime={project.foxStartTime}
          foxMaxRenderSeconds={project.foxMaxRenderSeconds}
          segmentId={project.segmentId}
          s3PresignedUploadUrl={project.s3PresignedUploadUrl}
          videoRenderUploadUrl={project.videoRenderUploadUrl}
          project={project}
          setIsExporting={setIsExporting}
          setExportComplete={setExportComplete}
          setUploadComplete={setUploadComplete}
          renderVariables={renderVariables}
          framesPerSecond={framesPerSecond}
          exportWidth={exportWidth}
          subtitlesType={subtitlesType}
       />
     }


     I have the project 

    <div>Ready to export? {
    readyToExport ? 
      <span className="exportReady">true</span> :
      <span className="exportNotReady">false</span>
    }</div>

    <button id="serversideplay" onClick={handlePlay}>play</button>

    <button id="serversidepause" onClick={handlePause}>pause</button>

    {isExporting &&
    <div>exporting....</div>
  }


    {exportComplete &&
    <div><span className="exportDone">Export DONE</span></div>
    }

    {uploadComplete &&
    <div><span className="uploadDone">Upload DONE</span></div>
    }

    </div>
  );
}





export default ServerSideExportCanvasContainer
