import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import { useLoader, useThree, useFrame } from '@react-three/fiber';
import { useControls, button } from 'leva';
import * as THREE from 'three';
import MobileVideo from './mobileVideo/MobileVideo';
import TextSlide from './textSlide/TextSlide';
import ImageClip from './imageClip/ImageClip'
import Camera from './Camera';
import CanvasBackground from './CanvasBackground';
import ChartsContainer from './ChartsContainer';
import BasicVideo from './basicVideo/BasicVideo'
import Slide from './slide/Slide'
import renderOrders from './renderOrders'
import CaptionsGroup from './captions/CaptionsGroup'
import SubtitlesGroup from './subtitles/SubtitlesGroup'
import WebcamVideo from './webcamVideo/WebcamVideo'
import ScreenVideo from './screenVideo/ScreenVideo'
import WebcamVideoPreview from './webcamVideo/WebcamVideoPreview'

const CanvasContents = ({ 
		showSlideEditor,
		variableValues,
		testKey,
		currentTime, 
		slideClips,
		textSlideClips,
		videoClips,
		chartClips,
		zoomClips,
		updateMeshWidth,
		updateMeshHeight,
		projectBackground,
		readyVideoClips,
		hideRenderedTextSlide,
		showChartStatic,
		showChartAnimated,
		imageClips,
		showBasicVideoStatic,
		showImageStatic,
		showScreenVideoStatic,
		isPlaying,
		setSelectedSlideElement,
		audioClips,
		showSlideChartStatic,
		showSlideChartAnimated,
		showSlideImageStatic,
		showSlideTextStatic,
		showWebcamStatic,
		focusedSlideElement,
		draggingElementId,
		draggingGroupId,
		resizingElementId,
		slideEditorDraggingSpacer,		
		selectedLayoutGroup,
		isDraggingToReorder,
		selectedSlideItemId,
		subtitlesType,
		webcamClips,
		isDraggingWebcam,
		isResizingWebcam,
		cameraPreviewRect
	}) => {	

	const showSubtitles = subtitlesType=='standard'
	const showCaptions = subtitlesType=='captions'

	//console.log(webcamClips)

	return (
		<>			
		<CanvasBackground background={projectBackground} renderOrder={renderOrders.projectBG} />


		
		{webcamClips && webcamClips.map((clip) => {
		    if (showWebcamStatic) {
		        return (
		            <WebcamVideoPreview
		                key={clip.id}
		                clip={clip}
		                time={currentTime}
		                projectBackground={projectBackground}
		                isPlaying={isPlaying}
		                zoomClips={zoomClips}
		                showWebcamStatic={showWebcamStatic}
		                isDraggingWebcam={isDraggingWebcam}
		                isResizingWebcam={isResizingWebcam}
		                slideClips={slideClips}
		                cameraPreviewRect={cameraPreviewRect}
		            />
		        );
		    } else {
		        return (
		            <WebcamVideo
		                key={clip.id}
		                clip={clip}
		                time={currentTime}
		                projectBackground={projectBackground}
		                isPlaying={isPlaying}
		                zoomClips={zoomClips}
		                showWebcamStatic={showWebcamStatic}
		                isDraggingWebcam={isDraggingWebcam}
		                isResizingWebcam={isResizingWebcam}
		                slideClips={slideClips}
		            />
		        );
		    }
		})}






		{videoClips && videoClips.map((clip) => {    


				 if(clip.isDeviceRecording ){

				return (
					<MobileVideo
						key={clip.id}    
						clip={clip}
						time={currentTime}					
						updateMeshWidth={updateMeshWidth}
						updateMeshHeight={updateMeshHeight}
						projectBackground={projectBackground}
						showMobileVideoStatic={showScreenVideoStatic}
						isPlaying={isPlaying}
						zoomClips={zoomClips}
						readyVideoClips={readyVideoClips}
					/>			
			
			)}
			else if(clip.isBasicVideo){
				return (
				<BasicVideo
					key={clip.id}    
					clip={clip}
					time={currentTime}					
					projectBackground={projectBackground}
					showBasicVideoStatic={showBasicVideoStatic}
					isPlaying={isPlaying}
					zoomClips={zoomClips}
					readyVideoClips={readyVideoClips}
				/>
			);
			}
			else return ( //legacy screen recordings
				<ScreenVideo
					isLegacyClip={clip.isScreenRecording?false:true}
					key={clip.id}    
					clip={clip}
					time={currentTime}
					updateMeshWidth={updateMeshWidth}
					updateMeshHeight={updateMeshHeight}
					projectBackground={projectBackground}
					showScreenVideoStatic={showScreenVideoStatic}										
					screenVideoDevice={clip.metadata.screenVideoApp} // in theory, could be Figma etc., later
					screenVideoDeviceFrame={clip.metadata.deviceFrame}
					zoomClips={zoomClips}
					readyVideoClips={readyVideoClips}
					variableValues={variableValues}
				/>			
			);
		//}
		})}

		
		{slideClips && slideClips.map((clip) => {        
			return (
				<Slide
					key={`${testKey}_${clip.id}`}    
					clip={clip}
					time={currentTime}          	
					projectBackground={projectBackground}
					isPlaying={isPlaying}
					setSelectedSlideElement={setSelectedSlideElement}
					hideRenderedTextSlide={hideRenderedTextSlide}
					variableValues={variableValues}
					showSlideChartAnimated={showSlideChartAnimated}
					showSlideChartStatic={showSlideChartStatic} 
					showSlideImageStatic={showSlideImageStatic}
					showSlideTextStatic={showSlideTextStatic}
					focusedSlideElement={focusedSlideElement}
					draggingElementId={draggingElementId}
					draggingGroupId={draggingGroupId}
					resizingElementId={resizingElementId}
					slideEditorDraggingSpacer={slideEditorDraggingSpacer}
					selectedLayoutGroup={selectedLayoutGroup}
					isDraggingToReorder={isDraggingToReorder}					
					selectedSlideItemId={selectedSlideItemId}

				/>
			);
		})}









		{chartClips && chartClips.map((clip) => {        
			return (
				<ChartsContainer
					key={clip.id}    
					clip={clip}
					time={currentTime}   
					showChartAnimated={showChartAnimated}
					showChartStatic={showChartStatic} 
					projectBackground={projectBackground}
				/>
			);
		})}

		{/*{videoClips && videoClips.map((clip) => {    
			if(clip.type=='webcam'){
				return(
				<WebcamVideo
					key={clip.id}    
					clip={clip}
					time={currentTime}					
					projectBackground={projectBackground}					
					isPlaying={isPlaying}
					zoomClips={zoomClips}
					readyVideoClips={readyVideoClips}
					showWebcamStatic={showWebcamStatic}
				/>
				)
			}
		})}*/}



		{showCaptions && audioClips && audioClips.map((audioClip) => {
		  const captionGroups = audioClip.metadata.captionGroups || [];
		  
		  return captionGroups.map((captionsGroup) => {
		    const globalGroupStartTime = audioClip.startTime + captionsGroup.startTime;		    
		    return (
		      <CaptionsGroup
		        key={`${audioClip.id}-${captionsGroup.id}`}
		        captionsGroup={captionsGroup}
		        globalGroupStartTime={globalGroupStartTime}
		        time={currentTime}
		        isPlaying={isPlaying}		        
		      />
		    );
		  });
		})}

		{showSubtitles && audioClips && audioClips.map((audioClip) => {
		  const subtitlesGroups = audioClip.metadata.subtitlesGroups || [];
		  
		  // console.log('audioClip.metadata')
		  // console.log(audioClip.metadata)
		  // console.log(subtitlesGroups)

		   return subtitlesGroups.map((subtitlesGroup) => {
		    const globalGroupStartTime = audioClip.startTime + subtitlesGroup.startTime;		    
		    // console.log('subtitlesGrouprender')
		    // console.log(subtitlesGroup)
		    return (
		      <SubtitlesGroup
		        key={`${audioClip.id}-${subtitlesGroup.id}`}
		        subtitlesGroup={subtitlesGroup}
		        globalGroupStartTime={globalGroupStartTime}
		        time={currentTime}
		        isPlaying={isPlaying}		        
		      />
		    );
		  });
		})}




		{/* Maybe we always show, who knows */}

		{/*
				<BasicVideo
					key={clip.id}    
					clip={clip}
					time={currentTime}					
					projectBackground={projectBackground}
					showBasicVideoStatic={showBasicVideoStatic}
					isPlaying={isPlaying}
				/>
				*/}


		

		
		{textSlideClips && textSlideClips.map((clip) => {        
			return (
				<TextSlide
					key={`${testKey}_${clip.id}`}    
					clip={clip}
					time={currentTime}          	
					hideRenderedTextSlide={hideRenderedTextSlide}
					projectBackground={projectBackground}
				/>
			);
		})}


		{imageClips && imageClips.map((clip) => {        
			return (
				<ImageClip
					key={clip.id}    
					clip={clip}
					time={currentTime}
					updateMeshWidth={updateMeshWidth}
					updateMeshHeight={updateMeshHeight}
					projectBackground={projectBackground}
					showImageStatic={showImageStatic}
					isPlaying={isPlaying}
				/>
			);
		})}
		
		


		</>
	);
};

export default CanvasContents




			