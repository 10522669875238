import React from 'react'

class SpritesheetImage extends React.Component{  	


	render(){	

		const {spriteSheetImageUrl, tileWidth, tileHeight, outputImageWidth, outputImageHeight, offsetX, offsetY} = this.props
	
		const outputImageScalar = outputImageWidth / tileWidth
		

	  return (
	  	
	  		<div style={{width: `${outputImageWidth}px`, height: `${outputImageHeight}px`}} className='spritesheetImageContainer'>
	  			<div style={{transform: `scale(${outputImageScalar})`, width: `${tileWidth}px`, height: `${tileHeight}px`, transformOrigin: '0% 0%', overflow: 'hidden'}} className='spritesheetImage'>
	  				<img style={{transform: `translateX(${-1 * offsetX}px) translateY(${-1 * offsetY}px)`}} className='spritesheetImage-completeImage' src={this.props.spriteSheetImageUrl} />
	  			</div>	  		
	  		</div>
			
	  )
	}
}

export default SpritesheetImage
