export const startTransitionConfigs = {
  none: (scalar) => ({
    label: "None",
    startFrom: {
      opacity: 1,      
    },
    startTo: {
      opacity: 1,      
    },
  }),
  fade: (scalar) => ({
    label: "Fade In",
    startFrom: {
      opacity: 0,      
    },
    startTo: {
      opacity: 1,      
    },
  }),
  fadeAndMoveUp: (scalar) => ({  
    label: "Fade Up",
    startFrom: {
      opacity: 0,
      positionY: -20 * scalar,
    },
    startTo: {
      opacity: 1,
      positionY: 0,
    },
  }),
  fadeAndMoveLeft: (scalar) => ({    
    label: "Fade Right",
    startFrom: {
      opacity: 0,
      positionX: -60 * scalar,
    },
    startTo: {
      opacity: 1,
      positionX: 0,
    },
  }),
  fadeAndScaleUp: (scalar) => ({     
    label: "Scale Up",
    startFrom: {
      opacity: 0,
      scale: 0.9,
    },
    startTo: {
      opacity: 1,
      scale: 1,
    },
  }),
  fadeAndScaleDown: (scalar) => ({  
    label: "Scale Down",
    startFrom: {
      opacity: 0,
      scale: 1.1,
    },
    startTo: {
      opacity: 1,
      scale: 1,
    },
  }),
};