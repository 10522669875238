import React from 'react';
import Icon from '../../components/misc/Icon';

const ButtonReactComponentTest = () => {
  return (
    <div className='editor-transcriptPanel-transcriptChunk-inlineControls'>
      <button onClick={()=> console.log('PREVIEW')} className='editor-transcriptPanel-transcriptChunk-inlineControls-previewBtn'>
        <Icon name='chunkPreview' />
        <div className='editor-transcriptPanel-transcriptChunk-inlineControls-previewBtn-tooltipContainer'>
          <div className='tooltip'>
            Preview
          </div>
        </div>
      </button>
      <button onClick={()=> console.log('regen')} className='editor-transcriptPanel-transcriptChunk-inlineControls-previewBtn'>
        <Icon name='chunkRegen' />
        <div className='editor-transcriptPanel-transcriptChunk-inlineControls-previewBtn-tooltipContainer'>
          <div className='tooltip'>
            Regen
          </div>
        </div>
      </button>
    </div>
  );
};

export default ButtonReactComponentTest;