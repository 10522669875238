export default function getChartColors() {
  return [
    {
      id: "chartBlue",
      value: "rgba(47, 127, 255, 1)",
      rgba: "rgba(47, 127, 255, 1)",
      type: "solid"
    },
    {
      id: "chartGreen",
      value: "rgba(68, 214, 133, 1)",
      rgba: "rgba(68, 214, 133, 1)",
      type: "solid"
    },    
    {
      id: "chartPurple",
      value: "rgba(202, 121, 255, 1)",
      rgba: "rgba(202, 121, 255, 1)",
      type: "solid"
    },
    {
      id: "chartOrange",
      value: "rgba(255, 168, 86, 1)",
      rgba: "rgba(255, 168, 86, 1)",
      type: "solid"
    },
    {
      id: "chartSky",
      value: "rgba(107, 198, 251, 1)",
      rgba: "rgba(107, 198, 251, 1)",
      type: "solid"
    },    
    {
      id: "chartRed",
      value: "rgba(251, 107, 107, 1)",
      rgba: "rgba(251, 107, 107, 1)",
      type: "solid"
    },    
    {
      id: "chartGray",
      value: "rgba(179, 182, 188, 1)",
      rgba: "rgba(179, 182, 188, 1)",
      type: "solid"
    },
    {
      id: "chartLightGray",
      value: "rgba(211, 214, 219, 1)",
      rgba: "rgba(211, 214, 219, 1)",
      type: "solid"
    },    
  ];
}