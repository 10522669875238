import React, { useRef, useEffect } from 'react';

const SpreadsheetInput = ({ activeCell, cellWidth, cellHeight, value, handleInputChange, setIsEditing, onEnterPress }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [activeCell.row, activeCell.col]); // Refocus on cell change
  
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();      
      return;
    } else if (e.key === 'Escape') {
      e.preventDefault();
      setIsEditing(false); // Also exit editing mode when Escape is pressed
      return;
    }
    e.stopPropagation();
  };



  return (
    <input
      ref={inputRef}
      type="text"
      className="editor-detailPanel-spreadsheet-cellInput"
      style={{
        position: 'absolute',
        left: activeCell.col * (cellWidth - 1),
        top: activeCell.row * (cellHeight - 1),
        width: cellWidth,
        height: cellHeight,
      }}
      value={value}
      onChange={handleInputChange}
      onBlur={() => setIsEditing(false)}
      onKeyDown={handleKeyDown}
      autoFocus
    />
  );
};


export default SpreadsheetInput;
