///Calculate the time through the video based on clip starttime, trims and time stretching


//lets take into account clip playback rate 






export function calulateVideoTimeFromTimelineTime(timelineTime, clip){
  const clipPlaybackRate = clip.clipPlaybackRate  
  const timeSinceVideoStart = (timelineTime - clip.startTime)*clipPlaybackRate

 
  const segments = clip.segments 
  const trimStart = clip.metadata.trimStart

  let accumulatedDuration = 0
  let videoTime = trimStart;

  for (const segment of segments) {
    let segmentDuration 
    if(segment.isSkipSegment){
      if(segment.isExpanded){
        segmentDuration = segment.originalDuration
      }else{
        segmentDuration=0
      }
    }else if(segment.isQuiet){
      segmentDuration = segment.originalDuration / segment.playbackRate
    }else{
     segmentDuration= segment.originalDuration
    }
    if (timeSinceVideoStart >= accumulatedDuration && timeSinceVideoStart < accumulatedDuration + segmentDuration) {
    // Calculate the position within the current segment
      const segmentElapsedTime = timeSinceVideoStart - accumulatedDuration; 
      videoTime += segment.isQuiet ? segmentElapsedTime * segment.playbackRate : segmentElapsedTime;
      break;
     } else {
        videoTime += segment.originalDuration
    }
    accumulatedDuration += segmentDuration;
  }

  return videoTime;
};










// export function calulateVideoTimeFromTimelineTime(timelineTime, clip){


//   const clipPlaybackRate = clip.clipPlaybackRate
//  // console.log(`clipPlaybackRate----- ${clipPlaybackRate}`)
  
//   const timeSinceVideoStart = (timelineTime - clip.startTime)*clipPlaybackRate
 
//   const segments = clip.segments 
//   const trimStart = clip.metadata.trimStart
//   // calulateVideoTimeFromTimelineTime(timeSinceVideoStart, segments,trimStart){
//   let accumulatedDuration = 0
//   let videoTime = trimStart;

//   for (const segment of segments) {
//     const segmentDuration = segment.isQuiet ? segment.originalDuration / segment.playbackRate : segment.originalDuration;

//     if (timeSinceVideoStart >= accumulatedDuration && timeSinceVideoStart < accumulatedDuration + segmentDuration) {
//     // Calculate the position within the current segment
//       const segmentElapsedTime = timeSinceVideoStart - accumulatedDuration;

      
//       videoTime += segment.isQuiet ? segmentElapsedTime * segment.playbackRate : segmentElapsedTime;
//       break;
//    } else {
//       videoTime += segment.isQuiet ? segment.originalDuration : segmentDuration;
//     }
//     accumulatedDuration += segmentDuration;
//   }

//   return videoTime;
// };



