import React, { useState, useEffect } from 'react';
import Icon from '../../../misc/Icon';
import {selectWindowToRecord} from '../../../../utils/recordingUtils/selectWindowToRecord'
import filter from 'lodash/filter';

const BLACKLISTED_APPS = ["Finder", "storeuid", "Notes", "Calendar", "Electron"];


const EditorNewScreenClipPopoverSelectWindowDefaultRow = ({primaryLabel, secondaryLabel, onClick }) => {  

  return (    
    <button onClick={onClick} className='editorPopover--screenClip-windowDefaultRow'>
      <div className='editorPopover--screenClip-windowDefaultRow-primaryLabel'>
        {primaryLabel}
      </div>
      {secondaryLabel && 
        <div className='editorPopover--screenClip-windowDefaultRow-secondaryLabel'>
          {secondaryLabel}
        </div>
      }
    </button>    
  );
};


const EditorNewScreenClipPopoverSelectWindowChromeRow = ({label, onClick }) => {  
  return (
      <button onClick={onClick} className='editorPopover--screenClip-windowChromeRow'>
        <div className='editorPopover--screenClip-windowChromeRow-iconContainer'>
          <img src='/chromeIcon.png' />
        </div>
        <div className='editorPopover--screenClip-windowChromeRow-rightContainer'>
          <div className='editorPopover--screenClip-windowChromeRow-title'>
            Google Chrome
          </div>
          {label && 
            <div className='editorPopover--screenClip-windowChromeRow-label'>
              {label}
            </div>
          }
        </div>
      </button>
  );
};

function getDisplayName(owningApplication, title, windowID) {
  if (owningApplication && title) {
    return `${owningApplication.applicationName}: ${title}`;
  } else if (!owningApplication && title) {
    return title;
  } else if (owningApplication && !title) {
    return `${owningApplication.applicationName}: ${windowID}`;
  } else {
    return "";
  }
}


const EditorNewScreenClipPopoverSelectWindow = ({closeModal, windowList}) => {  
  
  const hasChromeWindow = true

  ///TODO is windowLayer the same as level?
  //const layerFilteredWindowsList = filter(windowList, { windowLayer: 0 }); //old pre record kit
  const layerFilteredWindowsList = filter(windowList, { level: 0 });
  
  const filteredWindowsList = layerFilteredWindowsList.filter(window => {
    return !BLACKLISTED_APPS.includes(window.application_name);
  });

  const appNameCount = {};

  filteredWindowsList.forEach(window => {
    appNameCount[window.application_name] = (appNameCount[window.application_name] || 0) + 1;
  });

  const sortedWindowsList = filteredWindowsList.slice().sort((a, b) => {
    const appNameA = a.application_name.split(':')[0].trim().toLowerCase();
    const appNameB = b.application_name.split(':')[0].trim().toLowerCase();
    return appNameA.localeCompare(appNameB);
  });

   const finalWindowsList = sortedWindowsList.filter(window => {
    return !(appNameCount[window.application_name] > 1 && !window.title);
  });

  const chromeWindows = finalWindowsList.filter(window => window.application_name.includes('Google Chrome'));
  const nonChromeWindows = finalWindowsList.filter(window => !window.application_name.includes('Google Chrome'));

  // Sort Chrome windows by window name in ascending order
  const sortedChromeWindows = chromeWindows.slice().sort((a, b) => {
    const windowNameA = a.title.toLowerCase();
    const windowNameB = b.title.toLowerCase();
    return windowNameA.localeCompare(windowNameB);
  });

  const selectWindow = (selectedWindow) => {    
    selectWindowToRecord(selectedWindow);
    closeModal();    
  };
  return (
    <React.Fragment>      
      <div className='editorPopover-body editorPopover-body--scrollable'>        

        {sortedChromeWindows.map((recordingWindow) => {
          return (
            <EditorNewScreenClipPopoverSelectWindowChromeRow
              key={recordingWindow.id}
              label={recordingWindow.title}
              onClick={() => selectWindow(recordingWindow)}
            />
          );
        })}

        {chromeWindows.length > 0 &&
          <div className='editorPopover--screenClip-windowRowDivider'>
            All Windows
          </div>
        }
        {nonChromeWindows.map((recordingWindow) => {
          const isOnlyWindow = appNameCount[recordingWindow.applicationName] === 1;
          return (
            <EditorNewScreenClipPopoverSelectWindowDefaultRow
              key={recordingWindow.id}
              primaryLabel={recordingWindow.application_name}
              secondaryLabel={isOnlyWindow ? null : recordingWindow.title}
              onClick={() => selectWindow(recordingWindow)}
            />
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default EditorNewScreenClipPopoverSelectWindow