import {getDurationForDeviceCaptureId} from './deviceRecordings/getDurationForDeviceCaptureId'
import {getDurationForLegacyCaptureId} from './legacyScreenRecordings/getDurationForLegacyCaptureId'
import {getDurationForScreenCaptureId} from './screenRecordings/getDurationForScreenCaptureId'


async function getDurationForCaptureId(captureId,isDevice,isScreenRecording) {
  if(isDevice){
    return await getDurationForDeviceCaptureId(captureId)
  }else if(isScreenRecording){
    return await getDurationForScreenCaptureId(captureId)
  }else{
    return await getDurationForLegacyCaptureId(captureId)
  }
}

export { getDurationForCaptureId }


