import React, { useRef, useEffect, useState, useMemo, useContext } from 'react';
import { SpringAnimator } from '../utils/animations/SpringAnimator';
import {calulateVideoTimeFromTimelineTime} from '../../timeline/utils/calulateVideoTimeFromTimelineTime'
import WebcamVideoVideo from './WebcamVideoVideo'
import { getAnimationParamsForMotionStyle } from '../utils/animations/getAnimationParamsForMotionStyle'
import { startTransitionConfigs } from './transitionConfigs/startTransitionConfigs';
import { endTransitionConfigs } from './transitionConfigs/endTransitionConfigs';
import { simulateAnimationDuration } from '../utils/animations/simulateAnimationDuration';
import { getBackgroundForId } from '../../utils/brands/getBackgroundForId'
import defaultVideoAnimationValues from '../utils/animations/defaultVideoAnimationValues'
import {isLightBackground} from '../../utils/brands/isLightBackground'
import { calculateVideoZoom } from '../utils/videoZoom/calculateVideoZoom'
import renderOrders from '../renderOrders'
import {getWebcamRectForTime} from '../../utils/webcam/getWebcamRectForTime'

const FPS = 60; // Frames per second



const WebcamVideo = (props) => {
  const {clip,time,projectBackground, showWebcamStatic, isPlaying,slideClips} = props
  const startTransitionType = clip.metadata.startTransitionType
  const endTransitionType = clip.metadata.endTransitionType
  const motionStyle=clip.metadata.motionStyle || 'smooth'
  const animationParams=getAnimationParamsForMotionStyle(motionStyle)

  let startHiddenDuration = 0
  let endHiddenDuration = 0
  if(clip.metadata.startHiddenDuration){
    startHiddenDuration = clip.metadata.startHiddenDuration
  }
  if(clip.metadata.endHiddenDuration){
    endHiddenDuration = clip.metadata.endHiddenDuration
  }

  const relativeTime = time - clip.startTime

  let localTime 
  if(clip.isPlaceholder){
    localTime = relativeTime;
  }else{
    localTime = calulateVideoTimeFromTimelineTime(time,clip)
  }

  let visible = false 

   if(!clip.isPlaceholder){
    if(localTime >= clip.metadata.trimStart && localTime < clip.metadata.trimEnd){
      visible = true
    }
    if(clip.isUploadingVideo){ //When video is uploading we don't have the trim stuff yet so do this so it shows the placeholder
      if(relativeTime >= clip.startTime && relativeTime < clip.endTime){
        visible=true
      }
    }
    if (relativeTime < startHiddenDuration) {
      visible = false;
    }
    if (relativeTime > clip.duration - endHiddenDuration) {
      visible = false;
    }
  }else{ //for placeholders just use clip start and end time
    if(time>clip.startTime && time < clip.endTime){
      visible = true
    }
  }


  // Background I think should switch to project background or current-clip-at-current-time BG
  // for example if you have a video over a slide, it should take BG from slide
  // we might also be able to make it a different color somehow
  const backgroundId = clip.metadata.backgroundId
  let screenVideoBackground = projectBackground
  const isNoneBGColor = backgroundId =='none'

  const idToTest=backgroundId||projectBackground.id
  const isLightBG = isLightBackground(idToTest)

  //console.log(`basic video is light?----- ${isLight}`)

  if(backgroundId){
    screenVideoBackground=getBackgroundForId(backgroundId)
  }

  //

  const videoDuration = clip.duration // plays as long of and then exits
  const localStartTime = 0
  const localEndTime = videoDuration - 0.1
  const calculationWindowEnd = localEndTime + 0.2

  //

  const { startFrom: transitionStartFrom, startTo: transitionStartTo} = startTransitionConfigs[startTransitionType] || {};
  const { endTo: transitionEndTo } = endTransitionConfigs[endTransitionType] || {};
  const startFrom = { ...defaultVideoAnimationValues, ...transitionStartFrom };
  const startTo = { ...defaultVideoAnimationValues, ...transitionStartTo };
  const endTo = { ...defaultVideoAnimationValues, ...transitionEndTo };

  let initialProperties = startFrom
  if(showWebcamStatic){
    initialProperties = defaultVideoAnimationValues
  }

  const [propertySprings, setPropertySprings] = useState({});
  const [animationStates, setAnimationStates] = useState({});
  const [startToCompletedTime, setStartToCompletedTime] = useState(null);
  const [endToAnimationDuration, setEndToAnimationDuration] = useState(null);

  useEffect(() => {
    let newPropertySprings = {};
    let newAnimationStates = {};

    // Ensure that calculationWindowEnd and FPS are defined and valid
    const framesCount = calculationWindowEnd && FPS ? Math.round(calculationWindowEnd * FPS) : 0;

    // Initialize the springs with 'startFrom' values
    Object.keys(startFrom).forEach(property => {
      newPropertySprings[property] = new SpringAnimator(
        animationParams.mass,
        animationParams.stiffness,
        animationParams.damping,
        startFrom[property]
      );
      
      newAnimationStates[property] = new Array(framesCount).fill(startFrom[property]);
    });

    // Calculate the animation durations
    let startToDuration = simulateAnimationDuration(startFrom, startTo, animationParams, FPS);
    if(startToDuration === 'none'){
      startToDuration = 0
    }
    let endToDuration = simulateAnimationDuration(startTo, endTo, animationParams, FPS);
    if(endTransitionType === 'none'){
      endToDuration = 0
    }

    // Simulate the entire animation sequence
    for (let frame = 0; frame < calculationWindowEnd * FPS; frame++) {
      const simulationLocalTime = frame / FPS;

      // Animate from 'startFrom' to 'startTo'
      if (simulationLocalTime >= localStartTime + startHiddenDuration && simulationLocalTime < startToDuration + startHiddenDuration) {
        Object.keys(startTo).forEach(property => {
          newPropertySprings[property].setTarget(startTo[property]);
        });
      }

      // Start the 'endTo' animation at the endInitiateAnimationTime
      if (simulationLocalTime >= (localEndTime - endToDuration - endHiddenDuration) && simulationLocalTime <= (localEndTime - endHiddenDuration)) {
        Object.keys(endTo).forEach(property => {
          newPropertySprings[property].setTarget(endTo[property]);
        });
      }

      // Simulate the springs for all properties
      Object.keys(newPropertySprings).forEach(property => {
        const spring = newPropertySprings[property];        
        const isOpacity = property === 'opacity'
        spring.simulate(1000 / FPS, isOpacity, 0, 1); // Enable clamping only for opacity
        newAnimationStates[property][frame] = spring.position;
      });
    }

    // Update states at the end of the calculation
    setPropertySprings(newPropertySprings);
    setAnimationStates(newAnimationStates);
    setStartToCompletedTime(startToDuration);
    setEndToAnimationDuration(endToDuration);
  
  }, [videoDuration, JSON.stringify(animationParams), startTransitionType, endTransitionType, startHiddenDuration, endHiddenDuration]);


 
  //
  // VIDEO DISPLAY WIDTH AND DISPLAY HEIGHT
  // not underlying asset width and height
  //const positionStyle = 'right'
  const positionStyle = clip.metadata.positionStyle

  let rect={}

  if(visible){
    rect = getWebcamRectForTime(clip.metadata.layout,time,slideClips)
  }
  



  let videoDisplayWidth = rect.width
  let videoDisplayHeight = rect.height
  let videoDisplayRadius = 20

  // FRAME WIDTH AND HEIGHT

  const webcamFramePadding = 20
  const webcamFrameRadius = 8
  
  const frameWidth = videoDisplayWidth + webcamFramePadding
  const frameHeight = videoDisplayHeight + webcamFramePadding
  const frameRadius = videoDisplayWidth + webcamFrameRadius


  //
  // X AND Y OFFSET

  const groupRef = useRef();

  const SCENE_WIDTH = 1920
  const SCENE_HEIGHT = 1080

  let verticalOffset = 0
  let horizontalOffset = 0

  const left = rect.x
  const top = rect.y

  // Convert left (x) to horizontal offset
  // 1. Subtract half scene width to move origin to center
  // 2. Add half object width to get center point
  horizontalOffset = left - (SCENE_WIDTH / 2) + (videoDisplayWidth / 2)

  // Convert top (y) to vertical offset
  // 1. Subtract half scene height to move origin to center
  // 2. Add half object height (not subtract) since we want positive Y to be up
  verticalOffset = -((top + (videoDisplayHeight / 2)) - (SCENE_HEIGHT / 2)) * -1


  useEffect(() => {

    if (groupRef.current) {      
      groupRef.current.position.set(
        horizontalOffset,
        -verticalOffset,
        0);
    }
  }, [horizontalOffset, verticalOffset]);

  let isFullScreen = false
  if(videoDisplayWidth === SCENE_WIDTH){
    isFullScreen = true
  }

  let webcamRenderOrder = renderOrders.webcamVideo
  if(isFullScreen){
    webcamRenderOrder = renderOrders.webcamVideoFullScreen
  }


  return (
    <group ref={groupRef} renderOrder={webcamRenderOrder}>
    
    
      <WebcamVideoVideo 
        animationStates={animationStates}
        initialProperties={initialProperties}
        FPS={FPS}

        showWebcamStatic={showWebcamStatic}
        
        clip={clip}
        time={time}
        localTime={relativeTime}
        visible={visible}          

        startTransitionType={startTransitionType}
        endTransitionType={endTransitionType}
        animationParams={animationStates}
        
        isPlaying={isPlaying}

        videoDisplayWidth={videoDisplayWidth}
        videoDisplayHeight={videoDisplayHeight}
        videoDisplayRadius={videoDisplayRadius}

      />      
      

      {/*}
      <WebcamVideoDeviceFrame 
        animationStates={animationStates}
        initialProperties={startFrom}
        FPS={60}

        showScreenVideoStatic={showScreenVideoStatic}

        frameWidth={meshWidth + (WEBCAM_FRAME_PADDING * meshScale)}
        frameHeight={meshHeight + (WEBCAM_FRAME_PADDING * meshScale)}
        meshRadius={meshRadius + (WEBCAM_FRAME_EXTRA_RADIUS*meshScale)}

        frameOffset={WEBCAM_FRAME_PADDING * meshScale / 2}          
        chromeFrameMeshScale={chromeFrameMeshScale}     

        clip={clip}
        time={time}
        localTime={relativeTime}
        visible={visible}
        meshScale={meshScale}          

        startTransitionType={startTransitionType}
        endTransitionType={endTransitionType}
        animationParams={animationStates}

        screenVideoBackground={screenVideoBackground}
        variableValues={variableValues}
        clip={clip}
    
      />   
      */}

    </group>
  );
};

export default WebcamVideo
;


