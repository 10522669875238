import React, { useState, useEffect } from 'react';
import Icon from '../../misc/Icon';
import DPToggleGrid from './DPToggleGrid';
import DPNumberInput from './DPNumberInput';
import DPSelectMenu from './DPSelectMenu';
import DPColorPicker from './DPColorPicker';
import DPItemAnimationPanel from './DPItemAnimationPanel';
import { getOrgBrand } from '../../../utils/brands/getOrgBrand'
import { getBackgroundForId } from '../../../utils/brands/getBackgroundForId'

const EditorDetailPanelMobile = (props) => {

  const { trimMode, toggleTrimMode,clip,updateClipMetadata, updateClipBackgroundColor} = props

  const handleToggleTrimMode = () => {
    toggleTrimMode();
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.metaKey && event.key === 'l') {
        handleToggleTrimMode();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);


  const setHorizontalOffset=(value)=>{
     updateClipMetadata(clip.id,{horizontalOffset:value})
  }
    
  let label = 'Mobile'
  
  const handleUpdateMetadata = (field,value) => { 
    updateClipMetadata(clip.id,{[field]:value})
  }  

    const orgBrand=getOrgBrand()
    let activeBg = 'auto' //null
    if(clip.metadata.backgroundId=='none'){
      activeBg= 'none'
    }else if(clip.metadata.backgroundId){
      activeBg=clip.metadata.backgroundId
    }

    const setBgStyle = (bgId)=>{
      let value=bgId 
      if(bgId=='auto'){
        value=null
      }
      updateClipBackgroundColor(clip.id,value)
    }

    let bgStyles=[{ id:'none',value: 'none', label: 'None' }, {id:'auto', value: 'auto', label: 'Auto' }]
    
    orgBrand.backgrounds.forEach((bg)=>{
      if(bg.type=='image'){
        bgStyles.push({ value: bg.src, type: 'image',id:bg.id })
      }else{
        bgStyles.push({ value: bg.rgba, type: 'solid',id:bg.id  })
      }
    })


  return (
    <>
      <div className='editor-detailPanel-header'>
        <div className='editor-detailPanel-header-label'>
          iOS Device
        </div>         
      </div>          

      
      <div className='editor-detailPanel-row'>
        <button 
          onClick={toggleTrimMode}          
          className='dpButton dpButton--labelOnly dpButton--fullWidth dpButton--strong'
        >
          <div className='dpButton-label'>
            Edit Trim
          </div>
        </button>
      </div>      

      <div className='editor-detailPanel-divider' />
      
      <div className='editor-detailPanel-row'>
        <div className='editor-detailPanel-row-label'>
          Background
        </div>
        <div className='editor-detailPanel-row-hSpacer' />            
        <DPColorPicker                              
          activeColorId={activeBg}
          onChange={setBgStyle}
          options={bgStyles}          
        />       
      </div>    


       
      {/*
      <div className='editor-detailPanel-divider' />
      <div className='editor-detailPanel-row'>
        <div className='editor-detailPanel-row-label'>
          Background
        </div>
        <div className='editor-detailPanel-row-hSpacer' />                    
          <DPColorPicker          
            width={72}
            btnLabelLight={activeBg === 'none'}
            activeBg={activeBg}
            onChange={setBgStyle}
            options={bgStyles}
            colorPicker
          />               
      </div>   
      */}

      <div className='editor-detailPanel-divider' />

      <div className='editor-detailPanel-row'>
        <div className='editor-detailPanel-row-label'>
          Horizontal Offset
        </div>
        <div className='editor-detailPanel-row-hSpacer' />            
        <DPNumberInput           
          percentage
          min={-150}
          max={150}
          value={clip.metadata.horizontalOffset}
          setValue={setHorizontalOffset}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          iconName='doubleEndedArrow'              
        />
      </div>

     
      <div className='editor-detailPanel-divider' />

      <DPItemAnimationPanel 
        item={clip}
        handleUpdateMetadata={handleUpdateMetadata}
        activeStartTransition={clip.metadata.startTransitionType}
        activeEndTransition={clip.metadata.endTransitionType}
        itemType='mobile'
      />
    
      
    </>
  );
};

export default EditorDetailPanelMobile;