import React from 'react';
import { useSpring, animated } from '@react-spring/three';
import SlideFitImageElementMaterial from '../images/SlideFitImageElementMaterial'

// Create an animated version of the group
const AnimatedGroup = animated('group');

const SlideEditorCanvasPreviewFitImage = ({position, width, height, devMode, isDndDraggingAnyElement, isDndDraggingThisElement, imgSrc}) => {
  const [x, y, z] = position;

  const spring = useSpring({
    position: [x, y, z],
    config: { tension: 1000, friction: 60 },
    immediate: !isDndDraggingAnyElement && !isDndDraggingThisElement,
  });

  let opacity = 1;
  if(devMode) opacity = 0.75;
  if(isDndDraggingAnyElement) opacity = 0.5;
  if(isDndDraggingThisElement) opacity = 0;

  

  return (
    <AnimatedGroup position={spring.position}>      
      <mesh>
        <planeGeometry args={[width, height]} />
        <SlideFitImageElementMaterial 
          imgSrc={imgSrc}   
          opacity={opacity} // update to animated
        />
        {/* <meshBasicMaterial color="red" opacity={opacity} transparent /> */}
      </mesh>
    </AnimatedGroup>
  );
};

export default SlideEditorCanvasPreviewFitImage;