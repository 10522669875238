import {extractData} from './extractData'

//must have only 1 cell with a value



export const parseDataIntoDonutChart = (data) => {
  // console.log('parse data into donut')
  // console.log(data)

  // Extract data from the cell
  const cellData = extractData(data);
  if (!cellData) return { compatible: false };

  // Determine if the data is compatible as a number chart
 // const numberString = cellData.value.number.replace(/,/g, '');
 // const compatible = !isNaN(parseFloat(numberString));
   const numberValue = parseFloat(cellData.value.number);

  const compatible = cellData.value.prefix === '' &&
                     numberValue >= 0 &&
                     numberValue <= 100 &&
                     cellData.value.suffix.includes('%');


  return {
    compatible,
    aboveLabel: compatible ? cellData.aboveLabel : undefined,
    value: compatible ? cellData.value : undefined,
    belowLabel: compatible ? cellData.belowLabel : undefined
  };
};

export default parseDataIntoDonutChart;


