import React, {Component} from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Editor from '../components/editor/Editor'
// import find from 'lodash/find'


//Wraps editor to key it so it reloads on id change
class EditorWrapperContainer extends Component {
	render() {
		return (
			<Editor key={this.props.projectId} {...this.props}/>
		)
	}
}

function mapStateToProps(state,ownProps) {
	const projectId=ownProps.match.params.id
	// const project = find(state.projects,{id:`${projectId}`})
	return {
		projectId:projectId,
	//	project:project
	}
}

export default withRouter(connect(
	mapStateToProps,
	{
	})(EditorWrapperContainer))