import React, { useMemo } from 'react';
import * as THREE from 'three';
import renderOrders from '../../../renderOrders'

const SlideDonutChartFullRing = ({ outerRadius, innerRadius, color, opacity }) => {
  const geometry = useMemo(() => {
    const shape = new THREE.Shape();
    
    // Outer circle
    shape.absarc(0, 0, outerRadius, 0, Math.PI * 2, false);

    // Inner circle (hole)
    const holePath = new THREE.Path();
    holePath.absarc(0, 0, innerRadius, 0, Math.PI * 2, true);
    shape.holes.push(holePath);

    // Increase the number of segments for smoother circles
    const segments = Math.max(64, Math.floor(outerRadius * 8));

    return new THREE.ShapeGeometry(shape, segments);
  }, [outerRadius, innerRadius]);

  return (
    <mesh position={[0, 0, 0]} renderOrder={renderOrders.graphObject}>
      <primitive object={geometry} />
      <meshBasicMaterial 
        color={color} 
        transparent={true} 
        opacity={opacity} 
        side={THREE.DoubleSide} 
        renderOrder={renderOrders.graphObject} 
      />
    </mesh>
  );
};

export default SlideDonutChartFullRing;