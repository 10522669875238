import React, {useState,memo} from 'react'
import { useSelector,useDispatch } from 'react-redux';
import Icon from '../../misc/Icon';
import { useHistory } from 'react-router-dom';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import SelectColorDropdown from '../../uiKit/SelectColorDropdown'
import find from 'lodash/find'
import {openExportWindow} from '../../../utils/export/openExportWindow'
import {getOrgBrand} from '../../../utils/brands/getOrgBrand'
import {getOrgDomain} from '../../../utils/getOrgDomain'
import EditorVariantPopover from './EditorVariantPopover'
import EditorStatusBarExportBtn from './EditorStatusBarExportBtn'
import DPColorPicker from '../detailPanel/DPColorPicker';
import {updateProjectShouldUseClayBrand} from '../../../actions/juno'

 const motionOptions = [
    { value: 'smooth', label: 'Smooth' },
    { value: 'bouncy', label: 'Bouncy' },
    { value: 'molasses', label: 'Molasses' },
    { value: 'slow', label: 'Slow' },
    { value: 'zippy', label: 'Zippy' },     
  ];

const EditorStatusBar = (props) => {
  const dispatch = useDispatch();
  const {handleExportProject, handleUpdateProjectActiveBrand, projectBackgroundId, handleUpdateProjectBackground,defaultMotionStyle,handleUpdateDefaultMotionStyle,projectId, isEmpty,subscriptionStatus}=props


  const {refreshProjectVariables,projectVariables}=props


  const history = useHistory();
  const orgBrand=getOrgBrand()

  let backgrounds=orgBrand.backgrounds
  const projectBackground = find(backgrounds,{id:projectBackgroundId})
  const backgroundColorPreview = projectBackground
    ? { background: projectBackground.type === 'solid' ? projectBackground.rgba : '' }
    : {};



  let bgStyles=[]
  
  orgBrand.backgrounds.forEach((bg)=>{
    if(bg.type=='image'){
      bgStyles.push({ value: bg.src, type: 'image',id:bg.id })
    }else{
      bgStyles.push({ value: bg.rgba, type: 'solid',id:bg.id  })
    }
  })

 

  const [colorDropdownOpen, setColorDropdownOpen] = useState(false)
  const backgroundColorIsImage = projectBackground && projectBackground.type === 'image'
  let backgroundColorImage
  if(backgroundColorIsImage){
    backgroundColorImage = projectBackground.src
  }



  const toggleDropdown = (isOpen) => {
    setIsAnyDropdownOpen(isOpen);
  };
  
  const [variantPopoverOpen, setVariantPopoverOpen] = useState(false)

  const [motionDropdownOpen, setMotionDropdownOpen] = useState(false);
  const isAnyDropdownOpen = colorDropdownOpen || motionDropdownOpen || variantPopoverOpen

    
  let subscription_status = subscriptionStatus

  if(getOrgDomain()==' keplerventures.com' || getOrgDomain()=='cursorcapital.vc' || getOrgDomain()=='yarn.so'  || getOrgDomain()=='jasperdstory'){
    subscription_status='active'
  }

   const handleSettingsClick = () => {
        history.push('#settings/subscription'); // Update the URL hash
    };

  const hasVariants = projectVariables && projectVariables.length > 0
  let showVariantsPopover = false 
  if(getOrgDomain()=='clay.com' || getOrgDomain()=='yarn.so'){
    showVariantsPopover = true
  }

  const projectExports = useSelector(state => state.projectExports || {});


  const disabledExport = isEmpty || Object.keys(projectExports).length > 0


  let showSetBrandButton = false 
  let isClayBrand = false
  if(getOrgDomain()=='yarn.so'){
    showSetBrandButton = true
    if(props.project && props.project.use_clay_brand){
      isClayBrand = true
    }
  }

   const handleBrandButtonClick = () => {
    const newValue = !isClayBrand;
    dispatch(updateProjectShouldUseClayBrand(props.projectId, newValue))
  };


  return (
    <div className='editor-statusBar'>

        {showSetBrandButton &&
        <button 
          onClick={handleBrandButtonClick} 
          className='editor-statusBar-exportBtn editor-statusBar-exportBtn--upgrade'
        >
          CB: {isClayBrand ? 'On' : 'Off'}
        </button>
      }



      <DPColorPicker                              
        activeColorId={projectBackgroundId}
        onChange={(id)=>{handleUpdateProjectBackground(id)}}
        options={bgStyles}    
        statusBar      
      />       

      {/*}
      <SelectColorDropdown
        // activeBrand={activeBrand}
        activeColorId={projectBackgroundId}
        handleSelectColor={(id)=>{handleUpdateProjectBackground(id)}}
        handleClosePopover={() => setColorDropdownOpen(false)}
        colors={backgrounds}
        colorCount={backgrounds.length}
      >
        <button onClick={() => setColorDropdownOpen(!colorDropdownOpen)} className='editor-statusBar-bgBackgroundBtn'>
          <div style={backgroundColorPreview} className={'editor-statusBar-bgBackgroundBtn-colorPreview ' + (backgroundColorIsImage ? ' editor-statusBar-bgBackgroundBtn-colorPreview--image ' : ' ')}>
            {backgroundColorIsImage && 
             <img src={backgroundColorImage} />
            }
          </div>

          {!isAnyDropdownOpen && 
            <div className='editor-statusBar-btnTooltipContainer'>
              <div className='tooltip'>
                Edit Background
              </div>
            </div>
          }
        </button>
      </SelectColorDropdown>
      */}

      {/*}
        <DropdownMenu.Root modal={false} onOpenChange={setMotionDropdownOpen}> 
          <DropdownMenu.Trigger asChild>
            <button className='editor-statusBar-motionBtn'>
              <div className='editor-statusBar-motionBtn-iconContainer'>
                <Icon name='stopwatch' />
              </div>
              {/*}
              <div className='editor-statusBar-motionBtn-label'>
                {activeMotion}                
              </div>              
              {!isAnyDropdownOpen && 
                <div className='editor-statusBar-btnTooltipContainer'>
                  <div className='tooltip'>
                    Edit Motion Settings
                  </div>
                </div>
              }
            </button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content             
            align="start"
            alignOffset={-10}
            className='dropdownMenu dropdownMenu--selectMotion forceDarkTheme'
          >
            <div className='dropdownMenu-explainer'>
              Default motion settings for transitions and animations.
            </div>

            {motionOptions.map(option => (
              <DropdownMenu.Item key={option.value} onSelect={() => handleUpdateDefaultMotionStyle(option.value)} className={defaultMotionStyle === option.value ? 'dropdownMenu-item--active' : ''}>
                {option.label}
              </DropdownMenu.Item>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Root>  
        */}

      {!subscription_status &&       
        <button disabled={isEmpty} onClick={handleSettingsClick} className='editor-statusBar-exportBtn editor-statusBar-exportBtn--upgrade'>
          <div className='editor-statusBar-exportBtn-label editor-statusBar-exportBtn-label--disabled'>
            Export
          </div>
          <div className='editor-statusBar-exportBtn-label editor-statusBar-exportBtn-label--hover'>
            Upgrade
          </div>
        </button>
      }

      {/*
      {subscription_status &&       
        <button disabled={isEmpty} onClick={() => openExportWindow(projectId)} className='editor-statusBar-exportBtn editor-statusBar-exportBtn--default'>
          <div className='editor-statusBar-exportBtn-label editor-statusBar-exportBtn-label--default'>
            Export
          </div>          
        </button>
      }*/}
      
      
      {subscription_status &&       
        <button disabled={disabledExport} onClick={handleExportProject} className='editor-statusBar-exportBtn editor-statusBar-exportBtn--default'>
          <div className='editor-statusBar-exportBtn-label editor-statusBar-exportBtn-label--default'>
            Export
          </div>          
        </button>
      }
      



      {/*}
      <EditorStatusBarExportBtn 
        handleExportProject={handleExportProject}
        exportState={props.exportState}
        cancelExportProject={props.cancelExportProject}
      />
      */}
      
      
      {showVariantsPopover && 
        <EditorVariantPopover 
          projectVariables={projectVariables}
          refreshProjectVariables={refreshProjectVariables}
          projectId={props.projectId}
          projectName={props.projectName}
        >
          <button disabled={!hasVariants} data-active-state={hasVariants ? 'active' : 'inactive'} onClick={() => setVariantPopoverOpen(!variantPopoverOpen)} className='editor-statusBar-exportBtn editor-statusBar-exportBtn--variant'>
            <div className='editor-statusBar-exportBtn-iconContainer'>
              <Icon name='variants' />
            </div>
            {hasVariants &&
              <div className='editor-statusBar-exportBtn-label editor-statusBar-exportBtn-label--default'>
                Variants
              </div>
            }
          </button>  
        </EditorVariantPopover>
      }
      

    </div>
  );
}

// export default EditorStatusBar;

const MemoizedEditorStatusBar = memo(EditorStatusBar);

export default MemoizedEditorStatusBar;
