import React from 'react';
import * as THREE from 'three';
import CHRect from './CHRect';
import CHRectLabel from './CHRectLabel';

const FPS = 60; // Frames per second

const CHChartItem = ({value, width, borderRadius = 2, positionX, positionY, bottomCenterLabel, opacityAnimationState, heightAnimationState, time, animationType, initialHeight, isHidden, topCenterLabelAnimationStates, topCenterLabelOpacityAnimationStates, topCenterLabelPrefix, topCenterLabelSuffix, fontFamily, chartLabelFontWeight, topCenterLabel, isHighlight, isLowlight, barChartBarFillColor, barChartBarBorderColor, barChartValueLabelColor, barChartAxisLabelLabelColor, barChartMaxOpacities}) => {


  const frameIndex = Math.floor(time * FPS);

  // Get the correct frame opacity from the precalculated animation states
  const frameOpacity = opacityAnimationState[frameIndex] || 0; // Default to 0 if undefined

  const topCenterLabelTime = time - 0.5 // delayed
  const topCenterLabelOpacityFrameIndex = Math.floor(topCenterLabelTime * FPS);  

  // const frameOpacity = 1 
  let maxBorderOpacity = barChartMaxOpacities.border.default

  if(isLowlight){
    maxBorderOpacity = barChartMaxOpacities.border.lowlight
  }
  if(isHighlight){
    maxBorderOpacity = barChartMaxOpacities.border.highlight
  }

  let animatedBorderOpacity = frameOpacity * maxBorderOpacity

  let maxFillOpacity = barChartMaxOpacities.fill.default

  if(isLowlight){
    maxFillOpacity = barChartMaxOpacities.fill.lowlight
  }
  if(isHighlight){
    maxFillOpacity = barChartMaxOpacities.fill.highlight
  }

  let animatedFillOpacity = frameOpacity * maxFillOpacity


  let maxBottomCenterLabelOpacity = barChartMaxOpacities.label.default

  if(isLowlight){
    maxBottomCenterLabelOpacity = barChartMaxOpacities.label.lowlight
  }
  if(isHighlight){
    maxBottomCenterLabelOpacity = barChartMaxOpacities.label.highlight
  }

  let bottomCenterLabelOpacity = frameOpacity * maxBottomCenterLabelOpacity


  let maxTopCenterLabelOpacity = barChartMaxOpacities.value.default

  if(isLowlight){
    maxTopCenterLabelOpacity = barChartMaxOpacities.value.lowlight
  }
  if(isHighlight){
    maxTopCenterLabelOpacity = barChartMaxOpacities.value.highlight
  }


  let topLabelFrameOpacity = topCenterLabelOpacityAnimationStates[topCenterLabelOpacityFrameIndex] || 0
  let topCenterLabelOpacity = topLabelFrameOpacity * maxTopCenterLabelOpacity




  let frameHeight = heightAnimationState[frameIndex] || 0; // Default to 0 if undefined


  if(animationType === 'none'){
    animatedBorderOpacity = 1 * maxBorderOpacity
    animatedFillOpacity = 1 * maxFillOpacity
    frameHeight = initialHeight
    bottomCenterLabelOpacity = maxBottomCenterLabelOpacity
    topCenterLabelOpacity = maxTopCenterLabelOpacity  
  }

  if(isHidden){
    animatedBorderOpacity = 0
    animatedFillOpacity = 0
    frameHeight = 0
    bottomCenterLabelOpacity = 0
    topCenterLabelOpacity = 0
  }

  const formattedValue = typeof topCenterLabel === 'number' 
  ? topCenterLabel.toLocaleString() 
  : topCenterLabel;


  return (
    <>
      <group position={[positionX, positionY, 0]}>
        <CHRect
          height={frameHeight}
          width={width}
          borderRadius={borderRadius}
          borderColor={barChartBarBorderColor}
          borderOpacity={animatedBorderOpacity}
          fillColor={barChartBarFillColor}
          fillOpacity={animatedFillOpacity}
        />               
        
        {bottomCenterLabel && 
          <CHRectLabel
            key={"bottomCenter"}
            height={frameHeight}
            width={width}
            position="bottomCenter"
            labelHeight={50}
            minLabelWidth={200}
            labelGapVerticalOffset={24}
            labelGapHorizontalOffset={0}          
            labelText={bottomCenterLabel}
            frameOpacity={bottomCenterLabelOpacity}
            fontFamily={fontFamily}
            fontWeight={chartLabelFontWeight}
            color={barChartAxisLabelLabelColor}
          />  
        }
        {topCenterLabel !== null && topCenterLabel !== undefined && 
          <CHRectLabel
            key={"topCenter"}
            height={frameHeight}
            width={width}
            position="topCenter"
            labelHeight={50}
            minLabelWidth={200}
            labelGapVerticalOffset={4}
            labelGapHorizontalOffset={0}          
            labelText={`${topCenterLabelPrefix}${formattedValue}${topCenterLabelSuffix}`}
            frameOpacity={topCenterLabelOpacity}
            fontFamily={fontFamily}
            fontWeight={chartLabelFontWeight}
            color={barChartValueLabelColor}
          />
        }

      </group>

      {/*  
      <CHRectLabel
        key={"left"}
        height={height}
        width={width}
        position="left"
        labelHeight={50}
        minLabelWidth={200}
        labelGapVerticalOffset={0}
        labelGapHorizontalOffset={20}          
        labelText="Left Label that does this and that"
      />

      <CHRectLabel
        key={"right"}
        height={height}
        width={width}
        position="right"
        labelHeight={50}
        minLabelWidth={200}
        labelGapVerticalOffset={0}
        labelGapHorizontalOffset={20}          
        labelText="Right label that does this and that"
      />


                
      <CHRectLabel
        key={"topLeft"}
        height={height}
        width={width}
        position="topLeft"
        labelHeight={50}
        minLabelWidth={200}
        labelGapVerticalOffset={10}          
        labelText="Top left label that does this and that"
      />   
      

      <CHRectLabel
        key={"bottomLeft"}
        height={height}
        width={width}
        position="bottomLeft"
        labelHeight={50}
        minLabelWidth={200}
        labelGapVerticalOffset={10}
        labelGapHorizontalOffset={0}          
        labelText="Bottom left that does this and that"
              
      />  
      */}

      
    </>
  );
};

export default CHChartItem;
