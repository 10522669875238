import React from 'react';
import * as THREE from 'three';
import CHRectBorder from './CHRectBorder'
import CHRectFill from './CHRectFill'

const CHRect = ({height, width, borderRadius, borderColor, borderOpacity, borderLineWidth = 2, fillColor, fillOpacity}) => {
  
  // prevent border radius from affecting height and width
  // also prevent from overlapping caps 
  const adjustedFillWidth = Math.max(width - borderRadius, borderRadius)
  const adjustedFillHeight = Math.max(height - borderRadius, borderRadius)


  return (
    <>

      
      <CHRectBorder 
        height={height}
        width={width}
        borderRadius={borderRadius}
        borderColor={borderColor}
        borderOpacity={borderOpacity}
        borderLineWidth={borderLineWidth}
      />
      
      
      
      <CHRectFill 
        height={adjustedFillHeight}
        width={adjustedFillWidth}
        borderRadius={borderRadius}
        fillColor={fillColor}
        fillOpacity={fillOpacity}
      />     
      
    </>
  );
};

export default CHRect;
