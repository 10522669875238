
//We want to prevent groups merging with a webcam recoridng group
//we prevent backspace if it is at the start of the first transcript chunk in a group and the previous group is a webcam recordin

export const backspaceHandler = () => {
  return (state, dispatch, view) => {
    const { selection } = state;
    const { $cursor } = selection;
    
    // Proceed only if the selection is a cursor (not a range)
    if (!$cursor) return false;
    
    // Check if the cursor is at the beginning of its parent node
    if ($cursor.parentOffset > 0) return false;

    // Find the current transcript chunk and its depth
    let currentChunk = null;
    let chunkDepth = null;
    for (let i = $cursor.depth; i > 0; i--) {
      const node = $cursor.node(i);
      if (node.type.name === 'transcriptChunk') {
        currentChunk = node;
        chunkDepth = i;
        break;
      }
    }
    if (!currentChunk) return false;

    // Find the transcript group containing this chunk
    let currentGroup = null;
    let groupDepth = null;
    for (let i = chunkDepth - 1; i > 0; i--) {
      const node = $cursor.node(i);
      if (node.type.name === 'transcriptGroup') {
        currentGroup = node;
        groupDepth = i;
        break;
      }
    }
    if (!currentGroup) return false;

    // Get the positions of our current chunk
    const chunkPos = $cursor.before(chunkDepth);
    const groupPos = $cursor.before(groupDepth);
    
    // Check if there's any transcriptChunk before this one in the group
    const chunkIndex = $cursor.index(chunkDepth - 1);
    
    // If this isn't the first chunk in the group (index > 0), allow backspace
    if (chunkIndex > 0) {
      return false;
    }

    // Get the position at the start of the current 'transcriptGroup'
    const groupStartPos = $cursor.start(groupDepth);
    
    // If the group starts at position 0, there's no previous node
    if (groupStartPos === 0) return false;

    // Resolve the position just before the current 'transcriptGroup'
    const $prevPos = state.doc.resolve(groupStartPos - 1);
    
    // Get the node before the current 'transcriptGroup'
    const prevNode = $prevPos.nodeBefore;
    
    // For debugging
    console.log('Chunk index in group:', chunkIndex);
    console.log('Previous node:', prevNode?.type.name);
    console.log('Previous node group type:', prevNode?.attrs?.groupType);
    
    // Check if the previous node is a 'transcriptGroup' with webcam-recording type
    if (prevNode && 
        prevNode.type.name === 'transcriptGroup' && 
        prevNode.attrs.groupType === 'webcam-recording') {
      return true; // Prevent backspace
    }

    // Allow default behavior
    return false;
  };
};