import React, { useState, useEffect } from 'react';
import Icon from '../../../misc/Icon';
import {openPermissionsSettings} from '../../../../utils/recordingUtils/openPermissionsSettings'

const EditorNewScreenClipPermissions = ({closeModal, permissionsStatus}) => {
  
  

  return (
    <React.Fragment>
      <div className='selectWindowModal-permissionsHeader'>            
          <button onClick={closeModal} className='selectWindowModal-header-cancelBtn' >
            Cancel
          </button>
          <div className='selectWindowModal-permissionsHeader-label'>
            Recording Setup
          </div>                
          <div className='selectWindowModal-permissionsHeader-explainer'>
            Yarn requires some macOS permissions to record your screen and capture precise mouse movements while recording.
          </div>                      
        </div>  

        <div className='selectWindowModal-vSpacer' />
        
        <div className='selectWindowModal-permissionsRow'>            
          <div className='selectWindowModal-permissionsRow-label'>            
            Screen Recording
          </div>
          

          {permissionsStatus.screenRecordingPermissions == true && (
            <div className='selectWindowModal-permissionsRow-checkboxContainer'>
              <div className='selectWindowModal-permissionsRow-checkbox'>
                <Icon name='toDoItemCheckmark' />
              </div>
            </div>   
          )}

          {permissionsStatus.screenRecordingPermissions == false && (
            <div className='selectWindowModal-permissionsRow-btnContainer'>
              <button onClick={() => {openPermissionsSettings('screen')}} className='uiButton'>                
                <div className='uiButton-label'>
                  Open Settings
                </div>
              </button>
            </div> 
          )}
              


        </div>

        <div className='selectWindowModal-permissionsRow'>            
          <div className='selectWindowModal-permissionsRow-label'>              
            Accessibility
          </div>

          {permissionsStatus.accessibilityPermissions == true && (
            <div className='selectWindowModal-permissionsRow-checkboxContainer'>
              <div className='selectWindowModal-permissionsRow-checkbox'>
                <Icon name='toDoItemCheckmark' />
              </div>
            </div>   
          )}

          {permissionsStatus.accessibilityPermissions == false && (
            <div className='selectWindowModal-permissionsRow-btnContainer'>
              <button onClick={() => {openPermissionsSettings('accessibility')}} className='uiButton'>                
                <div className='uiButton-label'>
                  Open Settings
                </div>
              </button>
            </div> 
          )}


        </div>

        <div className='selectWindowModal-permissionsRowBottomSpacer' />  
    </React.Fragment>
  );
};

export default EditorNewScreenClipPermissions;