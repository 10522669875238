import React, { useState,useEffect, useRef } from 'react';
import * as Popover from '@radix-ui/react-popover';
import Icon from '../../../misc/Icon';


const AddMediaPopoverPrefillSearchesGalleryVideo = ({ prefillSearch, handleSelectItem }) => {

  const videoRef = useRef();

  const handleMouseEnter = () => {
    videoRef.current.play();
  };

  const handleMouseLeave = () => {
    const video = videoRef.current;
    if (video) {
      video.pause();
      video.currentTime = 0;  // Reset the video to the start
    }
  };

  //console.log(prefillSearch)
  //onClick={() => { insertVideoFromRecent(video) }} 

  return (
    <button onClick={() => handleSelectItem(video)} className='popover--addMediaPopover-onboarding-prefillGallery-item' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className='popover--addMediaPopover-onboarding-prefillGallery-item-inner'>
        <div className='popover--addMediaPopover-onboarding-prefillGallery-item-inner-videoContainer'>
          <video 
            ref={videoRef}            
            src={prefillSearch.previewMediaUrl}             
            muted
            loop
          />
        </div>
        <div className='popover--addMediaPopover-onboarding-prefillGallery-item-inner-label'>
          {prefillSearch.label}
        </div>
      </div>      
    </button>          
  )  
};


function AddMediaPopoverPrefillSearchesGallery({ prefillSearches, mediaType }) {
  
  return (
             
      <div className='popover--addMediaPopover-onboarding-prefillGallery'>
        {prefillSearches.map((prefillSearch) => (
          mediaType === 'video' ? (
            <AddMediaPopoverPrefillSearchesGalleryVideo
              key={prefillSearch.label}
              prefillSearch={prefillSearch}              
            />
          ) : (
            <button key={prefillSearch.label} className='popover--addMediaPopover-onboarding-prefillGallery-item'>
              <div className='popover--addMediaPopover-onboarding-prefillGallery-item-inner'>
                <div className='popover--addMediaPopover-onboarding-prefillGallery-item-inner-imageContainer'>
                  <img src={prefillSearch.previewMediaUrl} alt={prefillSearch.label} />
                </div>
                <div className='popover--addMediaPopover-onboarding-prefillGallery-item-inner-label'>
                  {prefillSearch.label}
                </div>
              </div>
            </button>
          )
        ))}
      </div>

  );
}

export default AddMediaPopoverPrefillSearchesGallery;
