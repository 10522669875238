import { Plugin, PluginKey } from "prosemirror-state"
import { Decoration, DecorationSet } from "prosemirror-view"

const variableHighlightKey = new PluginKey("variableHighlight")

function findVariables(doc) {
  const decorations = []
  const regex = /\{\{(.+?)\}\}/g

  doc.descendants((node, pos) => {
    if (node.isText) {
      let match
      while ((match = regex.exec(node.text)) !== null) {
        const start = pos + match.index
        const end = start + match[0].length
        decorations.push(
          Decoration.inline(start, end, {
            class: "variable-highlight"
          })
        )
      }
    }
  })

  return DecorationSet.create(doc, decorations)
}

const variableHighlightPlugin = new Plugin({
  key: variableHighlightKey,
  state: {
    init(_, { doc }) {
      return findVariables(doc)
    },
    apply(tr, set) {
      return tr.docChanged ? findVariables(tr.doc) : set.map(tr.mapping, tr.doc)
    }
  },
  props: {
    decorations(state) {
      return this.getState(state)
    }
  }
})

export default variableHighlightPlugin