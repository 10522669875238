import React, { useState, useEffect } from 'react';


const DECIMAL_PLACES = 3  

const EditorTimelineSnapLines = (props) => {
	const {
		clips,
		pixelsPerSec,
		isDragging,
		dragClipId,
		sceneGap,
		dndMode,
		scenes,
		dragDirection,
		resizeDirection
	} = props


	const [initialClipPositions, setInitialClipPositions] = useState([])

	const [initialScenePositions, setInitialScenePositions] = useState([])

	const [lastDragDirection, setLastDragDirection] = useState(null);

	useEffect(() => {
		if (isDragging && initialClipPositions.length==0 || (dragDirection && dragDirection !== lastDragDirection)) { //reset if the drag direction changes so that it shows the line if drag away and then back
			setInitialClipPositions(clips.map(clip => ({ id: clip.id, startTime: clip.startTime, endTime: clip.endTime })));
			setLastDragDirection(dragDirection);
		}
		if (isDragging && initialScenePositions.length==0) {
			setInitialScenePositions(scenes.map(scene => ({ id: scene.id, startTime: scene.startTime, endTime: scene.endTime })));
		}
		if(!isDragging){
			setInitialClipPositions([])
			setInitialScenePositions([])
			 setLastDragDirection(null);
		}
	}, [isDragging,dragDirection, clips])



	const isItemPushed = (item, initialPositions) => {
		const initialItem = initialPositions.find(initialItem => initialItem.id === item.id);
		return initialItem && (item.startTime !== initialItem.startTime || item.endTime !== initialItem.endTime);
	};




	const draggedClip = clips.find(clip => clip.id === dragClipId);   
	
	if(isDragging && !dndMode && draggedClip && draggedClip.type!=='audio'){
		return (
			<>


			{scenes.map(scene => {
				const sceneGapOffset = scene.sceneIndex * sceneGap
				const sceneStartPx = scene.startTime * pixelsPerSec +sceneGapOffset
				const sceneEndPx = (scene.startTime + scene.duration) * pixelsPerSec + sceneGapOffset
				let showStartLine = false    
				let showEndLine = false 

				if(draggedClip && scene.id == draggedClip.scene.id){ //only show snap lines within the scene
					if (parseFloat(draggedClip.startTime.toFixed(DECIMAL_PLACES)) === parseFloat(scene.startTime.toFixed(DECIMAL_PLACES))) {
						showStartLine = true;
					}
					{/*if (parseFloat(draggedClip.endTime.toFixed(DECIMAL_PLACES)) === parseFloat(scene.endTime.toFixed(DECIMAL_PLACES))) {
						showEndLine = true;
					}*/}
				}



			{/*		if (resizeDirection=='left') {
							if (
								parseFloat(draggedClip.endTime.toFixed(DECIMAL_PLACES)) === parseFloat(scene.startTime.toFixed(DECIMAL_PLACES))) {
								showStartLine = false;
							}
					}*/}
					if (resizeDirection=='right') {
							if (parseFloat(draggedClip.startTime.toFixed(DECIMAL_PLACES)) === parseFloat(scene.startTime.toFixed(DECIMAL_PLACES))){
								showStartLine = false;
							}
							}

					
						if (resizeDirection=='left') {
							if (parseFloat(draggedClip.endTime.toFixed(DECIMAL_PLACES)) === parseFloat(scene.endTime.toFixed(DECIMAL_PLACES))){
								showEndLine = false;
							}
							}
					


				const pushed = isItemPushed(scene, initialScenePositions)
				if(!pushed){
					return(
							<React.Fragment key={`snap_lines_${scene.id}`}>
							{showStartLine &&
							<div  style={{left: `${sceneStartPx}px`}} className='editor-timeline-snapLine' />
							}
							{showEndLine &&
							<div style={{left: `${sceneEndPx}px`}} className='editor-timeline-snapLine' />
							}
							</React.Fragment >
							)
				}})}

				{clips.map(clip => {
						const sceneGapOffset = clip.sceneIndex * sceneGap
						const clipStartPx = clip.startTime * pixelsPerSec +sceneGapOffset
						const clipEndPx = (clip.startTime + clip.duration) * pixelsPerSec + sceneGapOffset
						let showStartLine = false    
						let showEndLine = false 

						if(draggedClip && clip.scene.id == draggedClip.scene.id){ //only show snap lines within the scene

						if (clip.type!=='audio' && clip.id !== dragClipId && draggedClip) {
							if (parseFloat(draggedClip.startTime.toFixed(DECIMAL_PLACES)) === parseFloat(clip.startTime.toFixed(DECIMAL_PLACES)) ||
								parseFloat(draggedClip.endTime.toFixed(DECIMAL_PLACES)) === parseFloat(clip.startTime.toFixed(DECIMAL_PLACES))) {
								showStartLine = true;
							}
							if (parseFloat(draggedClip.startTime.toFixed(DECIMAL_PLACES)) === parseFloat(clip.endTime.toFixed(DECIMAL_PLACES)) ||
								parseFloat(draggedClip.endTime.toFixed(DECIMAL_PLACES)) === parseFloat(clip.endTime.toFixed(DECIMAL_PLACES))) {
								showEndLine = true;
							}
						}
					}


					if (resizeDirection=='left') {
							if (
								parseFloat(draggedClip.endTime.toFixed(DECIMAL_PLACES)) === parseFloat(clip.startTime.toFixed(DECIMAL_PLACES))) {
								showStartLine = false;
							}
					}
					if (resizeDirection=='right') {
							if (
								parseFloat(draggedClip.startTime.toFixed(DECIMAL_PLACES)) === parseFloat(clip.endTime.toFixed(DECIMAL_PLACES))) {
								showEndLine = false;
							}
					}


					  const pushed = isItemPushed(clip, initialClipPositions);

						if(!pushed){
							return(
						<React.Fragment key={`snap_lines_${clip.id}`}>
							{showStartLine &&
								<div  style={{left: `${clipStartPx}px`}} className='editor-timeline-snapLine' />
							}
							{showEndLine &&
							 <div style={{left: `${clipEndPx}px`}} className='editor-timeline-snapLine' />
						 }
						</React.Fragment >
					)}})}
			 </>
	);
} 
};

export default EditorTimelineSnapLines;
