const sampleBarChartData = {  
  // g2Reviews: [        
  //   [{ value: "Five stars" }, { value: "4,095" }],
  //   [{ value: "Four" }, { value: "818" }],
  //   [{ value: "Three" }, { value: "65" }],
  //   [{ value: "Two" }, { value: "6" }],
  //   [{ value: "One" }, { value: "13" }],    
  //   [{ value: "" }, { value: "" }],  
  // ],
  // notionJira: [    
  //   [{ value: "Notion" }, { value: "4.7" }],
  //   [{ value: "Jira" }, { value: "4.3" }],
  //   [{ value: "" }, { value: "" }],
  //   [{ value: "" }, { value: "" }],
  //   [{ value: "" }, { value: "" }],
  //   [{ value: "" }, { value: "" }],    
  // ],
  pump: [        
    [{ value: "Pump" }, { value: "$45k/month" }],
    [{ value: "AWS" }, { value: "$80k/month" }],
    [{ value: "" }, { value: "" }],
    [{ value: "" }, { value: "" }],
    [{ value: "" }, { value: "" }],    
    [{ value: "" }, { value: "" }],  
  ],
  bun: [        
    [{ value: "66,706" }, { value: "Bun" }],
    [{ value: "32,291" }, { value: "Deno.serve()" }],
    [{ value: "13,979" }, { value: "Node.js" }],
    [{ value: "" }, { value: "" }],
    [{ value: "" }, { value: "" }],
    [{ value: "" }, { value: "" }],
  ],
  benchmark: [        
    [{ value: "QWen" }, { value: "58.6" }],
    [{ value: "LLava" }, { value: "62.4" }],
    [{ value: "Fuyu-8B" }, { value: "84.8" }],
    [{ value: "" }, { value: "" }],
    [{ value: "" }, { value: "" }],    
    [{ value: "" }, { value: "" }],    
  ],
  
};

export default sampleBarChartData;



