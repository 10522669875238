import React, { useRef, useEffect } from 'react'
import Icon from '../../../../misc/Icon';

function SelectVoicePopoverVoiceButton({ 
  voice, 
  active, 
  handleChangeActiveVoice, 
  handleClosePopover, 
  hideSubLabel,
  currentlyPlayingId,
  setCurrentlyPlayingId
}) {
  const audioRef = useRef(null);

  const handleClick = () => {
    handleClosePopover()
    handleChangeActiveVoice(voice.id);
  };

  const playSample = (e) => {
    e.stopPropagation()
    if (voice.sampleAudio) {
      if (currentlyPlayingId === voice.id) {
        // Stop playing this voice
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
        }
        setCurrentlyPlayingId(null);
      } else {
        // Stop any currently playing audio
        if (currentlyPlayingId) {
          setCurrentlyPlayingId(null);
        }
        // Start playing this voice
        const newAudio = new Audio(voice.sampleAudio);
        newAudio.onended = () => {
          setCurrentlyPlayingId(null);
          newAudio.src = ''; // Release the audio source
          newAudio.load(); // Force the audio to reload and release the buffer
        };
        newAudio.play();
        audioRef.current = newAudio;
        setCurrentlyPlayingId(voice.id);
      }
    }
  }

  useEffect(() => {
    // Cleanup function to stop audio when component unmounts
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.src = '';
        audioRef.current.load();
      }
    };
  }, []);

  useEffect(() => {
    // Stop playing if another voice starts playing
    if (currentlyPlayingId && currentlyPlayingId !== voice.id && audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  }, [currentlyPlayingId, voice.id]);

  let subLabel = {
    'uk': 'British',
    'ng': 'Nigerian',
    'dn': 'Danish',
    'se': 'Swedish',
    'it': 'Italian',
    'jp': 'Japanese',
    'nl': 'Dutch',
    'fr': 'French',
    'de': 'Germany',
    'es': 'Spanish'
  }[voice.country] || '';

  const isPlaying = currentlyPlayingId === voice.id;

  return (
    <button 
      data-state={active ? "active" : "inactive"} 
      className='selectVoiceDropdown-grid-item'
      onClick={handleClick}
    >
      {voice.sampleAudio && 
        <div onClick={playSample} data-playing-state={isPlaying} className='selectVoiceDropdown-grid-item-sampleBtn'>        
          <div className='selectVoiceDropdown-grid-item-sampleBtn-inner'>
            {!isPlaying &&
              <Icon name='speaker-wave-2' />        
            }
            {isPlaying &&
              <Icon name='chunkPause' />        
            }
          </div>
        </div>      
      }
      {!voice.sampleAudio && 
        <div className='selectVoiceDropdown-grid-item-noSampleBtnSpacer' />
      }
      <div className='selectVoiceDropdown-grid-item-name'>
        {voice.name}
      </div>      
      {subLabel && !hideSubLabel &&
      <div className='selectVoiceDropdown-grid-item-sub'>
        {subLabel}
      </div>   
      }   
    </button>
  );
}

export default SelectVoicePopoverVoiceButton;