import { ExportTimeline} from './ExportTimeline';



const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

//For all media items create a load media promise and when they all resolve set readyToExport to true


async function createExportTimeline(project,exportStartTime,exportEndTime,onTimeUpdate,setReadyToExport,renderVariables) {
  try {

    const newTimeline = new ExportTimeline(exportStartTime,exportEndTime,onTimeUpdate,renderVariables);

    

    let scenes = project.timeline.scenes


    await newTimeline.initScenes(scenes)

    await Promise.all(newTimeline.loadMediaPromises);



    //temp add a delay before setting is ready to export
   // await delay(1000);

    // setReadyToExport(true);
    return newTimeline;

  } catch (error) {
    console.error('Error creating timeline:', error);
    throw error; // Rethrow or handle the error as appropriate
  }
}

export { createExportTimeline };

