import React, { useState } from 'react'
import * as Popover from '@radix-ui/react-popover';

function SelectVoicePopoverSpeedDropdownPlaybackRateBtn({ label, handleSelectPlaybackRate, playbackRate, activeVoiceoverPlaybackRate}) {
  
  const handleClick = () => {
    handleSelectPlaybackRate(playbackRate)      
  };

  const active = activeVoiceoverPlaybackRate === playbackRate;

  return (
    <div       
      className={'popoverMenu-item ' + (active ? 'popoverMenu-item--active' : '')}
      onClick={handleClick}
    >
      {label}
    </div>
  );
}

function SelectVoicePopoverSpeedDropdown({children, handleChangeVoiceoverPlaybackRate, activeVoiceoverPlaybackRate}) {
  const [open, setOpen] = useState(false);

  const handleOpenChange = (isOpen) => {
    setOpen(isOpen);
  };

  const handleSelectPlaybackRate = (playbackRate) => {
    handleChangeVoiceoverPlaybackRate(playbackRate);
    setOpen(false);
  };

  return (
    <Popover.Root modal={true} open={open} onOpenChange={handleOpenChange}>
      <Popover.Trigger asChild>
        {children}
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content 
          sideOffset={-1}
          align="start"
          alignOffset={-5}
          className='dropdownMenu dropdownMenu--voiceoverSpeed forceDarkTheme'          
        >
          <div className='dropdownMenu-explainer'>
            Adjust talking speed
          </div>
          <SelectVoicePopoverSpeedDropdownPlaybackRateBtn 
            label='Slowest'
            playbackRate={0.92}
            activeVoiceoverPlaybackRate={activeVoiceoverPlaybackRate}
            handleSelectPlaybackRate={handleSelectPlaybackRate}
          />
          <SelectVoicePopoverSpeedDropdownPlaybackRateBtn 
            label='Slow'
            playbackRate={0.96}
            activeVoiceoverPlaybackRate={activeVoiceoverPlaybackRate}
            handleSelectPlaybackRate={handleSelectPlaybackRate}
          />
          <SelectVoicePopoverSpeedDropdownPlaybackRateBtn 
            label='Default'
            playbackRate={1}
            activeVoiceoverPlaybackRate={activeVoiceoverPlaybackRate}
            handleSelectPlaybackRate={handleSelectPlaybackRate}
          />
          <SelectVoicePopoverSpeedDropdownPlaybackRateBtn 
            label='Fast'
            playbackRate={1.04}
            activeVoiceoverPlaybackRate={activeVoiceoverPlaybackRate}
            handleSelectPlaybackRate={handleSelectPlaybackRate}
          />
          <SelectVoicePopoverSpeedDropdownPlaybackRateBtn 
            label='Faster'
            playbackRate={1.08}
            activeVoiceoverPlaybackRate={activeVoiceoverPlaybackRate}
            handleSelectPlaybackRate={handleSelectPlaybackRate}
          />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

export default SelectVoicePopoverSpeedDropdown;