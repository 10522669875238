import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import GoogleLoginButton from '../../components/auth/GoogleLoginButton';
import Icon from '../../components/misc/Icon';
import ElectronMacDots from '../../components/ElectronMacDots';

const TextInputItem = React.forwardRef(({ value, label, placeholder, setValue }, ref) => {
  const handleInputChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div className='signUpFlow-panel-textInputItem'>
      <div className='signUpFlow-panel-textInputItem-label'>
        {label}
      </div>
      <div className='signUpFlow-panel-textInputItem-inputContainer'>
        <input 
          className='signUpFlow-panel-textInputItem-input' 
          value={value}
          onChange={handleInputChange}
          placeholder={placeholder}
          ref={ref} // Attach the forwarded ref to the input element
        />
      </div>
    </div>
  );
});


const LoginContainer = (props) => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [showSSOForm, setShowSSOForm] = useState(false);

  useEffect(() => {
    const query = qs.parse(props.location.search.slice(1));
    const error = query.error;
    if (error) {
      setErrorMessage('Please sign in with your work Google account.');
    }
  }, [props.location.search]); // Dependency array to re-run effect if search changes

  const emailInputRef = useRef(null); // Create a ref for the input

  useEffect(() => {
    if (showSSOForm) {
      emailInputRef.current.focus(); // Focus the input field when showSSOForm is true
    }
  }, [showSSOForm]); // Dependency array includes showSSOForm to run this effect on its change


  const isValidDomain = (email) => {
    // Here, implement your domain validation logic
    // For example, check if the domain is in a list of allowed domains
    const domain = email.split('@')[1];
    return ['example.com', 'faire.com','ext.faire.com'].includes(domain); // Example domains
  };


  const handleClickSSO = () => {
    if (isValidDomain(email)) {
      const url = `${process.env.REACT_APP_API_ENDPOINT}/auth/sso`;
      if (window.isElectron) {
        window.ipcRenderer.send('open-oauth-window', { url: url });
      } else {
        window.location = url;
      }
    } else {
      setErrorMessage('Invalid email domain for SSO.');
    }
  };



  useEffect(() => {
    const handleEnterPress = (event) => {
      if (event.key === 'Enter' && email.trim() !== '') {
        handleClickSSO();
      }
    };
    if (showSSOForm) {
      // Add event listener when the component mounts or showSSOForm becomes true
      document.addEventListener('keydown', handleEnterPress);
    }
    // Cleanup - remove event listener
    return () => document.removeEventListener('keydown', handleEnterPress);
  }, [email, showSSOForm]); // This effect depends on email and showSSOForm


  return (
    <div className="loginPageContainer">
      <div className='signUpFlow'>
        <div className='signUpFlow-panel signUpFlow-panel--stage--login'>
          <div className='signUpFlow-panel-logo'>
            <img src='./yarnlogo.png' />
          </div>
          <div className='signUpFlow-panel-title'>
            Log in
          </div>

          <div className='signUpFlow-panel-middle' />
          <div className='signUpFlow-panel-submitBtnContainer'>
            <GoogleLoginButton />

            {!showSSOForm && 
            	<>
            	<div className='signUpFlow-panel-submitBtnContainer-smallVSpacer' />
	            <button onClick={() => setShowSSOForm(true)} className='signUpFlow-panel-submitBtn signUpFlow-panel-submitBtn--sso'>							
                  <div className='signUpFlow-panel-submitBtn-iconContainer'>
                    <Icon name='key' />
                  </div>
                  <div className='signUpFlow-panel-submitBtn-label'>
                    Sign in with SSO
                  </div>              

							</button>
							</>
						}


            {showSSOForm && 
	            <>
	            	<div className='signUpFlow-panel-submitBtnContainer-smallVSpacer' />
	            	<div className='signUpFlow-panel-submitBtnContainer-smallVSpacer' />
		            <TextInputItem 
									label='Email'
                  value={email}
                  setValue={setEmail}
									placeholder='Email Address'
                  ref={emailInputRef}
								/>

								<button onClick={handleClickSSO} className='signUpFlow-panel-submitBtn signUpFlow-panel-submitBtn--continueWithSSO'>
									Continue with SSO
								</button>

							</>
						}            

            {errorMessage && (
              <div className='signUpFlow-panel-submitBtnErrorContainer'>
                {errorMessage}
              </div>
            )}

          {/*  {errorMessage && (
							<div className="error-message">
								Error: {errorMessage}
							</div>
						)}
*/}
          </div>

          
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

// The wrapping for Redux and React Router remains the same
export default withRouter(connect(mapStateToProps)(LoginContainer));
