import React, {Component} from 'react';
import { fontWeightToNumber } from '../../utils/fontWeightToNumber';

//fontSizeMultiplier is used to calculate the right font size for text elements with a maxHeight

const processText = (text) => {
  return text.split('\n').map(line => line.trim() === '' ? 'W' : line).join('\n');
};



class SlideTextElementGhost extends React.Component {
  
  render() {

    const {element,width,fontSizeMultiplier} = this.props

    let multiplier = 1 
    if(fontSizeMultiplier){
      multiplier=fontSizeMultiplier
    }

    const textStyle = element.metadata.textStyle 
    let style={}

    if(textStyle){
      const values = element.metadata.textProperties
      const fontSize = parseFloat(values.fontSize);
      const adjustedFontSize = fontSize * multiplier
      style = {     
        fontFamily: `${values.fontFamily}`,
        fontSize: `${adjustedFontSize}px`,
        textAlign: `${values.textAlignStyle}`,
        fontWeight: `${fontWeightToNumber(values.fontWeight)}`,       
        letterSpacing: `${values.letterSpacing}em`,
        lineHeight: `${values.lineHeight}`,        
      }    
    }
    if(element.metadata.textResizeType=='autoHeight' || element.metadata.textResizeType=='fixed'){
      style.width=width
    }

    let divId = `ghost_${element.id}`
    if(fontSizeMultiplier){
      divId=`ghost_${element.id}_dynamic_${fontSizeMultiplier}`
    }

    //Make sure empty lines have a height by adding a character in them
    const processedText = processText(element.metadata.text);

    return (
      <>
        <div id={divId} style={{...style}} className='editor-slideEditor-ghostTextItem'>
          {/*{element.metadata.text}*/}
        {processedText}
        </div>        
      </>
    );
  }
}


export default SlideTextElementGhost
