const initialState = {
	recordings: {} // Format: { [captureId]: { files: { [fileName]: { status, progress } } } }
};

export default function(state = {}, action) {
	switch(action.type) {
		case 'SET_IMAGE_FILE_TO_DOWNLOAD':
			const { imageId } = action;
			return {
				...state,
				[imageId]:'downloading'
			}

   case 'IMAGE_DOWNLOAD_SUCCESS':
      const { imageId: successImageId } = action;
      return {
        ...state,
        [successImageId]: 'completed', // Update the status to 'completed' on successful download
      };


		default:
			return state;
	}
}
