import React, { useState } from 'react';
import Icon from '../../../misc/Icon';
import * as Tooltip from '@radix-ui/react-tooltip';
import DPColorPicker from '../DPColorPicker';
import {getChartColorForBackgroundId} from '../../../../utils/brands/getChartColorForBackgroundId'
import {getBackgroundForId} from '../../../../utils/brands/getBackgroundForId'

const BarChartElementSpreadsheetRowBtns = ({cellHeight,barRowHighlightStates,setBarRowHighlightStates, focusSpreadsheet, barColorType, maxRow, availableChartColors,barCategoryColorIds,setBarCategoryColorIds, uniformColorId, projectBackgroundId, slideBackgroundId, barOpacity}) => {

  // Function to handle button click and update rowStatus
  const toggleRowStatus = (index, event) => {
    event.preventDefault();  // Prevent the default action of the button
    event.stopPropagation(); // Stop the click event from bubbling up
    const newRowStatuses = [...barRowHighlightStates];
    switch (newRowStatuses[index]) {
      case 'default':
        newRowStatuses[index] = 'highlight';
        break;
      case 'highlight':
        newRowStatuses[index] = 'lowlight';
        break;
      case 'lowlight':
        newRowStatuses[index] = 'default';
        break;
      default:
        break;
    }
    setBarRowHighlightStates(newRowStatuses);
    focusSpreadsheet();
  };

  let chartBackgroundId = projectBackgroundId
  const isNoneBGColor = slideBackgroundId =='none'

  if(slideBackgroundId && !isNoneBGColor){
    chartBackgroundId=getBackgroundForId(slideBackgroundId)
  }


  const chartBackground = getBackgroundForId(chartBackgroundId).rgba
  // console.log('chartBackground', chartBackground)
  // we apply the bg color to the row buttons to give a preview
  // doesn't work for image backgrounds, they are currently huge pngs
  // maybe can do something here in future dunno

  let uniformChartColorId = uniformColorId
  // console.log('uniformChartColorId',uniformChartColorId)
  if(!uniformColorId || uniformColorId === 'auto'){ // auto    
    uniformChartColorId = getChartColorForBackgroundId(chartBackgroundId)
  }

  // console.log('uniformChartColorId',uniformChartColorId)

  let uniformChartColor = getBackgroundForId(uniformChartColorId).rgba
  if(uniformChartColorId === 'white'){
    uniformChartColor = 'rgba(255,255,255)'
  }


  const handleColorChange = (value, index) => {
    const newBarCategoryColorIds = [...barCategoryColorIds];
    newBarCategoryColorIds[index] = value;
    setBarCategoryColorIds(newBarCategoryColorIds);
  };

  
  // taken from slide bar chart container
   const getInnerOpacity = (status) => {
    switch (status) {
      case 'default':
        return barOpacity;
      case 'lowlight':
        return barOpacity * 0.66;
      case 'highlight':
        return Math.min(barOpacity * 1.55, 100);
      default:
        return barOpacity;
    }
  };

  // console.log(getInnerOpacity('default'))
  // console.log(getInnerOpacity('lowlight'))
  // console.log(getInnerOpacity('highlight'))

  return (
    <div className='editor-detailPanel-spreadsheet-rowStatesList'>
      {barColorType === 'uniform' && barRowHighlightStates.map((status, index) => (
        // set innerOpacity
        // if status = default, then do barOpacity
        // if status = lowlight, then do barOpacity * 0.66
        // if status = default, then do Math.min(barOpacity * 1.55, 1)

        <Tooltip.Root delayDuration={0}>
          <Tooltip.Trigger asChild> 
            <button
              key={`${barColorType}--${index}`}
              data-highlight-status={status}
              data-above-max-row={index >= maxRow ? 'true' : 'false'} // Add this line
              onClick={(e) => toggleRowStatus(index, e)}  // Pass the event to the handler
              className={`editor-detailPanel-spreadsheetRowBtn editor-detailPanel-spreadsheetRowBtn--uniform editor-detailPanel-spreadsheetRowBtn--state--${status}`}
              style={{ height: `${cellHeight}px`, background: `${chartBackground}` }} // replace with the desired height
            >
              <div style={{background: `${uniformChartColor}`, opacity: `${getInnerOpacity(status) / 100}` }} className='editor-detailPanel-spreadsheetRowBtn--inner' />              
            </button> 
          </Tooltip.Trigger>   
           
            <Tooltip.Content side="left" className="tooltip">
              {status == 'default' && 
                <span>Default Fill</span>
              }
              {status == 'highlight' && 
                <span>Highlight Fill</span>
              }
              {status == 'lowlight' && 
                <span>Lowlight Fill</span>
              }

            </Tooltip.Content>    
                         
        </Tooltip.Root>    

        
      ))}

      {barColorType === 'category' && barCategoryColorIds.map((colorId, index) => (   
            <button
              key={`${barColorType}--${index}`}
              data-above-max-row={index >= maxRow ? 'true' : 'false'} // Add this line            
              className='editor-detailPanel-spreadsheetRowBtn editor-detailPanel-spreadsheetRowBtn--color'
              style={{ height: `${cellHeight}px`, background: `${chartBackground}`}} // replace with the desired height
            >
              <DPColorPicker                    
                activeColorId={colorId}
                onChange={(value) => handleColorChange(value, index)}
                //onChange={(value) => console.log(`change color ---- ${value}`)}
                options={availableChartColors}              
                chartColors
                spreadsheet
              />   
              
            </button> 
            
       
   
        
      ))}

    </div>
  );
};

export default BarChartElementSpreadsheetRowBtns;
