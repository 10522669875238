import React, { useState,useEffect,useCallback } from 'react';
import * as Popover from '@radix-ui/react-popover';
import Icon from '../../../misc/Icon';
import orderBy from 'lodash/orderBy' 
import {getRecentMedia} from '../../../../utils/getRecentMedia'
import AddMediaPopoverResultsList from './AddMediaPopoverResultsList'
import AddMediaPopoverPrefillSearchesGallery from './AddMediaPopoverPrefillSearchesGallery'
import { prefillSearchesImage, prefillSearchesVideo } from './prefillSearchTerms';
//import {searchPexels} from '../../../../actions/imageSearch'
import { debounce } from 'lodash';
import filter from 'lodash/filter'
import {getVideoFileDuration}  from '../../../../utils/getVideoFileDuration' 


// const getVideoDuration = (file) => {
//   return new Promise((resolve, reject) => {
//     const video = document.createElement('video');
//     video.preload = 'metadata';
//     const url = URL.createObjectURL(file);
//     video.src = url;
//     video.onloadedmetadata = () => {
//       window.URL.revokeObjectURL(url);
//       resolve(video.duration);
//     };
//     video.onerror = () => {
//       window.URL.revokeObjectURL(url);
//       reject(new Error("Failed to load video metadata"));
//     };
//   });
// };



function AddMediaPopoverContents(props) {
  const {     
    handleClosePopover,    
    handleNewImageFileUpload,
    insertImageFromRecent,
    handleNewVideoFileUpload,
    insertVideoFromRecent, 
    mediaType ,
    handleNewWebcamFileUpload,
    insertWebcamFromRecent,
    isWebcam,
    recentMedia
  }=props

  const [submitLoading, setSubmitLoading] = useState(false);

  const closePopover = () =>{
    handleClosePopover()
  }

  const handleUploadClick = () => {
    document.getElementById('file-upload').click();
    setSubmitLoading(false);
  };


const handleSelectFile = (file) => {
  console.log('select file', file.type);
  setSubmitLoading(true);
  
  const processFile = async () => {
    try {
      if (file.type.startsWith('image/')) {
        handleNewImageFileUpload(file);
      } else if (file.type.startsWith('video/') || file.type === 'video/quicktime') {
        const filePath = file.path; // Get the file path
       console.log('filepath is',filePath)
        const duration = await getVideoFileDuration(filePath);
        console.log(`Video duration: ${duration} seconds`);
        if (isWebcam) {
          handleNewWebcamFileUpload(file, duration);
        } else {
          handleNewVideoFileUpload(file, duration);
        }
      }
    } catch (error) {
      console.error('Error processing file:', error);
    } finally {
      setSubmitLoading(false);
      closePopover();
    }
  };

  processFile();
};

// const handleSelectFile = (file) => {
//   console.log('select file')
//   console.log(file)
//   console.log(file.type)
//   setSubmitLoading(true); // Indicate loading
//   const processFile = () => {
//     if (file.type.startsWith('image/')) {
//       handleNewImageFileUpload(file);
//       setSubmitLoading(false);
//       closePopover();
//     } else if (file.type.startsWith('video/') || file.type == 'video/quicktime'){
//      console.log('this bit here------------')
//       getVideoDuration(file).then(duration => {
//         console.log(`Video duration: ${duration} seconds`);
//        if(isWebcam){
//         handleNewWebcamFileUpload(file,duration)
//        }else{
//          handleNewVideoFileUpload(file, duration)
//        }
//         setSubmitLoading(false);
//         closePopover();
//       }).catch(error => {
//       //  console.error('Error getting video duration', error);
//         setSubmitLoading(false);
//       });
//     } else {
//       setSubmitLoading(false);
//     }
//   };
//   // Call processFile to handle file upload
//   processFile();
// };

  const handleSelectRecentImage=(image)=>{
    insertImageFromRecent(image)
    closePopover()
  }


  const handleSelectRecentVideo=(video)=>{
    if(isWebcam){ 
      insertWebcamFromRecent(video)
      closePopover()

    }else{
      insertVideoFromRecent(video)
      closePopover()
    }
  }

  const [inputValue, setInputValue] = useState('');
  const inputEmpty = inputValue.length < 1

  let prefillSearches = prefillSearchesImage
  if (mediaType === 'video') {
    prefillSearches = prefillSearchesVideo
  }


  const noRecentMedia = false
  const isOnboarding = noRecentMedia && inputEmpty

 // let uploadFileTypeAccept = 'image/*,video/*'; //'mixed'
  let uploadFileTypeAccept = 'image/*,video/*,.mov';
  if (mediaType === 'image') {
    uploadFileTypeAccept = 'image/*';
  } else if (mediaType === 'video') {
    uploadFileTypeAccept = 'video/*,.mov';
  }


  // let rowWidth = 670
  // let minListHeight = 256
  // if(mediaType === 'video'){
  //   rowWidth = 790 // to fit four 16/9 videos in 
  //   minListHeight = 294
  // }
  let rowWidth = 920
  let minListHeight = 256

  if(mediaType === 'video'){
    rowWidth = 996 // to fit four 16/9 videos in 
    minListHeight = 294
  }

  const [searchResults, setSearchResults] = useState([]);

  const debouncedSearch = useCallback(debounce((query) => {
    searchPexels(query).then(results => {
      let parsedResults=[]
      //format in same way as we save
      results.photos.forEach((result)=>{
        let parsedResult={...result}
        parsedResult.original_width = result.width
        parsedResult.original_height = result.height
        parsedResult.delivery_url = result.src.small
        parsedResults.push(parsedResult)
      })
      setSearchResults(parsedResults);
    });
  }, 1000), []);

  useEffect(() => {
    if (inputValue.trim()) {
      debouncedSearch(inputValue);
    } else {
      setSearchResults([]);
    }
  }, [inputValue, debouncedSearch]);


  const handleSelectMedia=(item)=>{
    if(item.type=='image'){
      handleSelectRecentImage(item)
    }
    if(item.type=='video'){
      handleSelectRecentVideo(item)
    }
    if(item.type=='webcamVideo'){
      handleSelectRecentVideo(item)
    }
  }

  return (
    <>

        {/*}
        <div className='popover--addMediaPopover-header'>
          <div className={'popover--addMediaPopover-header-searchInputContainer ' + (inputEmpty ? ' popover--addMediaPopover-header-searchInputContainer--empty ' : ' popover--addMediaPopover-header-searchInputContainer--notEmpty ')}>
            <input
              type="text"
              className='popover--addMediaPopover-header-searchInput'
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="Search Internet..."              
            />
            <div className='popover--addMediaPopover-header-leftSearchUI'>
              <div className='popover--addMediaPopover-header-leftSearchUI-iconContainer'>
                <Icon name='magnifyingGlass' />
              </div>
            </div>
            <div className='popover--addMediaPopover-header-rightSearchUI'>
              {!isOnboarding && prefillSearches.slice(0, 3).map((prefillSearch) => (
                <button key={prefillSearch.label} className='popover--addMediaPopover-header-rightSearchUI-prefillSearchBtn'>
                  <div className='popover--addMediaPopover-header-rightSearchUI-prefillSearchBtn-inner'>
                    <div className='popover--addMediaPopover-header-rightSearchUI-prefillSearchBtn-inner-label'>
                      {prefillSearch.label}
                    </div>
                  </div>
                </button>
              ))}

              <div className='popover--addMediaPopover-header-rightSearchUI-loadingSpinnerContainer'>
                <div className='popover--addMediaPopover-header-rightSearchUI-loadingSpinner' />
              </div>

              {/*}
              <button className='popover--addMediaPopover-header-rightSearchUI-searchBtn'>
                <div className='popover--addMediaPopover-header-rightSearchUI-searchBtn-inner'>
                  <div className='popover--addMediaPopover-header-rightSearchUI-label'>
                    Enter
                  </div>
                  <div className='popover--addMediaPopover-header-rightSearchUI-iconContainer'>
                    <Icon name='enterKey' />
                  </div>
                </div>
              </button>            
            </div>
          </div>
        </div>  
        */}      
        
        <div style={{width: rowWidth, minHeight: minListHeight}}  className='popover--addMediaPopover-listContainer'>

          {/* RECENT MEDIA */}
          {!noRecentMedia && inputEmpty &&
            <AddMediaPopoverResultsList 
              media={recentMedia}
              //handleSelectItem={mediaType === 'image' ? handleSelectRecentImage : handleSelectRecentVideo}
              handleSelectItem={handleSelectMedia}
              mediaType={mediaType}
              maxRows={64}
              rowWidth={rowWidth}
            />
          }

          {!inputEmpty && searchResults.length>0 &&

           <AddMediaPopoverResultsList 
              media={searchResults}
              handleSelectItem={mediaType === 'image' ? handleSelectRecentImage : handleSelectRecentVideo}
              mediaType={mediaType}
              maxRows={3}
              rowWidth={rowWidth}
            />
        }



          {isOnboarding &&
            <div className='popover--addMediaPopover-onboarding'>
              <div className='popover--addMediaPopover-onboarding-prefillGallery'>
                <AddMediaPopoverPrefillSearchesGallery 
                  prefillSearches={prefillSearches}
                  mediaType={mediaType}
                />
              </div>

              <div className='popover--addMediaPopover-onboarding-vSpacer' />

              <button onClick={handleUploadClick} className='popover--addMediaPopover-uploadBtn popover--addMediaPopover-uploadBtn--large'>
                <div className='popover--addMediaPopover-uploadBtn-iconContainer'>
                  <Icon name='laptopUpload' />
                </div>                          
                <input 
                  id="file-upload" 
                  type="file" 
                  style={{ display: 'none' }} 
                  onChange={(event) => {
   
                    const file = event.target.files[0]
                    handleSelectFile(file)
                    // handleNewImageFileUpload(clipId, file); // Pass the entire FileList object or a specific file
                  }} 
                 // accept="image/*"
                  accept={uploadFileTypeAccept}
                />            
              </button>
            </div>
          }

          </div>
           
        
        <div className='popover--addMediaPopover-footer'>
          <button data-state={isOnboarding ? 'hidden' : 'visible'} onClick={handleUploadClick} className='popover--addMediaPopover-uploadBtn'>
            <div className='popover--addMediaPopover-uploadBtn-iconContainer'>
              <Icon name='laptopUpload' />
            </div>                          
            <input 
              id="file-upload" 
              type="file" 
              style={{ display: 'none' }} 
              onChange={(event) => {
                const file = event.target.files[0]
                handleSelectFile(file)
              }} 
             // accept="image/*"
              accept={uploadFileTypeAccept}
            />            
          </button>
        </div>
      </>      
  );
}

export default AddMediaPopoverContents;