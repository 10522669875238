import React, {useState,useEffect} from 'react'
import Icon from '../../../misc/Icon'
import * as Tooltip from '@radix-ui/react-tooltip';
import find from 'lodash/find'
import {parseDataIntoNumberChart} from '../../../../utils/charts/parseDataIntoNumberChart'
import {parseDataIntoBarChart} from '../../../../utils/charts/parseDataIntoBarChart'
import {parseDataIntoDonutChart} from '../../../../utils/charts/parseDataIntoDonutChart'
import { ToggleGroup, ToggleGroupBtn } from '../../../uiKit/ToggleUI';
import ChartsPanelBarChart from './ChartsPanelBarChart'
import ChartsPanelNumber from './ChartsPanelNumber'
import ChartsPanelDonut from './ChartsPanelDonut'
import SelectColorDropdown from '../../../uiKit/SelectColorDropdown'
import { getOrgBrand } from '../../../../utils/brands/getOrgBrand'
import { getBackgroundForId } from '../../../../utils/brands/getBackgroundForId'


function ChartsPanel({clip,projectBackgroundId,deleteClip,updateChartClip,updateChartBackgroundColor,defaultMotionStyle}) {  
	const orgBrand=getOrgBrand()
	const activeChartType=clip.metadata.activeChartType
	
	const setActiveChartType = (chartType) => {
		let updatedMetadata = { ...clip.metadata, activeChartType: chartType }; 
		console.log('set chart type')
		console.log(chartType)
		updateChartClip(clip.id, updatedMetadata);
	};


	///////// NUMBER CHART //////////
	const [numberAnimationTypeRaw, setNumberAnimationTypeRaw] = useState(clip.metadata.numberChartData.animationType);
	const [numberChartData, setNumberChartData] = useState({ compatible: false });
	const [numberInputRawData, setNumberInputRawData] = useState(clip.metadata.numberChartData.rawData)

	useEffect(() => {
		if(numberChartData.compatible && activeChartType =='number'){
			let metadata={...clip.metadata}
			metadata.numberChartData.rawData = numberInputRawData
			metadata.numberChartData.aboveLabel=numberChartData.aboveLabel
			metadata.numberChartData.belowLabel=numberChartData.belowLabel
			metadata.numberChartData.value=numberChartData.value
			updateChartClip(clip.id,metadata)
		}
	}, [numberChartData])

	 useEffect(() => {
		const numberChartData = parseDataIntoNumberChart(numberInputRawData);
		setNumberChartData(numberChartData);
	}, [numberInputRawData])


	 useEffect(() => {
		 if(activeChartType =='number'){
			let metadata={...clip.metadata}
			metadata.numberChartData.animationType = numberAnimationTypeRaw
			updateChartClip(clip.id,metadata)
		}
	}, [numberAnimationTypeRaw])

	/////// DONUT CHART ///////////////
	const [donutAnimationTypeRaw, setDonutAnimationTypeRaw] = useState(clip.metadata.donutChartData.animationType);  
	const [donutChartData, setDonutChartData] = useState({ compatible: false });
	const [donutInputRawData, setDonutInputRawData] = useState(clip.metadata.donutChartData.rawData);

	useEffect(() => {
		if(donutChartData.compatible && activeChartType =='donut'){
			let metadata={...clip.metadata}
			metadata.donutChartData.rawData = donutInputRawData
			metadata.donutChartData.aboveLabel=donutChartData.aboveLabel
			metadata.donutChartData.belowLabel=donutChartData.belowLabel
			metadata.donutChartData.value=donutChartData.value
			updateChartClip(clip.id,metadata)
		}
	}, [donutChartData]);


	useEffect(() => {
		const donutChartData = parseDataIntoDonutChart(donutInputRawData);
		setDonutChartData(donutChartData);
	}, [donutInputRawData]);


	useEffect(() => {
		 if(activeChartType =='donut'){
			let metadata={...clip.metadata}
			metadata.donutChartData.animationType = donutAnimationTypeRaw
			updateChartClip(clip.id,metadata)
		}
	}, [donutAnimationTypeRaw])


	/////// BAR CHART ////////////
	const [barAnimationTypeRaw, setBarAnimationTypeRaw] = useState(clip.metadata.barChartData.animationType);  
	const [barChartData, setBarChartData] = useState({ compatible: false });
	const [barInputRawData, setBarInputRawData] = useState(clip.metadata.barChartData.rawData);
	const [barTitle, setBarTitle] = useState(clip.metadata.barChartData.title);  
	const [barRowHighlightStates, setBarRowHighlightStates] = useState(clip.metadata.barChartData.rowHighlightStates);

	useEffect(() => {
		if(barChartData.compatible && activeChartType =='bar'){
			let metadata={...clip.metadata}
			metadata.barChartData.rawData = barInputRawData
			metadata.barChartData.rowHighlightStates = barRowHighlightStates
			metadata.barChartData.barItems=combineHighlightStatesWithBarItems(barChartData.barItems,barRowHighlightStates)
			updateChartClip(clip.id,metadata)
		}
	}, [barChartData,barRowHighlightStates]);


	useEffect(() => {
		const barChartData = parseDataIntoBarChart(barInputRawData);
		setBarChartData(barChartData);
	}, [barInputRawData]);

	useEffect(() => {
		 if(activeChartType =='bar'){
			let metadata={...clip.metadata}
			metadata.barChartData.animationType = barAnimationTypeRaw
			updateChartClip(clip.id,metadata)
		}
	}, [barAnimationTypeRaw])


	useEffect(() => {
		 if(activeChartType =='bar'){
			let metadata={...clip.metadata}
			metadata.barChartData.title = barTitle
			updateChartClip(clip.id,metadata)
		}
	}, [barTitle])


function combineHighlightStatesWithBarItems(barItems, rowHighlightStates) {
	return barItems.map(item => {
		const highlightState = rowHighlightStates[item.rawRowIndex] || 'default';
		return {
			...item,
			highlightState: highlightState
		};
	});
}

	const [backgroundColorDropdownOpen, setBackgroundColorDropdownOpen] = useState(null)
	const backgroundId = clip.metadata.backgroundId
	

	// useEffect(() => {
	// 	// const brandKit = find(brands,{name:activeBrand})
  //   setChartBackgroundColorOptions(brandKit.backgrounds)
  // }, [activeBrand]);   

	const hasBackgroundColor = backgroundId?true:false

	const background = getBackgroundForId(backgroundId||projectBackgroundId)


  const backgroundColorPreviewStyle = background
      ? { background: background.type === 'solid' ? background.rgba : '' }
      : {};
	const backgroundColorIsImage = background && background.type === 'image'
  let backgroundColorImage
  if(backgroundColorIsImage){
    backgroundColorImage = background.src
  }

  const addBG = backgroundId == 'none'
  



	const handleSelectColor = (backgroundColorId) => {
		updateChartBackgroundColor(clip.id,backgroundColorId)
	};


	////
	const setMotionStyle = (motionStyle)=>{
		let metadata={...clip.metadata}
		if(activeChartType =='number'){
			if(motionStyle=='auto'){
				metadata.numberChartData.motionStyle = motionStyle
				metadata.numberChartData.isAutoMotionStyle = true
			}else{
				metadata.numberChartData.motionStyle = motionStyle
				metadata.numberChartData.isAutoMotionStyle = false
			}
		}
		if(activeChartType =='donut'){
			if(motionStyle=='auto'){
				metadata.donutChartData.motionStyle = motionStyle
				metadata.donutChartData.isAutoMotionStyle = true
			}else{
				metadata.donutChartData.motionStyle = motionStyle
				metadata.donutChartData.isAutoMotionStyle = false
			}
		}
		if(activeChartType =='bar'){
			if(motionStyle=='auto'){
				metadata.barChartData.motionStyle = motionStyle
				metadata.barChartData.isAutoMotionStyle = true
			}else{
				metadata.barChartData.motionStyle = motionStyle
				metadata.barChartData.isAutoMotionStyle = false
			}
		}
		updateChartClip(clip.id,metadata)
	}


	return (
		<div className='rightPanel rightPanel--charts'>
			<div className='rightPanel-header'>
				<div className='rightPanel-header-iconContainer'>
					<Icon name='chartClipMedium' />
				</div>
				<div className='rightPanel-header-label'>
					Chart <span className='rightPanel-header-label-sub'>Beta</span>
				</div>      
				<div className='rightPanel-right-colorDropdownContainer'>
					<SelectColorDropdown
            colors={orgBrand.backgrounds}
            activeColorId={backgroundId}
            handleSelectColor={(id)=> handleSelectColor(id)}
            handleClosePopover={() => setBackgroundColorDropdownOpen(false)}      
            isSlideBGColor   
            isAutoBGColor={!hasBackgroundColor}   
            isNoneBGColor={clip.metadata.backgroundId=='none'}       
            colorCount={orgBrand.backgrounds.length}
          >
           <button data-state={hasBackgroundColor ? "hasValue" : "noValue"} onClick={() => setBackgroundColorDropdownOpen(!backgroundColorDropdownOpen)} className={'uiButton uiButton--textBGColor' + (addBG ? ' uiButton--textBGColor--add ' : '')}>
	            {addBG && 
	              <>
	                <div className='uiButton-iconContainer'>
	                  <Icon name='plusMedium' />
	                </div>
	                <div className='uiButton-label'>
	                  Add BG
	                </div>
	              </>
	            }
	            {!addBG && 
	              <>
	                <div style={backgroundColorPreviewStyle} className={'uiButton-colorPreview ' + (backgroundColorIsImage ? ' uiButton-colorPreview--image ' : '')}>
	                  {backgroundColorIsImage && 
	                   <img src={backgroundColorImage} />
	                  }
	                </div>
	                <div className='uiButton-label'>
	                  BG{!hasBackgroundColor && <span className='uiButton-label-sub'>Auto</span>}
	                </div>
	              </>
	            }
            </button>
          </SelectColorDropdown>
				</div>
			</div>      


			<div className='rightPanel--charts-chartTypeGroup'>
				<button 
					onClick={() => setActiveChartType('number')}
					data-state={activeChartType === 'number' ? "active" : "inactive"}
					className='uiButton uiButton--chartType'
				>
					<div className='uiButton-label'>
						Number
					</div>
				</button>
				<button 
					onClick={() => setActiveChartType('donut')}
					data-state={activeChartType === 'donut' ? "active" : "inactive"}
					className='uiButton uiButton--chartType'
				>
					<div className='uiButton-label'>
						Donut
					</div>
				</button>
				<button 
					onClick={() => setActiveChartType('bar')}
					data-state={activeChartType === 'bar' ? "active" : "inactive"}
					className='uiButton uiButton--chartType'
				>
					<div className='uiButton-label'>
						Bar
					</div>
				</button>
			</div>

			{activeChartType === 'bar' &&
				<ChartsPanelBarChart 
					spreadsheetData={barInputRawData}
					setSpreadsheetData={setBarInputRawData}
					barTitle={barTitle}
					setBarTitle={setBarTitle}
					animationType={barAnimationTypeRaw}
					setAnimationType={setBarAnimationTypeRaw}
					barRowHighlightStates={barRowHighlightStates}
					setBarRowHighlightStates={setBarRowHighlightStates}
					metadata={clip.metadata.barChartData}
					setMotionStyle={setMotionStyle}
				/>
			}

			{activeChartType === 'number' &&
				<ChartsPanelNumber 
					inputData={numberInputRawData}
					setInputData={setNumberInputRawData}
					animationType={numberAnimationTypeRaw}
					setAnimationType={setNumberAnimationTypeRaw}
					metadata={clip.metadata.numberChartData}
					setMotionStyle={setMotionStyle}
				/>                  
			}

			{activeChartType === 'donut' &&
				<ChartsPanelDonut
					inputData={donutInputRawData}
					setInputData={setDonutInputRawData}
					animationType={donutAnimationTypeRaw}
					setAnimationType={setDonutAnimationTypeRaw}
					metadata={clip.metadata.donutChartData}
					setMotionStyle={setMotionStyle}
				/>                  
			}

			
			
			<div className='rightPanel-row-hSpacer' />


				<div className='rightPanel-vSpacer' />


				<br/>
				<br/>
				<button className='uiButton uiButton--centered uiButton--light' onClick={()=>{deleteClip(clip.id)}}>
					<div className='uiButton-label'>
						Delete Chart          
					</div>
				</button>
			 
		</div>
	);
}

export default ChartsPanel;

