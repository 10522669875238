const cursorGameVideoBG = () => {
  
  const videoUrl = '/sampleVideos/LG.mp4';

  return (
    <div className='cursorGame-videoBG'>
      <video src={videoUrl} loop autoPlay muted>        
      </video>
    </div>
  );
};

export default cursorGameVideoBG;
