import React, { useRef, useEffect } from 'react';
import { useLoader } from '@react-three/fiber';
import * as THREE from 'three';
import { getLegacyCursorPositionAtTimelineTime } from '../../utils/recordings/legacyScreenRecordings/getLegacyCursorPositionAtTime';
import { getCursorPositionAtTimelineTime } from '../../utils/recordings/screenRecordings/getCursorPositionAtTime';
import renderOrders from '../renderOrders'

const ScreenVideoCursor = ({ localTime, meshScale, clip, time, animationStates }) => {  
  const FPS = 60; // Frames per second, assuming this is defined or passed as a prop
  const cursorScale = 1.25;
  const POINTER_CURSOR_SIZE = 70; // Base size for pointer cursor
  const pointerImage = '../cursors/pointer.png'; // pointer
  // const pointerImage = '../cursors/default.png';
  // const pointerImage = '../cursors/grab.png';
  // const pointerImage = '../cursors/grabbing.png';
  // const pointerImage = '../cursors/text.png';
  // const pointerImage = '../cursors/touch.png';
  const pointerTexture = useLoader(THREE.TextureLoader, pointerImage);
  const pointerCursorRef = useRef();
  const pointerCursorGeometry = new THREE.PlaneGeometry(POINTER_CURSOR_SIZE * cursorScale, POINTER_CURSOR_SIZE * cursorScale);

  // Use effect to update cursor position based on timeline time
  useEffect(() => {    	
    let mostRecentPosition 
    if(clip.isScreenRecording){
      mostRecentPosition = getCursorPositionAtTimelineTime(clip, time);
    }else{
      mostRecentPosition = getLegacyCursorPositionAtTimelineTime(clip, time);
    }
    if (mostRecentPosition) {
      pointerCursorRef.current.position.set(mostRecentPosition.x, mostRecentPosition.y, 0);
    }
  }, [time, clip]);

  // Use effect to apply animation states
  useEffect(() => {    	
    const frameIndex = Math.floor(localTime * FPS);

    // Apply position animation
    if (animationStates && animationStates['positionX'] && animationStates['positionX'][frameIndex] !== undefined &&
        animationStates['positionY'] && animationStates['positionY'][frameIndex] !== undefined) {
      // pointerCursorRef.current.position.x += animationStates['positionX'][frameIndex];
      // pointerCursorRef.current.position.y += animationStates['positionY'][frameIndex];
    }

    // Apply scale animation
    if (animationStates && animationStates['scale'] && animationStates['scale'][frameIndex] !== undefined) {
      const scale = animationStates['scale'][frameIndex];
      pointerCursorRef.current.scale.set(scale, scale, scale);
    }

    // Apply rotation animation (assuming rotation around Z-axis)
    if (animationStates && animationStates['rotationZ'] && animationStates['rotationZ'][frameIndex] !== undefined) {
      pointerCursorRef.current.rotation.z = animationStates['rotationZ'][frameIndex];
    }

    // Apply opacity animation
    if (animationStates &&  animationStates['opacity'] && animationStates['opacity'][frameIndex] !== undefined) {
      pointerCursorRef.current.material.opacity = animationStates['opacity'][frameIndex];
    }

  }, [localTime, animationStates]);




  return (
    <>                         
      <mesh 
        ref={pointerCursorRef}
        geometry={pointerCursorGeometry}          	
        position={[0, 0, 0]}
        renderOrder={renderOrders.screenVideoCursor}
      >
        <meshBasicMaterial map={pointerTexture} transparent={true} opacity={1} />
      </mesh>      
    </>
  );
};

export default ScreenVideoCursor;
