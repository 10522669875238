import {ExportTimelineScene} from './ExportTimelineScene'
import {calculateTimelineTimeFromVideoTime} from '../../timeline/utils/calculateTimelineTimeFromVideoTime'
import {calulateVideoTimeFromTimelineTime} from '../../timeline/utils/calulateVideoTimeFromTimelineTime'
// import {ExportVideoClip} from './ExportVideoClip'
import {CodecVideoClip} from '../../timeline/CodecVideoClip'
import {findActiveSegmentForVideoClip} from '../../timeline/utils/findActiveSegmentForVideoClip'

const TICK_LENGTH=20
const SYNC_INTERVAL = 200 //was 500 might want to play with this
const BACKGROUND_TRACK_VOLUME=0.08
const INFINITE_TIMELINE = true
const MIN_SPLIT_CLIP_DURATION=0.5 //if split clip is less than this then delete it


class ExportTimeline {
	
	constructor(exportStartTime,exportEndTime,onTimeUpdate,renderVariables) {
		this._currentTime = exportStartTime || 0;
		this.exportStartTime = exportStartTime 
		this.exportEndTime = exportEndTime
		this._onTimeUpdate = onTimeUpdate
		this._scenes = [];
		this.loadMediaPromises=[]
		this.renderVariables=renderVariables
		this.exportStartTime=exportStartTime
	}


	async initScenes(scenes){//This happens on load timeline
		scenes.forEach((scene)=>{
			this.addScene(scene)
		})
		this.calculateDuration()
	}


	addLoadMediaPromise(promise) {
  		this.loadMediaPromises.push(promise);
	}

	findClipForId(clipId) {
		for (const scene of this._scenes) {
			for (const clip of scene.clips) {
				if (clip.id === clipId) {
					return clip;
				}
			}
		}
		return null;
	}

	findSceneForId(sceneId) {
		for (const scene of this._scenes) {
			if (scene.id == sceneId) {
				return scene;
			}	
		}
		return null;
	}


	addScene(scene) {
		const timelineScene = new ExportTimelineScene(scene,this.addLoadMediaPromise.bind(this),this.renderVariables,this.exportStartTime,this.exportEndTime )		
		timelineScene.initScene(true,scene.clips)
		this._scenes.push(timelineScene);
	}

	
	seek(time) {
		this._currentTime =Math.max(time, 0) //allow seeking beyond video
		if (this._onTimeUpdate) {
			this._onTimeUpdate(this._currentTime); // Update parent component
		}

		this.clips.forEach(clip => {
			if ( clip instanceof CodecVideoClip) {
				clip.seek(this._currentTime);
			}
		});
	}




	get duration() {
		return this._duration;
	}

	get currentTime() {
		return this._currentTime;
	}

	get isPlaying() {
		return this._isPlaying;
	}


get clips() {
	const clips= this._scenes.sort((a, b) => a.startTime - b.startTime)
		.flatMap((scene, sceneIndex) => 
			scene.clips.map(clip => (
				clip
			))
		);
		return clips
	}


	calculateDuration() {
		let cumulativeDuration = 0;
		this._scenes.sort((a, b) => a.sceneIndex - b.sceneIndex);

			// this._scenes.sort((a, b) => a.startTime - b.startTime);
		this._scenes.forEach((scene, index) => {
			if (index === 0) {
				scene.startTime = 0;
			} else {
				scene.startTime = cumulativeDuration;
			}
			// scene.sceneIndex=index
			cumulativeDuration += scene.duration;
		})

		this._duration = cumulativeDuration;
	}


	get scenes() {
		return this._scenes
			.sort((a, b) => a.startTime - b.startTime)
	}
////TODO clips and scene getters make new arrays each time --> unnecisarry rerenders


	destroy() {
		if (this._isPlaying) {
			this.pause();
		}
		if (this._playbackInterval) {
			clearInterval(this._playbackInterval);
			this._playbackInterval = null;
		}
		if (this._syncInterval) {
			clearInterval(this._syncInterval);
			this._syncInterval = null;
		}
		
		this._scenes.forEach(scene => {
			if (scene.destroy && typeof scene.destroy === 'function') {
				scene.destroy();
			}
		});
		this._scenes = null;
	}

}

export { ExportTimeline }



