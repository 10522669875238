import React from 'react';
import * as Popover from '@radix-ui/react-popover';
import Icon from '../../misc/Icon';
import getChartColors from '../../../utils/charts/getChartColors'; // Import the new utility function

const DPColorPicker = ({ activeColorId, onChange, options, width, colorPicker, btnLabelLight, extendSelection, textColors, valueBtnAutoWidth, chartColors, spreadsheet, statusBar }) => {
  
  let optionPreviewHeight = 36
  if(textColors){
    optionPreviewHeight = 22
  }

  const chartColorOptions = getChartColors()

  const allOptions = chartColors ? [...options, ...chartColorOptions] : options;
  const selectedOption = allOptions.find(opt => opt.id === activeColorId);
  const value = selectedOption?.value;

  let valueType = 'color'
  
  if(selectedOption && selectedOption.value === 'none'){
    valueType = 'none'  
  }

  if(selectedOption && selectedOption.value === 'auto'){
    valueType = 'auto'    
  }

  if(extendSelection && !selectedOption){
    valueType = 'extendSelection'
  }

  let valueBtnStyle
  if(valueBtnAutoWidth && valueType === 'auto'){
    valueBtnStyle = {
      width: `${valueBtnAutoWidth}px`
    }
  }

  let sideOffset = 4
  let alignOffset = -8

  if(spreadsheet){
    sideOffset = 1
  }
  if(statusBar){
    alignOffset = -100
  }

  return (
    <Popover.Root modal={spreadsheet}>
      <Popover.Trigger asChild >
        <button style={valueBtnStyle} data-spreadsheet-btn={spreadsheet} data-statusbar-btn={statusBar} data-value-type={valueType} data-color-type={textColors ? 'text' : 'bg'} className='dpColorPicker-valueBtn'>
          
          {valueType === 'none' &&
            <div className='dpColorPicker-valueBtn-label dpColorPicker-valueBtn-label--none'>
              None
            </div>
          }

          {valueType === 'auto' &&
            <div className='dpColorPicker-valueBtn-label dpColorPicker-valueBtn-label--auto'>
              Auto
            </div>
          }
          
          {selectedOption && selectedOption.type === 'solid' &&
            <div style={{background: `${value}`}} className='dpColorPicker-valueBtn-solid' />
          }

          {selectedOption && selectedOption.type === 'image' &&
            <div className='dpColorPicker-valueBtn-image'>
              <img src={value} alt={selectedOption.label} />
            </div>
          }

          {valueType === 'extendSelection' &&
            <div className='dpColorPicker-valueBtn-label dpColorPicker-valueBtn-label--extend'>              
              <Icon name='ellipses' />
            </div>
          }
          
        </button>
      </Popover.Trigger>
      
      <Popover.Portal>
        <Popover.Content 
          className={'dpColorPicker-popover ' + (textColors ? 'dpColorPicker-popover--textColors' : '')}
          side="bottom"
          sideOffset={sideOffset}
          align={spreadsheet ? 'start' : 'end'}
          alignOffset={alignOffset}    
          collisionPadding={10}

        >          
          <div className='dpColorPicker-popover-grid'>
            {options.map((option) => (
              <button
                key={option.id}
                data-selected-state={option.id === activeColorId ? 'selected' : 'unselected'}
                data-type-state={option.type === 'solid' ? 'solid' : 'image'}
                data-option-none-state={option.value === 'none' ? 'true' : 'false'}
                data-option-auto-state={option.value === 'auto' ? 'true' : 'false'}
                data-color-type={textColors ? 'text' : 'bg'}
                className='dpColorPicker-popover-option'
                onClick={() => onChange(option.id)}
              >                
                {option.value === 'none' &&
                  <div className='dpColorPicker-popover-option-special' style={{height: `${optionPreviewHeight}px`}}>
                    <div className='dpColorPicker-popover-option-special-label'>
                      None
                    </div>                    
                  </div>
                }
                {option.value === 'auto' &&
                  <div className='dpColorPicker-popover-option-special' style={{height: `${optionPreviewHeight}px`}}>
                    <div className='dpColorPicker-popover-option-special-label'>
                      Auto
                    </div>
                  </div>
                }
                {option.type === 'solid' &&
                  <div style={{height: `${optionPreviewHeight}px`, background: `${option.value}`}} className='dpColorPicker-popover-option-solid' />
                }
                {option.type === 'image' &&
                  <div className='dpColorPicker-popover-option-image' style={{height: `${optionPreviewHeight}px`}}>
                    <img src={option.value} alt={option.label} />
                  </div>
                }
                <div className='dpColorPicker-popover-option-bg' />
                <div className='dpColorPicker-popover-option-activeBorder' />
              </button>
            ))}   
          </div>       

          {chartColors && 
            <>
              <div className='dpColorPicker-popover-chartColorLabel'>
                Chart Colors
              </div>

              <div className='dpColorPicker-popover-grid'>
                {chartColorOptions.map((option) => (
                  <button
                    key={option.id}
                    data-selected-state={option.id === activeColorId ? 'selected' : 'unselected'}
                    data-type-state={option.type === 'solid' ? 'solid' : 'image'}
                    data-color-type={textColors ? 'text' : 'bg'}
                    className='dpColorPicker-popover-option'
                    onClick={() => onChange(option.id)}
                  >                
                    <div style={{height: `${optionPreviewHeight}px`, background: `${option.value}`}} className='dpColorPicker-popover-option-solid' />
                    <div className='dpColorPicker-popover-option-bg' />
                    <div className='dpColorPicker-popover-option-activeBorder' />
                  </button>
                ))}     
              </div>
              <div className='dpColorPicker-popover-chartBottomSpacer' />
            </>   
          }  
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default DPColorPicker;