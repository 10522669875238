import store from '../../store'





export function getOrgImageStyle() {  
  const state = store.getState()
  const orgBrand = state.orgBrand 
  
  let imageStyle=null

  if(orgBrand.imageStyle){
    imageStyle = orgBrand.imageStyle
  }

  return imageStyle
}
