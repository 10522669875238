import React from 'react'
import Icon from '../../misc/Icon'
import { connect } from 'react-redux'

const MoveToPopoverCollection = ({ isActive, collection, onChange }) => {
  return (
    <div
      className={`miniPopover-item miniPopover-item--collection ${collection.color ? `miniPopover-item--collection--${collection.color}` : ''}`}
      onClick={() => onChange(collection.id)}
    >      
      <div className='miniPopover-item-checkboxContainer'>
        <div data-active-state={isActive ? true : false} className='miniPopover-item-checkbox'>
          {isActive && <Icon name='checkmark' />}
        </div>
      </div>
      <div className='miniPopover-item-label'>
        {collection.title}
      </div>
    </div>
  );
};

const MoveToCollectionPopover = ({ showMoveToPopover, collections, pendingChanges, handleCollectionChange }) => {


  return (
    <div data-visibility-state={showMoveToPopover ? 'open' : 'closed'} className='miniPopover miniPopover--moveTo forceDarkTheme'>
      {collections
        .sort((a, b) => a.title.localeCompare(b.title))
        .map((collection) => (
          <MoveToPopoverCollection 
            key={collection.id} 
            collection={collection} 
            onChange={handleCollectionChange}
            isActive={pendingChanges.has(collection.id)}
          />
        ))}   
    </div>        
  );
};

const mapStateToProps = (state) => ({
  collections: state.collections,
});

export default connect(mapStateToProps)(MoveToCollectionPopover);