const initialState = {};

export default function(state = initialState, action) {
  switch(action.type) {
    case 'START_EXPORT_SUCCESS': {
      const { projectId, renderId, segmentCount, status, enqueuedTime, estimatedGenerationTime, renderSegmentSeconds, projectDuration } = action.payload;
      return {
        ...state,
        [projectId]: {
          renderId,
          status,
          completedSegments: 0,
          totalSegments: segmentCount,
          enqueuedTime,
          estimatedGenerationTime,
          renderSegmentSeconds,
          projectDuration
        }
      };
    }
    case 'UPDATE_EXPORT_STATUS': {
      const { projectId, status, inProgressTime } = action.payload;
      const currentExport = state[projectId];
      if (!currentExport) return state;
      return {
        ...state,
        [projectId]: {
          ...currentExport,
          status,
          inProgressTime
        }
      };
    }
    case 'UPDATE_EXPORT_PROGRESS': {
      const { projectId, completedSegment } = action.payload;
      const currentExport = state[projectId];
      if (!currentExport) return state;
      return {
        ...state,
        [projectId]: {
          ...currentExport,
          completedSegments: completedSegment + 1,
          status: 'in_progress'
        }
      };
    }
    case 'EXPORT_GENERATION_COMPLETED': {
      const { projectId, resultUrl, completionTime } = action.payload;
      const currentExport = state[projectId];
      if (!currentExport) return state;
      return {
        ...state,
        [projectId]: {
          ...currentExport,
          status: 'generation_completed',
          resultUrl,
          completionTime,
          completedSegments: currentExport.totalSegments
        }
      };
    }
    case 'EXPORT_DOWNLOADING': {
      const { projectId } = action.payload;
      const currentExport = state[projectId];
      if (!currentExport) return state;
      return {
        ...state,
        [projectId]: {
          ...currentExport,
          status: 'downloading'
        }
      };
    }
    case 'EXPORT_DOWNLOAD_COMPLETED': {
      const { projectId, filePath, filename, downloadCompletionTime } = action.payload;
      const currentExport = state[projectId];
      if (!currentExport) return state;
      return {
        ...state,
        [projectId]: {
          ...currentExport,
          status: 'downloaded',
          filePath,
          filename,
          downloadCompletionTime
        }
      };
    }
    case 'EXPORT_FAILED':
    case 'EXPORT_DOWNLOAD_FAILED': {
      const { projectId, error } = action.payload;
      const currentExport = state[projectId];
      if (!currentExport) return state;
      return {
        ...state,
        [projectId]: {
          ...currentExport,
          status: action.type === 'EXPORT_FAILED' ? 'failed' : 'download_failed',
          error
        }
      };
    }
    case 'CANCEL_EXPORT': {
      const { projectId } = action.payload;
      const { [projectId]: _, ...newState } = state;
      return newState;
    }
    case 'START_EXPORT_FAILURE': {
      const { projectId, error } = action.payload;
      return {
        ...state,
        [projectId]: {
          status: 'failed',
          error
        }
      };
    }
    default:
      return state;
  }
}