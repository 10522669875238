import React, { useState, useEffect, useRef } from 'react';
import { pixelsToTime } from '../utils/pixelsToTime';
import { timeToPixels } from '../utils/timeToPixels';
import Icon from '../../../misc/Icon';

const EditorTimelineSkipSegmentCursor = (props) => {
  const {
    currentTime,
    scenes,
    pixelsPerSec,
    timelineDurationSeconds,      
    maxTimelineDurationSeconds,
    sceneGap,
    handleSeek,
    altIsDown,
    preAltCurrentTime,
    onAltKeyDown,
    onAltKeyUp,
    isResizingSkipSegment
  } = props;

  const [cursorPosition, setCursorPosition] = useState(null);
  const containerRef = useRef(null);
  const isMouseDownRef = useRef(false);

  useEffect(() => {
    if(!isResizingSkipSegment){
       setCursorPosition(null);
     }else{
      const position = timeToPixels(currentTime,pixelsPerSec,scenes,sceneGap)
      setCursorPosition(position)
     }
  }, [isResizingSkipSegment, currentTime, handleSeek, pixelsPerSec, scenes, sceneGap]);




  // const selectionLeft = Math.min(startPoint, endPoint);
  // const selectionWidth = Math.abs(endPoint - startPoint);

  return (
    <div 
      ref={containerRef}
      className='editor-timeline-skipSegmentTimePreviewContainer' 
      style={{ width: `${pixelsPerSec * maxTimelineDurationSeconds}px`}}
    >
      {cursorPosition !== null && (
        <div 
          className="editor-timeline-skipSegment-timePreviewLine"
          style={{            
            left: `${cursorPosition}px`            
          }}
        >
          {/* <div className='editor-timeline-skipSegment-timePreviewLine-dot'/> */}
          <div className='editor-timeline-skipSegment-timePreviewLine-iconContainer'>
            <Icon name='previewPlayheadTriangle' />
          </div>

          <div className='editor-timeline-skipSegment-timePreviewLine-line'/>
        </div>
      )}
     {/* {startPoint !== null && endPoint !== null && (
        <div 
          className="editor-timeline-skipSegmentCursor"
          style={{
            position: 'absolute',
            left: `${selectionLeft}px`,
            width: `${selectionWidth}px`,
            height: '2px',
            backgroundColor: 'white',
            top: '123px'
          }}
        />
      )}*/}
    </div>
  );
};

export default EditorTimelineSkipSegmentCursor;