import React, { useState, useRef, useEffect } from 'react';
import { RoundedBox } from '@react-three/drei';
import { createBarChartMeshData } from './createBarChartMeshData';
import CHChartItem from './rect/CHChartItem';
import { SpringAnimator } from '../../three/utils/animations/SpringAnimator';
import { Text } from '@react-three/drei';
import CHBarChartTitle from './CHBarChartTitle'

const FPS = 60; // Frames per second
const animationDuration = 10

const defaultValues = {
  opacity: 0,
};
const opacityAnimationParams = {
  mass: 0.6,
  stiffness: 260,
  damping: 12,
};

const heightAnimationParams = {
  mass: 1,
  stiffness: 180,
  damping: 50,
};
const topCenterLabelOpacityAnimationParams = {
  mass: 1,
  stiffness: 120,
  damping: 80,
};

function countDecimals(value) {
  if (value === undefined || value === null || isNaN(value)) return 0;
  if (Math.floor(value) === value) return 0;
  return value.toString().split(".")[1]?.length || 0;
}





const createOpacityAnimationStates = (springParams, targetOpacity,initialOpacity) => {
  const spring = new SpringAnimator(
    springParams.mass,
    springParams.stiffness,
    springParams.damping,
    defaultValues.opacity,
    initialOpacity
  );

  const opacityStates = new Array(FPS * animationDuration).fill(defaultValues.opacity);
  for (let frame = 0; frame < opacityStates.length; frame++) {
    spring.setTarget(targetOpacity);
    spring.simulate(1000 / FPS);
    opacityStates[frame] = spring.position;
  }

  return opacityStates;
};


const CHBarChart = ({ title, rowData, time, animationType, isHidden, fontFamily, titleFontWeight, chartLabelFontWeight, barChartBarFillColor, barChartBarBorderColor, barChartValueLabelColor, barChartAxisLabelLabelColor, barChartTitleColor, barChartTitleMaxOpacity, barChartMaxOpacities}) => {
  const [barChartMeshes, setBarChartMeshes] = useState([]);
  const [opacityAnimationStates, setOpacityAnimationStates] = useState([]);
  const [topCenterLabelOpacityAnimationStates, setTopCenterLabelOpacityAnimationStates] = useState([]);
  const [heightAnimationStates, setHeightAnimationStates] = useState([]);
  const [valueAnimationStates, setValueAnimationStates] = useState([]);
  
  const [chartHasHighlightRow, setChartHasHighlightRow] = useState(false)
  const [chartHasLowlightRow, setChartHasLowlightRow] = useState(false)

  const isAnimated = animationType !== 'none';


  const rowDataDependency = JSON.stringify(
    rowData.map(item => ({
      label: item.label,
      highlightState: item.highlightState,
      rawRowIndex: item.rawRowIndex,
      value: {
        number: item.value.number,
        prefix: item.value.prefix,
        suffix: item.value.suffix
      }
    }))
  );
  
  useEffect(() => {
    if (rowData) {
      const meshData = createBarChartMeshData(rowData,title);
      setBarChartMeshes(meshData);

      setOpacityAnimationStates(createOpacityAnimationStates(opacityAnimationParams, 1, 0));
      setTopCenterLabelOpacityAnimationStates(createOpacityAnimationStates(topCenterLabelOpacityAnimationParams, 1, 0))

      const newHeightAnimationStates = meshData.map(mesh => {
        const heightStates = [];
        const heightSpring = new SpringAnimator(
          heightAnimationParams.mass,
          heightAnimationParams.stiffness,
          heightAnimationParams.damping,
          0 // Start from 0
        );
        for (let frame = 0; frame < FPS * animationDuration; frame++) {
          heightSpring.setTarget(mesh.dimensions[1]);
          heightSpring.simulate(1000 / FPS);
          heightStates.push(heightSpring.position);
        }
        return heightStates;
      });
      setHeightAnimationStates(newHeightAnimationStates);

      let hasHighlightRow = false;
      let hasLowlightRow = false;

      rowData.forEach(item => {
        if (item.highlightState === 'highlight') {
          hasHighlightRow = true;
        }
        if (item.highlightState === 'lowlight') {
          hasLowlightRow = true;
        }
      });

      setChartHasHighlightRow(hasHighlightRow);
      setChartHasLowlightRow(hasLowlightRow);
        
  }
  }, [rowDataDependency, title])

  

  // Calculate the staggered start times for each bar chart mesh
  const staggeredStartTimes = barChartMeshes.map((_, index) => index * 0.5);
  const frameIndex = Math.floor(time * FPS);
  const titleOpacity = opacityAnimationStates[frameIndex] || 0;  

  // console.log('-------')
  // console.log('barChartValueLabelColor')
  // console.log(barChartValueLabelColor)
  // console.log('-------')
  return (
    <>
      <group position={[0,0,0]} >

      {title && 
        <CHBarChartTitle 
          fontFamily={fontFamily}
          title={title}
          isHidden={isHidden}
          animationType={animationType}
          time={time}
          color={barChartTitleColor}
          maxOpacity={barChartTitleMaxOpacity}
        />
      }

      {barChartMeshes && barChartMeshes.map((mesh, index) => {
        const adjustedTime = time - staggeredStartTimes[index];


        return (
          <group key={mesh.label}>
            <CHChartItem 
              barChart
              
              value={mesh.value}
              width={mesh.dimensions[0]}
              initialHeight={mesh.dimensions[1]} // Initial height
              positionX={mesh.x}
              positionY={mesh.y}
              bottomCenterLabel={mesh.label}
              //topCenterLabel={`${mesh.valuePrefix}${mesh.value}${mesh.valueSuffix}`}
              
              isHighlight={mesh.highlightState === 'highlight'}
              isLowlight={mesh.highlightState === 'lowLight'}

              opacityAnimationState={opacityAnimationStates}
              heightAnimationState={heightAnimationStates[index]}
              topCenterLabelOpacityAnimationStates={topCenterLabelOpacityAnimationStates}              
              topCenterLabel={mesh.value}
              
              topCenterLabelPrefix={mesh.valuePrefix}
              topCenterLabelSuffix={mesh.valueSuffix}
              time={adjustedTime}
                            
              animationType={animationType}
              isHidden={isHidden}
              fontFamily={fontFamily}
              chartLabelFontWeight={chartLabelFontWeight}

              barChartBarFillColor={barChartBarFillColor}
              barChartBarBorderColor={barChartBarBorderColor}

              barChartValueLabelColor={barChartValueLabelColor}
              barChartAxisLabelLabelColor={barChartAxisLabelLabelColor}

              barChartMaxOpacities={barChartMaxOpacities}
            />
          </group>
        );
      })}

      </group>
    </>
  );
};

export default CHBarChart;
