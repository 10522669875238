import React, { useRef, useMemo } from 'react';
import * as THREE from 'three';
import renderOrders from '../../../renderOrders'

const LinePlane = ({ length, color, opacity, lineWidth, position, rotation }) => {
  const meshRef = useRef();
  const material = useMemo(() => new THREE.MeshBasicMaterial({ 
    color, 
    opacity, 
    transparent: true, 
    side: THREE.DoubleSide 
  }), [color, opacity]);

  const geometry = useMemo(() => new THREE.PlaneGeometry(length, lineWidth), [length, lineWidth]);

  return <mesh ref={meshRef} geometry={geometry} material={material} position={position} rotation={rotation} />;
};

const SlideBarChartBarRectBorderEdges = ({ adjustedHeight, adjustedBorderRadius, adjustedWidth, borderColor, borderOpacity, borderLineWidth }) => {
  const verticalLength = adjustedHeight - 2;
  const horizontalLength = adjustedWidth - 2;

  // Positions and rotations for the lines
  const positions = {
    left: new THREE.Vector3(-borderLineWidth / 2, verticalLength / 2, 1),
    right: new THREE.Vector3(adjustedWidth + (borderLineWidth * 0.5), verticalLength / 2 + 1, 1),
    top: new THREE.Vector3(horizontalLength / 2 + 1, adjustedHeight + (borderLineWidth * 0.5), 1),
    bottom: new THREE.Vector3(horizontalLength / 2 + 1, -borderLineWidth / 2, 1)
  };
  const rotationZ = Math.PI / 2;

  return (
    <>
      <LinePlane 
        length={verticalLength} 
        color={borderColor} 
        opacity={borderOpacity} 
        lineWidth={borderLineWidth} 
        position={positions.left} 
        rotation={[0, 0, rotationZ]} 
        renderOrder={renderOrders.graphObject}
      />
      <LinePlane 
        length={verticalLength} 
        color={borderColor} 
        opacity={borderOpacity} 
        lineWidth={borderLineWidth} 
        position={positions.right} 
        rotation={[0, 0, rotationZ]} 
        renderOrder={renderOrders.graphObject}
      />
      <LinePlane 
        length={horizontalLength} 
        color={borderColor} 
        opacity={borderOpacity} 
        lineWidth={borderLineWidth} 
        position={positions.top} 
        rotation={[0, 0, 0]} 
        renderOrder={renderOrders.graphObject}
      />
      <LinePlane 
        length={horizontalLength} 
        color={borderColor} 
        opacity={borderOpacity} 
        lineWidth={borderLineWidth} 
        position={positions.bottom} 
        rotation={[0, 0, 0]} 
        renderOrder={renderOrders.graphObject}
      />
    </>
  );
};

export default SlideBarChartBarRectBorderEdges;
