import React, { useRef, useEffect, useState, useMemo, useContext } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { Plane } from '@react-three/drei';
import ImageMaterial from './ImageMaterial'
import createRoundedRectShape from '../utils/createRoundedRectShape';
import { createDropShadow } from '../screenVideo/ScreenVideoDropShadow'; // Import the drop shadow function
import defaultImageAnimationValues from '../utils/animations/defaultImageAnimationValues'
import {getOrgImageStyle} from '../../utils/brands/getOrgImageStyle'
import renderOrders from '../renderOrders'


const IMAGE_BORDER_RADIUS = 18

const ImageClipDevice = ({ clip, time, localTime, visible, initialProperties, animationStates, animationParams, startTransitionType, endTransitionType, FPS, showImageStatic, isPlaying, isLightBG, renderOrder}) => {  

  const orgImageStyle = getOrgImageStyle()
  // console.log('org image style is-----')
  // console.log(orgImageStyle)


  // this is whether it's empty or not
  const nullSrc = false

  const meshWidth = clip.metadata.displayWidth

  const originalImageWidth = clip.metadata.originalWidth
  const originalImageHeight = clip.metadata.originalHeight
  const imageAspect = originalImageWidth / originalImageHeight


  // console.log('clip.metadata')
  // console.log(clip)

  const meshHeight = meshWidth / imageAspect

  const hasTransparency = clip.metadata.semiTransparent

  let meshRadius = IMAGE_BORDER_RADIUS
  if(hasTransparency){
    meshRadius = 0.1
  }

  if(orgImageStyle && orgImageStyle.borderRadius < 1){
    meshRadius = orgImageStyle.borderRadius
  }





  const deviceRef = useRef();  
  const shadowRef = useRef();

  //
  // Device Geometry
  const deviceGeometry = useMemo(() => {
    const shape = createRoundedRectShape(meshWidth, meshHeight, meshRadius);
    const geometry = new THREE.ShapeGeometry(shape);        
    // Compute UV mapping for the geometry using the provided logic that works
    const uvs = new Float32Array(geometry.attributes.position.count * 2);
    for (let i = 0; i < geometry.attributes.position.count; i++) {
      const vertex = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, i);
      // Map x, y vertex position to uv coordinates
      uvs[i * 2] = (vertex.x + meshWidth / 2) / meshWidth;
      uvs[i * 2 + 1] = (vertex.y + meshHeight / 2) / meshHeight;
    }
    geometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));
    return geometry;
  }, [meshWidth, meshHeight, meshRadius]);

  //
  // Shadow Setup  
  const shadowXOffset = 0;
  const shadowYOffset = -8;
  const shadowBlur = 20;
  const shadowSpread = -15;
  // const shadowOpacity = 0.68;
  let shadowOpacity = 0.84;
  if(hasTransparency){
    shadowOpacity = 0
  }

  if(orgImageStyle && orgImageStyle.shadowType && orgImageStyle.shadowType === 'none'){
    shadowOpacity = 0
  }


  const shadowMesh = useMemo(() => {
    const shadowOptions = {
      shadowXOffset, 
      shadowYOffset, 
      shadowBlur,    
      shadowSpread,  
      shadowColor: 'rgba(0,0,0,1)',
      shadowOpacity, 
    };
    return createDropShadow(meshWidth, meshHeight, shadowOptions);
  }, [meshWidth, meshHeight, shadowXOffset, shadowYOffset, shadowBlur, shadowSpread, shadowOpacity]);


  //
  // APPLY ANIMATIONS
    useEffect(() => {
      
      const frameIndex = Math.floor(localTime * FPS);
      if (deviceRef.current) {      
        Object.keys(animationStates).forEach(property => {
          let state = animationStates[property][frameIndex]; 
          if(showImageStatic){
            state = defaultImageAnimationValues[property]
          }       
          if (state !== undefined) {
            if (property.startsWith('position')) {
              const axis = property.slice('position'.length).toLowerCase();
              deviceRef.current.position[axis] = state;
            } else if (property === 'scale' && deviceRef.current.scale) {
              deviceRef.current.scale.set(state, state, state);
            } else if (property.startsWith('rotation')) {
              const axis = property.slice('rotation'.length).toLowerCase();
              deviceRef.current.rotation[axis] = state;
            } else if (property === 'opacity' && deviceRef.current.material) {
              deviceRef.current.material.opacity = state;            
            }
          }
        });
      }    
    }, [localTime,startTransitionType,endTransitionType,animationStates,showImageStatic]);

    useEffect(() => {
      
      const frameIndex = Math.floor(localTime * FPS);

      if (shadowRef.current) {
        // Initialize default values
        let animatedOpacity = 0, animatedScale = 1, animatedPosX = 0, animatedPosY = 0;

        // Check if the animation states for specific properties exist and assign values
        if (animationStates['opacity'] && animationStates['opacity'][frameIndex] !== undefined) {
          animatedOpacity = animationStates['opacity'][frameIndex];
        }
        if (animationStates['scale'] && animationStates['scale'][frameIndex] !== undefined) {
          animatedScale = animationStates['scale'][frameIndex];
        }
        if (animationStates['positionX'] && animationStates['positionX'][frameIndex] !== undefined) {
          animatedPosX = animationStates['positionX'][frameIndex];
        }
        if (animationStates['positionY'] && animationStates['positionY'][frameIndex] !== undefined) {
          animatedPosY = animationStates['positionY'][frameIndex];
        }

        

        // Apply animated values to the shadow mesh
        const scaleWithShadowSpread = animatedScale * (1 + shadowSpread * 2 / meshWidth);
        shadowRef.current.scale.set(scaleWithShadowSpread, scaleWithShadowSpread, scaleWithShadowSpread);

        // Apply animated position combined with static offsets to the shadow mesh
        shadowRef.current.position.x = animatedPosX + shadowXOffset;
        shadowRef.current.position.y = animatedPosY + shadowYOffset;
        shadowRef.current.position.z = 9;
        
        // Set the shadow's opacity based on animated opacity and Leva's opacity control
        shadowRef.current.material.opacity = shadowOpacity * (animatedOpacity * animatedOpacity * animatedOpacity);

        if(showImageStatic){
          shadowRef.current.position.x = shadowXOffset
          shadowRef.current.position.y = shadowYOffset
          shadowRef.current.position.z = 9
          shadowRef.current.material.opacity = shadowOpacity          
        }

      }
    }, [localTime, FPS, shadowXOffset, shadowYOffset, shadowSpread, shadowOpacity, meshWidth, startTransitionType,endTransitionType,JSON.stringify(animationParams),showImageStatic]);
    

    let placeholderImageSrc = 'https://res.cloudinary.com/yarn/image/upload/v1707037469/hypersphereSample/placeholderImageLight_cqxwqp.png'

    if(isLightBG){
      placeholderImageSrc = 'https://res.cloudinary.com/yarn/image/upload/v1707037468/hypersphereSample/placeholderImageDark_jeibym.png'
    }

  return (
    <>      
       
      {/* Placeholder */}

      {!clip.imgSrc && isPlaying &&
        <Plane                  
          position={[0, 0, 10]}
          args={[400, 400]} // width and height as arguments
          visible={visible}
        >
          <ImageMaterial
            imgSrc={placeholderImageSrc}
            //imgSrc={clip.imgSrc}
            opacity={0.2}
            renderOrder={renderOrder.legacyImageClip}
          />       
        </Plane>
      }

      {!nullSrc && 
        <>
          
          
          <primitive 
            object={shadowMesh}
            ref={shadowRef} 
            visible={visible}
            renderOrder={-10}
          />



          <mesh        
            ref={deviceRef}
            position={[initialProperties.positionX, initialProperties.positionY, initialProperties.positionZ]}
            scale={[initialProperties.scale, initialProperties.scale, initialProperties.scale]}
            geometry={deviceGeometry}
            visible={visible}
            renderOrder={renderOrder.legacyImageClip}
          >
            <ImageMaterial 
              imgSrc={clip.imgSrc}              
              opacity={1} // update to animated
            />
         
          </mesh>

        </>
      }


    </>
  );
};

export default ImageClipDevice;


