import React, { useState, useEffect } from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import EditorDetailPanelSlideAnimationPanelItem from './EditorDetailPanelSlideAnimationPanelItem';
import DPTabs from './DPTabs';

let tabOptions = [
  { id: 'animationType', label: 'Type' },    
  { id: 'delay', label: 'Delays' },
];

const ITEM_HEIGHT = 36; // Hardcoded height of each item

const EditorDetailPanelSlideAnimationPanel = ({ clipId,elements,updateSlideElementAnimationIndex,slideClip ,updateSlideElementMetadata, setIsDragResizingNumberInput}) => {
  const [originalItems, setOriginalItems] = useState([]);
  const [displayItems, setDisplayItems] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const [overIndex, setOverIndex] = useState(null);


useEffect(() => {

 const sortedElements = [...elements].sort((a, b) => {
    const indexA = a.metadata.animationOrderIndex ?? Infinity;
    const indexB = b.metadata.animationOrderIndex ?? Infinity;
    return indexA - indexB;
  });

  const formattedItems = sortedElements.map((element, index) => {
    let animationType = element.metadata.startTransitionType || 'fadeDown'
    let itemType = element.type
    if(element.type=='chart'){
      if(element.metadata.activeChartType=='donut'){
        animationType =element.metadata.donutChartData.animationType
        itemType='donutChart'
      }else{
        animationType =element.metadata.barChartData.animationType
        itemType='barChart'
      }
    }
    return(
    {
      id: element.id,
      type: itemType,
      label: element.type === 'text' ? `${element.metadata.text}`: element.metadata.originalFilename || `Element`,
      animationType:animationType,
      delay: element.metadata.enterDelay || 0,
      animationOrderIndex: element.metadata.animationOrderIndex ?? index,
      metadata:element.metadata
    }
    )
  });
    setOriginalItems(formattedItems);
    setDisplayItems(formattedItems);
  }, [elements]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor)
  );

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragMove = (event) => {
    const { active, delta } = event;
    if (active) {
      const oldIndex = displayItems.findIndex((item) => item.id === active.id);
      let newIndex = Math.round(oldIndex + delta.y / ITEM_HEIGHT);
      newIndex = Math.max(0, Math.min(newIndex, displayItems.length));
      setOverIndex(newIndex);
    }
  };

  const handleDragEnd = (event) => {
    const { active, delta } = event;
    if (active) {
      setDisplayItems((prevItems) => {
        const oldIndex = prevItems.findIndex((item) => item.id === active.id);
        const oldItem = prevItems[oldIndex];
        let newIndex = Math.round(oldIndex + delta.y / ITEM_HEIGHT);
        newIndex = Math.max(0, Math.min(newIndex, prevItems.length - 1));
        if (oldIndex !== newIndex) {
          const newItems = [...prevItems];
          newItems.splice(oldIndex, 1);
          newItems.splice(newIndex, 0, oldItem);
          updateSlideElementAnimationIndex(clipId, active.id, newIndex);
          return newItems;
        }
      return prevItems;
      });
    }
    setActiveId(null);
    setOverIndex(null);
  };

  const [activeTab, setActiveTab] = useState('animationType');

  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
  };



  return (
    <div className='editor-detailPanel--slide-animationPanel'>
      <div className='editor-detailPanel--slide-animationPanel-header'>
        
        <div className='editor-detailPanel--slide-animationPanel-header-title'>
          Animation
        </div>

        <div className='editor-detailPanel-row-hSpacer' />
        
        <DPTabs 
          tabOptions={tabOptions}
          activeTab={activeTab}
          onTabChange={handleTabChange}
          light     
          tight
        />


        {/*}
        <div className='editor-detailPanel-row-hSpacer' />
        {panelDetailType === 'animationType' && 
          <button onClick={() => setPanelDetailType('delay')} className='dpButton dpButton--light dpButton--labelOnly'>
            <div className='dpButton-label'>
              Delay
            </div>
          </button>
        }
        {panelDetailType === 'delay' && 
          <button onClick={() => setPanelDetailType('animationType')} className='dpButton dpButton--light dpButton--labelOnly'>
            <div className='dpButton-label'>
              Type
            </div>
          </button>
        }
        */}
      </div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragMove={handleDragMove}
        onDragEnd={handleDragEnd}
      >
        <div className='editor-detailPanel--slide-animationPanel-list'>
          {originalItems.map((item, index) => (
            <React.Fragment key={`original-${item.id}`}>
              <div
                data-active-state={overIndex === index ? 'true' : 'false'}
                className='editor-detailPanel--slide-animationPanel-list-dropLine'                  
                style={{ top: `${index * ITEM_HEIGHT}px` }}
              />
              <EditorDetailPanelSlideAnimationPanelItem
                id={`original-${item.id}`}
                label={item.label}
                isDragging={false}
                ITEM_HEIGHT={ITEM_HEIGHT}
                index={index}
                isOriginal={true}
                panelDetailType={activeTab}
                setIsDragResizingNumberInput={setIsDragResizingNumberInput}
                chartType={item.type === 'chart' && item.metadata.activeChartType}
                item={item}
                itemType={item.type}
              />
            </React.Fragment>
          ))}
          <div
            data-active-state={overIndex === originalItems.length ? 'true' : 'false'}
            className='editor-detailPanel--slide-animationPanel-list-dropLine'
            style={{ top: `${originalItems.length * ITEM_HEIGHT}px` }}
          />
          {displayItems.map((item, index) => (
            <EditorDetailPanelSlideAnimationPanelItem
              key={item.id}
              id={item.id}
              delay={item.delay}
              label={item.label}
              activeStartTransition={item.animationType}
              isDragging={item.id === activeId}
              ITEM_HEIGHT={ITEM_HEIGHT}
              index={index}
              isOriginal={false}
              clipId={clipId}
              elementId={item.id}
              metadata={item.metadata}
              updateSlideElementMetadata={updateSlideElementMetadata}
              elementType={item.type}
              chartType={(item.type === 'chart' && item.metadata.activeChartType) ? item.metadata.activeChartType : null}              
              panelDetailType={activeTab}
              setIsDragResizingNumberInput={setIsDragResizingNumberInput}
              itemType={item.type}
            />
          ))}
        </div>
      </DndContext>
    </div>
  );
};

export default EditorDetailPanelSlideAnimationPanel;