import React, { useMemo, useState, useCallback } from 'react';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import find from 'lodash/find';
import TranscriptPanelDragHandle from './TranscriptPanelDragHandle';

const DropLine = ({ yPos, isClosest }) => (
  <div 
    className='editor-transcriptPanel-draggableLine'
    data-closest-line={isClosest ? true : false}
    style={{top: `${yPos}px`}}
  />
);

const TranscriptPanelDragHandleGutter = ({ 
  transcriptChunksForDragHandles, 
  hoveredTranscriptChunk, 
  onDragStateChange,
  handleTranscriptPanelChunkDrop,
  transcriptChunkWithCursorInside,
  voiceoverClips,
  playClipFromTranscript,
  recalculateAudioClip,
  scrollPosition,
  previewingAudioClipId,
  cancelPreviewingAudioClip,
  addSceneAtEndOfProject,
  addAudioClipAtEndOfProject
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [draggingId, setDraggingId] = useState(null);
  const [dragPosition, setDragPosition] = useState(null);
  const [draggedItemRect, setDraggedItemRect] = useState(null);


  
   const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 100,
      },
    })
  );

  const dropLinePositions = useMemo(() => {
  const positions = [];
  let currentSceneId = null;
  transcriptChunksForDragHandles.forEach((chunk, index, array) => {
    const isLastChunk = index === array.length - 1;
    
    if (chunk.sceneId !== currentSceneId) {
      positions.push({
        id: `${chunk.clipId}-before`,
        yPos: chunk.dimensions.top,
        clipId: chunk.clipId,
        clipIndex: chunk.clipIndex,
        sceneId: chunk.sceneId,
        dropType: 'before'
      });
      currentSceneId = chunk.sceneId;
    }
    
    // Adjust the 'after' position for the last chunk
    const afterPosition = isLastChunk 
      ? chunk.dimensions.top + chunk.dimensions.height - 46 
      : chunk.dimensions.top + chunk.dimensions.height;
    
    positions.push({
      id: `${chunk.clipId}-after`,
      yPos: afterPosition,
      clipId: chunk.clipId,
      clipIndex: chunk.clipIndex,
      sceneId: chunk.sceneId,
      dropType: 'after'
    });
  });
  return positions;
}, [transcriptChunksForDragHandles]);

  const closestDropLine = useMemo(() => {
    if (!isDragging || dragPosition === null) return null;
    return dropLinePositions.reduce((closest, current) => {
      const currentDistance = Math.abs(current.yPos - dragPosition);
      const closestDistance = closest ? Math.abs(closest.yPos - dragPosition) : Infinity;
      return currentDistance < closestDistance ? current : closest;
    }, null);
  }, [isDragging, dragPosition, dropLinePositions]);

  const gutterHeight = useMemo(() => {
    return Math.max(...dropLinePositions.map(pos => pos.yPos), 0) + 20;
  }, [dropLinePositions]);

  const handleDragStart = useCallback((event) => {
	  setIsDragging(true);
    setIsLongDelayedEndDragging(true);
    setIsShortDelayedEndDragging(true);
	  setDraggingId(event.active.id);
	  const chunk = transcriptChunksForDragHandles.find(c => c.clipId === event.active.id);
	  if (chunk) {
	    setDragPosition(chunk.dimensions.top);
	    onDragStateChange(event.active.id, true, chunk.dimensions.top);
	    setDraggedItemRect({
	      top: chunk.dimensions.top,
	      height: chunk.dimensions.height
	    });
	  }
	}, [transcriptChunksForDragHandles, onDragStateChange]);

  const handleDragMove = useCallback((event) => {
    const chunk = transcriptChunksForDragHandles.find(c => c.clipId === event.active.id);
    if (chunk) {
      const newPosition = chunk.dimensions.top + event.delta.y;
      setDragPosition(newPosition);
      onDragStateChange(event.active.id, true, newPosition);
    }
  }, [transcriptChunksForDragHandles, onDragStateChange]);

  const handleDragEnd = useCallback((event) => {
	  setIsDragging(false);
	  setDraggingId(null);
	  setDragPosition(null);
	  //setDraggedItemRect(null);
	  if (closestDropLine) {
	    handleTranscriptPanelChunkDrop(event.active.id, closestDropLine);
	  }
	  onDragStateChange(null, false, null);
    setTimeout(() => {
      setIsShortDelayedEndDragging(false);
    }, 200);
    setTimeout(() => {
      setIsLongDelayedEndDragging(false);
    }, 1000);
	}, [closestDropLine, handleTranscriptPanelChunkDrop, onDragStateChange]);

  const [isLongDelayedEndDragging, setIsLongDelayedEndDragging] = useState(false);
  const [isShortDelayedEndDragging, setIsShortDelayedEndDragging] = useState(false);

  let draggableGutterStyle = {
    height: `${gutterHeight + 20}px`
  }

  if(isDragging){
    draggableGutterStyle = {
    minHeight: `${gutterHeight + 20}px`
  }}

  return (
  	<>
    <DndContext 
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragEnd={handleDragEnd}
    >
      <div 
        data-chunk-dragging={isDragging ? true : false}
        data-chunk-longdelayedend-dragging={isLongDelayedEndDragging ? true : false}
        data-chunk-shortdelayedend-dragging={isShortDelayedEndDragging ? true : false}
        className='editor-transcriptPanel-draggableGutter'      
        //style={{minHeight: `${gutterHeight + 20}px`}}
        style={draggableGutterStyle}
      >
        {dropLinePositions.map((line, index) => (
          <DropLine 
            key={`dropline-${index}`} 
            yPos={line.yPos} 
            isClosest={closestDropLine && line.yPos === closestDropLine.yPos}
          />
        ))}
        {transcriptChunksForDragHandles.map((chunk, index, array) => {
          const isHovered = chunk.clipId === hoveredTranscriptChunk;
          const isDragging = draggingId === chunk.clipId;
          const hasCursorInside = transcriptChunkWithCursorInside === chunk.clipId;
          const timelineClip = find(voiceoverClips, {id: chunk.clipId});
          const requiresUpdate = timelineClip && timelineClip.requiresUpdate;
          {/*const isPreviewPlaying = timelineClip && timelineClip.isPlayingFromTranscript;*/}
          const isLastChunk = index === array.length - 1;
          const isOnlyChunk = array.length === 1;
          const isNearBottom = chunk.dimensions.top - scrollPosition > window.innerHeight - 450 // messy

          return (
            <TranscriptPanelDragHandle
              key={chunk.clipId}
              id={chunk.clipId}
              yPos={chunk.dimensions.top}
              height={chunk.dimensions.height}
              text={chunk.textContent}
              hovered={isHovered || isDragging}
              hasCursorInside={hasCursorInside}
              requiresUpdate={requiresUpdate}
              // isPreviewPlaying={isPreviewPlaying}
              isOnlyChunk={isOnlyChunk}
              isLastChunk={isLastChunk}
              textLength={chunk.textContent.length}              
              playClipFromTranscript={playClipFromTranscript}
              recalculateAudioClip={recalculateAudioClip}
              clipId={chunk.clipId}    
              nearBottom={isNearBottom}
              previewingAudioClipId={previewingAudioClipId}
              cancelPreviewingAudioClip={cancelPreviewingAudioClip}
              addSceneAtEndOfProject={addSceneAtEndOfProject}
              addAudioClipAtEndOfProject={addAudioClipAtEndOfProject}
            />
          );
        })}
      </div>
      
      {draggedItemRect && isDragging &&
	      <div 
	        data-visible={(isDragging && draggedItemRect) ? true : false}
	        className='editor-transcriptPanel-draggableCover'
	        style={{            
	          top: `${draggedItemRect.top}px`,
	          height: `${draggedItemRect.height}px`,
	          width: '100%'
	        }}
	      />  
      }    
    </DndContext>
    </>
  );
};

export default TranscriptPanelDragHandleGutter;