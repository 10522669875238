


export default function (state = [], action) {
	switch (action.type) {
	
    case 'FETCH_IMAGES_SUCCESS':
      return action.response


    default:
      return state;
	}
}
