const SCENE_WIDTH = 1920;
const SCENE_HEIGHT = 1080;
const SCENE_ASPECT = SCENE_WIDTH / SCENE_HEIGHT;

const ZOOM_BOX_ASPECT = 16 / 9;

export const convertZoomValuesToZoomBox = (zoomValues) => {
  const scaledWidth = SCENE_WIDTH * zoomValues.scale;
  const scaledHeight = scaledWidth / ZOOM_BOX_ASPECT;

  // Initially x and y represent the center of the zoom box
  let x = zoomValues.originX;
  let y = -zoomValues.originY;



  // Adjust x and y to ensure the entire zoom box stays within the scene bounds
  const halfWidth = scaledWidth / 2;
  const halfHeight = scaledHeight / 2;
  // x = Math.max(-SCENE_WIDTH / 2 + halfWidth, Math.min(x, SCENE_WIDTH / 2 - halfWidth));
  // y = Math.max(-SCENE_HEIGHT / 2 + halfHeight, Math.min(y, SCENE_HEIGHT / 2 - halfHeight));

  // console.log('scaledWidth',scaledWidth)
  // console.log('scaledHeight',scaledHeight)
  // console.log('zoomValues.originX',zoomValues.originX)
  // console.log('zoomValues.originX',zoomValues.originX)
  // console.log('x',x)
  // console.log('y',y)

  return { x, y, width: scaledWidth, height: scaledHeight };
};
