import React from 'react'
import { createRoot } from "react-dom/client";
import ReactDOM from 'react-dom'
import SceneHeaderRightContainer from './SceneHeaderRightContainer'
 
const DEFAULT_TITLE="Default title"

class SceneHeaderNodeView {

	constructor(node, view, getPos) {
		this.node = node;
		this.view = view;
		this.getPos = getPos;

		this.dom = document.createElement("div")
		this.dom.classList.add('editor-transcriptPanel-sceneHeader')

		 //this.dom.style.backgroundColor = '#FF1493'

		this.contentDOM = document.createElement("div");
		this.contentDOM.classList.add('editor-transcriptPanel-sceneHeader-contents')
		this.dom.appendChild(this.contentDOM)

		// this.sceneHeaderRightDom=document.createElement("div");
		// this.sceneHeaderRightDom.classList.add('editor-transcriptPanel-sceneHeader-rightContainer')
		// this.sceneHeaderRightDom.contentEditable = 'false'
		// this.dom.appendChild(this.sceneHeaderRightDom)

		this.updateVisibility()
		this.updateClassNames()

	}


	updateClassNames(){
		const textContent=this.node.textContent
		if (textContent.length == 0){
			this.dom.classList.add("editor-transcriptPanel-sceneHeader--empty")
		}else{
			this.dom.classList.remove("editor-transcriptPanel-sceneHeader--empty")
		}
	}



	updateVisibility() {
		if (this.node.textContent==DEFAULT_TITLE) {
			this.contentDOM.style.display = 'none';
			this.dom.contentEditable = 'false'
			//this.sceneHeaderRightDom.style.display = 'none';
		} else {
			this.contentDOM.style.display = '';
			this.dom.contentEditable = 'true'
			//this.sceneHeaderRightDom.style.display = '';
		}
	}


update(node) {
    if (this.node.type !== node.type) {
      return false
    }
    // const oldDropdownIsOpen = this.node.attrs.isDropdownOpen
    const oldSceneDuration = this.node.attrs.sceneDuration
    const oldSceneCount = this.node.attrs.sceneCount
    const oldTextContent = this.node.textContent


    this.node = node
    if (
    	node.textContent !== oldTextContent ||
        node.attrs.sceneDuration !== oldSceneDuration ||
        node.attrs.sceneCount !== oldSceneCount
        ) {  
    	this.updateVisibility()
    	this.updateClassNames()
      //this.renderReactComponents();
    }
    return true;
  }


// ignoreMutation(mutation) {
// 	console.log('ignore mutation')
//   // Ignore all mutations when the content is hidden
//   return this.node.textContent == DEFAULT_TITLE;
// }

	destroy() {
			if(this.sceneHeaderRightDom){
			ReactDOM.unmountComponentAtNode(this.sceneHeaderRightDom)
		}	
	}


}

export default SceneHeaderNodeView
