import {webcamSizes as sizes, webcamPositions as positions,SLIDE_WIDTH,SLIDE_HEIGHT,FLOAT_CAMERA_INSET} from './webcamConfigs'
import {calculateWebcamRectForSizeAndPosition} from './calculateWebcamRectForSizeAndPosition'



export function calculateWebcamSizeAndPositionFromRect(rect) {
  // Helper function to calculate similarity between two rects
  const calculateRectSimilarity = (rect1, rect2) => {
    const dimensionMatch = Math.abs(rect1.width - rect2.width) + Math.abs(rect1.height - rect2.height);
    const positionMatch = Math.abs(rect1.x - rect2.x) + Math.abs(rect1.y - rect2.y);
    return dimensionMatch + positionMatch;
  };
  let bestMatch = {
    similarity: Infinity,
    size: null,
    position: null
  };
  // Iterate through all possible size and position combinations
  sizes.forEach(size => {
    const validPositions = size.validPositions;
    validPositions.forEach(positionName => {
      const candidateRect = calculateWebcamRectForSizeAndPosition(size.name, positionName);
      const similarity = calculateRectSimilarity(rect, candidateRect);

      if (similarity < bestMatch.similarity) {
        bestMatch = {
          similarity,
          size,
          position: positionName
        };
      }
    });
  });
  // If we couldn't find any match (shouldn't happen with valid input)
  if (!bestMatch.size || !bestMatch.position) {
    // Fall back to a default size and position
    return {
      size: sizes[0], // xSmallPortrait
      position: sizes[0].validPositions[0]
    };
  }
  return {
    size: bestMatch.size,
    position: bestMatch.position
  };
}