export function generateSRTFile(audioClips) {
  let srtContent = '';
  let subtitleNumber = 1;
  // Sort audio clips by their start time
  audioClips.sort((a, b) => (a.startTime || 0) - (b.startTime || 0));
  audioClips.forEach(clip => {
    if (!clip || !clip.metadata || !clip.metadata.subtitlesGroups) {
      console.warn('Clip or subtitlesGroups not found, skipping...');
      return;
    }
    const clipStartTime = clip.startTime || 0;
    clip.metadata.subtitlesGroups.forEach(group => {
      const groupStartTime = group.startTime || 0;
      const groupEndTime = group.endTime || group.originalEndTime || 0;
      
      // Calculate absolute start and end times
      const absoluteStartTime = clipStartTime + groupStartTime;
      const absoluteEndTime = clipStartTime + groupEndTime;
      
      // Check if there are two lines in the group
      if (group.lines.length === 2) {
        // Format the subtitle entry with two lines
        srtContent += `${subtitleNumber}\n`;
        srtContent += `${formatTime(absoluteStartTime)} --> ${formatTime(absoluteEndTime)}\n`;
        srtContent += `${group.lines[0].words.map(word => word.text).join(' ')}\n`;
        srtContent += `${group.lines[1].words.map(word => word.text).join(' ')}\n\n`;
      } else {
        // Combine all words from all lines in the group
        const allWords = group.lines.flatMap(line => line.words || []);
        if (allWords.length === 0) {
          console.warn(`No words found for group ${group.id}, skipping...`);
          return;
        }
        // Format the subtitle entry as a single line
        srtContent += `${subtitleNumber}\n`;
        srtContent += `${formatTime(absoluteStartTime)} --> ${formatTime(absoluteEndTime)}\n`;
        srtContent += `${allWords.map(word => word.text).join(' ')}\n\n`;
      }
      subtitleNumber++;
    });
  });
  return srtContent;
}

function formatTime(seconds) {
  const date = new Date(seconds * 1000);
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  const secs = date.getUTCSeconds().toString().padStart(2, '0');
  const ms = date.getUTCMilliseconds().toString().padStart(3, '0');
  return `${hours}:${minutes}:${secs},${ms}`;
}

// Usage example:
// const srtContent = generateSRTFile(audioClips);
// console.log(srtContent);