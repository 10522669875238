import React from 'react';

const BarChartElementSpreadsheetCell = ({ rowIndex, colIndex, cell, activeCell, isEditing, handleCellClick, handleDoubleClick, isSubselected, isSubselectionHead }) => {
  return (
    <div
      className={"editor-detailPanel-spreadsheet-cell " + (activeCell.row === rowIndex && activeCell.col === colIndex ? ' editor-detailPanel-spreadsheet-cell--selected ' : ' editor-detailPanel-spreadsheet-cell--unselected ') + (isSubselectionHead ? ' editor-detailPanel-spreadsheet-cell--subSelectedHead ' : '') + (isSubselected ? ' editor-detailPanel-spreadsheet-cell--subSelected' : '')}
      style={{ 
        position: 'absolute',
        left: colIndex * (cell.width - 1),
        top: rowIndex * (cell.height - 1),
        width: cell.width,
        height: cell.height,
      }}
      onClick={(e) => handleCellClick(rowIndex, colIndex, e)}
      onDoubleClick={() => handleDoubleClick(rowIndex, colIndex)}
      data-disableselect={false}
    >
      {activeCell.row === rowIndex && activeCell.col === colIndex && isEditing ? null : cell.value}
    </div>
  );
};

export default BarChartElementSpreadsheetCell;
