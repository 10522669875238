import React, { useRef, useEffect, useState, useMemo, useContext } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import { Plane } from '@react-three/drei';
import * as THREE from 'three';
import ImageMaterial from '../imageClip/ImageMaterial'
import createRoundedRectShape from '../utils/createRoundedRectShape';
import BasicVideoMaterial from './BasicVideoMaterial'
import ExportBasicVideoMaterial from './ExportBasicVideoMaterial'
import ExportBasicAlphaVideoMaterial from './ExportBasicAlphaVideoMaterial'
import { createDropShadow } from './BasicVideoDropShadow'; // Import the drop shadow function
import defaultVideoAnimationValues from '../utils/animations/defaultVideoAnimationValues'
import renderOrders from '../renderOrders'

const BasicVideoDevice = ({clip, time, localTime, visible, initialProperties, animationStates, animationParams, startTransitionType, endTransitionType,showBasicVideoStatic, FPS, isPlaying, isLightBG}) => {
  const nullSrc = clip.isUploadingVideo

  //const meshRadius = 14
  const basicVideoCornerRounding = clip.metadata.cornerRounding // should be stored on element

 // console.log(clip)
  const meshWidth = clip.metadata.displayWidth

  const originalVideoWidth = clip.metadata.originalWidth
  const originalVideoHeight = clip.metadata.originalHeight
  const videoAspect = originalVideoWidth / originalVideoHeight

  const meshHeight = meshWidth / videoAspect 


  const deviceRef = useRef();  
  const shadowRef = useRef();


  const hasTransparency = clip.metadata.semiTransparent

  //
  // DEVICE GEOMETRY  
  const maxRadius = Math.min(meshWidth, meshHeight) / 2;

  // Use the smaller of the desired radius or the maximum allowed radius
  let meshRadius = 0;
  if (basicVideoCornerRounding) {
    meshRadius = Math.min(basicVideoCornerRounding, maxRadius);
  }

  const isCircle = meshRadius >= maxRadius && meshWidth === meshHeight;

  const deviceGeometry = useMemo(() => {
    if (isCircle) {
      // Create a CircleGeometry
      const geometry = new THREE.CircleGeometry(maxRadius, 64);
      return geometry;
    } else if (meshRadius === 0) {
      // Create a simple rectangle geometry
      const geometry = new THREE.PlaneGeometry(meshWidth, meshHeight);
      return geometry;
    } else {
      // Create a rounded rectangle shape
      const shape = createRoundedRectShape(meshWidth, meshHeight, meshRadius);
      const geometry = new THREE.ShapeGeometry(shape);        
      const uvs = new Float32Array(geometry.attributes.position.count * 2);
      for (let i = 0; i < geometry.attributes.position.count; i++) {
        const vertex = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, i);
        uvs[i * 2] = (vertex.x + meshWidth / 2) / meshWidth;
        uvs[i * 2 + 1] = (vertex.y + meshHeight / 2) / meshHeight;
      }
      geometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));
      return geometry;
    }
  }, [meshWidth, meshHeight, meshRadius, isCircle, maxRadius]);


  //
  // SHADOW SETUP
  const shadowXOffset = 0;
  const shadowYOffset = -8;
  const shadowBlur = 20;
  const shadowSpread = -15;
  // const shadowOpacity = 0.68;
  let shadowOpacity = 0.84;
  if(hasTransparency){
    shadowOpacity = 0
  }


  const shadowMesh = useMemo(() => {
    const shadowOptions = {
      shadowXOffset, // X offset from Leva
      shadowYOffset, // Y offset from Leva
      shadowBlur,    // shadowBlur from Leva
      shadowSpread,  // shadowSpread (scale) from Leva
      shadowColor: 'rgba(0,0,0,1)',
      shadowOpacity, // Opacity from Leva
    };
    return createDropShadow(meshWidth, meshHeight, shadowOptions, meshRadius);
  }, [meshWidth, meshHeight, shadowXOffset, shadowYOffset, shadowBlur, shadowSpread, shadowOpacity, meshRadius]);


  // console.log('animationStates')
  // console.log(animationStates)
  // console.log('----')
  
  //
  // APPLY ANIMATIONS
    useEffect(() => {
      
      const frameIndex = Math.floor(localTime * FPS);
      if (deviceRef.current) {      
        Object.keys(animationStates).forEach(property => {
          let state = animationStates[property][frameIndex]; 
          if(showBasicVideoStatic){
            state=defaultVideoAnimationValues[property]
          }       
          if (state !== undefined) {
            if (property.startsWith('position')) {
              const axis = property.slice('position'.length).toLowerCase();
              deviceRef.current.position[axis] = state;
            } else if (property === 'scale' && deviceRef.current.scale) {
              deviceRef.current.scale.set(state, state, state);
            } else if (property.startsWith('rotation')) {
              const axis = property.slice('rotation'.length).toLowerCase();
              deviceRef.current.rotation[axis] = state;
            } else if (property === 'opacity' && deviceRef.current.material) {
              deviceRef.current.material.opacity = state;            
            }
          }
        });
      }    
    }, [localTime,startTransitionType,endTransitionType,animationStates,showBasicVideoStatic]);

    useEffect(() => {
      
      const frameIndex = Math.floor(localTime * FPS);

      if (shadowRef.current) {
        // Initialize default values
        let animatedOpacity = 0, animatedScale = 1, animatedPosX = 0, animatedPosY = 0;

        // Check if the animation states for specific properties exist and assign values
        if (animationStates['opacity'] && animationStates['opacity'][frameIndex] !== undefined) {
          animatedOpacity = animationStates['opacity'][frameIndex];
        }
        if (animationStates['scale'] && animationStates['scale'][frameIndex] !== undefined) {
          animatedScale = animationStates['scale'][frameIndex];
        }
        if (animationStates['positionX'] && animationStates['positionX'][frameIndex] !== undefined) {
          animatedPosX = animationStates['positionX'][frameIndex];
        }
        if (animationStates['positionY'] && animationStates['positionY'][frameIndex] !== undefined) {
          animatedPosY = animationStates['positionY'][frameIndex];
        }

        // Apply animated values to the shadow mesh
        const scaleWithShadowSpread = animatedScale * (1 + shadowSpread * 2 / meshWidth);
        shadowRef.current.scale.set(scaleWithShadowSpread, scaleWithShadowSpread, scaleWithShadowSpread);

        // Apply animated position combined with static offsets to the shadow mesh
        shadowRef.current.position.x = animatedPosX + shadowXOffset;
        shadowRef.current.position.y = animatedPosY + shadowYOffset;
        
        // Set the shadow's opacity based on animated opacity and Leva's opacity control
        shadowRef.current.material.opacity = shadowOpacity * (animatedOpacity * animatedOpacity * animatedOpacity);

        if(showBasicVideoStatic){
          shadowRef.current.position.x = shadowXOffset
          shadowRef.current.position.y = shadowYOffset
          shadowRef.current.position.z = 0
          shadowRef.current.material.opacity = shadowOpacity          
        }
      }
    }, [localTime, FPS, shadowXOffset, shadowYOffset, shadowSpread, shadowOpacity, meshWidth, startTransitionType,endTransitionType,JSON.stringify(animationParams), showBasicVideoStatic]);

  
      

    // console.log(`nullSrc is------ ${nullSrc}`)

    let placeholderImageSrc = 'https://res.cloudinary.com/yarn/image/upload/v1707036298/hypersphereSample/placeholderBasicVideoLight_ucrrsh.png'

    if(isLightBG){
      placeholderImageSrc = 'https://res.cloudinary.com/yarn/image/upload/v1707036297/hypersphereSample/placeholderBasicVideoDark_xrwcet.png'
    }


  return (
    <>      

      
      {/* Placeholder */}
      {nullSrc && isPlaying &&
        <Plane                  
          position={[0, 0, 0.1]}
          args={[400, 400]} // width and height as arguments
          visible={visible}
        >
          <ImageMaterial 
            imgSrc={placeholderImageSrc}
            opacity={0.2}
          />       
        </Plane>
      }
            
      {!nullSrc && 
        <>
          
          <primitive 
            object={shadowMesh}
            ref={shadowRef} 
            visible={visible}     
            renderOrder={renderOrders.deviceShadow}  
          /> 
              

          <mesh        
            ref={deviceRef}
            position={[initialProperties.positionX, initialProperties.positionY, initialProperties.positionZ]}
            scale={[initialProperties.scale, initialProperties.scale, initialProperties.scale]}
            geometry={deviceGeometry}
            visible={visible}
            renderOrder={renderOrders.basicVideo}
          >

          {!clip.isExport &&
            <BasicVideoMaterial 
              videoElement={clip.video}
              opacity={1} 
            />              
          }

        {/*{clip.isExport && !clip.hasAlphaChannel &&
            <ExportBasicVideoMaterial 
              opacity={1} 
               codecVideoClip={clip}  
            />     
          }
          {clip.isExport && clip.hasAlphaChannel &&
            <ExportBasicAlphaVideoMaterial 
              opacity={1} 
               codecVideoClip={clip}  
            />     
          }*/}


          {clip.isExport && !clip.metadata.semiTransparent &&
            <ExportBasicVideoMaterial 
              opacity={1} 
               codecVideoClip={clip}  
            />     
          }
          {clip.isExport && clip.metadata.semiTransparent &&
            <ExportBasicAlphaVideoMaterial 
              opacity={1} 
               codecVideoClip={clip}  
            />     
          }


          </mesh>
        </>
      }

    </>
  );
};

export default BasicVideoDevice;


