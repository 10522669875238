export const startTransitionConfigs = {
  none: {
    label: "None",
    startFrom: {
      opacity: 1,      
    },
    startTo: {
      opacity: 1,      
    },
  }, 
  fade: {
    label: "Fade In",
    startFrom: {
      opacity: 0,      
    },
    startTo: {
      opacity: 1,      
    },
  }, 
  fadeAndMoveUp: { 
    label: "Fade Up",
    startFrom: {
      opacity: 0,
      positionY: -20,
    },
    startTo: {
      opacity: 1,
      positionY: 0,
    },
  },
  fadeAndMoveDown: { 
    label: "Fade Down",
    startFrom: {
      opacity: 0,
      positionY: 20,
    },
    startTo: {
      opacity: 1,
      positionY: 0,
    },
  },
  fadeAndMoveLeft: { 
    label: "Fade Right",
    startFrom: {
      opacity: 0,
      positionX: -40,
    },
    startTo: {
      opacity: 1,
      positionX: 0,
    },
  },
  fadeAndMoveRight: { 
    label: "Fade Left",
    startFrom: {
      opacity: 0,
      positionX: 40,
    },
    startTo: {
      opacity: 1,
      positionX: 0,
    },
  },
  fadeAndScaleUp: {
    label: "Scale Up",
    startFrom: {
      opacity: 0,
      scale: 0.8,
    },
    startTo: {
      opacity: 1,
      scale: 1,
    },
  },
  fadeAndScaleIn: { 
    label: "Scale Down",
    startFrom: {
      opacity: 0,
      scale: 1.5,      
    },
    startTo: {
      opacity: 1,
      scale: 1,
    },
  },
  
};