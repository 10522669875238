import React, { useRef, useEffect } from 'react';
import { Text } from '@react-three/drei';

const TextSlideWordAnimateContents = ({ time, FPS, animationStates, initialProperties, wordsArray, wordStartTimes, fontFamily, fontWeight, fontSize, wordIsHidden, letterSpacing, textColor, allWordsSyncAnimation }) => {
  const groupRefs = useRef(wordsArray.map(() => React.createRef()));
  const textRefs = useRef(wordsArray.map(() => React.createRef()));

  // Update refs when wordsArray changes
  useEffect(() => {
    groupRefs.current = wordsArray.map(() => React.createRef());
    textRefs.current = wordsArray.map(() => React.createRef());
  }, [wordsArray]);

  // Set initial opacity for each text based on wordIsHidden
  useEffect(() => {
    textRefs.current.forEach((ref, index) => {
      if (ref.current && ref.current.material) {
        ref.current.material.opacity = wordIsHidden[index] ? 0 : 1;
      }
    });
  }, [wordIsHidden]);

  // Handling animations for each group
  useEffect(() => {
    const frameIndex = Math.floor(time * FPS);
    wordsArray.forEach((wordObj, index) => {
      const groupRef = groupRefs.current[index];
      const textRef = textRefs.current[index];

      if (groupRef.current) {
        let adjustedFrameIndex = frameIndex;        
        if (!allWordsSyncAnimation) {

          adjustedFrameIndex -= Math.floor(wordStartTimes[index] * FPS);
        }

        // Update each property based on animation states
        Object.keys(animationStates).forEach(property => {
          const state = animationStates[property][adjustedFrameIndex];
          if (state !== undefined) {
            if (property.startsWith('position')) {
              const axis = property.slice('position'.length).toLowerCase();
              groupRef.current.position[axis] = state;
            } else if (property === 'scale') {
              groupRef.current.scale.set(state, state, state);
            } else if (property.startsWith('rotation')) {
              const axis = property.slice('rotation'.length).toLowerCase();
              groupRef.current.rotation[axis] = state;
            }

            // Apply opacity to the text's material
            if (property === 'opacity' && textRef.current && textRef.current.material) {
              textRef.current.material.opacity = wordIsHidden[index] ? 0 : state;
            }
          }
        });
      }
    });
  }, [time, FPS, animationStates, wordsArray, wordStartTimes, wordIsHidden, allWordsSyncAnimation]);

  return (
    <>
      {wordsArray.map((wordObj, index) => (
        <group 
          key={index}
          ref={groupRefs.current[index]}
          position={[initialProperties.positionX, initialProperties.positionY, initialProperties.positionZ]}
          scale={[initialProperties.scale, initialProperties.scale, initialProperties.scale]}
        >
          <Text        
            ref={textRefs.current[index]}
            anchorX="left"
            anchorY="top"
            fontSize={fontSize}                
            textAlign="left"
            letterSpacing={letterSpacing}
            font={`../fonts/${fontFamily}/${fontFamily}-${fontWeight}.woff`}
            position={[wordObj.rect.normalLeft, wordObj.rect.normalTop, 10]}
          >
            <meshBasicMaterial transparent={true} color={textColor} />
            {wordObj.word}
          </Text>
        </group>
      ))}
    </>
  );
};

export default TextSlideWordAnimateContents;
