const WebcamClipNodeSpec = {
	attrs: {
		id:{default:'defaultID'},
		type:{default:'webcam'},
		isPlaceholder:{default:true},
		isUploadingVideo:{default:false},
		captureId:{default:null},
		fileName:{default:null},
		name:{default:null},
		relativeStartTime: {default:0},
		pinnedStartTime:{default:null},
		duration:  {default:0},
		minDuration:{default:0},
		placeholderDuration:{default:0},
		metadata: { default: [] },
		zIndex:  {default:0},
		recordingSegments:{default:[]},
		segments:{default:[]},
		sceneId:{default:'defaultSceneId'},
		clipPlaybackRate:{default:1},
		clipIndex:{default:0},

	},
};

 export default WebcamClipNodeSpec
 

 