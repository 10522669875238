import React from 'react';
import Icon from '../misc/Icon'

const ELECTRON_REDIRECT_URI="yarndev://auth/google/callback"
const WEB_REDIRECT_URI="http://localhost:3000/auth/google/callback"
const OAUTH_URL = "https://accounts.google.com/o/oauth2/v2/auth";
const redirectUri = "http://localhost:8000/auth/google/callback"; // Your Node.js callback URI
const GOOGLE_CLIENT_ID = "985997354828-kefep2d9gsferpbtvbv7lecrlvrkq8ca.apps.googleusercontent.com"; // Replace with your Google Client ID
const SCOPES = "profile email";



const GoogleLogin = () => {


	const handleLogin = () => {
		const url = `${process.env.REACT_APP_API_ENDPOINT}/auth/google`
		if(window.isElectron){
			//let url = `${OAUTH_URL}?response_type=code&scope=${SCOPES}&client_id=${GOOGLE_CLIENT_ID}&redirect_uri=${ELECTRON_REDIRECT_URI}&access_type=offline&prompt=consent`
			window.ipcRenderer.send('open-oauth-window',{url:url})	  
		}else{
			//let url = `${OAUTH_URL}?response_type=code&scope=${SCOPES}&client_id=${GOOGLE_CLIENT_ID}&redirect_uri=${WEB_REDIRECT_URI}&access_type=offline&prompt=consent`
			console.log(url)
			window.location= url
		}

	};

	return (
		
		<button className='signUpFlow-panel-submitBtn signUpFlow-panel-submitBtn--google' onClick={handleLogin}>
			<div className='signUpFlow-panel-submitBtn-iconContainer'>
				<Icon name='googleIconLarge' />
			</div>
			<div className='signUpFlow-panel-submitBtn-label'>
				Continue with Google
			</div>							
		</button>

	);
};

export default GoogleLogin;



	// const handleLogin = () => {
	//     window.open("http://localhost:8000/auth/google", "_self");
	// };




    // const handleLogin = () => {
	  //   const googleAuthUrl = "https://accounts.google.com/o/oauth2/v2/auth";
	  //   const redirectUri = "http://localhost:8000/auth/google/callback"; // Your Node.js callback URI
	  //   const clientId = "985997354828-kefep2d9gsferpbtvbv7lecrlvrkq8ca.apps.googleusercontent.com"; // Replace with your Google Client ID
	  //   const scope = "profile email";
	  //   const responseType = "code";

	  //   const authUrl = `${googleAuthUrl}?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&access_type=offline&prompt=consent`;

	  //   //window.open(authUrl, "_self");
	  //    window.ipcRenderer.send('open-oauth-window',{url:authUrl})	
    // };
