import React, { useState } from 'react';
import Icon from '../../misc/Icon'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'

function LibraryPageHeaderGalleryFilter({ galleryDisplay }) {  

    return (
      
      

      <DropdownMenu.Root modal={false}>
        <DropdownMenu.Trigger asChild>
          <button className='uiButton uiButton--filter uiButton--iconOnly'>
            <div className='uiButton-iconContainer'>
              <Icon name='filter' />
            </div>              
          </button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content             
          align="end"      
          alignOffset={-40}
          className='dropdownMenu dropdownMenu--sortOrder forceDarkTheme'
        >            
          <div className='dropdownMenu-explainer'>
            Filter
          </div>              
            <DropdownMenu.Item>
              Drafts
            </DropdownMenu.Item>
            <DropdownMenu.Separator/>
            <DropdownMenu.Item>
              Faire
            </DropdownMenu.Item>
            <DropdownMenu.Item>
              Sales
            </DropdownMenu.Item>
            <DropdownMenu.Item>
              Landing
            </DropdownMenu.Item>              
        </DropdownMenu.Content>
      </DropdownMenu.Root>  

          
    );
}

export default LibraryPageHeaderGalleryFilter
