import ReactDOM from 'react-dom'
import React from 'react'
import SlideTextElementGhost from '../../three/slide/slideEditor/SlideTextElementGhost';
import SlideEditorTextInput from '../../three/slide/slideEditor/SlideEditorTextInput'



const SCENE_WIDTH = 1920
const SCENE_HEIGHT = 1080


// const MAX_ELEMENT_HEIGHT=200
//To calcualte the fontsize 

const fontSizeMultipliers = [1,0.95, 0.9,0.85, 0.8,0.75, 0.7, 0.65,0.6,0.55, 0.5,0.4,0.3]


function calculateDynamicFontSize(element,maxHeight) {
  return new Promise((resolve) => {    
    const ghostContainer = document.createElement('div');
    ghostContainer.className = 'editor-slideEditor-ghostContainer';
    ghostContainer.style.width = `${SCENE_WIDTH}px`;
    ghostContainer.style.height = `${SCENE_HEIGHT}px`;
    ghostContainer.style.position = 'absolute';
    document.body.appendChild(ghostContainer);
    // console.log('calculate dynamic height')
    // console.log(`max height is `)
    // console.log(maxHeight)

    //make a ghost element for each font multiple
    
    ReactDOM.render(
      <React.Fragment>
        {fontSizeMultipliers.map(fontSizeMultiplier => (
          <SlideTextElementGhost
            key={`ghost_${element.id}_dynamic_${fontSizeMultiplier}`}
            elementId={element.id}
            element={element}
            slideElement={element}
            width={element.width}
            fontSizeMultiplier={fontSizeMultiplier}
          />
        ))}
      </React.Fragment>,
      ghostContainer,
      () => {
        setTimeout(() => {
        let optimalMultiplier = null;
        let optimalFontSize = null;

        for (let i = 0; i < fontSizeMultipliers.length; i++) {
          const multiplier = fontSizeMultipliers[i];
          const ghostElement = document.getElementById(`ghost_${element.id}_dynamic_${multiplier}`);
          if (ghostElement) {
          const ghostHeight = ghostElement.clientHeight;
          console.log(`Multiplier: ${multiplier}, Height: ${ghostHeight}`);
          if (ghostHeight <= maxHeight) {
            optimalMultiplier = multiplier;
            optimalFontSize = parseFloat(element.metadata.textProperties.fontSize) * multiplier;
             break;
            }
         }
        }
        if (optimalMultiplier !== null) {
          console.log(`Optimal font size found: ${optimalFontSize}px (multiplier: ${optimalMultiplier})`);
          element.metadata.textProperties.fontSize = optimalFontSize.toString();
        } else {
          console.log(`No suitable font size found. Using smallest multiplier.`);
          const smallestMultiplier = fontSizeMultipliers[fontSizeMultipliers.length - 1];
          element.metadata.textProperties.fontSize = (parseFloat(element.metadata.textProperties.fontSize) * smallestMultiplier).toString();
        }

        // Clean up ghost elements
          ReactDOM.unmountComponentAtNode(ghostContainer);
          document.body.removeChild(ghostContainer);
          resolve(element);
         
           }, 300);
      }
    );
  });
}



export { calculateDynamicFontSize }



