const blobCache = {};

export const fetchDeviceVideoUrl = async (captureId) => {
  let videoURL;
  if (captureId) {
    const videoPath = `deviceRecordings/${captureId}/deviceRecording.mov`;
    if (blobCache[videoPath]) {
      return blobCache[videoPath];
     } else {
      if(window.isElectron){
        const videoBuffer = await ipcRenderer.invoke('read-file-buffer', videoPath);
        const videoBlob = new Blob([videoBuffer], { type: 'video/mp4' });
        videoURL = URL.createObjectURL(videoBlob);
      }else{
        const s3BaseUrl = 'https://yarn-assets.s3.amazonaws.com/';
        videoURL = s3BaseUrl + videoPath;
      }
      blobCache[videoPath] = videoURL;
      return videoURL;
    }
  } 
};
