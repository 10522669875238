import store from '../store'
import find from 'lodash/find'



export function getUserForId(userId) {	
	let user={}
	const state = store.getState()
	const orgMember= find(state.orgMembers, {'id':Number(userId)})
	if(orgMember){
		user=orgMember
	}
	return user
}

