import { SpringAnimator } from '../animations/SpringAnimator';

const FPS = 60; // Frames per second

// Animation parameters for different motion settings
const animationParamsMap = {
  zoomSmooth: { mass: 1, stiffness: 120, damping: 30, precision: 1, instant: false },
  zoomZippy: { mass: 1, stiffness: 220, damping: 28, precision: 1, instant: false },
  zoomSlow: { mass: 1, stiffness: 120, damping: 80, precision: 1, instant: false },
  zoomInstant: { mass: 1, stiffness: 120, damping: 80, precision: 1, instant: true }
};

export const calculateAnimatedCameraBoxFrames = (cameraBoxSequence, zoomClips) => {
  const animatedCameraBoxFrames = [];
  const propertySprings = {};
  let lastMotionSettings = cameraBoxSequence[0]?.motionSettings || 'zoomSmooth';

  // Initialize the springs for each camera box property
  const defaultCameraBox = { x: 0, y: 0, width: 1920, height: 1080 };
  Object.keys(defaultCameraBox).forEach(property => {
    propertySprings[property] = new SpringAnimator(
      animationParamsMap[lastMotionSettings].mass,
      animationParamsMap[lastMotionSettings].stiffness,
      animationParamsMap[lastMotionSettings].damping,
      defaultCameraBox[property],
      0,
      animationParamsMap[lastMotionSettings].precision,
      animationParamsMap[lastMotionSettings].instant,
    );
  });

  // Calculate the total animation duration
  const totalDuration = parseFloat(Object.keys(cameraBoxSequence).pop()) + 5; // Additional time for springs to settle

  // Simulate the animation frame by frame
  for (let frame = 0; frame <= totalDuration * FPS; frame++) {
    const currentTime = frame / FPS;
    let targetValues = defaultCameraBox;

    // Process each entry in the cameraBoxSequence to find the current target values
    for (const time in cameraBoxSequence) {
      if (parseFloat(time) <= currentTime) {
        targetValues = cameraBoxSequence[time];
        if (targetValues.motionSettings !== lastMotionSettings) {
          // Update the springs with new motion settings
          lastMotionSettings = targetValues.motionSettings;
          const newParams = animationParamsMap[lastMotionSettings];
          Object.keys(propertySprings).forEach(property => {
            const oldSpring = propertySprings[property];
            propertySprings[property] = new SpringAnimator(
              newParams.mass,
              newParams.stiffness,
              newParams.damping,
              oldSpring.position, // Keep current position
              oldSpring.velocity, // Keep current velocity
              newParams.precision,
              newParams.instant
            );
          });
        }
      }
    }

    // Set targets and simulate springs
    const frameData = { time: currentTime };
    Object.keys(targetValues).forEach(property => {
      if (property !== 'time' && property !== 'motionSettings') {
        const spring = propertySprings[property];
        spring.setTarget(targetValues[property]);
        spring.simulate(1000 / FPS);
        frameData[property] = spring.position;
      }
    });

    animatedCameraBoxFrames.push(frameData);
  }

  return animatedCameraBoxFrames;
};
