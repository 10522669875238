const axios = require('axios');

export async function fetchLogoForDomain(domain) {
  return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/brandLogo?domain=${domain}`, {})
    .then(response => {
      const logos = response.data  //returns orginal logo and processedLogo which has padding removed
      return logos.processedLogo
    })
    .catch(error => {
      throw error;
    });
}
