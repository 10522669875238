import axios from 'axios';
import { post, put } from '../api';
import { Mixpanel } from '../Mixpanel';
import { getOrgName } from '../utils/getOrgName';
import { getOrgId } from '../utils/getOrgId';
import { getCurrentUserName } from '../utils/getCurrentUserName';

export async function handleSaveExport(projectId, resultUrl) {
    try {
        // Download the file from the S3 link
        const response = await axios.get(resultUrl, { responseType: 'blob' });
        const fileBlob = response.data;

        // Create a new export
        const createExportResponse = await post('/exports', { project_id: projectId });
        const { uploadURL, exportId } = createExportResponse;

        // Upload the downloaded file to the new location
        await axios.put(uploadURL, fileBlob, { 
            headers: { 'Content-Type': 'video/mp4' },
            maxContentLength: Infinity,
            maxBodyLength: Infinity
        });

        // Mark the export as complete
        const updateResponse = await put(`/exports/${exportId}/complete`);
        
        const filename = updateResponse.export.filename;
        const awsUrl = `https://yarn-assets.s3.amazonaws.com/exports/${getOrgId()}/${filename}`;
    
        Mixpanel.track('export_finished', {
            url: awsUrl,
            org: getOrgName(),
            user: getCurrentUserName()
        });

        return awsUrl;
    } catch (error) {
        console.error('Error saving export:', error);
        throw error; // Rethrow the error for the caller to handle
    }
}
// export async function handleSaveExport( projectId,filePath) {
//      try {
//         const createExportResponse = await post('/exports', { project_id: projectId });
//         const { uploadURL,exportId } = createExportResponse;
//         const videoBuffer = await ipcRenderer.invoke('read-export-buffer', filePath);
//         await axios.put(uploadURL, videoBuffer, { headers: { 'Content-Type': 'video/mp4' } });
//         const updateResponse = await put(`/exports/${exportId}/complete`);
        
//         const response= await put(`/exports/${exportId}/complete`);
//         const filename = response.export.filename

//         const awsUrl=`https://yarn-assets.s3.amazonaws.com/exports/${getOrgId()}/${filename}`

    
//         Mixpanel.track('export_finished',{
//             url:awsUrl,
//             org:getOrgName(),
//             user:getCurrentUserName()

//         })

//     } catch (error) {
//         console.error('Error saving export:', error);
//         // Handle error (e.g., display error message to the user)
//     }
// }

