
function applyVoiceoverPlaybackRateToSubtitles(subtitlesGroups,voiceoverPlaybackRate) {

  let adjustedSubtitlesGroups =[]
  subtitlesGroups.forEach((group)=>{
    const adjustedGroup={
      ...group,
      startTime:(group.originalStartTime || group.startTime)/voiceoverPlaybackRate,
      endTime:(group.originalEndTime || group.endTime)/voiceoverPlaybackRate
    }
    adjustedSubtitlesGroups.push(adjustedGroup)


  })

  return adjustedSubtitlesGroups

}

export { applyVoiceoverPlaybackRateToSubtitles };