import React, { useMemo } from 'react';
import { useLoader, useFrame } from '@react-three/fiber';
import * as THREE from 'three';

const SCENE_WIDTH = 1920;
const SCENE_HEIGHT = 1080;

const CanvasBackground = ({ background, renderOrder}) => {
  let type,rgba,src 
  if(background){
    type = background.type
    rgba = background.rgba
    src = background.src    
  }

 // const { type, rgba, src } = background;

  const bgTexture = useMemo(() => {
    return type === 'image' && src ? useLoader(THREE.TextureLoader, src) : null;
  }, [src, type]);


  return (
    <>      
      {type === 'image' && bgTexture && (
        <mesh position={[0, 0, 0]} renderOrder={renderOrder}>
          <planeGeometry args={[SCENE_WIDTH, SCENE_HEIGHT]}/>
          <meshBasicMaterial transparent={true} toneMapped={false}  map={bgTexture} />
        </mesh>
      )}

      {type === 'solid' && rgba && (
        <mesh position={[0, 0, 0]} renderOrder={renderOrder}>
          <planeGeometry args={[SCENE_WIDTH, SCENE_HEIGHT]} />
          <meshBasicMaterial transparent={true}  toneMapped={false} color={rgba} />
        </mesh>
      )}
    </>
  );
};

export default CanvasBackground;
