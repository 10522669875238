import React, { useRef, useEffect } from 'react';
import { Text } from '@react-three/drei';
import {getTextColorForId} from '../../utils/brands/getTextColorForId'



const SlideTextElementMeshes = ({ time, FPS, animationStates, initialProperties, lettersArray, letterStartTimes, fontFamily, fontWeight, fontSize, letterIsHidden, letterSpacing, textColor, allLettersSyncAnimation, zIndex, showSlideTextStatic, defaultValues, slideVisible, textElementOpacity }) => {
  const groupRefs = useRef(lettersArray.map(() => React.createRef()));
  const textRefs = useRef(lettersArray.map(() => React.createRef()));

  // Update refs when lettersArray changes
  useEffect(() => {
    groupRefs.current = lettersArray.map(() => React.createRef());
    textRefs.current = lettersArray.map(() => React.createRef());
  }, [lettersArray]);

  // Set initial opacity for each text based on letterIsHidden
  useEffect(() => {
    textRefs.current.forEach((ref, index) => {
      if (ref.current && ref.current.material) {
        const initialOpacity = letterIsHidden[index] && (!showSlideTextStatic || !slideVisible) ? 0 : 1
        ref.current.material.opacity = initialOpacity;        
      }
    });
  }, [letterIsHidden]);

  // console.log('showSlideTextStatic', showSlideTextStatic)
  // console.log('slideVisible', slideVisible)

  //console.log('showSlideTextStatic', showSlideTextStatic)

  // Handling animations for each group
  useEffect(() => {
    const frameIndex = Math.floor(time * FPS);
    lettersArray.forEach((letterObj, index) => {
      const groupRef = groupRefs.current[index];
      const textRef = textRefs.current[index];

      if (groupRef.current) {
        let adjustedFrameIndex = frameIndex;        
        if (!allLettersSyncAnimation) {
          adjustedFrameIndex -= Math.floor(letterStartTimes[index] * FPS);          
        }        

        // Update each property based on animation states
        Object.keys(animationStates).forEach(property => {
          
          let state = animationStates[property][adjustedFrameIndex];
          
          if(showSlideTextStatic){
            state = defaultValues[property];            
          }

          if (state !== undefined) {
            if (property.startsWith('position')) {
              const axis = property.slice('position'.length).toLowerCase();
              groupRef.current.position[axis] = state;
            } else if (property === 'scale') {
              groupRef.current.scale.set(state, state, state);
            } else if (property.startsWith('rotation')) {
              const axis = property.slice('rotation'.length).toLowerCase();
              groupRef.current.rotation[axis] = state;
            }

            // Apply opacity to the text's material
            if (property === 'opacity' && textRef.current && textRef.current.material) {
              const forceHide = letterIsHidden[index] && (!showSlideTextStatic && !slideVisible)
              const maxOpacity = letterObj.opacity || textElementOpacity
              textRef.current.material.opacity = forceHide ? 0 : state * maxOpacity;
            }
          }
        });
      }
    });
  }, [time, FPS, animationStates, lettersArray, letterStartTimes, letterIsHidden, allLettersSyncAnimation]);

//  console.log('Fonts')
 // console.log(`../fonts/${fontFamily}/${fontFamily}-${fontWeight}.woff`)



  return (
    <>
      {lettersArray.map((letterObj, index) => {
        let color = null
        if(letterObj.colorId){
          const colorObj=getTextColorForId(letterObj.colorId)
          if(colorObj){
            color=colorObj.rgba
          }
        }        
        return(
          <group 
            key={index}
            ref={groupRefs.current[index]}
            position={[initialProperties.positionX, initialProperties.positionY, 0]}
            scale={[initialProperties.scale, initialProperties.scale, initialProperties.scale]}
            renderOrder={zIndex}
          >

            <Text        
              ref={textRefs.current[index]}
              anchorX="left"
              anchorY="top"
              fontSize={fontSize}                
              textAlign="left"
              letterSpacing={letterSpacing}
              font={`../fonts/${fontFamily}/${fontFamily}-${fontWeight}.woff`}
              position={[letterObj.rect.normalLeft, letterObj.rect.normalTop, 0]}
              renderOrder={zIndex}
            >
              <meshBasicMaterial renderOrder={zIndex} transparent={true} color={color || textColor} />
                {letterObj.letter}
            </Text>
          </group>
        )
        }      
      )}
    </>
  );
};

export default SlideTextElementMeshes;
