import {LayoutGroup} from '../LayoutGroup'
import getSlideConfigs from './getSlideConfigs'

const slideConfigs = getSlideConfigs()
const {slideWidth,slideHeight}=slideConfigs




const LEFT_INSET = 160;
const RIGHT_INSET = 160;
const TOP_INSET = 80;
const BOTTOM_INSET = 80;
const TEXT_ELEMENT_MAX_WIDTH = 1400;

//for pinning for hstack we use valign and for vstack we use halign


export function calculateElementPositions(layout) {
  positionNestedLayouts(layout, 0, 0, slideWidth, slideHeight);
  adjustFreeTextElements(layout, slideHeight);
}


function adjustFreeTextElements(layout, slideHeight) {
    layout.children.forEach(child => {
      if (child.type === 'text') {
        adjustTextElement(child, slideHeight);
      } 
    });
  }

function calculateLayoutDimensions(layout) {
  let width = 0;
  let height = 0;
  let lastChildMargin = 0;

  layout.children.forEach((child, index) => {
    let childWidth, childHeight;
    if (child instanceof LayoutGroup) {
      const childDimensions = calculateLayoutDimensions(child);
      childWidth = childDimensions.width;
      childHeight = childDimensions.height;
    } else {
      childWidth = child.width;
      childHeight = child.height;
    }

    if (layout.type === 'hstack') {
      width += childWidth;
      if (index < layout.children.length - 1) {
        width += child.rightMargin || 0;
      } else {
        lastChildMargin = child.rightMargin || 0;
      }
      height = Math.max(height, childHeight);
    } else { // vstack
      width = Math.max(width, childWidth);
      height += childHeight;
      if (index < layout.children.length - 1) {
        height += child.bottomMargin || 0;
      } else {
        lastChildMargin = child.bottomMargin || 0;
      }
    }
  });

  return { width, height, lastChildMargin };
}

function adjustTextElement(element, slideHeight) {
  const bottomY = element.y + element.height;
  if (bottomY > (slideHeight - 10)) {
    const overflow = bottomY - (slideHeight - 10);
    element.y -= overflow;
  }
}


function positionNestedLayouts(layout, startX, startY, slideWidth, slideHeight) {
  if (layout.type === 'free') {
    layout.children.forEach(child => {
      if (child instanceof LayoutGroup) {
        positionNestedLayouts(child, startX + child.x, startY + child.y, slideWidth, slideHeight);
      } 
    });
    return;
  }

  let currentX = startX;
  let currentY = startY;

  // First pass: calculate total dimensions
  const { width, height } = calculateLayoutDimensions(layout);

  
  // Apply pinning for vstack and hstack
  if (layout.type === 'vstack') {
    const pin = layout.vAlign || 'middle'; // Use vAlign as pin for vstack
    const originalHeight = layout.height || height;

    switch (pin) {
      case 'top':
        layout.y = startY;
        break;
      case 'bottom':
        layout.y = startY + (originalHeight - height);
        break;
      case 'middle':
      default:
        const growth = height - originalHeight;
        layout.y = startY - growth / 2;
        break;
    }
    currentY = layout.y;
  } else if (layout.type === 'hstack') {
    const pin = layout.hAlign || 'middle'; // Use hAlign as pin for hstack
    const originalWidth = layout.width || width;

    switch (pin) {
      case 'left':
        layout.x = startX;
        break;
      case 'right':
        layout.x = startX + (originalWidth - width);
        break;
      case 'middle':
      default:
        const growth = width - originalWidth;
        layout.x = startX - growth / 2;
        break;
    }
    currentX = layout.x;
  }

  layout.width = width;
  layout.height = height;


  // Second pass: position children with alignment
  layout.children.forEach((child, index) => {
    let childWidth, childHeight;

    if (child instanceof LayoutGroup) {
      const childDimensions = calculateLayoutDimensions(child);
      childWidth = childDimensions.width;
      childHeight = childDimensions.height;
      
      if (layout.type === 'hstack') {
        child.x = currentX;
        
        const vAlign = layout.vAlign
        // Apply vAlign for hstack
        switch(vAlign) {
          case 'top':
            child.y = currentY;
            break;
          case 'bottom':
            child.y = currentY + layout.height - childHeight;
            break;
          case 'middle':
          default:
            child.y = currentY + (layout.height - childHeight) / 2;
            break;
        }

        currentX += childWidth + (child.rightMargin || 0);
      } else { // vstack
        const hAlign = layout.hAlign;
        // Apply hAlign for vstack
        switch(hAlign) {
          case 'left':
            child.x = currentX;
            break;
          case 'right':
            child.x = currentX + layout.width - childWidth;
            break;
          case 'middle':
          default:
            child.x = currentX + (layout.width - childWidth) / 2;
            break;
        }

        child.y = currentY;
        currentY += childHeight + (child.bottomMargin || 0);
      }

      child.width = childWidth;
      child.height = childHeight;
      positionNestedLayouts(child, child.x, child.y, slideWidth, slideHeight);
    } else if (child) {
      childWidth = child.width;
      childHeight = child.height;

      if (layout.type === 'hstack') {
        child.x = currentX;
        
        const vAlign = layout.vAlign;
        // Apply vAlign for hstack
        switch(vAlign) {
          case 'top':
            child.y = currentY;
            break;
          case 'bottom':
            child.y = currentY + layout.height - childHeight;
            break;
          case 'middle':
          default:
            child.y = currentY + (layout.height - childHeight) / 2;
            break;
        }

        currentX += childWidth + (child.rightMargin || 0);
      } else { // vstack
        const hAlign = layout.hAlign;
        // Apply hAlign for vstack
        switch(hAlign) {
          case 'left':
            child.x = currentX;
            break;
          case 'right':
            child.x = currentX + layout.width - childWidth;
            break;
          case 'middle':
          default:
            child.x = currentX + (layout.width - childWidth) / 2;
            break;
        }

        child.y = currentY;
        currentY += childHeight + (child.bottomMargin || 0);
      }
    }

      ///bug of death fix
      if (child.type === 'text') {
        adjustTextElement(child, slideHeight);
      }


  });

  // We no longer add the layout's own margin to its dimensions
}