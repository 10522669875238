import {getOrgBrand} from './getOrgBrand'
import find from 'lodash/find'

//Some logic here to find the right text color for a given background


//Figure out if its a light or dark background and then pick the 

function luminance(r, g, b) {
		const a = [r, g, b].map(function (v) {
				v /= 255;
				return v <= 0.03928 ? v / 12.92 : Math.pow( (v + 0.055) / 1.055, 2.4 );
		});
		return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

// Function to determine if background is light or dark
function isLightBackground(rgba) {
		const regex = /^rgba?\((\d+),\s*(\d+),\s*(\d+)/i;
		const matches = regex.exec(rgba);
		if (matches) {
				const lum = luminance(parseInt(matches[1]), parseInt(matches[2]), parseInt(matches[3]));
				return lum > 0.5;
		}
		return false; // Default in case of an error
}


function sortTextColorsByLuminance(textColors) {
	return textColors.map(color => {
		const regex = /^rgba?\((\d+),\s*(\d+),\s*(\d+)/i;
		const matches = regex.exec(color.rgba);
		return {
			...color,
			luminance: matches ? luminance(parseInt(matches[1]), parseInt(matches[2]), parseInt(matches[3])) : 0
		};
	}).sort((a, b) => a.luminance - b.luminance);
}



export function getTextColorForBackgroundId(id) {
	const orgBrand=getOrgBrand()
		const background = orgBrand.backgrounds.find(bg => bg.id === id);
		if (!background) return orgBrand.textColors[0]; // there should always be a background but just in case

		if(background.defaultTextColor){
			const textColors=orgBrand.textColors
			return find(textColors,{id:background.defaultTextColor})
		}



		let isLight


		if(background.type=='image'){ //use isLight field
			isLight=background.isLightbackground
		}else{
			isLight=isLightBackground(background.rgba)
		}

		//console.log(`isLightBackground--------- ${isLight}`)

		const sortedTextColors = sortTextColorsByLuminance(orgBrand.textColors);

		if (isLight) {
			return sortedTextColors[0];
		} else {
			return sortedTextColors[sortedTextColors.length - 1];
		}
}





// export function getTextColorForBackgroundId(id) {

//   let textColor
//   textColor = orgBrand.textColors[1]

//   return textColor
// }
