import axios from 'axios'
import store from './store'

//TODO look into headers, cache control and stuff
// axios.defaults.withCredentials = true

// let API=process.env.REACT_APP_API_ENDPOINT||window.envVars.reactAppApiEndpoint
let API=process.env.REACT_APP_API_ENDPOINT
let CLOUDINARY_API="https://api.cloudinary.com/v1_1/yarn/upload"

const getToken = () => {
  const state = store.getState()
  return state.authenticationStatus.token
};

const getHeaders = () => {
  const token = getToken();
  return {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
    'Authorization': `Bearer ${token}`,
     'Content-Type': 'application/json'
  };
};


const limitedFetch = async(url,params,limit,offset,fetchResponses)=>{
  const requestUrl=`${url}?limit=${limit}&&offset=${offset}`
  let response = await fetch(requestUrl,params)
  fetchResponses.push(...response)
  if(response.length==limit){
    const newOffset=offset+limit
   return await limitedFetch(url,params,limit,newOffset,fetchResponses)
  }else{
    return fetchResponses
  }
}


export const batchedFetch = async (
  url,
  params,
) => {
  let totalCount
  let offset=0
  let limit=100
  let fetchResponses=[]
  const responseArray = await limitedFetch(url,params,limit,offset,fetchResponses)
  return responseArray
};


export const fetch = async (
  url,
  params,
) => {
  const requestUrl=`${API}${url}`
  const headers = getHeaders()
  const result = await axios.get(requestUrl, {...params, headers})
  return result.data;
};


export const fetchAuth = async (
  url,
  params,
) => {
  const requestUrl=`${API}${url}`
  const result = await axios.get(requestUrl, {...params})
  return result.data;
};


 export const put = async (
  url,
  params,
) => {
  const requestUrl=`${API}${url}`
  const headers = getHeaders()
 // const result = await axios.put(requestUrl, {...params, headers})
    const result = await axios.put(requestUrl, params, {headers})
  return result.data;
};

 export const post = async (
  url,
  params,
) => {
  const requestUrl=`${API}${url}`
  const headers = getHeaders()
  const result = await axios.post(requestUrl, params, {headers})
//  console.log(result)
  return result.data;
};

export const postWithArrayBuffer = async (url, params) => {
  const requestUrl = `${API}${url}`;
  const headers = getHeaders();
  const result = await axios.post(requestUrl, params, {
    headers,
    responseType: 'arraybuffer'
  });
  return result.data;
}; 




export const deleteRequest = async (
  url
) => {
  const requestUrl = `${API}${url}`
  const headers = getHeaders()
  const result = await axios.delete(requestUrl, { headers })
  return result.data;
};



 export const postCloudinary = async (
  data
) => {
  const requestUrl=`${CLOUDINARY_API}`
  // delete axios.defaults.headers.common['Cache-Control'];
  // delete axios.defaults.headers.common['Pragma'];
  // delete axios.defaults.headers.common['Expires'];

  const result = await axios.post(requestUrl,data,{ withCredentials: false })
    //   axios.defaults.headers.common['Cache-Control']='no-cache'
    // axios.defaults.headers.common['Pragma']='no-cache'
    // axios.defaults.headers.common['Expires']='0'
  return result.data;
};



// export const deleteRequest = async (
//   url
// ) => {
//   const requestUrl=`${API}${url}`
//   const headers = getHeaders()
//   const result = await axios.delete(requestUrl,headers)
//   return result.data;
// };
