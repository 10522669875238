import findIndex from 'lodash/findIndex'




export default function (state = [], action) {
	switch (action.type) {
	

    case 'SIGN_OUT':
      return []
   
    case 'FETCH_SLIDE_TEMPLATES_SUCCESS':
      return action.response
      
    case 'CREATE_SLIDE_TEMPLATE_SUCCESS':
      return [...state,action.response]

       case 'ARCHIVE_SLIDE_TEMPLATE_SUCCESS':
      console.log('archive slide template success')
      console.log('Template ID to archive:', action.response.id)
      return state.filter(template => template.id !== action.response.id)
 
  
    default:
      return state;
	}
}
