import {archiveProjectRequest} from '../../actions/projects'
import {removeTab} from '../../actions/tabs'
import store from '../../store'

export async function archiveProject(projectId,history) {
  // console.log(`archive project ${projectId}`)
   store.dispatch(archiveProjectRequest(projectId))
   if(history){
      store.dispatch(removeTab(projectId,history))
   }

 }


