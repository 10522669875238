const CursorGameButton = ({ label, x, y, displayWidth, displayHeight, onClick }) => {
  // Define button size
  const buttonWidth = 20; // Adjust as needed
  const buttonHeight = 20; // Adjust as needed

  // Adjust position to center the button on (x, y)
  const left = x - buttonWidth / 2;
  const top = y - buttonHeight / 2;

  const buttonStyle = {
    position: 'absolute',
    left: `${left}px`,
    top: `${top}px`,
    width: `${buttonWidth}px`,
    height: `${buttonHeight}px`,
    // Other styles...
  };

  const handleClick = () => {
    onClick(x, y); // Pass button coordinates to the parent component
  };

  let showTooltipLeft = displayWidth - x < 100
  let showTooltipAbove = displayHeight - y < 100


  const tooltipStyle = {
    position: 'absolute',
    left: `${showTooltipLeft ? -50 : 16}px`,
    top: `${showTooltipAbove ? -30 : 16}px`,
        
  };

  return (
    <button className='cursorGame-button' style={buttonStyle} onClick={handleClick}>
      <div style={tooltipStyle} className='cursorGame-button--coordinate'>
        X:{Math.round(x)} <br/>Y:{Math.round(y)}
      </div>
    </button>
  );
};

export default CursorGameButton;
