export class SpringAnimator {
  constructor(mass, stiffness, damping, initialPosition = 0, initialVelocity = 0, precision = 0.01, instant = false){
    this.mass = mass;
    this.stiffness = stiffness;
    this.damping = damping;
    this.target = initialPosition;
    this.position = initialPosition;
    this.velocity = initialVelocity; // Initialize velocity with the argument
    this.precision = precision;
    this.instant = instant;
  }

  clampValue(min, max) {
    this.position = Math.min(Math.max(this.position, min), max);
  }

  setTarget(target) {
    this.target = target;
  }

  simulate(deltaMS, clamp = false, min = 0, max = 1) {
  
   if (this.instant) {
    // If instant flag is set, immediately update position and reset velocity
    this.position = this.target;
    this.velocity = 0;
    if (clamp) {
      this.clampValue(min, max);
    }
    return;
  }

  const deltaS = deltaMS / 1000;

  // Calculate the force based on the current position, target, and spring constants.
  const springForce = -(this.position - this.target) * this.stiffness;
  const dampingForce = -this.velocity * this.damping;

  // Calculate the acceleration.
  const acceleration = (springForce + dampingForce) / this.mass;


  // Update velocity and position.
  this.velocity += acceleration * deltaS;
  this.position += this.velocity * deltaS;

  if (clamp) {
    this.clampValue(min, max);
  }

  // Enforce precision for ending the simulation.
  if (Math.abs(this.position - this.target) < this.precision && Math.abs(this.velocity) < this.precision) {
    this.position = this.target;
    this.velocity = 0;
  }
}

  setInstant(instant) {
    this.instant = instant;
  }


  getPosition() {
    return this.position;
  }
}
