function getInitialState() {
  return JSON.parse(localStorage.getItem('userSettings')) || {
    updateBannerSlidesDismissed: false,
  };
}

export default function(state = getInitialState(), action) {
  switch (action.type) {
  
    case 'UPDATE_USER_SETTING':
      const { setting, value } = action.payload;
      return updateAndSave({
        ...state,
        [setting]: value
          
        
      });
    
    default:
      return state;
  }
}

function updateAndSave(newState) {
  localStorage.setItem('userSettings', JSON.stringify(newState));
  return newState;
}