import { EditorState, Plugin } from "prosemirror-state"
import { history, undo, redo,closeHistory } from "prosemirror-history"
import timelineSchema from '../../timelineProsemirror/schema/timelineSchema'
import { createClipPMNode,createScenePMNode } from './nodeCreators'

//User actions that we want to group into a tr
//drag
//resize clip 
// add clip
//delete 
//start time in clip node attrs is relative to the start of the scene not the absolute time in the timeline

export class ProseMirrorManager {

	constructor(setPMDocForDevMode) {
		this.setPMDocForDevMode=setPMDocForDevMode 
		this.initEditorState()
		this.editingAction = null; //drag,addClip etc
		this.currentTransaction = null
	}

	initEditorState() {
		this.doc = timelineSchema.nodes.doc.createAndFill();
		this.editorState = EditorState.create({
			doc: this.doc,
			plugins: [history()]
		});
	}

	startAction(actionType = 'default') {
		if (!this.currentTransaction) {
			this.currentTransaction = this.editorState.tr;
			this.editingAction = actionType;
			this.currentTransaction.setMeta('actionType', actionType);
		}
	}

	endAction() {
		if (this.currentTransaction && this.editingAction) {
			closeHistory(this.currentTransaction) //this prevents e.g adding lots of clips quickly from being grouped together in 1 undo step
			this.editorState = this.editorState.apply(this.currentTransaction);
			this.setPMDocForDevMode(this.editorState.doc);
			this.currentTransaction = null;
			this.editingAction = null;
		}
	}

	addSceneToPMDoc(scene,isInitialLoad){
		let tr = this.currentTransaction || this.editorState.tr;
		if(isInitialLoad){
			tr.setMeta('addToHistory', false)
		}
		const node =  createScenePMNode(scene);
		const mappedPos = tr.mapping.map(this.editorState.doc.content.size);
		tr.insert(mappedPos, node);
		if (!this.currentTransaction) {
			this.editorState = this.editorState.apply(tr);
			this.setPMDocForDevMode(this.editorState.doc);
		} else {
			this.currentTransaction = tr;
		}
	}

	addClipToPMDoc(clip,isInitialLoad) {
		let tr = this.currentTransaction || this.editorState.tr;
		if(isInitialLoad){
			tr.setMeta('addToHistory', false)
		}
		const node = createClipPMNode(clip);
		const mappedPos = tr.mapping.map(this.editorState.doc.content.size);
		tr.insert(mappedPos, node);
		if (!this.currentTransaction) {// If there was no ongoing transaction, apply the new transaction immediately
			this.editorState = this.editorState.apply(tr);
			this.setPMDocForDevMode(this.editorState.doc);
		} else {// If this action is part of an ongoing transaction, just update the current transaction
			this.currentTransaction = tr;
		}
	}

/////////////////////// SLIDES ///////////////////////////
syncSlideClip(updatedClip) {
//	console.log('sync slide clip here------')
	if(updatedClip){
  const clipId = updatedClip.id;
  let tr = this.currentTransaction || this.editorState.tr;
  let updated = false;

  this.editorState.doc.descendants((node, pos) => {
    if (node.type.name === 'slideClip' && node.attrs.id === clipId) {
      // Create a completely new node using the existing createSlideClipPMNode function
      const newNode = createClipPMNode(updatedClip);
      // Replace the entire slideClip node with the new one
      tr = tr.replaceWith(pos, pos + node.nodeSize, newNode);
      updated = true;
    }
  });

  if (updated) {
    if (!this.editingAction) {
      // If not in a grouped action, apply the transaction immediately
      this.editorState = this.editorState.apply(tr);
      this.setPMDocForDevMode(this.editorState.doc);
   //   console.log('SYNC SLIDE CLIP')
    } else {
      // If in a grouped action, update the current transaction
      this.currentTransaction = tr;
    }
  }
}
}


	onSlideDragResizeStart(){
//	console.log('start drag action')
		this.startAction('slideDragResize')
	}

	onSlideDragResizeEnd(){
		if (this.editingAction === 'slideDragResize') {
	//	console.log('end action here')
			this.endAction();
		}
	}
////////////////////////////////////////
//// dragging
	onDragStart() { //initialize a new transaction for batching updates
		this.startAction('drag')
	}

	onDragEnd() {
		if (this.editingAction === 'drag') {
			this.endAction();
		}
	}

	updateSceneIndex(sceneId, newSceneIndex) {
		let tr = this.currentTransaction || this.editorState.tr;
		let updated = false 
		this.editorState.doc.descendants((node, pos) => {
			if (node.attrs.id === sceneId) {
				const updatedAttrs = { ...node.attrs,sceneIndex:newSceneIndex };
				const mappedPos = tr.mapping.map(pos)
				tr.setNodeMarkup(mappedPos, null, updatedAttrs);
				updated = true;
			}
		})
		if (updated) {
			if (!this.editingAction) {// If not in a transaction, apply the changes immediately
				this.editorState = this.editorState.apply(tr);
				this.setPMDocForDevMode(this.editorState.doc);
			} else {// If in a transaction, the changes are applied when the transaction is committed
				this.currentTransaction = tr;
			}
		}
	}

	updateMultipleClipFields(updatesArray,preventUndo) {
		if(updatesArray.length){
			let tr = this.currentTransaction || this.editorState.tr;
			if(preventUndo){
				tr.setMeta('addToHistory', false)
			}
			let updated = false;
			updatesArray.forEach(update => {
					const { clipId, ...fields } = update; // Extract clipId and the rest of the fields
					this.editorState.doc.descendants((node, pos) => {
						if (node.attrs.id === clipId) {
							const updatedAttrs = { ...node.attrs, ...fields };
							tr.setNodeMarkup(pos, null, updatedAttrs);
							updated = true;
						}
					});
				});
			if (updated) {
				if (!this.editingAction) {// If not in a transaction, apply the changes immediately
					this.editorState = this.editorState.apply(tr);
					this.setPMDocForDevMode(this.editorState.doc);
				} else {// If in a transaction, the changes are applied when the transaction is committed
					this.currentTransaction = tr;
				}
			}
		}
	}

	createDocument(contentArray) {
		this.doc = timelineSchema.nodes.doc.createAndFill({}, contentArray);
		this.editorState = EditorState.create({
			doc: this.doc,
			plugins: [history()]
		});
		this.setPMDocForDevMode(this.editorState.doc)
	}

	updateNodeMetadata(nodeId, newMetadata) {
		const transaction = this.editorState.tr;
		let updated = false;
		this.editorState.doc.descendants((node, pos) => {
			if (node.attrs.id === nodeId) {
				const updatedAttrs = { ...node.attrs, metadata: { ...node.attrs.metadata, ...newMetadata } };
				transaction.setNodeMarkup(pos, null, updatedAttrs);
				updated = true;
			}
		});
		if (updated) {
			this.editorState = this.editorState.apply(transaction);
		}
		return updated;
	}

	//Use this for things like updating the placeholder img src (we dont want to undo to that state)
	updateNodeMetadataSilent(nodeId, newMetadata) {
		const transaction = this.editorState.tr;
		// Set meta flag to prevent this from being added to history
		transaction.setMeta('addToHistory', false);
		let updated = false;
		this.editorState.doc.descendants((node, pos) => {
			if (node.attrs.id === nodeId) {
				const updatedAttrs = { ...node.attrs, metadata: { ...node.attrs.metadata, ...newMetadata } };
				transaction.setNodeMarkup(pos, null, updatedAttrs);
				updated = true;
			}
		});
		if (updated) {
			this.editorState = this.editorState.apply(transaction);
			this.setPMDocForDevMode(this.editorState.doc);
		}
		return updated;
	}
	


	updateNodeAttrs(clip,message){
		let tr = this.currentTransaction || this.editorState.tr
		let updated = false
		this.editorState.doc.descendants((node, pos) => {
			if (node.attrs.id === clip.id) {
				let updatedAttrs = {...node.attrs, ...clip, metadata:{...clip.metadata}};
				tr.setNodeMarkup(pos, null, updatedAttrs);
				updated = true;
			}
		})
		if (updated) {
			if (!this.editingAction) {// If not in a transaction, apply the changes immediately
				this.editorState = this.editorState.apply(tr);
				this.setPMDocForDevMode(this.editorState.doc);
			} else {// If in a transaction, the changes are applied when the transaction is committed
				this.currentTransaction = tr;
			}
		} 
	}

	deleteNodeFromPmDoc(nodeId) {
		let tr = this.currentTransaction || this.editorState.tr
		let found = false
		let posToDelete = null
		this.editorState.doc.descendants((node, pos) => {
			if (node.attrs.id === nodeId && !found) {
				posToDelete = pos;
				found = true;
			}
		})
		// If a clip was found, map the position and perform the deletion
		if (found && posToDelete !== null) {
			let mappedPos = tr.mapping.map(posToDelete);
			tr.delete(mappedPos, mappedPos + tr.doc.nodeAt(mappedPos).nodeSize);
			if (!this.editingAction) {
				this.editorState = this.editorState.apply(tr);
				this.setPMDocForDevMode(this.editorState.doc);
			} else {// If in a transaction, the changes are applied when the transaction is committed
				this.currentTransaction = tr;
			}
		}
	}

	undo() {
		const result = undo(this.editorState, transaction => {
			this.editorState = this.editorState.apply(transaction);
			this.setPMDocForDevMode(this.editorState.doc);
		});
		if (result) {
			return true
		} else {
			console.log('No undo operation to perform.');
			return false
		}
	}

	redo() {
		const result = redo(this.editorState, transaction => {
			this.editorState = this.editorState.apply(transaction);
			this.setPMDocForDevMode(this.editorState.doc);
		});
		if (result) {
			return true
		} else {
			console.log('No redo operation to perform.');
			return false
		}
	}

	
	updateProjectSetting(field,value) { //backgroundID and playback rate
		const tr = this.editorState.tr;
		let updated = false;
		this.editorState.doc.descendants((node, pos) => {
			if (node.type.name === "projectSettings") {  // Assuming 'projectSettings' is the correct node type
				const updatedAttrs = { ...node.attrs, [field]:value };
				tr.setNodeMarkup(pos, null, updatedAttrs);
				updated = true;
			}
		})
		if (updated) {
			if (!this.editingAction) {
				this.editorState = this.editorState.apply(tr);
				this.setPMDocForDevMode(this.editorState.doc);
			} else {// If in a transaction, the changes are applied when the transaction is committed
				this.currentTransaction = tr;
			}
		}
	}

	destroy() {
		if (this.currentTransaction) {
			this.editorState = this.editorState.apply(this.currentTransaction);
			this.currentTransaction = null;
			this.editingAction = null;
		}
		this.editorState = null;
		this.doc = null;
		this.setPMDocForDevMode = null;
	}


}
