import React, { useEffect, useState } from 'react';
import { SpringAnimator } from '../../../three/utils/animations/SpringAnimator';
import CHDonutPartRing from './CHDonutPartRing';
import CHDonutFullRing from './CHDonutFullRing';
import CHDonutLabels from './CHDonutLabels';

const FPS = 60; // Frames per second
const calculateAnimationDuration = 10;

const CHDonut = ({ time, targetValue, suffix, postLabel, preLabel, animationType, isHidden, fontFamily, fontWeight, donutValueTextColor, donutLabelTextColor, donutFillColor, donutPartRingMaxOpacity, donutFullRingMaxOpacity}) => {
  let targetValueDecimal = targetValue > 1 ? targetValue / 100 : targetValue;
  const initialTargetValue = targetValueDecimal * 0.75;

  const outerRadius = 460;
  const thickness = 72;
  const innerRadius = outerRadius - thickness;

  const fullRingMaxOpacity = donutFullRingMaxOpacity;
  const partRingMaxOpacity = donutPartRingMaxOpacity;

  const springParams = { mass: 1, stiffness: 180, damping: 70 };

  const [percentFillFrames, setPercentFillFrames] = useState([]);
  const [ringOpacityFrames, setRingOpacityFrames] = useState([]);
  const [ringScaleFrames, setRingScaleFrames] = useState([]);

  useEffect(() => {
    const percentFillSpring = new SpringAnimator(springParams.mass, springParams.stiffness, springParams.damping, initialTargetValue);
    const ringOpacitySpring = new SpringAnimator(springParams.mass, springParams.stiffness, springParams.damping, 0);
    const ringScaleSpring = new SpringAnimator(springParams.mass, springParams.stiffness, springParams.damping, 0.8, 0, 0.001);

    const percentFillFrames = [];
    const ringOpacityFrames = [];
    const ringScaleFrames = [];

    for (let frame = 0; frame < FPS * calculateAnimationDuration; frame++) {
      percentFillSpring.setTarget(targetValueDecimal);
      percentFillSpring.simulate(1000 / FPS);
      percentFillFrames.push(percentFillSpring.position);

      ringOpacitySpring.setTarget(1);
      ringOpacitySpring.simulate(1000 / FPS);
      ringOpacityFrames.push(ringOpacitySpring.position);

      ringScaleSpring.setTarget(1); // Assuming the target scale is 1
      ringScaleSpring.simulate(1000 / FPS);
      ringScaleFrames.push(ringScaleSpring.position);
    }

    setPercentFillFrames(percentFillFrames);
    setRingOpacityFrames(ringOpacityFrames);
    setRingScaleFrames(ringScaleFrames);
  }, [targetValueDecimal]);

  const frameIndex = Math.floor(time * FPS);
  let percentFill = percentFillFrames[frameIndex] || targetValueDecimal;
  let ringOpacity = ringOpacityFrames[frameIndex] || 0;
  let ringScale = ringScaleFrames[frameIndex] || 1;

  if(animationType === 'none'){
    percentFill = targetValueDecimal
    ringScale = 1
    ringOpacity = 1
  }

  if(animationType === 'fadeIn'){
    percentFill = targetValueDecimal
    ringScale = 1    
  }

  if(animationType === 'scaleUp'){
    percentFill = targetValueDecimal    
  }


  if(isHidden){
    ringOpacity = 0
  }
  
  const fullRingOpacity = ringOpacity * fullRingMaxOpacity;
  const partRingOpacity = ringOpacity * partRingMaxOpacity;

  const formattedValue = Math.floor(percentFill * 100, 1);

  return (
    <>
      <group scale={[ringScale, ringScale, ringScale]} position={[0,0,0]}>
        {!isHidden &&
        <CHDonutFullRing
          color={donutFillColor}
          opacity={fullRingOpacity}
          outerRadius={outerRadius}
          innerRadius={innerRadius}
        />
        }
        {!isHidden &&
        <CHDonutPartRing
          color={donutFillColor}
          opacity={partRingOpacity}
          percentFill={percentFill}
          outerRadius={outerRadius}
          innerRadius={innerRadius}
        />
      }
      </group>

      <CHDonutLabels 
        animationType={animationType}
        formattedValue={formattedValue}
        time={time}
        suffix={suffix}
        postLabel={postLabel}
        preLabel={preLabel}
        fontFamily={fontFamily}
        fontWeight={fontWeight}
        isHidden={isHidden}
        donutValueTextColor={donutValueTextColor}
        donutLabelTextColor={donutLabelTextColor}
      />
    </>
  );
};

export default CHDonut;
