import store from '../../store'
import {fetchUploadedVideos} from '../../actions/videoUpload'

//poll for video upload status for when video uploads get stuck in uploading state

export async function checkVideoUploadStatus(videoId) {
	const videoUploads = await store.dispatch(fetchUploadedVideos())
	const video = videoUploads.find(upload => `${upload.id}` == videoId)
	if (video) {
		return video
	}
	return {status:'pending'}
}