import { calculateCameraBoxSequence } from '../zoom/calculateCameraBoxSequence';
import { calculateAnimatedCameraBoxFrames } from '../zoom/calculateAnimatedCameraBoxFrames';

const SCENE_WIDTH = 1920;
const SCENE_HEIGHT = 1080;

const defaultCameraBox = {
  width: 1920,
  height: 1080,
  x: 0,
  y: 0
};

export const calculateVideoZoom = (zoomClips, currentClipId, currentClipStartTime, currentClipDuration) => {

  const currentClipEndTime = currentClipStartTime + currentClipDuration;

  const zoomTimeCheck = 7

  const filteredZoomClips = zoomClips
    .filter(clip => {
      const zoomClipStartTime = clip.startTime;
      const zoomClipEndTime = clip.startTime + clip.duration;

      // Check if the zoom clip is within 5 seconds of the current clip
      return (
        (zoomClipStartTime >= currentClipStartTime - zoomTimeCheck && zoomClipStartTime <= currentClipEndTime + zoomTimeCheck) ||
        (zoomClipEndTime >= currentClipStartTime - zoomTimeCheck && zoomClipEndTime <= currentClipEndTime + zoomTimeCheck) ||
        (zoomClipStartTime <= currentClipStartTime - zoomTimeCheck && zoomClipEndTime >= currentClipEndTime + zoomTimeCheck)
      );
    })
    .slice() // Create a shallow copy to avoid mutating the original array
    .sort((a, b) => a.startTime - b.startTime); // Sort by startTime

  const newCameraBoxSequence = calculateCameraBoxSequence(filteredZoomClips);

  const adjustedSequence = {};
  Object.entries(newCameraBoxSequence).forEach(([time, value]) => {
    const adjustedTime = parseFloat(time) < 0 ? 0 : parseFloat(time);
    adjustedSequence[adjustedTime] = value;
  });

  const cleanedCameraBoxSequence = Object.fromEntries(
    Object.entries(adjustedSequence).sort((a, b) => parseFloat(a[0]) - parseFloat(b[0]))
  );

  const animatedCameraBoxFrames = calculateAnimatedCameraBoxFrames(cleanedCameraBoxSequence, filteredZoomClips);

  const zoomFrames = animatedCameraBoxFrames.map(frame => ({
    time: frame.time,
    scale: 1 / (frame.width / SCENE_WIDTH),
    originX: frame.x * (1 / (frame.width / SCENE_WIDTH)),
    originY: frame.y * -1 * (1 / (frame.width / SCENE_WIDTH))
  }));

  return zoomFrames;
};