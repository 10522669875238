import React, { useState } from 'react';
import Icon from '../../misc/Icon';

function LibraryPageHeaderSearch ({searchQuery,handleSearchChange}) {
  //const [searchValue, setSearchValue] = useState('');

  // const handleInputChange = (event) => {
  //   setSearchValue(event.target.value);
  // };

  return (
    <div className='libraryPage-header-search'>
      <input 
        className='libraryPage-header-search-input' 
        value={searchQuery}
        onChange={handleSearchChange}
        placeholder="Search"
      />
      <div className='libraryPage-header-search-ui'>        
        <Icon name='magnifyingGlass' />        
      </div>
    </div>
  );
}

export default LibraryPageHeaderSearch;
