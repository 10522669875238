import store from '../../store'


export function getAvailableTextStyles() {  
  const state = store.getState()

  let availableTextStyles=['title']

  const brand = state.orgBrand 
  if(brand){
    if(brand.heading1Font){
      availableTextStyles.push('heading1')
    }
    if(brand.heading2Font){
      availableTextStyles.push('heading2')
    }

    if(brand.heading3Font){
      availableTextStyles.push('heading3')
    }

    if(brand.bodyFont){
      availableTextStyles.push('body')
    }
    if(brand.bodyAltFont){
      availableTextStyles.push('bodyAlt')
    }

    if(brand.subFont){
      availableTextStyles.push('sub')
    }
    if(brand.clayFrameTitleFont){
      availableTextStyles.push('clayFrameTitle')
    }
    if(brand.clayFrameTitleBigFont){
      availableTextStyles.push('clayFrameTitleBig')
    }
    if(brand.claySquareNameFont){
      availableTextStyles.push('claySquareName')
    }
    if(brand.claySquareTitleFont){
      availableTextStyles.push('claySquareTitle')
    }

   

  }


  return availableTextStyles 
}
