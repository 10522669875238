import React, { useRef } from 'react';
import Icon from '../../../../misc/Icon';
import * as ContextMenu from '@radix-ui/react-context-menu';

const EditorTimelineSceneHeader = ({ scene, width, left, handleSeek, pixelsPerSec, scrollRef,splitScene }) => {
  const sceneHeaderRef = useRef(null);

  const handleContextMenuSeek = (event) => {
    if (sceneHeaderRef.current) {
      //const scrollAmount = scrollRef.current.scrollLeft;
      const clickX = event.clientX - sceneHeaderRef.current.getBoundingClientRect().left;
      const seekTime = clickX / pixelsPerSec + scene.startTime;
      handleSeek(seekTime);
    }
  };

  const handleClickSeek = (event) => {
    if (sceneHeaderRef.current) {
      const clickX = event.clientX - sceneHeaderRef.current.getBoundingClientRect().left ;
      const seekTime = clickX / pixelsPerSec + scene.startTime;
      handleSeek(seekTime);
    }
  };

  return (
    <ContextMenu.Root key={scene.id} modal={true}>
      <ContextMenu.Trigger asChild onContextMenu={handleContextMenuSeek}>
        <div 
          ref={sceneHeaderRef}
          style={{ width: `${width + 2}px`, left: `${left}px` }} 
          className='editor-timeline-sceneHeader'
          onClick={handleClickSeek} // Add click-to-seek functionality
        >
          <div className='editor-timeline-sceneHeader-label'>
            <div className='editor-timeline-sceneHeader-label-label'>
              {scene.title}
            </div>
            <div className='editor-timeline-sceneHeader-label-bg' />
          </div>
        </div>
      </ContextMenu.Trigger>
      <ContextMenu.Portal>
        <ContextMenu.Content className='contextMenu contextMenu--timeRuler forceDarkTheme'>          
          <ContextMenu.Item onSelect={splitScene}>
            <div className='dropdownMenu-item-iconContainer'>
              <Icon name='splitClipAlt2' />
            </div>
            Split Scene
          </ContextMenu.Item>
          <ContextMenu.Item onSelect={() => console.log('Option 1')}>
            <div className='dropdownMenu-item-iconContainer'>
              <Icon name='aaText' />
            </div>
            Edit...
          </ContextMenu.Item>
          <ContextMenu.Item onSelect={() => console.log('Option 2')}>
            <div className='dropdownMenu-item-iconContainer'>
              <Icon name='folder' />
            </div>
            Save to Library
          </ContextMenu.Item>
          <ContextMenu.Separator />
          <ContextMenu.Item onSelect={() => console.log('Option 3')}>
            <div className='dropdownMenu-item-iconContainer'>
              <Icon name='deleted' />
            </div>
            Delete
          </ContextMenu.Item>
        </ContextMenu.Content>
      </ContextMenu.Portal>
    </ContextMenu.Root>
  );
};

export default EditorTimelineSceneHeader;
