const initialState = {
	isAuthenticated: false,
	willAuthenticate: true,
	token:null
};

export default function (state = initialState, action) {
	switch (action.type) {
		case 'AUTHENTICATION_REQUEST':
			return {
				...state,
				willAuthenticate: true,
			};
		case 'AUTHENTICATION_SUCCESS':
			return {
				...state,
				willAuthenticate: false,
				isAuthenticated: true,
				token:action.token
			};
		case 'AUTHENTICATION_FAILURE':
			//console.log('authentication faliure')
			return {
				...state,
				willAuthenticate: false,
				isAuthenticated: false,
				token:null
			};

		default:
			return state;
	}
}