import React from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';
import Icon from '../misc/Icon'; // Adjust the path as needed

function SmallTabGroup({children, className}) {
  return (
    <div className={'uiSmallTabGroup ' + (className ? ` uiSmallTabGroup--${className} `:'')} >
      {children}
    </div>
  );
}

function SmallTab({active, handleClick, iconName, label, tooltipLabel, longTooltipDelay, disabled, nullBar}) {
  return (    
    <Tooltip.Root delayDuration={longTooltipDelay ? 400 : 0}>
      <Tooltip.Trigger asChild>
        <button 
          data-state={active ? "active" : "inactive"} 
          className={'uiSmallTab ' + (!label ? ' uiSmallTab--iconOnly ' : '') + (label && iconName ? ' uiSmallTab--mixed ' : '')}
          onClick={handleClick}
          disabled={disabled}        
        >
          {iconName && 
            <div className='uiSmallTab-iconContainer'>
              <Icon name={iconName} />
            </div>
          }
          {label && 
            <div className='uiSmallTab-label'>
              {label}
            </div>
          }

          {!nullBar && 
            <div className='uiSmallTab-underline' /> 
          }
          
        </button>  
      </Tooltip.Trigger>   
      {tooltipLabel &&               
        <Tooltip.Content side="top" className="tooltip tooltip--toggleBtn">
          {tooltipLabel}                        
        </Tooltip.Content>                          
      }
    </Tooltip.Root>
  );
}

export { SmallTabGroup, SmallTab };
