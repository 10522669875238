import store from '../../store'


export function getFontForTextStyle(textStyle) {  
  const state = store.getState()
  const brand = state.orgBrand 
  let font 
  if(brand){
    font = brand.font 
    if(textStyle!=='title'){
       font = {...brand[`${textStyle}Font`]} || {...brand.font} //fallback for switching brands
    }
  }

  return font
}