const WebcamRecordingChunkNodeSpec = {
    group: "block",
    attrs: {
        clipId: { default: 'defaultID' },
        sceneId: { default: 'defaultID' },
        // chunkRefreshKey: { default: 0 },
        // requiresUpdate: { default: true },
        // transcriptChunkIndex: { default: 0 },
        // transcriptChunkCount: { default: 1 },
    },
    content: "(webcamTranscriptWord|webcamTranscriptSpace)*",
    toDOM: function(node) {
        return ["webcamRecordingChunk", { 
            "data-clip-id": node.attrs.clipId,
            "data-scene-id": node.attrs.sceneId
        }, 0];
    },
    parseDOM: [{ tag: "webcamRecordingChunk" }]
};

export default WebcamRecordingChunkNodeSpec;