/// zIndex for tracks
// 1 is text slides
// 0 is the main screenrecording track
// -1 is voice clips
//-2 is background music



///Clips have a refrence to scene so can do eg. scene index


//So we have clip start time relative to the scene which is what we save
//when using the clip we want to get the absolute start time in the timeline
//so lets make getters and setters 


class ExportTimelineClip {
	constructor({ id, type, startTime, duration, zIndex,metadata },scene) {
		this.id = id;
		this.type = type; // 'video', 'audio', 'text', etc.
		this._startTime = startTime; //private
		this.duration = duration;
		this.zIndex = zIndex || 0; 
		this.metadata = metadata
		this.scene = scene

	}


	get startTime() {
        return this.scene.startTime + this._startTime;
    }

    set startTime(newStartTime) {
        this._startTime = newStartTime - this.scene.startTime;
    }

	get relativeStartTime() {
        return this._startTime 
    }
    
	get sceneId() {
        return this.scene.id;
    }

    get sceneIndex() {
        return this.scene.sceneIndex;
    }

    get sceneStartTime() {
        return this.scene.startTime;
    }

	get endTime() {
		return this.startTime+this.duration
	}
}



export { ExportTimelineClip };
