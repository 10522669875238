import {randomID} from '../../utils/randomID'


const FRAME_DURATION = 1 / 1500; // Duration of half frame at 60fps
//const FREEZE_DURATION=1 // default to 0.5 seconds
const FREEZE_DURATION=1 // default to 0.5 seconds
//const FREEZE_PLAYBACK_RATE =  FRAME_DURATION/FREEZE_DURATION


export function addFreezeFrame(originalSegments, freezeTime,clipPlaybackRate) {
    //we want the freeze duration to be the FREEZE_DURATION once playback rate is taken into account
    //e.g. for a 1 second freeze at 2x playback the uncalibrated freeze duration should be 2 seconds 
    const freezeDuration = FREEZE_DURATION * clipPlaybackRate 
    const freezePlaybackRate = FRAME_DURATION/freezeDuration


    const freezeSegment = {
        id: randomID(),
        originalStart: freezeTime,
        originalEnd: freezeTime + FRAME_DURATION,
        playbackRate: freezePlaybackRate,
        originalDuration: FRAME_DURATION,
        isQuiet: true,
        isManualFreeze: true,
        timeStretch:1/freezePlaybackRate
    };



    // const freezeSegment = {
    //     id: randomID(),
    //     originalStart: freezeTime,
    //     originalEnd: freezeTime + FRAME_DURATION,
    //     //     originalEnd: freezeTime + FRAME_DURATION,
    //     playbackRate: FREEZE_PLAYBACK_RATE,
    //     originalDuration: FRAME_DURATION,
    //   //  newDuration: FREEZE_DURATION,
    //    // newStart: null, // To be calculated based on previous segments
    //     //newEnd: null, // To be calculated
    //     isQuiet: true,
    //     isManualFreeze: true
    // };

    let adjustedSegments = [];
    let currentTime = 0;

    originalSegments.forEach(segment => {
        let segmentEndTime = segment.originalStart + segment.originalDuration;

        if (segment.originalStart <= freezeTime && segmentEndTime >= freezeTime) {
            // Segment needs to be split
            if (segment.originalStart < freezeTime) {
                // Part of the segment before the freeze
                let firstPart = { ...segment, id: randomID() };
                firstPart.originalEnd = freezeTime;
                firstPart.originalDuration = freezeTime - firstPart.originalStart;
             //   firstPart.newDuration = firstPart.originalDuration * segment.playbackRate;
          //      firstPart.newEnd = currentTime + firstPart.newDuration;
                adjustedSegments.push(firstPart);
                currentTime = currentTime + firstPart.originalDuration
            }

            // Add the freeze frame
           // freezeSegment.newStart = currentTime;
            //freezeSegment.newEnd = currentTime + freezeSegment.newDuration;
            adjustedSegments.push(freezeSegment);
            currentTime = currentTime + freezeSegment.newDuration

            // Part of the segment after the freeze
            if (segmentEndTime > freezeTime + FRAME_DURATION) {
                let secondPart = { ...segment, id: randomID() };
                secondPart.originalStart = freezeTime + FRAME_DURATION;
                secondPart.originalDuration = segmentEndTime - secondPart.originalStart;
               // secondPart.newDuration = secondPart.originalDuration * segment.playbackRate;
               // secondPart.newStart = currentTime;
                //secondPart.newEnd = currentTime + secondPart.newDuration;
                adjustedSegments.push(secondPart);
                currentTime = currentTime + secondPart.originalDuration
            }
        } else {
            // Segment is not affected by the freeze
            let newSegment = { ...segment };
            adjustedSegments.push(newSegment);
            currentTime += segment.originalDuration;
        }
    });

    return adjustedSegments.filter(seg => seg.originalDuration > 0); // Filter out any zero-duration segments
}





// export function addFreezeFrame(originalSegments, freezeTime) {
   

//     const freezeSegment = {
//       id:randomID(),
//       originalStart: freezeTime,
//       originalEnd: freezeTime + FRAME_DURATION,
//       playbackRate: FREEZE_PLAYBACK_RATE,
//       originalDuration: FRAME_DURATION,
//       newDuration: FREEZE_DURATION,
//       isQuiet:true,
//       isManualFreeze:true,
//       newStart:freezeTime,
//       newEnd:freezeTime+FREEZE_DURATION
//     }

  
//     let adjustedSegments = [];
//     let currentTime = 0;

//     for (let segment of originalSegments) {
//         let segmentEndTime = segment.originalStart + segment.originalDuration;

//         if (segment.originalStart < freezeTime && segmentEndTime > freezeTime) {
//             // Split the current segment at freezeTime, if freezeTime falls within the segment
//             let firstPart = {...segment, id: randomID()};
//             firstPart.originalEnd = freezeTime;
//             firstPart.newDuration = (freezeTime - firstPart.originalStart) * segment.playbackRate;

//             // Adjust end time for first part of the split segment
//             firstPart.newEnd = currentTime + firstPart.newDuration;

//             // Second part of the segment after the freeze frame
//             let secondPart = {...segment, id: randomID()};
//             secondPart.originalStart = freezeTime + FRAME_DURATION;
//             secondPart.originalDuration = segmentEndTime - secondPart.originalStart;
//             secondPart.newDuration = secondPart.originalDuration * segment.playbackRate;
//             secondPart.newStart = firstPart.newEnd + FREEZE_DURATION;
//             secondPart.newEnd = secondPart.newStart + secondPart.newDuration;

//             adjustedSegments.push(firstPart, freezeSegment, secondPart);
//         } else {
//             // If the current segment is not affected by the freezeTime, simply adjust its timing
//             let newSegment = {...segment};
//             newSegment.newStart = currentTime;
//             newSegment.newEnd = currentTime + newSegment.newDuration;
//             adjustedSegments.push(newSegment);
//         }

//         // Update currentTime to the end of the current segment, adjusted for playback rate
//         currentTime += segment.newDuration;
//     }

//     return adjustedSegments.filter(seg => seg.originalDuration > 0); // Filter out any zero-duration segments



// }
