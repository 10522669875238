import ReactDOM from 'react-dom'
import React from 'react'
import SlideTextElementGhost from '../../three/slide/slideEditor/SlideTextElementGhost';
import SlideEditorTextInput from '../../three/slide/slideEditor/SlideEditorTextInput'
import {calculateElementPositions} from '../../timeline/slide/slideUtils/calculateElementPositions'
import getSlideConfigs from '../../timeline/slide/slideUtils/getSlideConfigs'

const slideConfigs = getSlideConfigs()
const {slideHPadding,slideVPadding,slideWidth,slideHeight,slideAspect}=slideConfigs


const SCENE_WIDTH = 1920
const SCENE_HEIGHT = 1080


function calculateLettersArrayAndPositions(slide,variableTextColorId) {
  return new Promise((resolve) => {    
    const ghostContainer = document.createElement('div');
    ghostContainer.className = 'editor-slideEditor-ghostContainer';
    ghostContainer.style.width = `${SCENE_WIDTH}px`;
    ghostContainer.style.height = `${SCENE_HEIGHT}px`;
    ghostContainer.style.position = 'absolute';
    document.body.appendChild(ghostContainer);

    const textElements = slide.elements.filter(element => element.type === 'text');
    
    ReactDOM.render(
      <React.Fragment>
        {textElements.map(element => (
          <SlideTextElementGhost
            key={`ghost_${element.id}`}
            elementId={element.id}
            element={element}
            slideElement={element}
            clipId={slide.id}
            width={element.width}
          />
        ))}
      </React.Fragment>,
      ghostContainer,
      () => {
         setTimeout(() => {
        textElements.forEach(element => {
          const ghostElement = document.getElementById(`ghost_${element.id}`);          
          if (ghostElement) {
            element.height = ghostElement.clientHeight;            
          }
        });

        const elementPositions = calculateElementPositions(
          slide.layout,
          slideWidth,
          slideHeight
        )

        const textAreaContainer = document.createElement('div');
        textAreaContainer.style.position = 'absolute';
        textAreaContainer.style.visibility = 'hidden';
        textAreaContainer.style.width = '1920px';
        textAreaContainer.style.height = '1080px';
        document.body.appendChild(textAreaContainer);

        let completedElements = 0;

        textElements.forEach(element => {
          const initialValue=element.metadata.docJson
          const hAlign = element.metadata.textHAlign
          const vAlign = element.metadata.textVAlign

          const containerDiv = document.createElement('div');
          containerDiv.style.top = `${element.y}px`;
          containerDiv.style.left = `${element.x}px`;
          containerDiv.style.width = `${element.width}px`;
          containerDiv.style.height = `${element.height}px`;
          containerDiv.className = 'editor-slideEditor-item';
          containerDiv.style.position = 'absolute'
          textAreaContainer.appendChild(containerDiv);



          ReactDOM.render(
            <SlideEditorTextInput
              key={`slide_text_area_${element.id}`}
              elementId={element.id}
              element={element}
              initialValue={initialValue}
              slideElement={element}
              clipId={slide.id}
              isExport={true}
              variableTextColorId={variableTextColorId}
              handleSetLettersArray={(lettersArray, text, docJson) => {
                element.metadata.lettersArray = lettersArray;
                completedElements++;
                if (completedElements === textElements.length) {
                  // Remove React components without unmounting
                  while (ghostContainer.firstChild) {
                    ghostContainer.removeChild(ghostContainer.firstChild);
                  }
                  while (textAreaContainer.firstChild) {
                    textAreaContainer.removeChild(textAreaContainer.firstChild);
                  }
                  // Remove containers from the DOM
                  document.body.removeChild(ghostContainer);
                  document.body.removeChild(textAreaContainer);
                  resolve(slide);
                }
              }}
              canvasX={0}
              canvasY={0}
              canvasWidth={SCENE_WIDTH}
              canvasHeight={SCENE_HEIGHT}
              invertScalar={1}
              hAlign={hAlign}
              vAlign={vAlign}
              isGhostCalc={true}
            />
            ,
            containerDiv
          );
        });
           }, 200);
      }
    );
  });
}



export { calculateLettersArrayAndPositions }



