import React, { useEffect, useState } from 'react';
import { Text } from '@react-three/drei';
import { SpringAnimator } from '../../../three/utils/animations/SpringAnimator';
import renderOrders from '../../../three/renderOrders'

const FPS = 60; // Frames per second
const calculateAnimationDuration = 10;

const CHNumberValue = ({ maxOpacity, fontWeight, yPos, fontSize, time, targetValue, prefix, suffix, animationType, isHidden, fontFamily, color }) => {
  
  const springParams = { mass: 1, stiffness: 200, damping: 70 };
  const [labelOpacityFrames, setLabelOpacityFrames] = useState([]);
  const [valueScaleFrames, setValueScaleFrames] = useState([]); // State to store scale frames

  const initialScale = 0.8

  useEffect(() => {
    const labelOpacitySpring = new SpringAnimator(springParams.mass, springParams.stiffness, springParams.damping, 0);    
    const valueScaleSpring = new SpringAnimator(springParams.mass, springParams.stiffness, springParams.damping, initialScale); // Starting scale

    const opacityFrames = [];
    const scaleFrames = []; // Array to hold scale frames
    for (let frame = 0; frame < FPS * calculateAnimationDuration; frame++) {
      labelOpacitySpring.setTarget(1); // Target opacity
      labelOpacitySpring.simulate(1000 / FPS);
      opacityFrames.push(labelOpacitySpring.position);

      valueScaleSpring.setTarget(1); // Target scale
      valueScaleSpring.simulate(1000 / FPS);
      scaleFrames.push(valueScaleSpring.position);
    }

    setLabelOpacityFrames(opacityFrames);
    setValueScaleFrames(scaleFrames); // Set scale frames
  }, [targetValue]);

  const frameIndex = Math.floor(time * FPS);
  const animatedLabelOpacity = labelOpacityFrames[frameIndex] || 0;
  const animatedScale = valueScaleFrames[frameIndex] || 0.8; // Use scale frame based on time

  const formattedValue = targetValue;

  let valueScale = animatedScale
  let labelOpacity = animatedLabelOpacity * maxOpacity;

  if(animationType === 'none'){
    labelOpacity = maxOpacity
    valueScale = 1
  }

  if(animationType === 'fadeIn'){    
    valueScale = 1
  }

  return (
    <> 
      <group scale={[valueScale, valueScale, valueScale]}>    
        <Text
          anchorX="center"
          anchorY="middle"
          lineHeight={1.2}
          fontSize={fontSize}
          textAlign="center"
          font={`../fonts/${fontFamily}/${fontFamily}-${fontWeight}.woff`}
          position={[0, yPos, 0]}
          maxWidth={1200}
          letterSpacing={-0.02}        
          renderOrder={renderOrders.graphObject}  
        >
          <meshBasicMaterial transparent={true} color={color} opacity={labelOpacity} />
          {prefix}{formattedValue}{suffix}
        </Text>
      </group>
    </>
  );
};

export default CHNumberValue;
