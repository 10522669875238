import store from '../store'


export function getOrgName() {	
	const state = store.getState()
	let name
	const organization=state.organization
	if(organization){
		name=organization.name
	}
	return name 
}
