import React, { useMemo, useState, useCallback } from 'react';
import {formatDuration} from '../../../../utils/dateFormatters/formatDuration'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import Icon from '../../../misc/Icon'

const TranscriptPanelSceneHeaderUI = ({yPos, sceneTitle, sceneDuration,sceneId,mergeScene,isFirstHeader,isOnlyHeader}) => {

  const preventMerge = isFirstHeader 


  const notFirstOrOnly = !isFirstHeader && !isOnlyHeader
  return (
  	<>    
      <div         
        className='editor-transcriptPanel-sceneHeaderGutter-sceneHeader'   
        style={{top: `${yPos}px`}}
      >
       <div className='editor-transcriptPanel-sceneHeaderGutter-sceneHeader-title'>
        {sceneTitle}
       </div>       
        <div className='editor-transcriptPanel-sceneHeaderGutter-sceneHeader-duration'>
          {formatDuration(sceneDuration)}
        </div>       
       <div className='editor-transcriptPanel-sceneHeaderGutter-sceneHeader-hSpacer' />
              
       <DropdownMenu.Root 
          //key={sceneId}
          modal={true}          
        > 
          <DropdownMenu.Trigger asChild>
            <button className='editor-transcriptPanel-sceneHeaderGutter-sceneHeader-dropdownBtn'>
              <Icon name='ellipses'/>
            </button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Portal>
            <DropdownMenu.Content             
              align="end"
              alignOffset={-10}
              className='dropdownMenu dropdownMenu--sceneHeader forceDarkTheme'
              // onEscapeKeyDown={()=>{this.props.toggleDropdown()}}
              // onPointerDownOutside={()=>{this.props.toggleDropdown()}}
              // onFocusOutside={()=>{this.props.toggleDropdown()}}
              // onInteractOutside={()=>{this.props.toggleDropdown()}}
            >             
              {/*}
              <DropdownMenu.Item>
                <div className='dropdownMenu-item-iconContainer'>
                  <Icon name='folder' />
                </div>
                Save to Library              
              </DropdownMenu.Item>
              <DropdownMenu.Separator/>

              <DropdownMenu.Item>   
                <div className='dropdownMenu-item-iconContainer'>
                  <Icon name='plusMedium' />
                </div>          
                Add Scene above           
              </DropdownMenu.Item>
              */}
              
              {notFirstOrOnly &&
                <DropdownMenu.Item onSelect={()=>{mergeScene(sceneId,"before")}}>      
                  <div className='dropdownMenu-item-iconContainer'>
                    <Icon name='mergeSceneAboveMedium' />
                  </div>       
                  Merge into Scene above  
                </DropdownMenu.Item>   
              }                        

              {isFirstHeader && !isOnlyHeader &&
                <DropdownMenu.Item className='dropdownMenu-item--disabled'>
                  <div className='dropdownMenu-item-iconContainer'>
                    <Icon name='mergeSceneAboveMedium' />
                  </div>       
                  Merge into Scene above  
                </DropdownMenu.Item>   
              }                        

              {isFirstHeader && isOnlyHeader &&
                <DropdownMenu.Item onSelect={()=>{mergeScene(sceneId,"before")}}>
                  <div className='dropdownMenu-item-iconContainer'>
                    <Icon name='mergeSceneAboveMedium' />
                  </div>       
                  Remove Scene
                </DropdownMenu.Item>   
              }                        


              
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>

        

      </div>
      
      
    </>
  );
};

export default TranscriptPanelSceneHeaderUI;