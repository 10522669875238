import { Plugin, PluginKey } from 'prosemirror-state';
import { DecorationSet, Decoration } from 'prosemirror-view';

const skipDecorationPluginKey = new PluginKey('skipDecoration');

function findSkipBoundaries(doc) {
    const boundaries = [];
    
    doc.descendants((node, pos) => {
        if (node.type.name === 'transcriptGroup' && 
            node.attrs.groupType === 'webcam-recording' && 
            node.attrs.skippedWords?.length > 0) {
            
            // Sort skipped words by original index
            const skippedWords = [...node.attrs.skippedWords].sort((a, b) => 
                a.originalWordIndex - b.originalWordIndex
            );
            
            let currentRange = null;
            skippedWords.forEach(word => {
                if (!currentRange) {
                    currentRange = {
                        groupPos: pos,
                        words: [word],
                        startIndex: word.originalWordIndex,
                        endIndex: word.originalWordIndex
                    };
                } else {
                    // Check if this word is consecutive by index
                    if (word.originalWordIndex === currentRange.endIndex + 1) {
                        currentRange.words.push(word);
                        currentRange.endIndex = word.originalWordIndex;
                    } else {
                        boundaries.push(currentRange);
                        currentRange = {
                            groupPos: pos,
                            words: [word],
                            startIndex: word.originalWordIndex,
                            endIndex: word.originalWordIndex
                        };
                    }
                }
            });

            if (currentRange) {
                boundaries.push(currentRange);
            }

            // For each range, find the appropriate position for the indicator
            boundaries.forEach(range => {
                let indicatorPos = null;
                let foundPosition = false;

                // Look through the group's content to find word positions
                node.descendants((childNode, childPos) => {
                    if (!foundPosition && childNode.type.name === 'webcamTranscriptWord') {
                        const wordIndex = childNode.attrs.originalWordIndex;
                        
                        // If we find a word just before our range
                        if (wordIndex === range.startIndex - 1) {
                            indicatorPos = pos + childPos + childNode.nodeSize;
                            foundPosition = true;
                        }
                        // If we find a word just after our range (and haven't found a position yet)
                        else if (!indicatorPos && wordIndex === range.endIndex + 1) {
                            indicatorPos = pos + childPos;
                            foundPosition = true;
                        }
                    }
                });

                // If we found a position, add it to the range info
                if (indicatorPos) {
                    range.indicatorPos = indicatorPos;
                }
            });
        }
    });

    console.log('boundaries')
    console.log(boundaries)
    return boundaries;
}

function createSkipIndicator() {
    const span = document.createElement('span');
    Object.assign(span.style, {
        display: 'inline',
        position: 'relative',
        borderRight: '3px solid #4d90fe',
        margin: '0 2px',
        padding: 0,
        width: 0,
        height: '1em',
        verticalAlign: 'text-bottom',
    });
    return span;
}


const skipDecorationPlugin = new Plugin({
    key: skipDecorationPluginKey,
    state: {
        init() {
            return DecorationSet.empty;
        },
        apply(tr, old) {
        const boundaries = findSkipBoundaries(tr.doc);
        const decorations = [];

        boundaries.forEach(range => {
            if (range.indicatorPos) {
                decorations.push(
                    Decoration.widget(
                        range.indicatorPos,
                        createSkipIndicator,
                        { side: 0 }
                    )
                );
            }
        });

        return DecorationSet.create(tr.doc, decorations);
    },
    },
    props: {
        decorations(state) {
            return this.getState(state);
        }
    }
});

export default skipDecorationPlugin;