export const identifySlowLegacyCursorPeriods = async (normalizedCursorData,minCursorTime) => {
  try {
    //const maxDistance = 20; // pixels
    const maxDistance = 7; // pixels
    const minTime = minCursorTime || 3; // seconds

    // console.log('MIN CURSOR TIME')
    // console.log(minTime)

    let slowPeriods = [];
    let currentStartIndex = null;

    for (let i = 0; i < normalizedCursorData.length; i++) {
      const startPoint = normalizedCursorData[i];
      let isWithinRadius = true;

      // Find the index where time exceeds startPoint.time + minTime
      const endIndex = normalizedCursorData.findIndex(point => point.time > startPoint.time + minTime);

      if (endIndex !== -1) {
        for (let j = i + 1; j < endIndex; j++) {
          const currentPoint = normalizedCursorData[j];
          const distance = Math.sqrt(Math.pow(currentPoint.x - startPoint.x, 2) + Math.pow(currentPoint.y - startPoint.y, 2));

          if (distance > maxDistance) {
            isWithinRadius = false;
            break;
          }
        }

        if (isWithinRadius) {
          // Check if this period is contiguous with the previous one
          if (currentStartIndex !== null && normalizedCursorData[currentStartIndex].time >= startPoint.time - minTime) {
            // Extend the previous period
            slowPeriods[slowPeriods.length - 1].end = normalizedCursorData[endIndex].time;
          } else {
            // Start a new slow period
            slowPeriods.push({ start: startPoint.time, end: normalizedCursorData[endIndex].time });
            currentStartIndex = i;
          }

          // Skip to the end of this slow period
          i = endIndex - 1;
        }
      }
    }

    //merge back to back slow periods
    let mergedSlowPeriods = [];
      slowPeriods.forEach(period => {
        if (mergedSlowPeriods.length === 0) {
          mergedSlowPeriods.push(period);
        } else {
          let lastPeriod = mergedSlowPeriods[mergedSlowPeriods.length - 1];
          if (lastPeriod.end >= period.start) {
          // Extend the previous period if there is overlap or continuity
          lastPeriod.end = Math.max(lastPeriod.end, period.end);
        } else {
          mergedSlowPeriods.push(period);
         }
      }
    });

    // console.log('slow cursor periods')
    // console.log(slowPeriods)
    // console.log(mergedSlowPeriods)
    return mergedSlowPeriods;
  } catch (error) {
    console.error('Failed to identify slow cursor periods:', error);
    return []; // Return an empty array in case of error
  }
};
