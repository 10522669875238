import React, { useMemo } from 'react';
import { Text } from '@react-three/drei';
import * as THREE from 'three';
import createRoundedRectShape from '../utils/createRoundedRectShape';

const CaptionsWordText = ({ word, localTime, fontSize, padding, isVisible }) => {
  let wordOpacity = 1;
  if (localTime >= word.startTime && localTime < word.endTime) {
    wordOpacity = 1;
  }        
  if (localTime >= word.endTime) {
    wordOpacity = 1;
  }

  return (
    <Text
      anchorX="left"
      anchorY="top"
      fontSize={fontSize}
      font='../fonts/YInter/YInter-Semibold.woff'
      letterSpacing={-0.01}
      position={[word.normalLeft - (word.width / 2) + padding, word.normalTop + (word.height / 2), 0]}
      renderOrder={111}
      visible={isVisible}
    >
      <meshBasicMaterial transparent={true} opacity={wordOpacity} color='white' />
      {word.text}
    </Text>
  );
};
export default CaptionsWordText;