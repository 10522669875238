const FADE_DURATION = 1

export function fadeVolumeTowardsEnd(currentTime, duration, initialVolume) {
    // Calculate when the fade-out should start based on the total duration and fadeDuration
    const fadeOutStartTime = duration - FADE_DURATION;
    // Check if the current time is within the fade-out period
    if (currentTime >= fadeOutStartTime) {
        // Calculate the proportion of the fade-out completed
        const fadeOutProgress = (currentTime - fadeOutStartTime) / FADE_DURATION;
        // Calculate the new volume, linearly reducing it to 0
        const newVolume = initialVolume * (1 - fadeOutProgress);
        // Make sure the volume doesn't go below 0
        return Math.max(newVolume, 0);
    }
    // If it's not yet time to fade out, return the initial volume
    return initialVolume;
}