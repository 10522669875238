import {SlideElement} from './SlideElement'

class ImageElement extends SlideElement {



  constructor(options,slideClip) {
    super(options,slideClip)

    this.isUploadingImage = false
    this.metadata.imgSrc = this.convertToHttps(this.metadata.imgSrc)
  }

  convertToHttps(url) {
    if (typeof url === 'string' && url.startsWith('http:')) {
      return url.replace(/^http:/, 'https:')
    }
    return url
  }

  toJSON() {
    const elementJSON =  {
      id: this.id,
      type:this.type,
      width:this.width,
      height:this.height,
      x:this.x,
      y:this.y,
      metadata:this.metadata,
      zIndex:this.zIndex,
      bottomMargin:this.bottomMargin,
      rightMargin:this.rightMargin
    };
    return elementJSON
  }

  
}


export { ImageElement }
