import ReactDOM from 'react-dom'
import React from 'react'
import SlideTextElementGhost from '../../../three/slide/slideEditor/SlideTextElementGhost';


const SCENE_WIDTH = 1920
const SCENE_HEIGHT = 1080



function measureNewTextElementHeight(element) {
  return new Promise((resolve) => {    
    const ghostContainer = document.createElement('div');
    ghostContainer.className = 'editor-slideEditor-ghostContainer';
    ghostContainer.style.width = `${SCENE_WIDTH}px`;
    ghostContainer.style.height = `${SCENE_HEIGHT}px`;
    ghostContainer.style.position = 'absolute';
    document.body.appendChild(ghostContainer);

    ReactDOM.render(
      <React.Fragment>
          <SlideTextElementGhost
            key={`ghost_${element.id}_new_element`}
            elementId={element.id}
            element={element}
            slideElement={element}
            width={element.width}
            fontSizeMultiplier={1}
          />
      </React.Fragment>,
      ghostContainer,
      () => {
        setTimeout(() => {
          const ghostElement = document.getElementById(`ghost_${element.id}_dynamic_1`);
          if (ghostElement) {
            const ghostHeight = ghostElement.clientHeight;  
            element.height = ghostHeight
          }
          ReactDOM.unmountComponentAtNode(ghostContainer);
          document.body.removeChild(ghostContainer);
          resolve(element);
         
           }, 5);
      }
    );
  });
}



export { measureNewTextElementHeight }



