import React, { useRef, useEffect, useState, useMemo, useContext } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import { Plane } from '@react-three/drei';
import * as THREE from 'three';
import ImageMaterial from '../imageClip/ImageMaterial'
import createRoundedRectShape from '../utils/createRoundedRectShape';
import MobileVideoMaterial from './MobileVideoMaterial'
import { createDropShadow } from './MobileVideoDropShadow'; // Import the drop shadow function
import defaultVideoAnimationValues from '../utils/animations/defaultVideoAnimationValues'
import resizeMobileDeviceToFit from '../utils/resizeMobileDeviceToFit'
import getMobileDeviceModelLayout from '../utils/getMobileDeviceModelLayout'
import getMobileDeviceModelImageSrc from '../utils/getMobileDeviceModelImageSrc'
import MobileVideoDeviceImageMaterial from './MobileVideoDeviceImageMaterial'
import ExportMobileVideoMaterial from './ExportMobileVideoMaterial'
import renderOrders from '../renderOrders'

const WINDOW_PADDING = 88 // how much window padding to apply around device

const MobileVideoDevice = ({clip, time, localTime, visible, initialProperties, animationStates, animationParams, startTransitionType, endTransitionType,showMobileVideoStatic, FPS, isPlaying, isLightBG, mobileDeviceModel, updateMeshWidth, updateMeshHeight}) => {

  const mobileDeviceLayout = useMemo(() => {
    return getMobileDeviceModelLayout(mobileDeviceModel)
  }, [mobileDeviceModel]);

 const mobileDeviceDeviceImageSrc = useMemo(() => {
    return getMobileDeviceModelImageSrc(mobileDeviceModel)
  }, [mobileDeviceModel]);

  //
  // DEVICE MESH DIMENSIONS AND SCALE
  const { deviceMeshWidth, deviceMeshHeight} = useMemo(() => {

    const data = resizeMobileDeviceToFit(
      mobileDeviceLayout.deviceWidth,
      mobileDeviceLayout.deviceHeight,
      WINDOW_PADDING
    )
    if(updateMeshHeight){
      updateMeshHeight(clip.id,data.deviceMeshHeight)
    }
    if(updateMeshWidth){
      updateMeshWidth(clip.id,data.deviceMeshWidth)
    }
    return data
  }, [mobileDeviceModel, mobileDeviceLayout]);




  const meshScale = useMemo(() => {
    return mobileDeviceLayout.deviceWidth > 0 ? deviceMeshWidth / mobileDeviceLayout.deviceWidth : 1;
  }, [deviceMeshWidth, mobileDeviceModel, mobileDeviceLayout]);

  const screenMeshRadius = meshScale * mobileDeviceLayout.screenRadius // exaggerated for iphone
  const screenMeshWidth = meshScale * mobileDeviceLayout.screenWidth
  const screenMeshHeight = meshScale * mobileDeviceLayout.screenHeight
  // const screenMeshXOffset = meshScale * mobileDeviceLayout.screenXOffset
  // const screenMeshYOffset = meshScale * mobileDeviceLayout.screenYOffset

  const screenMeshXOffset = meshScale * mobileDeviceLayout.screenXOffset
  const screenMeshYOffset = meshScale * mobileDeviceLayout.screenYOffset



  const screenRef = useRef();
  const screenBGRef = useRef();
  const deviceRef = useRef();
  const shadowRef = useRef();


  const deviceMeshRadius = 0

  //
  // SCREEN GEOMETRY  
  const screenGeometry = useMemo(() => {
    const shape = createRoundedRectShape(screenMeshWidth, screenMeshHeight, screenMeshRadius);
    const geometry = new THREE.ShapeGeometry(shape);        
    // Compute UV mapping for the geometry using the provided logic that works
    const uvs = new Float32Array(geometry.attributes.position.count * 2);
    for (let i = 0; i < geometry.attributes.position.count; i++) {
      const vertex = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, i);
      // Map x, y vertex position to uv coordinates
      uvs[i * 2] = (vertex.x + screenMeshWidth / 2) / screenMeshWidth;
      uvs[i * 2 + 1] = (vertex.y + screenMeshHeight / 2) / screenMeshHeight;
    }
    geometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));
    return geometry;
  }, [screenMeshWidth, screenMeshHeight, screenMeshRadius]);

  //
  // DEVICE GEOMETRY  
  const deviceGeometry = useMemo(() => {
    const shape = createRoundedRectShape(deviceMeshWidth, deviceMeshHeight, deviceMeshRadius);
    const geometry = new THREE.ShapeGeometry(shape);        
    // Compute UV mapping for the geometry using the provided logic that works
    const uvs = new Float32Array(geometry.attributes.position.count * 2);
    for (let i = 0; i < geometry.attributes.position.count; i++) {
      const vertex = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, i);
      // Map x, y vertex position to uv coordinates
      uvs[i * 2] = (vertex.x + deviceMeshWidth / 2) / deviceMeshWidth;
      uvs[i * 2 + 1] = (vertex.y + deviceMeshHeight / 2) / deviceMeshHeight;
    }
    geometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));
    return geometry;
  }, [deviceMeshWidth, deviceMeshHeight, screenMeshRadius]);



  // //
  // // SHADOW SETUP
  // const shadowXOffset = 0;
  // const shadowYOffset = -8;
  // const shadowBlur = 20;
  // const shadowSpread = -15;
  // // const shadowOpacity = 0.68;
  // let shadowOpacity = 0.84;
  


  // const shadowMesh = useMemo(() => {
  //   const shadowOptions = {
  //     shadowXOffset, // X offset from Leva
  //     shadowYOffset, // Y offset from Leva
  //     shadowBlur,    // shadowBlur from Leva
  //     shadowSpread,  // shadowSpread (scale) from Leva
  //     shadowColor: 'rgba(0,0,0,1)',
  //     shadowOpacity, // Opacity from Leva
  //   };
  //   return createDropShadow(screenMeshWidth, screenMeshHeight, shadowOptions);
  // }, [screenMeshWidth, screenMeshHeight, shadowXOffset, shadowYOffset, shadowBlur, shadowSpread, shadowOpacity]);

  
  //
  // APPLY ANIMATIONS
    useEffect(() => {
      
      const frameIndex = Math.floor(localTime * FPS);
      if (screenRef.current && deviceRef.current && screenBGRef.current) {      
        Object.keys(animationStates).forEach(property => {
          let state = animationStates[property][frameIndex]; 
          if(showMobileVideoStatic){
            state=defaultVideoAnimationValues[property]
          }       
          if (state !== undefined) {
            if (property === 'positionX') {
              screenRef.current.position.x = state + screenMeshXOffset;
              screenBGRef.current.position.x = state + screenMeshXOffset;
              deviceRef.current.position.x = state;
            } else if (property === 'positionY') {
              screenRef.current.position.y = state + screenMeshYOffset;
              screenBGRef.current.position.y = state + screenMeshYOffset;
              deviceRef.current.position.y = state;
            } else if (property === 'positionZ') {              
              //screenRef.current.position.z = defaultScreenDeviceFrameAnimationValues['positionZ']; 
              screenRef.current.position.z = state;
              screenBGRef.current.position.z = state;
              deviceRef.current.position.z = state;
            } else if (property === 'scale' && screenRef.current.scale) {
              screenRef.current.scale.set(state, state, state);
              screenBGRef.current.scale.set(state, state, state);
              deviceRef.current.scale.set(state, state, state);
            } else if (property.startsWith('rotation')) {
              const axis = property.slice('rotation'.length).toLowerCase();
              screenRef.current.rotation[axis] = state;
              screenBGRef.current.rotation[axis] = state;
              deviceRef.current.rotation[axis] = state;
            } else if (property === 'opacity' && screenRef.current.material && screenBGRef.current.material) {              
              // some tricky fixes for opacity so frame shows appropriately
              screenRef.current.material.opacity = state * state * state;
              screenBGRef.current.material.opacity = state * state;
              screenBGRef.current.material.transparent = true; 
              deviceRef.current.material.opacity = state;
            }
          }
        });
      }    
    }, [localTime,startTransitionType,endTransitionType,animationStates,showMobileVideoStatic]);


    // // SHADOW ANIMATIONS
    // useEffect(() => {
      
    //   const frameIndex = Math.floor(localTime * FPS);

    //   if (shadowRef.current) {
    //     // Initialize default values
    //     let animatedOpacity = 0, animatedScale = 1, animatedPosX = 0, animatedPosY = 0;

    //     // Check if the animation states for specific properties exist and assign values
    //     if (animationStates['opacity'] && animationStates['opacity'][frameIndex] !== undefined) {
    //       animatedOpacity = animationStates['opacity'][frameIndex];
    //     }
    //     if (animationStates['scale'] && animationStates['scale'][frameIndex] !== undefined) {
    //       animatedScale = animationStates['scale'][frameIndex];
    //     }
    //     if (animationStates['positionX'] && animationStates['positionX'][frameIndex] !== undefined) {
    //       animatedPosX = animationStates['positionX'][frameIndex];
    //     }
    //     if (animationStates['positionY'] && animationStates['positionY'][frameIndex] !== undefined) {
    //       animatedPosY = animationStates['positionY'][frameIndex];
    //     }

    //     // Apply animated values to the shadow mesh
    //     const scaleWithShadowSpread = animatedScale * (1 + shadowSpread * 2 / screenMeshWidth);
    //     shadowRef.current.scale.set(scaleWithShadowSpread, scaleWithShadowSpread, scaleWithShadowSpread);

    //     // Apply animated position combined with static offsets to the shadow mesh
    //     shadowRef.current.position.x = animatedPosX + shadowXOffset;
    //     shadowRef.current.position.y = animatedPosY + shadowYOffset;
        
    //     // Set the shadow's opacity based on animated opacity and Leva's opacity control
    //     shadowRef.current.material.opacity = shadowOpacity * (animatedOpacity * animatedOpacity * animatedOpacity);

    //     if(showMobileVideoStatic){
    //       shadowRef.current.position.x = shadowXOffset
    //       shadowRef.current.position.y = shadowYOffset
    //       shadowRef.current.position.z = 0.1
    //       shadowRef.current.material.opacity = shadowOpacity          
    //     }
    //   }
    // }, [localTime, FPS, shadowXOffset, shadowYOffset, shadowSpread, shadowOpacity, screenMeshWidth, startTransitionType,endTransitionType,JSON.stringify(animationParams), showMobileVideoStatic]);

  
          

  return (
    <>      

      
        <>
          
          {/*}
          <primitive 
            object={shadowMesh}
            ref={shadowRef} 
            visible={visible}       
          /> 
          */}


          <mesh        
            ref={screenBGRef}
            position={[initialProperties.positionX + screenMeshXOffset, initialProperties.positionY + screenMeshXOffset, initialProperties.positionZ]}
            scale={[initialProperties.scale, initialProperties.scale, initialProperties.scale]}
            geometry={screenGeometry}
            visible={visible}
            renderOrder={renderOrders.mobileVideoScreenBG}
          >            
            <meshBasicMaterial attach="material" transparent={true} color="rgb(0,0,0)" opacity={0.5} />
          </mesh>
              
          <mesh        
            ref={screenRef}
            position={[initialProperties.positionX + screenMeshXOffset, initialProperties.positionY + screenMeshXOffset, initialProperties.positionZ]}
            scale={[initialProperties.scale, initialProperties.scale, initialProperties.scale]}
            geometry={screenGeometry}
            visible={visible}
            renderOrder={renderOrders.mobileVideo}
          >


            {!clip.isExport &&
              <MobileVideoMaterial 
                videoElement={clip.video}
              opacity={1} // update to animated
              />              
            }

           {clip.isExport &&
            <ExportMobileVideoMaterial 
              codecVideoClip={clip}
              opacity={1} // update to animated
            />              
          }
         
          </mesh>

          <mesh        
            ref={deviceRef}
            position={[initialProperties.positionX, initialProperties.positionY, initialProperties.positionZ]}
            scale={[initialProperties.scale, initialProperties.scale, initialProperties.scale]}
            geometry={deviceGeometry}
            visible={visible}
            renderOrder={renderOrders.mobileVideoFrame}
          >


            <MobileVideoDeviceImageMaterial 
              imgSrc={mobileDeviceDeviceImageSrc}              
              opacity={1} // update to animated
            />
         
          </mesh>



        </>
      

    </>
  );
};

export default MobileVideoDevice;


