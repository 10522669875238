import {TimelineClip} from './TimelineClip'
import {fetchLegacyVideoURL} from '../utils/recordings/legacyScreenRecordings/getLegacyMasterRecordingData'
import {fetchBasicVideo} from '../utils/basicVideos/fetchBasicVideo'
import {fetchDeviceVideoUrl} from '../utils/recordings/deviceRecordings/fetchDeviceVideoUrl'
import {calculateLegacyRecordingSilentAndActiveSegments} from '../utils/recordings/legacyScreenRecordings/calculateLegacyRecordingSilentAndActiveSegments'
import {calculateRecordingSilentAndActiveSegments} from '../utils/recordings/screenRecordings/calculateRecordingSilentAndActiveSegments'
import {calculateTrimmedSegments} from './utils/calculateTrimmedSegments'
import {addFreezeFrame} from './utils/addFreezeFrame'
import {addSkipSegment} from './utils/addSkipSegment'
import {removeQuietOrSkipSegment} from './utils/removeQuietOrSkipSegment'
import {updateSkipSegmentDuration} from './utils/updateSkipSegmentDuration'
import {calulateVideoTimeFromTimelineTime} from './utils/calulateVideoTimeFromTimelineTime'
import find from 'lodash/find'
import {fetchScreenRecordingURL} from '../utils/recordings/screenRecordings/getMasterRecordingData'
import {checkVideoUploadStatus} from './utils/checkVideoUploadStatus'

//basic video is an upload (not a screen recording)
//screenVideoApp the app that was recorded
//deviceFrame = color
//April 11th add is deviceRecording true/false
//isDeviceRecording

//recordingSegments are the untrimmed segments
//segments are the trimmed segments with clipplaybackrate applied


//clipPlaybackRate is for the whole clip and applied to all segments
//this.isScreenRecording is true for the new recordkit recordings


//Duration is the duration on the timeline
//recording duration is the duration of the underlying video

class VideoClip extends TimelineClip {

	constructor(options,scene,handleVideoClipLoaded,updateVideoClipSegments) {
    super(options,scene)
		this.video = document.createElement('video'); 
    this.video.muted=false
    this.isBasicVideo = options.isBasicVideo
    this.isDeviceRecording = options.isDeviceRecording || false
    this.isUploadingVideo=options.isUploadingVideo || false
    this.videoId=options.videoId
    this.segments=options.segments || []
    this.recordingSegments = options.recordingSegments||[]
    this.hasSegments = this.segments.length
    this.hasRecordingSegments = this.recordingSegments.length
    this.isScreenRecording = options.isScreenRecording

    //New May 11th 
    this.clipPlaybackRate = options.clipPlaybackRate || 1
    
    

     if(this.videoId=='23891685'){
      this.isUploadingVideo=false
      this.metadata.originalHeight = 2160
      this.metadata.originalWidth=3456
      this.metadata.displayWidth=1440
      this.metadata.trimStart = 0 
      this.metadata.trimEnd=14.333333
     // this.metadata.semiTransparent = false

    }
    if(this.videoId=='1633266791'){
      this.isUploadingVideo=false
      this.metadata.originalHeight = 2160
      this.metadata.originalWidth=3456
      this.metadata.displayWidth=1440
      this.metadata.trimStart = 0 
      this.metadata.trimEnd=4.766667
    }


    if(this.metadata.label=='Google Chrome'){
      this.metadata.screenVideoApp='chrome'
    }else{
      this.metadata.screenVideoApp=''
    }





    if(!this.isUploadingVideo){
      if(!this.isBasicVideo){
        this.captureId=options.captureId
        this.initialize(options.captureId)
      }else{
        if(this.videoId){
          this.initializeBasicVideo()
        } 
      }
    }
    if(this.isDeviceRecording && !this.metadata.horizontalOffset){
      this.metadata.horizontalOffset=0
    }
    this.video.addEventListener('ended', this.onEnded.bind(this));
    this.ended = false;
    
    this._handleVideoClipLoaded=handleVideoClipLoaded
    this.updateVideoClipSegments=updateVideoClipSegments
    if(this.metadata.isMuted){
      this.video.muted = true
    }

    this.pollInterval = null 
    this.pollAttempts = 0 
    this.maxPollAttempts = 30
    if (this.isUploadingVideo) {
      this.startPollingUploadStatus()
    }
  }

  //// Sometimes basic videos can get stuck in uploading state
  //e.g if you upload a video and navigate away so lets poll for status and update if it has finished uploading
  async startPollingUploadStatus() {
    if (this.pollInterval) {
      clearInterval(this.pollInterval)
    }
    this.pollInterval = setInterval(async () => {
      try {
        // Stop polling if we're no longer uploading or hit max attempts
        if (!this.isUploadingVideo || this.pollAttempts >= this.maxPollAttempts) {
          this.stopPolling()
          return
       }
      const response = await checkVideoUploadStatus(this.videoId)
      if (response.status === 'complete') {
        await this.handleUploadCompleteFromPolling(response)
      } else if (response.status === 'failed') {
        console.log('upload failed')
      }
      this.pollAttempts++
     } catch (error) {
      console.error('Error polling upload status:', error)
    }
    }, 10000)
  }

  handleUploadCompleteFromPolling(videoObj){ //TODO make sure timeline saves after do this
    this.metadata.originalWidth = videoObj.original_width 
    this.metadata.originalHeight = videoObj.original_height
    this.metadata.displayWidth=videoObj.default_display_width
    this.metadata.semiTransparent = videoObj.semi_transparent
    this.metadata.originalFileName = videoObj.original_filename 
    this.finishUpload()
  }


  stopPolling() {
    if (this.pollInterval) {
      clearInterval(this.pollInterval)
      this.pollInterval = null
    }
  }

  updateIsMuted(value){
    this.video.muted=value
  }

 
  onEnded() {
    this.ended = true; // Set ended to true when video ends
  }

  async finishUpload(){
    await this.initializeBasicVideo()
  }

  async initializeBasicVideo() {
    const videoUrl = await fetchBasicVideo(`${this.videoId}.mp4`);
    this.videoUrl = videoUrl;
    this.video.src = videoUrl;
    this.video.preload = 'auto';
    this.video.playbackRate=this.clipPlaybackRate
    this.video.addEventListener('loadedmetadata', this.onMetadataLoaded.bind(this)) 
  }

  async initialize(captureId) {
    let videoUrl 
    if(this.isScreenRecording){
      videoUrl = await fetchScreenRecordingURL(captureId);
    }else if(this.isDeviceRecording){
      videoUrl = await fetchDeviceVideoUrl(captureId)
    }
    else{
      videoUrl = await fetchLegacyVideoURL(captureId);
    }
    this.videoUrl = videoUrl;
    this.video.src = videoUrl;
    this.video.preload = 'auto';
    this.video.playbackRate=this.clipPlaybackRate
    this.video.addEventListener('loadedmetadata', this.onMetadataLoaded.bind(this)) 
  }

  async calculateRecordingSegments(captureId){
    if(this.isBasicVideo){
      const segment={
        id: 0,
        originalDuration: this.recordingDuration,
        originalStart: 0,
        originalEnd:this.recordingDuration ,
        isQuiet: false,
        playbackRate:1,
        newStart:0,
        newEnd: this.recordingDuration,
        newDuration: this.recordingDuration,
        timeStretch:1
      }
      const segments = [segment]
      this.recordingSegments=segments
      this.calculateTrimmedSegments()
    }else{
      let segments 
      if(this.isScreenRecording){
        segments = await calculateRecordingSilentAndActiveSegments(captureId,this.recordingDuration)
      }else{
        segments = await calculateLegacyRecordingSilentAndActiveSegments(captureId,this.recordingDuration,this.isDeviceRecording)
      }
      
      this.recordingSegments=segments
      this.calculateTrimmedSegments()
      this._handleVideoClipLoaded()
    }
  }

  updateClipPlaybackRate(playbackRate){
    this.clipPlaybackRate = playbackRate 
    this.video.playbackRate=this.clipPlaybackRate
    this.calculateTrimmedSegments()
  }

  updateSkipSegmentDuration(segmentId,newDuration,direction){
    const adjustedSegments =updateSkipSegmentDuration(this.recordingSegments,segmentId,newDuration,this.recordingDuration,direction)
    this.recordingSegments=adjustedSegments
    this.calculateTrimmedSegments()
  }


  calculateTrimmedSegments(){ //apply trim and clip playback rate
    const isInitialLoad = this.segments.length==0
    this.segments = calculateTrimmedSegments(this.recordingSegments,this.metadata.trimStart,this.metadata.trimEnd,this.clipPlaybackRate)
    this.calculateDurationFromSegments()
    if(isInitialLoad){
     // this.updateVideoClipSegments(this.id,this.recordingSegments,this.segments,this.duration)
       this.updateVideoClipSegments(this.id)
    }
    this._handleVideoClipLoaded()
  }

  updateTrimValues(trimStartTime,trimEndTime){
    this.metadata.trimStart=trimStartTime
    this.video.currentTime=trimStartTime
    this.metadata.trimEnd=trimEndTime
    this.calculateTrimmedSegments()
  }

  addSkipSegment(skipTime){
    const adjustedSegments =addSkipSegment(this.recordingSegments,skipTime,this.clipPlaybackRate)
    this.recordingSegments=adjustedSegments
    this.calculateTrimmedSegments()

  }

  addFreezeFrame(freezeTime){
    const adjustedSegments =addFreezeFrame(this.recordingSegments,freezeTime,this.clipPlaybackRate)
    this.recordingSegments=adjustedSegments
    this.calculateTrimmedSegments()
  }

  removeFreeze(segmentId){
    const adjustedSegments = removeQuietOrSkipSegment(this.recordingSegments,segmentId,this.recordingDuration)
    this.recordingSegments=adjustedSegments
    this.calculateTrimmedSegments()
  }

  removeSkip(segmentId){
    const adjustedSegments = removeQuietOrSkipSegment(this.recordingSegments,segmentId,this.recordingDuration)
    this.recordingSegments=adjustedSegments
    this.calculateTrimmedSegments()
  }

  toggleSkipSegment(segmentId,isExpanded){
    const segment = this.recordingSegments.find(segment => segment.id === segmentId);
    if(segment){
      segment.isExpanded = isExpanded 
      segment.newDuration = isExpanded?segment.originalDuration : 0
    }
    this.calculateTrimmedSegments()
  }

  expandSkipSegment(segmentId){
    const segment = this.recordingSegments.find(segment => segment.id === segmentId);
    if(segment){
      segment.isExpanded = false 
      segment.originalDuration = 0
      segment.newDuration = 0
    }
    this.calculateTrimmedSegments()
  }

  updateSegmentPlaybackRate(segmentId,playbackRate){
    const segment = this.recordingSegments.find(segment => segment.id === segmentId);
    const adjustedPlaybackRate = playbackRate/this.clipPlaybackRate
    if (segment) {
      segment.playbackRate = adjustedPlaybackRate
      segment.timeStretch=1/adjustedPlaybackRate
      segment.newDuration = segment.originalDuration / adjustedPlaybackRate;
    } else {
      console.warn(`Segment with ID ${segmentId} not found.`);
    }
    this.calculateTrimmedSegments()
  }
  
  calculateDurationFromSegments() {
    let currentTime = this.metadata.trimStart;
    let totalDuration = 0;
    this.segments.forEach(segment => {
      totalDuration += segment.newDuration;
    });
    this.duration = totalDuration;
  }

  onMetadataLoaded() {
    this.isUploadingVideo=false 
    this.recordingDuration = this.video.duration   
    if(!this.metadata.trimEnd){
      this.metadata.trimStart=0
      this.metadata.trimEnd=this.video.duration
    }
    this.video.currentTime = this.metadata.trimStart
    if(!this.hasSegments || !this.hasRecordingSegments){
      this.calculateRecordingSegments(this.captureId)
    }else{//temp for dev recalc every time
      /////////temp so recalcs
     // this.calculateRecordingSegments(this.captureId)
      ////////////



      // console.log('just want to calculate trimmed segment here - split clip')
      //this.calculateTrimmedSegments()
       // this._handleVideoClipLoaded()
      this._handleVideoClipLoaded()
    }
  }

  playFromCurrentTime(currentTime) { //updated to take into account playback rates
   if(this.video && this.video.src){
    if (this.ended) {
      return
    }
    if (currentTime >= this.startTime && currentTime < this.endTime) {
      const videoTime = calulateVideoTimeFromTimelineTime(currentTime, this)
      this.video.currentTime = videoTime;
      this.video.play();
    }
    }
  }

  pause() {
    if(!this.isUploadingVideo){ //stop error when pausing project while uploading
      this.video.pause();
    }
  }

  seek(currentTime) {
    this.ended = false
    if(this.video && this.video.src){
      if (currentTime >= this.startTime && currentTime < this.endTime) {
        const videoTime = calulateVideoTimeFromTimelineTime(currentTime, this)
    //    console.log(`video time is ${videoTime}`)
        this.video.currentTime = videoTime
     } else {
        this.video.pause(); // If the current time is outside the clip, pause it
      }
    }
  }

  ///colors and stuff
  setBackgroundColor(backgroundColorId){
    let backgroundColor
    if(!backgroundColorId){ //remove background
      backgroundColor = this.projectBackground
      this.metadata.isAutoBackgroundColor=true
    }else{
      backgroundColor = find(this.brandKit.backgrounds,{id:backgroundColorId})
      this.metadata.isAutoBackgroundColor=false
    }
    this.metadata.backgroundColor=backgroundColor
  }

  toJSON() {
   const json = {
      id: this.id,
      type:this.type,
      captureId:this.captureId,
      isDeviceRecording:this.isDeviceRecording,
      isScreenRecording:this.isScreenRecording,
      isUploadingVideo:this.isUploadingVideo,
      videoId:this.videoId,
      fileName:this.fileName,
      isBasicVideo:this.isBasicVideo,
      startTime:this._startTime,
      absoluteStartTime:this.startTime,
     // startTime:this.startTime,
      duration:this.duration,
      name:this.name,
      metadata:this.metadata,
      zIndex:this.zIndex,
      recordingSegments:this.recordingSegments,
      segments:this.segments,
      clipPlaybackRate:this.clipPlaybackRate
    };
    return json
  }

  destroy() {
    this.stopPolling()
    if (!this.video.paused) {
      this.video.pause();
    }
    this.video.removeEventListener('playing', this.onPlaying);
    this.video.removeEventListener('ended', this.onEnded);
    this.video.removeEventListener('loadedmetadata', this.onMetadataLoaded);
    this.video.src = '';
    this.video.load(); // Forces the release of the video buffer
    this.video = null;
    this.ended = false;
    this.captureId = null;
  }

}

export { VideoClip }