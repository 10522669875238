import {post,put,postCloudinary,fetch} from '../api'
import {downloadMissingVideos} from '../utils/assets/downloadMissingVideos'
import { Mixpanel } from '../Mixpanel'
import * as Sentry from '@sentry/electron/renderer'
import axios from 'axios'

function calculateDisplayWidth(originalWidth, originalHeight) {
  const maxWidth = 1600;
  const maxHeight = 900;
  let displayWidth = originalWidth;
  let displayHeight = originalHeight;
  if (originalWidth <= maxWidth && originalHeight <= maxHeight) { //Dont scale up
    return  displayWidth
  }
  const originalAspectRatio = originalWidth / originalHeight;
  const targetAspectRatio = maxWidth / maxHeight;
  if (originalAspectRatio > targetAspectRatio) {
    displayWidth = maxWidth;
    displayHeight = maxWidth / originalAspectRatio;
  } else {
    displayHeight = maxHeight;
    displayWidth = maxHeight * originalAspectRatio;
  }
  return Math.round(displayWidth)
}


const CHUNK_SIZE = 10 * 1024 * 1024; 

export const uploadVideoAndHandleResponse = (file, fileId) => async (dispatch) => {
  try {
    const uniqueUploadId = `${fileId}-${Date.now()}`;
    const signatureResponse = await post('/video/upload', { public_id: fileId,chunk_size:CHUNK_SIZE,uniqueUploadId:uniqueUploadId});
    const { apiKey, timestamp, signature, eager } = signatureResponse;
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
    const uploadChunks = async () => {
      let uploadResponse;
      for (let i = 0; i < totalChunks; i++) {
        uploadResponse = await uploadChunk(
          file, fileId,i, CHUNK_SIZE, file.size, uniqueUploadId, 
          apiKey, timestamp, signature, eager, 
          i === totalChunks - 1
        );
      }
      return uploadResponse;
    };
    
    const uploadResponse = await uploadChunks();
    const {width,height,original_filename,public_id,asset_id,url,format,version} = uploadResponse;
    const display_width = calculateDisplayWidth(uploadResponse.width, uploadResponse.height);
   
    const reqBody = {
      original_filename: uploadResponse.original_filename, 
      public_id: uploadResponse.public_id, 
      format: uploadResponse.format, 
      original_height: uploadResponse.height, 
      original_width: uploadResponse.width,
      asset_id: uploadResponse.asset_id,
      version: uploadResponse.version,
      display_width: display_width,
      duration: uploadResponse.duration
    };

    const updateVideoObjResponse = await put(`/video/${fileId}`, reqBody);

    // Download from cloudinary to then upload to s3
    const deliveryUrl = updateVideoObjResponse.delivery_url;
   
    const cloudinaryResponse = await axios.get(deliveryUrl, {
      responseType: 'blob'
    });
   
    const videoBlob = cloudinaryResponse.data;

    const contentType = format == 'webm' ? 'video/webm' : 'video/mp4';
    const s3Format = format == 'webm' ? 'webm' : 'mp4';

    // Get S3 upload URL
    const s3UploadUrlResponse = await post('/video/get-s3-upload-url', { id: fileId, format: s3Format});
   
    const { uploadURL, key } = s3UploadUrlResponse;
    // Upload to S3
    const s3UploadResponse = await axios.put(uploadURL, videoBlob, {
      headers: {
        'Content-Type': contentType
      }
    });

    if (s3UploadResponse.status !== 200) {
      throw new Error('Failed to upload to S3');
    }
    const s3StatusUpdateResponse = await put(`/video/${fileId}/update-s3-status`, { status: 'complete' });

    //TODO can do this before we upload to s3 maybe
    await ipcRenderer.invoke('download-basic-video',fileId,updateVideoObjResponse.delivery_url); 
    Mixpanel.track('upload_video',{delivery_url:updateVideoObjResponse.delivery_url});
    dispatch(fetchUploadedVideos());
    return updateVideoObjResponse;
  } catch (error) {
    console.error('Error during video upload process:', error);
    error.name = 'Video upload error';
    Sentry.captureException(error);
  }
};


const uploadChunk = async (file, fileId, index, chunkSize, totalFileSize, uniqueUploadId, apiKey, timestamp, signature, eager, isLastChunk) => {
  const start = index * chunkSize;
  const end = Math.min(start + chunkSize, totalFileSize);
  const blob = file.slice(start, end);
  const contentRange = `bytes ${start}-${end - 1}/${totalFileSize}`;
  
  const headers = {
    "X-Unique-Upload-Id": timestamp,
    "Content-Range": contentRange,
  };

  const form = new FormData();
    form.append("upload_large", true);
    form.append('file', blob);
    form.append("upload_preset",'basic_video')
    form.append('api_key', apiKey);
    form.append('timestamp', timestamp);
    form.append('signature', signature);
    form.append('public_id', fileId);
    
    console.log('Uploading chunk:', { index, start, end, contentRange });

  try {
    delete axios.defaults.headers.common['Cache-Control'];
    delete axios.defaults.headers.common['Pragma'];
    delete axios.defaults.headers.common['Expires'];

    const response = await axios.post(
      `https://api.cloudinary.com/v1_1/yarn/upload`,
      form,
      { headers }
    );
    console.log('Chunk upload response:', response.data);

    axios.defaults.headers.common['Cache-Control']='no-cache'
    axios.defaults.headers.common['Pragma']='no-cache'
    axios.defaults.headers.common['Expires']='0'
    return response.data;
  } catch (error) {
    console.error('Error uploading chunk:', error.response ? error.response.data : error.message);
    throw error;
  }
};


export function fetchUploadedVideos(){
  return (dispatch) => fetch(`/videos`)
  .then((response) => {
    dispatch({ type: 'FETCH_VIDEOS_SUCCESS', response })
    downloadMissingVideos(response)
    return response
  })
  .catch((error) => {
    console.log(error)
    return error
  })
}

export function updateVideoInsertCount(videoId) {
  return (dispatch) => put(`/video/${videoId}/update-insert-count`)
    .then((response) => {
       Mixpanel.track('insert_video_from_recent');
    return response
    })
    .catch((error) => {
      console.log(error)
      return error
    })
}



export function updateVideoDefaultDisplayWidth(videoId,width) {
  const reqBody={
    width:Math.round(width)
  }

  return (dispatch) => put(`/video/${videoId}/update-default-width`,reqBody)
    .then((response) => {
    console.log(response)
    dispatch(fetchUploadedVideos())
    return response
    })
    .catch((error) => {
      console.log(error)
      return error
    })
}


