import {setFilesToBeDownloaded} from '../../actions/downloads'
import store from '../../store'

//const forceRedownloadRecordings=["1622414889","60327862","1622414889","345043052"]

const forceRedownloadRecordings=[]


// const forceRedownloadRecordings = [
//   "11615450",
//   "77564711",
//   "551561084",
//   "651631318",
//   "983466984",
//   "1045097060",
//   "1160119123",
//   "1431218389",
//   "1570516735",
//   "1635726998",
//   "1866514364",
//   "2076402635"
// ];

export async function downloadMissingRecordings(recordings) {
	const recordingsList = await ipcRenderer.invoke('get-recordings-list'); 
	for (const recording of recordings) {
		const captureId=recording.capture_id
		if (!recordingsList.includes(captureId)  || forceRedownloadRecordings.includes(captureId)) {
			const isDevice = recording.is_device
			console.log(`Downloading missing recording: ${captureId}`);
//			console.log(`is device recording? ${isDevice}`)
			ipcRenderer.invoke('download-recording-files',captureId,isDevice); 
			store.dispatch(setFilesToBeDownloaded(captureId,isDevice))
		}else{
		//	console.log('we already have the recording ')
		}
	}
}




// export async function downloadMissingRecordings(recordings) {
//     const recordingsList = await ipcRenderer.invoke('get-recordings-list');
//     for (const recording of recordings) {
//         const captureId = recording.capture_id;
//         const recordingPath = path.join(os.homedir(), 'yarn-cache', 'recordings', captureId);

//         let shouldDownload = false;

//         if (recordingsList.includes(captureId)) {
//             // Check if all required files are present
//             try {
//                 const filesInDir = await fs.readdir(recordingPath);
//                 shouldDownload = requiredFiles.some(file => !filesInDir.includes(file.name));
//             } catch (err) {
//                 console.error(`Error reading directory for ${captureId}:`, err);
//                 shouldDownload = true; // Assume download needed if directory read fails
//             }
//         } else {
//             shouldDownload = true;
//         }

//         if (shouldDownload) {
//             console.log(`Downloading missing recording: ${captureId}`);
//             ipcRenderer.invoke('download-recording-files', captureId);
//             store.dispatch(setFilesToBeDownloaded(captureId));
//         }
//     }
// }