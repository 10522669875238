export function calculateTimelineTimeFromVideoTime(videoCurrentTime, clip) {
    
    const trimStart = clip.metadata.trimStart;
    const clipPlaybackRate = clip.clipPlaybackRate
    const timeElapsedSinceVideoStart = videoCurrentTime - trimStart;
    let elapsedTimelineTime = 0;
    let elapsedVideoTime = 0;

    for (const segment of clip.segments) {
        // Calculate the adjusted segment duration for the timeline

        let adjustedSegmentDuration
        if(segment.isSkipSegment){
            if(segment.isExpanded){
            adjustedSegmentDuration=segment.originalDuration / clipPlaybackRate
            }else{
                adjustedSegmentDuration = 0
            }

        }else{
            adjustedSegmentDuration = segment.originalDuration / (segment.playbackRate * clipPlaybackRate) 
        }


       
     //   console.log(`adjustedSegmentDuration---- ${adjustedSegmentDuration}`)

        // Check if the current video time is within this segment
        if (timeElapsedSinceVideoStart <= elapsedVideoTime + segment.originalDuration) {
            // Calculate the additional time in the current segment
            const additionalTime = timeElapsedSinceVideoStart - elapsedVideoTime;

            // Adjust elapsedTimelineTime for the current segment
            if(segment.isSkipSegment){
                if(segment.isExpanded){
                     elapsedTimelineTime += additionalTime / (1 * clipPlaybackRate)
                }else{
                    elapsedTimelineTime+=0
                }
            }else{
                 elapsedTimelineTime += additionalTime / (segment.playbackRate * clipPlaybackRate)
            }


           
            break;
        } else {
            // For completed segments, add the adjusted duration to elapsedTimelineTime
            // and the original duration to elapsedVideoTime
            elapsedTimelineTime += adjustedSegmentDuration;
            elapsedVideoTime += segment.originalDuration;
        }
    }

    // Calculate the timeline time by adding the start time of the clip
    const timelineTime = elapsedTimelineTime + clip.startTime;
  // console.log(`timelineTime ----- ${timelineTime}`)
    return timelineTime;
}




//Prod
// export function calculateTimelineTimeFromVideoTime(videoCurrentTime, clip) {
    
//     const trimStart = clip.metadata.trimStart;
//     const clipPlaybackRate = clip.clipPlaybackRate
//     const timeElapsedSinceVideoStart = videoCurrentTime - trimStart;
//     let elapsedTimelineTime = 0;
//     let elapsedVideoTime = 0;

//     for (const segment of clip.segments) {
//         // Calculate the adjusted segment duration for the timeline
//         const adjustedSegmentDuration = segment.originalDuration / (segment.playbackRate * clipPlaybackRate)


//         // Check if the current video time is within this segment
//         if (timeElapsedSinceVideoStart <= elapsedVideoTime + segment.originalDuration) {
//             // Calculate the additional time in the current segment
//             const additionalTime = timeElapsedSinceVideoStart - elapsedVideoTime;

//             // Adjust elapsedTimelineTime for the current segment
//             elapsedTimelineTime += additionalTime / (segment.playbackRate * clipPlaybackRate)
//             break;
//         } else {
//             // For completed segments, add the adjusted duration to elapsedTimelineTime
//             // and the original duration to elapsedVideoTime
//             elapsedTimelineTime += adjustedSegmentDuration;
//             elapsedVideoTime += segment.originalDuration;
//         }
//     }

//     // Calculate the timeline time by adding the start time of the clip
//     const timelineTime = elapsedTimelineTime + clip.startTime;
//   // console.log(`timelineTime ----- ${timelineTime}`)
//     return timelineTime;
// }


