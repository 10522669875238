export const getNormalizedLegacyCursorData = async (
  masterRecordingCursorData,
  masterRecordingVideoProcessStartTimeMs,
  masterRecordingVideoProcessEndTimeMs,
  masterRecordingWidth,
  masterRecordingHeight,
  masterRecordingXOffset,
  masterRecordingYOffset
) => {
  try {
    const filteredCursorData = masterRecordingCursorData.filter(cursorPoint => 
      cursorPoint.time >= masterRecordingVideoProcessStartTimeMs && 
      cursorPoint.time <= masterRecordingVideoProcessEndTimeMs
    );


    
    // Adjust the time property of each object to start from zero and shift the origin
    const normalizedCursorData = filteredCursorData.map(cursorPoint => ({
      x: (cursorPoint.x - masterRecordingXOffset) - (masterRecordingWidth / 2),
      y: (cursorPoint.y - masterRecordingYOffset - (masterRecordingHeight / 2)) * -1, // Inverting y if needed
      time: (cursorPoint.time - masterRecordingVideoProcessStartTimeMs) / 1000,
      type:cursorPoint.type
    }));

    return normalizedCursorData;

  } catch (error) {
    console.error('Failed to get calculate normalizedCursor Data:', error);
    return []; // Return an empty array in case of error
  }
};
