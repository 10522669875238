// renderOrders.js

const renderOrders = {
  webcamVideo: 66,
  webcamVideoShadow: 65,
  webcamVideoFrame: 64,
  webcamVideoFrameShadow: 63,











  slideObject: 2,
  graphObject: 2,
  donutRingPart: 2,
  webcamVideoFullScreen: 1,
  slideBG: 0,
  graphBG: 0,
  legacyImageClip: -1,  
  screenVideoCursor: -1,  
  screenVideoOverlay: -2,  
  screenVideo: -3,  
  basicVideo: -3,  
  mobileVideo: 5,
  deviceShadow: -4,
  mobileVideoScreenBG: 3,    
  screenVideoFrame: -5,    
  mobileVideoFrame: 3,    
  screenVideoFrameShadow: -6,  
  videoBG: -7,
  projectBG: -8,
};

export default renderOrders;