//clipPlaybackRate is for the whole clip and is applied to all the segments

//TODO not sure we need segment.duration and segment.newDuration

export function calculateTrimmedSegments(originalSegments, trimStart, trimEnd,clipPlaybackRate) {
   
  let trimmedSegments = [];
  let currentTime = trimStart;
 	let totalDuration = 0;

  originalSegments.forEach(segment => {
  	const trimmedStart = Math.max(segment.originalStart, trimStart);
    const trimmedEnd = Math.min(segment.originalEnd, trimEnd);
  
  	if(trimmedStart<trimmedEnd){ //segment is inside the trim  		
  
  		let newSegment={...segment}
  		newSegment.originalStart=trimmedStart
      newSegment.originalEnd=trimmedEnd
  
      const originalDuration = trimmedEnd-trimmedStart
      newSegment.originalDuration=originalDuration
  
      let segmentDuration
      if(segment.isSkipSegment){
        if(segment.isExpanded){
          segmentDuration=originalDuration/clipPlaybackRate
        }else{
          segmentDuration=0
        }
      }
      else{
        segmentDuration =(originalDuration/segment.playbackRate)/clipPlaybackRate
      }
      ////new April 15
      newSegment.newDuration = segmentDuration
      /////
      newSegment.duration= segmentDuration
      newSegment.newStart = currentTime;
    	newSegment.newEnd = currentTime + segmentDuration;
    	currentTime = newSegment.newEnd;
    	totalDuration += segmentDuration
    	trimmedSegments.push(newSegment)
  	}
  })

    return trimmedSegments;
}


