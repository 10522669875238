export const getFaceBoxForWebcamCaptureId = async (
  captureId
) => {
  if (window.isElectron) {
    const relativePath = `webcamRecordings/${captureId}/face_box.json`;
    try {
      const content = await ipcRenderer.invoke("read-file", relativePath);
      const data = JSON.parse(content);
      return data;
    } catch (error) {
      console.error("Error reading or parsing face box:", error);
      return null;
    }
  }
  return null;
};
