import { SpringAnimator } from '../../../three/utils/animations/SpringAnimator';

const animationParams = {
  mass: 1,
  stiffness: 170,
  damping: 26,
};

export const getSmoothedCursorData = async (
  normalizedCursorData,
  masterRecordingVideoDuration
) => {
  try {
    const FPS = 60;
    const totalFrames = masterRecordingVideoDuration * FPS;
    const smoothedCursorData = [];
    // Create a new array with every third row from normalizedCursorData
    const filteredCursorData = normalizedCursorData.filter((_, index) => index % 3 === 0);
    // Initialize with first data point
    let currentCursorType = filteredCursorData[0].cursorType;
    
    let springX = new SpringAnimator(
      animationParams.mass,
      animationParams.stiffness,
      animationParams.damping,
      filteredCursorData[0].x
    );
    
    let springY = new SpringAnimator(
      animationParams.mass,
      animationParams.stiffness,
      animationParams.damping,
      filteredCursorData[0].y
    );
    
    let nextDataPointIndex = 0;
    let lastKnownDataPoint = filteredCursorData[0];

    for (let frame = 0; frame < totalFrames; frame++) {
      const currentTime = frame / FPS;
      
      // Update position targets and cursor type when reaching new data point
      while (nextDataPointIndex < filteredCursorData.length && 
             filteredCursorData[nextDataPointIndex].time <= currentTime) {
        lastKnownDataPoint = filteredCursorData[nextDataPointIndex];
        springX.setTarget(lastKnownDataPoint.x);
        springY.setTarget(lastKnownDataPoint.y);
        currentCursorType = lastKnownDataPoint.cursorType;
        nextDataPointIndex++;
      }
      
      // Simulate spring physics
      springX.simulate(1000 / FPS);
      springY.simulate(1000 / FPS);
      
      // Store smoothed data point
      smoothedCursorData.push({
        x: springX.getPosition(),
        y: springY.getPosition(),
        time: currentTime,
        cursorType: currentCursorType
      });
    }
    
    return smoothedCursorData;
  } catch (error) {
    console.error('Failed to calculate smoothedCursor Data:', error);
    return [];
  }
};