import React, { useState, useRef, useEffect } from 'react';
import EditorDetailPanelChartSpreadsheetCell from './EditorDetailPanelChartSpreadsheetCell';
import EditorDetailPanelChartSpreadsheetInput from './EditorDetailPanelChartSpreadsheetInput';
import EditorDetailPanelChartSpreadsheetRowBtns from './EditorDetailPanelChartSpreadsheetRowBtns';
import { useSelectionContainer, boxesIntersect } from "@air/react-drag-to-select";

const cellWidth = 106;
const cellHeight = 24;

const getMaxRow = (data) => {
  for (let i = data.length - 1; i >= 0; i--) {
    if (data[i].some(cell => cell.value !== "")) {
      return i + 1; // Adding 1 because row indices are 0-based
    }
  }
  return 0; // Return 0 if all rows are empty
};

const Spreadsheet = ({data, updateData,barRowHighlightStates,setBarRowHighlightStates,barColorType,availableChartColors,barCategoryColorIds,setBarCategoryColorIds, uniformColorId, projectBackgroundId, slideBackgroundId, barOpacity}) => {  
  const [activeCell, setActiveCell] = useState({ row: 0, col: 0 });
  const [isEditing, setIsEditing] = useState(false);
  const [subselectionHead, setSubselectionHead] = useState(null);
  const [subselectedCells, setSubselectedCells] = useState([]);
  const [spreadsheetFocused, setSpreadsheetFocused] = useState(false);  // New state to track focus
  const spreadsheetRef = useRef(null);
  const selectableItems = useRef([]);  // To store the position of each cell

  const { DragSelection } = useSelectionContainer({
    eventsElement: spreadsheetRef.current,
      onSelectionChange: (box) => {
        const selectedIndexes = [];
        selectableItems.current.forEach((item, index) => {
          const cellRow = Math.floor(index / 2); // Adjust yourColumnCount accordingly
          const cellCol = index % 2; // Adjust yourColumnCount accordingly
          // console.log("Cell Index:", index, "Row:", cellRow, "Col:", cellCol);
          if (boxesIntersect(box, { ...item, top: item.top + window.scrollY, left: item.left + window.scrollX })) {
            selectedIndexes.push({ row: cellRow, col: cellCol });
          }
        });

        setSubselectedCells(selectedIndexes);
      },
      selectionProps: {
        style: {
          border: 'none', // Custom border style
          borderRadius: 0,            // Custom border radius
          backgroundColor: 'none',   // Custom background color
          opacity: 0,               // Custom opacity
        },
      },
      onSelectionStart: (startPoint) => {
        const startX = startPoint.x + window.scrollX;
        const startY = startPoint.y + window.scrollY;
        const firstCell = selectableItems.current.find(item => {
          const itemBox = { 
            top: item.top, 
            left: item.left, 
            width: item.width, 
            height: item.height 
          };
          return boxesIntersect({ top: startY, left: startX, width: 0, height: 0 }, itemBox);
        });
        if (firstCell) {
          const firstCellRow = Math.floor(firstCell.index / 2);
          const firstCellCol = firstCell.index % 2;
          setActiveCell({ row: firstCellRow, col: firstCellCol });
        }
      },
  });

  useEffect(() => {
    if (spreadsheetRef.current) {
      selectableItems.current = [];
      const cellElements = Array.from(spreadsheetRef.current.children);
      cellElements.forEach((cell, index) => {
        const { left, top, width, height } = cell.getBoundingClientRect();
        selectableItems.current.push({ left, top, width, height, index });
      });
    }
  }, [data]);


  useEffect(() => {
    const handleKeyPress = (e) => {
      if (!spreadsheetFocused) return; // Add this check to ensure spreadsheet is focused
       if (isEditing && ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(e.key)) {
        return; // Skip arrow key handling in editing mode
      }
      if (e.key === 'Enter') {
        if (isEditing) {
          handleInputEnterPress(); // Move to the next cell
          setIsEditing(false); // Exit editing mode
          focusSpreadsheet()
        } else {
          setIsEditing(true); // Enter editing mode
        }
        e.preventDefault();
        return;
      }
      if (!isEditing){
        if (e.key === 'a' && (e.metaKey || e.ctrlKey)) {
          e.preventDefault();  // Prevent the default action of Ctrl+A or Command+A
          const allCells = [];
          for (let r = 0; r < data.length; r++) {
            for (let c = 0; c < data[0].length; c++) {
              allCells.push({ row: r, col: c });
            }
          }
          setSubselectedCells(allCells);
          return;  // Return early to ensure no further processing
        }
      }

      if (e.shiftKey && !isEditing) {
        let { row, col } = subselectionHead || activeCell;

          // Check for Shift + Command/Control + Arrow Up/Down
          if (e.metaKey || e.ctrlKey) {
            switch (e.key) {
              case 'ArrowUp':
                row = 0; // Set to the first row
                break;
              case 'ArrowDown':
                row = data.length - 1; // Set to the last row
                break;
              case 'ArrowLeft':
                col = 0; // Set to the first column
                break;
              case 'ArrowRight':
                col = data[0].length - 1; // Set to the last column
                break;
              default:
                return; // Ignore other keys
            }
          } else {
            // Existing switch case for normal arrow key movement with Shift
            switch (e.key) {
              case 'ArrowLeft':
                col = Math.max(col - 1, 0);
                break;
              case 'ArrowRight':
                col = Math.min(col + 1, data[0].length - 1);
                break;
              case 'ArrowUp':
                row = Math.max(row - 1, 0);
                break;
              case 'ArrowDown':
                row = Math.min(row + 1, data.length - 1);
                break;
              default:
                return;
            }
          }

          setSubselectionHead({ row, col });        
      } else if (!e.shiftKey && !isEditing) {
        // Clear the subselection head when moving with arrow keys without Shift
        setSubselectionHead(null);

        let { row, col } = activeCell;

        switch (e.key) {
          case 'ArrowLeft':
            col = Math.max(col - 1, 0);
            break;
          case 'ArrowRight':
            col = Math.min(col + 1, data[0].length - 1);
            break;
          case 'ArrowUp':
            row = Math.max(row - 1, 0);
            break;
          case 'ArrowDown':
            row = Math.min(row + 1, data.length - 1);
            break;
          default:
            return; // Ignore other keys
        }

        setActiveCell({ row, col });
      }
      
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [activeCell, isEditing, data, subselectionHead, spreadsheetFocused]);


  const handleInputEnterPress = () => {
    let newRow = activeCell.row;
    let newCol = activeCell.col;

    if (newRow < data.length - 1) {
      newRow++; // Move to the next row
    } else {
      newRow = 0; // Start from the first row
      if (newCol < data[0].length - 1) {
        newCol++; // Move to the next column
      }
    }
    setActiveCell({ row: newRow, col: newCol });
  };

  const handleFocus = () => {
    setSpreadsheetFocused(true);
  };

  const handleBlur = () => {
    setSpreadsheetFocused(false);
    setSubselectedCells([]);  // Clear subselected cells
    setSubselectionHead(null);  // Reset the subselection head
  };

  useEffect(() => {
    const handleBackspace = (e) => {
      if (!spreadsheetFocused) return; // Add this check to ensure spreadsheet is focused
      if (e.key === 'Backspace' && !isEditing) {
        e.preventDefault(); // Prevent the default backspace behavior
        const newData = [...data];        
        // Clear active cell
        newData[activeCell.row][activeCell.col].value = '';
        // Clear subselected cells
        subselectedCells.forEach(cell => {
          newData[cell.row][cell.col].value = '';
        });

        updateData(newData);
      }
    };
    window.addEventListener('keydown', handleBackspace);
    return () => {
      window.removeEventListener('keydown', handleBackspace);
    };
  }, [isEditing, activeCell, subselectedCells, data]);


  // Set subselection
  useEffect(() => {
    if (subselectionHead) {
      const newSubselectedCells = [];
      const startRow = Math.min(activeCell.row, subselectionHead.row);
      const endRow = Math.max(activeCell.row, subselectionHead.row);
      const startCol = Math.min(activeCell.col, subselectionHead.col);
      const endCol = Math.max(activeCell.col, subselectionHead.col);

      for (let row = startRow; row <= endRow; row++) {
        for (let col = startCol; col <= endCol; col++) {
          newSubselectedCells.push({ row, col });
        }
      }

      setSubselectedCells(newSubselectedCells);
    } else {
      // Clear subselectedCells if there is no subselectionHead
      setSubselectedCells([]);
    }
  }, [subselectionHead, activeCell]);


  const handleTyping = (e) => {
    if (!spreadsheetFocused) return; // Add this check to ensure spreadsheet is focused

    // Check if there is an active cell, we are not editing, and no command/control keys are held down
    if (!isEditing && activeCell && !e.metaKey && !e.ctrlKey) {
      e.preventDefault(); // Prevent default typing behavior

      const updatedData = [...data];

      const validCharactersRegex = /^[a-zA-Z0-9!@#$%^&*()_+?><:{}[\]="~\-';,/\\.\s]$/;

      // If the key is a spacebar, clear the cell data and focus the cell
      if (e.key === ' ') {
        updatedData[activeCell.row][activeCell.col].value = '';
      }

      // For other alphanumeric keys, update the value of the active cell
      else if (validCharactersRegex.test(e.key)) {
        updatedData[activeCell.row][activeCell.col].value = e.key;
      } else {
        return; // If it's not space or alphanumeric, or command/control keys are held, do nothing
      }

      updateData(updatedData);
      setIsEditing(true); // Set the cell to editing mode
    }
  };



  useEffect(() => {
    window.addEventListener('keydown', handleTyping);
    return () => {
      window.removeEventListener('keydown', handleTyping);
    };
  }, [activeCell, isEditing, data]);



  const focusSpreadsheet = () => {
    spreadsheetRef.current?.focus();
  };


  const handleCellClick = (rowIndex, colIndex, e) => {
    if (!spreadsheetFocused) return; // Add this check to ensure spreadsheet is focused
    const isShiftClick = e.shiftKey;
    const isSameCell = activeCell.row === rowIndex && activeCell.col === colIndex;
    if (isSameCell) {
      setIsEditing(true);
    } else {
      if (!isShiftClick) {
        // Change the active cell only if Shift is not pressed
        setActiveCell({ row: rowIndex, col: colIndex });
        setSubselectionHead(null);
        setSubselectedCells([]);
      } else {
        console.log('fire with Shift');
        setSubselectionHead({ row: rowIndex, col: colIndex });
        // Do not change the active cell here
      }
    }
    focusSpreadsheet();
  };

  useEffect(() => {
    if (subselectionHead) {
      const newSubselectedCells = calculateSubselectedCells(activeCell, subselectionHead);
      setSubselectedCells(newSubselectedCells);
    }
  }, [activeCell, subselectionHead]);


  const calculateSubselectedCells = (startCell, endCell) => {
    const startRow = Math.min(startCell.row, endCell.row);
    const endRow = Math.max(startCell.row, endCell.row);
    const startCol = Math.min(startCell.col, endCell.col);
    const endCol = Math.max(startCell.col, endCell.col);

    const newSubselectedCells = [];
    for (let row = startRow; row <= endRow; row++) {
      for (let col = startCol; col <= endCol; col++) {
        newSubselectedCells.push({ row, col });
      }
    }
    return newSubselectedCells;
  };



  const handleDoubleClick = (rowIndex, colIndex) => {
    if (!spreadsheetFocused) return; // Add this check to ensure spreadsheet is focused
    setActiveCell({ row: rowIndex, col: colIndex });
    setIsEditing(true);
    focusSpreadsheet();
  };

  const handleInputChange = (e) => {
    const newData = [...data];
    newData[activeCell.row][activeCell.col].value = e.target.value;
    updateData(newData);
  };


  // COPY PASTING

  const updateSpreadsheetDataFromPaste = (pastedRows) => {
  let newData = [...data];
  let startRow = activeCell.row;
  let startCol = activeCell.col;
  let endRow = startRow;
  let endCol = startCol;

  for (let i = 0; i < pastedRows.length; i++) {
    if (startRow + i >= 6) break; // Ensure not to exceed row limit
    endRow = startRow + i;
    for (let j = 0; j < pastedRows[i].length; j++) {
      if (startCol + j >= 2) break; // Ensure not to exceed column limit
      endCol = startCol + j;
      // Update cell data
      let currentRow = startRow + i;
      let currentCol = startCol + j;
      newData[currentRow][currentCol].value = pastedRows[i][j];
    }
  }
  updateData(newData);
  // Calculate the subselected cells based on the paste area
  const newSubselectedCells = [];
  for (let row = startRow; row <= endRow; row++) {
    for (let col = startCol; col <= endCol; col++) {
      newSubselectedCells.push({ row, col });
    }
  }
  setSubselectedCells(newSubselectedCells);
};


  useEffect(() => {
    const handlePaste = (e) => {
      const data = e.clipboardData.getData('text');
      const rows = data.split('\n').map(row => row.split('\t'));
      // Assuming you have a function to update your spreadsheet data
      updateSpreadsheetDataFromPaste(rows);
    };

    window.addEventListener('paste', handlePaste);
    return () => {
      window.removeEventListener('paste', handlePaste);
    };
  },)

    const maxRow = getMaxRow(data);



  const renderCells = () => {
    return (
      <>
      <DragSelection />
      <div ref={spreadsheetRef} className='editor-detailPanel-spreadsheet-cellsContainer' data-disableselect={true}>
        
        {data.flatMap((row, rowIndex) => 
          row.map((cell, colIndex) => {
            const isSubselected = subselectedCells.some(subCell => subCell.row === rowIndex && subCell.col === colIndex);
            const isSubselectionHead = subselectionHead && subselectionHead.row === rowIndex && subselectionHead.col === colIndex;

            return (
              <EditorDetailPanelChartSpreadsheetCell
                key={`${rowIndex}-${colIndex}`}
                rowIndex={rowIndex}
                colIndex={colIndex}
                cell={{ ...cell, width: cellWidth, height: cellHeight }}
                activeCell={activeCell}
                isEditing={isEditing}
                isSubselected={isSubselected}
                handleCellClick={(rowIndex, colIndex, e) => handleCellClick(rowIndex, colIndex, e)}
                handleDoubleClick={handleDoubleClick}
                isSubselectionHead={isSubselectionHead}
              />
            );
          })
        )}
      </div>
      </>
    );
  };



  return (
    <div className='editor-detailPanel-spreadsheetContainer'>
      <div ref={spreadsheetRef} className='editor-detailPanel-spreadsheet' onFocus={handleFocus} onBlur={handleBlur} tabIndex="0">
        {renderCells()}
        {isEditing && (
          <EditorDetailPanelChartSpreadsheetInput
            activeCell={activeCell}
            cellWidth={cellWidth}
            cellHeight={cellHeight}
            value={data[activeCell.row][activeCell.col].value}
            handleInputChange={handleInputChange}
            setIsEditing={setIsEditing}
            onEnterPress={handleInputEnterPress}
          />
        )}


      </div>

      <EditorDetailPanelChartSpreadsheetRowBtns 
        cellHeight={cellHeight} 
        barRowHighlightStates={barRowHighlightStates}
        setBarRowHighlightStates={setBarRowHighlightStates}
        focusSpreadsheet={focusSpreadsheet}
        barColorType={barColorType}
        maxRow={maxRow}
        availableChartColors={availableChartColors}
        barCategoryColorIds={barCategoryColorIds}
        setBarCategoryColorIds={setBarCategoryColorIds}
        projectBackgroundId={projectBackgroundId}
        slideBackgroundId={slideBackgroundId}
        uniformColorId={uniformColorId}
        barOpacity={barOpacity}
      />
      
    </div>
  );
};

export default Spreadsheet;
