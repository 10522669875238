import store from '../../store'





export function getOrgScreenVideoStyle() {  
  const state = store.getState()
  const orgBrand = state.orgBrand 
  
  let videoStyle=null

  if(orgBrand.screenVideoStyle){
    videoStyle = orgBrand.screenVideoStyle
  }

  return videoStyle
}
