import React, { useState } from 'react';
import Icon from '../../misc/Icon';
import { throttle } from 'lodash';
import { ToggleGroup, ToggleGroupBtn } from '../../uiKit/ToggleUI';
import { Slider } from '../../uiKit/Slider';
import { CheckboxItem } from '../../uiKit/CheckboxItem';
import { useSpring, animated } from 'react-spring';
import * as Tooltip from '@radix-ui/react-tooltip';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { convertZoomValuesToZoomBox } from '../../../three/utils/zoom/convertZoomValuesToZoomBox'; // Make sure to import this

const ZOOM_BOX_ASPECT = 16 / 9;

const ZOOM_MIN = 0.55
const ZOOM_MAX = 0.95

const ZoomBar = ({ clip,updateZoomValues,updateZoomBox,deleteClip,getClipForId,addZoom }) => {  


	const {zoomValues} = clip.metadata
	const {zoomBox} = clip.metadata
  const clipId = clip.id
  
  const parentClipId=clip.metadata.parentClip
  const parentClip = getClipForId(parentClipId)


  // const [zoomLevel, setZoomLevel] = useState(zoomValues.scale)

  const manualMode = clip.metadata.zoomValues.originX !== 'auto'


  const setManualMode = ()=>{    
    if(!manualMode){   
      const newZoomValues = {...zoomValues, originX: 0, originY: 0}   
      updateZoomValues(clip.id, newZoomValues)      
      const updatedZoomBox = convertZoomValuesToZoomBox(newZoomValues);
      updateZoomBox(clipId, updatedZoomBox);  
    }
    if(manualMode){      
      const newZoomValues = {...zoomValues, originX: 'auto', originY: 'auto'}   
      updateZoomValues(clip.id, newZoomValues)   
      const updatedZoomBox = convertZoomValuesToZoomBox(newZoomValues);
      updateZoomBox(clipId, updatedZoomBox);    
    } 
  }

  const motionSettings = zoomValues.motionSettings
  const endMotionSettings = zoomValues.endMotionSettings

  // console.log('zoomValues')
  // console.log(zoomValues)

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const showEndMotionSettings = endMotionSettings 

  const updateMotionSettings = (newValue) => {
    const newZoomValues = {...zoomValues, motionSettings: newValue}
    updateZoomValues(clip.id, newZoomValues);

    const updatedZoomBox = convertZoomValuesToZoomBox(newZoomValues);
    updateZoomBox(clipId, updatedZoomBox);  

    if (!showEndMotionSettings) {
      setDropdownOpen(false);
    }
  };

  const updateEndMotionSettings = (newValue) => {
    const newZoomValues = {...zoomValues, endMotionSettings: newValue}
    updateZoomValues(clip.id, newZoomValues);

    const updatedZoomBox = convertZoomValuesToZoomBox(newZoomValues);
    updateZoomBox(clipId, updatedZoomBox);  

    setDropdownOpen(true);
  };



  const handleEndMotionSettingsToggle = () => {
    
    if (showEndMotionSettings) {
      updateEndMotionSettings(null);
    } else {
      updateEndMotionSettings(motionSettings);
    }
    setDropdownOpen(true);
  };


  const buttonZoomIncrement = 0.05


  const handleZoomIn = () => {      
    const newZoomLevel = Math.max(zoomValues.scale - buttonZoomIncrement, ZOOM_MIN);
    const newZoomValues = { ...zoomValues, scale: newZoomLevel }

    updateZoomValues(clipId, { ...zoomValues, scale: newZoomLevel });

    const updatedZoomBox = convertZoomValuesToZoomBox(newZoomValues);
    updateZoomBox(clipId, updatedZoomBox);    
  };

  const handleZoomOut = () => {    
    const newZoomLevel = Math.min(zoomValues.scale + buttonZoomIncrement, ZOOM_MAX);
    const newZoomValues = { ...zoomValues, scale: newZoomLevel }
    updateZoomValues(clipId, newZoomValues);
    
    const updatedZoomBox = convertZoomValuesToZoomBox(newZoomValues);
    updateZoomBox(clipId, updatedZoomBox);
  };


  const [isDragging, setIsDragging] = useState(false);

  const handleStartChange = () => {
    setIsDragging(true);  
  };

  const handleEndChange = () => {
    setIsDragging(false);    
  };


  const handleSliderChange = (e) => {
    const newZoomLevel = parseFloat(e.target.value);

    // Update local zoom values
    const updatedZoomValues = {
      ...zoomValues,
      scale: newZoomLevel
    };
    
    updateZoomValues(clipId, updatedZoomValues);

    const updatedZoomBox = convertZoomValuesToZoomBox(updatedZoomValues);
    updateZoomBox(clipId, updatedZoomBox);          
  };


  const handleWheel = (event) => {        
    const scaleChange = event.deltaY * -0.001; // Adjust this value for faster or slower zoom
    const newScale = Math.min(Math.max(zoomValues.scale + scaleChange, ZOOM_MIN), ZOOM_MAX); // Ensure newScale is within bounds
    
    // Update local zoom values
    const updatedZoomValues = {
        ...zoomValues,
        scale: newScale
    };
    
    updateZoomValues(clipId, updatedZoomValues);    

    // Calculate and update the global zoom box based on the updated zoom values
    const updatedZoomBox = convertZoomValuesToZoomBox(localZoomValues);
    updateZoomBox(clipId, updatedZoomBox);

  };

  let motionSettingsLabel = 'Mixed'

  if((motionSettings === 'zoomSmooth' && !endMotionSettings) || (motionSettings === 'zoomSmooth' && endMotionSettings === 'zoomSmooth')){
    motionSettingsLabel = 'Smooth'
  }

  if((motionSettings === 'zoomInstant' && !endMotionSettings) || (motionSettings === 'zoomInstant' && endMotionSettings === 'zoomInstant')){
    motionSettingsLabel = 'Instant'
  }
  if((motionSettings === 'zoomZippy' && !endMotionSettings) || (motionSettings === 'zoomZippy' && endMotionSettings === 'zoomZippy')){
    motionSettingsLabel = 'Zippy'
  }
  if((motionSettings === 'zoomSlow' && !endMotionSettings) || (motionSettings === 'zoomSlow' && endMotionSettings === 'zoomSlow')){
    motionSettingsLabel = 'Slow'
  }

  const phoneRecordingZoom = clip.metadata.parentClipIsDeviceRecording


  return (
    <div className='editor-topBar editor-topBar--zoom '>
      
      <div className='editor-topBar-leftContainer'>

        {/*}
        {!phoneRecordingZoom && 
          <ToggleGroup fullWidth toggleCount={2} className='editor-topBar--zoom-type'>          
            <ToggleGroupBtn                
              active={manualMode}
              handleClick={() => setManualMode(!manualMode)}            
              label='Manual'
              tooltipLabel='Drag to position zoom precisely'
              tooltipBelow
            />   
            <ToggleGroupBtn                
              active={!manualMode}
              handleClick={() => setManualMode(!manualMode)}
              label='Auto'
              tooltipLabel='Tracks cursor automatically'
              tooltipBelow
            />               
          </ToggleGroup>
        }
        */}

      </div>


      <div onWheel={handleWheel} className='editor-topBar-centerContainer'>
        <Tooltip.Root delayDuration={400}>
          <Tooltip.Trigger asChild>
            <div data-state={isDragging ? 'dragging' : 'notDragging'} className='editor-topBar--zoom-zoomLevel'>
              <button onClick={handleZoomOut} className='editor-topBar--zoom-zoomLevel-button editor-topBar--zoom-zoomLevel-button--left'>
                <Icon name='minus' />
              </button>
              <Slider 
                sliderValue={zoomValues.scale}
                updateSliderValue={(newValue) => handleSliderChange({ target: { value: newValue }})}
                max={ZOOM_MAX}
                min={ZOOM_MIN}
                inverted
                step={0.005}
                label='Zoom'
                onStartChange={handleStartChange}
                onEndChange={handleEndChange}
                // minLabel="Zoom Less"
                // maxLabel="Zoom More"
                // showPlus
                // showMinus
              />  
              <button onClick={handleZoomIn} className='editor-topBar--zoom-zoomLevel-button editor-topBar--zoom-zoomLevel-button--right'>
                <Icon name='plus' />
              </button>       
            </div>   
          </Tooltip.Trigger>                         
          {/*}
          <Tooltip.Content side="bottom" className="tooltip">
            You can also scroll to adjust zoom
          </Tooltip.Content>                                    
          */}
        </Tooltip.Root>
      </div>


      <div className='editor-topBar-rightContainer'>        

        <DropdownMenu.Root>
          <DropdownMenu.Trigger asChild>
            <button className='dpButton dpButton--mixed dpButton--zoomBarMotion'>
              <div className='dpButton-iconContainer'>
                <Icon name='stopwatch' />
              </div>
              <div className='dpButton-label'>
                {motionSettingsLabel}
              </div>
            </button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Portal>
            <DropdownMenu.Content             
              align="end"
              alignOffset={0}
              className='dropdownMenu dropdownMenu--selectMotionPanel forceDarkTheme'
            >                            
              {!showEndMotionSettings && 
                <div className='dropdownMenu-subHeader'>
                  Zoom Animation Speed
                </div>
              }
              
              {showEndMotionSettings && 
                <div className='dropdownMenu-subHeader'>
                  Zoom Start
                </div>
              }

              {!showEndMotionSettings &&
                <>
                  <DropdownMenu.Item onSelect={() => updateMotionSettings('zoomInstant')}  className={motionSettings === 'zoomInstant' ? 'dropdownMenu-item--active' : ''}>
                    Instant
                  </DropdownMenu.Item>
                  <DropdownMenu.Item onSelect={() => updateMotionSettings('zoomZippy')} className={motionSettings === 'zoomZippy' ? 'dropdownMenu-item--active' : ''}>
                    Zippy
                  </DropdownMenu.Item>
                  <DropdownMenu.Item onSelect={() => updateMotionSettings('zoomSmooth')} className={motionSettings === 'zoomSmooth' ? 'dropdownMenu-item--active' : ''}>
                    Smooth
                  </DropdownMenu.Item>
                  <DropdownMenu.Item onSelect={() => updateMotionSettings('zoomSlow')} className={motionSettings === 'zoomSlow' ? 'dropdownMenu-item--active' : ''}>
                    Slow
                  </DropdownMenu.Item>
                </>
              }

              {showEndMotionSettings && (
                <>
                  <DropdownMenu.Item onSelect={(event) => {event.preventDefault(); updateMotionSettings('zoomInstant')}} className={motionSettings === 'zoomInstant' ? 'dropdownMenu-item--active' : ''}>
                    Instant
                  </DropdownMenu.Item>
                  <DropdownMenu.Item onSelect={(event) => {event.preventDefault(); updateMotionSettings('zoomZippy')}} className={motionSettings === 'zoomZippy' ? 'dropdownMenu-item--active' : ''}>
                    Zippy
                  </DropdownMenu.Item>
                  <DropdownMenu.Item onSelect={(event) => {event.preventDefault(); updateMotionSettings('zoomSmooth')}} className={motionSettings === 'zoomSmooth' ? 'dropdownMenu-item--active' : ''}>
                    Smooth
                  </DropdownMenu.Item>
                  <DropdownMenu.Item onSelect={(event) => {event.preventDefault(); updateMotionSettings('zoomSlow')}} className={motionSettings === 'zoomSlow' ? 'dropdownMenu-item--active' : ''}>
                    Slow
                  </DropdownMenu.Item>
                  <div className='dropdownMenu-smallVSpacer' />
                  <div className='dropdownMenu-subHeader'>
                    Zoom End
                  </div>
                  <DropdownMenu.Item onSelect={(event) => {event.preventDefault(); updateEndMotionSettings('zoomInstant')}} className={endMotionSettings === 'zoomInstant' ? 'dropdownMenu-item--active' : ''}>
                    Instant
                  </DropdownMenu.Item>
                  <DropdownMenu.Item onSelect={(event) => {event.preventDefault(); updateEndMotionSettings('zoomZippy')}} className={endMotionSettings === 'zoomZippy' ? 'dropdownMenu-item--active' : ''}>
                    Zippy
                  </DropdownMenu.Item>
                  <DropdownMenu.Item onSelect={(event) => {event.preventDefault(); updateEndMotionSettings('zoomSmooth')}} className={endMotionSettings === 'zoomSmooth' ? 'dropdownMenu-item--active' : ''}>
                    Smooth
                  </DropdownMenu.Item>
                  <DropdownMenu.Item onSelect={(event) => {event.preventDefault(); updateEndMotionSettings('zoomSlow')}} className={endMotionSettings === 'zoomSlow' ? 'dropdownMenu-item--active' : ''}>
                    Slow
                  </DropdownMenu.Item>
                </>
              )}

                <div className='dropdownMenu-smallVSpacer' />
                  <DropdownMenu.Item onSelect={(event) => { event.preventDefault(); handleEndMotionSettingsToggle(event); }} className='dropdownMenu-item--light'>
                    {showEndMotionSettings ? 'Same start/end' : 'Different end speed'}
                  </DropdownMenu.Item>                


              {/*}
              <div className='dropdownMenu--selectMotionPanel-showDoubleBtnContainer'>
                <button onClick={()=> handleEndMotionSettingsToggle} className='dropdownMenu--selectMotionPanel-showDoubleBtn'>
                  
                </button>

                {/*}
                <CheckboxItem 
                  checked={showEndMotionSettings}
                  toggleChecked={handleEndMotionSettingsToggle}
                  label='Different start/end speed'
                />
                
              </div>
              */}
                
            </DropdownMenu.Content>
            </DropdownMenu.Portal>
          </DropdownMenu.Root>  

          

      </div>

      {/*}
      <div className='rightPanel-header'>
        <div className='rightPanel-header-iconContainer'>
          <Icon name='magnifyingGlass' />
        </div>
        <div className='rightPanel-header-label'>
          Zoom 
        </div>

        <button onClick={() => setManualMode(!manualMode)} className='rightPanel-header-lightButton'>
          {manualMode ? 'Switch to Auto' : 'Manual Control'}
        </button>
      </div>
      */}

        {/*}
        {!manualMode && 
          <div className='rightPanel-row'>
            <Slider 
              min={0.55}
              max={0.95}              
              step={0.005}
              sliderValue={clip.metadata.zoomValues.scale} 
              updateSliderValue={(value)=> {updateZoomValues(clip.id, {...zoomValues, scale: value})}}
              inverted
            />
          </div>
        }
        
    	
        


        

        <div className='rightPanel-row'>
          <div className='rightPanel-row-label'>
            <div className='rightPanel-row-label-iconContainer'>
              <Icon name='stopwatchMedium' />
            </div>
            <div className='rightPanel-row-label-label'>
              Motion
            </div>
          </div>
          <div className='rightPanel-row-right'>
            
          </div>
        </div>
    	
    	
      

      <div className='rightPanel-vSpacer' />

      <button className='uiButton uiButton--centered uiButton--light' onClick={()=>{deleteClip(clip.id)}}>
      	<div className='uiButton-label'>
      		Delete Zoom
      	</div>
      </button>

      */}

    <div className='editor-topBar-rightEdgeContainer' />

    </div>
  );
};

export default ZoomBar;
