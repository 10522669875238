import {getDeviceDimensionsForCaptureId} from './deviceRecordings/getDeviceDimensionsForCaptureId'
import getMobileDeviceModelForClip from '../../three/utils/getMobileDeviceModelForClip'
 
export const getLabelForCaptureId = async (captureId,isDevice,isScreenRecording) => {
  try {
    if(isDevice){
      const deviceDimensions = await getDeviceDimensionsForCaptureId(captureId)
      const deviceModel = getMobileDeviceModelForClip(deviceDimensions.width,deviceDimensions.height)
      let label = 'iPad'
      if (deviceModel.toLowerCase().includes('iphone')) {
        label = 'iPhone';
      }
      return label
    }else if(isScreenRecording){
      let data;
      const relativePath = `screenRecordings/${captureId}/metadata.json`;
      const content = await ipcRenderer.invoke('read-file', relativePath);
      data = JSON.parse(content);
      return data.appName
    }
    else{
      let data;
      const relativePath = `recordings/${captureId}/metadata.json`;
      const content = await ipcRenderer.invoke('read-file', relativePath);
      data = JSON.parse(content);
      return data.appName
    }
  } catch (error) {
    console.error('There has been a problem with your fetch operation:', error);
    return null;
  }
};
