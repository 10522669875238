
export const findParentLayoutGroup = (elementId, layoutGroup, includeToplevel = false) => {
    let result = null;

    const search = (currentGroup, depth) => {
        let containsElement = currentGroup.children.some(child => child.id === elementId);

        for (const child of currentGroup.children) {
            if (child.isLayoutGroup) {
                containsElement = search(child, depth + 1) || containsElement;
            }
        }

        if (containsElement && (includeToplevel || depth > 0)) {
            result = currentGroup;
        }

        return containsElement;
    };

    search(layoutGroup, 0);
    return result;
};





// export const findParentLayoutGroup = (elementId, layoutGroup, includeToplevel = false) => {
//     let result = null;
//     let lowestDepth = Infinity;

//     const search = (currentGroup, depth) => {
//         if ((includeToplevel || depth > 0) && 
//             currentGroup.children.some(child => child.id === elementId || child.isLayoutGroup)) {
            
//             if (depth < lowestDepth) {
//                 result = currentGroup;
//                 lowestDepth = depth;
//             }
//         }

//         for (const child of currentGroup.children) {
//             if (child.isLayoutGroup) {
//                 search(child, depth + 1);
//             }
//         }
//     };

//     search(layoutGroup, 0);
//     return result;
// };


// export const findParentLayoutGroup = (elementId, layoutGroup, includeToplevel = false) => {
// 	if ((includeToplevel || layoutGroup.depth > 0) && layoutGroup.children.some(child => child.id === elementId)) {
// 		return layoutGroup;
// 	}
// 	for (const child of layoutGroup.children) {
// 		if (child.isLayoutGroup) {
// 			const result = findParentLayoutGroup(elementId, child, includeToplevel);
// 			if (result) return result;
// 		}
// 	}
// 	return null;
// };