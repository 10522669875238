// calculateLetterStartTimes.js

export function calculateLetterStartTimes(lettersArray, startToAnimationDuration, animationGrouping, perWordAnimateOverlapRatio, perLineAnimateOverlapRatio) {
  
  if (animationGrouping === 'everything') {    
    return lettersArray.map(() => 0); // All start at the same time
  }  

  if (animationGrouping === 'word') {    
    return lettersArray.map(letter => 
      letter.wordIndex * startToAnimationDuration * perWordAnimateOverlapRatio
    );
  }

  if (animationGrouping === 'line') {        
    return lettersArray.map(letter => 
      letter.paragraphIndex * startToAnimationDuration * perLineAnimateOverlapRatio
    );
  }

  // Default to no animation delay
  return lettersArray.map(() => 0);
}
