import React from 'react';

const DPTabs = ({ tabOptions, activeTab, onTabChange, light, tight }) => {
  const handleTabClick = (tabId) => {
    onTabChange(tabId);
  };


  return (
    <div className={"dpTabs " + (light ? " dpTabs--light " : '') + (tight ? " dpTabs--tight " : '')}>
      {tabOptions.map((tab) => (
        <button
          key={tab.id}
          className="dpTabs-tab"
          onClick={() => handleTabClick(tab.id)}
          data-active-tab={activeTab === tab.id ? 'active' : 'inactive'}
        >
          <div className='dpTabs-tab-inner'>
            {tab.label}
          </div>
        </button>
      ))}
    </div>
  );
};

export default DPTabs;
