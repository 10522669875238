import React, {useState,useEffect} from 'react'
import Icon from '../../../misc/Icon'
import * as Tooltip from '@radix-ui/react-tooltip';
import { ToggleGroup, ToggleGroupBtn } from '../../../uiKit/ToggleUI';
import { SmallTabGroup, SmallTab } from '../../../uiKit/SmallTabsUI';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'

  const motionOptions = [
    { value: 'auto', label: 'Auto' },
    { value: 'smooth', label: 'Smooth' },
    { value: 'bouncy', label: 'Bouncy' },
    { value: 'molasses', label: 'Molasses' },
    { value: 'slow', label: 'Slow' },
    { value: 'zippy', label: 'Zippy' },     
  ];

  const sampleData = [
    '4x faster',
    '100k+ requests',
    '200+ templates',
    '100k fewer formulas',    
    '6,000 customers',
    'Up to $2,500 per week',
    'Over 135 currencies',
    'Up to 90% faster',
  ];



function ChartsPanelNumber({inputData, setInputData, animationType, setAnimationType,metadata,setMotionStyle}) {  
  

   // Handler to update the input data
  const handleInputChange = (e) => {
    setInputData(e.target.value);
  };

  const handleSampleDataClick = () => {
    const randomIndex = Math.floor(Math.random() * sampleData.length);
    setInputData(sampleData[randomIndex]);
  };



  let motionStyle = metadata.motionStyle

  if(metadata.isAutoMotionStyle){
    motionStyle='Auto'
  }


  return (
    <>
      <div className='rightPanel-row'>        
        <div className='rightPanel--charts-numberInput'>
          <input 
            type="text" 
            placeholder="Number data"
            className='rightPanel--charts-numberInput-input'
            id="numberInput" 
            value={inputData} 
            onChange={handleInputChange}
          />
          {/*}
          <div className='rightPanel--charts-numberInput-ui'>
            <button className='rightPanel--charts-numberInput-ui-sample'>
              <Icon name='shuffleMedium' />
            </button>
          </div>
          */}
        </div>
      </div>

      <button className='uiButton' onClick={handleSampleDataClick}>
        <div className='uiButton-label'>
          Sample Data
        </div> 
      </button>

      <div className='rightPanel-separator' />

      <div className='rightPanel-row rightPanel-row--tabAndDropdown'>
                
        <SmallTabGroup>
          <SmallTab
            label='Animate In'
            active={true}
            //handleClick={() => setAnimateActiveTab('in')}              
            nullBar
          />
          {/*<SmallTab
            label='Animate Out'
            active={animateActiveTab === 'out'}
            handleClick={() => setAnimateActiveTab('out')}                              
          /> 
          */}         
        </SmallTabGroup>
        
        {/*}
        <DropdownMenu.Root modal={false}>
          <DropdownMenu.Trigger asChild>
            <button className='uiButton uiButton--motionStyle'>
              <div className='uiButton-iconContainer'>
                <Icon name='stopwatch' />
              </div>                
              <div className='uiButton-label'>
                {motionStyle}                
              </div>                
            </button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content             
            align="end"
            alignOffset={-48}
            className='dropdownMenu dropdownMenu--selectMotionPanel forceDarkTheme'
          >            
            <div className='dropdownMenu-explainer'>
              Slide animation settings.
            </div>
            {motionOptions.map(option => (
              <DropdownMenu.Item key={option.value} onSelect={() => setMotionStyle(option.value)} className={motionStyle === option.label ? 'dropdownMenu-item--active' : ''}>
                {option.label}
              </DropdownMenu.Item>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Root>  
        */}
    
      
      </div>

      <div className='rightPanel-row rightPanel-row--transitionBy rightPanel-row--transitionBy--empty' />                                        

        <button
          data-state={animationType === 'none' ? "active" : "inactive"}            
          onClick={() => setAnimationType('none')}
          className='uiButton uiButton--preview'
        >
          <div className='uiButton-label'>
            None
          </div>            
        </button>

        {/*
        <button 
          data-state={animationType === 'countUp' ? "active" : "inactive"}            
          onClick={() => setAnimationType('countUp')}
          className='uiButton uiButton--preview'
        >
          <div className='uiButton-label'>
            Count Up
          </div>            
        </button>
        */}

        <button 
          data-state={animationType === 'splitScale' ? "active" : "inactive"}            
          onClick={() => setAnimationType('splitScale')}
          className='uiButton uiButton--preview'
        >
          <div className='uiButton-label'>
            Split Scale
          </div> 
        </button>    

        <button 
          data-state={animationType === 'fadeIn' ? "active" : "inactive"}            
          onClick={() => setAnimationType('fadeIn')}
          className='uiButton uiButton--preview'
        >
          <div className='uiButton-label'>
            Fade In
          </div>            
        </button>

        {/*
        <button 
          data-state={animationType === 'scaleUp' ? "active" : "inactive"}            
          onClick={() => setAnimationType('scaleUp')}
          className='uiButton uiButton--preview'
        >
          <div className='uiButton-label'>
            Scale Up
          </div>            
        </button>      
        */}

    </>
  )
}

export default ChartsPanelNumber;

