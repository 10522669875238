import React, { useState, useRef, useEffect } from 'react';
import * as ContextMenu from '@radix-ui/react-context-menu'
import LibraryProjectContextMenuContents from './LibraryProjectContextMenuContents'
import LibraryProjectDropdownMenuContents from './LibraryProjectDropdownMenuContents'
import VideoPreview from '../../uiKit/videoPreview/VideoPreview'
import Icon from '../../misc/Icon'
import {formatDuration} from '../../../utils/dateFormatters/formatDuration'
import { useHistory } from 'react-router-dom';
import {openProject} from '../../../utils/projectUtils/openProject'
import {formatTimeAgo} from '../../../utils/dateFormatters/formatTimeAgo'
import {getUserForId} from '../../../utils/getUserForId'
import {getCurrentUserId} from '../../../utils/getCurrentUserId'
import { useSpring, animated } from 'react-spring';
import {getProjectLoadingStatus} from '../../../utils/projectUtils/getProjectLoadingStatus'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { useMoveToCollectionPopover } from '../../uiKit/moveToCollection/useMoveToCollectionPopover';
import {saveProjectName} from '../../../utils/projectUtils/saveProjectName' 
import LibraryProjectListItemCollections from './LibraryProjectListItemCollections'

function LibraryProjectListItem({project, onMenuOpen, onMenuClose}) {

  const {
    showMoveToPopover,
    setShowMoveToPopover,
    pendingChanges,
    handleCollectionChange,
    saveMoveToCollectionChanges
  } = useMoveToCollectionPopover(project);

  // const [showMoveToPopover, setShowMoveToPopover] = useState(false);
  
  const {name,duration} = project
  const history = useHistory()

  const handleArchive = () => {
    archiveProject(project.id); // Call the archive function with the project ID
  };

  const handleDuplicate = () => {
    duplicateProject(project.id); // Call the archive function with the project ID
  };

  let authorName
  let lastEditedByUser=getUserForId(project.updated_by)
  if(lastEditedByUser){
    authorName = lastEditedByUser.id === getCurrentUserId() ? "You" : lastEditedByUser.first_name
  }
  
  const isDraft = project.is_private
  //
  let isLocked = false
  let lockedUserFirstName 
  let lockedAvatarUrl 

  if(project && project.project_access_user){
    const lockedUserObj = getUserForId(project.project_access_user)
    if(lockedUserObj){
      lockedUserFirstName=lockedUserObj.first_name       
      if(lockedUserObj.id !== getCurrentUserId()){
        isLocked=true
      }
    }
  } 

  //

  const isLoading = getProjectLoadingStatus(project.id)
  const widthLoadingIndicator = 25

  const labelLoadingEffect = useSpring({
    opacity: isLoading ? 0.25 : 1,
    transform: isLoading ? `translateX(${widthLoadingIndicator}px)` : 'translateX(0px)',
    config: { tension: 400, friction: 32 },
    delay: isLoading ? 0 : 150
  });


  const indicatorLoadingEffect = useSpring({
    opacity: isLoading ? 1 : 0,
    transform: isLoading ? 'scale(1)' : 'scale(0.5)',
    config: { tension: 400, friction: 32 },
    delay: isLoading ? 0 : 0
  });

  const subLabelLoadingEffect = useSpring({
    opacity: isLoading ? 0.25 : 0.5,    
    delay: isLoading ? 0 : 250
  });


  
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [promoteZIndex, setPromoteZIndex] = useState(false)  






  useEffect(() => {
    if (dropdownOpen) {
      onMenuOpen(); // used in parent to prevent hover flashes
      // Immediately promote z-index when dropdown opens
      setPromoteZIndex(true);
    } else {
      onMenuClose();// used in parent to prevent hover flashes
      saveMoveToCollectionChanges() //save collection changes

      // Delay setting promoteZIndex to false when dropdown closes
      const timer = setTimeout(() => {
        setPromoteZIndex(false);
      }, 100);

      // Clean up the timer if the component unmounts or dropdownOpen changes again
      return () => clearTimeout(timer);
    }
  }, [dropdownOpen]);

  const [showMoveToPopoverDropdown, setShowMoveToPopoverDropdown] = useState(false);





  const [projectName, setProjectName] = useState(project.name);
  const [isRenaming, setIsRenaming] = useState(false);
  const inputRef = useRef(null);
  const renameTimeoutRef = useRef(null);

  const handleNameChange = (e) => {
    setProjectName(e.target.value);
  };

  const handleRenameClick = () => {
    // Close the dropdown menu
    setDropdownOpen(false);
    
    // Set a small timeout before opening the rename Popover
    renameTimeoutRef.current = setTimeout(() => {
      setIsRenaming(true);
    }, 120);
  };

    const handleRenameClose = (event) => {
    // Prevent closing if the click is inside the input
    if (event && inputRef.current && inputRef.current.contains(event.target)) {
      return;
    }
    saveProjectName(project.id, projectName);
    setIsRenaming(false);
  };

  useEffect(() => {
    if (isRenaming && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }

    // Cleanup function
    return () => {
      if (renameTimeoutRef.current) {
        clearTimeout(renameTimeoutRef.current);
      }
    };
  }, [isRenaming]);

  const handleInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleRenameClose();
    }
  };

    const onItemClick = (e) => {
    if (!isRenaming && !isLoading && !dropdownOpen) {
       if (e.metaKey) {
        openProject(project.id);
    } else {
      openProject(project.id, history);
    }
    }
  };

  // const onItemClick = () => {
  //   if (!isRenaming && !isLoading && !dropdownOpen) {
  //     openProject(project.id, history);
  //   }
  // };

  const onContextMenuChange = (open) =>{
    if (open){
      setShowMoveToPopover(false)
      onMenuOpen();// used in parent to prevent hover flashes
    }
    if(!open){
      saveMoveToCollectionChanges()
      onMenuClose();// used in parent to prevent hover flashes
    }
  }

  // const authorNames = ["Sarah", "Nicole", "Michael", "You", "Frankie"];
  // const randomAuthorName = authorNames[Math.floor(Math.random() * authorNames.length)];
  // const randomDuration = Math.floor(Math.random() * 201); // 0 to 200

  return (
  //  <ContextMenu.Root key={project.id}  onOpenChange={(open) => { if (open) setShowMoveToPopover(false)}}>
        <ContextMenu.Root key={project.id}  onOpenChange={onContextMenuChange}>
      <ContextMenu.Trigger asChild>        
        <div key={project.id} data-dropdown-open={dropdownOpen ? 'true' : 'false'} data-locked-state={isLocked ? "true" : 'false'} data-input-active={isRenaming ? "true" : "false"} data-loading-state={isLoading ? "true" : 'false'} data-promotezindex-state={promoteZIndex ? 'true' : 'false'} className='libraryPage-projectListItem' onClick={onItemClick}>
          
          {/*} Wait for server rendering
          <div className='libraryPage-projectListItem-preview'>        
            <div className='libraryPage-projectListItem-preview-cardExample'>

            </div>
          </div>
          */}
          
          <animated.div style={{ ...indicatorLoadingEffect, width: `${widthLoadingIndicator}px` }} className='libraryPage-projectListItem-loadingIndicatorContainer'>
            <div className='libraryPage-projectListItem-loadingIndicator'>
              <div className='libraryPage-projectListItem-loadingIndicator-spinner' />
            </div>
          </animated.div>


          <animated.div style={labelLoadingEffect} data-promotezindex-state={isRenaming} className='libraryPage-projectListItem-label'>

            <div className='libraryPage-projectListItem-label-label'>
              {name}
            </div>        
            {isDraft && 
            <div className='libraryPage-projectListItem-label-isDraft'>
              Draft
            </div>
            }
            {isLocked && lockedUserFirstName && 
              <div className='libraryPage-projectListItem-label-isEditing'>
                {lockedUserFirstName} is editing
              </div>
            }
            <div className='libraryPage-projectListItem-label-inputContainer'>
              <input
                ref={inputRef}
                type="text"
                value={projectName}
                onChange={handleNameChange}
                className='libraryPage-projectListItem-label-input'
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onKeyPress={handleInputKeyPress}
              />
            </div> 
            
          </animated.div>

          {isRenaming && 
            <div onClick={(e) => handleRenameClose(e)} className='libraryPage-projectListItem-renameBGClick' />
          }  


          {project.collections && project.collections.length > 0 &&
            <LibraryProjectListItemCollections
              collectionIds={project.collections}
            />
          }


          
          
          <animated.div style={subLabelLoadingEffect} className='libraryPage-projectListItem-duration'>
            {formatDuration(duration)}
          </animated.div>                        
          

          {!isDraft && 
            <animated.div style={subLabelLoadingEffect} className='libraryPage-projectListItem-timestamp'>            
              {authorName} · {formatTimeAgo(project.updated_at)}
            </animated.div>
          }

          {isDraft && 
            <animated.div style={subLabelLoadingEffect} className='libraryPage-projectListItem-timestamp'>            
              {formatTimeAgo(project.updated_at)}
            </animated.div>
          }


          <div className='libraryPage-projectListItem-dropdownContainer'>
            {!isLoading &&
              <DropdownMenu.Root 
                modal={true}
                open={dropdownOpen}
                onOpenChange={(open) => {
                 // console.log('open change here---------')
                  setDropdownOpen(open);
                  setTimeout(() => {
                    if(!open){
                      setShowMoveToPopoverDropdown(false);
                    }
                  }, 75);
                }}> 
                <DropdownMenu.Trigger asChild>
                  <button onClick={()=> setDropdownOpen(!dropdownOpen)} className='libraryPage-projectListItem-dropdownMenuBtn'>  
                    <div className='libraryPage-projectListItem-dropdownMenuBtn-inner'>
                      <Icon name='ellipses' />                                          
                    </div>
                  </button>
                </DropdownMenu.Trigger>                       
                <LibraryProjectDropdownMenuContents
                  alignOffset={-10}
                  sideOffset={-4}
                  setShowMoveToPopover={setShowMoveToPopoverDropdown}
                  showMoveToPopover={showMoveToPopoverDropdown}
                  handleArchive={handleArchive}
                  handleDuplicate={handleDuplicate}
                  project={project}
                  renameProject={handleRenameClick}
                  closeDropdown={()=> setDropdownOpen(false)}    
                  pendingChanges={pendingChanges}
                  handleCollectionChange={handleCollectionChange}                
                />                                   
              </DropdownMenu.Root>
            }
          </div>



        </div>
      </ContextMenu.Trigger>
      <ContextMenu.Portal>
        <LibraryProjectContextMenuContents
          setShowMoveToPopover={setShowMoveToPopover}
          showMoveToPopover={showMoveToPopover}
          project={project}
          pendingChanges={pendingChanges}
          handleCollectionChange={handleCollectionChange} 
          renameProject={()=>handleRenameClick()}  
        />                  
      </ContextMenu.Portal>
    </ContextMenu.Root>           
  );
}

export default LibraryProjectListItem
