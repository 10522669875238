import {fetch,batchedFetch,post,put} from '../api'



export function updateProjectShouldUseClayBrand(projectId,isClayBrand) {
	const requestBody={isClayBrand:isClayBrand}
	return (dispatch) =>{ 
		 return put(`/project/${projectId}/clay-brand`,requestBody)
			.then((response) => {
				console.log(response)

				dispatch({ type: 'UPDATE_PROJECT_SUCCESS', response })
				
				return response
			})
			.catch((error) => {
				return error
			})
	}
}