import React, { useState,useEffect } from 'react';
import Icon from '../../misc/Icon';
import DPToggleGrid from './DPToggleGrid';
import DPColorPicker from './DPColorPicker';
import DPNumberInput from './DPNumberInput';
import DPSelectMenu from './DPSelectMenu';
import DPItemAnimationPanel from './DPItemAnimationPanel';
import { getOrgBrand } from '../../../utils/brands/getOrgBrand'
import { getBackgroundForId } from '../../../utils/brands/getBackgroundForId'
import DPCheckbox from './DPCheckbox'

const EditorDetailPanelBasicVideo = (props) => {
  const {clip, toggleTrimMode,updateClipBackgroundColor} = props

  const handleUpdateMetadata = (field,value) => { 
    props.updateClipMetadata(clip.id,{[field]:value})
  }  

  const orgBrand=getOrgBrand()
  let activeBg = 'auto' //null
  if(clip.metadata.backgroundId=='none'){
    activeBg= 'none'
  }else if(clip.metadata.backgroundId){
    activeBg=clip.metadata.backgroundId
  }

  const setBgStyle = (bgId)=>{
    let value=bgId 
    if(bgId=='auto'){
      value=null
    }
    updateClipBackgroundColor(clip.id,value)
  }

  let bgStyles=[{ id:'none',value: 'none', label: 'None' }, {id:'auto', value: 'auto', label: 'Auto' }]
  
  const handleUpdateCornerRounding= (value) => {
    handleUpdateMetadata('cornerRounding',value)
  }  

  orgBrand.backgrounds.forEach((bg)=>{
    if(bg.type=='image'){
      bgStyles.push({ value: bg.src, type: 'image',id:bg.id })
    }else{
      bgStyles.push({ value: bg.rgba, type: 'solid',id:bg.id  })
    }
  })

  const isBGVideo = false

  const setHorizontalOffset=(value)=>{
     props.updateClipMetadata(clip.id,{horizontalOffset:value})
  }

  const setVerticalOffset=(value)=>{
     props.updateClipMetadata(clip.id,{verticalOffset:value})
  }

  const isMuted = clip.metadata.isMuted || false 

  const setIsMuted=(value)=>{
     props.updateClipMetadata(clip.id,{isMuted:value})
     clip.updateIsMuted(value)
  }



  return (
    <>
      <div className='editor-detailPanel-header'>
        <div className='editor-detailPanel-header-label'>
          Basic Video
        </div>
        <div className='editor-detailPanel-header-vSpacer' />        
       
      </div>
        


       <DPCheckbox
            label='Mute Video'
            checked={isMuted}
            setChecked={setIsMuted}
          />


      {/*}
      <div className='editor-detailPanel-row'>
      
       <DPNumberInput 
          min={0}
          max={1600}          
          value={isBGVideo ? 1920 : imageWidth}
          setValue={handleWidthChange}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          label='W'          
          disabled={isBGVideo}
          width={79}
        />
        <div className='editor-detailPanel-row-smallHSpacer' />
        <DPNumberInput
          min={0}
          max={1600}               
          value={isBGVideo ? 1080 : imageHeight}
          setValue={handleHeightChange} 
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          label='H'
          disabled={isBGVideo}
          width={79}
        />
        
        <div className='editor-detailPanel-row-hSpacer' />
        <button 
          onClick={() => setIsBGVideo(!isBGVideo)}
          data-active-state={isBGVideo}
          className='dpButton dpButton--iconOnly dpButton--mid dpButton--backgroundImage'
        >
          <div className='dpButton-iconContainer'>
            <Icon name='backgroundImage' />
          </div>
        </button>        
        
      </div>

      <div className='editor-detailPanel-divider' />
      */}
      <div className='editor-detailPanel-row'>
       <button 
          onClick={toggleTrimMode}          
          className='dpButton dpButton--labelOnly dpButton--fullWidth dpButton--strong'
        >
          <div className='dpButton-label'>
            Edit Trim
          </div>
        </button>
      </div>

      <div className='editor-detailPanel-divider' />
      
      <div className='editor-detailPanel-row'>
        <div className='editor-detailPanel-row-label'>
          Background
        </div>
        <div className='editor-detailPanel-row-hSpacer' />            
        <DPColorPicker                              
          activeColorId={activeBg}
          onChange={setBgStyle}
          options={bgStyles}          
        />       
      </div>    

    
      <div className='editor-detailPanel-divider' />
          <div className='editor-detailPanel-row'>
            <div className='editor-detailPanel-row-label'>
              Corner Rounding
            </div>
            <div className='editor-detailPanel-row-hSpacer' />            
            <DPNumberInput 
              min={0}
              max={1000}
              value={clip.metadata.cornerRounding || 0}
              setValue={handleUpdateCornerRounding}
              setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
              iconName='cornerRadius'              
            />
          </div>
      
      {/*}
      <div className='editor-detailPanel-divider' />
      <div className='editor-detailPanel-row'>
        <div className='editor-detailPanel-row-label'>
          Horizontal Offset
        </div>
        <div className='editor-detailPanel-row-hSpacer' />            
        <DPNumberInput           
          percentage
          min={-150}
          max={150}
          value={clip.metadata.horizontalOffset || 0}
          setValue={setHorizontalOffset}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          iconName='doubleEndedArrow'              
        />
      </div>

      <div className='editor-detailPanel-divider' />

      <div className='editor-detailPanel-row'>
        <div className='editor-detailPanel-row-label'>
          Vertical Offset
        </div>
        <div className='editor-detailPanel-row-hSpacer' />            
        <DPNumberInput           
          percentage
          min={-150}
          max={150}
          value={clip.metadata.verticalOffset || 0}
          setValue={setVerticalOffset}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          iconName='doubleEndedArrow'              
        />
      </div>
      */}


      {/*
      <div className='editor-detailPanel-divider' />
      
      <div className='editor-detailPanel-row'>
        <div className='editor-detailPanel-row-label'>
          Volume
        </div>
        <div className='editor-detailPanel-row-hSpacer' />            
        <DPNumberInput 
          percentage
          min={0}
          max={150}
          value={volume}
          setValue={setVolume}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          iconName={volumeIcon}
        />
      </div>     
      */}

      <div className='editor-detailPanel-divider' />

       <DPItemAnimationPanel 
         item={clip}
        handleUpdateMetadata={handleUpdateMetadata}
        activeStartTransition={props.clip.metadata.startTransitionType}
        activeEndTransition={props.clip.metadata.endTransitionType}
        itemType='screenVideo'
      />

     {/* {!isBGVideo && stylesDetail &&
        <>
          <div className='editor-detailPanel-divider' />
          <div className='editor-detailPanel-row'>
            <div className='editor-detailPanel-row-label'>
              Corner Rounding
            </div>
            <div className='editor-detailPanel-row-hSpacer' />            
            <DPNumberInput 
              min={0}
              max={1000}
              value={cornerRounding}
              setValue={setCornerRounding}
              setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
              iconName='cornerRadius'              
            />
          </div>

          <div className='editor-detailPanel-divider' />
          <div className='editor-detailPanel-row'>
            <div className='editor-detailPanel-row-label'>
              Shadow
            </div>
            <div className='editor-detailPanel-row-hSpacer' />            
            <DPSelectMenu
              width={89}
              value={shadowStyle}
              onChange={setShadowStyle}
              options={shadowStyles}
            />
          </div>
        </>
      }
      <div className='editor-detailPanel-divider' />
      <div className='editor-detailPanel-row'>
        <div className='editor-detailPanel-row-label'>
          Video Type
        </div>
        <div className='editor-detailPanel-row-hSpacer' />            
        <DPSelectMenu
          value={videoType}
          onChange={setVideoType}
          options={videoTypes}
        />
      </div>
      {videoType === 'clip' &&
        <> 
          <div className='editor-detailPanel-divider' />
          <div className='editor-detailPanel-row'>
            <div className='editor-detailPanel-row-label'>
              Background
            </div>
            <div className='editor-detailPanel-row-hSpacer' />            
            
          </div>
        </>
      }*/}
    </>
  );
};

export default EditorDetailPanelBasicVideo;