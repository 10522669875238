export const fetchMasterCursorData = async (projectPath,captureId) => {
  try {
    let data;
    if(window.isElectron){
      if (captureId) {
      // If fsProjectName is provided, use IPC to read the file from the main process
      const relativePath = `recordings/${captureId}/mousemoves-0.json`;
      const content = await ipcRenderer.invoke('read-file', relativePath);
      data = JSON.parse(content);
    } else {
      const MOUSE_DATA_SRC = `${projectPath}/recording/mousemoves-0.json`;
      const response = await fetch(MOUSE_DATA_SRC);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      data = await response.json();
    }
    }else { // Server side export
      if (!captureId) {
        throw new Error('captureId is required for server-side export');
      }
      const s3BaseUrl = 'https://yarn-assets.s3.amazonaws.com/';
      const cursorDataUrl = `${s3BaseUrl}recordings/${captureId}/mousemoves-0.json`;
      
      const response = await fetch(cursorDataUrl);
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
      }
      data = await response.json();
    //  console.log('Cursor data fetched:', data.length, 'items');
    }

    

    return data.map(item => {      
      return {
        x: item.x,
        y: item.y,
        time: item.processTimeMs
        // time: item.unixTimeMs        
      };
    });
  } catch (error) {
    console.error('Failed to load mouse data:', error);
    return []; // Return an empty array in case of error
  }
};
