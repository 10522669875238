export const getNormalizedLegacyKeystrokeData = async (
  keystrokeData,
  masterRecordingVideoProcessStartTimeMs,
  masterRecordingVideoProcessEndTimeMs,
) => {
  try {
    const filteredKeystrokeData = keystrokeData.filter(stroke => 
      stroke.processTimeMs >= masterRecordingVideoProcessStartTimeMs && 
      stroke.processTimeMs <= masterRecordingVideoProcessEndTimeMs
    );


    

    const normalizedKeystrokeData = filteredKeystrokeData.map(stroke => ({
      time: (stroke.processTimeMs - masterRecordingVideoProcessStartTimeMs) / 1000,
    }));

    return normalizedKeystrokeData;

  } catch (error) {
    console.error('Failed to get calculate normalizedCursor Data:', error);
    return []; // Return an empty array in case of error
  }
};
