import React from 'react'
import { Route,Redirect } from 'react-router-dom'

const MatchAuthenticated = ({
	path,
	location,
	isAuthenticated,
	willAuthenticate,	
	component: Component,
}) =>
{
	if (isAuthenticated && !willAuthenticate){
		return( 
			<Route
				path={path}
				render={(props) => { 
					return <Component {...props} /> }
				}
			/>
	
		)
	} 	
	else if(!isAuthenticated && !willAuthenticate){
		return <Redirect to={{ pathname: '/login' }} />
	}
	else return null

} 
	
export default MatchAuthenticated
