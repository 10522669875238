import store from '../../store'
import find from 'lodash/find'
import {randomID} from '../randomID'
import {getCurrentUserId} from '../getCurrentUserId'
import {createProject} from '../../actions/projects'
import { Mixpanel } from '../../Mixpanel'

//use isDraft to overwrite is_private = false when duplicating a project, otherwise just duplicate is_private

// export async function duplicateProject(projectId,isDraft) {
// 	const nowDate=new Date()
// 	const newProjectId = `${randomID()}`
// 	const createdBy=getCurrentUserId()

// 	const state = store.getState()
// 	const project = find(state.projects,{id:projectId})
// 	let newProject ={...project}
// 	newProject.id = newProjectId
// 	newProject.name = `Copy of ${project.name}`
// 	newProject.created_at=nowDate
// 	newProject.updated_at=nowDate
// 	newProject.created_by=createdBy
// 	if(isDraft){
// 		newProject.is_private = true
// 	}


// 	store.dispatch(createProject(newProject))
// 	Mixpanel.track('duplicate_project',{name:newProject.name})
// }



export async function duplicateProject(projectId, isDraft) {
    const nowDate = new Date().toISOString()
    const newProjectId = `${randomID()}`
    const createdBy = getCurrentUserId()
    const state = store.getState()
    const project = find(state.projects, {id: projectId})
    let newProject = {...project}
    newProject.id = newProjectId
    newProject.name = `Copy of ${project.name}`
    newProject.created_at = nowDate
    newProject.updated_at = nowDate
    newProject.created_by = createdBy
    newProject.updated_by = createdBy
    if(isDraft){
        newProject.is_private = true
    }
    store.dispatch(createProject(newProject))
    Mixpanel.track('duplicate_project', {name: newProject.name})

    return newProjectId  // Return the new project ID
}