import React, {Component} from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ExportPlayer from '../components/export/ExportPlayer'


//Wraps editor to key it so it reloads on id change
class ExportWrapperContainer extends Component {
	render() {
		return (
			<ExportPlayer key={this.props.projectId} {...this.props}/>
		)
	}
}

function mapStateToProps(state,ownProps) {
	const projectId=ownProps.match.params.id
	
	return {
		projectId:projectId
	}
}

export default withRouter(connect(
	mapStateToProps,
	{
	})(ExportWrapperContainer))