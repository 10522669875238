export function getImageDimensions(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function() {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = function(error) {
      reject(error);
    };
    img.src = url; // Set the source to start loading the image
  });
}