import React, { useState, useRef, useEffect } from 'react';
import { RoundedBox } from '@react-three/drei';
import { createBarChartMeshData } from './createBarChartMeshData';
import SlideBarChartBar from './SlideBarChartBar';
import { SpringAnimator } from '../../../utils/animations/SpringAnimator';
import { Text } from '@react-three/drei';
import {getBackgroundForId} from '../../../../utils/brands/getBackgroundForId'
import { simulateAnimationDuration } from '../../../utils/animations/simulateAnimationDuration';

const FPS = 60; // Frames per second

const defaultValues = {
  opacity: 0,
};
const opacityAnimationParams = {
  mass: 0.6,
  stiffness: 260,
  damping: 12,
};



const heightAnimationParams = {
  mass: 1,
  stiffness: 180,
  damping: 50,
};
const topCenterLabelOpacityAnimationParams = {
  mass: 1,
  stiffness: 120,
  damping: 80,
};

function countDecimals(value) {
  if (value === undefined || value === null || isNaN(value)) return 0;
  if (Math.floor(value) === value) return 0;
  return value.toString().split(".")[1]?.length || 0;
}





const createOpacityAnimationStates = (springParams, targetOpacity, initialOpacity, duration, fadeOutDuration, fadeOutSpringParams, staggerDelay = 0) => {
  const spring = new SpringAnimator(
    springParams.mass,
    springParams.stiffness,
    springParams.damping,
    defaultValues.opacity,
    initialOpacity
  );

  const fadeOutSpring = fadeOutSpringParams ? new SpringAnimator(
    fadeOutSpringParams.mass,
    fadeOutSpringParams.stiffness,
    fadeOutSpringParams.damping,
    defaultValues.opacity,
    initialOpacity
  ) : spring;

  const totalFrames = Math.ceil(FPS * duration);
  const opacityStates = new Array(totalFrames).fill(defaultValues.opacity);
  const fadeOutStartFrame = totalFrames - (FPS * fadeOutDuration);
  const staggerStartFrame = FPS * staggerDelay;

  for (let frame = 0; frame < totalFrames; frame++) {
    // Don't start animating until after stagger delay
    if (frame < staggerStartFrame) {
      opacityStates[frame] = initialOpacity;
      continue;
    }

    // After stagger delay but before fade out - use entrance spring
    if (frame < fadeOutStartFrame) {
      spring.setTarget(targetOpacity);
      spring.simulate(1000 / FPS, true, 0, 1);
      opacityStates[frame] = spring.position;
    } else {
      // During fade out - use exit spring
      fadeOutSpring.setTarget(0);
      fadeOutSpring.simulate(1000 / FPS, true, 0, 1);
      opacityStates[frame] = fadeOutSpring.position;
    }
  }

  return opacityStates;
};


const SlideBarChart = ({ title, rowData, time, animationType, isHidden, fontFamily, titleFontWeight, chartLabelFontWeight, barChartBarFillColor, barChartBarBorderColor, barChartValueLabelColor, barChartAxisLabelLabelColor, barChartTitleColor, barChartTitleMaxOpacity, barChartMaxOpacities, element, chartWidth, chartX, chartY, chartHeight, showValueLabels, showAxisLabels, animationDuration}) => {
  const [barChartMeshes, setBarChartMeshes] = useState([]);
  const [opacityAnimationStates, setOpacityAnimationStates] = useState([]);
  const [topCenterLabelOpacityAnimationStates, setTopCenterLabelOpacityAnimationStates] = useState([]);
  const [heightAnimationStates, setHeightAnimationStates] = useState([]);
  const [valueAnimationStates, setValueAnimationStates] = useState([]);
  

  //console.log('opacityAnimationStates')
  //console.log(opacityAnimationStates)

  const [chartHasHighlightRow, setChartHasHighlightRow] = useState(false)
  const [chartHasLowlightRow, setChartHasLowlightRow] = useState(false)

  let barSpacingPercentage
  if(element.metadata.barSpacing){
    barSpacingPercentage = element.metadata.barSpacing
  }

  

  const isAnimated = animationType !== 'none';

  const rowDataDependency = JSON.stringify(
    rowData.map(item => ({
      label: item.label,
      highlightState: item.highlightState,
      rawRowIndex: item.rawRowIndex,
      value: {
        number: item.value.number,
        prefix: item.value.prefix,
        suffix: item.value.suffix
      }
    }))
  );
  
   useEffect(() => {
    if (rowData) {
      const meshData = createBarChartMeshData(rowData, chartWidth, chartHeight, barSpacingPercentage);
      setBarChartMeshes(meshData);

      const fadeOutDuration = simulateAnimationDuration(
        { opacity: 1 },
        { opacity: 0 },
        opacityAnimationParams,
        FPS
      );

      

      // const fadeOutTopCenterDuration = simulateAnimationDuration(
      //   { opacity: 1 },
      //   { opacity: 0 },
      //   topCenterLabelOpacityAnimationParams,
      //   FPS
      // );

      // console.log('fadeOutDuration')
      // console.log(fadeOutDuration)
      // const fadeOutDuration = 0.6333333333333333

      // Create an array to store all animation states
      const newOpacityAnimationStates = [];
      const newTopCenterLabelOpacityAnimationStates = [];

      meshData.forEach((mesh, index) => {
        const staggerDelay = index * 0.5;
        
        // Regular opacity uses same params for in/out
        newOpacityAnimationStates[index] = createOpacityAnimationStates(
          opacityAnimationParams,
          1,
          0,
          animationDuration,
          fadeOutDuration,
          null, // use same params for exit
          staggerDelay
        );

        // Top center label uses different params for in/out
        newTopCenterLabelOpacityAnimationStates[index] = createOpacityAnimationStates(
          topCenterLabelOpacityAnimationParams, // slower entrance
          1,
          0,
          animationDuration,
          fadeOutDuration,
          opacityAnimationParams, // faster exit
          staggerDelay
        );
      });

      // Set all states at once rather than updating incrementally
      setOpacityAnimationStates(newOpacityAnimationStates);
      setTopCenterLabelOpacityAnimationStates(newTopCenterLabelOpacityAnimationStates);

      const newHeightAnimationStates = meshData.map((mesh, index) => {
        const heightStates = [];
        const heightSpring = new SpringAnimator(
          heightAnimationParams.mass,
          heightAnimationParams.stiffness,
          heightAnimationParams.damping,
          0
        );
        
        const staggerDelay = index * 0.5 * FPS; // Convert to frames
        
        for (let frame = 0; frame < FPS * animationDuration; frame++) {
          if (frame < staggerDelay) {
            heightStates.push(0);
            continue;
          }
          
          heightSpring.setTarget(mesh.dimensions[1]);
          heightSpring.simulate(1000 / FPS);
          heightStates.push(heightSpring.position);
        }
        return heightStates;
      });
      setHeightAnimationStates(newHeightAnimationStates);

      // console.log('newHeightAnimationStates')
      // console.log(newHeightAnimationStates)

      let hasHighlightRow = false;
      let hasLowlightRow = false;

      rowData.forEach(item => {
        if (item.highlightState === 'highlight') {
          hasHighlightRow = true;
        }
        if (item.highlightState === 'lowlight') {
          hasLowlightRow = true;
        }
      });

      setChartHasHighlightRow(hasHighlightRow);
      setChartHasLowlightRow(hasLowlightRow);
        
  }
  }, [rowDataDependency, barSpacingPercentage, chartHeight, chartWidth, animationDuration])

  

  // Calculate the staggered start times for each bar chart mesh
  const frameIndex = Math.floor(time * FPS);

  //
  // Chart offset
  const groupRef = useRef();

  // need to switch from top-left coordinates to top-right coordinates

  const SCENE_WIDTH = 1920;
  const SCENE_HEIGHT = 1080;
  
  useEffect(() => {
    if (groupRef.current) {
      // Convert from scene coordinates to Three.js coordinates
      const threeJsX = chartX + (chartWidth / 2) - SCENE_WIDTH / 2;
      const threeJsY = -chartY - (chartHeight / 2) + SCENE_HEIGHT / 2; // Negate Y because Three.js Y grows upwards
      
      groupRef.current.position.set(threeJsX, threeJsY, 0);
    }
  }, [chartX, chartY, chartWidth, chartHeight]);




  return (
    <>
      <group ref={groupRef}>      

      {barChartMeshes && barChartMeshes.map((mesh, index) => {
        
        const isUniformColor = element.metadata.isUniformColor

        const barItems = element.metadata.barChartData.barItems

        let barFillColor = barChartBarFillColor
        if(!isUniformColor){
          let barFillColorId
          if(barItems[index] && barItems[index].categoryColorId){
            barFillColorId = barItems[index].categoryColorId
          }
          barFillColor = getBackgroundForId(barFillColorId).rgba          
        }

        const barBorderColor = barFillColor

        let isHighlight = mesh.highlightState === 'highlight'
        let isLowlight = mesh.highlightState === 'lowlight'
        if(!isUniformColor){
          isHighlight = false
          isLowlight = false
        }
        
        // console.log('opacityAnimationStates[index]')
        // console.log(opacityAnimationStates[index])
        // console.log('topCenterLabelOpacityAnimationStates[index]')
        // console.log(topCenterLabelOpacityAnimationStates[index])

        return (
          <group key={`${mesh.label}-${index}`}>
            <SlideBarChartBar
              key={index}

              barChart
              
              value={mesh.value}
              width={mesh.dimensions[0]}
              initialHeight={mesh.dimensions[1]} // Initial height
              positionX={mesh.x}
              positionY={mesh.y}
              bottomCenterLabel={mesh.label}
              //topCenterLabel={`${mesh.valuePrefix}${mesh.value}${mesh.valueSuffix}`}
              
              isHighlight={isHighlight}
              isLowlight={isLowlight}

              opacityAnimationState={opacityAnimationStates[index]}
              heightAnimationState={heightAnimationStates[index]}
              topCenterLabelOpacityAnimationStates={topCenterLabelOpacityAnimationStates[index]}
              topCenterLabel={mesh.value}
              
              topCenterLabelPrefix={mesh.valuePrefix}
              topCenterLabelSuffix={mesh.valueSuffix}
              time={time}
                            
              animationType={animationType}
              isHidden={isHidden}
              fontFamily={fontFamily}
              chartLabelFontWeight={chartLabelFontWeight}

              barChartBarFillColor={barFillColor}
              barChartBarBorderColor={barBorderColor}

              barChartValueLabelColor={barChartValueLabelColor}
              barChartAxisLabelLabelColor={barChartAxisLabelLabelColor}

              barChartMaxOpacities={barChartMaxOpacities}
              showValueLabels={showValueLabels}
              showAxisLabels={showAxisLabels}
            />
          </group>
        );
      })}

      </group>
    </>
  );
};

export default SlideBarChart;
