import {webcamSizes as sizes, webcamPositions as positions,SLIDE_WIDTH,SLIDE_HEIGHT,FLOAT_CAMERA_INSET} from './webcamConfigs'


export function calculateWebcamRectForSizeAndPosition(sizeName, position) {
  const size = sizes.find(s => s.name === sizeName);
 

  let rect = {
    height: size.height,
    width: size.width,
    x: 0,
    y: 0
  };

  const verticalCenter = (SLIDE_HEIGHT - size.height) / 2;
  const bottomVerticalFloat = SLIDE_HEIGHT - size.height - FLOAT_CAMERA_INSET;
  const rightHorizontalFloat = SLIDE_WIDTH - size.width - FLOAT_CAMERA_INSET;

  switch(position) {
    case 'topLeft':
      rect.x = FLOAT_CAMERA_INSET;
      rect.y = FLOAT_CAMERA_INSET;
      break;
    case 'middleLeft':
      rect.x = FLOAT_CAMERA_INSET;
      rect.y = verticalCenter;
      break;
    case 'bottomLeft':
      rect.x = FLOAT_CAMERA_INSET;
      rect.y = bottomVerticalFloat;
      break;
    case 'fillLeft':
      rect.x = 0;
      rect.y = 0;
      break;
    case 'center':
      rect.x = (SLIDE_WIDTH - size.width) / 2;
      rect.y = verticalCenter;
      break;
    case 'topRight':
      rect.x = rightHorizontalFloat;
      rect.y = FLOAT_CAMERA_INSET;
      break;
    case 'middleRight':
      rect.x = rightHorizontalFloat;
      rect.y = verticalCenter;
      break;
    case 'bottomRight':
      rect.x = rightHorizontalFloat;
      rect.y = bottomVerticalFloat;
      break;
    case 'fillRight':
      rect.x = SLIDE_WIDTH - size.width;
      rect.y = 0;
      break;
    default:
      break;
  }

  return rect;
}