import React, { useMemo, useEffect } from 'react';
import * as THREE from 'three';

const SlideImageElementFillMaterial = ({ opacity, imgSrc, meshWidth, meshHeight, imageWidth, imageHeight, renderOrder }) => {
  
  // console.log('meshWidth', meshWidth)
  // console.log('meshHeight', meshHeight)
  // console.log('imageWidth', imageWidth)
  // console.log('imageHeight', imageHeight)

  const texture = useMemo(() => {
    const loader = new THREE.TextureLoader();
    const texture = loader.load(imgSrc, (tex) => {
      tex.colorSpace = THREE.SRGBColorSpace;      
      tex.needsUpdate = true;
    });
    return texture;
  }, [imgSrc]);

  const material = useMemo(() => {
    if (texture && meshWidth && meshHeight && imageWidth && imageHeight) {
      const aspectRatioMesh = meshWidth / meshHeight;
      const aspectRatioImage = imageWidth / imageHeight;
      
      let scaleX, scaleY;
      
      if (aspectRatioMesh > aspectRatioImage) {
        // Mesh is wider than the image
        scaleX = 1;
        scaleY = (aspectRatioMesh / aspectRatioImage);
      } else {
        // Mesh is taller than the image
        scaleX = (aspectRatioImage / aspectRatioMesh);
        scaleY = 1;
      }

      texture.repeat.set(1 / scaleX, 1 / scaleY);
      texture.offset.set((1 - 1 / scaleX) / 2, (1 - 1 / scaleY) / 2);
    }

    return new THREE.MeshBasicMaterial({
      map: texture,
      transparent: true,
      opacity: opacity,
      precision: 'highp',
    });
  }, [texture, opacity, meshWidth, meshHeight, imageWidth, imageHeight]);

  // Use useEffect to set the renderOrder after the material is created
  useEffect(() => {
    if (material && renderOrder !== undefined) {
      material.renderOrder = renderOrder;
    }
  }, [material, renderOrder]);

  return <primitive object={material} attach="material" transparent={true} />;
};

export default SlideImageElementFillMaterial;