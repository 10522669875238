import {SlideElement} from './SlideElement'

class ChartElement extends SlideElement {



  constructor(options,slideClip) {
    super(options,slideClip)

  }


  toJSON() {
    const elementJSON =  {
      id: this.id,
      type:this.type,
      width:this.width,
      height:this.height,
      x:this.x,
      y:this.y,
      metadata:this.metadata,
      zIndex:this.zIndex,
      bottomMargin:this.bottomMargin,
      rightMargin:this.rightMargin

    };
    return elementJSON
  }

  
}


export { ChartElement }
