const tracks=[
	{   
		id:'2099254854', // checked
		title: "Babar", 
		src:"/songs/Babar.wav",
		custom: null
	},
	{   
		id:'2074254854', // checked
		title: "Discovery", 
		src:"/songs/Discovery.m4a",
		custom: null
	},
	{   
		id:'1670698683', // checked
		title: "Havana", 
		src:"/songs/Havana.m4a",
		custom: null
	},
	{   
		id:'1424167421', // checked
		title: "Lift", 
		src:"/songs/Lift.m4a",
		custom: null
	},	
	{   
		id:'5568844444', // checked
		title: "Oracle", 
		src:"/songs/Oracle.m4a",
		custom: null
	},	
	{   
		id:'1467144444', // checked
		title: "Orleans", 
		src:"/songs/Orleans.wav",
		custom: null
	},	
	{   
		id:'1467167444', // checked
		title: "New York", 
		src:"/songs/NewYork.wav",
		custom: null
	},	
	{   
		id:'1467167421', // checked
		title: "Pixar", 
		src:"/songs/Pixar.mp3",
		custom: null
	},	
	{   
		id:'1945908397', // checked
		title: "Ragtime", 
		src:"/songs/Ragtime.m4a",
		custom: null
	},
		{   
		id:'905566483', // checked
		title: "Tiptoe", 
		src:"/songs/Tiptoe.m4a",
		custom: null
	},
		{   
		id:'1594940597', // checked
		title: "Waves", 
		src:"/songs/Waves.m4a",
		custom: null
	},
	
	// Unsupervised	
	{   
		id:'88450', // checked
		title: "A Winter to Remember", 
		src:"/songs/unsupervised/ES_A Winter to Remember - Trevor Kowalski.mp3",
		custom: "Unsupervised"
	},	
	
	{   
		id:'88452', // checked
		title: "Amalthea", 
		src:"/songs/unsupervised/ES_Amalthea - Van Sandano.mp3",
		custom: "Unsupervised"
	},
	
	{   
		id:'88454', // checked
		title: "Light Room", 
		src:"/songs/unsupervised/ES_Baby's Room - Brendon Moeller.mp3",
		custom: "Unsupervised"
	},
	{   
		id:'88455', // checked
		title: "Beyond the Unknown", 
		src:"/songs/unsupervised/ES_Beyond the Unknown - Megan Wofford.mp3",
		custom: "Unsupervised"
	},
	{   
		id:'88456', // checked
		title: "Bird Watcher", 
		src:"/songs/unsupervised/ES_Bird Watcher - Sight of Wonders.mp3",
		custom: "Unsupervised"
	},
	{   
		id:'88457', // checked
		title: "From Stardust", 
		src:"/songs/unsupervised/ES_From Stardust - Christoffer Moe Ditlevsen.mp3",
		custom: "Unsupervised"
	},
	{   
		id:'88458', // checked
		title: "Heart Beam", 
		src:"/songs/unsupervised/ES_Heart Beam - ELFL.mp3",
		custom: "Unsupervised"
	},
	{   
		id:'88459', // checked
		title: "Holding on to Hope", 
		src:"/songs/unsupervised/ES_Holding on to Hope - Megan Wofford.mp3",
		custom: "Unsupervised"
	},
	{   
		id:'88460', // checked
		title: "Journey to Mars", 
		src:"/songs/unsupervised/ES_Journey to Mars - Sayuri Hayashi Egnell.mp3",
		custom: "Unsupervised"
	},
	{   
		id:'88461', // checked
		title: "Past the Shadows", 
		src:"/songs/unsupervised/ES_Past the Shadows - Megan Wofford.mp3",
		custom: "Unsupervised"
	},
	{   
		id:'88462', // checked
		title: "Sola Ventus", 
		src:"/songs/unsupervised/ES_Sola Ventus - Lama House.mp3",
		custom: "Unsupervised"
	},
	{   
		id:'88463', // checked
		title: "Starways", 
		src:"/songs/unsupervised/ES_Starways - Eden Avery.mp3",
		custom: "Unsupervised"
	},
	{   
		id:'88464', // checked
		title: "Twinkling Grace", 
		src:"/songs/unsupervised/ES_Twinkling Grace - Rand Aldo.mp3",
		custom: "Unsupervised"
	},

	
	// Stack AI	
	{   
		id:'98450',
		title: "A Winter to Remember", 
		src:"/songs/canvas/ES_A Winter to Remember - Trevor Kowalski.mp3",
		custom: "Canvas"
	},		
	{   
		id:'98452',
		title: "All is Now", 
		src:"/songs/canvas/ES_All Is Now - Ave Air.mp3",
		custom: "Canvas",		
	},	
	{   
		id:'98454',
		title: "Amalthea", 
		src:"/songs/canvas/ES_Amalthea - Van Sandano.mp3",
		custom: "Canvas"
	},
	{   
		id:'98455',
		title: "An Animated Life", 
		src:"/songs/canvas/ES_An Animated Life - Imprismed.mp3",
		custom: "Canvas"
	},
	{   
		id:'98456',
		title: "Are We Forever", 
		src:"/songs/canvas/ES_Are We Forever - Ave Air.mp3",
		custom: "Canvas"
	},
	{   
		id:'98457',
		title: "Baby's Room", 
		src:"/songs/canvas/ES_Baby's Room - Brendon Moeller.mp3",
		custom: "Canvas"
	},
	{   
		id:'98458',
		title: "Farewell My Dear", 
		src:"/songs/canvas/ES_Farewell My Dear - Brendon Moeller.mp3",
		custom: "Canvas"
	},
	{   
		id:'98459',
		title: "Heart Beam", 
		src:"/songs/canvas/ES_Heart Beam - ELFL.mp3",
		custom: "Canvas"
	},
	{   
		id:'98460',
		title: "In Search of Wonder", 
		src:"/songs/canvas/ES_In Search of Wonder - Brendon Moeller.mp3",
		custom: "Canvas"
	},
	{   
		id:'98461',
		title: "Inbound", 
		src:"/songs/canvas/ES_Inbound - Brendon Moeller.mp3",
		custom: "Canvas"
	},
	{   
		id:'98462',
		title: "Moral Mishap", 
		src:"/songs/canvas/ES_Moral Mishap - Gridded.mp3",
		custom: "Canvas"
	},
	{   
		id:'98463',
		title: "Nocturnal Soul", 
		src:"/songs/canvas/ES_Nocturnal Soul - Sonum.mp3",
		custom: "Canvas"
	},
	{   
		id:'98464',
		title: "Undertow", 
		src:"/songs/canvas/ES_Undertow - Gridded.mp3",
		custom: "Canvas"
	},

	// Parcha	
	{   
		id:'128450', // done
		title: "A Beginning", 
		src:"/songs/parcha/ES_A Beginning - Rymdklang Soundtracks.mp3",
		custom: "Parcha"
	},		
	{   
		id:'128452', // done
		title: "All Parts Equal", 
		src:"/songs/parcha/ES_All Parts Equal - Airae.mp3",
		custom: "Parcha",		
	},	
	{   
		id:'128454', // done
		title: "Brand New Beginning", 
		src:"/songs/parcha/ES_Brand New Beginning - Oman.mp3",
		custom: "Parcha"
	},
	{   
		id:'128455', // done
		title: "I Follow", 
		src:"/songs/parcha/ES_I Follow - Mar Vei.mp3",
		custom: "Parcha"
	},
	{   
		id:'128456', // done
		title: "Kalimba", 
		src:"/songs/parcha/ES_Kalimba - Duckmaw.mp3",
		custom: "Parcha"
	},
	{   
		id:'128457', // done
		title: "Live Free", 
		src:"/songs/parcha/ES_Live Free - Imprismed.mp3",
		custom: "Parcha"
	},
	{   
		id:'128458', // done
		title: "Positronic Emotions", 
		src:"/songs/parcha/ES_Positronic Emotions - Oman.mp3",
		custom: "Parcha"
	},
	{   
		id:'128459', // done
		title: "Ramo", 
		src:"/songs/parcha/ES_Ramo - Valante.mp3",
		custom: "Parcha"
	},
	{   
		id:'128460', // done
		title: "The Ticket", 
		src:"/songs/parcha/ES_She's Got a Ticket to My Heart (Instrumental Version) - Basixx.mp3",
		custom: "Parcha"
	},
	{   
		id:'128461', // done
		title: "Summer Vibe", 
		src:"/songs/parcha/ES_Summer Vibe - Tomas Skyldeberg.mp3",
		custom: "Parcha"
	},
	{   
		id:'128462', // done
		title: "Timeless Serenity", 
		src:"/songs/parcha/ES_Timeless Serenity - dvine.mp3",
		custom: "Parcha"
	},
	{   
		id:'128463', // done
		title: "Vice City", 
		src:"/songs/parcha/ES_Vice City - Ben Elson.mp3",
		custom: "Parcha"
	},
	{   
		id:'128464', // todo
		title: "Where I Belong", 
		src:"/songs/parcha/ES_Where I Belong - Oman.mp3",
		custom: "Parcha"
	},
	{   
		id:'128465', // todo
		title: "Wings of Tomorrow", 
		src:"/songs/parcha/ES_Wings of Tomorrow - Rymdklang Soundtracks.mp3",
		custom: "Parcha"
	},
	{   
		id:'128466', // todo
		title: "Wishful", 
		src:"/songs/parcha/ES_Wishful - dvine.mp3",
		custom: "Parcha"
	},
	{   
		id:'128467', // todo
		title: "You Go", 
		src:"/songs/parcha/ES_You Go - Duckmaw.mp3",
		custom: "Parcha"
	},
	{   
		id:'128468', // todo
		title: "Voyage", 
		src:"/songs/parcha/ES_Voyage - PW.mp3", // not there
		custom: "Parcha"
	},

	// // Faire	
	// {   
	// 	id:'7728450', // todo
	// 	title: "Cotton and Strangers", 
	// 	src:"/songs/faire/ES_Cotton and Strangers - Candelion.mp3",
	// 	custom: "Faire"
	// },		
	// {   
	// 	id:'7728452', // todo
	// 	title: "Doze Off", 
	// 	src:"/songs/faire/ES_Doze Off - Martin Landstrom.mp3",
	// 	custom: "Faire",		
	// },	
	// {   
	// 	id:'7728454', // todo
	// 	title: "Happy-Go-Lucky", 
	// 	src:"/songs/faire/ES_Happy-Go-Lucky - Victor Lundberg.mp3",
	// 	custom: "Faire"
	// },
	// {   
	// 	id:'7728455', // todo
	// 	title: "I Ain't Lyin No More", 
	// 	src:"/songs/faire/ES_I Ain't Lyin No More - Rikard From.mp3",
	// 	custom: "Faire"
	// },
	// {   
	// 	id:'7728456', // todo
	// 	title: "In the Barn - Roy Edwin Williams", 
	// 	src:"/songs/faire/ES_In the Barn - Roy Edwin Williams.mp3",
	// 	custom: "Faire"
	// },
	// {   
	// 	id:'7728457', // todo
	// 	title: "It's A Beautiful Day", 
	// 	src:"/songs/faire/ES_It's A Beautiful Day (Instrumental Version) - River Sam.mp3",
	// 	custom: "Faire"
	// },
	// {   
	// 	id:'7728458', // todo
	// 	title: "Kailua Beach Park", 
	// 	src:"/songs/faire/ES_Kailua Beach Park - Paper Twins.mp3",
	// 	custom: "Faire"
	// },
	// {   
	// 	id:'7728459', // todo
	// 	title: "Myself and I", 
	// 	src:"/songs/faire/ES_Myself and I (Instrumental Version) - Niklas Gabrielsson with Martin Landstrom & His Orchestra.mp3",
	// 	custom: "Faire"
	// },
	// {   
	// 	id:'7728460', // todo
	// 	title: "Alcones Negros", 
	// 	src:"/songs/faire/ES_Paseo - Alcones Negros.mp3",
	// 	custom: "Faire"
	// },
	// {   
	// 	id:'7728461', // todo
	// 	title: "Revving", 
	// 	src:"/songs/faire/ES_Revving - Martin Landstrom.mp3",
	// 	custom: "Faire"
	// },
	// {   
	// 	id:'7728462', // todo
	// 	title: "Riding High", 
	// 	src:"/songs/faire/ES_Riding High - John Runefelt.mp3",
	// 	custom: "Faire"
	// },
	// {   
	// 	id:'7728463', // todo
	// 	title: "The Fuzzy Love", 
	// 	src:"/songs/faire/ES_The Fuzzy Love - Roots and Recognition.mp3",
	// 	custom: "Faire"
	// },
	// {   
	// 	id:'7728464', // todo
	// 	title: "Yuzu", 
	// 	src:"/songs/faire/ES_Yuzu - Hara Noda.mp3",
	// 	custom: "Faire"
	// },	
		
]

export default tracks


// const songs = [
//  // { id: 0, title: 'Discovery', src: '../songs/Discovery.m4a' }, // todo
//   // { id: 1, title: 'Havana', src: '../songs/Havana.m4a' }, // todo
//  // { id: 2, title: 'Lift', src: '../songs/Lift.m4a' }, // todo
//   //{ id: 3, title: 'Oracle', src: '../songs/Oracle.m4a' }, // todo
//  // { id: 4, title: 'Ragtime', src: '../songs/Ragtime.m4a' }, // todo
//  // { id: 5, title: 'Tiptoe', src: '../songs/Tiptoe.m4a' }, // todo
//   { id: 6, title: 'Waves', src: '../songs/Waves.m4a' }, // todo
// ];