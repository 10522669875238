export default function (state = {}, action) {
	switch (action.type) {
	
   
    case 'SIGN_OUT':
      return {}
   
    case 'FETCH_USER_SUCCESS':
      return action.response

    case 'UPDATE_PRO_MODE':
      return {...state,is_pro_mode:action.isProMode}



    default:
      return state;
	}
}
