import { EditorState, TextSelection } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import schema  from '../../../../../prosemirror/schema/editorSchema'
import TranscriptChunkNodeView from '../../../../../prosemirror/nodeViews/TranscriptChunkNodeView'
import SceneHeaderNodeView from '../../../../../prosemirror/nodeViews/SceneHeaderNodeView' 
import TranscriptGroupNodeView from '../../../../../prosemirror/nodeViews/TranscriptGroupNodeView' 
import WebcamRecordingChunkNodeView from '../../../../../prosemirror/nodeViews/WebcamRecordingChunkNodeView'
import { baseKeymap } from 'prosemirror-commands'
import { keymap } from 'prosemirror-keymap'
import { makeDocJson } from '../utils/makeDocJson'
//import { updateTranscriptChunkAndSceneCounts } from '../utils/updateTranscriptChunkAndSceneCounts'
import { updateClipIndexAndSceneCounts } from '../utils/updateClipIndexAndSceneCounts'
import {enterKeyHandler} from '../utils/enterKeyHandler'
import {backspaceHandler} from '../utils/backspaceHandler'
import {tabKeyHandler} from '../utils/tabKeyHandler'
import {handlePaste} from '../utils/handlePaste'
import { inputRules } from 'prosemirror-inputrules';
import { insertSceneInputRule } from './inputRules/insertSceneInputRule'
import {insertWebcamGroupInputRule} from './inputRules/insertWebcamGroupInputRule'
import paragraphButtonsPlugin from '../../../../../prosemirror/plugins/paragraphButtonsPlugin'
import {cursorInsideNodePlugin} from '../../../../../prosemirror/plugins/cursorInsideNodePlugin'
import {showInlineControlsPlugin} from '../../../../../prosemirror/plugins/showInlineControlsPlugin'
import {randomID} from '../../../../../utils/randomID'
import { Plugin, PluginKey } from 'prosemirror-state'

import filter from 'lodash/filter'
import webcamRecordingKeyPressPlugin from '../../../../../prosemirror/plugins/webcamRecordingKeyPressPlugin'
import selectionPlugin from '../../../../../prosemirror/plugins/selectionPlugin'
import {arrowPlugin} from '../../../../../prosemirror/plugins/arrowPlugin'
import skipDecorationPlugin from '../../../../../prosemirror/plugins/skipDecorationPlugin'
import getParsedNodes from '../utils/getParsedNodes'




const SET_DOC_FOR_DEV_PANEL=true //show the transcript doc in dev panel

export class TranscriptProsemirrorManager {

	 constructor(seekToTimeInWebcamClip,setPMDocForDevMode, updateTimelineFromTranscript,playClipFromTranscript,recalculateAudioClip,addSceneFromTranscriptPanel,handleMouseOverTranscriptChunk,handleMouseLeaveTranscriptChunk,handleCursorInsideTranscriptChunk) {
		this.setPMDocForDevMode = setPMDocForDevMode;
		this.updateTimelineFromTranscript=updateTimelineFromTranscript
		this.playClipFromTranscript=playClipFromTranscript
		this.recalculateAudioClip=recalculateAudioClip
		this.addSceneFromTranscriptPanel=addSceneFromTranscriptPanel
		this.handleMouseOverTranscriptChunk=handleMouseOverTranscriptChunk
		this.handleMouseLeaveTranscriptChunk=handleMouseLeaveTranscriptChunk
		this.handleCursorInsideTranscriptChunk=handleCursorInsideTranscriptChunk
		this.view = null;
		this.seekToTimeInWebcamClip=seekToTimeInWebcamClip
	}


	init(container, clips, scenes) {
		const audioClips = filter(clips,{type:'audio'})
		const webcamClips = filter(clips,{type:'webcam'})

		const doc = makeDocJson(webcamClips,audioClips, scenes);
		const handleMouseOverTranscriptChunk =this.handleMouseOverTranscriptChunk
		const handleMouseLeaveTranscriptChunk = this.handleMouseLeaveTranscriptChunk
		if (this.view) {// If view exists, update the state
			this.view.updateState(EditorState.create({
				doc: doc,
				plugins: this.createPlugins(),
			}));
		} else {
			this.view = new EditorView(container, {
				state: EditorState.create({
					doc: doc,
					plugins: this.createPlugins(),
				}),
				nodeViews: {
					transcriptChunk: (node, view, getPos) => new TranscriptChunkNodeView(node, view, getPos,handleMouseOverTranscriptChunk,handleMouseLeaveTranscriptChunk),
					sceneHeader: (node, view, getPos) => new SceneHeaderNodeView(node, view, getPos),
					transcriptGroup: (node, view, getPos) => new TranscriptGroupNodeView(node, view, getPos),
					webcamRecordingChunk: (node, view, getPos) => new WebcamRecordingChunkNodeView(node, view, getPos,handleMouseOverTranscriptChunk,handleMouseLeaveTranscriptChunk),
				},
				handlePaste:handlePaste,
				dispatchTransaction: this.dispatchTransaction.bind(this)
			});
		}
		if (SET_DOC_FOR_DEV_PANEL) {
			this.setPMDocForDevMode(this.view.state.doc);
		}
	}

	getInitialChunks(){
		if(this.view){
			const parsedNodes = getParsedNodes(this.view)
			return parsedNodes
		}
	}

	manuallyAddAudioClip(){
		const clipId=randomID()
		const {state} = this.view
		let {tr} = state
		let lastSceneId = null;
		state.doc.descendants((node, pos) => {
			if (node.type.name === "sceneHeader") {
				lastSceneId = node.attrs.sceneId;
			} 
		});
		const newNode = schema.nodes.transcriptChunk.createAndFill({
			clipId: clipId,
			sceneId: lastSceneId,
		});
		const insertPos = state.doc.content.size;
		tr = tr.insert(insertPos, newNode);
		const newNodePos = insertPos + 1; // +1 to move inside the node
		tr = tr.setSelection(TextSelection.create(tr.doc, newNodePos));
		this.view.dispatch(tr);
		this.view.focus()
	}

	focusSceneHeader(sceneId) { //actually lets focus the first transcript chunk
		const { state, dispatch } = this.view;
		let foundPos = null;
		let foundNode = null;
		state.doc.descendants((node, pos) => {
			if (node.type.name === "sceneHeader" && node.attrs.sceneId === sceneId) {
				foundPos = pos;
				foundNode = node;
				return false; // Stop the search
			}
		});
		
		if (foundPos !== null && foundNode) {
			// this puts cursor in the header this does the text in the header
			const start = foundPos + 2;
			//const end = start + foundNode.nodeSize - 1;
			const end = start
			const transaction = state.tr.setSelection(TextSelection.create(state.doc, start, end));
			dispatch(transaction);
			this.view.focus();
		} else {
			console.log(`Scene header with sceneId ${sceneId} not found.`);
		}
	}



	

	createPlugins() {
		const copyPlugin = new Plugin({

    props: {
      handleDOMEvents: {
       copy: (view, event) => this.handleCopy(view, event)
      }
    }
  });
    return [
			webcamRecordingKeyPressPlugin,
			selectionPlugin,
			arrowPlugin,
			keymap({
				"Shift-Enter": this.regenClip,
				"Mod-Enter": this.playClip,
				"Enter": enterKeyHandler(),
				'Backspace': backspaceHandler(),
				"Tab":tabKeyHandler()
			}),
			keymap(baseKeymap),
			cursorInsideNodePlugin(this.handleCursorInsideTranscriptChunk),
			inputRules({
				rules: [insertSceneInputRule(this.addSceneFromTranscriptPanel),insertWebcamGroupInputRule],
			}),
			copyPlugin
		];
}

handleCopy = (view, event) => {
  const { state } = view;
  const { from, to } = state.selection;
  
  // Check if the entire document is selected
  const isWholeDocSelected = from === 0 && to === state.doc.content.size;
  
  // console.log(`isWholeDocSelected`,isWholeDocSelected)
  // If not the whole doc, let default behavior handle it
  if (!isWholeDocSelected) return false;
  
  // Extract text from transcript chunks only
  const textParts = [];
  state.doc.descendants(node => {
    if (node.type.name === 'transcriptChunk') {
      textParts.push(node.textContent);
    }
  });
  
  // If no content, let default behavior handle it
  if (textParts.length === 0) return false;
  
  // Join with single newlines
  const plainText = textParts.join('\n');
  
  // Set clipboard data
  event.preventDefault();
  event.clipboardData.setData('text/plain', plainText);
  
  return true;
}


	regenClip=()=>{
		const { state } = this.view;
		const { selection } = state;
		const { $from, $to } = selection;
		let transcriptChunkNode = null;
		state.doc.nodesBetween($from.pos, $to.pos, (node, pos) => {
		  if (node.type.name === 'transcriptChunk') {
			transcriptChunkNode = node;
			return false;  // Stops the iteration after the first voiceClip node is found
			}
		});
		if (transcriptChunkNode) {
			const clipId = transcriptChunkNode.attrs.clipId;
			this.recalculateAudioClip(clipId)
		} 
	}

	playClip=()=>{
		const { state } = this.view;
		const { selection } = state;
		const { $from, $to } = selection;
		let transcriptChunkNode = null;
		state.doc.nodesBetween($from.pos, $to.pos, (node, pos) => {
		  if (node.type.name === 'transcriptChunk') {
			transcriptChunkNode = node;
			return false;  // Stops the iteration after the first voiceClip node is found
			}
		});
		if (transcriptChunkNode) {
			const clipId = transcriptChunkNode.attrs.clipId;
			this.playClipFromTranscript(clipId)
		} 
	}

	updateRequiresUpdateStatus(id,requiresUpdate){
		if(this.view){
			let tr = this.view.state.tr
			this.view.state.doc.descendants((node, pos) => {
			  if (node.type.name === "transcriptChunk" && node.attrs.clipId==id) {
					const updatedAttrs = { ...node.attrs, requiresUpdate:requiresUpdate };
					tr.setNodeMarkup(pos, null, updatedAttrs);
				}
			});
			tr.setMeta('addToHistory', false)
			let newState = this.view.state.apply(tr);
			this.view.updateState(newState);
			// this.editorState = this.editorState.apply(tr);
			if(SET_DOC_FOR_DEV_PANEL){
				this.setPMDocForDevMode(this.view.state.doc);
			}
		}
	}

	updateTranscriptFromTimeline(voiceoverClips, scenes) {
		const { state } = this.view;
		const { from, to } = state.selection;
		const currentSelectionPos = { from, to }
		const container = null;
		this.init(container, voiceoverClips, scenes);
		const newState = this.view.state;
		let newFrom = Math.min(currentSelectionPos.from, newState.doc.content.size);
		let newTo = Math.min(currentSelectionPos.to, newState.doc.content.size);
		const transaction = newState.tr.setSelection(TextSelection.create(newState.doc, newFrom, newTo));
		this.view.dispatch(transaction);
		this.view.focus();
	}

  dispatchTransaction(transaction) {
    try {
      let newState = this.view.state.apply(transaction);
      this.view.updateState(newState);

      if (transaction.docChanged) {
        try{
          const updateTransaction = updateClipIndexAndSceneCounts(newState);
          
          if (updateTransaction.docChanged) {
            const docSize = newState.doc.content.size;
            // Check if any step in the transaction would create invalid positions
            const isValid = updateTransaction.steps.every(step => {
              const from = step.from || 0;
              const to = step.to || 0;
              return from <= docSize && to <= docSize;
            });
            if (isValid) {
              newState = this.view.state.apply(updateTransaction);
              this.view.updateState(newState);
            } else {
              console.warn('Skipped invalid update transaction');
            }
          }
          // Get the updated chunks and update the timeline
          const parsedNodes = getParsedNodes(this.view);

        	this.updateTimelineFromTranscript(parsedNodes);          
          if (SET_DOC_FOR_DEV_PANEL) {// Update dev panel if enabled
            this.setPMDocForDevMode(newState.doc);
          }
        } catch (updateError) {
          console.error('Error applying update transaction:', updateError);
          // Revert to the state after the initial transaction if the update fails
          this.view.updateState(newState);
        }
      }
    } catch (error) {
      console.error('Error applying transaction:', error);
      // Attempt to recover by creating a new valid selection
      try {
        const recovery = this.view.state.tr;
        const docSize = this.view.state.doc.content.size;
        const validPos = Math.min(transaction.selection.$from.pos, docSize);
        recovery.setSelection(TextSelection.create(this.view.state.doc, validPos));
        this.view.dispatch(recovery);
      } catch (recoveryError) {
        console.error('Failed to recover from transaction error:', recoveryError);
      }
    }
  }

  // Add a helper method to validate transaction positions
  validateTransaction(tr, docSize) {
    if (!tr || !tr.steps) return false; 
    return tr.steps.every(step => {
      const from = step.from || 0;
      const to = step.to || 0;
      return from >= 0 && to >= 0 && from <= docSize && to <= docSize;
    });
  }


	destroy() {
		if (this.view) {
			this.view.destroy();
			this.view = null;
		}
	}
}


