const WebcamTranscriptSpaceNodeSpec = {
    inline: true,
    group: "inline",
    attrs: {
        startTime: { default: 0 },
        endTime: { default: 0 }
    },
    content: "text*",
    toDOM: function(node) {
        return ["span", { 
            class: "editor-transcriptPanel-transcriptGroup--cameraRecording-space",
            "data-start": node.attrs.startTime,
            "data-end": node.attrs.endTime
        }, 0];
    },
    parseDOM: [{ 
        tag: "span.editor-transcriptPanel-transcriptGroup--cameraRecording-space",
        getAttrs: dom => ({
            startTime: parseFloat(dom.getAttribute("data-start")),
            endTime: parseFloat(dom.getAttribute("data-end"))
        })
    }]
};

export default WebcamTranscriptSpaceNodeSpec;