// const AudioClipNodeSpec = {
// 	group: "block",
// 	attrs: {
// 		id:{default:'defaultId'},
// 		type:{default:'audio'},
// 		startTime: {default:0},
// 		duration:  {default:0},
// 		metadata: { default: {} },
// 		voiceoverPlaybackRate:{default:1},
// 		zIndex:  {default:1},
// 		sceneId:{default:'defaultSceneId'},
// 		requiresUpdate:{default:true},
// 	},
// 	content: "paragraph",
//     isolating:true,
//   	toDOM(node) {return ['transcriptChunk',0]},
//     parseDOM: [{tag: 'transcriptChunk'}],

// };

//  export default AudioClipNodeSpec
 




 const AudioClipNodeSpec = {
	attrs: {
		id:{default:'defaultId'},
		type:{default:'audio'},
		relativeStartTime: {default:0},
		pinnedStartTime:{default:null},
		parentWebcamClip:{default:null},
		duration:  {default:0},
		metadata: { default: {} },
		voiceoverPlaybackRate:{default:1},
		zIndex:  {default:1},
		clipIndex:{default:0},
		indexInParentClip:{default:0},
		sceneId:{default:'defaultSceneId'}
	},
	
};

 export default AudioClipNodeSpec
 