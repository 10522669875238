

class SlideElement {


	constructor({ id, type,metadata, width,height,x,y,zIndex,bottomMargin,rightMargin},slideClip) {
		this.id = id
		this.type = type// text,image
		this.width = width
		this.height = height
		this.x = x 
		this.y = y
		this.metadata = metadata
		this.slideClip = slideClip
		this.bottomMargin=bottomMargin
		this.rightMargin=rightMargin
	}


	updateMetadata(newMetadata){
		this.metadata = {...this.metadata,...newMetadata}
	}

	 updateDimensions(newDimensions){
	 	this.width = newDimensions.width
		this.height = newDimensions.height
		this.x = newDimensions.x 
		this.y = newDimensions.y
	 }


	 updateElementField(field,value){
	 	this[field]=value
	 }




	 get zIndex() {
		return this.metadata.zOrder + 5
	}



	 get startTime() {
		//return this.slideClip.startTime + 0.1
		return Math.min(this.slideClip.startTime + this.delay,this.slideClip.startTime+this.slideClip.duration)
		//return this.slideClip.startTime + this.delay
	}

	get duration(){
		return this.slideClip.endTime - this.startTime
	}


	get endTime() {
		return this.slideClip.endTime
	}


	 get delay() {
        if (!this.slideClip || !this.slideClip.elements) {
            return 0;
        }

        const elements = this.slideClip.elements;
        const currentAnimationIndex = this.metadata.animationOrderIndex;

        if (currentAnimationIndex === undefined) {
            return 0;
        }

        let totalDelay = 0;
        for (const element of elements) {
            if (element.metadata.animationOrderIndex < currentAnimationIndex) {
                totalDelay += element.metadata.enterDelay || 0;
            }
        }

        totalDelay += this.metadata.enterDelay || 0;

        return totalDelay;
    }


}



export { SlideElement };
