import React from 'react';
import { getOrgBrand } from '../../../utils/brands/getOrgBrand';
import { getTextColorForBackgroundId } from '../../../utils/brands/getTextColorForBackgroundId';
import { getTextColorForId } from '../../../utils/brands/getTextColorForId';
import DPColorPicker from './DPColorPicker';
import {getOrgDomain} from '../../../utils/getOrgDomain'

const EditorDetailPanelTextColorPicker = ({ textColorId, background, handleSelectTextColor }) => {
  const orgBrand = getOrgBrand();
  
  let forceInlineIds
  if(getOrgDomain()=='clay.com'){
    forceInlineIds = ['5', '8', '12', '16'] // nice clay colors
  }

  const inlinePreviewCount = 4; // Number of colors to show inline if forceInlineIds is not provided

  const getColorStyle = (colorId) => {
    if (colorId === 'auto') {
      const autoColor = getTextColorForBackgroundId(background.id);
      return { background: autoColor.rgba };
    }
    const color = getTextColorForId(colorId);
    return { background: color.rgba };
  };

  let inlineColors = ['auto'];
  let remainingColors = [];

  if (forceInlineIds && Array.isArray(forceInlineIds)) {
    // Use forceInlineIds for inline buttons
    inlineColors = ['auto', ...forceInlineIds.map(String)];
    remainingColors = orgBrand.textColors
      .filter(color => !forceInlineIds.map(String).includes(String(color.id)))
      .map(color => ({
        value: color.rgba,
        type: 'solid',
        id: String(color.id)
      }));
  } else {
    // Use the original logic if forceInlineIds is not provided
    inlineColors = ['auto', ...orgBrand.textColors.slice(0, inlinePreviewCount).map(color => String(color.id))];
    remainingColors = orgBrand.textColors.slice(inlinePreviewCount).map(color => ({
      value: color.rgba,
      type: 'solid',
      id: String(color.id)
    }));
  }

  return (
    <div className='dpTextColorPicker'>
      {inlineColors.map((colorId) => {
        if (colorId === 'auto') {
          return (
            <button 
              key={colorId}
              data-active-state={textColorId === 'auto' ? 'true' : 'false'} 
              className='dpTextColorPicker-autoBtn'
              onClick={() => handleSelectTextColor(null)}
            >
              <div className='dpTextColorPicker-autoBtn-primaryLabel'>
                Auto Color
              </div>              
            </button>
          );
        } else {
          return (
            <button 
              key={colorId}
              data-active-state={textColorId === colorId ? 'true' : 'false'} 
              className='dpTextColorPicker-manualColorBtn'
              onClick={() => handleSelectTextColor(colorId)}
            >
              <div 
                className='dpTextColorPicker-manualColorBtn-inner' 
                style={getColorStyle(colorId)}
              />
              <div className='dpTextColorPicker-manualColorBtn-innerBorder' />
            </button>
          );
        }
      })}
      <div className='dpTextColorPicker-tinyVSpacer' />
      {remainingColors.length > 0 && (
        <DPColorPicker 
          activeColorId={textColorId}
          onChange={handleSelectTextColor}
          options={remainingColors}
          extendSelection
          textColors
        />
      )}
    </div>
  );
};

export default EditorDetailPanelTextColorPicker;