import React, { useState, useEffect } from 'react';
import Icon from '../../misc/Icon';
import * as ContextMenu from '@radix-ui/react-context-menu'
import SlidePreview from '../../slidePreview/SlidePreview'
import {archiveSlideTemplate,restoreSlideTemplate} from '../../../actions/slideTemplates'
import { useDispatch } from 'react-redux';

const TemplateBarTemplate = (props) => {
  const {template,useSlideTemplate,activeBackgroundId,displayWidth, disabled, popover}=props
  const dispatch = useDispatch();

  const handleArchiveTemplate = () => {    
    dispatch(archiveSlideTemplate(template.id));
  };

  const handleRestoreTemplate = () => {    
    dispatch(restoreSlideTemplate(template.id));
  };

  let sidePadding = 4
  if(popover){
    sidePadding = 5
  }

  const handleClick = () => {
    if (!disabled) {
      useSlideTemplate(template);
    }
  };

  return (    
    <ContextMenu.Root>
      <ContextMenu.Trigger asChild>
        <button style={{width: `${displayWidth}px`, paddingLeft: `${sidePadding}px`, paddingRight: `${sidePadding}px`}} data-disabled-state={disabled ? 'true' : 'false'} className='editor-templateBar-template' onClick={handleClick}>      
          {/* {template.id} */}
          <div style={{width: `${displayWidth - sidePadding - sidePadding}px`}} className='editor-templateBar-template-slidePreviewContainer'>
            <SlidePreview 
              slideData={template.content}
              displayWidth={displayWidth - sidePadding - sidePadding}
              activeBackgroundId={activeBackgroundId}
            />
          </div>
          <div className='editor-templateBar-template-hoverBorder' />
        </button>    
      </ContextMenu.Trigger>
      <ContextMenu.Portal>
        <ContextMenu.Content collisionPadding={10} className='contextMenu forceDarkTheme'>                                
          {!props.archived && 
            <ContextMenu.Item onSelect={handleArchiveTemplate}>          
              Archive Template          
            </ContextMenu.Item>
          }
          {props.archived && 
            <ContextMenu.Item onSelect={handleRestoreTemplate}>          
              Unarchive Template          
            </ContextMenu.Item>
          }
        </ContextMenu.Content>
      </ContextMenu.Portal>
    </ContextMenu.Root>    
  );
};



export default TemplateBarTemplate;
