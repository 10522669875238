import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import { useLoader, useThree, useFrame, Group } from '@react-three/fiber';
import * as THREE from 'three';
import CameraControls from 'camera-controls'
import { Text, Plane } from '@react-three/drei'
import {getTextColorForId} from '../../../utils/brands/getTextColorForId'
import {getTextColorForBackgroundId} from '../../../utils/brands/getTextColorForBackgroundId'
import { useSpring, animated, interpolate } from '@react-spring/three'
import SlideEditorCanvasPreviewFitImage from './SlideEditorCanvasPreviewFitImage';
import SlideEditorCanvasPreviewFillImage from './SlideEditorCanvasPreviewFillImage';

const SCENE_WIDTH = 1920;
const SCENE_HEIGHT = 1080;

CameraControls.install({ THREE });



// Create an animated version of the group
const AnimatedGroup = animated('group');

const SlideEditorCanvasPreviewLetter = ({letterValue, position, fontFamily, fontWeight, fontSize, devMode, textColor, isDndDraggingAnyElement, isDndDraggingThisElement, maxOpacity}) => {
  const [x, y, z] = position;

  const spring = useSpring({
    position: [x, y, z],
    config: { tension: 1000, friction: 60 },
    immediate: !isDndDraggingAnyElement && !isDndDraggingThisElement,
  });

  let opacity = 1 * maxOpacity
  if(devMode){
    opacity = 0.75 * maxOpacity
  }
  // if(isDndDraggingAnyElement){
  //   opacity = 0.5 * maxOpacity
  // }
  // if(isDndDraggingThisElement){
  //   opacity = 0 * maxOpacity
  // }
  

  return (
    <AnimatedGroup position={spring.position}>
      <Text
        anchorX="left"
        anchorY="top"
        fontSize={fontSize}                
        textAlign="left"        
        font={`../fonts/${fontFamily}/${fontFamily}-${fontWeight}.woff`}
      >
        <meshBasicMaterial color={devMode ? "red" : textColor} opacity={opacity}/>
        {letterValue}
      </Text>
    </AnimatedGroup>
  );
};





const SlideEditorCanvasPreview = ({ slide, dragDndElementId,background,promotedTextElement }) => {
  const { camera, gl, scene } = useThree();
  const cameraControlsRef = useRef(null);

  // Initialize camera controls on mount
  useEffect(() => {
    cameraControlsRef.current = new CameraControls(camera, gl.domElement);
  }, [camera, gl.domElement]);

  useEffect(() => { //component did mount
    const box = new THREE.Box3().setFromCenterAndSize(
      new THREE.Vector3(0, 0, 0), // The main plane is centered at (0, 0, 0)
      new THREE.Vector3(SCENE_WIDTH, SCENE_HEIGHT, 0) // The size of the main plane
    );
    // Fit the camera to the bounding box of the main plane
    cameraControlsRef.current.fitToBox(box, false);
  }, []);

  // Animate camera controls on each frame
  useFrame((_, delta) => {
    if(cameraControlsRef && cameraControlsRef.current ){
      cameraControlsRef.current.update(delta);
    }
  });

  // console.log('slideElements')
  // console.log(slide.elements)

  const isDndDraggingAnyElement = dragDndElementId

  const devMode = false  
 const SCENE_WIDTH = 1920;
  const SCENE_HEIGHT = 1080;

  return (
    <>
      {slide.elements.map((slideElement) => {
        if (slideElement.type === 'text' && promotedTextElement === slideElement.id) {
          const { metadata, x, y, width, height } = slideElement;
          const { lettersArray, textColorId, textStyle } = metadata;

          //console.log('elementX', x);

          const textColorIsAuto = !slideElement.metadata.textColorId;
          let textColor = textColorIsAuto
            ? getTextColorForBackgroundId(background.id).rgba
            : getTextColorForId(slideElement.metadata.textColorId).rgba;

          const textProperties = slideElement.metadata.textProperties;
          const isDndDraggingThisElement = dragDndElementId === slideElement.id;

          // Calculate the group's position in Three.js coordinates
          const normalizedElementX = x + width / 2 - SCENE_WIDTH / 2;
          const normalizedElementY = -y - height / 2 + SCENE_HEIGHT / 2;


          const isFontLoaded = slideElement.isFontLoaded

          if(isFontLoaded){

          return (
            <group key={slideElement.id} position={[normalizedElementX, normalizedElementY, 0]}>
              {/* for dev }
              <Plane args={[width, height]} position={[0, 0, 1]}>
                <meshBasicMaterial color="red" transparent opacity={0.2} />
              </Plane>
              */}

              {lettersArray.map((letterObj, index) => {
                // console.log('letterObj.rect', letterObj.rect);
                // console.log('letterNormalLeft', letterObj.rect.normalLeft);
                return (
                  <SlideEditorCanvasPreviewLetter
                    key={`${slideElement.id}-letter-${index}-${isDndDraggingAnyElement}`}
                    letterValue={letterObj.letter}
                    position={[
                      letterObj.rect.normalLeft,
                      letterObj.rect.normalTop,
                      0
                    ]}
                    fontFamily={textProperties.fontFamily}
                    fontWeight={textProperties.fontWeight}
                    fontSize={textProperties.fontSize}
                    devMode={devMode}
                    textColor={letterObj.colorId ? getTextColorForId(letterObj.colorId).rgba : textColor}
                    isDndDraggingAnyElement={isDndDraggingAnyElement}
                    isDndDraggingThisElement={isDndDraggingThisElement}
                    maxOpacity={letterObj.opacity || slideElement.metadata.textOpacity}
                  />
                );
              })}
            </group>
          );
        }
        }
        return null;
      })}
    </>
  );
};

export default SlideEditorCanvasPreview