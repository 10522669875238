import React, { useState, useEffect, useRef } from 'react';
import * as ContextMenu from '@radix-ui/react-context-menu'
import LibraryProjectContextMenuContents from './LibraryProjectContextMenuContents'
import LibraryProjectDropdownMenuContents from './LibraryProjectDropdownMenuContents'
import VideoPreview from '../../uiKit/videoPreview/VideoPreview'
import {formatTimeAgo} from '../../../utils/dateFormatters/formatTimeAgo'
import LibraryProjectItemPreview from '../old/LibraryProjectItemPreview'
import {openProject} from '../../../utils/projectUtils/openProject'
import { useHistory } from 'react-router-dom';
import {getProjectLoadingStatus} from '../../../utils/projectUtils/getProjectLoadingStatus'
import {archiveProject} from '../../../utils/projectUtils/archiveProject'
import {duplicateProject} from '../../../utils/projectUtils/duplicateProject'
import LibraryProjectItemLockedBanner from '../old/LibraryProjectItemLockedBanner'
import {getUserForId} from '../../../utils/getUserForId'
import {getCurrentUserId} from '../../../utils/getCurrentUserId'
import Icon from '../../misc/Icon';
import { useSpring, animated } from 'react-spring';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import * as Popover from '@radix-ui/react-popover';
import {saveProjectName} from '../../../utils/projectUtils/saveProjectName' 
import { useMoveToCollectionPopover } from '../../uiKit/moveToCollection/useMoveToCollectionPopover';
import {formatDuration} from '../../../utils/dateFormatters/formatDuration'


// const formatDuration = (durationInSeconds) => {
//   const minutes = Math.floor(durationInSeconds / 60);
//   const seconds = durationInSeconds % 60;
//   return `${minutes}:${seconds.toString().padStart(2, '0')}`;
// };

function LibraryProjectCard({ width, id, project, toggleMuted, isMuted }) {
  const history = useHistory()
 // const [showMoveToPopover, setShowMoveToPopover] = useState(false);

  const {
    showMoveToPopover,
    setShowMoveToPopover,
    pendingChanges,
    handleCollectionChange,
    saveMoveToCollectionChanges
  } = useMoveToCollectionPopover(project);

  const [showMoveToPopoverDropdown, setShowMoveToPopoverDropdown] = useState(false);
  const {name,duration} = project

  const handleArchive = () => {
    archiveProject(project.id); // Call the archive function with the project ID
  };

  const handleDuplicate = () => {
    duplicateProject(project.id); // Call the archive function with the project ID
  };

  const isDraft = project.is_private

  const isLoading = getProjectLoadingStatus(project.id)  
  let isLocked = false
  let lockedUserFirstName 
  let lockedAvatarUrl 

  if(project && project.project_access_user){
    const lockedUserObj = getUserForId(project.project_access_user)
    if(lockedUserObj){
      lockedUserFirstName=lockedUserObj.first_name 
      lockedAvatarUrl=lockedUserObj.profile_image_url
      if(lockedUserObj.id !== getCurrentUserId()){
        isLocked=true
      }
    }
  }

  let authorName
  let lastEditedByUser = getUserForId(project.updated_by)
  if(lastEditedByUser){
    authorName = lastEditedByUser.id === getCurrentUserId() ? "You" : lastEditedByUser.first_name
  }

  // Loading animation
  const widthLoadingIndicator = 32

  const labelLoadingEffect = useSpring({
    opacity: isLoading ? 0.25 : 1,
    transform: isLoading ? `translateX(${widthLoadingIndicator}px)` : 'translateX(0px)',
    config: { tension: 400, friction: 32 },
    delay: isLoading ? 0 : 120
  });

  const subLabelLoadingEffect = useSpring({
    opacity: isLoading ? 0.25 : 0.5,    
    transform: isLoading ? `translateX(${widthLoadingIndicator}px)` : 'translateX(0px)',
    config: { tension: 400, friction: 32 },    
    delay: isLoading ? 0 : 120
  });


  const indicatorLoadingEffect = useSpring({
    opacity: isLoading ? 1 : 0,
    transform: isLoading ? 'scale(1)' : 'scale(0.5)',
    config: { tension: 400, friction: 32 },
    delay: isLoading ? 0 : 0
  });

  
  // TEMP
  const tempPreviewWidth = width - 16
  const sceneWidth = 1920
  const sceneHeight = 1080
  const scalar = tempPreviewWidth / sceneWidth

  const borderRadiusOriginal = 3
  const scaledBorderRadius = borderRadiusOriginal / scalar

  const [dropdownOpen, setDropdownOpen] = useState(false)  

  const [promoteZIndex, setPromoteZIndex] = useState(false)  

  useEffect(() => {
    if (dropdownOpen) {
      // Immediately promote z-index when dropdown opens
      setPromoteZIndex(true);
    } else {
      saveMoveToCollectionChanges() 
      // Delay setting promoteZIndex to false when dropdown closes
      const timer = setTimeout(() => {
        setPromoteZIndex(false);
      }, 100);

      // Clean up the timer if the component unmounts or dropdownOpen changes again
      return () => clearTimeout(timer);
    }
  }, [dropdownOpen]);

  
  
    const [projectName, setProjectName] = useState(project.name);
    const [isRenaming, setIsRenaming] = useState(false);
    const inputRef = useRef(null);
    const renameTimeoutRef = useRef(null);

    const handleNameChange = (e) => {
      setProjectName(e.target.value);
    };

    const handleRenameClick = () => {
      // Close the dropdown menu
      setDropdownOpen(false);
      
      // Set a small timeout before opening the rename Popover
      renameTimeoutRef.current = setTimeout(() => {
        setIsRenaming(true);
      }, 120);
    };

    const handleRenameClose = (event) => {
      saveProjectName(project.id,projectName)
      // Prevent closing if the click is inside the input
      if (event && inputRef.current && inputRef.current.contains(event.target)) {
        return;
      }
      setIsRenaming(false);
      // Here you might want to save the new project name
    };

    useEffect(() => {
      if (isRenaming && inputRef.current) {
        inputRef.current.focus();
        inputRef.current.select();
      }

      // Cleanup function
      return () => {
        if (renameTimeoutRef.current) {
          clearTimeout(renameTimeoutRef.current);
        }
      };
    }, [isRenaming]);

    const handleInputKeyPress = (e) => {
      if (e.key === 'Enter') {
        handleRenameClose();
      }
    };



  const onCardClick = (e) => {
    if(!isLocked){
    if (!isRenaming && !isLoading && !dropdownOpen) {
      if (e.metaKey) {
        openProject(project.id);
      } else {
        openProject(project.id, history);
      }
    }
    }
  };


  const onContextMenuChange = (open) =>{
    if (open){
      setShowMoveToPopover(false)
    }
    if(!open){
      saveMoveToCollectionChanges()
    }
  }

  
  return (
    <ContextMenu.Root key={id} onOpenChange={onContextMenuChange}>
      <ContextMenu.Trigger asChild>
        <div 
          style={{ width: `${width}px` }}
          data-input-active={isRenaming ? 'true' : 'false'}
          className={'libraryProjectCardOuter ' + (promoteZIndex ? 'libraryProjectCardOuter--promoteZIndex' : '')}
          onClick={onCardClick} 
          //onClick={() => setIsLoading(!isLoading)}
        >
          <div className='libraryProjectCard'>
            <div className='libraryProjectCard-preview'> 
              {/*}
              <div className='libraryProjectCard-preview-duration'>
                {formatDuration(duration)}
              </div>
              */}
              <div style={{width: `${sceneWidth * scalar}px`, height: `${sceneHeight * scalar}px`}} className='libraryPage-project-previewUI'>
                
                {isLoading && 
                  <div className='libraryPage-project-previewUI-spinnerContainer'>
                    <div className='libraryPage-project-previewUI-spinner' />
                  </div>
                }
                {isLocked && 
                  <LibraryProjectItemLockedBanner
                    isLocked={isLocked}
                    lockedUserFirstName={lockedUserFirstName}
                    lockedAvatarUrl={lockedAvatarUrl}
                  />
                }                
              </div>

              <div style={{width: `${sceneWidth}px`, height: `${sceneHeight}px`, transform: `scale(${scalar})`}} className='libraryPage-project-previewInnerContainer'>                
                <LibraryProjectItemPreview 
                  project={project}           
                  scaledBorderRadius={scaledBorderRadius} 
                />
              </div>
            </div>
            <div className='libraryProjectCard-footer'>
              <animated.div style={{ ...indicatorLoadingEffect, width: `${widthLoadingIndicator}px` }} className='libraryProjectCard-footer-loadingIndicatorContainer'>
                <div className='libraryProjectCard-footer-loadingIndicator'>
                  <div className='libraryProjectCard-footer-loadingIndicator-spinner' />
                </div>
              </animated.div>
        
              <div className='libraryProjectCard-footer-leftContainer'>
                
                    <animated.div style={labelLoadingEffect} className='libraryProjectCard-footer-label'>
                      <div data-input-active={isRenaming ? "true" : "false"} className='libraryProjectCard-footer-label-name'>                  
                        <div className='libraryProjectCard-footer-label-name-name'>
                          {projectName}
                        </div>                  
                        {isDraft && 
                          <div className='libraryProjectCard-footer-label-name-isDraft'>
                            Draft
                          </div>
                        }
                      </div>
                      <div data-input-active={isRenaming ? "true" : "false"} className='libraryProjectCard-footer-label-inputContainer'>
                        <input
                          ref={inputRef}
                          type="text"
                          value={projectName}
                          onChange={handleNameChange}
                          className='libraryProjectCard-footer-label-input'
                          onClick={(e) => e.stopPropagation()}
                          onKeyPress={handleInputKeyPress}
                        />
                      </div> 

                    </animated.div>
                  
                  
                                           
                
                
                {isRenaming && 
                  <div onClick={()=> handleRenameClose()} className='libraryProjectCard-rename-bgClick' />
                }  
                

                {!isDraft && 
                  <animated.div style={subLabelLoadingEffect} className='libraryProjectCard-footer-sub'>
                    {authorName} · {formatTimeAgo(project.updated_at)}
                  </animated.div>
                }
                {isDraft && 
                  <animated.div style={subLabelLoadingEffect} className='libraryProjectCard-footer-sub'>
                    {formatTimeAgo(project.updated_at)}
                  </animated.div>
                }
              </div>

              {!isLoading &&
              <div className='libraryProjectCard-footer-rightContainer'>
                <DropdownMenu.Root 
                  modal={true}
                  open={dropdownOpen}
                  onOpenChange={(open) => {
                    setDropdownOpen(open);
                    setTimeout(() => {
                      setShowMoveToPopoverDropdown(false);
                    }, 75);
                  }}> 
                  <DropdownMenu.Trigger asChild>
                    <button onClick={()=> setDropdownOpen(!dropdownOpen)} className='libraryProjectCard-footer-dropdownMenuBtn'>  
                      <div className='libraryProjectCard-footer-dropdownMenuBtn-inner'>
                        <Icon name='ellipses' />                                          
                      </div>
                    </button>
                  </DropdownMenu.Trigger>                       
                  <LibraryProjectDropdownMenuContents
                    alignOffset={-10}
                    sideOffset={-20}
                    setShowMoveToPopover={setShowMoveToPopoverDropdown}
                    showMoveToPopover={showMoveToPopoverDropdown}
                    handleArchive={handleArchive}
                    handleDuplicate={handleDuplicate}
                    project={project}
                    renameProject={handleRenameClick}
                    pendingChanges={pendingChanges}
                    handleCollectionChange={handleCollectionChange}
                    closeDropdown={()=> setDropdownOpen(false)}                    
                  />                                   
                </DropdownMenu.Root>
              </div>
              }
            </div>
          </div>        
        </div>
      </ContextMenu.Trigger>
      <ContextMenu.Portal>
        <LibraryProjectContextMenuContents
          setShowMoveToPopover={setShowMoveToPopover}
          showMoveToPopover={showMoveToPopover}
          handleArchive={handleArchive}
          handleDuplicate={handleDuplicate}
          project={project}
          pendingChanges={pendingChanges}
          handleCollectionChange={handleCollectionChange}
          renameProject={()=>handleRenameClick()}          
        />              
      </ContextMenu.Portal>
    </ContextMenu.Root>           
  );
}

export default LibraryProjectCard;
