
////// REGEX FOR NUMBER
// {
//   prefix: e.g.$
//   value: 3,
//   suffix: e.g. %, /month
//   preLabel:e.g. up to,
//   postLabel:e.f. faster
// }

// Extract preLabel, number (with prefix and suffix), and postLabel from the string
export const extractData = (str) => {
  const startOrWhitespace = "(?:^|\\s)";// Start of the string or a whitespace character
  const prefix = "([^\\d\\s]*)";// Captures any non-digit, non-space characters (prefix)
  const initialDigits = "\\d{1,3}";// Matches 1 to 3 digits
  const commaGroups = "(,\\d{3})*";// Optionally matches groups of a comma followed by three digits
  const decimalPart = "(\\.\\d+)?";// Optionally matches a decimal point followed by one or more digits
  

  // // Combines the parts for capturing the numeric value
  // const numericValue = `(${initialDigits}${commaGroups}${decimalPart})`;

  //const numericValue = "(\\d{1,3}(?:,\\d{3})*|\\d+)"; //handle morethan 3 numbers
 // const numericValue = `(${initialDigits}${commaGroups}${decimalPart}|\\d+(\\.\\d+)?)`;
 
 //const numericValue = "(\\d+(?:,\\d{3})*(?:\\.\\d+)?)"; //this works but breaks while typing e.g 10,00
 const numericValue = "(\\d+(?:,\\d+)*(?:\\.\\d+)?)";
 

  //const numericValue = "(\\d{1,3}(?:,\\d{3})*|\\d+)(\\.\\d+)?";
  // Optionally captures non-digit, non-space characters (suffix)
  const suffix = "([^\\d\\s]+)?";
  // Captures any text following the number (post-label), starting with a space or end of the string
  //const postLabel = "(\\s.*|$)";
  const postLabel = "(?:\\s+(.*))?$";
  // Combine all parts to form the final regex
  const regexPattern = `${startOrWhitespace}${prefix}${numericValue}${suffix}${postLabel}`;
  const regex = new RegExp(regexPattern);
  // Use the constructed regex to match the string
  const match = str.match(regex);
  // console.log(match);
  // Process the match result



  return match ? {
    aboveLabel: str.substring(0, match.index).trim(),
    value: {
      prefix: match[1].trim(),
      number: parseFloat(match[2].replace(/,/g, '')),
      //number: match[2],
      suffix: match[3] ? match[3].trim() : '',
    },
     belowLabel: match[4] ? match[4].trim() : ''
  } : null;
};
