import React from 'react'
import Icon from '../../components/misc/Icon'


// function formatDuration(seconds) {
//   if(seconds==0){ //empty segment
//     return [0, 0].map(val => val.toString().padStart(2, '0')).join(':');
//   }else{
//     const wholeSeconds = Math.floor(seconds); // Round down to the nearest whole number
//     const minutes = Math.floor(wholeSeconds / 60);
//     const remainingSeconds = Math.max(wholeSeconds % 60,1) //minimum 1 second
//     return [minutes, remainingSeconds].map(val => val.toString().padStart(2, '0')).join(':');
//   }
// }

class TranscriptChunkLeft extends React.Component{  
  render(){   
    const {requiresUpdate,transcriptChunkCount} = this.props
    
    const isOnlyClip = transcriptChunkCount<2


    return ( 
      <>
      <div className={'editor-transcriptPanel-transcriptChunk-left ' + (requiresUpdate ? ' editor-transcriptPanel-transcriptChunk-left--requiresUpdate ' : ' editor-transcriptPanel-transcriptChunk-left--ready ')}>      

      {/*}
        <button className='editor-transcriptPanel-transcriptChunk-left-btn'>
          <div className='editor-transcriptPanel-transcriptChunk-left-btn-inner'>

          </div>

        </button>
       {/* <div className='editor-transcriptPanel-transcriptChunk-duration'>
          {formatDuration(clipDuration)}
        </div>
        
        <div className='editor-transcriptPanel-transcriptChunk-startArrow'>
          <Icon name='playbackPlay'/>
        </div>
        */}

      </div>

      {/*}
      {isOnlyClip && 
        <div className='editor-transcriptPanel-transcriptChunk-enterKeyTooltip'>
          New clip with Enter ⏎
        </div>
      }
      */}

      </>

        

    )
  }
}

export default TranscriptChunkLeft
