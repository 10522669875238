import { Decoration, DecorationSet } from "prosemirror-view"
import { Plugin } from "prosemirror-state";
import ReactDOM from 'react-dom'
import ButtonReactComponentTest from './ButtonReactComponentTest'




const paragraphButtonsPlugin = new Plugin({
  state: {
    init(_, { doc }) {
      return createDecorations(doc)
    },
    apply(tr, old) {
      return tr.docChanged ? createDecorations(tr.doc) : old
    }
  },
  props: {
    decorations(state) {
      return this.getState(state)
    }
  }
})

function findLastWordPos(text) {
  const words = text.split(/[\s-]+/);
  const lastWord = words[words.length - 1];
  return text.lastIndexOf(lastWord);
}

function createDecorations(doc) {
  const decorations = []
  doc.descendants((node, pos) => {
    if (node.type.name === "paragraph") {
      const endPos = pos + node.nodeSize
      const text = node.textContent
      const lastWordPos = pos + findLastWordPos(text)
      
      decorations.push(
        Decoration.inline(lastWordPos, endPos, {
          class: "widget-highlight",
          //style: "background-color: rgba(255,0,0,0.15); border-radius: 2px;"
        })
      )
      
      decorations.push(
        Decoration.widget(endPos-1, () => {
          const redDiv = document.createElement("div")    

          ReactDOM.render(<ButtonReactComponentTest
              />, redDiv)      
          return redDiv
        })
      )
    }
  })
  return DecorationSet.create(doc, decorations)
}

export default paragraphButtonsPlugin