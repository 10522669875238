export const findGroupById = (rootGroup, groupId) => {
		if (rootGroup.id === groupId) {
			return rootGroup;
		}
		for (let child of rootGroup.children) {
			if (child.isLayoutGroup) {
				const found = findGroupById(child, groupId);
				if (found) return found;
			}
		}
		return null;
	}