import React, { useEffect, useState } from 'react';
import { Text } from '@react-three/drei';
import { SpringAnimator } from '../../../three/utils/animations/SpringAnimator';
import renderOrders from '../../../three/renderOrders'

const FPS = 60; // Frames per second
const calculateAnimationDuration = 10;

const CHNumberPreLabel = ({ preLabel, fontSize, fontWeight, fontFamily, targetYPos, maxOpacity, time, animationType, isHidden, color }) => {
  
  const springParams = { mass: 1, stiffness: 180, damping: 50 };
  const [labelOpacityFrames, setLabelOpacityFrames] = useState([]);
  const [labelYPosFrames, setLabelYPosFrames] = useState([]);  

  let animateDelay = 0.5

  if(animationType === 'fadeIn'){
    animateDelay = 0
  }

  const adjustedTime = time - animateDelay

  const initialYPos = targetYPos - 20;

  useEffect(() => {
    const labelOpacitySpring = new SpringAnimator(springParams.mass, springParams.stiffness, springParams.damping, 0);
    const labelYPosSpring = new SpringAnimator(springParams.mass, springParams.stiffness, springParams.damping, initialYPos);

    const opacityFrames = [];
    const yPosFrames = [];

    for (let frame = 0; frame < FPS * calculateAnimationDuration; frame++) {
      labelOpacitySpring.setTarget(1); // Target opacity
      labelOpacitySpring.simulate(1000 / FPS);
      opacityFrames.push(labelOpacitySpring.position);

      labelYPosSpring.setTarget(targetYPos); // Target Y position
      labelYPosSpring.simulate(1000 / FPS);
      yPosFrames.push(labelYPosSpring.position);
    }

    setLabelOpacityFrames(opacityFrames);
    setLabelYPosFrames(yPosFrames);

  }, [targetYPos]);

  const frameIndex = Math.floor(adjustedTime * FPS);

  const animatedLabelOpacity = labelOpacityFrames[frameIndex] || 0;
  const animatedYPos = labelYPosFrames[frameIndex] || initialYPos;

  let labelOpacity = animatedLabelOpacity * maxOpacity;
  let yPos = animatedYPos

  if(animationType === 'none'){
    labelOpacity = maxOpacity
    yPos = targetYPos
  }

  if(animationType === 'fadeIn'){    
    yPos = targetYPos
  }


  return (
    <> 
      <group scale={[1, 1, 1]}>    
        <Text
          anchorX="center"
          anchorY="bottom"
          lineHeight={1.2}
          fontSize={fontSize}
          textAlign="center"
          font={`../fonts/${fontFamily}/${fontFamily}-${fontWeight}.woff`}
          position={[0, yPos, 0]}
          maxWidth={1200}
          letterSpacing={-0.02}          
          renderOrder={renderOrders.graphObject}
        >
          <meshBasicMaterial transparent={true} color={color} opacity={labelOpacity} />
          {preLabel}
        </Text>
      </group>
    </>
  );
};

export default CHNumberPreLabel;
