
export function findNextNodePosition(doc, startPos, endPos, nodeTypes, direction = 'forward') {
    let foundPos = null;
    const positions = [];

    // Collect all matching nodes between the positions
    doc.nodesBetween(
        Math.min(startPos, endPos),
        Math.max(startPos, endPos),
        (node, pos) => {
            if (nodeTypes.includes(node.type.name)) {
                positions.push({ 
                    pos: pos,
                    nodeSize: node.nodeSize,
                });
            }
        }
    );

    if (direction === 'forward') {
        // Find the first position after startPos
        for (let i = 0; i < positions.length; i++) {
            if (positions[i].pos > startPos) {
                foundPos = positions[i].pos + positions[i].nodeSize - 2;
                break;
            }
        }
    } else {
        // Find the last position before startPos
        for (let i = positions.length - 1; i >= 0; i--) {
            if (positions[i].pos < startPos) {
                foundPos = positions[i].pos + positions[i].nodeSize - 2;
                break;
            }
        }
    }

    return foundPos;
}