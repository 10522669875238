export function convertSlideNodeToJSON(slideNode) {
  const json = {
    id: slideNode.attrs.id,
    type: slideNode.attrs.type,
    duration: slideNode.attrs.duration,
    relativeStartTime:slideNode.attrs.relativeStartTime,
    layout: convertLayoutNodeToJSON(slideNode.content.content[0]),
    sceneId:slideNode.attrs.sceneId,
    metadata: {
      ...slideNode.attrs.metadata,
    },
    zIndex: slideNode.attrs.zIndex
  };

  return json;
}

function convertLayoutNodeToJSON(layoutNode) {
  const json = {
    ...layoutNode.attrs,
    children: layoutNode.content.content.map(childNode => {
      if (childNode.type.name === 'layoutGroup') {
        return convertLayoutNodeToJSON(childNode);
      } else if (childNode.type.name === 'slideElement') {
        return convertElementNodeToJSON(childNode);
      }
    }),
    isLayoutGroup: true
  };

  return json;
}

function convertElementNodeToJSON(elementNode) {
  const json = {
    ...elementNode.attrs
  };

  if (elementNode.type.name === 'text') {
    json.text = elementNode.content.content[0].text;
  }

  return json;
}

