import React from 'react'
import { createRoot } from "react-dom/client";
import ReactDOM from 'react-dom'
import TranscriptChunkLeft from './TranscriptChunkLeft'
import TranscriptChunkRight from './TranscriptChunkRight'
 

//contentEditable false on extra dom bits to make sure it doesnt mess up arrow keys/selection

class WebcamRecordingChunkNodeView {

	constructor(node, view, getPos,handleMouseOverTranscriptChunk,handleMouseLeaveTranscriptChunk) {
		this.getPos=getPos
		this.renderReactComponents=this.renderReactComponents.bind(this)

		this.handleMouseOverTranscriptChunk=handleMouseOverTranscriptChunk
		this.handleMouseLeaveTranscriptChunk=handleMouseLeaveTranscriptChunk

		this.dom = document.createElement("div")
		this.dom.classList.add('editor-transcriptPanel-transcriptChunk')
		// this.dom.id=`transcript-chunk-${node.attrs.clipId}`
		// this.dom.onmouseover = () => this.handleMouseOverTranscriptChunk(node.attrs.clipId)
		// this.dom.onmouseleave = () => this.handleMouseLeaveTranscriptChunk(node.attrs.clipId)
		this.dom.onmouseover = this.handleMouseOver.bind(this)
		this.dom.onmouseleave = this.handleMouseLeave.bind(this)		

		this.contentDOM = document.createElement("div");
		this.contentDOM.classList.add('editor-transcriptPanel-transcriptChunk-contents')
		this.dom.appendChild(this.contentDOM)		

		this.node=node

		this.renderReactComponents()
	}


	handleMouseOver(){
		this.handleMouseOverTranscriptChunk(this.node.attrs.clipId)
	}
	handleMouseLeave(){
		this.handleMouseLeaveTranscriptChunk(this.node.attrs.clipId)
	}


	renderReactComponents(){
		const textContent=this.node.textContent
		if (textContent.length == 0){
			this.dom.classList.add("editor-transcriptPanel-transcriptChunk--empty")
		}else{
			this.dom.classList.remove("editor-transcriptPanel-transcriptChunk--empty")
		}

		const {requiresUpdate,transcriptChunkCount,clipId} = this.node.attrs

		if (requiresUpdate){
			this.dom.classList.add("editor-transcriptPanel-transcriptChunk--requiresUpdate")
		}else{
			this.dom.classList.remove("editor-transcriptPanel-transcriptChunk--requiresUpdate")
		}
	

	}

	playClip(){
		const {clipId}=this.node.attrs
		this.playClipFromTranscript(clipId)
	}

	update(node,decorations){
		if (this.node.type !== node.type) {
			return false
		}
		const oldIsEmpty=this.node.textContent.length ==0
		const oldRequiresUpdate = this.node.attrs.requiresUpdate
		const oldTranscriptChunkCount = this.node.attrs.transcriptChunkCount

		const newIsEmpty = node.textContent.length ==0

		this.node = node;
		if (oldIsEmpty !== newIsEmpty ||
			oldRequiresUpdate !== node.attrs.requiresUpdate || 
			oldTranscriptChunkCount !== node.attrs.transcriptChunkCount) {
			this.renderReactComponents();
		}
		return true;
	}


	destroy() {
		
	}


}

export default WebcamRecordingChunkNodeView
