import {randomID} from '../../utils/randomID'


const DEFAULT_SKIP_DURATION = 3


export function addSkipSegment(originalSegments, skipTime,clipPlaybackRate) {
    //we want the freeze duration to be the FREEZE_DURATION once playback rate is taken into account
    //e.g. for a 1 second freeze at 2x playback the uncalibrated freeze duration should be 2 seconds 
    const skipDuration = DEFAULT_SKIP_DURATION * clipPlaybackRate 



    const skipSegment = {
        id: randomID(),
        originalStart: skipTime,
        originalEnd: skipTime + DEFAULT_SKIP_DURATION,
        playbackRate: null,
        originalDuration: skipDuration,
        isSkipSegment:true,
        isExpanded:true,
        timeStretch:null
    };

    let adjustedSegments = [];
    let currentTime = 0;

    originalSegments.forEach(segment => {
        let segmentEndTime = segment.originalStart + segment.originalDuration;

        if (segment.originalStart <= skipTime && segmentEndTime >= skipTime) {
            // Segment needs to be split
            if (segment.originalStart < skipTime) {
                // Part of the segment before the freeze
                let firstPart = { ...segment, id: randomID() };
                firstPart.originalEnd = skipTime;
                firstPart.originalDuration = skipTime - firstPart.originalStart;
                adjustedSegments.push(firstPart);
                currentTime = currentTime + firstPart.originalDuration
            }

            // Add the freeze frame

            adjustedSegments.push(skipSegment);
            currentTime = currentTime + skipSegment.newDuration

            // Part of the segment after the freeze
            if (segmentEndTime > skipTime + DEFAULT_SKIP_DURATION) {
                let secondPart = { ...segment, id: randomID() };
                secondPart.originalStart = skipTime + DEFAULT_SKIP_DURATION;
                secondPart.originalDuration = segmentEndTime - secondPart.originalStart;
                adjustedSegments.push(secondPart);
                currentTime = currentTime + secondPart.originalDuration
            }
        } else {// Segment is not affected 
            let newSegment = { ...segment };
            adjustedSegments.push(newSegment);
            currentTime += segment.originalDuration;
        }
    });

    return adjustedSegments.filter(seg => seg.originalDuration > 0); // Filter out any zero-duration segments
}



