import React from 'react'
import TranscriptChunkNodeSpec from './TranscriptChunkNodeSpec'
import SceneHeaderNodeSpec from './SceneHeaderNodeSpec'
import TranscriptGroupNodeSpec from './TranscriptGroupNodeSpec'
import WebcamRecordingChunkNodeSpec from './WebcamRecordingChunkNodeSpec'
import WebcamTranscriptWordNodeSpec from './WebcamTranscriptWordNodeSpec'
import WebcamTranscriptSpaceNodeSpec from './WebcamTranscriptSpaceNodeSpec'

//https://github.com/ProseMirror/prosemirror-schema-basic/blob/master/src/schema-basic.js
// const brDOM = ["br"]

export const nodes = {
  doc: {
   // content: "(sceneHeader transcriptChunk+)+"
    content: "(sceneHeader transcriptGroup+)+"
  },
  paragraph: {
    content: "inline*",
    group: "block",
    parseDOM: [
      {
        tag: "p"
      },
    ],
    toDOM(node) {
      const {nodeId,indentLevel} = node.attrs;
      return ["div",{class:`doc-para`}, 0]
    }},
  transcriptChunk:TranscriptChunkNodeSpec,
  sceneHeader:SceneHeaderNodeSpec,
  // text: {
  //   group: "inline",
  //   marks: "",
  // },
  text: {
    group: "inline",
    marks: "", // Explicitly prevent any marks
    isolating: true // Try to isolate text nodes from parent marks
},
  transcriptGroup:TranscriptGroupNodeSpec,
  webcamRecordingChunk:WebcamRecordingChunkNodeSpec,
  webcamTranscriptWord:WebcamTranscriptWordNodeSpec,
  webcamTranscriptSpace:WebcamTranscriptSpaceNodeSpec

}

export default nodes