import {TimelineClip} from './TimelineClip'
import {calulateVideoTimeFromTimelineTime} from './utils/calulateVideoTimeFromTimelineTime'
import find from 'lodash/find'
import {calculateTrimmedSegments} from './utils/calculateTrimmedSegments'
import sampleTranscript from './sampleTranscript.json'
import {defaultColorAdjustments,defaultWebcamLayout} from '../utils/webcam/webcamConfigs'
import {fetchWebcamVideo} from '../utils/webcam/fetchWebcamVideo'
import {getTranscriptForWebcamCaptureId} from '../utils/webcam/getTranscriptForWebcamCaptureId'
import {updateTranscriptChunks} from '../utils/webcam/updateTranscriptChunks'
import {calculateWebcamSkipRangesFromWords} from '../utils/webcam/calculateWebcamSkipRangesFromWords'
import {calculateWebcamRecordingSegments} from '../utils/webcam/calculateWebcamRecordingSegments'

//Duration is the duration on the timeline
//recording duration is the duration of the underlying video

class WebcamClip extends TimelineClip {

  constructor(options,scene,handleVideoClipLoaded,updateVideoClipSegments){
    super(options,scene)
    this.video = document.createElement('video'); 
    
    
    this.video.muted=false
    
    this.isUploadingVideo=options.isUploadingVideo || false
    this.captureId=options.captureId
    this.segments=options.segments || []
    this.recordingSegments = options.recordingSegments||[]
    this.hasSegments = this.segments.length
    this.hasRecordingSegments = this.recordingSegments.length
    this.clipPlaybackRate = options.clipPlaybackRate || 1
    this.pinnedStartTime = options.pinnedStartTime

    this.clipIndex = options.clipIndex
    this.isPlaceholder = options.isPlaceholder || false
    this.placeholderDuration = options.placeholderDuration || null
    this.minDuration = options.minDuration || null

    this.duration = options.duration 
    this.recordingDuration = options.recordingDuration || options.duration

    this._handleVideoClipLoaded=handleVideoClipLoaded
    this.updateVideoClipSegments=updateVideoClipSegments

    if(!this.isUploadingVideo && !this.isPlaceholder){
       this.initalizeWebcamVideo()
    }
    if(!this.metadata.colorAdjustments){
      this.metadata.colorAdjustments=defaultColorAdjustments
    }

    if(!this.metadata.layout){
      this.metadata.layout = defaultWebcamLayout
    }

    if(this.metadata.isMuted){
      this.video.muted = true
    }

    if(!this.isPlaceholder && !this.metadata.transcript){
      this.getWebcamTranscript()
    }

  }


  updateIsMuted(value){
    this.video.muted=value
  }


  async getWebcamTranscript(){
    const transcript = await getTranscriptForWebcamCaptureId(this.captureId,this.metadata.trimStart,this.metadata.trimEnd)
    this.metadata.transcript = transcript
    console.log(transcript)
  }

  calculateTrimmedTranscript(){
    console.log('calculate trimmed transcript')
  }

  updateTrimValues(trimStartTime,trimEndTime){
    this.metadata.trimStart=trimStartTime
    this.video.currentTime=trimStartTime
    this.metadata.trimEnd=trimEndTime
    this.calculateTrimmedSegments()
    this.calculateTrimmedTranscript()
  }

  updateChunksAndSkippedWords(newChunks,skippedWords){
    if (!this.metadata.transcript || !newChunks?.length) {
      return;
    }
    const updatedTranscript = updateTranscriptChunks(
      this.metadata.transcript,
     newChunks
    );
    if (updatedTranscript) {
      this.metadata.transcript = updatedTranscript;    
      this.metadata.transcript.skippedWords = skippedWords
    }
    const skipRanges = calculateWebcamSkipRangesFromWords(skippedWords)
    this.calculateRecordingSegments(skipRanges)
  }


  calculateRecordingSegments(skipRanges=[]){
   // console.log(this.recordingDuration)
    const segments = calculateWebcamRecordingSegments(this.recordingDuration,skipRanges)
    this.recordingSegments = segments 
    const isInitialLoad = this.segments.length==0
    this.calculateTrimmedSegments()
  }

  calculateTrimmedSegments(){ //apply trim and clip playback rate
    const isInitialLoad = this.segments.length==0
    this.segments = calculateTrimmedSegments(this.recordingSegments,this.metadata.trimStart,this.metadata.trimEnd,this.clipPlaybackRate)
    this.calculateDurationFromSegments()
    if(isInitialLoad){
       this.updateVideoClipSegments(this.id)
    }
  }


  async finishUpload(){
    await this.initalizeWebcamVideo()
  }


  async initalizeWebcamVideo() {
    const videoUrl = await fetchWebcamVideo(this.captureId);
    this.videoUrl = videoUrl
    this.video.src = videoUrl
    this.video.crossOrigin = 'anonymous' // Add this line to enable CORS
    this.video.preload = 'auto'
    this.video.playbackRate = this.clipPlaybackRate
    if(!this.metadata.trimEnd){
      this.metadata.trimStart=0
      this.metadata.trimEnd=this.recordingDuration
    }
    if (!this.hasSegments || !this.hasRecordingSegments) {
      this.calculateRecordingSegments()
    }
    this.isUploadingVideo = false
  }

 

   calculateDurationFromSegments() {
    let currentTime = this.metadata.trimStart;
    let totalDuration = 0;
    this.segments.forEach(segment => {
      totalDuration += segment.newDuration;
    });
    this.duration = totalDuration;
  }

  playFromCurrentTime(currentTime) { //updated to take into account playback rates
   if(this.video && this.video.src){
    if (this.ended) {
      return
    }
    if (currentTime >= this.startTime && currentTime < this.endTime) {
      const videoTime = calulateVideoTimeFromTimelineTime(currentTime, this)
     // console.log(`video time is-------- ${videoTime}`)
      this.video.currentTime = videoTime;
      this.video.play();
    }
    }
  }

  pause() {
    this.video.pause();
  }

  seek(currentTime) {
    this.ended = false
    if(this.video && this.video.src && !this.isUploadingVideo){
      if (currentTime >= this.startTime && currentTime < this.endTime) {
        const videoTime = calulateVideoTimeFromTimelineTime(currentTime, this)
        this.video.currentTime = videoTime
     } else {
        this.video.pause(); // If the current time is outside the clip, pause it
      }
    }
  }


  toJSON() {
   const json = {
      id: this.id,
      type:this.type,
      clipIndex:this.clipIndex,
      captureId:this.captureId,
      placeholderDuration:this.placeholderDuration,
      minDuration:this.minDuration,
      isUploadingVideo:this.isUploadingVideo,
      fileName:this.fileName,
      startTime:this._startTime,
      pinnedStartTime:this.pinnedStartTime,
      absoluteStartTime:this.startTime, //for server side export add this for audio- TODO maybe update ssr to calc based on scenes
      duration:this.duration,
      recordingDuration:this.recordingDuration,
      name:this.name,
      metadata:this.metadata,
      zIndex:this.zIndex,
      recordingSegments:this.recordingSegments,
      segments:this.segments,
      clipPlaybackRate:this.clipPlaybackRate,
      isPlaceholder:this.isPlaceholder
    };
    return json
  }

   
 

  destroy() {
    if (!this.video.paused) {
      this.video.pause();
    }
    this.video.removeEventListener('playing', this.onPlaying);
    this.video.removeEventListener('ended', this.onEnded);
    //this.video.removeEventListener('loadedmetadata', this.onMetadataLoaded);
    this.video.src = '';
    this.video.load(); // Forces the release of the video buffer
    this.video = null;
    this.ended = false;
    this.captureId = null;
  }

}

export { WebcamClip }