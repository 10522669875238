import React from 'react';
import Icon from '../../misc/Icon';

const DPCheckbox = ({ label, checked, setChecked, tight }) => {

  return (
    <div 
      data-checked-state={checked ? 'checked' : 'unchecked'}             
      onClick={()=> setChecked(!checked)}
      className={'dpCheckboxContainer editor-detailPanel-row' + (tight ? ' editor-detailPanel-row--tight ' : '')}
    >
      <div className='editor-detailPanel-row-label'>
        {label}
      </div>
      <div className='editor-detailPanel-row-hSpacer' />
      <div className='editor-detailPanel-row-checkbox'>
        <div className='editor-detailPanel-row-checkbox-iconContainer'>
          <Icon name='toDoItemCheckmark' />
        </div>    
      </div>


     
    </div>
  );
};

export default DPCheckbox