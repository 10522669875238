import React, {Component} from 'react'
import { withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import pusherInstance from '../pusherInstance'
import {handleUpdateProjectAccessUserPusher,fetchProjects} from '../actions/projects'
import {webcamProcessingComplete} from '../actions/webcamRecording'
import {fetchRecordings} from '../actions/recordings'
import {fetchCollections} from '../actions/collections'
import {fetchUploadedImages} from '../actions/imageUpload'
import {fetchUploadedVideos} from '../actions/videoUpload'
import {fetchOrganization} from '../actions/organization'
import {getCurrentUserId} from '../utils/getCurrentUserId'
import debounce from 'lodash/debounce'


class OrgPusherContainer extends Component {

	constructor(){
		super()
		this.connectedToPusher=false
		this.debouncedFetchProjects = null
	}


	componentDidMount() {
		// Create the debounced function here
		this.debouncedFetchProjects = debounce(this.props.fetchProjects, 4000)
	}


	componentDidUpdate(prevProps) {
		if(!this.connectedToPusher && this.props.organization && this.props.organization.id ){
			this.subscribeToOrganisationPusherChannel()
		}
	}

	subscribeToOrganisationPusherChannel(){
		const channel = pusherInstance.subscribe(`organization-${this.props.organization.id}`);
		channel.bind('project-access-user-update', this.handleProjectAccessUserUpdate) 
		channel.bind('new-project', this.handleNewProjectPusher) 
		channel.bind('project-updated', this.handleProjectUpdatedPusher) 
		channel.bind('subscription-updated', this.handleSubscriptionUpdatedPusher) 
		channel.bind('collection-updated', this.handleCollectionUpdated) 
		channel.bind('project-collection-updated', this.handleProjectCollectionUpdated) 
		channel.bind('webcam-processing-complete',this.handleWebcamProcessingComplete)

		this.connectedToPusher=true
	}

	handleWebcamProcessingComplete=(pusherData)=>{
		this.props.webcamProcessingComplete(pusherData.webcam);
	}


	handleProjectAccessUserUpdate=(pusherData)=>{
		this.props.handleUpdateProjectAccessUserPusher(pusherData.project_id,pusherData.project_access_user)
		if(!pusherData.project_access_user){
			this.props.fetchProjects()
			this.props.fetchRecordings()
			this.props.fetchUploadedImages()
			this.props.fetchUploadedVideos()
		}
	}

//For now we just refetch everything-TODO do this properly

	handleCollectionUpdated=(pusherData)=>{
		const updatedBy=pusherData.updatedBy
		if(!updatedBy || updatedBy!==getCurrentUserId()){ //dont fetch if its you
			this.props.fetchCollections()
		}
	}


	handleProjectCollectionUpdated=()=>{
		this.debouncedFetchProjects()
	}

	handleProjectUpdatedPusher=(pusherData)=>{ //TODO fetch individual project
		const updatedBy=pusherData.updatedBy
		if(!updatedBy || updatedBy!==getCurrentUserId()){ //dont fetch if its you
			this.debouncedFetchProjects()
		}else{
	//		console.log('i made the changes so dont refetch')
		}
		
	}

	handleSubscriptionUpdatedPusher=()=>{
		this.props.fetchOrganization()
	}


	handleNewProjectPusher=(pusherData)=>{
		this.debouncedFetchProjects()
	}

	
	render() {
		return null
	}
}


const mapStateToProps = (state) => ({
	organization:state.organization,

});

export default withRouter(connect(
	mapStateToProps,
	{	
		handleUpdateProjectAccessUserPusher,
	fetchProjects,
	fetchRecordings,
	fetchOrganization,
	fetchUploadedImages,
	fetchUploadedVideos,
	fetchCollections,
	webcamProcessingComplete
		
	})(OrgPusherContainer))
