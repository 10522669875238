import React, { useState, useEffect, useRef, useCallback } from 'react'
import Icon from '../misc/Icon'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import {getProjectMetadata} from '../../utils/projectUtils/getProjectMetadata'
import {saveProjectName} from '../../utils/projectUtils/saveProjectName'
import debounce from 'lodash/debounce'
import {createNewProject} from '../../utils/projectUtils/createNewProject'
import {randomID} from '../../utils/randomID'
import {logout} from '../../actions/auth'
import find from 'lodash/find';
import {closeProject} from '../../utils/projectUtils/closeProject'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import TabBarItem from './TabBarItem'
import {navigateToLibrary} from '../../utils/library/navigateToLibrary'
import {addTab,setActiveTab,removeTab} from '../../actions/tabs'


function TabBar() {
  const tabs = useSelector(state => state.tabs);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // State to track the window width

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth); // Update window width on resize
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize); // Cleanup the event listener
    };
  }, []);


  useEffect(() => {
    const currentPath = location.pathname;
   if (currentPath.startsWith('/project/') && !tabs.some(tab => tab.path === currentPath)) {
      const projectId = currentPath.split('/project/')[1];
      const newTab = {
        id: projectId,
        projectId: projectId,
        path: currentPath,
        active: true
      };
      dispatch(addTab(newTab));
    } else {
      dispatch(setActiveTab(tabs.find(tab => tab.path === currentPath)?.id));
    }
  }, [location, dispatch]);


  const removeTabHandler = (tabId) => {
    dispatch(removeTab(tabId,history))
  };

  const handleTabClick = (path) => {
    history.push(path);
  };

  // const isLibraryActive = location.pathname === '/library';
  const isLibraryActive = location.pathname === '/library' || location.pathname.startsWith('/library/');

  const isExportRoute = /\/export\/[^/]+/.test(location.pathname);

  const hideTabBar = location.pathname === '/recording-controls' || location.pathname === '/updates' || location.pathname === '/record-webcam' || isExportRoute

  //
  // Calculation for Tab Widths
  const leftSpacerWidth = 194
  const rightSpacerWidth = 220

  const projectTabsWidth = windowWidth - leftSpacerWidth - rightSpacerWidth

  const maxTabWidth = 220
  const tabMargin = 15
  const tabCount = tabs.length
    
  const tabTotalWidth = projectTabsWidth / tabCount

  const calcTabWidth = tabTotalWidth - tabMargin

  const tabWidth = Math.min(calcTabWidth, maxTabWidth)

  const widthNewBtnTooltip = 65
  let hideNewBtnTooltip

  const rightFreeSpace = projectTabsWidth - (tabCount * (tabWidth + tabMargin))
  
  if(rightFreeSpace < widthNewBtnTooltip){
    hideNewBtnTooltip = true
  }

  return (
    <>
      {!hideTabBar && 
        <div className='tabBar'>
          <div className='editor-tabBar-leftSpacer' />
          <button 
            //onClick={() => history.push('/library')} 
            onClick={() => navigateToLibrary(history)}             
            className='tabBar-tab tabBar-tab--library'
            data-state={isLibraryActive ? "active" : "inactive"}
          >
            <div className='tabBar-tab--library-iconContainer'>
              <Icon name={isLibraryActive ? 'homeFill' : 'home'} />
            </div>
            <div className='tabBar-tab-labelContainer'>
              <div className='tabBar-tab-label'>
                Home
              </div>
            </div>
          </button>
          <div className='tabBar-projectList'>
            {tabs.map(tab => (
              <TabBarItem 
                key={tab.id} 
                id={tab.id}
                projectId={tab.projectId}
                path={tab.path}
                active={tab.active}
                onClick={handleTabClick}
                removeTab={removeTabHandler}
                tabWidth={tabWidth}
              />
            ))}                
          </div>
          <button className='tabBar-newBtn' onClick={()=>{createNewProject(history)}}>
            <div className='tabBar-newBtn-inner'>
              <Icon name='plusMedium' />
            </div>  
            {!hideNewBtnTooltip &&     
              <div className='tabBar-newBtn-tooltip'>
                New Draft <span className='tabBar-newBtn-tooltip-shortcut'>⌘N</span>
              </div>
            }
          </button>
           
          
        </div>
      }
    </>
  );

}

export default TabBar;
