import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SubscriptionContainer from './SubscriptionContainer'
import {logout} from '../../actions/auth'
import Icon from '../../components/misc/Icon'


class SettingsContainer extends Component {
  state = {
    isModalOpen: false,
    activeTab: ''
  };

  componentDidMount() {
    this.updateModalState();
    // Add event listener for hash changes
    window.addEventListener('hashchange', this.updateModalState, false);
  }

  componentDidUpdate(prevProps) {
    // Check if the location changed
    if (this.props.location !== prevProps.location) {
      this.updateModalState();
    }
  }

  componentWillUnmount() {
    // Remove event listener
    window.removeEventListener('hashchange', this.updateModalState, false);
  }

closeModal = () => {

  this.props.history.push(window.location.pathname + window.location.search);
};

  updateModalState = () => {
    const hash = this.props.location.hash;
    const hashParts = hash.replace('#', '').split('/');

    if (hashParts[0] === 'settings') {
      this.setState({ isModalOpen: true, activeTab: hashParts[1] || '' });
    } else {
      this.setState({ isModalOpen: false, activeTab: '' });
    }
  };

  render() {
    const { isModalOpen, activeTab } = this.state;

    let currentUser = this.props.user
    let userFirstName 
    let userEmail 
    let userAvatarUrl 

    if(currentUser){
      userFirstName=currentUser.first_name
      userEmail = currentUser.email
      userAvatarUrl=currentUser.profile_image_url
    }

    if(isModalOpen){
    return (
      <div className='settingsModalContainer'>
        <div className='settingsModal'>
          <div onClick={this.closeModal} className='settingsModal-closeBtnContainer'>
            <button className='settingsModal-closeBtn'>
              <Icon name='crossMedium' />
            </button>
          </div>
          <div className='settingsModal-sideMenu'>

            <div className='settingsModal-sideMenu-profile'>
              
              
                <div className='settingsModal-sideMenu-profile-leftContainer'>
                  {userAvatarUrl && 
                    <div className='settingsModal-sideMenu-profile-avatarContainer'>
                      <img className='settingsModal-sideMenu-profile-avatar' referrerpolicy="no-referrer" src={userAvatarUrl} />
                    </div>             
                  }   
                </div>
              
              <div className='settingsModal-sideMenu-profile-rightContainer'>
                  <div className='settingsModal-sideMenu-profile-name'>
                    {userFirstName}
                  </div>                  
                  <div className='settingsModal-sideMenu-profile-email'>
                    {userEmail}
                  </div>                  
                </div>
            </div>

            <div className='settingsModal-sideMenu-vSpacer'/>

            <div className='settingsModal-sideMenu-tabContainer'>
              <button className='settingsModal-sideMenu-tab' onClick={logout}>
                <div className='settingsModal-sideMenu-tab-label'>
                  Sign out
                </div>
              </button>
            </div>


          </div>
          
            {activeTab=='subscription' &&
              <SubscriptionContainer />
            }
          
          

         
        </div>
        <div onClick={this.closeModal} className='settingsModalUnderlay' />
      </div>
    );
  }else return null
  }
}

function mapStateToProps(state, ownProps) {
  // Your mapStateToProps logic
  return {
    user:state.user
    // Your mapStateToProps returns
  };
}

export default withRouter(connect(mapStateToProps, {})(SettingsContainer));
