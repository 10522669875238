import React, { useState, useEffect } from 'react';
import { useControls } from 'leva';
import renderOrders from '../../../three/renderOrders'

const CHDonutPartRing = ({percentFill, outerRadius, innerRadius, color, opacity}) => {  
  
  const tubeRadius = (outerRadius - innerRadius) / 2;
  const radius = innerRadius + tubeRadius;

  const arc = 2 * Math.PI * percentFill; // Calculate the arc length based on percentFill

  // State to store rotation
  const [rotation, setRotation] = useState([0, 0, 0]);

  useEffect(() => {
    const initialRotation = Math.PI / 2;
    const arcAdjustment = -arc;
    setRotation([0, 0, initialRotation + arcAdjustment]);
  }, [percentFill]);

  const scaleAdjust = 0.997

  // render order doesn't seem to be working quite right

  return (
    <mesh position={[0, 0, 6]} rotation={rotation} scale={[scaleAdjust, scaleAdjust, scaleAdjust]} renderOrder={renderOrders.graphObject} >
      <torusGeometry args={[radius, tubeRadius, 30, 100, arc]} renderOrder={renderOrders.graphObject} />
      <meshBasicMaterial color={color} transparent={true} opacity={opacity} renderOrder={renderOrders.graphObject} />
    </mesh>
  );
};

export default CHDonutPartRing;
