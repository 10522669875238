import React from 'react';

const layouts = {
  iphone14plus: {
    screenWidth: 1284,
    screenHeight: 2778,
    deviceWidth: 1429,
    deviceHeight: 2902,
    screenXOffset: 1,
    screenYOffset: 2,
    screenRadius: 180,
  },
  iphone14: {
    screenWidth: 1170,
    screenHeight: 2532,
    deviceWidth: 1299,
    deviceHeight: 2655,
    screenXOffset: 6.5,
    screenYOffset: 0.5,
    screenRadius: 150,
  },
  iphone15pro: {
    screenWidth: 1179,
    screenHeight: 2556,
    deviceWidth: 1293,
    deviceHeight: 2656,
    screenXOffset: 1,
    screenYOffset: 0.5,
    screenRadius: 191,
  },
  iphone15promax: {
    screenWidth: 1290,
    screenHeight: 2796,
    deviceWidth: 1403,
    deviceHeight: 2896,
    screenXOffset: 0,
    screenYOffset: 0,
    screenRadius: 191,
  },
  ipad10landscape: {
    screenWidth: 2360,
    screenHeight: 1640,
    deviceWidth: 2550,
    deviceHeight: 1829,
    screenXOffset: 2,
    screenYOffset: 0.5,
    screenRadius: 40,
  },
  ipad10portrait: {
    screenWidth: 1640,
    screenHeight: 2360,
    deviceWidth: 1829,
    deviceHeight: 2550,
    screenXOffset: 0.5,
    screenYOffset: -2,
    screenRadius: 40,
  },
  ipadminilandscape: {
    screenWidth: 2266,
    screenHeight: 1488,
    deviceWidth: 2510,
    deviceHeight: 1728,
    screenXOffset: 2,
    screenYOffset: 0,
    screenRadius: 45,
  },
  ipadminiportrait: {
    screenWidth: 1488,
    screenHeight: 2266,
    deviceWidth: 1728,
    deviceHeight: 2510,
    screenXOffset: 0,
    screenYOffset: -2,
    screenRadius: 45,
  },
  ipadpro11landscape: {
    screenWidth: 2388,
    screenHeight: 1668,
    deviceWidth: 2578,
    deviceHeight: 1862,
    screenXOffset: 2,
    screenYOffset: -2,
    screenRadius: 40,
  },
  ipadpro11portrait: {
    screenWidth: 1668,
    screenHeight: 2388,
    deviceWidth: 1862,
    deviceHeight: 2578,
    screenXOffset: -2,
    screenYOffset: -2,
    screenRadius: 40,
  },
  ipadpro129landscape: {
    screenWidth: 2732,
    screenHeight: 2048,
    deviceWidth: 2928,
    deviceHeight: 2244,
    screenXOffset: 2,
    screenYOffset: -2,
    screenRadius: 40,
  },
  ipadpro129portrait: {
    screenWidth: 2048,
    screenHeight: 2732,
    deviceWidth: 2244,
    deviceHeight: 2928,
    screenXOffset: -2,
    screenYOffset: -2,
    screenRadius: 40,
  },
  ipad9landscape: {
    screenWidth: 2160,
    screenHeight: 1620,
    deviceWidth: 2350,
    deviceHeight: 1814,
    screenXOffset: 2,
    screenYOffset: -2,
    screenRadius: 40,
  },
  ipad9portrait: {
    screenWidth: 1620,
    screenHeight: 2160,
    deviceWidth: 1814,
    deviceHeight: 2350,
    screenXOffset: -2,
    screenYOffset: -2,
    screenRadius: 40,
  },
  
  // ipad10portrait: {
  //   screenWidth: 1640,
  //   screenHeight: 2360,
  //   deviceWidth: 1700,
  //   deviceHeight: 2400,
  //   screenXOffset: 0,
  //   screenYOffset: 0,
  //   screenRadius: 191,
  // }
};

const getMobileDeviceModelLayout = (mobileDeviceModel) => {
  // Return the layout object directly from the predefined layouts
  return layouts[mobileDeviceModel] || {};  // Return an empty object if the model is not found
};

export default getMobileDeviceModelLayout;





//This triggers the rerender bug cos new objects
// const getMobileDeviceModelLayout = (mobileDeviceModel) => {
  
//   let mobileDeviceLayout = []

//   if(mobileDeviceModel === 'iphone14plus'){
//     mobileDeviceLayout.screenWidth = 1284
//     mobileDeviceLayout.screenHeight = 2778
//     mobileDeviceLayout.deviceWidth = 1429
//     mobileDeviceLayout.deviceHeight = 2902
//     mobileDeviceLayout.screenXOffset = 74
//     mobileDeviceLayout.screenYOffset = 60
//   }
//   if(mobileDeviceModel === 'iphone14'){
//     mobileDeviceLayout.screenWidth = 1170
//     mobileDeviceLayout.screenHeight = 2532
//     mobileDeviceLayout.deviceWidth = 1299
//     mobileDeviceLayout.deviceHeight = 2655
//     mobileDeviceLayout.screenXOffset = 6.5 
//     mobileDeviceLayout.screenYOffset = 0.5 
//   }

//   return mobileDeviceLayout
// };

// export default getMobileDeviceModelLayout;
