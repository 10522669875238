import {Schema} from 'prosemirror-model'
import EditorNodes from './nodes/EditorNodes'

//https://discuss.prosemirror.net/t/nodes-append-is-not-a-function/2216/2
const timelineSchema = new Schema({
  nodes:EditorNodes,
  marks: []
})



export default timelineSchema