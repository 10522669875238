const SlideClipNodeSpec = {
	attrs: {
		id:{default:'defaultID'},
		type:{default:'chart'},
		relativeStartTime: {default:0},
		//startTime: {default:0},
		duration:  {default:0},
		metadata: { default: [] },
		zIndex:  {default:1},
		sceneId:{default:'defaultSceneId'}
	},
};

 export default SlideClipNodeSpec
 