import React, {Component} from 'react';
import {EditorState} from "prosemirror-state"
import {EditorView} from "prosemirror-view"
import {history,undo,redo} from "prosemirror-history"
import {keymap} from "prosemirror-keymap"
import {baseKeymap} from "prosemirror-commands"
import textSlideSchema from '../../prosemirror/textSlide/schema/textSlideSchema'
import { fontWeightToNumber } from '../utils/fontWeightToNumber';


const DEFAULT_JSON=`{"type":"doc","content":[{"type":"paragraph","attrs":{"indentLevel":0},"content":[{"type":"text","text":"Headline"}]}]}`


function luminance(r, g, b) {
    const a = [r, g, b].map(function (v) {
        v /= 255;
        return v <= 0.03928 ? v / 12.92 : Math.pow( (v + 0.055) / 1.055, 2.4 );
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

// Function to determine if background is light or dark
function isSlideBackgroundLight(rgba) {
    const regex = /^rgba?\((\d+),\s*(\d+),\s*(\d+)/i;
    const matches = regex.exec(rgba);
    if (matches) {
        const lum = luminance(parseInt(matches[1]), parseInt(matches[2]), parseInt(matches[3]));
        return lum > 0.5;
    }
    return false; // Default in case of an error
}




class TextSlideInputTextArea extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      wordsArray:[]
    };
  }

  componentDidMount() {   
    this.loadView()
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.initialValue !== this.props.initialValue) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    const propsToCheck = ['fontFamily', 'fontWeight', 'fontSize','textAlign', 'listType', 'canvasX', 'canvasY', 'invertScalar'];
    const hasRelevantPropsChanged = propsToCheck.some(prop => {
      return prevProps[prop] !== this.props[prop];
    });
    if (hasRelevantPropsChanged) {
      setTimeout(() => {
        this.calcWordsArray()
      }, 10);    
    }
    if(prevProps.initialValue !== this.props.initialValue){
      this.loadView()
    }
  }

  componentWillUnmount(){
    if(this.view){
      this.view.destroy()
      this.view=null
      if(!this.props.isGhostCalc){
        window.textEditView=null
      }
    
      //window.textSlideView=null
    }
  }

  handleEditorBlur = () => {
    const { doc } = this.view.state;
   // console.log('Editor has lost focus');
    if(!doc.textContent){
      this.loadView(DEFAULT_JSON)
    }
    this.props.setTextEditorIsFocused(false)
  }
  
  handleEditorFocus = () => {
    this.props.setTextEditorIsFocused(true)
    if(this.props.handleSetActivePanelClip && (!this.props.activePanelClip || this.props.activePanelClip.id!=this.props.slide.id) ){
      this.props.handleSetActivePanelClip(this.props.slide)
    }
  }

  loadView(defaultJson){
    if(this.view){
      this.view.destroy()
    }
    const {clipId} = this.props
    let divId='text_slide_pm_input'
    if(clipId){
      divId=`text_slide_pm_input_${clipId}`
    }

    const handleEditorBlur=this.handleEditorBlur
    const handleEditorFocus = this.handleEditorFocus
    //this.view = new EditorView(document.querySelector("#text_slide_pm_input"), {
    this.view = new EditorView(document.querySelector(`#${divId}`), {
      attributes: {               
        spellCheck: false,
      },
      state: EditorState.create({
      doc: this.makePMDoc(defaultJson),
      plugins:[
        // history(),
        // keymap({
        // "Mod-z": undo,
        // "Mod-y": redo,
        // }),
        keymap(baseKeymap),
      ],  
    }),
    handleDOMEvents: { //we disable all of these events
      blur(view, event) { handleEditorBlur()},
      focus(view, event) { handleEditorFocus()},
    },
    handleKeyDown:this.handleKeyDown,
    dispatchTransaction: transaction => { //for resizing inputbox -- maybe throttle?
      const { state, transactions } = this.view.state.applyTransaction(transaction)
      this.view.updateState(state)
      const wordPositions = this.calcWordsArray();
    },
    
    })
    

    if(!this.props.isGhostCalc){
      window.textEditView=this.view      
    }
    
    if(this.props.isGhostCalc){
      setTimeout(() => {
        this.calcWordsArray();
      }, 100);
    } else {
      this.calcWordsArray();
    }

  }

  makePMDoc=(json)=>{
    const jsonDoc = json || this.props.initialValue
    if (jsonDoc) {
      return textSlideSchema.nodeFromJSON(JSON.parse(jsonDoc));
    }
    const para = textSlideSchema.nodes.paragraph.createAndFill(null, [textSlideSchema.text('Headline')]);
    return textSlideSchema.nodes.doc.createAndFill(null, [para]);
  }

  calcWordsArray=()=> {
    let wordsArray = [];
    const { doc } = this.view.state;
    let paragraphIndex = -1;
    doc.descendants((node, pos) => {
      if (node.type.name === "paragraph") {
            paragraphIndex++; // Increment the paragraph index
        }
        if (node.isText) {
            const text = node.text;
            let match;
            //const regex = /\b\w+('\w+)?\b/g;
            const regex = /[^\s]+/g;
            while ((match = regex.exec(text)) !== null) {
              const start = pos + match.index;
              const end = start + match[0].length;
              const startCoords = this.view.coordsAtPos(start);
              const endCoords = this.view.coordsAtPos(end);
              const horizontalCenter = 1920 / 2
              const verticalCenter = 1080 / 2
              let manualHOffset = 0 // not sure why this is needed 
              if(this.props.textAlign === 'left'){
                manualHOffset = 19  * (1 / this.props.invertScalar)
              }
              if(this.props.textAlign === 'center'){
                manualHOffset = 19 * (1 / this.props.invertScalar)
              }
              if(this.props.textAlign === 'right'){
                manualHOffset = 19  * (1 / this.props.invertScalar)
              }
              const rect = {
                left: startCoords.left,
                normalLeft: (((startCoords.left * 0.988) + manualHOffset - this.props.canvasX ) * this.props.invertScalar) - horizontalCenter ,
                // right: endCoords.right,
                // normalRight: horizontalCenter - endCoords.right - this.props.canvasX,
                top: startCoords.top,
                normalTop: (verticalCenter - ((startCoords.top - this.props.canvasY) * this.props.invertScalar)) * 0.988,
                bottom: endCoords.bottom,
                normalBottom: verticalCenter - ((endCoords.bottom - this.props.canvasY) * this.props.invertScalar), 
                width: endCoords.right - startCoords.left,
                height: endCoords.bottom - startCoords.top,
              };
              wordsArray.push({ 
                word: match[0], 
                rect: rect ,
                paragraphIndex: paragraphIndex
              });
            }
        }        
    });


    const text = doc.textContent
    const docJson= JSON.stringify(doc.toJSON()); 
    if(this.props.handleSetWordsArray){
     // console.log('set words array')
       this.props.handleSetWordsArray(wordsArray,text,docJson)
    }
    return this.setState({wordsArray:wordsArray});
  }


  render() {
    const {fontFamily, fontWeight, textAlign, fontSize, listType,clipId, slide, letterSpacing, lineHeight,slideBG} = this.props
    const {wordsArray} = this.state

    const listIndent = 60 
    let maxWidth = 1520
    if(listType){
      maxWidth -= listIndent
    }

    const marginLeft = listType ? listIndent : 0

    const textAlignStyle = listType ? "left" : textAlign 
    
    const devMode = false

    let letterSpacingScaler = 0 // some fonts require adjustments between web and webGL rendering    

    if(fontFamily === 'YMonaSans'){
      letterSpacingScaler = 0.024
    }
    if(fontFamily === 'YMarlideDisplay'){
      letterSpacingScaler = 0.002
    }
    if(fontFamily === 'YInter' && fontWeight === 'Bold'){
      letterSpacingScaler = 0.009
    }
    if(fontFamily === 'YInter' && fontWeight === 'Bold' && letterSpacing > 0){
      letterSpacingScaler = -0.01
    }
    if(fontFamily === 'YInter' && fontWeight === 'Semibold'){
      letterSpacingScaler = 0.009
    }
    if(fontFamily === 'YInter' && fontWeight === 'Regular'){
      letterSpacingScaler = 0.010
    }
    if(fontFamily === 'YPoppins' && fontWeight === 'Medium'){
      letterSpacingScaler = -0.02
    }
    if(fontFamily === 'YPoppins'){
      letterSpacingScaler = 0.01
    }
    if(fontFamily === 'YIBMPlexSans' && fontWeight === 'Semibold'){
      letterSpacingScaler = 0.024
    }
    if(fontFamily === 'YPlusJakartaSans'){
      letterSpacingScaler = 0.008
    }
    if(fontFamily === 'YMontserrat'){
      //letterSpacingScaler = -0.024
    }
    if(fontFamily === 'YIBMPlexSans' && fontWeight === 'Semibold' && letterSpacing < -0.04){
      letterSpacingScaler = 0.014
    }
    if(fontFamily === 'YSohne'){
      letterSpacingScaler = 0.004
    }
    if(fontFamily === 'YSwitzer'){
      letterSpacingScaler = 0.004
    }
    

    const textStyle = {     
      fontFamily: `${fontFamily}`,
      fontSize: `${fontSize}px`,
      textAlign: `${textAlignStyle}`,
      fontWeight: `${fontWeightToNumber(fontWeight)}`, 
      marginLeft: `${marginLeft}px`,
      letterSpacing: `${letterSpacing + letterSpacingScaler}em`,
      lineHeight: `${lineHeight}`
    }    

    let divId='text_slide_pm_input'
    if(clipId){
      divId=`text_slide_pm_input_${clipId}`
    }

    

    // const slideBG = slide.metadata.slideBackgroundColor

    // console.log('slideBG')
    // console.log(slideBG)

    let slideBGIsLight = false 
    // const backgroundColor = clip.metadata.
    if(slideBG && slideBG.type ==='solid' && slideBG.rgba){
      slideBGIsLight = isSlideBackgroundLight(slideBG.rgba);
    }
    if(slideBG && slideBG.type ==='image' && slideBG.src === '../bgImages/causal/causalBG.png'){
      slideBGIsLight = true
    }
    

    return (
      <>
        <div style={{...textStyle, width: `${maxWidth}px`}} className={'editor-textSlide-textArea' + (devMode ? ' editor-textSlide-textArea--devMode' : '') + (!slideBGIsLight ? ' editor-textSlide-textArea--defaultBlue ' : ' editor-textSlide-textArea--standardColors ')}>
          <div className={'textSlide-PMContainer ' + (listType ? 'textSlide-PMContainer--isList' : 'textSlide-PMContainer--headline')}  id={divId} />          
        </div>
        <div  className='editor-textSlide-textAreaDeactive' />
      </>
    );
  }
}


export default TextSlideInputTextArea
