import {randomID} from '../../utils/randomID'

//type can be hstack, vstack or free

class LayoutGroup { 

constructor({ id, type, hAlign, vAlign, x, y, width, height,depth,bottomMargin,rightMargin,isLocked }) {
    this.id = id || randomID();
    this.depth=depth || 0
    this.type = type || 'vstack';
    this.hAlign = hAlign || 'left';
    this.vAlign = vAlign || 'middle';
    this.x = x || 50;
    this.y = y || 100;
    this.width = width || 200;
    this.height = height || 300;
    this.children = []; // Initialize as an empty array
    this.isLayoutGroup=true
    this.bottomMargin=bottomMargin || 50
    this.rightMargin=rightMargin || 50
    this.isLocked = isLocked || false
  }

  addChild(child, index = this.children.length) {
    child.parentHAlign = this.getParentHAlign.bind(this, child);
    this.children.splice(index, 0, child);
  }

  getParentHAlign(child) {
    return this.hAlign;
  }

 removeChild(childId) {
    const index = this.children.findIndex(child => child.id === childId);
    if (index !== -1) {
      this.children.splice(index, 1);
    }
  }


reorderChild(childId, newIndex) {
    const currentIndex = this.children.findIndex(child => child.id === childId);
    if (currentIndex !== -1) {
      const [child] = this.children.splice(currentIndex, 1);
      this.children.splice(newIndex, 0, child);
    }
  }

  findChildById(id) {
    for (const child of this.children) {
      if (child.id === id) return child;
      if (child instanceof LayoutGroup) {
        const found = child.findChildById(id);
        if (found) return found;
      }
    }
    return null;
  }

  traverse(callback) {
    callback(this);
    for (const child of this.children) {
      if (child instanceof LayoutGroup) {
        child.traverse(callback);
      } else {
        callback(child);
      }
    }
  }


  toJSON() {

    return {
      id: this.id,
      isLayoutGroup:true,
      depth:this.depth,
      type: this.type,
      hAlign:this.hAlign,
      vAlign:this.vAlign,
      isLocked:this.isLocked,
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      bottomMargin:this.bottomMargin,
      rightMargin:this.rightMargin,
      children: this.children.map(child => child.toJSON())
    };
  }
}

export {LayoutGroup}