import React, { useRef, useEffect } from 'react';
import { Text } from '@react-three/drei';

const TextSlideWordListAnimateContents = ({ time, FPS, animationStates, initialProperties, wordsArray, wordStartTimes, fontFamily, fontWeight, fontSize, wordIsHidden, textColor, allWordsSyncAnimation, listType }) => {
  const paragraphRefMap = useRef(new Map());

  // Map to hold the first word index of each paragraph
  const firstWordIndexInParagraph = new Map();

  // Populate the map with the index of the first word in each paragraph
  wordsArray.forEach((wordObj, index) => {
    if (!firstWordIndexInParagraph.has(wordObj.paragraphIndex)) {
      firstWordIndexInParagraph.set(wordObj.paragraphIndex, index);
    }

    if (!paragraphRefMap.current.has(wordObj.paragraphIndex)) {
      paragraphRefMap.current.set(wordObj.paragraphIndex, {
        groupRef: React.createRef(),
        textRef: React.createRef()
      });
    }
  });

  useEffect(() => {
    paragraphRefMap.current.forEach((refs, paragraphIndex) => {
      const firstWordIndex = firstWordIndexInParagraph.get(paragraphIndex);
      if (refs.textRef.current && refs.textRef.current.material) {
        refs.textRef.current.material.opacity = wordIsHidden[firstWordIndex] ? 0 : 1;
      }
    });
  }, [wordIsHidden, wordsArray]);

  useEffect(() => {
    const frameIndex = Math.floor(time * FPS);
    wordsArray.forEach((wordObj) => {
      const paragraphIndex = wordObj.paragraphIndex;
      const firstWordIndex = firstWordIndexInParagraph.get(paragraphIndex);
      const { groupRef, textRef } = paragraphRefMap.current.get(paragraphIndex);

      if (groupRef.current) {
        let adjustedFrameIndex = frameIndex;
        if (!allWordsSyncAnimation) {
          adjustedFrameIndex -= Math.floor(wordStartTimes[firstWordIndex] * FPS);
        }

        Object.keys(animationStates).forEach(property => {
          const state = animationStates[property][adjustedFrameIndex];
          if (state !== undefined) {
            if (property.startsWith('position')) {
              const axis = property.slice('position'.length).toLowerCase();
              groupRef.current.position[axis] = state;
            } else if (property === 'scale') {
              groupRef.current.scale.set(state, state, state);
            } else if (property.startsWith('rotation')) {
              const axis = property.slice('rotation'.length).toLowerCase();
              groupRef.current.rotation[axis] = state;
            }

            if (property === 'opacity' && textRef.current && textRef.current.material) {
              textRef.current.material.opacity = wordIsHidden[firstWordIndex] ? 0 : state;
            }
          }
        });
      }
    });
  }, [time, FPS, animationStates, wordsArray, wordStartTimes, wordIsHidden, allWordsSyncAnimation]);

  return (
    <>
      {wordsArray
        .filter((wordObj, index, array) => {
          return index === 0 || wordObj.paragraphIndex !== array[index - 1].paragraphIndex;
        })
        .map((wordObj, index) => {
          const paragraphIndex = wordObj.paragraphIndex;
          const { groupRef, textRef } = paragraphRefMap.current.get(paragraphIndex);
          return (
            <group 
              key={index}
              ref={groupRef}
              position={[initialProperties.positionX, initialProperties.positionY, initialProperties.positionZ]}
              scale={[initialProperties.scale, initialProperties.scale, initialProperties.scale]}
            >
              <Text        
                ref={textRef}
                anchorX="left"
                anchorY="top"
                fontSize={fontSize}
                textAlign={listType === 'bullet' ? "left" : "right"}
                font={`../fonts/${fontFamily}/${fontFamily}-${fontWeight}.woff`}
                position={[listType === 'bullet' ? -760 : -800, wordObj.rect.normalTop, 10]}
              >
                <meshBasicMaterial transparent={true} color={textColor} />
                  {listType === 'bullet' ? '•' : `${paragraphIndex + 1}.`}
              </Text>
            </group>
          );
        })
      }
    </>
  );
};

export default TextSlideWordListAnimateContents;
