import React, { useState } from 'react';
import * as ContextMenu from '@radix-ui/react-context-menu'
import Icon from '../../misc/Icon'
import MoveToCollectionPopover from '../../uiKit/moveToCollection/MoveToCollectionPopover'
import {shareProjectWithTeam} from '../../../utils/projectUtils/shareProjectWithTeam'
import {archiveProject} from '../../../utils/projectUtils/archiveProject'
import {duplicateProject} from '../../../utils/projectUtils/duplicateProject'
import {restoreProject} from '../../../utils/projectUtils/restoreProject'
import {getOrgNameLabel} from '../../../utils/getOrgNameLabel'
import { useHistory } from 'react-router-dom'
import {orgHasCollections} from '../../../utils/collectionUtils/orgHasCollections'

const LibraryProjectContextMenuContents = ({
  setShowMoveToPopover, 
  showMoveToPopover,
  project,
  renameProject,  
  pendingChanges,
  handleCollectionChange

}) => {

  const history = useHistory();
  const orgNameLabel = getOrgNameLabel()

  const isArchived = project.archived_at?true:false

  const isPrivate = project.is_private

  const hasCollections = orgHasCollections() // org has collections

  if(isArchived){
    return(
      <>
     <ContextMenu.Content collisionPadding={10} className='contextMenuWrapper'>
          <div className='contextMenu contextMenu--libraryProject forceDarkTheme'>                    
              <ContextMenu.Item onSelect={() => restoreProject(project.id,history)}>
                <div className='dropdownMenu-item-iconContainer'>
                  <Icon name='moveToMedium' />
                </div>
                Restore
              </ContextMenu.Item>  
          </div>
      </ContextMenu.Content>
    </>
    )
  }
  else{
  return (
    <>                        
      <ContextMenu.Content collisionPadding={10} className='contextMenuWrapper'>
        
        

        {!showMoveToPopover && 
          <div className='contextMenu contextMenu--libraryProject forceDarkTheme'>                    
             

           {/*}

           {!isPrivate && 
             <div className='dropdownMenu-explainer dropdownMenu-explainer--short'>
              {orgNameLabel}
             </div>
           }
           */}

           


             {!isPrivate && hasCollections &&
               <ContextMenu.Item                  
                  onSelect={(event) => {
                    event.preventDefault();
                    setShowMoveToPopover(true);
                  }}
                >
                <div className='dropdownMenu-item-iconContainer'>
                  <Icon name='plusMedium' />
                </div>
                Add to Collection
              </ContextMenu.Item>  
              }   

              {/*}
              {isPrivate &&
                <ContextMenu.Item className='contextMenu-item--disabled'>
                  <div className='dropdownMenu-item-iconContainer'>
                    <Icon name='plusMedium' />
                  </div>
                  Add to Collection
                </ContextMenu.Item>  
              }
              */}
            

            {isPrivate && 
              <ContextMenu.Item onSelect={() => shareProjectWithTeam(project.id)}>
                <div className='dropdownMenu-item-iconContainer'>
                  <Icon name='moveToMedium' />
                </div>
                Move to {orgNameLabel}
              </ContextMenu.Item>  
            }

             
            <ContextMenu.Item onSelect={renameProject}>
              <div className='dropdownMenu-item-iconContainer'>
                <Icon name='aaTextMedium' />
              </div>
              Rename
            </ContextMenu.Item>  
            
            

            <ContextMenu.Item  onSelect={() => duplicateProject(project.id)} >
              <div className='dropdownMenu-item-iconContainer'>
                <Icon name='duplicateMedium' />
              </div>
              Make a Copy
            </ContextMenu.Item>   
          
            <ContextMenu.Separator />  
                    
            <ContextMenu.Item onSelect={() => archiveProject(project.id,history)} className='contextMenu-item--light'>              
              <div className='dropdownMenu-item-iconContainer'>
                <Icon name='deletedMedium' />
              </div>
              Delete
            </ContextMenu.Item>   
          </div>
        }

        <MoveToCollectionPopover 
          showMoveToPopover={showMoveToPopover}
          project={project}
          pendingChanges={pendingChanges}
          handleCollectionChange={handleCollectionChange}
        />

      </ContextMenu.Content>
    </>
  );
}
};

export default LibraryProjectContextMenuContents;